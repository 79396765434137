import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "src/components/Form";
import { makeStyles } from "@mui/styles";
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    Tooltip,
} from "@mui/material";
import { FilesRequestRetentionPeriod } from "./FilesRequestRetentionPeriod";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../types/redux/store";
import {
    createRequestFileByEmail,
    getDefaultRequestFilesByEmailProps,
    saveEmails,
} from "../../redux/pages/filesRequest/actions";
import { TextFieldShrinkWrapper } from "../TextFieldShrinkWrapper";
import { RequestByEmailDTO } from "../../services/generated";
import { EmailPreviewWindow } from "../ModalWindows/EmailPreviewWindow";
import { openDialog } from "src/redux/dialogs/commonDialog/actions";
import { pluralize, WordTranslationKey } from "src/helpers/wordCasesUtil";
import { AutocompleteForEmail } from "../Autocomplete/AutocompleteForEmail";

const useStyle = makeStyles({
    buttonContainer: {
        marginTop: "24px",
    },
    button: {
        width: "100%",
    },
    tooltip: {
        maxWidth: 500,
    },
    contentLongRow: {
        "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
});

const requestFilePreviewFormModalWindow =
    "REQUEST_FILE_PREVIEW_FORM_MODAL_WINDOW";

export const FilesRequestByEmail: FC = () => {
    const { t, i18n } = useTranslation();
    const styles = useStyle();
    const dispatch = useDispatch();
    const requestByEmailForm = useSelector(
        (store: Store) => store.pages.filesRequestPage.requestByEmailForm,
    );
    const { dialogName } = useSelector(
        (store: Store) => store.dialogs.commonDialog.dialog,
    );
    const user = useSelector(
        (store: Store) => store.authenticationHandler.authentication.user,
    );
    const isLoadedDefaultValues = useRef(false);
    const [isValidAutocomplete, setIsValidAutocomplete] = useState(false);

    const { register, handleSubmit, control, watch, reset } =
        useForm<RequestByEmailDTO>({
            defaultValues: {
                daysToLiveRequest: 1,
                daysToLiveFiles: 1,
                messageText: requestByEmailForm?.messageText,
                recipientsEmails: requestByEmailForm?.recipientsEmails,
                subject: requestByEmailForm?.subject,
            },
        });

    const values = watch();

    useEffect(() => {
        dispatch(getDefaultRequestFilesByEmailProps());
        isLoadedDefaultValues.current = false;
    }, [dispatch, i18n.language]);

    useEffect(() => {
        if (requestByEmailForm && !isLoadedDefaultValues.current) {
            reset(requestByEmailForm);
            isLoadedDefaultValues.current = true;
        }
    }, [requestByEmailForm, reset]);

    const handleMailsChange = (e: any, data: string[]) => {
        dispatch(saveEmails(data));
    };

    const sendForm = (data: RequestByEmailDTO) => {
        if (requestByEmailForm?.recipientsEmails !== undefined) {
            data.recipientsEmails = requestByEmailForm?.recipientsEmails;
        }
        dispatch(createRequestFileByEmail(data));
    };

    const handleValidEmail = (isAutocompleteValid: boolean) => {
        setIsValidAutocomplete(isAutocompleteValid);
    };

    const tooltipText =
        ((!requestByEmailForm?.recipientsEmails ||
            !requestByEmailForm?.recipientsEmails.length ||
            !values.subject) &&
            (t("request-files:tooltip.emptyFields") as string)) ||
        (!isValidAutocomplete &&
            (t("request-files:tooltip.errorEmail") as string));

    const [shrink, setShrink] = useState(false);

    useEffect(() => {
        if (!shrink) {
            setShrink(values.subject !== "");
        }
    }, [values.subject, shrink]);

    const handleFocus = () => {
        setShrink(true);
    };

    const handleBlur = () => {
        setShrink(values.subject !== "");
    };

    return (
        <form name="fileEmail" onSubmit={handleSubmit(sendForm)}>
            <FilesRequestRetentionPeriod control={control} />
            <AutocompleteForEmail
                options={[]}
                inputLabel={`${t("request-files:to")} *`}
                isValid={handleValidEmail}
                onChange={handleMailsChange}
            />
            <FormControl>
                <InputLabel required shrink={shrink}>
                    {t("request-files:subject")}
                </InputLabel>
                <Tooltip
                    title={values.subject || ""}
                    className={styles.tooltip}
                    disableInteractive
                >
                    <Input
                        required
                        className={styles.contentLongRow}
                        fullWidth={true}
                        {...register("subject", {
                            onBlur: handleBlur,
                        })}
                        onFocus={handleFocus}
                    />
                </Tooltip>
            </FormControl>
            <TextFieldShrinkWrapper
                label={t("request-files:message")}
                value={values.messageText}
                fullWidth={true}
                multiline
                maxRows={4}
                {...register("messageText")}
                maxLength={65000}
            />
            <Grid container spacing={4} className={styles.buttonContainer}>
                <Grid item xs={6}>
                    <Button
                        color="secondary"
                        className={styles.button}
                        onClick={() => {
                            dispatch(
                                openDialog(requestFilePreviewFormModalWindow),
                            );
                        }}
                    >
                        {t("request-files:preview")}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Tooltip
                        disableHoverListener={!tooltipText}
                        disableInteractive
                        title={tooltipText}
                        className={styles.tooltip}
                    >
                        <span>
                            <Button
                                disabled={!!tooltipText}
                                color="primary"
                                type="submit"
                                className={styles.button}
                            >
                                {t("request-files:request")}
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
            {dialogName === requestFilePreviewFormModalWindow && (
                <EmailPreviewWindow
                    sender={user && user.email ? user.email : ""}
                    messagePrefix={t("request-files:messagePrefix")}
                    retentionPolicy={t("request-files:retentionPolicy", {
                        time: `${values.daysToLiveRequest - 1} ${t(
                            pluralize(
                                values.daysToLiveRequest - 1,
                                WordTranslationKey.DAY,
                                i18n.languages[0].toUpperCase(),
                            ),
                        )}
                       ${11} ${t(
                           pluralize(
                               11,
                               WordTranslationKey.HOUR,
                               i18n.languages[0].toUpperCase(),
                           ),
                       )}
                       ${59} ${t(
                           pluralize(
                               59,
                               WordTranslationKey.MINUTE,
                               i18n.languages[0].toUpperCase(),
                           ),
                       )}`,
                    })}
                    messageText={values.messageText}
                />
            )}
        </form>
    );
};
