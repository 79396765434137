import { createAsyncThunk } from "@reduxjs/toolkit";
import { settingsApiService } from "src/services/clientApi";
import { LogFileSettings } from "src/services/generated";

export const getSettingsLogFile = createAsyncThunk(
    "getSettingsLogFile",
    async (args, thankAPI) => {
        return (await settingsApiService.getLogFileSettings()).data;
    },
);

export const updateSettingsLogFile = createAsyncThunk<
    LogFileSettings,
    LogFileSettings
>("updateSettingsLogFile", async data => {
    return (await settingsApiService.updateLogFileSettings(data)).data;
});
