import copy from "copy-to-clipboard";
import i18n from "i18next";
import { enqueueSuccess } from "./enqueueError";

export function copyText(text: string) {
    copy(text);
    enqueueSuccess(i18n.t("my-file:snackbar.successCopy") as string, 4000);
}

export function copyElementContentById(id: string) {
    let element = document.getElementById(id) as HTMLInputElement;
    if (element) {
        copy(element.value);
        enqueueSuccess(i18n.t("my-file:snackbar.successCopy"), 4000);
    }
}
