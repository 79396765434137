import { PaletteOptions } from "@mui/material/styles/createPalette";
import { color } from "./variables/color";

export const palette: PaletteOptions = {
    primary: {
        main: color.primary.main,
        dark: color.primary.dark,
        light: color.primary.light,
    },
    text: {
        primary: color.sendFileColor.onSecondary.text,
        secondary: color.sendFileColor.onSecondary.text,
    },
};

export default palette;
