import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { CommonAdminForm } from "src/components/Admin/commonAdminForm";
import { useForm } from "src/components/Form";
import { Preloader } from "src/components/Preloader";
import { LicenseInfoDTO } from "src/services/generated";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
    getLicenseSettings,
    updateLicenseSettings,
} from "src/redux/pages/administrationSettings/license/actions";
import { Store } from "src/types/redux/store/index";
import { LicenseSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { LicenseAlert } from "src/components/LicenseAlert";
import moment from "moment";
import { commonDateFormat } from "src/constants/momentFormats";

const useStyles = makeStyles(() => ({
    wrapper: {
        position: "relative",
        maxWidth: 671,
    },
    textField: {
        "& textarea": {
            fontFamily: "monospace",
        },
    },
    licenseHasExpired: {
        color: "rgba(255, 0, 0, 1)",
    },
    default: {},
}));

export const Licenses = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loading, form } = useSelector<Store, LicenseSettingsPage>(
        store =>
            store.pages.administration.administrationSettings
                .licenseSettingsPage,
    );
    const styles = useStyles();

    const { register, handleSubmit, reset } = useForm<LicenseInfoDTO>({
        defaultValues: form,
    });

    useEffect(() => {
        dispatch(getLicenseSettings());
    }, [dispatch]);

    useEffect(() => {
        reset(form);
    }, [reset, form]);
    const licenseTermDays = useMemo(() => {
        const licenseTerm = moment(form?.validity);
        return licenseTerm.diff(moment(new Date()), "days") + 1;
    }, [form?.validity]);

    const onSubmit = (data: LicenseInfoDTO) => {
        dispatch(updateLicenseSettings({ license: data.license }));
    };

    return (
        <CommonAdminForm
            className={styles.wrapper}
            onSubmit={handleSubmit(onSubmit)}
        >
            {loading && <Preloader over size={40} />}
            <TextField
                InputLabelProps={{ shrink: true }}
                className={styles.textField}
                label={t("admin-settings:license.key")}
                fullWidth={true}
                multiline
                minRows={4}
                maxRows={8}
                {...register("license")}
            />
            <div>
                {t("admin-settings:license.licenseCount")}{" "}
                {form?.totalUserCount ?? ""}
            </div>
            <div>
                {t("admin-settings:license.freeLicenseCount")}{" "}
                {form?.freeUserCount ?? ""}
            </div>
            <div
                className={
                    licenseTermDays < 1
                        ? styles.licenseHasExpired
                        : styles.default
                }
            >
                {t("admin-settings:license.validTerm")}{" "}
                {moment(form?.validity).format(commonDateFormat()) ?? ""}
            </div>
            {licenseTermDays < 31 ? (
                <LicenseAlert licenseTermDays={licenseTermDays}></LicenseAlert>
            ) : null}
        </CommonAdminForm>
    );
};
