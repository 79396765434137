import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { TechSupportPageStates } from "src/types/redux/store/pages/TechSupportPage";
import { changeTechSupportPageState } from "src/redux/pages/techSupport/action";

const useStyle = makeStyles({
    title: {
        textAlign: "center",
        fontSize: 16,
        fontFamily: "Roboto",
    },
    button: {
        marginTop: "40px",
        width: 200,
        "@media screen and (max-width: 480px)": {
            width: "100%",
        },
    },
});

export const AfterTechSupportMessageSending = () => {
    const styles = useStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const sendMore = () => {
        dispatch(
            changeTechSupportPageState(TechSupportPageStates.MESSAGE_CREATING),
        );
    };

    useEffect(() => {
        return () => {
            sendMore();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h2 className={styles.title}>{t("support:sentMessage")}</h2>
            <div>
                <Button
                    className={styles.button}
                    color="primary"
                    onClick={sendMore}
                >
                    {t("support:sendMore")}
                </Button>
            </div>
        </div>
    );
};
