import React, { MouseEvent } from "react";
import { IconButton, Menu, MenuItem, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStules = makeStyles((theme: Theme) => ({
    menuItem: {
        pointerEvents: "unset !important" as any,
    },
    anchor: {
        "& svg": {
            fill: "#1a1f29",
        },
    },
}));

interface DropDownMenuProps {
    context?: { id?: string | number };
    items: DropDownMenuItem<any>[];
    anchorElement: React.ElementType;
    ref?: React.Ref<unknown>;
}

export interface DropDownMenuItem<T = { id?: string | number }> {
    title: string;
    onClick?: (event: React.MouseEvent, context?: T) => void;
    itemId?: string;
    isDisabled?: (id: number, context?: T) => boolean;
    hint?: (id: number, context?: T) => string | void;
    isHide?: (context?: T) => boolean;
}

export const DropDownMenu = (props: DropDownMenuProps) => {
    const { items, anchorElement, context } = props;
    const [anchorEl, setAnchorEl] = React.useState<Element | null>();

    const styles = useStules();

    const handleClick = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getAction = (item: DropDownMenuItem) => {
        return (event: React.MouseEvent) => {
            item.onClick!(event, context);
            handleClose();
        };
    };

    return (
        <React.Fragment>
            <IconButton
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
                className={styles.anchor}
            >
                {React.createElement(anchorElement)}
            </IconButton>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {items.map((item, index) => {
                    return !item.isHide?.(context) ? (
                        <Tooltip
                            key={item.itemId ?? item.title}
                            title={item.hint?.(index, context) || ""}
                        >
                            <MenuItem
                                className={styles.menuItem}
                                key={index}
                                data-id={item.itemId}
                                onClick={event =>
                                    !item.isDisabled?.(index, context) &&
                                    getAction(item)(event)
                                }
                                disabled={item.isDisabled?.(index, context)}
                            >
                                {item.title}
                            </MenuItem>
                        </Tooltip>
                    ) : null;
                })}
            </Menu>
        </React.Fragment>
    );
};
