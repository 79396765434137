import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { Store } from "src/types/redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
    createStorage,
    disposeEditingStorage,
    disposeError,
    editStorage,
} from "src/redux/pages/administrationStorages/actions";
import { closeDialog } from "src/redux/dialogs/commonDialog/actions";
import { useForm } from "src/components/Form";
import { Preloader } from "src/components/Preloader";
import { LocalFileStorageDTO } from "src/services/generated";
import { gbMultiplier } from "src/constants/fileSizeMultiplier";
import { ClosingDialogTitle } from "../СlosingDialogTitle";

const useStyle = makeStyles(() =>
    createStyles({
        marginTop20: {
            marginTop: 20,
        },
        marginLeft16: {
            marginLeft: 16,
        },
        selectStorageType: {
            width: "100%",
        },
        totalSizeInput: {
            marginTop: 4,
        },
        pathTextField: {
            "& input": {
                paddingLeft: 0,
            },
        },
        adornmentSelect: {
            "&::before": {
                borderBottom: "none",
                borderBottomStyle: "none !important",
            },

            "&::after": {
                borderBottom: "none",
                borderBottomStyle: "none !important",
            },

            "& .MuiSelect-select": {
                opacity: 0.6,
                paddingRight: "0 !important",
                paddingBottom: "6px",
            },
        },
        adornmentFormControl: {
            paddingBottom: 0,
            flexShrink: 0,
            width: "auto",
            "& .MuiSelect-icon": {
                display: "none",
            },
        },
        iconClose: {
            width: "24px",
            height: "24px",
        },
    }),
);

const StyledDialog = withStyles({
    paper: {
        width: "368px",
        textAlign: "left",
    },
})(Dialog);

const EditOrCreateStorage: React.FC = () => {
    const styles = useStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { editingStorage, pending, newStorageSettings } = useSelector(
        (store: Store) => store.pages.administration.administrationStoragesPage,
    );
    const {
        register,
        handleSubmit,
        watch,
        formState,
        setError,
        errors,
        clearErrors,
        setValue,
    } = useForm<LocalFileStorageDTO>({
        defaultValues: {
            path: editingStorage?.path || "",
            size: editingStorage?.size
                ? editingStorage.size / gbMultiplier
                : undefined,
        },
        shouldUnregister: false,
    });
    const [basePath, setBasePath] = useState<string>(
        newStorageSettings?.basePaths?.[0] || "",
    );

    const handleClose = () => {
        dispatch(closeDialog("EditStorageWindow"));
        dispatch(disposeError());
        dispatch(disposeEditingStorage());
    };

    useEffect(() => {
        !basePath && setBasePath(newStorageSettings?.basePaths?.[0] || "");
    }, [newStorageSettings, basePath]);

    const onSubmit = (data: LocalFileStorageDTO) => {
        const { path, size } = data;
        if (editingStorage) {
            dispatch(
                editStorage({
                    ...editingStorage,
                    path,
                    basePath,
                    size: size * gbMultiplier,
                }),
            );
        } else {
            dispatch(
                createStorage({
                    path,
                    size: size * gbMultiplier,
                    basePath: basePath,
                }),
            );
        }
    };

    const onSelectChange = (e: any) => {
        setBasePath(e.target.value);
    };

    const { size, path } = watch();

    const invalidSize = !(size > 0);
    const invalidPath = !path;
    const showPathError = formState.touchedFields.path && invalidPath;
    const disableSave = invalidSize || invalidPath;

    useEffect(() => {
        clearErrors("size");
        if (size && (!Boolean(Number(size)) || Number(size) <= 0)) {
            setError("size", {
                message: t("admin-storages:errors.requireSize.errorSize"),
            });
        }
        if (size && (size as unknown as string)?.indexOf?.(",") >= 0) {
            setError("size", {
                message: t("admin-storages:errors.requireSize.errorSymbolSize"),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setError, size, clearErrors]);

    return (
        <StyledDialog open={true} onClose={handleClose}>
            {pending && <Preloader over />}
            <ClosingDialogTitle onClose={handleClose}>
                {editingStorage
                    ? t(`admin-storages:modals.edit`)
                    : t(`admin-storages:modals.create`)}
            </ClosingDialogTitle>
            <DialogContent>
                <TextField
                    className={styles.pathTextField}
                    label={t("admin-storages:modals.path")}
                    InputProps={{
                        startAdornment: (
                            <FormControl
                                className={styles.adornmentFormControl}
                            >
                                <Select
                                    renderValue={value => <div>{value}</div>}
                                    className={styles.adornmentSelect}
                                    value={basePath}
                                    onChange={onSelectChange}
                                >
                                    {newStorageSettings?.basePaths?.map(
                                        basePath => (
                                            <MenuItem
                                                key={basePath}
                                                value={basePath}
                                            >
                                                {basePath}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>
                        ),
                    }}
                    defaultValue={editingStorage ? path : ""}
                    onChange={e => {
                        setValue("path", e.target.value);
                    }}
                    error={showPathError}
                    helperText={
                        showPathError && t("admin-storages:errors.requirePath")
                    }
                />
                <TextField
                    label={t("admin-storages:modals.space")}
                    {...register("size")}
                    error={!!errors["size"]}
                    inputProps={{ autoComplete: "off" }}
                    helperText={!!errors["size"] ? errors.size.message : ""}
                />
            </DialogContent>

            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <Button onClick={handleClose}>
                            {t("admin-storages:modals.cancel")}
                        </Button>
                    </Grid>
                    <Grid item className={styles.marginLeft16}>
                        <Button
                            color="primary"
                            disabled={disableSave}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t("admin-storages:modals.save")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </StyledDialog>
    );
};

export default EditOrCreateStorage;
