import React, { useState } from "react";
import { ButtonGroupToggle } from "src/components/ButtonGroupToggle";
import { Box, Grid, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { ButtonToggleType } from "src/types/TabType";
import { useTranslation } from "react-i18next";
import { AdministrationFilesReportsPage } from "./reports/Files";
import { AdministrationUsersReportsPage } from "./reports/Users";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        height100: {
            height: "calc(100% - 64px)",
        },
        buttonGroup: {
            width: "350px",
            marginBottom: "24px",
        },
    }),
);

enum Tabs {
    FILES = "FILES",
    USERS = "USERS",
}
export const AdministrationReportsPage = () => {
    const styles = useStyles();
    const { t } = useTranslation("admin-reports");

    const [tabId, setTabId] = useState(Tabs.FILES);

    const handleFileFilterChange = (value: Tabs) => {
        setTabId(value);
    };

    const buttons: ButtonToggleType[] = [
        {
            label: t("tabs.files"),
            value: Tabs.FILES,
        },
        {
            label: t("tabs.users"),
            value: Tabs.USERS,
        },
    ];
    return (
        <>
            <Box>
                <ButtonGroupToggle
                    onChange={handleFileFilterChange}
                    buttons={buttons}
                    value={tabId}
                    className={styles.buttonGroup}
                />
            </Box>
            <Grid
                minWidth={0}
                container
                wrap="nowrap"
                className={styles.height100}
            >
                {tabId === Tabs.USERS ? (
                    <AdministrationUsersReportsPage />
                ) : (
                    <AdministrationFilesReportsPage />
                )}
            </Grid>
        </>
    );
};
