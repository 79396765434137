import React from "react";
import classNames from "classnames";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    tableRow: {
        "&:not(:first-child)": {
            marginTop: "8px",
        },
    },
    rowTitle: {
        fontWeight: 500,
        opacity: "80%",
    },
});

type SmallTableRowProps = {
    title: string;
    value: React.ReactNode;
    className?: {
        title?: string;
        value?: string;
        root?: string;
    };
};

export const SmallTableRow = ({
    title,
    value,
    className,
}: SmallTableRowProps) => {
    const styles = useStyles();

    return (
        <Grid
            container
            wrap="nowrap"
            columnSpacing={4}
            className={classNames(className?.root, styles.tableRow)}
        >
            <Grid item xs={6} sm={4}>
                <Typography
                    component={"div"}
                    className={classNames(className?.title, styles.rowTitle)}
                >
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={6} sm={8}>
                <Typography
                    component={"div"}
                    className={className?.value}
                    noWrap
                >
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
};
