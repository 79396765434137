import React from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../types/redux/store";
import { closeDialog } from "../../redux/dialogs/commonDialog/actions";
import { withStyles } from "@mui/styles";
import { EmailPreview } from "./EmailPreview";

export const sendFilePreviewFormModalWindow =
    "SEND_FILE_PREVIEW_FORM_MODAL_WINDOW";

const StyledDialog = withStyles({
    paperWidthSm: {
        width: "672px",
        fontFamily: "Roboto",
    },
})(Dialog);

type Props = {
    messagePrefix?: string;
    sender?: string;
    messageText?: string;
    retentionPolicy?: string;
};

export const EmailPreviewWindow = (props: Props) => {
    const dispatch = useDispatch();
    const { isOpen, dialogName } = useSelector(
        (store: Store) => store.dialogs.commonDialog.dialog,
    );

    const closeWindow = () => {
        dispatch(closeDialog(dialogName));
    };

    return (
        <StyledDialog open={isOpen}>
            <EmailPreview {...props} onClose={closeWindow} />
        </StyledDialog>
    );
};
