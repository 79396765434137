import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { messageApi, adminFilesApi } from "src/services/clientApi";
import { MessagePublicModelRequestParams } from "src/types/redux/store/pages/DownloadPage";

type getMessageForDownloadingParams = {
    isPublic?: boolean;
} & MessagePublicModelRequestParams;

export const getMessageForDownloading = createAsyncThunk(
    "getMessageForDownloading",
    async (
        {
            messageId,
            password,
            isPublic = true,
        }: getMessageForDownloadingParams,
        thunkAPI,
    ) => {
        try {
            const result = isPublic
                ? await messageApi.getPublicMessageById(messageId, password)
                : await adminFilesApi.getMessageById(messageId);
            return result.data;
        } catch (error) {
            return thunkAPI.rejectWithValue((error as any).response.status);
        }
    },
);
export const resetMessage = createAction("resetMessage");
