import React, { FC } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "src/components/Form";
import { useTranslation } from "react-i18next";
import { pluralize, WordTranslationKey } from "../../helpers/wordCasesUtil";
import { DAYS_TO_EXPIRE } from "src/constants/sendFiles";

type FilesRequestRetentionPeriodType = {
    control: any;
};

export const FilesRequestRetentionPeriod: FC<
    FilesRequestRetentionPeriodType
> = props => {
    const { control } = props;
    const { t, i18n } = useTranslation();
    const locale = i18n.languages && i18n.languages[0];

    return (
        <>
            <FormControl>
                <InputLabel>
                    {t("request-files:fileIsAvailableForDownloadingLabel")}
                </InputLabel>
                <Controller
                    name="daysToLiveFiles"
                    render={({ field: { value, onChange } }) => (
                        <Select onChange={onChange} value={value}>
                            {DAYS_TO_EXPIRE.map((count, index) => (
                                <MenuItem key={index} value={count}>
                                    {`${count} ${t(
                                        pluralize(
                                            count,
                                            WordTranslationKey.DAY,
                                            locale,
                                        ),
                                    )}`}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    control={control}
                />
            </FormControl>
            <FormControl>
                <InputLabel>
                    {t("request-files:requestIsAvailableLabel")}
                </InputLabel>
                <Controller
                    name="daysToLiveRequest"
                    render={({ field: { value, onChange } }) => (
                        <Select onChange={onChange} value={value}>
                            {DAYS_TO_EXPIRE.map((count, index) => (
                                <MenuItem key={index} value={count}>
                                    {`${count} ${t(
                                        pluralize(
                                            count,
                                            WordTranslationKey.DAY,
                                            locale,
                                        ),
                                    )}`}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    control={control}
                />
            </FormControl>
        </>
    );
};
