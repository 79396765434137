import React from "react";
import { ToggleButtonGroup, ToggleButton, Theme } from "@mui/material";
import { ButtonToggleType } from "src/types/TabType";
import { useTranslation } from "react-i18next";
import { withStyles } from "@mui/styles";

interface ButtonGroupSwitchersProps {
    buttons: ButtonToggleType[];
    onChange: (value: any) => void;
    value: any;
    className?: string;
}

const StyledToggleButton = withStyles((theme: Theme) => {
    return {
        root: {
            ...(theme.components.MuiButton?.styleOverrides
                .containedSecondary as any),
            "&:not(first-child)": {
                borderLeft: `1px solid ${theme.palette.primary.main} !important`,
                marginLeft: "0 !important",
            },
            marginLeft: 0,
            "&.Mui-selected": {
                ...(theme.components?.MuiButton?.styleOverrides
                    ?.containedPrimary as any),
                "&:hover": {
                    cursor: "default",
                    backgroundColor: (
                        theme.components?.MuiButton?.styleOverrides
                            ?.containedPrimary as any
                    )?.backgroundColor,
                },
                "&:active": {},
            },
            "&:not(:last-child)": {
                borderRight: "none",
            },
            height: "36px",
            boxShadow: "none !important",
            textTransform: "none",
            fontWeight: 400,
            letterSpacing: "normal",
            fontSize: "14px",
        },
    };
})(ToggleButton);

export const ButtonGroupToggle = (props: ButtonGroupSwitchersProps) => {
    const { onChange, buttons, value, className = "" } = props;
    const { t } = useTranslation();

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newValue: any,
    ) => {
        if (newValue) onChange(newValue);
    };

    return (
        <ToggleButtonGroup
            exclusive={true}
            className={className}
            value={value}
            onChange={handleChange}
        >
            {buttons.map(button => {
                const flexBasis = (100 / buttons.length).toFixed(1);
                return (
                    <StyledToggleButton
                        sx={{
                            flexBasis: `${flexBasis}%`,
                            maxWidth: `${flexBasis}%`,
                        }}
                        value={button.value}
                        key={button.value}
                    >
                        {t(button.label)}
                    </StyledToggleButton>
                );
            })}
        </ToggleButtonGroup>
    );
};
