import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { TextField, Autocomplete, Tooltip } from "@mui/material";
import Clear from "@mui/icons-material/Clear";
import { isValidEmail } from "src/helpers/email";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    root: {
        height: "24px",
    },
    errorRoot: {
        border: "2px solid #D32F2F",
        background: "#ffe5e5",
    },
    label: {
        padding: "6px 8px 6px 12px",
    },
    errorDeleteIcon: {
        color: "#D32F2F",
    },
});

type Props = {
    defaultValue?: string[];
    options: string[];
    inputLabel: string;
    isValid?: (valid: boolean) => void;
    required?: boolean;
    name?: string;
    onChange: (event: React.ChangeEvent<object>, value: string[]) => void;
};

export const AutocompleteForEmail = ({
    defaultValue,
    options,
    inputLabel,
    onChange,
    isValid,
    required,
    name,
}: Props) => {
    const { t } = useTranslation();
    const [chips, setChips] = useState<string[]>(defaultValue || []);
    const [input, setInput] = useState("");
    const [editValue, setEditValue] = useState("");
    const styles = useStyles();

    useEffect(() => {
        if (editValue) {
            setInput(editValue);
            setEditValue("");
        } else {
            setInput(input);
        }
    }, [chips, setInput, setChips, editValue, input]);

    const editChip = (index: number) => {
        const copy = [...chips];
        const [editValue] = copy.splice(index, 1);
        const trimmedInput = input.trim();
        if (trimmedInput) {
            setChips([...copy, trimmedInput]);
        } else {
            setChips(copy);
        }
        setEditValue(editValue);
    };
    return (
        <Autocomplete
            value={chips}
            defaultValue={chips}
            multiple
            id="tags-filled"
            options={options as any} // todo:migration check type
            freeSolo
            renderTags={
                ((value: string[], getTagProps: any) =>
                    value.map((option: string, index) => {
                        return (
                            <Tooltip
                                key={index}
                                title={
                                    isValidEmail(option)
                                        ? ""
                                        : t("send-file:tooltipText.errorEmail")
                                }
                            >
                                <Chip
                                    label={option}
                                    variant="outlined"
                                    classes={{
                                        root: `${styles.root} ${
                                            !isValidEmail(option) &&
                                            styles.errorRoot
                                        }`,
                                        label: styles.label,
                                        deleteIcon: isValidEmail(option)
                                            ? undefined
                                            : styles.errorDeleteIcon,
                                    }}
                                    onClick={(e: any) => editChip(index)}
                                    onDelete={(e: any) => {
                                        setChips([...chips, input]);
                                    }}
                                    {...getTagProps({ index })}
                                />
                            </Tooltip>
                        );
                    })) as any
            }
            autoSelect
            renderInput={params => (
                <TextField
                    name={name}
                    {...params}
                    required={required}
                    label={inputLabel}
                />
            )}
            inputValue={input}
            onInputChange={(event, value, reason) => {
                setInput(value);
            }}
            clearIcon={<Clear />}
            onChange={(event, value) => {
                const updated = value
                    .flatMap((el: any) => el.split(/\s|;/))
                    .map(s => s.trim())
                    .filter(s => s !== "");
                setChips(updated);
                onChange(event, updated);
                if (isValid) {
                    const valid =
                        updated.length > 0 && updated.every(isValidEmail);
                    isValid(valid);
                }
            }}
        />
    );
};
