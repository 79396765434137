import { createAsyncThunk } from "@reduxjs/toolkit";
import { statisticsApi } from "src/services/clientApi";
import { StatsFileSummaryDTO } from "src/services/generated";

export interface FileStatsRequestParams {
    startDate: string;
    endDate: string;
}

export const receiveStats = createAsyncThunk("receiveStats", async () => {
    return (await statisticsApi.getSummary()).data;
});

export const receiveFileStats = createAsyncThunk(
    "receiveFileStats",
    async (params: FileStatsRequestParams) => {
        return (
            await statisticsApi.getFileSummary(params.startDate, params.endDate)
        ).data;
    },
);
export const resetPeriod = createAsyncThunk("resetPeriod", async () => {
    return { uploadsCount: 0, uploadsSize: 0 } as StatsFileSummaryDTO;
});
