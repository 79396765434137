import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { DownloadPage } from "src/types/redux/store/pages/DownloadPage";
import { getMessageForDownloading, resetMessage } from "./actions";
import { MessagePublicModel } from "../../../services/generated";
import { FileViewModel } from "src/types/FileDisplay";
import _ from "lodash";
import { MetaAction } from "src/types/redux/PayloadAction";

const initialState: DownloadPage = {
    downloadMessage: {},
    inProgress: false,
    error: false,
    forbidden: false,
};

export const downloadPageReducer = createReducer<DownloadPage>(initialState, {
    [getMessageForDownloading.pending.type](
        state,
        action: MetaAction<{ messageId: string; password?: string }>,
    ) {
        state.password = action.meta.arg.password;
        state.inProgress = true;
    },
    [getMessageForDownloading.fulfilled.type](
        state,
        action: PayloadAction<MessagePublicModel>,
    ) {
        const newFiles = action.payload.files;
        const oldFiles = state.downloadMessage.files;
        if (!Array.isArray(oldFiles)) {
            state.downloadMessage = action.payload;
        } else {
            const mapped: FileViewModel[] = [];

            oldFiles.forEach(oldFile => {
                const fileToReplace = newFiles?.find(
                    newFile => newFile.id === oldFile.id,
                );
                if (fileToReplace) {
                    mapped.push(fileToReplace);
                }
            });
            mapped.push(..._.differenceBy(newFiles, oldFiles, el => el.id));

            state.downloadMessage.files = mapped;
        }
        state.inProgress = false;
        state.error = false;
        state.forbidden = false;
    },
    [getMessageForDownloading.rejected.type](
        state,
        action: PayloadAction<number | Error>,
    ) {
        const status = action.payload;
        state.inProgress = false;

        if (status === 403) {
            state.forbidden = true;
        } else {
            state.error = true;
        }
    },
    [resetMessage.type](state) {
        state.downloadMessage = initialState.downloadMessage;
        state.error = false;
        state.forbidden = false;
        state.inProgress = false;
    },
});
