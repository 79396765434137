import * as React from "react";
import {
    SelectProps,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    MenuItem,
    IconButton,
} from "@mui/material";
import { Controller } from "../FieldController";
import { UseControllerProps } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";

type FieldSelectProps<T> = {
    name: string;
    label: string;
    className?: string;
    options: { label: string; value: string | number }[];
    controllerProps?: Omit<UseControllerProps, "name">;
    clearHidden?: boolean;
} & SelectProps<T>;

export const FieldSelect = <T,>({
    name,
    label,
    className,
    options,
    controllerProps,
    clearHidden = false,
    ...rest
}: FieldSelectProps<T>) => {
    const handleChange = (
        event: SelectChangeEvent,
        onChange: (v: unknown) => void,
    ) => {
        const {
            target: { value },
        } = event;
        onChange(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value,
        );
    };

    return (
        <Controller name={name} className={className} {...controllerProps}>
            {({ field: { onChange, value }, formState }) => {
                return (
                    <FormControl>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            sx={{
                                "& .MuiSelect-icon": {
                                    display:
                                        value && !clearHidden ? "none" : "",
                                },
                            }}
                            endAdornment={
                                value && !clearHidden ? (
                                    <IconButton
                                        sx={{
                                            visibility:
                                                value && !clearHidden
                                                    ? "visible"
                                                    : "hidden",
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        }}
                                        onClick={() => {
                                            onChange(
                                                formState.defaultValues[name],
                                            );
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                ) : null
                            }
                            // @ts-ignore
                            value={
                                rest.multiple && Array.isArray(value)
                                    ? value
                                    : value
                                      ? [value]
                                      : []
                            }
                            // @ts-ignore
                            onChange={e => handleChange(e, onChange)}
                            {...rest}
                        >
                            {options.map(o => (
                                <MenuItem key={o.value} value={o.value}>
                                    {o.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }}
        </Controller>
    );
};
