import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AboutPage } from "./pages/AboutPage";
import { TechSupport } from "./pages/TechSupport";
import { LoginPage } from "./pages/LoginPage";
import { PrivateRoute } from "./routes/PrivateRoute";
import { Footer } from "./components/Footer";
import { SendFile } from "src/pages/SendFile";
import { MyFiles } from "src/pages/MyFiles";
import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { routePaths } from "./routes/routePaths";
import { AdministrationStatisticsPage } from "./pages/Admin/AdministrationStatisticsPage";
import { AdministrationUsersPage } from "./pages/Admin/AdministrationUsersPage";
import { AdministrationReportsPage } from "./pages/Admin/AdministrationReportsPage";
import { AdministrationFilesPage } from "./pages/Admin/AdministrationFilesPage";
import { AdministrationStoragesPage } from "./pages/Admin/AdministrationStoragesPage";
import { ManagePageTemplate, Page } from "./pages/ManagePageTemplate";
import { ErrorCodes, ErrorPage } from "./pages/ErrorPage/ErrorPage";
import {
    changeSendFilePageState,
    deleteMessage,
} from "./redux/pages/sendFile/actions";
import { SendFilePageStates } from "./types/redux/store/pages/SendFilePage";
import { DownloadPage } from "./pages/DownloadPage";
import { FilesRequestPage } from "./pages/FilesRequestPage";
import { ResponseMessagePage } from "./pages/ResponseMessagePage";
import { Header } from "./components/Header";
import { GeneralSettings } from "./pages/Admin/settings/GeneralSettings";
import { MailHeadersSettings } from "./pages/Admin/settings/MailHeadersSettings";
import { Licenses } from "./pages/Admin/settings/Licenses";
import { Deactivation } from "./pages/Admin/settings/Deactivation";
import { Ldap } from "./pages/Admin/settings/Ldap";
import { Email } from "./pages/Admin/settings/Email";
import { CommonAdminForm } from "./components/Admin/commonAdminForm";
import {
    changeFilesRequestPageState,
    changeRequestType,
} from "./redux/pages/filesRequest/actions";
import {
    FilesRequestPageState,
    FilesRequestType,
} from "./types/redux/store/pages/FilesRequestPage";
import { ResponseMessageDownloadPage } from "./pages/ResponseMessageDownloadPage";
import { UserModelRolesEnum } from "./services/generated";
import { ExtendMessage } from "./pages/Admin/settings/ExtendMessage";
import {
    loginViaRefreshToken,
    validateAccessToken,
} from "./redux/pages/login/action";
import { Store } from "./types/redux/store";
import { LogoSettings } from "./pages/Admin/settings/LogoSettings";
import { getLogoUrl } from "./redux/logo/actions";
import { StorageSettings } from "./pages/Admin/settings/StorageSettings";
import AdministrationLogFilesPage from "./pages/Admin/AdministrationLogFilesPage";
import { LogFilesSettings } from "./pages/Admin/settings/LogFilesSettings";
import { LicenseExpiredMessage } from "./pages/Admin/settings/LicenseExpiredMessage";
import { getPublicSettings } from "./redux/pages/applicationSettings/actions";
import { DeletedMessage } from "./pages/Admin/settings/DeletedMessage";
import { getVersionInfo } from "./redux/version/actions";
import { useNetwork } from "./hooks/useNetwork";
import { ErrorBoundary } from "react-error-boundary";
import { Preloader } from "./components/Preloader";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        app: {
            minHeight: "100vh",
            background: "#FAFAFC",
        },
        pageContent: {
            height: "calc(100vh - 128px)",
            overflow: "auto",
            [theme.breakpoints.down("xs")]: {
                height: "calc(100vh - 72px)",
            },
        },
    }),
);

const adminPages: Page[] = [
    {
        name: "admin:pages.statistics",
        url: routePaths.administrationTemplate.statistics,
        component: AdministrationStatisticsPage,
    },
    {
        name: "admin:pages.users",
        url: routePaths.administrationTemplate.users,
        component: AdministrationUsersPage,
    },
    {
        name: "admin:pages.reports",
        url: routePaths.administrationTemplate.reports,
        component: AdministrationReportsPage,
    },
    {
        name: "admin:pages.files",
        url: routePaths.administrationTemplate.files,
        component: AdministrationFilesPage,
    },
    {
        name: "admin:pages.storages",
        url: routePaths.administrationTemplate.storages,
        component: AdministrationStoragesPage,
    },
    {
        name: "admin:pages.logFiles",
        url: routePaths.administrationTemplate.logFiles,
        component: AdministrationLogFilesPage,
    },
    {
        name: "admin:pages.settings",
        url: "",
        component: CommonAdminForm,
        accordion: [
            {
                name: "admin:pages.settingsMenu.general",
                component: GeneralSettings,
                route: routePaths.administrationTemplate.settings.general,
            },
            {
                name: "admin:pages.settingsMenu.logo",
                component: LogoSettings,
                route: routePaths.administrationTemplate.settings.logo,
            },
            {
                name: "admin:pages.settingsMenu.storage",
                component: StorageSettings,
                route: routePaths.administrationTemplate.settings.storage,
            },
            {
                name: "admin:pages.settingsMenu.ldap",
                component: Ldap,
                route: routePaths.administrationTemplate.settings.ldap,
            },
            {
                name: "admin:pages.settingsMenu.email",
                component: Email,
                route: routePaths.administrationTemplate.settings.email,
            },
            {
                name: "admin:pages.settingsMenu.texts",
                component: CommonAdminForm,
                route: "",
                accordion: [
                    {
                        name: "admin:pages.settingsMenu.textsMessages.emailMessage",
                        component: MailHeadersSettings,
                        route: routePaths.administrationTemplate.settings
                            .textsMessages.emailMessage,
                    },
                    {
                        name: "admin:pages.settingsMenu.textsMessages.extendMessage",
                        component: ExtendMessage,
                        route: routePaths.administrationTemplate.settings
                            .textsMessages.extendMessage,
                    },
                    {
                        name: "admin:pages.settingsMenu.textsMessages.deleteMessage",
                        component: DeletedMessage,
                        route: routePaths.administrationTemplate.settings
                            .textsMessages.deleteMessage,
                    },
                    {
                        name: "admin:pages.settingsMenu.textsMessages.licenseMessage",
                        component: LicenseExpiredMessage,
                        route: routePaths.administrationTemplate.settings
                            .textsMessages.licenseMessage,
                    },
                ],
            },
            {
                name: "admin:pages.settingsMenu.licences",
                component: Licenses,
                route: routePaths.administrationTemplate.settings.licences,
                license: true,
            },
            {
                name: "admin:pages.settingsMenu.logFiles",
                component: LogFilesSettings,
                route: routePaths.administrationTemplate.settings.logFiles,
            },
            {
                name: "admin:pages.settingsMenu.deactivation",
                component: Deactivation,
                route: routePaths.administrationTemplate.settings.deactivation,
            },
        ],
    },
];

const AppLayout = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const styles = useStyles();
    const isOnline = useNetwork();

    const { accessToken, user } = useSelector(
        (store: Store) => store.authenticationHandler.authentication,
    );
    const { isAuthenticated } = useSelector(
        (store: Store) => store.authenticationHandler,
    );
    useEffect(() => {
        dispatch(validateAccessToken(accessToken));
    }, [dispatch, accessToken]);

    useEffect(() => {
        dispatch(deleteMessage());
        dispatch(changeSendFilePageState(SendFilePageStates.MESSAGE_CREATING));
        dispatch(
            changeFilesRequestPageState(FilesRequestPageState.REQUEST_CREATING),
        );
        dispatch(changeRequestType(FilesRequestType.BY_LINK));
    }, [dispatch, location.pathname]);

    useEffect(() => {
        dispatch(getLogoUrl());
        dispatch(getPublicSettings());
        dispatch(getVersionInfo());
    }, [dispatch]);

    return (
        <div className={`${styles.app} App`}>
            <ErrorBoundary fallback={<ErrorPage />}>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route>
                        <Header />
                        <section className={styles.pageContent}>
                            {isOnline ? (
                                isAuthenticated ? (
                                    <Switch>
                                        <PrivateRoute path="/" exact={true}>
                                            <Redirect to="/sendFile" />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            path="/sendFile"
                                            component={SendFile}
                                        />
                                        <PrivateRoute
                                            path="/myFiles"
                                            component={MyFiles}
                                        />
                                        <PrivateRoute
                                            path="/requestFile"
                                            component={FilesRequestPage}
                                        />
                                        <PrivateRoute
                                            path="/administration"
                                            roles={[UserModelRolesEnum.ADMIN]}
                                        >
                                            <ManagePageTemplate
                                                pages={adminPages}
                                            />
                                        </PrivateRoute>
                                        <Route
                                            path="/about"
                                            component={AboutPage}
                                        />
                                        <PrivateRoute
                                            path="/support"
                                            component={TechSupport}
                                        />
                                        <Route
                                            path="/requestMessage/:requestMessageUUID"
                                            component={ResponseMessagePage}
                                        />
                                        <Route
                                            path="/download/:messageId"
                                            component={DownloadPage}
                                        />
                                        <PrivateRoute
                                            path="/responseMessage/:responseMessageUUID"
                                            component={
                                                ResponseMessageDownloadPage
                                            }
                                        />
                                        <Route path="/forbidden">
                                            <ErrorPage
                                                errorType={ErrorCodes.FORBIDDEN}
                                            />
                                        </Route>
                                        <Route path="*">
                                            <ErrorPage
                                                errorType={ErrorCodes.NOT_FOUND}
                                                isReturnHome
                                            />
                                        </Route>
                                    </Switch>
                                ) : (
                                    <Preloader over />
                                )
                            ) : (
                                <ErrorPage errorType={ErrorCodes.NETWORK} />
                            )}
                        </section>
                        <Footer />
                    </Route>
                </Switch>
            </ErrorBoundary>
        </div>
    );
};

export default AppLayout;
