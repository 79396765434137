import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { LdapSettingsDTO } from "src/services/generated";
import { LdapSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getSettingsLdap, updateSettingsLdap } from "./actions";

const generalSettingsInitialState: LdapSettingsPage = {
    loading: false,
};

export const ldapSettingsReducer = createReducer<LdapSettingsPage>(
    generalSettingsInitialState,
    {
        [getSettingsLdap.pending.type](state) {
            state.loading = true;
        },
        [getSettingsLdap.fulfilled.type](
            state,
            action: PayloadAction<LdapSettingsDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [getSettingsLdap.rejected.type](state) {
            state.loading = false;
        },
        [updateSettingsLdap.pending.type](state) {
            state.loading = true;
        },
        [updateSettingsLdap.fulfilled.type](state) {
            state.loading = false;
        },
        [updateSettingsLdap.rejected.type](state) {
            state.loading = false;
        },
    },
);
