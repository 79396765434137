import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { techSupportApi } from "src/services/clientApi";
import { TechSupportPageStates } from "src/types/redux/store/pages/TechSupportPage";

export const sendMessageForTechSupport = createAsyncThunk(
    "sendMessageForTechSupport",
    async (message: string, thunkAPI) => {
        thunkAPI.dispatch(
            changeTechSupportPageState(
                TechSupportPageStates.AFTER_MESSAGE_SENDING,
            ),
        );
        return (await techSupportApi.sendMessageToTechSupport({ message }))
            .data;
    },
);

export const changeTechSupportPageState = createAction<TechSupportPageStates>(
    "changeTechSupportPageState",
);
