import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FileDisplay from "../FileDisplay/FileDisplay";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMessageForDownloading } from "src/redux/pages/downloadPage/actions";
import _ from "lodash";
import { getSumSizeFiles, isTempFile } from "../../helpers/file";

import { Store } from "src/types/redux/store";
import { TimeToExpireInfo } from "./TimeToExpireInfo";
import { FileDTO } from "src/services/generated";
import {
    downloadFileByUser,
    downloadAllFileByUser,
    downloadFileByAdmin,
} from "src/helpers/download";
import { enqueueError } from "src/helpers/enqueueError";

const useStyle = makeStyles({
    headerText: {
        color: "#000000",
        fontSize: "16px",
        fontWeight: "bold",
        margin: "0 0 40px",
    },
    email: {
        color: "#4A90E2",
        textDecoration: "underline",
    },
    filesContainer: {
        display: "flex",
        flexDirection: "column",
    },
    expireInfo: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        margin: "16px 0px",
    },
    fileDownloadButton: {
        width: "200px",
    },
    warning: {
        boxShadow:
            "0px 1px 5px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.04)",
        borderRadius: "4px",
        marginBottom: "8px",
        backgroundColor: "#F5F1E3",
        border: "1px solid #FFCC00",
        padding: "16px",
        textAlign: "center",
    },
    timeWrapper: {
        marginTop: "24px",
    },
    footer: {
        position: "sticky",
        bottom: 0,
        background: "#FAFAFC",
        width: "100%",
        paddingBottom: "16px",
    },
    protectedInfo: {
        fontSize: "14px",
        fontWeight: "500",
        marginBottom: "16px",
    },
});

type DownloadFileViewProps = {
    messageId: string;
    password?: string;
    className?: string;
    hideTitle?: boolean;
    hideFooter?: boolean;
    isProtected?: boolean;
    isPublic?: boolean;
};

export const DownloadFileView = ({
    messageId,
    password,
    className,
    hideTitle = false,
    hideFooter = false,
    isProtected = false,
    isPublic = true,
}: DownloadFileViewProps) => {
    const styles = useStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { downloadMessage } = useSelector(
        (store: Store) => store.pages.downloadPage,
    );

    const download = async (file?: FileDTO) => {
        const isExpiredTime =
            new Date(downloadMessage.expireTime!) < new Date();
        const newFile: FileDTO = downloadMessage.files.find(
            (el: FileDTO) => file?.id === el.id,
        );
        const isLimitReachedAllFiles = !downloadMessage.files?.some(
            (el: FileDTO) => el.downloadCount! < downloadMessage.downloadLimit!,
        );

        if (
            file &&
            newFile.downloadCount! > file.downloadCount! &&
            newFile.downloadCount! >= downloadMessage.downloadLimit!
        ) {
            enqueueError(t("download-files:snackbar.errorOfFiles"));
            return;
        }
        if (isLimitReachedAllFiles) {
            enqueueError(t("download-files:snackbar.errorOfMessage"));
            return;
        }
        if (isExpiredTime) {
            return;
        }
        if (file) {
            isPublic
                ? await downloadFileByUser(messageId, file?.id, password)
                : await downloadFileByAdmin(
                      messageId,
                      file?.id,
                      file?.size,
                      file?.name,
                  );
        } else {
            const totalSize = getSumSizeFiles(downloadMessage.files);
            await downloadAllFileByUser(messageId, totalSize, password);
        }

        setTimeout(() => {
            dispatch(
                getMessageForDownloading({ messageId, password, isPublic }),
            );
        });
    };

    const isExpiredTime = new Date(downloadMessage.expireTime!) < new Date();

    const isEmptyFiles = downloadMessage?.files?.length === 0;
    const isAllSuccessFile =
        isEmptyFiles || !downloadMessage?.files?.every(isTempFile);
    const isLimitReachedAllFiles = downloadMessage.files?.some(
        file => file.downloadCount! < downloadMessage.downloadLimit!,
    );

    useEffect(() => {
        const dispatcher = _.debounce(() => {
            dispatch(
                getMessageForDownloading({ messageId, password, isPublic }),
            );
        }, 5000);
        if (!isAllSuccessFile) {
            dispatcher();
        }
    }, [
        dispatch,
        messageId,
        downloadMessage,
        isAllSuccessFile,
        password,
        isPublic,
    ]);

    return (
        <div className={className}>
            {!isAllSuccessFile && (
                <Box className={styles.warning}>
                    {t("download-files:warning.filesLoading")}
                </Box>
            )}
            {isEmptyFiles && (
                <Box className={styles.warning}>
                    {t("download-files:warning.filesUnavailable")}
                </Box>
            )}

            {!hideTitle && (
                <h1 className={styles.headerText}>
                    {downloadMessage.files?.length === 0
                        ? t("download-files:emptyMessage", {
                              username: downloadMessage.sender?.email,
                          })
                        : downloadMessage.files?.length === 1
                          ? t("download-files:downloadAvailableFile", {
                                username: downloadMessage.sender?.email,
                            })
                          : t("download-files:downloadAvailableFiles", {
                                username: downloadMessage.sender?.email,
                            })}
                </h1>
            )}
            {isProtected && (
                <Box className={styles.protectedInfo}>
                    {t("download-files:protected")}
                </Box>
            )}

            <div className={styles.filesContainer}>
                {downloadMessage.files?.map((file, index) => {
                    return (
                        <FileDisplay
                            key={index}
                            file={file}
                            downloadLimit={downloadMessage.downloadLimit}
                            isExpiredTime={isExpiredTime}
                            onClick={() => download(file)}
                        />
                    );
                })}
            </div>

            {!hideFooter && isLimitReachedAllFiles && (
                <Box className={styles.footer}>
                    <Box className={styles.timeWrapper}>
                        <TimeToExpireInfo
                            time={downloadMessage.expireTime!}
                            isExpiredTime={isExpiredTime}
                        />
                    </Box>
                    {downloadMessage.files?.length! > 1 && (
                        <Button
                            onClick={() => download()}
                            disabled={isExpiredTime}
                            className={styles.fileDownloadButton}
                            color="primary"
                        >
                            {t("download-files:downloadFile")}
                        </Button>
                    )}
                </Box>
            )}
        </div>
    );
};
