import React, { useEffect } from "react";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { Store } from "src/types/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "src/components/Preloader";
import { DownloadFileView } from "src/components/DownloadFiles/DownloadFilesPreview";
import {
    getMessageForDownloading,
    resetMessage,
} from "src/redux/pages/downloadPage/actions";
import { ResponseMessageDownloadView } from "src/components/DownloadFiles/ResponseMessageDownloadView";
import {
    getResponseMessageById,
    resetResponseMessage,
} from "src/redux/pages/responseMessageDownloadPage/actions";
import { TimeToExpireInfo } from "src/components/DownloadFiles/TimeToExpireInfo";
import { ErrorCodes, ErrorPage } from "src/pages/ErrorPage/ErrorPage";
import {
    downloadFilesAllByAdmin,
    downloadResponceAllFileByUser,
} from "src/helpers/download";
import { ClosingDialogTitle } from "../СlosingDialogTitle";
import { getSumSizeFiles } from "src/helpers/file";
import {
    ReportsFileDTO,
    ReportsFileDTOMessageTypeEnum,
} from "src/services/generated";

const useStyle = makeStyles(() =>
    createStyles({
        marginLeft16: {
            marginLeft: 16,
        },
        viewContent: {
            maxHeight: "640px",
            overflowY: "auto",
            marginBottom: "20px",
            textAlign: "left",
            paddingRight: "12px",
            "&::-webkit-scrollbar": {
                width: "10px",
                height: "0px",
            },
            "&::-webkit-scrollbar-track": {
                background: "rgba(219, 219, 219, 1)",
            },
            "&::-webkit-scrollbar-thumb": {
                width: "8px",
                height: "8px",
                background: "rgba(26, 31, 41, 0.38)",
                borderRadius: "8px",
            },
        },
        content: {
            width: "100%",
        },
    }),
);

const StyledDialog = withStyles({
    paper: {
        width: "600px",
        textAlign: "left",
    },
})(Dialog);

type FileReportMessageDetailProps = {
    reportFile: ReportsFileDTO;
    setReportFile: React.Dispatch<ReportsFileDTO>;
};

export const FileReportMessageDetail = ({
    reportFile,
    setReportFile,
}: FileReportMessageDetailProps) => {
    const styles = useStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        inProgress: inProgressSendMessage,
        downloadMessage,
        error,
        password,
    } = useSelector((store: Store) => store.pages.downloadPage);
    const { inProgress: inProgressResponseMessage, responseMessage } =
        useSelector((store: Store) => store.pages.responseMessageDownloadPage);

    useEffect(() => {
        if (reportFile?.messageType === "SEND") {
            dispatch(
                getMessageForDownloading({
                    messageId: reportFile.messageId,
                    password,
                    isPublic: false,
                }),
            );
        }
        if (reportFile?.messageType === "RESPONSE") {
            dispatch(
                getResponseMessageById({
                    messageId: reportFile.messageId,
                    isPublic: false,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, password, reportFile?.messageId]);

    const handleClose = () => {
        setReportFile(null);
        dispatch(resetMessage());
        dispatch(resetResponseMessage());
    };

    const handleSave = () => {
        setReportFile(null);
        let totalSize;
        switch (reportFile.messageType) {
            case ReportsFileDTOMessageTypeEnum.RESPONSE:
                totalSize = getSumSizeFiles(responseMessage.responseFiles);
                downloadResponceAllFileByUser(reportFile.messageId, totalSize);
                break;
            case ReportsFileDTOMessageTypeEnum.SEND:
                totalSize = getSumSizeFiles(downloadMessage.files);
                downloadFilesAllByAdmin(reportFile.messageId, totalSize);
        }

        dispatch(resetMessage());
        dispatch(resetResponseMessage());
    };

    const disabledButton =
        new Date(downloadMessage?.expireTime!) < new Date() ||
        error ||
        downloadMessage?.files?.length === 0 ||
        responseMessage?.responseFiles?.length === 0 ||
        inProgressSendMessage ||
        inProgressResponseMessage;
    return (
        <>
            {reportFile ? (
                <StyledDialog
                    open={true}
                    onClose={handleClose}
                    aria-labelledby="scroll-dialog-title"
                >
                    <ClosingDialogTitle onClose={handleClose}>
                        {t("admin-reports:messageDetail.title", {
                            email:
                                reportFile.emailCreatorLink ||
                                reportFile.senderEmail,
                        })}
                    </ClosingDialogTitle>
                    {inProgressSendMessage || inProgressResponseMessage ? (
                        <Preloader />
                    ) : (
                        <div className={styles.viewContent}>
                            {error ? (
                                <ErrorPage errorType={ErrorCodes.GENERAL} />
                            ) : (
                                <>
                                    {reportFile.messageType === "SEND" && (
                                        <DownloadFileView
                                            className={styles.content}
                                            messageId={reportFile.messageId}
                                            password={password}
                                            hideTitle
                                            hideFooter
                                            isProtected={
                                                downloadMessage?.protected
                                            }
                                            isPublic={false}
                                        />
                                    )}
                                    {reportFile.messageType === "RESPONSE" && (
                                        <ResponseMessageDownloadView
                                            responseMessage={responseMessage}
                                            messageId={reportFile.messageId}
                                            hideTitle
                                            hideFooter
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    {!error && downloadMessage.expireTime && (
                        <TimeToExpireInfo
                            time={downloadMessage.expireTime!}
                            isExpiredTime={
                                new Date(downloadMessage.expireTime!) <
                                new Date()
                            }
                        />
                    )}
                    <DialogActions>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                        >
                            <Grid item>
                                <Button variant="text" onClick={handleClose}>
                                    {t("admin-logFiles:modals.cancel")}
                                </Button>
                            </Grid>
                            <Grid item className={styles.marginLeft16}>
                                <Button
                                    color="primary"
                                    onClick={handleSave}
                                    disabled={disabledButton}
                                >
                                    {t("download-files:downloadFile")}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </StyledDialog>
            ) : null}
        </>
    );
};
