import { LogFileDTO } from "src/services/generated";
import { store } from "src/redux/store";
import { openDialog } from "src/redux/dialogs/commonDialog/actions";
import { setViewLogFile } from "src/redux/pages/administrationLogFiles/actions";
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
const dispatch = store.dispatch;

export const onViewLogFile = (file: LogFileDTO) => {
    dispatch(openDialog("ViewLogFile"));
    dispatch(setViewLogFile(file));
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
            alignItems: "center",
        },
        table: {
            background: "#FFF",
            padding: "20px 24px 0px",
            "& .blockedUser": {
                opacity: 0.5,
            },
        },
        tableVisibility: {
            display: "block",
            height: "100%",
            width: "100%",
            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },
        rowClassName: {
            cursor: "pointer",
        },
        sortButton: {
            cursor: "pointer",
        },
        activationDialogContent: {
            width: 352,
            marginBottom: 40,
            textAlign: "left",
            fontSize: 14,
            lineHeight: "16px",
        },
        cancelButton: {
            width: 120,
        },
        image: {},
        loadButton: {
            border: "none",
            background: "none",
            cursor: "pointer",
            width: 24,
            height: 24,
            "&:disabled": {
                opacity: 0.38,
                cursor: "default",
            },
        },
        smallTable: {
            position: "relative",
            backgroundColor: "#fff",
            display: "none",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            [theme.breakpoints.down("xs")]: {
                display: "flex",
            },
        },
        smallTableContent: {
            overflowY: "auto",
            height: "100%",
        },
        fileListItem: {
            color: "rgba(101, 119, 203, 1)",
            "&:not(:last-child)": {
                marginBottom: 5,
            },
        },
        filesPropertiesMobile: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 12,
            lineHeight: "14px",
            opacity: 0.6,
        },

        mobileRow: {
            display: "flex",
            borderBottom: "1px solid #DBDBDB",
            minHeight: 64,
        },
        mobileRowExpand: {
            width: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        mobileRowContent: {
            flex: 1,
            overflow: "hidden",
            padding: "8px 12px",
        },
        mobileRowDropdown: {
            width: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },

        alignNumberContentColumn: {
            textAlign: "end",
            paddingRight: "56px",
        },
        sizeColumnWithPadding: {
            paddingRight: "40px",
        },
        longText: {
            maxWidth: "0",
        },
        bigColumn: {
            width: "70%",
        },
        middleColumn: {
            width: "15%",
        },
        contentLongRow: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        sizeColumn: {
            width: "64px",
            textAlign: "end",
            whiteSpace: "nowrap",
        },
        dateColumn: {
            width: "155px",
        },
        menuColumn: {
            width: "64px",
        },
        viewFile: {
            color: "rgba(101, 119, 203, 1)",
            height: "16px",
            cursor: "pointer",
        },
        mobilePagination: {
            height: "56px",
            justifyContent: "center",
        },
    }),
);
