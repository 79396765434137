import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSuccess, enqueueError } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { settingsApiService } from "src/services/clientApi";
import { ExtendMessageMailHeadersDTO } from "src/services/generated";

export const getExtendMessage = createAsyncThunk(
    "getExtendMessage",
    async () => {
        return (await settingsApiService.getExtendPeriodMailHeaders()).data;
    },
);

export const updateExtendMessage = createAsyncThunk(
    "updateMailHeaders",
    async (settings: ExtendMessageMailHeadersDTO, thankAPI) => {
        try {
            const response =
                await settingsApiService.updateExtendPeriodMailHeaders(
                    settings,
                );
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
            return response.data;
        } catch (error) {
            thankAPI.rejectWithValue(error);
            enqueueError(i18n.t("admin-settings:error.edit"));
        }
    },
);
