import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { REFRESH_TOKEN_KEY } from "src/constants/localstorageKeys";
import { UserBlockCountDownParams } from "src/types/redux/store/pages/loginPage";
import { loginApi, updateAccessToken } from "../../../services/clientApi";
import { AuthenticationRequestModel } from "../../../services/generated";

export interface ChangeCountDownParams {
    params: UserBlockCountDownParams | null;
    username: string;
}

export const authenticate = createAsyncThunk(
    "authenticate",
    async (params: AuthenticationRequestModel, thankAPI) => {
        try {
            const x = (await loginApi.createAuthenticationToken(params)).data;
            updateAccessToken(x.accessToken!);
            return x;
        } catch (error) {
            const errorStatus = (error as any).response.status;
            const temporaryBlocked =
                (error as any).response.data.errorCodeStr ===
                "temporaryBlocked";
            const licenseHasExpired =
                (error as any).response.data.errorCodeStr ===
                "send-file:errors:licenseHasExpired";
            if (errorStatus === 403 && temporaryBlocked) {
                thankAPI.dispatch(
                    setCountDownParams({
                        params: {
                            blockTimeInMinutes: 15,
                            date: Date.now(),
                            username: params.username,
                        },
                        username: params.username,
                    }),
                );
                return thankAPI.rejectWithValue({
                    errorStatus: null,
                    temporaryBlocked,
                });
            }
            return thankAPI.rejectWithValue({ errorStatus, licenseHasExpired });
        }
    },
);

export const logOut = createAction("logOut", () => {
    updateAccessToken(null);
    return { payload: {} };
});

export const loginViaRefreshToken = createAsyncThunk(
    "loginViaRefreshToken",
    async (refreshToken: string) => {
        const newToken = (await loginApi.recreateAccessToken(refreshToken))
            .data;
        updateAccessToken(newToken.accessToken!);
        return newToken;
    },
);

export const startCountDown = createAsyncThunk(
    "startCountDown",
    async (username: string) => {
        setInterval(() => {}, 1000);
    },
);

export const setCountDownParams =
    createAction<ChangeCountDownParams>("setCountDownParams");

export const validateAccessToken = createAsyncThunk(
    "validateAccessToken",
    async (accessToken: string, thunkApi) => {
        if (!accessToken) {
            return thunkApi.rejectWithValue(false);
        }
        try {
            const isValid = (await loginApi.isTokenValid(accessToken)).data;
            const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
            if (!isValid && refreshToken) {
                await thunkApi.dispatch(loginViaRefreshToken(refreshToken));
            } else if (!isValid) {
                thunkApi.dispatch(logOut());
            }
            return isValid;
        } catch {
            return thunkApi.rejectWithValue(false);
        }
    },
);
