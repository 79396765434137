import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { FilesFilter } from "src/types/redux/store/pages/MyFilesPage";
import { controllerApi, messageApi, myFilesApi } from "src/services/clientApi";
import { Store } from "src/types/redux/store";
import {
    Encrypted,
    FileDTO,
    FileStateRequest,
    FileStatus,
    IncreaseDownloadLimitDTO,
    ReceivedMessageResponseDataMessageTypeEnum,
} from "src/services/generated";
import { Upload, UploadFileInfo } from "src/types/Upload";
import { ChangeFileUploadProgressActionType } from "../sendFile/actions";
import axios, { CancelTokenSource } from "axios";
import { SendMessageData } from "src/types/redux/store/pages/SendFilePage";
import { fireFileWasNotUploadedPopup } from "src/helpers/file";

export interface FilesInfoParams {
    hideInactive?: boolean;
    searchText?: string;
    page?: number;
    pageSize?: number;
}

let cancelTokens: Map<string, CancelTokenSource> = new Map();
let errorUploadsCount = 0;

export const setHideInactive = createAction<boolean>("setHideInactive");
export const setFilesFilter = createAction<FilesFilter>("setFilesFilter");
export const setFilesSearchFilter = createAction<string>(
    "FILES_SET_SEARCH_FILTER",
);
export const setAddFilesUploadId = createAction<null | string>(
    "setAddFFilesUploadId",
);
export const removeWrongFiles = createAction("removeWrongFiles");
export const changeAddedFileUploadProgressAction =
    createAction<ChangeFileUploadProgressActionType>(
        "changeAddedFileUploadProgressAction",
    );
export const setAddedFiles = createAction<FileDTO[]>("setAddedFiles");

export interface IncreaseDownloadLimitArgs {
    messageId: string;
    downloadLimit: IncreaseDownloadLimitDTO;
}

export const increaseDownloadLimit = createAsyncThunk(
    "increaseDownloadLimit",
    async (
        { messageId, downloadLimit }: IncreaseDownloadLimitArgs,
        thunkApi,
    ) => {
        try {
            return (
                await myFilesApi.increaseDownloadLimit(messageId, downloadLimit)
            ).data;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

export const loadUploadedFilesInfo = createAsyncThunk(
    "loadUploadedFilesInfo",
    async (params: FilesInfoParams | undefined, thunkAPI) => {
        const store = thunkAPI.getState() as Store;
        const myFilesPage = store.pages.myFilesPage;
        const result = await myFilesApi.findUserMessages(
            params?.hideInactive || myFilesPage.hideInactive,
            params?.searchText || myFilesPage.searchFilter,
            params?.page,
            params.pageSize,
        );
        return result.data;
    },
);

export const loadRecievedFilesInfo = createAsyncThunk(
    "loadRecievedFilesInfo",
    async (params: FilesInfoParams | undefined, thunkAPI) => {
        const store = thunkAPI.getState() as Store;
        const myFilesPage = store.pages.myFilesPage;
        const result = await myFilesApi.findResponseMessages(
            params?.hideInactive || myFilesPage.hideInactive,
            params?.searchText || myFilesPage.searchFilter,
            params?.page,
            params.pageSize,
        );
        return result.data;
    },
);

export type DeleteMessagePayload = {
    messageId: string;
    messageType: ReceivedMessageResponseDataMessageTypeEnum;
    page?: number;
    pageSize?: number;
    filter?: FilesFilter;
};
export const deleteMessageOnServer = createAsyncThunk<
    void,
    DeleteMessagePayload
>(
    "deleteMessageOnServer",
    async ({ messageId, messageType, page, filter, pageSize }, thunkApi) => {
        try {
            await myFilesApi.deleteMessage(messageId, messageType);
        } catch (e) {
            return thunkApi.rejectWithValue(e);
        }
        if (filter === FilesFilter.SENT) {
            await thunkApi.dispatch(loadUploadedFilesInfo({ page, pageSize }));
        }
        if (filter === FilesFilter.RECEIVED) {
            await thunkApi.dispatch(loadRecievedFilesInfo({ page, pageSize }));
        }
    },
);

export interface ProlongPeriodArgs {
    upload: Upload;
    prolongFor: number;
    notifyRecepients: boolean;
}
export const prolongPeriod = createAsyncThunk(
    "prolongPeriod",
    async (
        { upload, prolongFor, notifyRecepients }: ProlongPeriodArgs,
        thunkApi,
    ) => {
        try {
            return (
                await myFilesApi.increaseExpireTime(upload.id, {
                    daysCount: prolongFor,
                    sendNotify: notifyRecepients,
                })
            ).data;
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

export interface FilesWithArgs {
    files: File[];
    encrypted: Encrypted;
}
export const addFiles = createAsyncThunk(
    "addFiles",
    async ({ files, encrypted }: FilesWithArgs, thunkAPI) => {
        return await Promise.all(
            files.map(async (file, i) => {
                const state = thunkAPI.getState() as Store;
                const myFilesPageState = state.pages.myFilesPage;
                let fileInfo: FileDTO = {
                    id: "" + errorUploadsCount++,
                    name: file.name,
                    size: file.size,
                    downloadCount: 0,
                    status: FileStatus.DUMMY,
                    error: null,
                };

                try {
                    const apiResponse = (
                        await messageApi.reserveFileInMessage1(
                            myFilesPageState.addFilesUploadId,
                            {
                                name: file.name,
                                size: file.size,
                                encrypted: encrypted,
                            },
                        )
                    ).data;
                    fileInfo.id = apiResponse.id;
                    if (apiResponse.status === FileStatus.ERROR) {
                        fireFileWasNotUploadedPopup(file.name);
                        fileInfo.status = FileStatus.ERROR;
                        fileInfo.error = apiResponse.error;
                        return fileInfo;
                    }
                    cancelTokens.set(fileInfo.id!, axios.CancelToken.source());
                    thunkAPI.dispatch(
                        uploadAddedFileToServer({
                            file,
                            messageId: myFilesPageState.addFilesUploadId,
                            reservedFileUUID: fileInfo.id,
                        }),
                    );
                } catch (error) {
                    fileInfo = (error as any).response.data;
                    fireFileWasNotUploadedPopup(file.name);
                }

                return fileInfo;
            }),
        );
    },
);

export const uploadAddedFileToServer = createAsyncThunk<any, UploadFileInfo>(
    "uploadAddedFileToServer",
    async (uploadFileInfo, thunkAPI) => {
        const { file, messageId, reservedFileUUID } = uploadFileInfo;
        if (!messageId) {
            return;
        }

        const onUploadProgress = (progressEvent: ProgressEvent) => {
            const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
            );
            thunkAPI.dispatch(
                changeAddedFileUploadProgressAction({
                    fileId: reservedFileUUID,
                    progress,
                }),
            );
        };

        try {
            const fileModel = await messageApi.uploadFileToMessage(
                messageId,
                reservedFileUUID,
                file,
                {
                    onUploadProgress,
                    cancelToken: cancelTokens.get(reservedFileUUID)!.token,
                },
            );
            cancelTokens.delete(reservedFileUUID);
            return fileModel.data;
        } catch (e) {
            fireFileWasNotUploadedPopup(file.name);
        }
    },
);

export const deleteAddedFile = createAsyncThunk(
    "deleteAddedFile",
    async (fileId: string, thunkAPI) => {
        const state = thunkAPI.getState() as Store;
        const myFilesPageState = state.pages.myFilesPage;
        if (cancelTokens.has(fileId!)) {
            cancelTokens.get(fileId!)!.cancel();
            cancelTokens.delete(fileId!);
        }
        try {
            if (fileId) {
                await messageApi.deleteFileFromMessage(
                    myFilesPageState.addFilesUploadId,
                    fileId,
                );
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const sendByEmail = createAsyncThunk(
    "sendByEmail",
    async (sendMessageInfo: SendMessageData, thunkAPI) => {
        try {
            await myFilesApi.resendMessage(
                sendMessageInfo.messageId,
                sendMessageInfo.messageModel,
            );
            const store = thunkAPI.getState() as Store;
            const myFilesPageState = store.pages.myFilesPage;
            const f =
                myFilesPageState.filesFilter === FilesFilter.SENT
                    ? loadUploadedFilesInfo
                    : loadRecievedFilesInfo;
            thunkAPI.dispatch(
                f({
                    page: 0,
                    hideInactive: myFilesPageState.hideInactive,
                    searchText: myFilesPageState.searchFilter,
                }),
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const incrementDownloadCount = createAction<string>(
    "incrementDownloadCount",
);

export const getDownloadStateFilesMyFiles = createAsyncThunk(
    "getDownloadStateFilesMyFiles",
    async (fileStateRequest: FileStateRequest) => {
        return (await controllerApi.getState(fileStateRequest)).data;
    },
);
