import i18n from "src/i18n";
import { Languages } from "src/types/Languages";

export const yearMask = () => "0000";

export const monthMask = () =>
    i18n.language === Languages.EN ? "00/0000" : "00.0000";

export const dateMask = () =>
    i18n.language === Languages.EN ? "00/00/0000" : "00.00.0000";

export const dateRangeMask = () =>
    i18n.language === Languages.EN
        ? "00/00/0000 - 00/00/0000"
        : "00.00.0000 - 00.00.0000";
