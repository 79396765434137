import { createAsyncThunk } from "@reduxjs/toolkit";
import { infoControlleApi } from "src/services/clientApi";
import { VersionInfoDto } from "src/services/generated";

export const getVersionInfo = createAsyncThunk<VersionInfoDto>(
    "getVersionInfo",
    async () => {
        return (await infoControlleApi.getVersion()).data;
    },
);
