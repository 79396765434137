import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useShowBlock = (key: string) => {
    const { t } = useTranslation();
    const text = useMemo(() => t(key), [key, t]);
    const showText = text !== key.split(":", 2)[1];

    return [text, showText];
};
