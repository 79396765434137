import React, { ReactNode, useEffect } from "react";
import {
    FieldError,
    FormProvider,
    SubmitHandler,
    useForm,
    useFormContext,
    UseFormProps,
    UseFormReturn,
    FieldValues,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty, isEqual, isFunction, omit } from "lodash";
import { rem } from "polished";
import styled from "@emotion/styled";
import { usePrevious } from "react-use";
import { SubmitButton } from "./SubmitButton";
import { ResetButton } from "./ResetButton";

export { useFormContext };

type YupResolverSchemaType = Parameters<typeof yupResolver>[0];

type ChildrenFunction = (methods: UseFormReturn) => ReactNode;
type FormProps<T> = {
    className?: string;
    onSubmit?: SubmitHandler<T>;
    children?: ReactNode | ChildrenFunction;
    id?: string;
    formConfig?: Omit<UseFormProps<T>, "resolver"> & {
        schema?: YupResolverSchemaType;
    };
    onChange?: (values: T) => void;
};

const Form = <T,>({
    onSubmit,
    children,
    formConfig,
    onChange,
    ...rest
}: FormProps<T>) => {
    const config: UseFormProps = {
        mode: "all",
        shouldUnregister: true,
        ...formConfig,
    };

    if (formConfig?.schema) {
        config["resolver"] = yupResolver(formConfig?.schema);
    }

    // @ts-ignore
    const methods = useForm<T>(config);

    const defaultValues = formConfig?.defaultValues;
    const prevDefaultValue = usePrevious(defaultValues);
    useEffect(() => {
        if (defaultValues) {
            // @ts-ignore
            methods.reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEqual(defaultValues, prevDefaultValue)]);

    const prevVals = usePrevious(methods.watch());
    useEffect(() => {
        if (!isEqual(prevVals, methods.watch())) {
            onChange?.(methods.watch());
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={makeSubmitHandler<T>(onSubmit, methods)} {...rest}>
                {isFunction(children)
                    ? children(methods as UseFormReturn)
                    : children}
            </form>
        </FormProvider>
    );
};

Form.SubmitButton = SubmitButton;
Form.ResetButton = ResetButton;
export { Form };

function makeSubmitHandler<T>(
    onSubmit: SubmitHandler<T>,
    methods: UseFormReturn<T>,
) {
    async function handleSubmit(values: FieldValues) {
        try {
            // @ts-ignore
            await onSubmit(values);
        } catch (ex) {
            if (isServerException(ex)) {
                ex.messages?.forEach(e => {
                    // @ts-ignore
                    const fieldName = e?.fieldName || "x-server";
                    methods.setError(fieldName as any, {
                        type: "server",
                        message: e.message,
                    });
                });
                // TODO: Пока непонятно, что делать если ошибка брошена не с сервера, поэтому просто выведем в консоль
            } else {
                methods.setError("x-client" as any, {
                    type: "client",
                    // @ts-ignore
                    message: ex?.message,
                });
            }
        }
    }
    return methods.handleSubmit(handleSubmit);
}

type ServerException = {
    statusCode: number;
    messages: { fieldName: string; message: string }[];
};
function isServerException(ex: unknown): ex is ServerException {
    return (ex as ServerException).statusCode !== undefined;
}
export const UnknownFormErrors = () => {
    const {
        formState: { errors },
        getValues,
    } = useFormContext();

    if (isEmpty(errors)) return null;

    const vals = getValues();

    const notFieldErrors = omit(errors, Object.keys(vals));

    if (isEmpty(notFieldErrors)) return null;

    return (
        <StyledErrors>
            <h3>Ошибки</h3>
            <ul>
                {Object.entries(notFieldErrors).map(([k, v]) => (
                    <li key={k}>{(v as FieldError).message}</li>
                ))}
            </ul>
        </StyledErrors>
    );
};

const StyledErrors = styled.div`
    margin: ${rem(16)} 0;
`;
