import React, { useEffect } from "react";
import { FileLinkForm } from "src/components/FileSendBox/FileLinkForm";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store/index";
import { SendMessageForm } from "./SendMessageForm";
import { Switchers, TabType } from "../Switchers";
import {
    changeSendFilePageState,
    deleteFile,
    deleteMessage,
    setSendType,
} from "src/redux/pages/sendFile/actions";
import { deleteMessageOnServer } from "src/redux/pages/myFiles/actions";
import {
    FileDTO,
    MessageModel,
    MessageModelSendingTypeEnum,
    ReceivedMessageResponseDataMessageTypeEnum,
} from "../../services/generated";
import { useTranslation } from "react-i18next";
import {
    getTooltipText,
    getTooltipTextWithPassword,
} from "src/helpers/getTooltipText";
import {
    SendFilePageStates,
    SendMessageData,
} from "src/types/redux/store/pages/SendFilePage";

const useStyles = makeStyles({
    sendFileModeNav: {
        marginBottom: "60px",
    },
    sendBoxTab: {
        textTransform: "none",
        letterSpacing: "normal",
        fontSize: "14px",
    },
});

interface FileSendBoxProps {
    onCancel?: () => void;
    onDone?: () => void;
    onSendByEmail?: (sendMessageData: SendMessageData) => void;
    message?: MessageModel;
    files?: FileDTO[];
}

export const FileSendBox: React.FC<FileSendBoxProps> = ({
    onCancel,
    onDone,
    onSendByEmail,
    message: messageFromProps,
    files,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        message: messageFromSelector,
        password,
        isPasswordEnabled,
        sendType,
        directLinksEnabled,
    } = useSelector((store: Store) => store.pages.sendFilePage);

    const message = messageFromProps ?? messageFromSelector;

    const tabs: TabType[] = [
        {
            label: t("send-file:sendByLink"),
            value: MessageModelSendingTypeEnum.LINK,
        },
        {
            label: t("send-file:sendByMail"),
            value: MessageModelSendingTypeEnum.EMAIL,
            disabled: directLinksEnabled,
        },
    ];

    const onChangeTab = (value: MessageModelSendingTypeEnum) => {
        dispatch(setSendType(value));
    };

    const cancelMessage = (message: MessageModel) => {
        if (onCancel) {
            onCancel();
            return;
        }
        message.files?.forEach(file => {
            dispatch(deleteFile({ file, messageId: message.id! }));
        });
        dispatch(
            deleteMessageOnServer({
                messageId: message.id!,
                messageType: ReceivedMessageResponseDataMessageTypeEnum.MESSAGE,
            }),
        );
        dispatch(deleteMessage());
        dispatch(changeSendFilePageState(SendFilePageStates.MESSAGE_CREATING));
    };

    useEffect(() => {
        return () => {
            dispatch(setSendType(MessageModelSendingTypeEnum.LINK));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className={classes.sendFileModeNav}>
                <Switchers
                    onChange={onChangeTab}
                    value={sendType}
                    tabs={tabs}
                    tabClassName={classes.sendBoxTab}
                />
            </div>
            {message && sendType === MessageModelSendingTypeEnum.LINK && (
                <FileLinkForm
                    onDone={onDone}
                    message={message}
                    tooltipText={t(
                        getTooltipTextWithPassword(
                            files ?? message.files!,
                            isPasswordEnabled,
                            password,
                        ),
                    )}
                    onCancel={cancelMessage}
                />
            )}
            {message && sendType === MessageModelSendingTypeEnum.EMAIL && (
                <SendMessageForm
                    onSendByEmail={onSendByEmail}
                    message={message}
                    password={password}
                    tooltipText={t(getTooltipText(files ?? message.files!))}
                    tooltipWithPasswordCheckingText={t(
                        getTooltipTextWithPassword(
                            files ?? message.files!,
                            isPasswordEnabled,
                            password,
                        ),
                    )}
                    onCancel={cancelMessage}
                    isCreateMessage={
                        sendType === MessageModelSendingTypeEnum.EMAIL
                    }
                />
            )}
        </div>
    );
};
