import { UploadForAdmin } from "src/types/Upload";

export interface AdminFilesFilter {
    hideInactive: boolean;
    searchText: string | null;
}

export type SortDir = "ASC" | "DESC";

export enum SortField {
    SIZE = "size",
    SENDER = "senderEmail",
    RECIPIENT = "recipientEmail",
    CREATE_DATE = "createDateTime",
}

export interface AdministrationFilesPage {
    uploads: UploadForAdmin[];
    filter: AdminFilesFilter;
    loadingUploads: boolean;
    totalPages: number;
    totalElements: number;
    emails: Array<string>;
}
