import React from "react";
import { LinearProgress } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { prettyBytes } from "src/helpers/prettyBytes";
import { FileDTO, FileUploadProgressbarResponse } from "src/services/generated";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    downloadInformation: {
        marginTop: "6px",
    },
    informationText: {
        fontFamily: "Roboto",
        fontSize: "12px",
        lineHeight: "14px",
        color: "rgba(26, 31, 41, 0.6)",
        marginTop: "4px",
    },
    informationTextLoad: {
        color: "rgba(101, 119, 203, 1)",
    },
});

const StyledLinearProgress = withStyles({
    root: {
        height: "8px",
    },
    dashed: {
        backgroundImage: "none",
        backgroundColor: "#efeff4",
        animation: "none",
    },
    barColorPrimary: {
        backgroundColor: "#787777",
    },
    colorPrimary: {
        backgroundColor: "#6577cb",
    },
})(LinearProgress);

type FileMemoryStatusProps = {
    files: FileDTO[];
    memory: FileUploadProgressbarResponse;
};

export const FileMemoryStatus = ({ files, memory }: FileMemoryStatusProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const overallVolume = memory.uploadedBeforeSize + memory.storageFreeSpace;
    const uploadedBefore = Math.round(
        (memory.uploadedBeforeSize * 100) / overallVolume,
    );
    let uploadedNow = 0;
    files?.forEach(element => {
        if (element.status === "READY") {
            uploadedNow += element.size;
        }
    });
    const uploadedNowPercent = Math.round(
        ((memory.uploadedBeforeSize + uploadedNow) * 100) / overallVolume,
    );

    return (
        <div className={styles.downloadInformation}>
            <StyledLinearProgress
                className="NewStyle"
                variant="buffer"
                value={uploadedBefore}
                valueBuffer={uploadedNowPercent}
            />
            <div className={styles.informationText}>
                {memory.uploadedBeforeSize !== 0 &&
                    t("send-file:fileMemoryStatus.memoryStatusBefore", {
                        uploadedBefore: prettyBytes(memory.uploadedBeforeSize),
                    })}
                <span className={styles.informationTextLoad}>
                    {prettyBytes(uploadedNow)}
                </span>
                {t("send-file:fileMemoryStatus.memoryStatusOverall", {
                    overallVolume: prettyBytes(overallVolume),
                })}
            </div>
        </div>
    );
};
