import React from "react";
import { TFunction } from "i18next";
import { ClassNameMap, Tooltip, Box } from "@mui/material";
import { Lock } from "@mui/icons-material";
import moment from "moment";
import classNames from "classnames";

import { DatagridColumn } from "src/components/DataGrid";
import { UploadForAdmin, UploadedFileWithStoragePath } from "src/types/Upload";
import { prettyBytes } from "src/helpers/prettyBytes";
import { getSumSizeFiles } from "src/helpers/file";
import { CommonMessageDTOMessageTypeEnum } from "src/services/generated";
import { ReactComponent as InfinityIcon } from "src/icons/infinity.svg";
import { commonDateTimeFormat } from "src/constants/momentFormats";
import { isUploadInactive } from "./utils";

export const getColumns: (
    t: TFunction,
    styles: ClassNameMap,
) => DatagridColumn<UploadForAdmin & UploadedFileWithStoragePath>[] = (
    t,
    styles,
) => [
    {
        accessorKey: "name",
        header: t("admin-files:headers.file"),
        AggregatedCell: ({ row }) => {
            return `${row.original.files.length} ${t(
                "admin-files:headers.filesCount",
            )}`;
        },
        Cell: ({ cell, row }) => {
            const name =
                (cell.getValue() as string) || row.original.files?.[0]?.name;
            return (
                <Box title={name} className={styles.contentLongRow}>
                    {name}
                </Box>
            );
        },
        size: 100,
        minSize: 50,
        enableSorting: false,
    },
    {
        accessorKey: "size",
        header: t("admin-files:headers.size"),
        AggregatedCell: ({ row }) => {
            return prettyBytes(getSumSizeFiles(row.original.files), {
                hideSize: true,
                sizeToRound: "MB",
            });
        },
        Cell: ({ cell, row }) => {
            const size =
                (cell.getValue() as number) || row.original.files?.[0]?.size;
            return prettyBytes(size, {
                hideSize: true,
                sizeToRound: "MB",
            });
        },
        size: 100,
    },
    {
        accessorKey: "downloadCount",
        header: t("admin-files:headers.limit"),
        AggregatedCell: () => null,
        Cell: ({ row }) => {
            const file = row.original.files?.[0] || row.original;
            const type =
                row.original.messageType ||
                row.getParentRow().original.messageType;

            switch (type) {
                case CommonMessageDTOMessageTypeEnum.MESSAGE:
                    return file.downloadCount !== undefined
                        ? `${file.downloadCount}/${file.downloadLimit}`
                        : null;
                case CommonMessageDTOMessageTypeEnum.RESPONSE_MESSAGE:
                    return <InfinityIcon />;
                default:
                    return null;
            }
        },
        enableSorting: false,
        size: 100,
    },
    {
        accessorKey: "dispatchDate",
        header: t("admin-files:headers.dispatchDate"),
        Cell: ({ cell, row }) => {
            const date =
                (cell.getValue() as string) || row.original.dispatchDate;
            const value = date
                ? moment(date).format(commonDateTimeFormat())
                : null;
            return (
                <Box title={value} className={styles.contentLongRow}>
                    {value}
                </Box>
            );
        },
        enableSorting: false,
        size: 100,
    },
    {
        accessorKey: "storagePath",
        header: t("admin-files:headers.location"),
        AggregatedCell: ({ row }) => {
            const files = row.original.files;
            return files.length === 1 ? (
                <Box
                    title={files?.[0]?.storagePath}
                    className={styles.contentLongRow}
                >
                    {files?.[0]?.storagePath}
                </Box>
            ) : null;
        },
        Cell: ({ cell, row }) => {
            const storagePath =
                (cell.getValue() as string) ||
                row.original.files?.[0]?.storagePath;
            return (
                <Box title={storagePath} className={styles.contentLongRow}>
                    {storagePath}
                </Box>
            );
        },
        enableSorting: false,
        size: 100,
    },
    {
        accessorKey: "senderEmail",
        header: t("admin-files:headers.sender"),
        Cell: ({ cell }) => {
            const sender = cell.getValue() as string;
            return (
                <Box title={sender} className={styles.contentLongRow}>
                    {sender}
                </Box>
            );
        },
        size: 120,
    },
    {
        accessorKey: "recipientEmail",
        header: t("admin-files:headers.recipient"),
        Cell: ({ cell, row }) => {
            const recipients = cell.getValue() as string[];
            if (!recipients) {
                return null;
            }
            return recipients.length > 1 ? (
                <Tooltip
                    placement="top-start"
                    arrow
                    title={recipients.map((value, index) => (
                        <Box
                            className={classNames({
                                [styles.disabled]: isUploadInactive(
                                    row.original,
                                ),
                            })}
                            key={index}
                        >
                            {value}
                        </Box>
                    ))}
                >
                    <Box>
                        {`${recipients.length} ${t(
                            "admin-files:headers.recipientsCount",
                        )}`}
                    </Box>
                </Tooltip>
            ) : (
                <Box title={recipients[0]} className={styles.contentLongRow}>
                    {recipients[0]}
                </Box>
            );
        },
        size: 120,
    },
    {
        accessorKey: "security",
        header: "",
        Cell: ({ cell, row }) => {
            const security =
                (cell.getValue() as string) || row.original.security;
            return security ? <Lock /> : null;
        },
        enableSorting: false,
        size: 48,
        grow: false,
    },
];
