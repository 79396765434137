import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { loadListLogFiles, setViewLogFile, unsetViewLogFile } from "./actions";
import { UserFilters } from "src/types/redux/store/pages/administration/AdministrationUsersPage";
import { LogFileDTO, PageLogFileDTO } from "../../../services/generated";
import { AdministrationLogFilesPage } from "src/types/redux/store/pages/administration/AdministrationLogFilesPage";

export const defaultUserFilters: UserFilters = {
    hideBlocked: false,
    endPeriod: null,
    nameFilter: "",
    startPeriod: null,
};

const initialState: AdministrationLogFilesPage = {
    logFilesInfo: {},
    listLogFiles: [],
    loadingListLogFiles: false,
    viewedLogFile: {},
};

export const administrationLogFilesPageReducer =
    createReducer<AdministrationLogFilesPage>(initialState, {
        [loadListLogFiles.pending.type](state) {
            state.loadingListLogFiles = true;
        },
        [loadListLogFiles.fulfilled.type](
            state,
            action: PayloadAction<PageLogFileDTO>,
        ) {
            state.logFilesInfo = action.payload;
            state.listLogFiles = action.payload.content;
            state.loadingListLogFiles = false;
        },
        [loadListLogFiles.rejected.type](
            state,
            action: PayloadAction<PageLogFileDTO>,
        ) {
            state.logFilesInfo = action.payload;
            state.listLogFiles = action.payload.content;
            state.loadingListLogFiles = false;
        },
        [setViewLogFile.type](state, action: PayloadAction<LogFileDTO>) {
            state.viewedLogFile = action.payload;
        },
        [unsetViewLogFile.type](state) {
            state.viewedLogFile = {};
        },
    });
