import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { getResponseMessageById } from "../redux/pages/responseMessageDownloadPage/actions";
import { ResponseMessageDownloadView } from "../components/DownloadFiles/ResponseMessageDownloadView";
import { CircularProgress } from "@mui/material";
import { FilesSecurePassword } from "../components/FilesSecurePassword";

const useStyle = makeStyles({
    container: {
        width: "480px",
        display: "flex",
        flexDirection: "column",
        margin: "80px auto",
        marginTop: "80px",
    },
    circularProgress: {
        margin: "20vh auto",
    },
});

export const ResponseMessageDownloadPage: FC = () => {
    const styles = useStyle();
    const { responseMessageUUID } = useParams<{
        responseMessageUUID: string;
    }>();
    const history = useHistory();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const inProgress = useSelector(
        (store: Store) => store.pages.responseMessageDownloadPage.inProgress,
    );
    const errorCode = useSelector(
        (store: Store) => store.pages.responseMessageDownloadPage.errorCode,
    );
    const isProtected = useSelector(
        (store: Store) => store.pages.responseMessageDownloadPage.isProtected,
    );
    const responseMessage = useSelector(
        (store: Store) =>
            store.pages.responseMessageDownloadPage.responseMessage,
    );

    useEffect(() => {
        dispatch(getResponseMessageById({ messageId: responseMessageUUID }));
    }, [dispatch, responseMessageUUID]);

    const isDownloadingPermitted =
        Array.isArray(responseMessage.responseFiles) || !isProtected;

    useEffect(() => {
        if (errorCode === 404) {
            history.push(`/*`);
        }
        if (errorCode === 403) {
            history.push(`/forbidden`);
        }
        if (errorCode === 401) {
            history.push(`/login?redirectUrl=${pathname}`);
        }
    }, [errorCode, history, pathname]);

    return (
        <div className={styles.container}>
            {inProgress || Boolean(errorCode) ? (
                <CircularProgress className={styles.circularProgress} />
            ) : isDownloadingPermitted ? (
                <ResponseMessageDownloadView
                    responseMessage={responseMessage}
                    messageId={responseMessageUUID}
                />
            ) : (
                <FilesSecurePassword />
            )}
        </div>
    );
};
