/**
 * The enum stores part of keys, which is used for looking for the text
 * in i18next.
 */
export enum WordTranslationKey {
    DAY = "common:dayCases.",
    MINUTE = "common:minuteCases.",
    HOUR = "common:hourCases.",
    DOWNLOAD = "common:downloadCases.",
    ENTRY = "common:entryCases.",
}

/**
 * Variants of cases for words.
 */
export enum CaseEnding {
    NOMINATIVE = "nominative",
    GENITIVE = "genitive",
    ACCUSATIVE = "accusative",
}

/**
 * Choose right case for current word. The choice depends on language.
 * Especially important for russian language.
 *
 * @param count
 * @param word
 * @param locale
 */
export const pluralize = (
    count: number,
    word: WordTranslationKey,
    locale: string,
) => {
    const caseEnding =
        locale === "EN" ? pluralizeEn(count) : pluralizeRu(count);
    return `${word}${caseEnding}`;
};

const pluralizeRu = (count: number): CaseEnding => {
    const lastDigit = count % 100;

    if (
        (lastDigit > 10 && lastDigit < 20) ||
        (lastDigit >= 5 && lastDigit <= 9) ||
        lastDigit % 10 === 0
    ) {
        return CaseEnding.ACCUSATIVE;
    }
    if (lastDigit === 1) {
        return CaseEnding.NOMINATIVE;
    }

    return CaseEnding.GENITIVE;
};

const pluralizeEn = (count: number): CaseEnding => {
    return count === 1 ? CaseEnding.NOMINATIVE : CaseEnding.GENITIVE;
};
