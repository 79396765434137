import React, { useMemo, useState } from "react";
import {
    Button,
    Dialog,
    FormControl,
    Grid,
    Input,
    InputLabel,
    TextField,
    Tooltip,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useForm } from "src/components/Form";
import {
    EmailForm,
    SendMessageData,
} from "src/types/redux/store/pages/SendFilePage";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store/index";
import { sendMessage } from "src/redux/pages/sendFile/actions";
import { openDialog } from "../../redux/dialogs/commonDialog/actions";
import {
    MessageModelSendingTypeEnum,
    MessageModel,
} from "../../services/generated";
import { FileUploadFailedWindow } from "../ModalWindows/FileUploadFailedWindow";
import { isFiledFile } from "src/helpers/file";
import { calculateTimeToExpire } from "src/helpers/dateUtils";
import { pluralize, WordTranslationKey } from "src/helpers/wordCasesUtil";
import i18n from "src/i18n";
import { AutocompleteForEmail } from "../Autocomplete/AutocompleteForEmail";
import { isValidEmail } from "src/helpers/email";
import { UploadedFile } from "../../types/Upload";
import { EmailPreview } from "../ModalWindows/EmailPreview";

const emails: string[] = [];

const useStyles = makeStyles(() =>
    createStyles({
        gridPadding: {
            paddingTop: 24,
        },
        tooltip: {
            maxWidth: 500,
        },
        contentLongRow: {
            "& .MuiInputBase-input": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        },
    }),
);

const getDownloadLimitFromMessage = (message: MessageModel) => {
    return (message?.files || []).reduce(
        (result, file: any) => {
            if (result !== undefined) {
                return result;
            }
            return file.downloadLimit;
        },
        message?.downloadLimit,
    );
};

type Props = {
    message: MessageModel;
    tooltipText?: string;
    tooltipWithPasswordCheckingText?: string;
    onCancel?: (message: MessageModel) => void;
    onSendByEmail?: (sendMessageData: SendMessageData) => void;
    password?: string;
    isCreateMessage?: boolean;
    title?: React.ReactNode;
};

export const SendMessageForm = ({
    tooltipWithPasswordCheckingText,
    onCancel,
    message,
    password,
    onSendByEmail,
    isCreateMessage,
    title,
}: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const dialog = useSelector(
        (store: Store) => store.dialogs.commonDialog.dialog,
    );
    const timeToExpire = calculateTimeToExpire(message?.expireTime);
    const { user } = useSelector(
        (store: Store) => store.authenticationHandler.authentication,
    );
    const [previewEmail, setPreviewEmail] = useState(false);

    const { register, watch, setValue } = useForm<EmailForm>({
        defaultValues: {
            subject: message?.subject,
            text: message?.text || "",
        },
        shouldUnregister: false,
    });

    const emailForm = watch();

    const handleMailsChange = (e: any, data: string[]) => {
        setValue("to", data);
    };

    const sendMessageByEmail = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (onSendByEmail) {
            onSendByEmail({
                sendingType: MessageModelSendingTypeEnum.EMAIL,
                messageId: message?.id,
                messageModel: {
                    password,
                    downloadLimit:
                        message?.downloadLimit ||
                        (
                            message.files.find(
                                file => (file as UploadedFile).downloadLimit,
                            ) as UploadedFile
                        )?.downloadLimit,
                    expireTime: message?.expireTime,
                    recipients: emailForm?.to,
                    subject: emailForm?.subject,
                    text: emailForm?.text,
                },
            });
            return;
        }

        e.preventDefault();
        if (message?.id === undefined) {
            return;
        }

        const isFailedFiles = message.files?.some(isFiledFile);
        if (isFailedFiles) {
            dispatch(openDialog("FileUploadFailedWindow"));
            return;
        }

        dispatch(
            sendMessage({
                sendingType: MessageModelSendingTypeEnum.EMAIL,
                messageId: message?.id,
                messageModel: {
                    password,
                    downloadLimit: message?.downloadLimit,
                    expireTime: message?.expireTime,
                    recipients: emailForm?.to,
                    subject: emailForm?.subject,
                    text: emailForm?.text,
                },
            }),
        );
    };

    const openPreviewEmail = () => {
        setPreviewEmail(true);
    };

    const onContinueSendMessage = () => {
        dispatch(
            sendMessage({
                sendingType: MessageModelSendingTypeEnum.EMAIL,
                messageId: message?.id!,
                messageModel: {
                    password,
                    downloadLimit: message?.downloadLimit,
                    expireTime: message?.expireTime,
                    recipients: emailForm?.to,
                    subject: emailForm?.subject,
                    text: emailForm?.text,
                },
            }),
        );
    };

    const hasSubject = useMemo(() => Boolean(emailForm?.subject), [emailForm]);
    const hasRecipients = useMemo(
        () => Boolean(emailForm?.to?.length),
        [emailForm],
    );
    const hasInvalidRecipients = useMemo(
        () =>
            Boolean(
                emailForm?.to?.filter(item => !isValidEmail(item)).length > 0,
            ),
        [emailForm],
    );

    const tooltipWithPasswordCheckingTextTitle = useMemo(() => {
        return (
            tooltipWithPasswordCheckingText ||
            ((!hasSubject || !hasRecipients) &&
                (t("send-file:tooltipText.emptyFields") as string)) ||
            (hasInvalidRecipients &&
                (t("send-file:tooltipText.errorEmailSendButton") as string))
        );
    }, [
        hasSubject,
        hasRecipients,
        hasInvalidRecipients,
        t,
        tooltipWithPasswordCheckingText,
    ]);

    const previewComponent = (
        <EmailPreview
            messagePrefix={t("send-file:preview.subject")}
            sender={user?.email}
            messageText={emailForm?.text}
            retentionPolicy={t("send-file:preview.retentionPolicy", {
                downloadedCount: getDownloadLimitFromMessage(message),
                time: `${timeToExpire.days} ${t(
                    pluralize(
                        timeToExpire.days,
                        WordTranslationKey.DAY,
                        i18n.languages[0].toUpperCase(),
                    ),
                )}
                         ${timeToExpire.hours} ${t(
                             pluralize(
                                 timeToExpire.hours,
                                 WordTranslationKey.HOUR,
                                 i18n.languages[0].toUpperCase(),
                             ),
                         )}
                         ${timeToExpire.minutes} ${t(
                             pluralize(
                                 timeToExpire.minutes,
                                 WordTranslationKey.MINUTE,
                                 i18n.languages[0].toUpperCase(),
                             ),
                         )}`,
            })}
            onClose={() => setPreviewEmail(false)}
        />
    );

    return (
        <>
            <form
                style={{
                    display: isCreateMessage
                        ? "unset"
                        : !previewEmail
                          ? "unset"
                          : "none",
                }}
            >
                {title}
                <AutocompleteForEmail
                    options={emails}
                    required
                    onChange={handleMailsChange}
                    inputLabel={t("send-file:sendMessageForm.to")}
                />
                <FormControl>
                    <InputLabel required>
                        {t("send-file:sendMessageForm.subject")}
                    </InputLabel>
                    <Tooltip
                        title={emailForm.subject || ""}
                        className={classes.tooltip}
                        disableInteractive
                    >
                        <Input
                            required
                            className={classes.contentLongRow}
                            fullWidth={true}
                            {...register("subject")}
                        />
                    </Tooltip>
                </FormControl>
                <TextField
                    label={t("send-file:sendMessageForm.message")}
                    inputProps={{ maxLength: 65000 }}
                    fullWidth={true}
                    multiline
                    maxRows={4}
                    {...register("text")}
                />
                <Grid className={classes.gridPadding} container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            color="secondary"
                            onClick={openPreviewEmail}
                            fullWidth={true}
                        >
                            {t("send-file:sendMessageForm.preview")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Tooltip
                            disableHoverListener={
                                !tooltipWithPasswordCheckingTextTitle
                            }
                            disableInteractive
                            title={tooltipWithPasswordCheckingTextTitle || ""}
                            className={classes.tooltip}
                        >
                            <span>
                                <Button
                                    disabled={
                                        !!tooltipWithPasswordCheckingTextTitle
                                    }
                                    color="primary"
                                    fullWidth={true}
                                    onClick={sendMessageByEmail}
                                >
                                    {t("send-file:sendMessageForm.send")}
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            color="secondary"
                            onClick={() => onCancel && onCancel(message!)}
                            fullWidth={true}
                        >
                            {t("send-file:cancel").toUpperCase()}
                        </Button>
                    </Grid>
                </Grid>

                {dialog.dialogName === "FileUploadFailedWindow" && (
                    <FileUploadFailedWindow
                        onContinue={onContinueSendMessage}
                    />
                )}
            </form>
            {previewEmail ? (
                !isCreateMessage ? (
                    previewComponent
                ) : (
                    <Dialog open={isCreateMessage}>{previewComponent}</Dialog>
                )
            ) : null}
        </>
    );
};
