import { styleVariables } from "../variables";
import { ComponentsOverrides, CSSInterpolation } from "@mui/material";

const floatButton: CSSInterpolation = {
    color: styleVariables.color.primary.light,
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:hover": {
        backgroundColor: `${styleVariables.color.primary.light}14`,
    },
    "&:active": {
        backgroundColor: `${styleVariables.color.primary.light}29`,
    },
    "&:disabled": {
        backgroundColor: "transparent",
        color: styleVariables.color.sendFileColor.onSecondary.text,
        opacity: 0.38,
    },
};

export const MuiButton: ComponentsOverrides["MuiButton"] = {
    root: {
        borderRadius: "2px",
        padding: "11px 16px",
        height: "36px",
    },

    containedPrimary: {
        color: styleVariables.color.secondary.light,
        backgroundColor: styleVariables.color.primary.main,
        boxShadow: styleVariables.shadow.buttonShadow.default,
        "&:hover": {
            backgroundColor: styleVariables.color.primary.light,
            boxShadow: styleVariables.shadow.buttonShadow.hover,
        },
        "&:active": {
            backgroundColor: styleVariables.color.primary.dark,
            boxShadow: styleVariables.shadow.buttonShadow.active,
        },
        "&:disabled": {
            backgroundColor: styleVariables.color.disabled,
            color: styleVariables.color.sendFileColor.onSecondary.text,
            opacity: 0.38,
            boxShadow: "none",
        },
    },

    outlinedPrimary: {
        color: styleVariables.color.sendFileColor.onSecondary.text,
        boxSizing: "border-box",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: styleVariables.color.primary.light,
        backgroundColor: styleVariables.color.secondary.light,
        boxShadow: styleVariables.shadow.buttonShadow.default,
    },

    containedSecondary: {
        color: styleVariables.color.sendFileColor.onSecondary.text,
        boxSizing: "border-box",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: styleVariables.color.primary.main,
        backgroundColor: styleVariables.color.secondary.light,
        boxShadow: styleVariables.shadow.buttonShadow.default,
        "&:hover": {
            borderColor: styleVariables.color.primary.main,
            backgroundColor: styleVariables.color.secondary.main,
            boxShadow: styleVariables.shadow.buttonShadow.hover,
        },
        "&:active": {
            borderColor: styleVariables.color.primary.dark,
            backgroundColor: styleVariables.color.secondary.dark,
            boxShadow: styleVariables.shadow.buttonShadow.active,
        },
        "&:disabled": {
            color: styleVariables.color.sendFileColor.onSecondary.text,
            backgroundColor: styleVariables.color.disabled,
            opacity: 0.38,
            border: "none",
            boxShadow: "none",
        },
    },

    outlinedSecondary: {
        color: styleVariables.color.primary.light,
        boxShadow: styleVariables.shadow.buttonShadow.default,
        backgroundColor: "transparent",
        borderColor: "transparent",
        "&:hover": {
            borderColor: "transparent",
            backgroundColor: "#f8f9fc",
        },
    },

    textSecondary: floatButton,
    textPrimary: floatButton,
    text: floatButton,
};
