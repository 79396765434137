import React, { useEffect, useState } from "react";
import {
    Box,
    StandardTextFieldProps,
    InputAdornment,
    Popper,
    Button,
    Paper,
    Dialog,
    DialogContent,
    DialogActions,
    Theme,
    ClickAwayListener,
    IconButton,
} from "@mui/material";
import { ReactComponent as CalendarIcon } from "src/icons/calendar.svg";
import { Close } from "@mui/icons-material";

import dayjs, { Dayjs } from "dayjs";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import { useResize } from "src/hooks/useResize";
import { ClosingDialogTitle } from "../ModalWindows/СlosingDialogTitle";
import { commonDateFormat } from "src/constants/momentFormats";
import { Calendar } from "../Calendar/Calendar";
import { dateRangeRegExp } from "src/constants/regular";
import { dateRangeMask } from "src/constants/masks";
import { MaskedInput } from "../MaskedInput ";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        desktopCalendar: {
            display: "flex",
            zIndex: "10000",
        },
        mobileCalendar: {
            display: "flex",
            flexDirection: "column",
        },
        container: {
            width: "100%",
            display: "flex",
        },
        resetButtonWrapper: {
            padding: "12px 8px",
        },
    }),
);

const maskConfig = () => ({
    mask: dateRangeMask(),
    lazy: true,
    eager: true,
});

const formatDateRange = (dateRange: Dayjs[]) => {
    return dateRange
        ? `${dateRange?.[0]?.format(
              commonDateFormat(),
          )} - ${dateRange?.[1]?.format(commonDateFormat())}`
        : "";
};

export interface DateRangePickerProps extends StandardTextFieldProps {
    value?: Dayjs[];
    onChangeDate?: (dateRange: Dayjs[]) => void;
    onReset?: () => void;
}

export const DateRangePicker = ({
    value,
    onChangeDate,
    onReset,
    ...props
}: DateRangePickerProps) => {
    const styles = useStyles();
    const [isOpen, seiIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [inputValue, setInputValue] = useState(formatDateRange(value));
    const [startDate, setStartDate] = useState(value?.[0] || dayjs());
    const [endDate, setEndDate] = useState(value?.[1] || dayjs());
    const [error, setError] = useState<string | null>(null);
    const [isFirstSelected, setIsFirstSelected] = useState(false);
    const { t } = useTranslation();
    const { isScreenSm } = useResize();

    const updateStartDate = (date: Dayjs) => {
        setStartDate(date.startOf("date"));
    };

    const updateEndDate = (date: Dayjs) => {
        setEndDate(date.endOf("date"));
    };

    useEffect(() => {
        setInputValue(formatDateRange(value));
        updateStartDate(value?.[0] || dayjs());
        updateEndDate(value?.[1] || dayjs());
        setError(null);
    }, [value]);

    const onOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        seiIsOpen(true);
    };

    const handleReset = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setInputValue("");
        updateStartDate(dayjs());
        updateEndDate(dayjs());
        onReset?.();
        setError(null);
    };

    const desktopChangeDate = (date: Dayjs) => {
        if (!isFirstSelected || startDate.isAfter(date)) {
            setIsFirstSelected(true);
            updateStartDate(date);
        } else {
            setIsFirstSelected(false);
            updateEndDate(date);
            handleChange([startDate, date.endOf("day")]);
        }
    };

    const handleChange = (dateRange: Dayjs[]) => {
        onClose();
        if (dateRange[0].isBefore(dateRange[1])) {
            setInputValue(formatDateRange(dateRange));
            onChangeDate(dateRange);
            setError(null);
        } else {
            setError(t("date-picker:error.startDateAfterEndDate"));
        }
    };

    const onClose = () => {
        seiIsOpen(false);
    };

    const onAccept = (value: string) => {
        setInputValue(value);
        seiIsOpen(false);
        if (value === "") {
            setError(null);
        }
    };

    const onComplete = (value: string) => {
        if (dateRangeRegExp.test(value)) {
            const dateRange = value
                .split(" - ")
                .map((item: string, index: number) => {
                    const parsedDate = dayjs(item, commonDateFormat());
                    return index === 0
                        ? parsedDate.startOf("day")
                        : parsedDate.endOf("day");
                });
            if (dateRange[0].isSameOrBefore(dateRange[1])) {
                updateStartDate(dateRange[0]);
                updateEndDate(dateRange[1]);
                setError(null);
            } else {
                setError(t("date-picker:error.startDateAfterEndDate"));
            }
        } else {
            setError(t("date-picker:error.incorrect"));
        }
    };

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box className={styles.container}>
                <MaskedInput
                    value={inputValue}
                    config={maskConfig()}
                    onAccept={onAccept}
                    onComplete={onComplete}
                    error={!!error}
                    helperText={error}
                    {...props}
                    autoComplete="off"
                    InputProps={{
                        endAdornment: !inputValue ? (
                            <InputAdornment
                                position="end"
                                onClick={onOpen}
                                sx={{ cursor: "pointer" }}
                            >
                                <CalendarIcon />
                            </InputAdornment>
                        ) : (
                            <InputAdornment
                                position="end"
                                sx={{ cursor: "pointer" }}
                            >
                                <IconButton onClick={handleReset} edge="end">
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onClick={onOpen}
                />

                {isScreenSm ? (
                    <Popper
                        className={styles.desktopCalendar}
                        open={isOpen}
                        anchorEl={anchorEl}
                        onMouseDown={(e: any) => e.preventDefault()}
                    >
                        <Paper>
                            <Calendar
                                onChange={desktopChangeDate}
                                date={startDate}
                            />
                        </Paper>
                        <Paper>
                            <Calendar
                                onChange={desktopChangeDate}
                                date={endDate}
                            />
                        </Paper>
                    </Popper>
                ) : (
                    <Dialog open={isOpen} onClose={onClose}>
                        <ClosingDialogTitle onClose={onClose}>
                            {props.label}
                        </ClosingDialogTitle>
                        <DialogContent className={styles.mobileCalendar}>
                            <Calendar
                                onChange={updateStartDate}
                                date={startDate}
                            />
                            <Calendar onChange={updateEndDate} date={endDate} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="secondary">
                                {t("common:cancel")}
                            </Button>
                            <Button
                                onClick={() =>
                                    handleChange([startDate, endDate])
                                }
                                type="submit"
                                color="primary"
                            >
                                {t("common:confirm")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Box>
        </ClickAwayListener>
    );
};
