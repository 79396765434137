import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "./ProgressBar";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: "16px 24px",
        backgroundColor: "#fff",
        border: "1px solid #E7E7E7",
        borderRadius: 4,
    },
    title: {
        marginBottom: 20,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
        fontFamily: "Roboto",
    },
    chart: ({ chartNameHeight }: any) => ({
        height: chartNameHeight,
        display: "flex",
        alignItems: "center",
        "&:not(:last-child)": {
            marginBottom: 12,
        },
    }),
    chartWithDevider: ({ chartNameHeight }: any) => ({
        paddingBottom: 12,
        borderBottom: "1px solid #E7E7E7",
        height: chartNameHeight + 12,
    }),
    chartName: ({ showMetrics, chartNameWidth }: any) => ({
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        width: chartNameWidth,
        "&::before": {
            content: '""',
            position: "absolute",
            top: -1,
            left: 0,
            width: "calc(100% + 10px)",
            height: 1,
            backgroundColor: "#E7E7E7",
            display: showMetrics ? "" : "none",
        },
    }),
    chartNameDevider: ({ showMetrics }: any) => ({
        height: "100%",
        width: 12,
        borderRight: showMetrics ? "1px solid #E7E7E7" : "",
    }),
    chartProgressContainer: {
        flex: 1,
        height: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
    },
    metric: {
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        borderRight: "1px solid #E7E7E7",
    },
    progressBar: {
        zIndex: 1,
    },
    chartFooter: {
        marginTop: 4,
        display: "flex",
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "14px",
        height: 28,
        color: "rgba(26, 31, 41, 0.38)",
    },
    total: ({ chartNameWidth }: any) => ({
        width: chartNameWidth,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    }),
    totalAmount: {
        color: theme.palette.primary.main,
    },
    metricValues: {
        height: "100%",
        position: "relative",
        flex: 1,
        marginLeft: "12px",
    },
    metricValue: {
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        transform: "translateX(-50%)",
        fontWeight: 400,
    },
}));

export interface Dataset {
    name: string | React.ReactElement;
    all: number;
    completed: number;
    withDivider?: boolean;
}

interface MetricsInfo {
    width: string;
    value: number;
}

interface HorisontalBarChartProps {
    title: string;
    total?: number;
    metricSize?: number;
    datasets: Dataset[];
    chartNameHeight: number;
    chartNameWidth: number;
    className?: string;
    children?: React.ReactNode;
}

const getMetrics = (
    total?: number,
    metricSize?: number,
): MetricsInfo[] | undefined => {
    if (!total || !metricSize) {
        return;
    }
    const metricsToShow = Math.floor(total / metricSize);
    const metricWidth = (metricSize / total) * 100;

    return [...Array(metricsToShow)].map<MetricsInfo>((x, i) => ({
        width: (metricWidth * (i + 1)).toFixed(2) + "%",
        value: metricSize * (i + 1),
    }));
};

export const HorisontalBarChart: React.FC<HorisontalBarChartProps> = ({
    title,
    datasets = [],
    chartNameHeight,
    chartNameWidth,
    metricSize,
    total,
    className,
    children,
}) => {
    const styles = useStyles({
        showMetrics: !!metricSize,
        chartNameHeight,
        chartNameWidth,
    });
    const { t } = useTranslation();

    const metricsInfo = getMetrics(total, metricSize);

    return (
        <div className={classNames(className, styles.container)}>
            <div className={styles.title}>{title}</div>
            <div>
                {datasets.map((dataset, i) => (
                    <div
                        key={i}
                        className={classNames(styles.chart, {
                            [styles.chartWithDevider]: dataset.withDivider,
                        })}
                    >
                        <div className={styles.chartName}>{dataset.name}</div>
                        <div className={styles.chartNameDevider} />
                        <div className={styles.chartProgressContainer}>
                            {metricsInfo?.map((metricInfo, i) => (
                                <div
                                    key={i}
                                    className={styles.metric}
                                    style={{ width: metricInfo.width }}
                                />
                            ))}
                            <ProgressBar
                                className={styles.progressBar}
                                all={dataset.all}
                                completed={dataset.completed}
                            />
                        </div>
                    </div>
                ))}
            </div>
            {(!!metricSize || total !== undefined) && (
                <div className={styles.chartFooter}>
                    <div className={styles.total}>
                        {total !== undefined && (
                            <>
                                <div>
                                    {t("common:horisontalRabChart.total")}
                                </div>
                                <div className={styles.totalAmount}>
                                    {total}
                                </div>
                            </>
                        )}
                    </div>
                    {metricsInfo && (
                        <div className={styles.metricValues}>
                            {metricsInfo.map((metricInfo, i) => (
                                <div
                                    key={i}
                                    className={styles.metricValue}
                                    style={{ left: metricInfo.width }}
                                >
                                    {metricInfo.value}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            {children ? children : <></>}
        </div>
    );
};
