import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "src/redux/store";
import AppLayout from "./AppLayout";
import { StyledEngineProvider } from "@mui/material/styles";
import { NotificationContainer } from "react-notifications";
import { useDayJs } from "./DayJsConfig";
import { ReactQueryProviders } from "./helpers/ReactQuery";
import { ThemeProvider } from "./theme";

const App = () => {
    useDayJs();

    return (
        <ThemeProvider>
            <StyledEngineProvider injectFirst>
                <div id="send-file-notifications-wrapper">
                    <NotificationContainer />
                </div>
                <BrowserRouter>
                    <Provider store={store}>
                        <ReactQueryProviders>
                            <AppLayout />
                        </ReactQueryProviders>
                    </Provider>
                </BrowserRouter>
            </StyledEngineProvider>
        </ThemeProvider>
    );
};

export default App;
