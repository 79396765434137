import { Visibility } from "@mui/icons-material";
import {
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useState } from "react";

const StyledInputAdornment = withStyles({
    root: {
        marginRight: 0,
        marginBottom: "4px",
    },
})(InputAdornment);

const StyledIconButton = withStyles({
    root: {
        padding: 0,
    },
})(IconButton);

const StyledVisibility = withStyles({
    root: {
        color: "#1A1F29",
    },
})(Visibility);

export const PasswordWithEye = React.forwardRef(
    (props: TextFieldProps, ref) => {
        const [inputType, setInputType] = useState<"text" | "password">(
            "password",
        );
        const hidePassword = () => {
            setInputType("password");
        };

        const showPassword = () => {
            setInputType("text");
        };

        return (
            <TextField
                autoComplete="off"
                type={inputType}
                InputProps={{
                    endAdornment: (
                        <StyledInputAdornment position="start">
                            <StyledIconButton
                                onPointerDown={showPassword}
                                onPointerUp={hidePassword}
                                onPointerLeave={hidePassword}
                            >
                                <StyledVisibility />
                            </StyledIconButton>
                        </StyledInputAdornment>
                    ),
                }}
                inputRef={ref}
                {...props}
            />
        );
    },
);
