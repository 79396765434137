import { TableCellClasses } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiTableCell: Partial<
    OverridesStyleRules<keyof TableCellClasses, "MuiTableCell", unknown>
> = {
    root: {
        position: "relative",
        padding: "16px 8px",
        "&:first-of-type": {
            paddingLeft: 24 + 8,
        },
        "&:last-child": {
            paddingRight: 24 + 8,
        },
        fontSize: 14,
    },
    head: {
        fontWeight: 500,
        color: "#1A1F29",
        borderBottom: "2px solid rgba(224, 224, 224, 1)",
        "& > *": {
            opacity: 0.7,
        },
        "&:first-of-type:before": {
            content: '""',
            background: "white",
            position: "absolute",
            bottom: -2,
            left: 0,
            height: 2,
            width: 24,
        },
        "&:last-child:before": {
            content: '""',
            background: "white",
            position: "absolute",
            bottom: -2,
            right: 0,
            height: 2,
            width: 24,
        },
    },
    body: {
        verticalAlign: "middle",
        padding: 8,
        minHeight: 40,
        "& *": {
            verticalAlign: "middle",
        },
    },
};
