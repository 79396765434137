import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { settingsApiService } from "src/services/clientApi";
import { EndingOrExpiredLicenseMailHeadersDTO } from "src/services/generated";

export const getLicenseExpiredMessageHeaders = createAsyncThunk(
    "getLicenseExpiredMessageHeaders",
    async () => {
        return (await settingsApiService.getEndingOrExpiredLicenseMailHeaders())
            .data;
    },
);

export const updateLicenseExpiredMessageHeaders = createAsyncThunk(
    "updateLicenseExpiredMessageHeaders",
    async (settings: EndingOrExpiredLicenseMailHeadersDTO, thankAPI) => {
        try {
            const response =
                await settingsApiService.updateEndingOrExpiredLicenseMailHeaders(
                    settings,
                );
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
            return response.data;
        } catch (error) {
            thankAPI.rejectWithValue(error);
            enqueueError(i18n.t("admin-settings:error.edit"));
        }
    },
);
