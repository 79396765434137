import React from "react";
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
} from "@mui/material";
import { useForm } from "src/components/Form";
import { useTranslation } from "react-i18next";
import { ReactComponent as Copy } from "../../icons/copy.svg";
import { copyElementContentById } from "../../helpers/clipboard";
import { makeStyles, createStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
    changeSendFilePageState,
    deleteFile,
} from "../../redux/pages/sendFile/actions";
import { MessageModel } from "../../services/generated";
import { isFiledFile } from "src/helpers/file";
import { openDialog } from "src/redux/dialogs/commonDialog/actions";
import { getMessageDownloadWithHost } from "src/helpers/url";
import { FileUploadFailedWindow } from "../ModalWindows/FileUploadFailedWindow";
import { SendFilePageStates } from "src/types/redux/store/pages/SendFilePage";
import { Store } from "src/types/redux/store";
import { DirectLinksList } from "../DirectLinksList";

const useStyles = makeStyles(() =>
    createStyles({
        gridPadding: {
            paddingTop: 48,
        },
        linkOverflow: {
            textOverflow: "ellipsis",
        },
        tooltip: {
            maxWidth: 500,
        },
    }),
);

interface FileLinkFormProps {
    message: MessageModel;
    tooltipText?: string;
    onCancel?: (message: MessageModel) => void;
    onDone?: () => void;
}

export const FileLinkForm: React.FC<FileLinkFormProps> = ({
    message,
    tooltipText,
    onCancel,
    onDone,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const directLinksEnabled = useSelector(
        (store: Store) => store.pages.sendFilePage.directLinksEnabled,
    );
    const { register } = useForm({
        defaultValues: {
            link: "",
        },
    });

    const sendMessageByLink = () => {
        if (onDone) {
            onDone();
            return;
        }

        const isFailedFiles = message?.files?.some(isFiledFile);
        if (isFailedFiles) {
            dispatch(openDialog("FileUploadFailedWindow"));
            return;
        }

        dispatch(
            changeSendFilePageState(SendFilePageStates.AFTER_LINK_SENDING),
        );
    };

    const onContinueSendMessage = () => {
        dispatch(
            changeSendFilePageState(SendFilePageStates.AFTER_LINK_SENDING),
        );
        message?.files.forEach(file => {
            if (isFiledFile(file)) {
                dispatch(deleteFile({ messageId: message.id, file }));
            }
        });
    };

    return (
        <form name="fileLink">
            <FormControl>
                <TextField
                    id="FileLinkForm:link"
                    label={t("send-file:downloadLink")}
                    helperText={t("send-file:downloadLinkHint")}
                    className={classes.linkOverflow}
                    {...register("link")}
                    value={getMessageDownloadWithHost(message?.id)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={() =>
                                        copyElementContentById(
                                            "FileLinkForm:link",
                                        )
                                    }
                                >
                                    <Copy />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </FormControl>
            {directLinksEnabled && <DirectLinksList message={message} />}
            <Grid className={classes.gridPadding} container spacing={2}>
                <Grid item xs={6}>
                    <Button
                        onClick={() => onCancel && onCancel(message!)}
                        type="reset"
                        fullWidth={true}
                    >
                        {t("send-file:cancel").toUpperCase()}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    {tooltipText ? (
                        <Tooltip
                            disableInteractive
                            title={tooltipText}
                            className={classes.tooltip}
                        >
                            <span>
                                <Button disabled={true} fullWidth={true}>
                                    {t("send-file:done").toUpperCase()}
                                </Button>
                            </span>
                        </Tooltip>
                    ) : (
                        <Button
                            onClick={sendMessageByLink}
                            type="button"
                            color="primary"
                            fullWidth={true}
                        >
                            {t("send-file:done").toUpperCase()}
                        </Button>
                    )}
                </Grid>
            </Grid>
            <FileUploadFailedWindow onContinue={onContinueSendMessage} />
        </form>
    );
};
