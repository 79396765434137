import { IconButton, Input, InputAdornment, InputProps } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import { withStyles } from "@mui/styles";

const StyledInputAdornment = withStyles({
    root: {
        marginRight: 0,
        marginBottom: "4px",
    },
})(InputAdornment);

const StyledIconButton = withStyles({
    root: {
        padding: 0,
    },
})(IconButton);

interface FindInputProps extends InputProps {
    clear?: () => void;
}

export const FindInput: React.FC<FindInputProps> = ({ clear, ...rest }) => {
    const { t } = useTranslation();
    const placeholder = rest.placeholder
        ? rest.placeholder
        : t("common:search");

    return (
        <Input
            placeholder={placeholder}
            startAdornment={
                <StyledInputAdornment position="start">
                    <StyledIconButton>
                        {rest.value ? <Close onClick={clear} /> : <Search />}
                    </StyledIconButton>
                </StyledInputAdornment>
            }
            {...rest}
        />
    );
};
