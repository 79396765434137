import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { Store } from "src/types/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "src/redux/dialogs/commonDialog/actions";
import { Preloader } from "src/components/Preloader";
import { unsetViewLogFile } from "src/redux/pages/administrationLogFiles/actions";
import { LogFileDTO } from "src/services/generated";
import InfiniteScroll from "react-infinite-scroll-component";
import { ACCESS_TOKEN_KEY } from "src/constants/localstorageKeys";
import { ClosingDialogTitle } from "../СlosingDialogTitle";

const useStyle = makeStyles(() =>
    createStyles({
        logText: {
            fontFamily: "Roboto Mono",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
            margin: 0,
            wordBreak: "break-all",
        },
        marginTop20: {
            marginTop: 20,
        },
        marginLeft16: {
            marginLeft: 16,
        },
        selectStorageType: {
            width: "100%",
        },
        totalSizeInput: {
            marginTop: 4,
        },
        pathTextField: {
            "& input": {
                paddingLeft: 0,
            },
        },
        adornmentSelect: {
            "&::before": {
                borderBottom: "none",
                borderBottomStyle: "none !important",
            },
            "&::after": {
                borderBottom: "none",
                borderBottomStyle: "none !important",
            },
            "& .MuiSelect-select": {
                opacity: 0.6,
                paddingRight: "0 !important",
                paddingBottom: "6px",
            },
        },
        adornmentFormControl: {
            paddingBottom: 0,
            flexShrink: 0,
            width: "auto",
        },
        content: {
            overflowY: "auto",
            marginBottom: "20px",
            textAlign: "left",
            height: "852px",
            "&::-webkit-scrollbar": {
                width: "10px",
                height: "0px",
            },
            "&::-webkit-scrollbar-track": {
                background: "rgba(219, 219, 219, 1)",
            },
            "&::-webkit-scrollbar-thumb": {
                width: "8px",
                height: "8px",
                background: "rgba(26, 31, 41, 0.38)",
                borderRadius: "8px",
            },
        },
        noScroll: {
            "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
            },
        },
    }),
);

const StyledDialog = withStyles({
    paper: {
        maxWidth: "900px ",
        height: "976px",
        textAlign: "left",
    },
})(Dialog);

type ViewLogFileProps = {
    onDownloadLogFile: (file: LogFileDTO) => void;
};

export const ViewLogFile = ({ onDownloadLogFile }: ViewLogFileProps) => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    const styles = useStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { viewedLogFile } = useSelector(
        (store: Store) => store.pages.administration.administrationLogFilesPage,
    );
    const [items, setItems] = useState<string[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const handleClose = () => {
        dispatch(closeDialog("ViewLogFile"));
        dispatch(unsetViewLogFile());
    };
    const handleSave = () => {
        onDownloadLogFile(viewedLogFile);
        dispatch(closeDialog("ViewLogFile"));
        dispatch(unsetViewLogFile());
    };
    useEffect(() => {
        const getContent = async () => {
            const response = await fetch(
                `/api/admin/log/file/view/${viewedLogFile.name}`,
                {
                    method: "POST",
                    headers: new Headers({
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json",
                    }),
                    body: JSON.stringify({
                        offset: 0,
                        count: 100,
                    }),
                },
            );
            const logData = (await response.text()).split("\n");
            logData.pop();
            setItems(logData);
        };
        getContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchContent = async () => {
        const response = await fetch(
            `/api/admin/log/file/view/${viewedLogFile.name}`,
            {
                method: "POST",
                headers: new Headers({
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify({
                    offset: +`${page}00`,
                    count: 100,
                }),
            },
        );
        const logData = (await response.text()).split("\n");
        logData.pop();
        return logData;
    };

    const fetchData = async () => {
        const data = await fetchContent();
        setItems([...items, ...data]);
        if (data.length === 0 || data.length < 100) {
            setHasMore(false);
        }
        setPage(page + 1);
    };

    return (
        <StyledDialog
            open={true}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
        >
            <ClosingDialogTitle id="scroll-dialog-title" onClose={handleClose}>
                {viewedLogFile?.name}
            </ClosingDialogTitle>
            <div id="scrollableDiv" className={styles.content}>
                <InfiniteScroll
                    className={styles.noScroll}
                    dataLength={items.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<Preloader />}
                    scrollableTarget="scrollableDiv"
                >
                    {items.map((el, index) => (
                        <p className={styles.logText} key={index}>
                            {el}
                        </p>
                    ))}
                </InfiniteScroll>
            </div>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <Button variant="text" onClick={handleClose}>
                            {t("admin-logFiles:modals.cancel")}
                        </Button>
                    </Grid>
                    <Grid item className={styles.marginLeft16}>
                        <Button color="primary" onClick={handleSave}>
                            {t("admin-logFiles:modals.download")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </StyledDialog>
    );
};
