import React, { useCallback, useState } from "react";
import { Button, Grid, Theme, Box } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import LogFilesTable from "src/components/CommonTable/TableRealisation/logFilesTable";
import { downloadLogFile, downloadAllLogFiles } from "src/helpers/download";
import { DateRangePicker } from "src/components/DatePicker/DateRangePicker";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { ViewLogFile } from "src/components/ModalWindows/Admin/ViewLogFile";
import { LogFileDTO } from "src/services/generated";
import { ReactComponent as DownloadImage } from "../../icons/download.svg";

import { Dayjs } from "dayjs";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        height100: {
            height: "100%",
        },
        actions: {
            height: "52px",
            [theme.breakpoints.down("xs")]: {
                padding: "16px 8px 0",
                height: "132px",
                flexDirection: "column",
            },
        },
        dateRange: {
            width: "100%",
        },
        downloadAllLogFilesButton: {
            minWidth: 0,
            width: "218px",
            marginRight: 16,
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
        content: {
            marginTop: "16px",
            height: "calc(100% - 68px)",
            [theme.breakpoints.down("xs")]: {
                height: "calc(100% - 148px)",
            },
        },
        dateRangeWrapper: {
            width: "268px",
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
    }),
);

export const AdministrationLogFilesPage = () => {
    const styles = useStyle();
    const { t } = useTranslation();

    const [dateRange, setDateRange] = useState<undefined | Dayjs[]>();
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const { commonDialog } = useSelector((store: Store) => store.dialogs);

    const onDownload = async () => {
        setIsLoadingFiles(true);
        await downloadAllLogFiles(dateRange, t("admin-logFiles:errors.get"));
        setIsLoadingFiles(false);
    };
    const onDownloadLogFile = async (file: LogFileDTO) => {
        if (file) {
            setIsLoadingFiles(true);
            await downloadLogFile(
                file.name,
                file.size,
                t("admin-logFiles:errors.get"),
            );
            setIsLoadingFiles(false);
        }
    };
    const onResetPeriod = useCallback(() => {
        setDateRange(undefined);
    }, [setDateRange]);

    return (
        <Box className={styles.height100}>
            <Box className={styles.height100}>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                    className={styles.actions}
                >
                    <Button
                        className={styles.downloadAllLogFilesButton}
                        startIcon={<DownloadImage fill={"#FFFFFF"} />}
                        color="primary"
                        onClick={onDownload}
                        disabled={isLoadingFiles}
                    >
                        {t("admin-logFiles:downloadAll")}
                    </Button>

                    <Grid
                        wrap="nowrap"
                        container
                        direction="row"
                        alignItems="center"
                        className={styles.dateRangeWrapper}
                    >
                        <DateRangePicker
                            className={styles.dateRange}
                            onReset={onResetPeriod}
                            label={t("common:dateRangePicker.periodTitle")}
                            value={dateRange}
                            onChangeDate={newValue => {
                                setDateRange(newValue);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={styles.content}>
                    <LogFilesTable
                        dateRange={dateRange}
                        onDownloadLogFile={onDownloadLogFile}
                        isLoadingFile={isLoadingFiles}
                    />
                </Grid>
            </Box>
            {commonDialog.dialog.dialogName === "ViewLogFile" &&
                commonDialog.dialog.isOpen && (
                    <ViewLogFile onDownloadLogFile={onDownloadLogFile} />
                )}
        </Box>
    );
};

export default AdministrationLogFilesPage;
