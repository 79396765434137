export const pages = {
    myFiles: "myFiles",
    sendFile: "sendFile",
    requestFile: "requestFile",
    sentFiles: "sentFiles",
    requestedFiles: "requestedFiles",
    allFiles: "allFiles",
    about: "about",
    aboutArtezio: "www.artezio.com",
    support: "support",
    administration: "administration",
    users: "users",
    storages: "storages",
    events: "events",
    files: "files",
    statistics: "statistics",
    settings: "settings",
    general: "general",
    storageSettings: "storage",
    logo: "logo",
    textsMessages: "textsMessages",
    emailMessage: "emailMessage",
    extendMessage: "extendMessage",
    deleteMessage: "deleteMessage",
    licenseMessage: "licenseMessage",
    licences: "licences",
    deactivation: "deactivation",
    ldap: "ldap",
    email: "email",
    dispatch: "dispatch",
    permissionList: "permissionList",
    logFiles: "logFiles",
    reports: "reports",
    login: "login",
};

export const routePaths = {
    root: "/",
    login: `/${pages.login}`,
    sendFile: `/${pages.sendFile}`,
    requestFile: `/${pages.requestFile}`,
    myFiles: `/${pages.myFiles}`,
    sentFiles: `/${pages.myFiles}/${pages.sentFiles}`,
    requestedFiles: `/${pages.myFiles}/${pages.requestedFiles}`,
    allFiles: `/${pages.myFiles}/${pages.allFiles}`,
    about: `/${pages.about}`,
    aboutArtezio: `https://${pages.aboutArtezio}`,
    support: `/${pages.support}`,
    administration: `/${pages.administration}`,
    administrationTemplate: {
        users: `/${pages.administration}/${pages.users}`,
        reports: `/${pages.administration}/${pages.reports}`,
        storages: `/${pages.administration}/${pages.storages}`,
        files: `/${pages.administration}/${pages.files}`,
        logFiles: `/${pages.administration}/${pages.logFiles}`,
        statistics: `/${pages.administration}/${pages.statistics}`,
        settings: {
            general: `/${pages.administration}/${pages.settings}/${pages.general}`,
            storage: `/${pages.administration}/${pages.settings}/${pages.storageSettings}`,
            logo: `/${pages.administration}/${pages.settings}/${pages.logo}`,
            licences: `/${pages.administration}/${pages.settings}/${pages.licences}`,
            deactivation: `/${pages.administration}/${pages.settings}/${pages.deactivation}`,
            ldap: `/${pages.administration}/${pages.settings}/${pages.ldap}`,
            email: `/${pages.administration}/${pages.settings}/${pages.email}`,
            logFiles: `/${pages.administration}/${pages.settings}/${pages.logFiles}`,
            textsMessages: {
                emailMessage: `/${pages.administration}/${pages.settings}/${pages.textsMessages}/${pages.emailMessage}`,
                extendMessage: `/${pages.administration}/${pages.settings}/${pages.textsMessages}/${pages.extendMessage}`,
                deleteMessage: `/${pages.administration}/${pages.settings}/${pages.textsMessages}/${pages.deleteMessage}`,
                licenseMessage: `/${pages.administration}/${pages.settings}/${pages.textsMessages}/${pages.licenseMessage}`,
            },
        },
    },
};
