import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    FilesRequestPage,
    FilesRequestPageState,
    FilesRequestType,
} from "../../../types/redux/store/pages/FilesRequestPage";
import {
    changeFilesRequestPageState,
    changeRequestFileByLinkProps,
    changeRequestType,
    createRequestFileByEmail,
    createRequestFileByLink,
    getDefaultRequestFilesByEmailProps,
    getDefaultRequestFilesByLinkProps,
    resetFilesRequestPage,
    saveEmails,
} from "./actions";
import {
    RequestByEmailDTO,
    RequestByLinkDTO,
    UploadLink,
} from "../../../services/generated";

const initialState: FilesRequestPage = {
    requestType: FilesRequestType.BY_LINK,
    pageState: FilesRequestPageState.REQUEST_CREATING,
    response: {},
    inProgress: false,
};

export const filesRequestPageReducer = createReducer<FilesRequestPage>(
    initialState,
    {
        [changeRequestType.type](
            state,
            action: PayloadAction<FilesRequestType>,
        ) {
            state.requestType = action.payload;
        },
        [getDefaultRequestFilesByLinkProps.fulfilled.type](
            state,
            action: PayloadAction<RequestByLinkDTO>,
        ) {
            state.requestByLinkForm = action.payload;
        },
        [getDefaultRequestFilesByEmailProps.fulfilled.type](
            state,
            action: PayloadAction<RequestByEmailDTO>,
        ) {
            state.requestByEmailForm = action.payload;
        },
        [createRequestFileByEmail.pending.type](state) {
            state.inProgress = true;
        },
        [createRequestFileByEmail.rejected.type](state) {
            state.inProgress = false;
        },
        [createRequestFileByEmail.fulfilled.type](
            state,
            action: PayloadAction<string[]>,
        ) {
            state.response.recipientEmails = action.payload;
            state.pageState = FilesRequestPageState.AFTER_REQUEST_SENDING;
            state.inProgress = false;
        },
        [createRequestFileByLink.fulfilled.type](
            state,
            action: PayloadAction<UploadLink>,
        ) {
            state.response.link = action.payload.uploadUrl;
            state.pageState = FilesRequestPageState.AFTER_REQUEST_SENDING;
            state.inProgress = false;
        },
        [createRequestFileByLink.pending.type](state) {
            state.inProgress = true;
        },
        [createRequestFileByLink.rejected.type](state) {
            state.inProgress = false;
        },
        [changeRequestFileByLinkProps.type](
            state,
            action: PayloadAction<RequestByLinkDTO>,
        ) {
            state.requestByLinkForm = action.payload;
        },
        [saveEmails.type](state, action: PayloadAction<string[]>) {
            if (state.requestByEmailForm) {
                state.requestByEmailForm.recipientsEmails = action.payload;
            }
        },
        [changeFilesRequestPageState.type](
            state,
            action: PayloadAction<FilesRequestPageState>,
        ) {
            state.pageState = action.payload;
        },
        [resetFilesRequestPage.type](state, action: PayloadAction) {
            Object.assign(state, initialState);
        },
    },
);
