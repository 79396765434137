import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { AppVersionInfo } from "src/types/redux/store/AppVersionInfo";
import { getVersionInfo } from "./actions";
import { VersionInfoDto } from "src/services/generated";

const appUrlPathInitialState: AppVersionInfo = {
    loading: false,
};

export const appVersionReducer = createReducer<AppVersionInfo>(
    appUrlPathInitialState,
    {
        [getVersionInfo.pending.type](state) {
            state.loading = true;
        },
        [getVersionInfo.fulfilled.type](
            state,
            action?: PayloadAction<VersionInfoDto>,
        ) {
            state.loading = false;
            state.appVersionInfo = action.payload;
        },
        [getVersionInfo.rejected.type](state) {
            state.loading = false;
        },
    },
);
