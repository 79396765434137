import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { GeneralSettingsDTO } from "src/services/generated";
import { GeneralSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getSettingsGeneral, updateSettingsGeneral } from "./actions";

const generalSettingsInitialState: GeneralSettingsPage = {
    form: {
        encryptionState: null,
        directLinksEnabled: null,
    },
    loading: false,
};

export const generalSettingsReducer = createReducer<GeneralSettingsPage>(
    generalSettingsInitialState,
    {
        [getSettingsGeneral.pending.type](state) {
            state.loading = true;
        },
        [getSettingsGeneral.fulfilled.type](
            state,
            action: PayloadAction<GeneralSettingsDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [getSettingsGeneral.rejected.type](state) {
            state.loading = false;
        },
        [updateSettingsGeneral.pending.type](state) {
            state.loading = true;
        },
        [updateSettingsGeneral.fulfilled.type](
            state,
            action: PayloadAction<GeneralSettingsDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [updateSettingsGeneral.rejected.type](state) {
            state.loading = false;
        },
    },
);
