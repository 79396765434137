import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Store } from "../../types/redux/store";
import { closeDialog } from "src/redux/dialogs/commonDialog/actions";
import { withStyles, makeStyles } from "@mui/styles";

const StyledDialog = withStyles({
    paperWidthSm: {
        width: "400px",
        fontFamily: "Roboto",
        marginBottom: "250px",
    },
})(Dialog);

const useStyles = makeStyles({
    message: {
        fontSize: "16px",
        textAlign: "left",
    },
});
type Props = {
    onContinue: () => void;
};

export const FileUploadFailedWindow = (props: Props) => {
    const { onContinue } = props;
    const styles = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dialog = useSelector(
        (store: Store) => store.dialogs.commonDialog.dialog,
    );

    const closeWindow = () => {
        dispatch(closeDialog(dialog.dialogName));
    };
    const deleteFaledFile = () => {
        onContinue();
        dispatch(closeDialog(dialog.dialogName));
    };

    return (
        <StyledDialog open={dialog.isOpen}>
            <DialogContent>
                <Typography className={styles.message} gutterBottom>
                    {t("common:modals.fileUploadFailedWindow.message")}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeWindow}>
                    {t("common:modals.fileUploadFailedWindow.cancel")}
                </Button>
                <Button onClick={deleteFaledFile} color="primary">
                    {t("common:modals.fileUploadFailedWindow.continue")}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};
