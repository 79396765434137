import { ComponentsOverrides } from "@mui/material/styles/overrides";

export const MuiDialogTitle: ComponentsOverrides["MuiDialogTitle"] = {
    root: {
        textAlign: "left",
        padding: 0,
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "16px",
        marginBottom: 16,
    },
};
