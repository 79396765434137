import styleVariables from "../variables";

export const MuiTooltip = {
    tooltip: {
        backgroundColor: "#FFFFFF",
        padding: "7px 8px",
        borderRadius: 0,
        boxShadow:
            "0px 8px 10px rgba(0, 0, 0, 0.08), 0px 3px 14px rgba(0, 0, 0, 0.06), 0px 5px 5px rgba(0, 0, 0, 0.1)",
        fontSize: 12,
        fontWeight: 400,
        color: styleVariables.color.sendFileColor.onSecondary.text,
    },
    arrow: {
        color: "#fff",
    },
};
