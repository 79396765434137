import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    privateSettingsApi,
    publicSettingsApi,
} from "../../../services/clientApi";

export const getPublicSettings = createAsyncThunk(
    "getPublicSettings",
    async (args, thunkAPI) => {
        const response = await publicSettingsApi.getPublicSettings();
        return response.data;
    },
);

export const getPrivateSettings = createAsyncThunk(
    "getPrivateSettings",
    async (args, thunkAPI) => {
        const response = await privateSettingsApi.getPrivateSettings();
        return response.data;
    },
);
