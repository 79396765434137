import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Store } from "../../../types/redux/store";
import { controllerApi, responseMessageApi } from "../../../services/clientApi";
import {
    DeleteFileParams,
    ResponseMessagePatch,
} from "../../../types/redux/store/pages/ResponseMessage";
import { UploadFileInfo } from "../../../types/Upload";
import {
    Encrypted,
    FileDTO,
    FileError,
    FileStateRequest,
    FileStatus,
} from "../../../services/generated";
import axios, { CancelTokenSource } from "axios";
import { fireFileWasNotUploadedPopup } from "src/helpers/file";
import { FilesWithArgs } from "../myFiles/actions";

export type CreateReserveFileUUIDType = {
    uuid?: string;
    fileId?: string;
    size: number;
    name: string;
    status?: FileStatus;
    error?: FileError;
    encrypted: Encrypted;
};
export type ChangeFileUploadProgressActionType = {
    fileId: string;
    progress: number;
};
export type EmailTextAndSubject = { subject: string; messageText: string };
export type ReturnCreateFileUUID = {
    fileId: string;
    name: string;
    size: number;
    status?: FileStatus;
};

let cancelTokens: Map<string, CancelTokenSource>;

export const handleFailFileUpload = createAction<string>(
    "handleFailFileUpload",
);
export const changeFileUploadProgressAction =
    createAction<ChangeFileUploadProgressActionType>(
        "changeFileUploadProgressAction",
    );
export const updateStateFromFormData = createAction<EmailTextAndSubject>(
    "updateStateFromFormData",
);

export const createResponseMessage = createAsyncThunk(
    "createResponseMessage",
    async (requestMessageUUID: string, thunkAPI) => {
        cancelTokens = new Map();
        const result =
            await responseMessageApi.createResponseMessageForGivenRequest(
                requestMessageUUID,
            );
        return result.data;
    },
);

export const uploadFilesToResponseMessage = createAsyncThunk(
    "uploadFilesToResponseMessage",
    async ({ files, encrypted }: FilesWithArgs, thunkAPI) => {
        let state = thunkAPI.getState() as Store;
        let responseMessage = state.pages.responseMessagePage.responseMessage;
        const messageUuid = responseMessage?.uuid;

        if (messageUuid === undefined) {
            return;
        }
        files.forEach(async file => {
            let state = thunkAPI.getState() as Store;

            let result = await thunkAPI.dispatch(
                reserveFileInResponseMessage({
                    size: file.size,
                    name: file.name,
                    uuid: messageUuid,
                    encrypted: encrypted,
                }),
            );

            if (
                (result as any).error ||
                (result.payload as FileDTO).status === FileStatus.ERROR
            ) {
                const errorFileModel = (result.payload || {}) as FileDTO;
                fireFileWasNotUploadedPopup(file.name);
                thunkAPI.dispatch(
                    createReserveFileUUIDInResponseMessage(errorFileModel),
                );
                return;
            }

            const fileId = (result.payload as FileDTO).id;
            state = thunkAPI.getState() as Store;
            responseMessage = state.pages.responseMessagePage.responseMessage;
            cancelTokens.set(fileId, axios.CancelToken.source());
            if (responseMessage?.uuid) {
                thunkAPI.dispatch(
                    createReserveFileUUIDInResponseMessage(
                        result.payload as CreateReserveFileUUIDType,
                    ),
                );
                await thunkAPI.dispatch(
                    uploadCurrentFileToResponseMessage({
                        file: file,
                        reservedFileUUID: fileId,
                        messageId: responseMessage?.uuid,
                    }),
                );
            }
        });
    },
);

export const uploadCurrentFileToResponseMessage = createAsyncThunk(
    "uploadCurrentFileToResponseMessage",
    async (uploadFileInfo: UploadFileInfo, thunkAPI) => {
        const { file, messageId, reservedFileUUID } = uploadFileInfo;
        if (!messageId) {
            return;
        }

        const onUploadProgress = (progressEvent: ProgressEvent) => {
            const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
            );
            thunkAPI.dispatch(
                changeFileUploadProgressAction({
                    fileId: reservedFileUUID,
                    progress,
                }),
            );
        };
        try {
            const fileModel =
                await responseMessageApi.uploadFileToResponseMessage(
                    messageId,
                    reservedFileUUID,
                    file,
                    {
                        onUploadProgress,
                        cancelToken: cancelTokens.get(reservedFileUUID)!.token,
                    },
                );

            cancelTokens.delete(reservedFileUUID);
            return fileModel.data;
        } catch (e) {
            fireFileWasNotUploadedPopup(file.name);
            thunkAPI.dispatch(handleFailFileUpload(reservedFileUUID));
        }
    },
);

export const createReserveFileUUIDInResponseMessage = createAction<FileDTO>(
    "createReserveFileUUIDInResponseMessage",
);

export const reserveFileInResponseMessage = createAsyncThunk<
    FileDTO,
    CreateReserveFileUUIDType
>(
    "responseMessage/reserveFile",
    async (
        { name, size, uuid, encrypted }: CreateReserveFileUUIDType,
        thunkAPI,
    ) => {
        try {
            return (
                await responseMessageApi.reserveFileInMessage(uuid, {
                    name,
                    size,
                    encrypted,
                })
            ).data;
        } catch (error) {
            return thunkAPI.rejectWithValue((error as any).response.data);
        }
    },
);

export const sendResponseMessageForm = createAsyncThunk(
    "sendResponseMessageForm",
    async (responseMessagePatch: ResponseMessagePatch) =>
        responseMessageApi.updateResponseMessageAndSend(
            responseMessagePatch.uuid,
            responseMessagePatch.responseMessageEmailDTO,
        ),
);

export const deleteCurrentFileFromResponseMessage = createAsyncThunk(
    "deleteCurrentFileFromResponseMessage",
    async (params: DeleteFileParams, thunkAPI) => {
        const store = thunkAPI.getState() as Store;
        const messageId = store.pages.responseMessagePage.responseMessage?.uuid;
        if (!messageId) {
            return;
        }
        const fileToCancel =
            store.pages.responseMessagePage.responseMessage?.responseFiles?.find(
                file => file.id === params.file.id,
            );

        if (fileToCancel && cancelTokens.has(fileToCancel.id)) {
            cancelTokens.get(fileToCancel.id)!.cancel();
            cancelTokens.delete(fileToCancel.id);
        }
        await responseMessageApi.deleteFileFromResponseMessage(
            params.messageId,
            params.file.id,
        );
        return params.file.id;
    },
);
export const getDownloadVolumeValuesFromResponseMessage = createAsyncThunk(
    "getDownloadVolumeValuesFromResponseMessage",
    async (excludeMessageId: string) => {
        return (await controllerApi.getProgressbarStats({ excludeMessageId }))
            .data;
    },
);

export const getDownloadStateFilesResponseMessage = createAsyncThunk(
    "getDownloadStateFilesResponseMessage",
    async (fileStateRequest: FileStateRequest) => {
        return (await controllerApi.getState(fileStateRequest)).data;
    },
);
