import React, { ReactNode, useMemo, useState } from "react";
import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_ColumnDef,
    MRT_TableOptions,
    MRT_SortingState,
    MRT_PaginationState,
    MRT_RowSelectionState,
    MRT_TablePagination,
    MRT_ToolbarInternalButtons,
    MRT_ExpandButton,
    MRT_Row,
    MRT_ExpandedState,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { useTranslation } from "react-i18next";
import { Languages } from "src/types/Languages";
import { GroupedBy } from "./components/GroupedBy";
import { SelectedRows } from "./components/SelectedRows";
import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { DataGridMobile } from "./DataGridMobile";
import { useMediaQuery, useTheme } from "@mui/material";

export type DataGridProps<T> = Omit<
    MRT_TableOptions<T>,
    "onPaginationChange" | "onSortingChange"
> & {
    onPaginationChange?: (p: PaginationState) => void;
    onSortingChange?: (s: SortingState) => void;
    onRowSelectionChange?: (s: SelectionState) => void;
    onOpenFilterDialog?: () => void;
    mobileView?: ReactNode;
};
export type PaginationState = MRT_PaginationState;
export type SortingState = MRT_SortingState;
export type SelectionState = MRT_RowSelectionState;
export type Row<T> = MRT_Row<T>;
export type ExpandedState = MRT_ExpandedState;
export const ExpandButton = MRT_ExpandButton;

export const DataGrid = <T,>({
    columns: cols,
    data,
    state,
    rowCount,
    getRowId,
    onRowSelectionChange,
    onPaginationChange,
    onOpenFilterDialog,
    mobileView,
    manualPagination,
    getSubRows,
    renderRowActions,
    enableExpanding,
    ...rest
}: DataGridProps<T>) => {
    const columns = useMemo<MRT_ColumnDef<T>[]>(() => cols, [cols]);
    const { i18n } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

    const mobileComponent = mobileView ? (
        mobileView
    ) : (
        <DataGridMobile<T>
            data={data}
            totalPages={rowCount || data.length}
            pageSize={state?.pagination.pageSize}
            onPaginationChange={onPaginationChange}
            onOpenFilter={onOpenFilterDialog}
            pageNumber={state?.pagination.pageIndex}
            selectedRows={state?.rowSelection}
            onRowSelect={onRowSelectionChange}
            manualPagination={manualPagination}
            getSubRows={getSubRows}
            columns={columns}
            // @ts-ignore
            getRowId={getRowId}
            renderRowActions={renderRowActions}
            enableExpanding={enableExpanding}
        />
    );
    //pass table options to useMaterialReactTable
    // @ts-ignore
    const table = useMaterialReactTable<T>({
        columns,
        data,
        muiTablePaperProps: {
            elevation: 0,
            sx: {
                display: "flex",
                flexDirection: "column",
                height: "100%",
            },
        },
        muiTableContainerProps: {
            sx: {
                height: "100%",
            },
        },
        // @ts-ignore
        localization:
            i18n.language === Languages.RU
                ? MRT_Localization_RU
                : MRT_Localization_EN,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableFilters: false,
        enableColumnDragging: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        manualSorting: !!rest.onSortingChange,
        // TODO: Перезаписаны дефолтные тулбары.
        // https://www.material-react-table.com/docs/guides/toolbar-customization#override-with-custom-toolbar-components
        renderTopToolbar: data => {
            return (
                <Container>
                    <GroupedBy table={data.table} />
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <MRT_ToolbarInternalButtons table={data.table} />
                </Container>
            );
        },
        renderBottomToolbar: data => {
            return (
                <Container>
                    <Box>
                        <SelectedRows table={data.table} />
                    </Box>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <MRT_TablePagination table={data.table} />
                </Container>
            );
        },
        state: { density: "compact", ...state },
        rowCount,
        onRowSelectionChange,
        // @ts-ignore
        onPaginationChange,
        getRowId,
        manualPagination,
        getSubRows,
        renderRowActions,
        enableExpanding,
        ...rest,
    });
    return (
        // @ts-ignore
        matches ? <MaterialReactTable table={table} /> : <>{mobileComponent}</>
    );
};

export const DEFAULT_PAGINATION: PaginationState = {
    pageIndex: 0,
    pageSize: 10,
};

DataGrid.useTableState = () => {
    const [pagination, setPagination] =
        useState<PaginationState>(DEFAULT_PAGINATION);

    const [sorting, setSorting] = useState<SortingState>([]);
    const [selected, setSelected] = useState<SelectionState>({});

    return {
        selected,
        setSelected,
        sorting,
        setSorting,
        pagination,
        setPagination,
    };
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 12px 8px;
`;
