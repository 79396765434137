import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
    FilesRequestPageState,
    FilesRequestType,
} from "../../../types/redux/store/pages/FilesRequestPage";
import { requestFilesApi } from "../../../services/clientApi";
import {
    RequestByEmailDTO,
    RequestByLinkDTO,
} from "../../../services/generated";

export const changeRequestType =
    createAction<FilesRequestType>("changeRequestType");

export const getDefaultRequestFilesByLinkProps = createAsyncThunk(
    "getDefaultRequestFilesByLinkProps",
    async () => {
        return (await requestFilesApi.getDefaultRequestByLinkProps()).data;
    },
);

export const getDefaultRequestFilesByEmailProps = createAsyncThunk(
    "getDefaultRequestFilesByEmailProps",
    async () => {
        return (await requestFilesApi.getDefaultRequestByEmailProps()).data;
    },
);

export const createRequestFileByLink = createAsyncThunk(
    "createRequestFileByLink",
    async (data: RequestByLinkDTO) => {
        return (await requestFilesApi.createRequestMessageByLink(data)).data;
    },
);

export const createRequestFileByEmail = createAsyncThunk(
    "createRequestFileByEmail",
    async (data: RequestByEmailDTO) => {
        return (await requestFilesApi.createRequestMessageByEmail(data)).data;
    },
);

export const changeRequestFileByLinkProps = createAction<RequestByLinkDTO>(
    "changeRequestFileByLinkProps",
);

export const saveEmails = createAction<string[]>("saveEmails");

export const changeFilesRequestPageState = createAction<FilesRequestPageState>(
    "changeFilesRequestPageState",
);

export const resetFilesRequestPage = createAction("resetFilesRequestPage");
