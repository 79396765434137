import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppLogo } from "src/services/generated";
import {
    publicSettingsApi,
    settingsApiService,
} from "../../services/clientApi";

export const getLogoUrl = createAsyncThunk<AppLogo>("getLogoUrl", async () => {
    return (await publicSettingsApi.getLogoUrl()).data;
});

export const changeLogo = createAsyncThunk("changeLogo", async (file: File) => {
    return (await settingsApiService.uploadLogo(file)).data;
});

export const changeMobileLogo = createAsyncThunk(
    "changeMobileLogo",
    async (file: File) => {
        return (await settingsApiService.uploadLogoMobile(file)).data;
    },
);
