import { Upload } from "src/types/Upload";

export enum FilesFilter {
    SENT = "SENT",
    RECEIVED = "RECEIVED",
}

export interface MyFilesPage {
    searchFilter?: string;
    uploads: Upload[];
    filesFilter: FilesFilter;
    hideInactive: boolean;
    totalPages: number;
    totalElements: number;
    updatingUpload?: boolean;
    loadingUploads: boolean;
    addFilesUploadId: null | string;
    addedFilesIds: string[];
    sendingByEmail: boolean;
    reservedFileId: string[];
}

export interface UpdateFileStatus {
    uploadId: string;
    fileId: string;
    status: string;
}
