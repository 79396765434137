import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Paper,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useForm, FieldAutoComplete, Option } from "src/components/Form";
import { Search } from "@mui/icons-material";
import { ReactComponent as CheckboxIconUnchecked } from "./../../icons/checkboxUnchecked.svg";
import { ReactComponent as CheckboxIconChecked } from "./../../icons/checkboxChecked.svg";
import { UserFilters } from "src/types/redux/store/pages/administration/AdministrationUsersPage";
import { Store } from "src/types/redux/store";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { setUserFilters } from "src/redux/pages/administrationUsers/actions";
import styleVariables from "../../theme/variables";
import { defaultUserFilters } from "src/redux/pages/administrationUsers/reducer";
import { DateRangePicker } from "../DatePicker/DateRangePicker";
import { Dayjs } from "dayjs";
import { adminFilesEmailsApi } from "src/services/clientApi";
import { ISO_FORMAT_DATE } from "src/constants/momentFormats";

const useStyles = makeStyles(() =>
    createStyles({
        flexContainer: {
            textAlign: "left",
            height: "100%",
            width: "100%",
            padding: 16,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
        },
        form: {
            paddingTop: styleVariables.spacing * 7,
            flexGrow: 1,
            boxSizing: "border-box",
            paddingBottom: "20px",
        },
        dateRange: {
            width: "100% !important",
        },
    }),
);

export const UsersFilter = () => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { hideBlocked, nameFilter, startPeriod, endPeriod } = useSelector(
        (store: Store) =>
            store.pages.administration.administrationUsersPage.filters,
    );
    const [dateRange, setDateRange] = useState<Dayjs[] | null>(null);
    const [options, setOptions] = useState<Option[]>([]);

    const dispatch = useDispatch();
    const { register, getValues, setValue, control } = useForm<UserFilters>({
        defaultValues: {
            hideBlocked,
            nameFilter,
            startPeriod: startPeriod,
            endPeriod: endPeriod,
        },
        shouldUnregister: false,
    });

    const updateFilters = () => {
        dispatch(setUserFilters(getValues()));
    };

    const isEqualValues =
        Object.keys(getValues()).length === 0 ||
        _.isEqual(defaultUserFilters, getValues());

    const clearFilters = () => {
        setDateRange(dateRange => (dateRange === null ? undefined : null));
        dispatch(setUserFilters(defaultUserFilters));
        Object.keys(defaultUserFilters).forEach((key: any) => {
            setValue(key, (defaultUserFilters as any)[key]);
        });
    };

    const onDateRangeChange = (dateRange: Dayjs[]) => {
        setDateRange(dateRange);
        setValue("startPeriod", dateRange[0].format(ISO_FORMAT_DATE));
        setValue("endPeriod", dateRange[1].format(ISO_FORMAT_DATE));
        updateFilters();
    };

    const onResetDateRange = () => {
        setValue("startPeriod", null);
        setValue("endPeriod", null);
        updateFilters();
    };

    useEffect(() => {
        const getOptions = async () => {
            const emails = (await adminFilesEmailsApi.getEmailsFromAD()).data;
            const options = emails.map((email: string) => ({
                label: email,
                value: email,
            }));
            setOptions(options);
        };
        getOptions();
    }, []);

    useEffect(() => {
        return () => clearFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Paper className={styles.flexContainer} elevation={1}>
            <b>{t("admin-users:filterForm.label")}</b>
            <Box className={styles.form}>
                <form name="filter">
                    <FieldAutoComplete
                        options={options}
                        name="nameFilter"
                        placeholder={t("admin-users:filterForm.search")}
                        // @ts-ignore
                        controllerProps={{ control }}
                        freeSolo
                        onChange={updateFilters}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    sx={{ marginBottom: "4px" }}
                                >
                                    <IconButton sx={{ padding: 0 }}>
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormControl>
                        <DateRangePicker
                            value={dateRange}
                            className={styles.dateRange}
                            label={t("my-file:filter.lastActivity")}
                            onChangeDate={onDateRangeChange}
                            onReset={onResetDateRange}
                        />
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<CheckboxIconUnchecked />}
                                checkedIcon={<CheckboxIconChecked />}
                                checked={hideBlocked}
                                {...register("hideBlocked", {
                                    onChange: updateFilters,
                                })}
                            />
                        }
                        label={t("admin-users:filterForm.hideBlocked")}
                    />
                </form>
            </Box>
            <Button
                disabled={isEqualValues}
                color="secondary"
                fullWidth={true}
                onClick={clearFilters}
            >
                {t("admin-users:filterForm.clear")}
            </Button>
        </Paper>
    );
};

export default UsersFilter;
