import {
    RequestByEmailDTO,
    RequestByLinkDTO,
} from "../../../../services/generated";

export interface FilesRequestPage {
    requestType: FilesRequestType;
    requestByLinkForm?: RequestByLinkDTO;
    requestByEmailForm?: RequestByEmailDTO;
    pageState: FilesRequestPageState;
    response: Response;
    inProgress: boolean;
}

export interface Response {
    recipientEmails?: string[];
    link?: string;
}

export enum FilesRequestType {
    BY_LINK,
    BY_EMAIL,
}

export enum FilesRequestPageState {
    REQUEST_CREATING,
    AFTER_REQUEST_SENDING,
}
