import {
    MessageModel,
    MessageModelSendingTypeEnum,
    EmailMessageModel,
    LinkMessageModel,
    FileStatus,
    FileUploadProgressbarResponse,
} from "src/services/generated";

export interface FileType {
    name: string;
    size: string;
    status: FileStatus;
    id: string;
    progress: number;
}

export type FileSettingsForm = {
    storageTermExpiration?: number | undefined;
    daysToExpired?: number | undefined;
    password?: string;
    passwordEnabled: boolean;
    withDirectLinks?: boolean;
};

export interface EmailForm {
    to: string[] | undefined;
    subject?: string;
    text?: string;
}

export interface SendFilePage {
    sendType: MessageModelSendingTypeEnum;
    message?: MessageModel & { errors?: string[] };
    uploadedFilesCount: number;
    currentPageState: SendFilePageStates;
    isPasswordEnabled: boolean;
    directLinksEnabled: boolean;
    inProgressSendMessage: boolean;
    password: string;
    creatingMessage: boolean;
    memory: FileUploadProgressbarResponse;
    reservedFileId: string[];
}

export interface SendMessageData {
    messageId: string;
    messageModel: EmailMessageModel;
    sendingType: MessageModelSendingTypeEnum;
}

export interface UpdateMessageData {
    messageId: string | undefined;
    messageModel: LinkMessageModel;
}

export enum SendFilePageStates {
    AFTER_MESSAGE_SENDING,
    AFTER_LINK_SENDING,
    MESSAGE_CREATING,
    MESSAGE_EDITING,
}

export interface FileStatusUpdate {
    fileId: string;
    status: string;
}

export interface DeleteFailedFilesByUsingDialogParams {
    fileIds: string[];
    dialogName: string;
}
