import { createAsyncThunk } from "@reduxjs/toolkit";
import { settingsApiService } from "src/services/clientApi";
import { LicenseInfoDTO, LicenseUpdateDTO } from "src/services/generated";

export const getLicenseSettings = createAsyncThunk(
    "getLicenseSettings",
    async () => {
        return (await settingsApiService.getLicensesInfo()).data;
    },
);

export const updateLicenseSettings = createAsyncThunk<
    LicenseInfoDTO,
    LicenseUpdateDTO
>("updateLicenseSettings", data => {
    return settingsApiService.updateLicense(data) as Promise<LicenseInfoDTO>;
});
