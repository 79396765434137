import React from "react";
import { useStyles } from "./utils";
import { Preloader } from "src/components/Preloader";
import classNames from "classnames";
import { prettyBytes } from "src/helpers/prettyBytes";
import moment from "moment";
import { commonDateTimeFormat } from "src/constants/momentFormats";
import { ReactComponent as DownloadImage } from "src/icons/download.svg";
import { Box, Pagination } from "@mui/material";
import { LogFileDTO } from "src/services/generated";
import { PaginationState } from "src/components/DataGrid";
import { PaginationType } from "src/types/Pagination";

type MobileTableProps = {
    listLogFiles: LogFileDTO[];
    isLoadingFile: boolean;
    loadingListLogFiles: boolean;
    onDownloadLogFile: (file: LogFileDTO) => void;
    pagination: PaginationType;
    setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
};

export const MobileTable = ({
    listLogFiles,
    loadingListLogFiles,
    isLoadingFile,
    onDownloadLogFile,
    pagination: { page, totalElements, totalPages, pageSize },
    setPagination,
}: MobileTableProps) => {
    const onChangePage = (e: any, page: number) => {
        setPagination({
            pageIndex: page - 1,
            pageSize,
        });
    };

    const styles = useStyles();
    return (
        <div className={styles.smallTable}>
            {loadingListLogFiles && <Preloader over />}
            <Box className={styles.smallTableContent}>
                {listLogFiles.map(file => (
                    <div key={file.name} className={styles.mobileRow}>
                        <div className={styles.mobileRowContent}>
                            <div
                                className={classNames(
                                    styles.contentLongRow,
                                    styles.fileListItem,
                                )}
                                title={file.name}
                                key={file.name}
                                // onClick={() => onViewLogFile(file)}
                            >
                                {file.name}
                            </div>
                            <div className={styles.filesPropertiesMobile}>
                                <div>
                                    {prettyBytes(file.size, {
                                        sizeToRound: "MB",
                                    })}
                                </div>
                                <div>
                                    {moment(file.createdDateTime).format(
                                        commonDateTimeFormat(),
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.mobileRowDropdown}>
                            <button
                                className={styles.loadButton}
                                disabled={isLoadingFile}
                                onClick={() => onDownloadLogFile(file)}
                            >
                                <DownloadImage
                                    fill={"#1A1F29"}
                                    className={styles.image}
                                />
                            </button>
                        </div>
                    </div>
                ))}
            </Box>
            {totalPages > 1 && (
                <Pagination
                    className={styles.mobilePagination}
                    onChange={onChangePage}
                    page={page || 1}
                    color="primary"
                    size="small"
                    count={totalElements}
                />
            )}
        </div>
    );
};
