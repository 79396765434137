import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions = {
    fontFamily: "Roboto",
    fontSize: 12,
    button: {
        textTransform: "uppercase",
        fontStyle: "normal",
        fortWeight: 500,
        letterSpacing: "0.125em",
        fontSize: 12,
        textAlign: "center",
        lineHeight: "14px",
        alignItems: "center",
    },
    h6: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "16px",
        fontFamily: "Roboto",
        marginBottom: 26,
    },
};

export default typography;
