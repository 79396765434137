import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { StatsFileSummaryDTO, StatsSummaryDTO } from "src/services/generated";
import { IAdministrationStatisticsPage } from "src/types/redux/store/pages/administration/AdministrationStatisticsPage";
import { receiveFileStats, receiveStats, resetPeriod } from "./actions";

const administrationStatisticsPageInitialState: IAdministrationStatisticsPage =
    {
        loadingFilesStats: false,
        loadingStats: false,
    };

export const administrationStatisticsPageReducer =
    createReducer<IAdministrationStatisticsPage>(
        administrationStatisticsPageInitialState,
        {
            [receiveStats.pending.type](state) {
                state.loadingStats = true;
            },
            [receiveStats.fulfilled.type](
                state,
                action: PayloadAction<StatsSummaryDTO>,
            ) {
                state.loadingStats = false;
                state.stats = action.payload;
            },
            [receiveStats.rejected.type](state) {
                state.loadingStats = false;
                state.stats = undefined;
            },
            [receiveFileStats.pending.type](state) {
                state.loadingFilesStats = true;
            },
            [receiveFileStats.fulfilled.type](
                state,
                action: PayloadAction<StatsFileSummaryDTO>,
            ) {
                state.loadingFilesStats = false;
                state.fileStats = action.payload;
            },
            [receiveFileStats.rejected.type](state) {
                state.loadingFilesStats = false;
                state.fileStats = undefined;
            },
            [resetPeriod.fulfilled.type](
                state,
                action: PayloadAction<StatsFileSummaryDTO>,
            ) {
                state.fileStats = action.payload;
            },
        },
    );
