import {
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    InputProps,
} from "@mui/material";
import { ChangeEvent, FocusEvent } from "react";
import { Close } from "@mui/icons-material";
import { Controller } from "../../FieldController";
import React from "react";

type FieldInputProps = InputProps & { label: string };
export const FieldInput = ({
    name,
    defaultValue = "",
    label,
    error,
    ...inputProps
}: FieldInputProps) => {
    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        onChange: (e: any) => void,
    ) => {
        inputProps?.onChange?.(e);
        onChange(e);
    };

    const handleBlur = (
        e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
        onBlur: (e: any) => void,
    ) => {
        onBlur(e);
        inputProps?.onBlur?.(e);
    };

    return (
        <Controller name={name} defaultValue={defaultValue}>
            {({ field: { value, onBlur, onChange }, formState }) => (
                <FormControl>
                    <InputLabel error={error}>{label}</InputLabel>
                    <Input
                        value={value}
                        error={error}
                        onBlur={e => handleBlur(e, onBlur)}
                        onChange={e => handleChange(e, onChange)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        onChange(
                                            formState.defaultValues[name] || "",
                                        )
                                    }
                                    edge="end"
                                >
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        }
                        {...inputProps}
                    />
                </FormControl>
            )}
        </Controller>
    );
};
