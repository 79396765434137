import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import { UploadForAdmin } from "src/types/Upload";
import moment from "moment";

export const isUploadInactive = (upload: UploadForAdmin) => {
    const isExpired = moment(upload.dispatchDate).isBefore(moment());
    const isExceedDownloadLimit = upload.files?.every(
        file =>
            file.downloadLimit &&
            file.downloadCount &&
            file.downloadLimit === file.downloadCount,
    );
    return isExceedDownloadLimit || isExpired;
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            height: "100%",
        },
        deleteDialogTitle: {
            marginBottom: 40,
        },
    }),
);
