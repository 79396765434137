import React, { useEffect } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "src/components/Form";
import { useTranslation } from "react-i18next";
import { CommonAdminForm } from "src/components/Admin/commonAdminForm";
import { Preloader } from "src/components/Preloader";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { LogFileSettings } from "src/services/generated";
import { mbMultiplier } from "src/constants/fileSizeMultiplier";
import { isNumber } from "lodash";
import { LogFileSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import {
    getSettingsLogFile,
    updateSettingsLogFile,
} from "src/redux/pages/administrationSettings/logFiles/actions";

const useStyle = makeStyles({
    container: {
        maxWidth: 800,
    },
});

export const LogFilesSettings = () => {
    const { t } = useTranslation();
    const styles = useStyle();
    const dispatch = useDispatch();
    const { loading, form } = useSelector<Store, LogFileSettingsPage>(
        store =>
            store.pages.administration.administrationSettings
                .logFileSettingsPage,
    );

    const { formState, reset, watch, register, handleSubmit } = useForm<
        LogFileSettingsPage["form"]
    >({
        defaultValues: {
            ...form,
            totalSizeCap: form.totalSizeCap
                ? form.totalSizeCap / mbMultiplier
                : form.totalSizeCap,
        },
    });

    useEffect(() => {
        dispatch(getSettingsLogFile());
    }, [dispatch]);

    useEffect(() => {
        reset({
            ...form,
            totalSizeCap: form.totalSizeCap
                ? form.totalSizeCap / mbMultiplier
                : form.totalSizeCap,
        });
    }, [form, reset]);

    const { totalSizeCap, maxHistory } = watch();
    const isValidTotalSizeCap = isNumber(+totalSizeCap) && totalSizeCap >= 0;
    const isValidMaxHistory = isNumber(+maxHistory) && maxHistory >= 0;
    const showErrorTotalSizeCap =
        formState.touchedFields.totalSizeCap && !isValidTotalSizeCap;
    const showErrorMaxHistory =
        formState.touchedFields.maxHistory && !isValidMaxHistory;

    const onSubmit = (data: LogFileSettings) => {
        dispatch(
            updateSettingsLogFile({
                totalSizeCap: data.totalSizeCap
                    ? data.totalSizeCap * mbMultiplier
                    : null,
                maxHistory: data.maxHistory ? +data.maxHistory : null,
            }),
        );
    };

    return (
        <CommonAdminForm
            className={styles.container}
            disableSubmit={!isValidTotalSizeCap || !isValidMaxHistory}
            onSubmit={handleSubmit(onSubmit)}
        >
            {loading && <Preloader over />}
            <Grid container direction="column">
                <Grid item xs={6}>
                    <Typography variant="h6">
                        {t(
                            "admin-settings:logFilesSettings.titleSettingsLogFiles",
                        )}
                    </Typography>
                    <TextField
                        error={showErrorTotalSizeCap}
                        InputLabelProps={{ shrink: true }}
                        label={t(
                            "admin-settings:logFilesSettings.maxSizeLogFiles",
                        )}
                        {...register("totalSizeCap")}
                    />

                    <TextField
                        error={showErrorMaxHistory}
                        InputLabelProps={{ shrink: true }}
                        label={t(
                            "admin-settings:logFilesSettings.maxPeriodLogFiles",
                        )}
                        {...register("maxHistory")}
                    />
                </Grid>
            </Grid>
        </CommonAdminForm>
    );
};
