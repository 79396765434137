import React from "react";
import { ClosingDialogTitle } from "../СlosingDialogTitle";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProlongPeriodArgs } from "src/redux/pages/myFiles/actions";
import { Controller, useForm } from "src/components/Form";
import { DAYS_TO_EXPIRE } from "src/constants/sendFiles";
import { WordTranslationKey, pluralize } from "src/helpers/wordCasesUtil";
import styled from "@emotion/styled";
import { BaseDialogProps } from "src/pages/MyFiles";

interface ProlongPeriodForm {
    prolongFor: number | "";
    notifyRecepients: boolean;
}

type DialogProlongProps = {
    prolong?: (param: ProlongPeriodArgs) => void;
} & BaseDialogProps;

export const DialogProlong = ({
    open,
    onClose,
    upload,
    prolong,
}: DialogProlongProps) => {
    const { t, i18n } = useTranslation();
    const locale = i18n.languages && i18n.languages[0];

    const { register, setValue, control, getValues } =
        useForm<ProlongPeriodForm>({
            defaultValues: {
                prolongFor: "",
                notifyRecepients: false,
            },
        });

    const onNotifyRecepientsChange = (event: any) => {
        setValue("notifyRecepients", event.target.checked);
    };

    const onSubmit = () => {
        const { prolongFor, notifyRecepients } = getValues();
        if (prolongFor !== "") {
            prolong({ upload, prolongFor, notifyRecepients });
            onClose();
        }
    };

    return (
        <DialogStyles open={open} onClose={onClose}>
            <ClosingDialogTitle onClose={onClose}>
                {t("my-file:prolongDialog.title")}
            </ClosingDialogTitle>
            <DialogContentStyles>
                <FormStyles>
                    <FormControl>
                        <InputLabel>
                            {t("my-file:prolongDialog.selectPlaceholder")}
                        </InputLabel>
                        <Controller
                            name="prolongFor"
                            render={({ field: { value, onChange } }) => (
                                <Select onChange={onChange} value={value}>
                                    {DAYS_TO_EXPIRE.map((count, index) => (
                                        <MenuItem key={index} value={count}>
                                            {`${count} ${t(
                                                pluralize(
                                                    count,
                                                    WordTranslationKey.DAY,
                                                    locale,
                                                ),
                                            )}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            control={control}
                        />
                    </FormControl>
                    {upload?.recipients.length > 0 && (
                        <Controller
                            name="notifyRecepients"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabelStyles
                                    label={t(
                                        "my-file:prolongDialog.notifyRecepients",
                                    )}
                                    control={
                                        <Checkbox
                                            checked={field.value}
                                            color="primary"
                                            {...register("notifyRecepients", {
                                                onChange:
                                                    onNotifyRecepientsChange,
                                            })}
                                        />
                                    }
                                />
                            )}
                        />
                    )}
                </FormStyles>
            </DialogContentStyles>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    {t("my-file:prolongDialog.cancel")}
                </Button>
                <Button onClick={onSubmit} type="submit" color="primary">
                    {t("my-file:prolongDialog.accept")}
                </Button>
            </DialogActions>
        </DialogStyles>
    );
};

const DialogStyles = styled(Dialog)`
    & .MuiPaper-root {
        width: 440px;
    }
`;

const FormStyles = styled.form`
    text-align: left;
`;

const DialogContentStyles = styled(DialogContent)`
    width: 100%;
`;

const FormControlLabelStyles = styled(FormControlLabel)`
    margin-left: -8px;
`;
