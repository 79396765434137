import React from "react";
import { Grid, Theme, Box } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FilesFilter } from "../../components/filters/FilesFilter";
import AdminFilesTable from "../../components/CommonTable/TableRealisation/adminFilesTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        height100: {
            height: "100%",
        },
        filterWrapper: {
            height: "100%",
            width: "240px",
            flexShrink: 0,
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "block",
            },
        },
        tableWrapper: {
            width: "100%",
            height: "100%",
            marginRight: 0,
            minWidth: 0,
            [theme.breakpoints.up("md")]: {
                marginRight: "16px",
            },
        },
    }),
);

export const AdministrationFilesPage = () => {
    const styles = useStyles();

    return (
        <Grid container wrap="nowrap" className={styles.height100}>
            <Box className={styles.tableWrapper}>
                <AdminFilesTable />
            </Box>
            <Box className={styles.filterWrapper}>
                <FilesFilter />
            </Box>
        </Grid>
    );
};
