import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { styleVariables } from "../variables";

export const MuiFormControlLabel: ComponentsOverrides["MuiFormControlLabel"] = {
    label: {
        fontSize: 14,
    },
    root: {
        marginLeft: styleVariables.spacing * 0,
    },
};

export default MuiFormControlLabel;
