import React, { useMemo } from "react";

export interface TextEllipsisProps {
    ellipsis?: string;
    text: string;
    limit?: number;
    className?: string;
    mode?: EllipsisMode;
}

export enum EllipsisMode {
    InPlace,
    After,
    Before,
}

export const TextEllipsis = ({
    text = "",
    className,
    mode,
    ellipsis = "...",
    limit = 50,
}: TextEllipsisProps) => {
    const ellipsisStr = useMemo(() => {
        if (text?.length > limit + ellipsis.length) {
            let breakpoint;

            switch (mode) {
                case EllipsisMode.After:
                    breakpoint = limit + text.substring(limit).indexOf(" ");
                    break;

                case EllipsisMode.Before:
                    breakpoint = text.substring(0, limit).lastIndexOf(" ");
                    break;

                default:
                    return text.slice(0, limit - ellipsis.length) + ellipsis;
            }

            return text.substring(0, breakpoint) + ellipsis;
        } else {
            return text;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    return (
        <span title={text} className={className}>
            {ellipsisStr}
        </span>
    );
};
