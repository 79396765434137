import React, { ReactElement } from "react";
import {
    Controller,
    ControllerRenderProps,
    ControllerProps,
    ControllerFieldState,
    UseFormStateReturn,
} from "react-hook-form";

type FieldControllerProps = {
    children?: (arg: {
        field: ControllerRenderProps<any, any>;
        formState: UseFormStateReturn<any>;
        fieldState: ControllerFieldState;
    }) => ReactElement;
    as?: ReactElement;
    render?: (arg: {
        field: ControllerRenderProps<any, any>;
        formState: UseFormStateReturn<any>;
        fieldState: ControllerFieldState;
    }) => ReactElement;
    className?: string;
} & Omit<ControllerProps<any>, "render">;

const FieldController = ({
    children,
    name,
    defaultValue,
    as,
    render,
    ...rest
}: FieldControllerProps) => {
    return (
        <Controller
            defaultValue={defaultValue}
            name={name}
            render={props => render?.(props) || children(props)}
            {...rest}
        />
    );
};

export { FieldController as Controller };
