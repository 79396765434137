import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { shadow } from "../variables/shadow";

export const MuiPaper: ComponentsOverrides["MuiPaper"] = {
    elevation1: {
        boxShadow: shadow.headerPaper.light,
    },
    elevation2: {
        boxShadow: shadow.containerShadow.dark,
    },
    root: {
        textAlign: "center",
        boxSizing: "border-box",
        backgroundImage: "unset",
    },
    rounded: {
        borderRadius: "2px",
    },
};

export default MuiPaper;
