import { ComponentsOverrides } from "@mui/material/styles/overrides";

export const MuiListItem: ComponentsOverrides["MuiListItem"] = {
    gutters: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 9,
        paddingBottom: 9,
    },
};

export default MuiListItem;
