import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { fromCronToLight } from "src/helpers/cronFromatAdapters";
import { StorageSettingsDTO } from "../../../../services/generated";
import { StorageSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import {
    getStorageSettings,
    SetCronFormatValues,
    setCronFormatValues,
    setLightFormatValues,
    SetLightFormatValues,
    setPeriodTypeIsNormal,
    updateStorageSettings,
} from "./actions";
import { delayBeforeDeletingDays } from "./constants";
import i18n from "src/i18n";

const storageSettingsInitialState: StorageSettingsPage = {
    loading: false,
    form: {
        countOfDayDelayBeforeMessageRemoving: delayBeforeDeletingDays[0],
        simpleForm: false,
        notEnoughFreeSpaceNotificationThreshold: 10,
    },
};

export const storageSettingsReducer = createReducer<StorageSettingsPage>(
    storageSettingsInitialState,
    {
        [setLightFormatValues.type](
            state,
            action: PayloadAction<SetLightFormatValues>,
        ) {
            state.form.simpleCron_checkFreeSpace_time =
                action.payload.freeSpaceCheckLightFormat.time;
            state.form.simpleCron_checkFreeSpace_period =
                action.payload.freeSpaceCheckLightFormat.periodType;

            state.form.simpleCron_deleteFiles_time =
                action.payload.expiredFilesLightFormat.time;
            state.form.simpleCron_deleteFiles_period =
                action.payload.expiredFilesLightFormat.periodType;
        },
        [setCronFormatValues.type](
            state,
            action: PayloadAction<SetCronFormatValues>,
        ) {
            state.form.expiredFilesSchedule =
                action.payload.expiredFilesSchedule;
            state.form.freeSpaceCheckSchedule =
                action.payload.freeSpaceCheckSchedule;
        },
        [setPeriodTypeIsNormal.type](state, action: PayloadAction<boolean>) {
            state.form.simpleForm = action.payload;
        },
        [getStorageSettings.pending.type](state) {
            state.loading = true;
        },
        [getStorageSettings.fulfilled.type](
            state,
            action: PayloadAction<StorageSettingsDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
            const freeSpaceCheckLightFormat = fromCronToLight(
                action.payload.freeSpaceCheckSchedule,
            );
            const expiredFilesLightFormat = fromCronToLight(
                action.payload.expiredFilesSchedule,
            );
            if (freeSpaceCheckLightFormat && expiredFilesLightFormat) {
                state.form.simpleCron_checkFreeSpace_period =
                    freeSpaceCheckLightFormat.periodType;
                state.form.simpleCron_checkFreeSpace_time =
                    freeSpaceCheckLightFormat.time;

                state.form.simpleCron_deleteFiles_time =
                    expiredFilesLightFormat.time;
                state.form.simpleCron_deleteFiles_period =
                    expiredFilesLightFormat.periodType;
            }
        },
        [getStorageSettings.rejected.type](state) {
            state.loading = false;
        },
        [updateStorageSettings.pending.type](state) {
            state.loading = true;
        },
        [updateStorageSettings.fulfilled.type](
            state,
            action: PayloadAction<StorageSettingsDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
            const freeSpaceCheckLightFormat = fromCronToLight(
                action.payload.freeSpaceCheckSchedule,
            );
            const expiredFilesLightFormat = fromCronToLight(
                action.payload.expiredFilesSchedule,
            );
            if (freeSpaceCheckLightFormat && expiredFilesLightFormat) {
                state.form.simpleCron_checkFreeSpace_time =
                    freeSpaceCheckLightFormat.time;
                state.form.simpleCron_checkFreeSpace_period =
                    freeSpaceCheckLightFormat.periodType;

                state.form.simpleCron_deleteFiles_time =
                    expiredFilesLightFormat.time;
                state.form.simpleCron_deleteFiles_period =
                    expiredFilesLightFormat.periodType;
            }
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
        },
        [updateStorageSettings.rejected.type](state) {
            state.loading = false;
            enqueueError(i18n.t("admin-settings:error.edit"));
        },
    },
);
