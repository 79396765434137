import { TFunction } from "react-i18next";
import { DatagridColumn } from "src/components/DataGrid";
import { prettyBytes } from "src/helpers/prettyBytes";
import { LocalFileStorageDTO } from "src/services/generated";

export const getColumns: (
    t: TFunction,
    sumStorages: LocalFileStorageDTO,
) => DatagridColumn<LocalFileStorageDTO>[] = (t, sumStorages) => [
    {
        accessorKey: "fullPath",
        header: t("tableHeader.path"),
        footer: t("tableFooter.total"),
    },
    {
        accessorKey: "usedSpace",
        header: t("tableHeader.usedSpace"),
        Cell: ({ cell }) => {
            return prettyBytes(cell.getValue() as number, {
                hideSize: true,
                sizeToRound: "GB",
            });
        },
        footer: prettyBytes(sumStorages.usedSpace, {
            hideSize: true,
            sizeToRound: "GB",
        }),
        size: 100,
    },
    {
        accessorKey: "freeSpace",
        header: t("tableHeader.freeSpace"),
        Cell: ({ cell }) => {
            return prettyBytes(cell.getValue() as number, {
                hideSize: true,
                sizeToRound: "GB",
            });
        },
        footer: prettyBytes(sumStorages.freeSpace, {
            hideSize: true,
            sizeToRound: "GB",
        }),
        size: 100,
    },
    {
        accessorKey: "size",
        header: t("tableHeader.totalSpace"),
        Cell: ({ cell }) => {
            return prettyBytes(cell.getValue() as number, {
                hideSize: true,
                sizeToRound: "GB",
            });
        },
        footer: prettyBytes(sumStorages.size, {
            hideSize: true,
            sizeToRound: "GB",
        }),
        size: 100,
    },
];
