import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ButtonGroupToggle } from "src/components/ButtonGroupToggle";
import { InformCard } from "src/components/InformCard";
import { useTranslation } from "react-i18next";
import { ButtonToggleType } from "src/types/TabType";
import {
    Dataset,
    HorisontalBarChart,
} from "src/components/HorisontalBarChart/HorisontalBarChart";
import { ReactComponent as UsersAmountImg } from "src/icons/statistics/usersAmount.svg";
import { ReactComponent as ActiveUsersImg } from "src/icons/statistics/activeUsers.svg";
import { ReactComponent as UploadFilesImg } from "src/icons/statistics/uploadFiles.svg";
import { ReactComponent as UploadLimitImg } from "src/icons/statistics/uploadLimit.svg";
import { ReactComponent as UploadSizeImg } from "src/icons/statistics/uploadSize.svg";
import { StorageChartName } from "src/components/StorageChartName";
import { Preloader } from "src/components/Preloader";
import {
    receiveFileStats,
    receiveStats,
    resetPeriod,
} from "src/redux/pages/administrationStatistics/actions";
import moment from "moment";
import { IAdministrationStatisticsPage } from "src/types/redux/store/pages/administration/AdministrationStatisticsPage";
import { LicenseAlert } from "src/components/LicenseAlert";
import { prettyBytes } from "src/helpers/prettyBytes";
import dayjs, { Dayjs } from "dayjs";
import { DateRangePicker } from "src/components/DatePicker/DateRangePicker";
import { DatePicker } from "src/components/DatePicker/DatePicker";

enum StatisticPeriodType {
    MONTH = "MONTH",
    YEAR = "YEAR",
    PERIOD = "PERIOD",
}

const useStyles = makeStyles((theme: Theme) => ({
    tabs: {
        width: "100%",
        marginBottom: 20,
    },
    dateRange: {
        width: "100%",
    },
    periodBlock: {
        height: "100%",
        position: "relative",
        padding: "16px 24px",
        backgroundColor: "#fff",
        border: "1px solid #E7E7E7",
        borderRadius: 4,
    },
    limitChartName: {
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    limitChartNameValue: {
        color: theme.palette.primary.main,
    },
    fileStatsWrapper: {
        position: "relative",
        padding: "10px",
        margin: "-10px",
    },
    height100: {
        height: "100%",
    },
    informationMessage: {
        backgroundColor: "red",
    },
    resetButtonWrapper: {
        paddingLeft: "15px",
        marginBottom: "16px",
    },
    statistics: {
        overflowY: "auto",
        maxWidth: "none",
        [theme.breakpoints.down("xs")]: {
            padding: "16px",
        },
    },
    block: {
        "&:nth-child(1)": {
            order: 1,
        },
        "&:nth-child(2)": {
            order: 3,
        },
        "&:nth-child(3)": {
            order: 2,
        },
        "&:nth-child(4)": {
            order: 4,
        },
        [theme.breakpoints.up("sm")]: {
            order: "1 !important",
        },
    },
    cards: {
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
}));

const buttons: ButtonToggleType[] = [
    {
        label: "admin-statistics:periodType.month",
        value: StatisticPeriodType.MONTH,
    },
    {
        label: "admin-statistics:periodType.year",
        value: StatisticPeriodType.YEAR,
    },
    {
        label: "admin-statistics:periodType.period",
        value: StatisticPeriodType.PERIOD,
    },
];

const getMetrics = (all?: number) => {
    const divider = 3;
    if (all >= divider) {
        return Math.floor(all / divider);
    }
    return all;
};

export const AdministrationStatisticsPage = () => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loadingFilesStats, loadingStats, fileStats, stats } = useSelector<
        Store,
        IAdministrationStatisticsPage
    >(store => store.pages.administration.administrationStatisticsPage);
    const [periodType, setTypePeriod] = useState<StatisticPeriodType>(
        StatisticPeriodType.MONTH,
    );
    const [dateRange, setDateRange] = useState<Dayjs[]>(null);
    const [month, setMonth] = useState(dayjs());
    const [year, setYear] = useState(dayjs());
    const licenseTermDays = useMemo(() => {
        const licenseTerm = moment(new Date(stats?.licenseValidBefore));
        return licenseTerm.diff(moment(new Date()), "days") + 1;
    }, [stats?.licenseValidBefore]);

    useEffect(() => {
        dispatch(receiveStats());
    }, [dispatch]);

    const onChangeMonth = (date: Dayjs) => {
        setMonth(date);
        const startDate = date.startOf("month").format();
        const endDate = date.endOf("month").format();
        dispatch(receiveFileStats({ startDate, endDate }));
    };

    const onChangeYear = (date: Dayjs) => {
        setYear(year);
        const startDate = date.startOf("year").format();
        const endDate = date.endOf("year").format();
        dispatch(receiveFileStats({ startDate, endDate }));
    };

    const onChangeDateRange = (dateRange: Dayjs[]) => {
        setDateRange(dateRange);
        const startDate = dateRange[0]?.format();
        const endDate = dateRange[1]?.format();
        dispatch(receiveFileStats({ startDate, endDate }));
    };

    const onChangePeriodType = useCallback(
        (value: any) => {
            dispatch(resetPeriod());
            setTypePeriod(value);
            setMonth(dayjs());
            setYear(dayjs());
            setDateRange(null);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const onResetPeriod = () => {
        setDateRange(undefined);
        dispatch(resetPeriod());
    };

    const storagesDataSets = useMemo(() => {
        const datasets: Dataset[] = stats?.storages.map(storage => {
            return {
                all: storage.freeSpace + storage.usedSpace,
                completed: storage.usedSpace,
                name: <StorageChartName storage={storage} />,
            };
        });

        if (datasets?.length) {
            const allStoragesDataset = datasets.reduce(
                (total, dataset) => {
                    total.all += dataset.all;
                    total.completed += dataset.completed;
                    return total;
                },
                { all: 0, completed: 0, withDivider: true, name: undefined },
            );

            allStoragesDataset.name = (
                <StorageChartName
                    storage={{
                        fullPath: t("admin-statistics:storageChart.totalSpace"),
                        size: allStoragesDataset.all,
                        usedSpace: allStoragesDataset.completed,
                        freeSpace:
                            allStoragesDataset.all -
                            allStoragesDataset.completed,
                    }}
                />
            );

            datasets.unshift(allStoragesDataset);
        }

        return datasets;
    }, [stats?.storages, t]);

    return (
        <Grid container spacing={4} className={styles.statistics}>
            {loadingStats && <Preloader over />}
            <Grid
                item
                xs={12}
                sm={6}
                container
                spacing={4}
                className={`${styles.block} ${styles.cards}`}
            >
                <Grid item xs>
                    <InformCard
                        className={styles.height100}
                        name={t("admin-statistics:cardNames.usersCount")}
                        value={(stats?.usersCount || 0).toString()}
                        icon={UsersAmountImg}
                    />
                </Grid>
                <Grid item xs>
                    <InformCard
                        className={styles.height100}
                        name={t("admin-statistics:cardNames.activeUsers")}
                        value={(stats?.activeUsersCount || 0).toString()}
                        icon={ActiveUsersImg}
                    />
                </Grid>
                <Grid item xs>
                    <InformCard
                        className={styles.height100}
                        name={t("admin-statistics:cardNames.uploaLimit")}
                        icon={UploadLimitImg}
                    >
                        {prettyBytes(stats?.uploadLimit || 0)}
                    </InformCard>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} className={styles.block}>
                <HorisontalBarChart
                    className={styles.height100}
                    title={t("admin-statistics:licensesChart.title")}
                    total={stats?.licensesCount || 0}
                    metricSize={getMetrics(stats?.licensesCount)}
                    chartNameHeight={36}
                    chartNameWidth={62}
                    datasets={[
                        {
                            all: stats?.licensesCount || 0,
                            completed: stats?.activeLicensesCount || 0,
                            name: (
                                <div className={styles.limitChartName}>
                                    <div>
                                        {t(
                                            "admin-statistics:licensesChart.active",
                                        )}
                                    </div>
                                    <div className={styles.limitChartNameValue}>
                                        {stats?.activeLicensesCount || 0}
                                    </div>
                                </div>
                            ),
                        },
                    ]}
                >
                    {licenseTermDays < 31 ? (
                        <LicenseAlert
                            licenseTermDays={licenseTermDays}
                        ></LicenseAlert>
                    ) : null}
                </HorisontalBarChart>
            </Grid>
            <Grid item xs={12} sm={6} className={styles.block}>
                <div className={styles.periodBlock}>
                    <ButtonGroupToggle
                        className={styles.tabs}
                        buttons={buttons}
                        onChange={onChangePeriodType}
                        value={periodType}
                    />
                    {periodType === StatisticPeriodType.MONTH && (
                        <DatePicker
                            value={month}
                            label={t("common:datePicker.month")}
                            onChangeDate={onChangeMonth}
                            type="month"
                        />
                    )}
                    {periodType === StatisticPeriodType.YEAR && (
                        <DatePicker
                            value={year}
                            label={t("common:datePicker.year")}
                            onChangeDate={onChangeYear}
                            type="year"
                        />
                    )}
                    {periodType === StatisticPeriodType.PERIOD && (
                        <Grid container alignItems="center">
                            <DateRangePicker
                                className={styles.dateRange}
                                onReset={onResetPeriod}
                                label={t("common:dateRangePicker.periodTitle")}
                                value={dateRange}
                                onChangeDate={onChangeDateRange}
                            />
                        </Grid>
                    )}
                    <div className={styles.fileStatsWrapper}>
                        {loadingFilesStats && !loadingStats && (
                            <Preloader over />
                        )}
                        <Grid container spacing={4} className={styles.cards}>
                            <Grid item xs>
                                <InformCard
                                    name={t(
                                        "admin-statistics:cardNames.uploadCound",
                                    )}
                                    value={(
                                        fileStats?.uploadsCount || 0
                                    ).toString()}
                                    icon={UploadFilesImg}
                                />
                            </Grid>
                            <Grid item xs>
                                <InformCard
                                    name={t(
                                        "admin-statistics:cardNames.uploadSize",
                                    )}
                                    icon={UploadSizeImg}
                                >
                                    {prettyBytes(fileStats?.uploadsSize || 0)}
                                </InformCard>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={6} className={styles.block}>
                <HorisontalBarChart
                    className={styles.height100}
                    title={t("admin-statistics:storageChart.title")}
                    chartNameHeight={60}
                    chartNameWidth={120}
                    datasets={storagesDataSets}
                />
            </Grid>
        </Grid>
    );
};

export default AdministrationStatisticsPage;
