import {
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    TextFieldProps,
} from "@mui/material";
import { Controller } from "../../FieldController";
import React from "react";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";

type FieldInputProps = {
    onChange?: (v: Value) => void;
    onBlur?: () => void;
    labels: {
        from: string;
        to: string;
    };
    min?: number;
    max?: number;
    defaultValue?: Value;
} & Omit<TextFieldProps, "label" | "onChange">;

type Value = { from?: number; to?: number };

export const FieldInputRange = ({
    name,
    labels,
    defaultValue,
    min,
    max,
    ...inputProps
}: FieldInputProps) => {
    const handleChange = (v: Value, onChange: (e: any) => void) => {
        onChange(v);
        inputProps?.onChange?.(v);
    };

    const handleBlur = (
        v: Value,
        onBlur: () => void,
        onChange: (e: any) => void,
    ) => {
        onBlur();
        onChange(v);
        inputProps?.onBlur?.();
    };

    return (
        <Controller name={name} defaultValue={defaultValue}>
            {({ field: { value, onBlur, onChange } }) => {
                return (
                    <Row>
                        <FormControl>
                            <InputLabel>{labels.from}</InputLabel>
                            <Input
                                inputProps={{
                                    min,
                                    max,
                                }}
                                type="number"
                                value={value?.from || ""}
                                onBlur={e =>
                                    handleBlur(
                                        {
                                            ...value,
                                            from: Number(e.target.value),
                                        },
                                        onBlur,
                                        onChange,
                                    )
                                }
                                onChange={e => {
                                    handleChange(
                                        {
                                            ...value,
                                            from: Number(e.target.value),
                                        },
                                        onChange,
                                    );
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                handleChange(
                                                    { ...value, from: "" },
                                                    onChange,
                                                )
                                            }
                                            edge="end"
                                        >
                                            <Close />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel>{labels.to}</InputLabel>
                            <Input
                                inputProps={{
                                    min,
                                    max,
                                }}
                                type="number"
                                value={value?.to || ""}
                                onBlur={e =>
                                    handleBlur(
                                        {
                                            ...value,
                                            to: Number(e.target.value),
                                        },
                                        onBlur,
                                        onChange,
                                    )
                                }
                                onChange={e =>
                                    handleChange(
                                        {
                                            ...value,
                                            to: Number(e.target.value),
                                        },
                                        onChange,
                                    )
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                handleChange(
                                                    { ...value, to: "" },
                                                    onChange,
                                                )
                                            }
                                            edge="end"
                                        >
                                            <Close />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Row>
                );
            }}
        </Controller>
    );
};

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;
