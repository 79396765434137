import React, { useEffect, useState } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles, createStyles } from "@mui/styles";
import styleVariables from "../../../theme/variables";
import { Theme } from "@mui/material";

type ShowOnlySelectedProps = {
    setIsShowSelected: (arg: boolean) => void;
};
export const ShowOnlySelected = ({
    setIsShowSelected,
}: ShowOnlySelectedProps) => {
    const { t } = useTranslation("common");

    return (
        <FormControlLabel
            control={
                <Checkbox
                    onChange={e => {
                        setIsShowSelected(e.target.checked);
                    }}
                />
            }
            label={t("dataGrid.showOnlySelected")}
        />
    );
};

ShowOnlySelected.useSelected = <T,>({
    allData,
    getItemId,
    selectedKeys,
}: {
    allData: object;
    selectedKeys: string[];
    getItemId: (arg: T) => string;
}) => {
    const flattedData = Object.values(allData).flat();
    const filteredData =
        flattedData.filter(e => selectedKeys.includes(getItemId(e))) || [];
    return {
        filteredData,
    };
};

export const usePaginatedDataStore = <T,>({
    content,
    pageNumber,
}: {
    content: T[];
    pageNumber: number;
}) => {
    const [allData, setAllData] = useState({});

    useEffect(() => {
        content &&
            setAllData(d => {
                return { ...d, [pageNumber]: content };
            });
    }, [content, pageNumber]);

    return allData;
};

export const useFilterStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexContainer: {
            textAlign: "left",
            height: "calc(100% + 60px)",
            width: "100%",
            padding: 16,
            display: "flex",
            overflowY: "auto",
            flexDirection: "column",
            marginTop: "-60px", // Высота кнопок
            [theme.breakpoints.down("md")]: {
                marginTop: "0px", // Высота кнопок
            },
        },
        form: {
            display: "flex",
            flexDirection: "column",
            paddingTop: styleVariables.spacing * 7,
            flexGrow: 1,
            boxSizing: "border-box",
            paddingBottom: "4px",
        },
        filterLabel: {
            fontWeight: 500,
        },
        autocompleteOptionText: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }),
);

export const usePageStyles = makeStyles((theme: Theme) =>
    createStyles({
        height100: {
            height: "100%",
        },
        filterWrapper: {
            height: "100%",
            width: "320px",
            flexShrink: 0,
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
                flexDirection: "column",
            },
        },
        tableWrapper: {
            width: "100%",
            height: "calc(100% - 30px)",
            minWidth: 0,
            marginRight: 0,
            [theme.breakpoints.up("md")]: {
                marginRight: "16px",
            },
        },
        drawer: {
            width: "280px",
            [theme.breakpoints.up("xs")]: {
                width: "400px",
            },
            maxHeight: "100%",
            height: "100%",
            maxWidth: "100%",
            margin: "0",
            borderRadius: "0",
            padding: "0",
        },
    }),
);
