import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    Autocomplete,
} from "@mui/material";
import { createSelector } from "reselect";
import {
    CommonDialogType,
    DialogItem,
    DialogNames,
} from "../types/redux/store/dialogs/commonDialog";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { closeDialog } from "../redux/dialogs/commonDialog/actions";
import { makeStyles } from "@mui/styles";
import styleVariables from "../theme/variables";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "src/components/Form";
import { ReactComponent as Clear } from "../icons/clear.svg";
import { ClosingDialogTitle } from "./ModalWindows/СlosingDialogTitle";

interface UserReportDialogProps {
    name: DialogNames;
}

const mapState = createSelector<
    CommonDialogType,
    CommonDialogType["dialog"],
    DialogItem
>(
    state => state.dialog,
    dialog => dialog,
);

const useStyles = makeStyles({
    content: {
        minWidth: 352,
    },
    box: {
        maxWidth: 240,
        paddingBottom: styleVariables.spacing * 6,
    },
    radioGroup: {
        "&.MuiFormControl-root": {
            height: "fit-content",
            textAlign: "left",
            paddingBottom: styleVariables.spacing * 6,
        },
    },
    autocomplete: {
        "&.MuiFormControl-root": {
            paddingBottom: 0,
        },
    },
});

const users: string[] = [];
const radioValues = {
    all: "all",
    chooseUser: "chooseUser",
};

export const UserFilesReportDialog = (props: UserReportDialogProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { register, handleSubmit, setValue, control } = useForm({
        defaultValues: {
            time: "",
            numbersOfUsers: "",
            users: [],
        },
    });
    useEffect(() => {
        register("users");
    });

    const { name } = props;

    const dispatch = useDispatch();
    const { dialogName, isOpen } = useSelector((store: Store) =>
        mapState(store.dialogs.commonDialog),
    );

    const close = () => {
        dispatch(closeDialog(name));
    };
    const open = (): boolean => {
        if (dialogName === name && isOpen) {
            return true;
        }
        return false;
    };
    const download = () => {
        //TODO: Add download service
        close();
    };

    const [radioValue, setRadioValue] = useState(radioValues.all);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    return (
        <Dialog open={open()} onClose={close}>
            <ClosingDialogTitle onClose={close}>
                {t("admin-users:fileReportDialog.title")}
            </ClosingDialogTitle>
            <DialogContent className={classes.content}>
                <form onSubmit={handleSubmit(() => {})}>
                    <Box className={classes.box}>
                        <TextField
                            label={t("admin-users:fileReportForm.time")}
                            {...register("time")}
                        />
                    </Box>
                    <FormControl className={classes.radioGroup}>
                        <FormLabel>
                            {t("admin-users:fileReportForm.numbersOfUsers")}
                        </FormLabel>
                        <Controller
                            name="numbersOfUsers"
                            render={() => (
                                <RadioGroup
                                    value={radioValue}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel
                                        name="all"
                                        value={radioValues.all}
                                        control={<Radio color="primary" />}
                                        label={t(
                                            "admin-users:fileReportForm.allUsers",
                                        )}
                                    />
                                    <FormControlLabel
                                        name="chooseUser"
                                        value={radioValues.chooseUser}
                                        control={<Radio color="primary" />}
                                        label={t(
                                            "admin-users:fileReportForm.chooseUsers",
                                        )}
                                    />
                                </RadioGroup>
                            )}
                            control={control}
                        />
                    </FormControl>
                    {/* TODO: Replace on AutocompleteForEmail? */}
                    <Autocomplete
                        multiple
                        options={users}
                        freeSolo
                        renderTags={(value: string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <Chip
                                    key={index}
                                    label={option}
                                    size="small"
                                    variant="outlined"
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={params => (
                            <TextField
                                className={classes.autocomplete}
                                {...params}
                                label={t("admin-users:fileReportForm.users")}
                            />
                        )}
                        clearIcon={<Clear />}
                        onChange={(e, data) => {
                            setValue("users", data);
                        }}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="secondary" variant="outlined">
                    {t("admin-users:fileReportDialog.cancel")}
                </Button>
                <Button onClick={download} color="primary">
                    {t("admin-users:fileReportDialog.download")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
