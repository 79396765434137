import React, { useEffect } from "react";
import { ReactMaskProps, useIMask } from "react-imask";
import { InputMaskElement, FactoryOpts } from "imask";
import { TextField, StandardTextFieldProps } from "@mui/material";

type Handlers = Partial<
    Pick<
        ReactMaskProps<InputMaskElement, FactoryOpts>,
        "onAccept" | "onComplete"
    >
>;
export type MaskedInputProps = StandardTextFieldProps & {
    config: FactoryOpts;
} & Handlers;
export const MaskedInput = ({
    config,
    onAccept,
    onComplete,
    value = "",
    ...rest
}: MaskedInputProps) => {
    const {
        value: inputValue,
        ref,
        setValue,
    } = useIMask(config, { onAccept, onComplete });

    useEffect(() => {
        setValue(value as string);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return <TextField inputRef={ref} {...rest} value={inputValue} />;
};
