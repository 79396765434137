import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSuccess, enqueueError } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { settingsApiService } from "src/services/clientApi";
import { NotificationAboutDeletingMailHeadersDTO } from "src/services/generated";

export const getDeletedMessage = createAsyncThunk(
    "getDeletedMessage",
    async () => {
        return (
            await settingsApiService.getNotificationAboutDeletingMailHeaders()
        ).data;
    },
);

export const updateDeletedMessage = createAsyncThunk(
    "updateDeletedMessage",
    async (settings: NotificationAboutDeletingMailHeadersDTO, thankAPI) => {
        try {
            const response =
                await settingsApiService.updateNotificationAboutDeletingMailHeaders(
                    settings,
                );
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
            return response.data;
        } catch (error) {
            thankAPI.rejectWithValue(error);
            enqueueError(i18n.t("admin-settings:error.edit"));
        }
    },
);
