import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store/index";
import { EmptyFileInput } from "src/components/SendFilePage/EmptyFileInput";
import { AfterMessageSending } from "../components/SendFilePage/AfterMessageSending";
import { SendFilePageStates } from "../types/redux/store/pages/SendFilePage";
import { MessageEditor } from "../components/SendFilePage/MessageEditor";
import { changePasswordSwitchState } from "src/redux/pages/sendFile/actions";
import { Preloader } from "src/components/Preloader";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            display: "flex",
            justifyContent: "center",
            paddingTop: 120,
            marginBottom: 24,
            [theme.breakpoints.down("md")]: {
                paddingTop: 72,
            },
            [theme.breakpoints.down("sm")]: {
                paddingTop: 52,
                backgroundColor: "#fff",
            },
        },
    }),
);

const pageStates = new Map([
    [
        SendFilePageStates.AFTER_LINK_SENDING,
        <AfterMessageSending key={SendFilePageStates.AFTER_LINK_SENDING} />,
    ],
    [
        SendFilePageStates.AFTER_MESSAGE_SENDING,
        <AfterMessageSending key={SendFilePageStates.AFTER_MESSAGE_SENDING} />,
    ],
    [
        SendFilePageStates.MESSAGE_EDITING,
        <MessageEditor key={SendFilePageStates.MESSAGE_EDITING} />,
    ],
    [
        SendFilePageStates.MESSAGE_CREATING,
        <Box sx={{ width: "500px" }} key={SendFilePageStates.MESSAGE_CREATING}>
            <EmptyFileInput />
        </Box>,
    ],
]);

export const SendFile = () => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const { currentPageState, creatingMessage } = useSelector(
        (store: Store) => store.pages.sendFilePage,
    );

    useEffect(() => {
        return () => {
            dispatch(changePasswordSwitchState(false));
        };
    }, [currentPageState, dispatch]);

    return (
        <div className={styles.page}>
            {creatingMessage && <Preloader over />}
            {pageStates.get(currentPageState)}
        </div>
    );
};
