import styled from "@emotion/styled";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { DatePicker } from "src/components/DatePicker/DatePicker";
import { Controller } from "src/components/Form";
import { ReportsFileLifetimeIsActualEnum as Actuals } from "src/services/generated";

type Value = {
    isActual?: Actuals;
    actualDate?: Date;
};
export type FieldLifeTimeProps = {
    name: string;
    label?: string;
    labels: {
        title?: string;
        actual?: string;
        notActual?: string;
        actualDate?: string;
    };
    defaultValue?: Value;
};
export const FieldLifeTime = ({
    name,
    defaultValue = {},
    labels,
}: FieldLifeTimeProps) => (
    <Controller name={name} defaultValue={defaultValue}>
        {({ field: { onChange, value } }) => {
            return (
                <FormControl>
                    <Label>{labels.title}</Label>
                    <FormControlLabel
                        label={labels.actual}
                        control={
                            <Checkbox
                                checked={
                                    value.isActual === Actuals.ALIVE ||
                                    value.isActual === Actuals.ALL
                                }
                                onChange={(_, checked) => {
                                    onChange({
                                        ...value,
                                        isActual: checked
                                            ? value.isActual ===
                                              Actuals.NOT_ALIVE
                                                ? Actuals.ALL
                                                : Actuals.ALIVE
                                            : value.isActual === Actuals.ALL
                                              ? Actuals.NOT_ALIVE
                                              : undefined,
                                    });
                                }}
                            />
                        }
                    />
                    {(value.isActual === Actuals.ALIVE ||
                        value.isActual === Actuals.ALL) && (
                        <DatePicker
                            type="date"
                            label={labels.actualDate}
                            value={
                                value?.actualDate
                                    ? dayjs(value?.actualDate)
                                    : undefined
                            }
                            onReset={() => {
                                onChange({
                                    ...value,
                                    actualDate: undefined,
                                });
                            }}
                            onChangeDate={v => {
                                onChange({
                                    ...value,
                                    actualDate: v.toDate(),
                                });
                            }}
                        />
                    )}
                    <FormControlLabel
                        label={labels.notActual}
                        control={
                            <Checkbox
                                checked={
                                    value.isActual === Actuals.NOT_ALIVE ||
                                    value.isActual === Actuals.ALL
                                }
                                onChange={(_, checked) => {
                                    onChange({
                                        ...value,
                                        isActual: checked
                                            ? value.isActual === Actuals.ALIVE
                                                ? Actuals.ALL
                                                : Actuals.NOT_ALIVE
                                            : value.isActual === Actuals.ALL
                                              ? Actuals.ALIVE
                                              : undefined,
                                    });
                                }}
                            />
                        }
                    />
                </FormControl>
            );
        }}
    </Controller>
);

const Label = styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
`;
