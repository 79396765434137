import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    authenticate,
    logOut,
    loginViaRefreshToken,
    validateAccessToken,
    setCountDownParams,
} from "./action";
import {
    AuthenticationHandler,
    BlockedUsersCountDownParamsMap,
    UserBlockCountDownParams,
} from "../../../types/redux/store/pages/loginPage";
import { AuthenticationResponseModel } from "../../../services/generated";
import { getUserModelFromToken } from "src/helpers/jwt";
import {
    ACCESS_TOKEN_KEY,
    COUNT_DOWN_PARAMS,
    REFRESH_TOKEN_KEY,
} from "src/constants/localstorageKeys";

const getInitialState = (): AuthenticationHandler => {
    let countDownParams: BlockedUsersCountDownParamsMap = {};
    try {
        const object = JSON.parse(
            localStorage.getItem(COUNT_DOWN_PARAMS),
        ) as BlockedUsersCountDownParamsMap;
        const validObject = Object.keys(
            object,
        ).reduce<BlockedUsersCountDownParamsMap>((result, username) => {
            if (
                typeof object[username].blockTimeInMinutes === "number" &&
                typeof object[username].date === "number"
            ) {
                result[username] = object[username];
            }
            return result;
        }, {});
        countDownParams = validObject;
    } catch {}

    return {
        authentication: {
            accessToken: localStorage.getItem(ACCESS_TOKEN_KEY) || undefined,
            refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY) || undefined,
            user: {},
        },
        authenticationFailCode: null,
        temporaryBlocked: null,
        licenseHasExpired: null,
        authenticateLoading: false,
        validationLoading: false,
        isAuthenticated: false,
        countDownParams,
    };
};

export const authenticationReducer = createReducer<AuthenticationHandler>(
    getInitialState(),
    {
        [authenticate.pending.type](state) {
            state.authenticateLoading = true;
        },
        [authenticate.fulfilled.type](
            state,
            action: PayloadAction<AuthenticationResponseModel>,
        ) {
            const { accessToken, refreshToken } = action.payload || {};
            accessToken && localStorage.setItem(ACCESS_TOKEN_KEY, accessToken!);
            refreshToken &&
                localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken!);

            state.authentication.accessToken = accessToken;
            state.authentication.refreshToken = refreshToken;
            state.authentication.user = getUserModelFromToken(accessToken);

            state.authenticationFailCode = null;
            state.temporaryBlocked = null;
            state.licenseHasExpired = null;
            state.authenticateLoading = false;
        },
        [authenticate.rejected.type](
            state,
            action: PayloadAction<{
                errorStatus: number;
                temporaryBlocked?: boolean;
                licenseHasExpired?: boolean;
            }>,
        ) {
            state.authenticateLoading = false;
            state.authenticationFailCode = action.payload.errorStatus;
            state.temporaryBlocked = action.payload.temporaryBlocked;
            state.licenseHasExpired = action.payload.licenseHasExpired;
        },
        [logOut.type](state) {
            state.authentication.accessToken = "";
            state.authentication.user = null;
            state.authentication.refreshToken = "";
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(REFRESH_TOKEN_KEY);
        },
        [loginViaRefreshToken.fulfilled.type](
            state,
            action: PayloadAction<AuthenticationResponseModel>,
        ) {
            localStorage.setItem(ACCESS_TOKEN_KEY, action.payload.accessToken!);
            return {
                isFailAuthentication: false,
                authentication: {
                    accessToken: action.payload.accessToken,
                    refreshToken: action.payload.refreshToken,
                    user: getUserModelFromToken(
                        state.authentication.accessToken,
                    ),
                },
            };
        },
        [loginViaRefreshToken.rejected.type](state) {
            state.authentication.accessToken = "";
            state.authentication.refreshToken = "";
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(REFRESH_TOKEN_KEY);
        },
        [validateAccessToken.pending.type](state) {
            return {
                ...state,
                validationLoading: true,
            };
        },
        [validateAccessToken.fulfilled.type](
            state,
            action: PayloadAction<any>,
        ) {
            state.validationLoading = false;
            state.isAuthenticated = true;
            if (action.payload) {
                state.authentication.user = getUserModelFromToken(
                    state.authentication.accessToken,
                );
            }
        },
        [validateAccessToken.rejected.type](
            state,
            action: PayloadAction<boolean>,
        ) {
            // unanable to recreate token -> goto login
            state.isAuthenticated = true;
            state.validationLoading = false;
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            state.authenticationFailCode = null;
            state.authentication.accessToken = "";
            state.authentication.user = {
                email: undefined,
                firstName: undefined,
                lastName: undefined,
            };
        },
        [setCountDownParams.type](
            state,
            action: PayloadAction<{
                params: UserBlockCountDownParams | null;
                username: string;
            }>,
        ) {
            if (action.payload.params === null) {
                delete state.countDownParams[action.payload.username];
            } else {
                state.countDownParams[action.payload.username] =
                    action.payload.params;
            }
            localStorage.setItem(
                COUNT_DOWN_PARAMS,
                JSON.stringify(state.countDownParams),
            );
        },
    },
);
