import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    CommonDialogType,
    DialogItem,
} from "../../../types/redux/store/dialogs/commonDialog";
import {
    openDialog,
    closeDialog,
    addToDialogChain,
    deleteDialogFromChain,
} from "./actions";

const dialogInitialState: CommonDialogType = {
    dialog: {
        dialogName: "",
        isOpen: false,
    },
    dialogChain: [],
};

export const commonDialogReducer = createReducer<CommonDialogType>(
    dialogInitialState,
    {
        [openDialog.type](state, action: PayloadAction<DialogItem>) {
            state.dialog = action.payload;
        },
        [closeDialog.type](state, action: PayloadAction<DialogItem>) {
            state.dialog = action.payload;
        },
        [addToDialogChain.type](state, action: PayloadAction<string>) {
            state.dialogChain.push(action.payload);
        },
        [deleteDialogFromChain.type](state, action: PayloadAction<string>) {
            // const index = state.dialogChain.indexOf(action.payload);
            // state.dialogChain.splice(index, 1);
            state.dialogChain.shift();
        },
    },
);
