import React, { ReactNode, useState } from "react";
import { Box, Drawer, Grid, useMediaQuery, useTheme } from "@mui/material";
import { FilesReportsFilter } from "src/pages/Admin/reports/FilesReportsFilter";
import { AdminFilesReportsTable } from "src/pages/Admin/reports/FilesReportsTable";
import { DataGrid } from "src/components/DataGrid";
import { useFilesReports } from "./apiMethods";
import {
    ReportsFileDTO,
    ReportsFileLifetimeIsActualEnum,
    ReportsFilesRequestParams,
    ReportsFilesRequestParamsActionsEnum,
    ReportsFilesRequestParamsFileReportStatusesEnum,
} from "src/services/generated";
import { usePageStyles } from "./utils";
import { FileReportMessageDetail } from "src/components/ModalWindows/Admin/FileReportMessageDetail";

const defaultFilters: ReportsFilesRequestParams = {};
export const AdministrationFilesReportsPage = () => {
    const styles = usePageStyles();
    const [filters, setFilters] = useState(defaultFilters);
    const [activeReportFile, setActiveReportFile] =
        useState<ReportsFileDTO>(null);

    const {
        pagination,
        sorting,
        setPagination,
        setSorting,
        selected,
        setSelected,
    } = DataGrid.useTableState();
    const { data, isLoading, allData } = useFilesReports({
        filters,
        pagination,
        sorting,
    });
    const [isOpenFilter, setIsopenFilter] = useState(false);

    const onOpenFilter = () => {
        setIsopenFilter(true);
    };

    const onCloseFilter = () => {
        setIsopenFilter(false);
    };

    return (
        <>
            <Grid
                minWidth={0}
                container
                wrap="nowrap"
                className={styles.height100}
            >
                <Box className={styles.tableWrapper}>
                    <AdminFilesReportsTable
                        onOpenFilterDialog={onOpenFilter}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setSelected}
                        setActiveReportFile={setActiveReportFile}
                        onSortingChange={setSorting}
                        allData={allData}
                        table={{
                            data: data?.content || [],
                            rowCount: data?.totalElements,
                            state: {
                                isLoading,
                                pagination,
                                sorting,
                                rowSelection: selected,
                            },
                        }}
                    />
                </Box>

                <FilterDrawer
                    isOpenFilter={isOpenFilter}
                    onCloseFilter={onCloseFilter}
                >
                    <FilesReportsFilter
                        onChange={d => {
                            const fileStatus = d.fileStatus
                                .filter(s => s.checked)
                                .map(
                                    s =>
                                        s.value as ReportsFilesRequestParamsFileReportStatusesEnum,
                                );
                            setFilters({
                                fileName: d?.fileName,
                                date: {
                                    start: d?.date?.start.toISOString(),
                                    end: d?.date?.end.toISOString(),
                                },
                                emails: d.emails?.map(u => u.value),
                                actions:
                                    d.actions as unknown as ReportsFilesRequestParamsActionsEnum[],
                                text: d.text,
                                theme: d.theme,
                                fileSize: {
                                    from: d.size?.from,
                                    to: d.size?.to,
                                },
                                lifetime: {
                                    actualDate:
                                        d.lifetime?.isActual ===
                                            ReportsFileLifetimeIsActualEnum.ALIVE ||
                                        d.lifetime?.isActual ===
                                            ReportsFileLifetimeIsActualEnum.ALL
                                            ? d.lifetime?.actualDate?.toISOString()
                                            : undefined,
                                    isActual: d.lifetime?.isActual,
                                },
                                fileReportStatuses:
                                    // Если в фильтре выбрано все, это тоже самое что не выбрано ничего
                                    fileStatus.length > 1
                                        ? undefined
                                        : fileStatus[0],
                            });
                        }}
                    />
                </FilterDrawer>
            </Grid>
            <FileReportMessageDetail
                reportFile={activeReportFile}
                setReportFile={setActiveReportFile}
            />
        </>
    );
};
type FilterDrawerProps = {
    onCloseFilter: () => void;
    isOpenFilter: boolean;
    children: ReactNode;
};
const FilterDrawer = ({
    isOpenFilter,
    onCloseFilter,
    children,
}: FilterDrawerProps) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const styles = usePageStyles();

    return matches ? (
        <Drawer
            onClose={onCloseFilter}
            anchor="right"
            open={isOpenFilter}
            PaperProps={{
                className: styles.drawer,
            }}
        >
            {children}
        </Drawer>
    ) : (
        <Box className={styles.filterWrapper}>{children}</Box>
    );
};
