import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    checkIfFileInIntermediateState,
    fireFileWasNotUploadedPopup,
    isSuccessFile,
} from "src/helpers/file";
import {
    FileStatusUpdate,
    SendFilePage,
    SendFilePageStates,
} from "src/types/redux/store/pages/SendFilePage";
import {
    changePasswordSwitchState,
    changeDirectLinksEnabled,
    changeSendFilePageState,
    createMessage,
    createFileUUID,
    deleteFile,
    deleteMessage,
    sendMessage,
    setSendType,
    UpdateEmailForm,
    updateEmailForm,
    UpdateFileSettingsForm,
    updateFileSettingsForm,
    updateMessageModel,
    uploadFileToServer,
    changeFileUploadProgressAction,
    ChangeFileUploadProgressActionType,
    updateFileStatuses,
    changePassword,
    updateFileStatus,
    getDownloadVolumeValues,
    getDownloadStateFilesSendFile,
} from "src/redux/pages/sendFile/actions";
import {
    FileDTO,
    FileError,
    FileStateResponse,
    FileStatus,
    FileUploadProgressbarResponse,
    MessageModel,
    MessageModelSendingTypeEnum,
} from "src/services/generated";

export const sendFileInitialState: SendFilePage = {
    sendType: MessageModelSendingTypeEnum.LINK,
    uploadedFilesCount: 0,
    currentPageState: SendFilePageStates.MESSAGE_CREATING,
    isPasswordEnabled: false,
    directLinksEnabled: false,
    inProgressSendMessage: false,
    password: "",
    creatingMessage: false,
    memory: { storageFreeSpace: 0, uploadedBeforeSize: 0 },
    reservedFileId: [],
};

export const sendFilePageReducer = createReducer<SendFilePage>(
    sendFileInitialState,
    {
        [updateFileSettingsForm.type](
            state,
            action: PayloadAction<UpdateFileSettingsForm>,
        ) {
            Object.assign(state.message, action.payload);
        },
        [setSendType.type](
            state,
            action: PayloadAction<MessageModelSendingTypeEnum>,
        ) {
            if (state.message) {
                state.message.sendingType = action.payload;
            }
            state.sendType = action.payload;
        },
        [updateEmailForm.type](state, action: PayloadAction<UpdateEmailForm>) {
            Object.assign(state.message, action.payload);
        },

        [uploadFileToServer.fulfilled.type](
            state,
            action: PayloadAction<FileDTO>,
        ) {
            if (!action.payload || !state.message || !state.message.files) {
                return;
            }

            const currentFileIndex: number | undefined =
                state.message.files?.findIndex(file => {
                    return file.id === action.payload.id;
                });
            state.message.files[currentFileIndex] = action.payload;
            state.uploadedFilesCount++;
            if (checkIfFileInIntermediateState(action.payload)) {
                state.reservedFileId.push(action.payload.id);
            }
        },
        [uploadFileToServer.rejected.type](
            state,
            action: PayloadAction<string>,
        ) {
            if (!state.message?.files) {
                return;
            }

            const currentFileIndex = state.message.files?.findIndex(file => {
                return file.id === action.payload;
            });
            if (currentFileIndex) {
                state.message.files!.slice(currentFileIndex, 1);
            }
        },

        [updateFileStatuses.fulfilled.type](
            state,
            action: PayloadAction<MessageModel>,
        ) {
            const filesWithUpdatedStatuses = action.payload.files!;
            let currentFilesState = state.message?.files!;
            filesWithUpdatedStatuses.forEach(file => {
                let existingFileWithOldStatus = currentFilesState.find(
                    currentStateFile => currentStateFile.id === file.id,
                );
                if (existingFileWithOldStatus !== undefined) {
                    existingFileWithOldStatus.status = file.status;
                }
            });
        },
        [deleteMessage.type](state) {
            state.message = undefined;
            state.uploadedFilesCount = 0;
        },

        [createMessage.pending.type](state) {
            state.creatingMessage = true;
        },
        [createMessage.fulfilled.type](
            state,
            action: PayloadAction<MessageModel, string, { arg: MessageModel }>,
        ) {
            action.payload.sendingType = MessageModelSendingTypeEnum.LINK;
            state.message = action.payload;
            state.currentPageState = SendFilePageStates.MESSAGE_EDITING;
            state.creatingMessage = false;
        },
        [createMessage.rejected.type](state) {
            state.creatingMessage = false;
        },

        [createFileUUID.type](state, action: PayloadAction<FileDTO>) {
            if (state.message && !state.message.files) {
                state.message.files = [];
            }
            state.message?.files?.unshift(action.payload);
        },

        [deleteFile.fulfilled.type](state, action: PayloadAction<string>) {
            const currentFileIdIndex = state.message?.files?.findIndex(
                file => file.id === action.payload,
            );
            if (currentFileIdIndex !== undefined && currentFileIdIndex !== -1) {
                state.message?.files?.splice(currentFileIdIndex, 1);
                state.uploadedFilesCount =
                    state.message?.files?.filter(isSuccessFile).length || 0;
            }
            const currentReservedFileIdIndex = state.reservedFileId?.findIndex(
                id => id === action.payload,
            );
            if (
                currentReservedFileIdIndex !== undefined &&
                currentReservedFileIdIndex !== -1
            ) {
                state.reservedFileId?.splice(currentReservedFileIdIndex, 1);
            }
        },

        [sendMessage.pending.type](state) {
            state.inProgressSendMessage = true;
        },

        [sendMessage.fulfilled.type](
            state,
            action: PayloadAction<MessageModel>,
        ) {
            state.message = action.payload;
            state.inProgressSendMessage = false;
        },

        [sendMessage.rejected.type](state) {
            state.inProgressSendMessage = false;
        },

        [updateMessageModel.fulfilled.type](
            state,
            action: PayloadAction<MessageModel>,
        ) {
            state.message = action.payload;
            state.message.files = action.payload.files;
        },
        [changePassword.type](state, action: PayloadAction<string>) {
            state.password = action.payload;
        },
        [changeSendFilePageState.type](
            state,
            action: PayloadAction<SendFilePageStates>,
        ) {
            state.currentPageState = action.payload;
        },

        [changePasswordSwitchState.type](
            state,
            action: PayloadAction<boolean>,
        ) {
            state.isPasswordEnabled = action.payload;
            state.password = "";
        },
        [changeDirectLinksEnabled.type](state, action: PayloadAction<boolean>) {
            state.directLinksEnabled = action.payload;
        },

        [changeFileUploadProgressAction.type](
            state,
            action: PayloadAction<ChangeFileUploadProgressActionType>,
        ) {
            const file = state.message?.files?.find(
                file => action.payload.fileId === file.id,
            );
            if (file) {
                (file as any).progress = action.payload.progress;
            }
        },

        [getDownloadVolumeValues.fulfilled.type](
            state,
            action: PayloadAction<FileUploadProgressbarResponse>,
        ) {
            state.memory = action.payload;
        },

        [updateFileStatus.type](
            state,
            action: PayloadAction<FileStatusUpdate>,
        ) {
            const file = state.message?.files?.find(
                file => action.payload.fileId === file.id,
            );
            if (file) {
                file.status = action.payload.status as FileStatus;
            }
        },
        [getDownloadStateFilesSendFile.fulfilled.type](
            state,
            action: PayloadAction<FileStateResponse>,
        ) {
            const files = state.message?.files?.filter(
                file =>
                    action.payload.files[file.id] &&
                    file.id === action.payload.files[file.id].id,
            );
            if (files?.length) {
                files.map(file => {
                    file.status = action.payload.files[file.id]
                        .status as FileStatus;
                    file.error = action.payload.files[file.id]
                        .error as FileError;
                    return file;
                });
                files
                    .filter(file => file.error === FileError.INFECTED)
                    .forEach(file => fireFileWasNotUploadedPopup(file.name));
            }
            state.reservedFileId = state.reservedFileId.filter(id =>
                checkIfFileInIntermediateState(action.payload.files[id]),
            );
        },
    },
);
