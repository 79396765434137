import Tabs from "@mui/material/Tabs";
import Tab, { TabProps } from "@mui/material/Tab";
import React, { useMemo } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routePaths } from "../routes/routePaths";
import { NavLinkButton } from "src/types/NavLinkButton";
import { UserModelRolesEnum } from "../services/generated";
import { Store } from "src/types/redux/store";
import { useSelector } from "react-redux";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        fontSize: {
            fontSize: 12,
        },
    }),
);

const buttons: NavLinkButton[] = [
    {
        name: "common:header.sendFile",
        url: routePaths.sendFile,
        role: UserModelRolesEnum.STANDARD_USER,
    },
    {
        name: "common:header.requestFile",
        url: routePaths.requestFile,
        role: UserModelRolesEnum.STANDARD_USER,
    },
    {
        name: "common:header.myFile",
        url: routePaths.myFiles,
        role: UserModelRolesEnum.STANDARD_USER,
    },
    {
        name: "common:header.administration",
        url: routePaths.administration,
        role: UserModelRolesEnum.ADMIN,
    },
];

const LinkTab: React.ComponentType<TabProps & LinkProps> =
    Tab as React.ComponentType<TabProps & LinkProps>;

interface NavLinksProps {
    className?: string;
}

export function NavLinks(props: NavLinksProps) {
    const { className } = props;
    const styles = useStyle();
    const { t } = useTranslation();
    const location = useLocation();
    const { user } = useSelector(
        (store: Store) => store.authenticationHandler.authentication,
    );

    const userRoles = user?.roles;

    const filteredButtons = useMemo(() => {
        return buttons.filter(
            button =>
                !button.hasOwnProperty("role") ||
                userRoles?.includes(button.role!),
        );
    }, [userRoles]);

    let currentRoute: any =
        filteredButtons.find(button => {
            const regExp = new RegExp(`^${button.url}`, "i");
            return regExp.test(location.pathname);
        }) || filteredButtons[0];
    currentRoute = currentRoute ? currentRoute.url : false;

    return (
        <Tabs
            value={currentRoute}
            indicatorColor="primary"
            aria-label="disabled tabs example"
            className={`${styles.fontSize} ${className}`}
        >
            {filteredButtons.map(button => (
                <LinkTab
                    value={button.url}
                    to={button.url}
                    label={t(button.name)}
                    component={Link}
                    key={button.url}
                    style={{ minWidth: 0 }}
                />
            ))}
        </Tabs>
    );
}
