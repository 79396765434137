import React from "react";
import { useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        maxHeight: 50,
    },
}));

interface LogoProps {
    className?: string;
}

export const Logo: React.FC<LogoProps> = ({ className }) => {
    const styles = useStyles();
    const { appLogoInfo } = useSelector((store: Store) => store.appLogoInfo);

    return (
        appLogoInfo && (
            <img
                className={classNames(styles.logo, className)}
                src={appLogoInfo.logoPath}
                alt=""
                id="logo"
            />
        )
    );
};
