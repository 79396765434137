import dayjs from "dayjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Languages } from "./types/Languages";
import arraySupport from "dayjs/plugin/arraySupport";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import "dayjs/locale/ru";
import "dayjs/locale/en";

dayjs.extend(arraySupport);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

export const useDayJs = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        document.cookie = `lang=${i18n.language.toLowerCase()}`;
        if (i18n.language === Languages.RU) {
            dayjs.locale("ru");
        } else {
            dayjs.locale("en");
        }
    }, [i18n.language]);
};
