import { CircularProgress } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { AfterTechSupportMessageSending } from "src/components/TechSupport/AfterTechSupportMessageSending";
import { TechSupportMessageEditor } from "src/components/TechSupport/TechSupportMessageEditor";
import { Store } from "src/types/redux/store";
import { TechSupportPageStates } from "src/types/redux/store/pages/TechSupportPage";

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            justifyContent: "center",
            display: "flex",
            margin: "auto",
            maxWidth: "414px",
            paddingTop: "80px",
            "@media screen and (max-width: 480px)": {
                padding: "48px 24px 0px",
                width: "100%",
            },
            "@media screen and (min-width: 481px) and (max-width: 1024px)": {
                paddingTop: "120px",
            },
        },
        circularProgress: {
            textAlign: "center",
            margin: "10vh auto",
        },
    }),
);

const pageStates = new Map([
    [
        TechSupportPageStates.MESSAGE_CREATING,
        <TechSupportMessageEditor
            key={TechSupportPageStates.MESSAGE_CREATING}
        />,
    ],
    [
        TechSupportPageStates.AFTER_MESSAGE_SENDING,
        <AfterTechSupportMessageSending
            key={TechSupportPageStates.AFTER_MESSAGE_SENDING}
        />,
    ],
]);

export const TechSupport = () => {
    const styles = useStyles();
    const { currentPageState, inProgress } = useSelector(
        (store: Store) => store.pages.techSupportPage,
    );

    return (
        <div className={styles.container}>
            {inProgress ? (
                <div className={styles.circularProgress}>
                    <CircularProgress />{" "}
                </div>
            ) : (
                pageStates.get(currentPageState)
            )}
        </div>
    );
};
