import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import React from "react";

type ExpanderProps = {
    isExpanding: boolean;
    setIsExpanding: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Expander = ({ isExpanding, setIsExpanding }: ExpanderProps) => {
    const toggleExpand = () => {
        setIsExpanding(isExpanding => !isExpanding);
    };

    return (
        <Box onClick={toggleExpand}>
            {isExpanding ? <StyledArrowDropDown /> : <StyledArrowRight />}
        </Box>
    );
};

export const StyledArrowDropDown = styled(ArrowDropDown)`
    cursor: pointer;
`;

export const StyledArrowRight = styled(ArrowRight)`
    cursor: pointer;
`;
