import React, { useCallback, useEffect, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Theme,
} from "@mui/material";
import { FileDisplayContainer } from "../FileDisplayContainer/FileDisplayContainer";
import { FileDisplay } from "../FileDisplay/FileDisplay";
import { FileSettingsForm } from "../FileSettingsForm";
import { FileSendBox } from "../FileSendBox/FileSendBox";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import {
    changeSendFilePageState,
    deleteFile,
    deleteMessage,
    updateFileStatuses,
    uploadFiles,
    getDownloadVolumeValues,
    getDownloadStateFilesSendFile,
} from "src/redux/pages/sendFile/actions";
import { useTranslation } from "react-i18next";
import { checkIfFileInIntermediateState } from "src/helpers/file";
import _ from "lodash";
import { LeaveUnsaveChangesDialog } from "../LeaveUnsaveChangesDialog";
import { SendFilePageStates } from "src/types/redux/store/pages/SendFilePage";
import {
    FileStatus,
    ReceivedMessageResponseDataMessageTypeEnum,
} from "src/services/generated";
import { deleteMessageOnServer } from "src/redux/pages/myFiles/actions";
import { FileViewModel } from "src/types/FileDisplay";
import { FileMemoryStatus } from "../FileMemoryStatus/FileMemoryStatus";
import { PublicSettingsDTO } from "src/services/generated";
import { ClosingDialogTitle } from "../ModalWindows/СlosingDialogTitle";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        loadFilesBox: {
            [theme.breakpoints.down("md")]: {
                marginBottom: "72px",
            },
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                marginBottom: "64px",
            },
        },
        fileDisplayContainer: {
            marginBottom: "20px",
            [theme.breakpoints.down("md")]: {
                marginBottom: "24px",
            },
            [theme.breakpoints.down("sm")]: {
                marginBottom: "16px",
            },
        },
        wrapper: {
            width: "100%",
            [theme.breakpoints.up("lg")]: {
                paddingRight: "150px",
                paddingLeft: "150px",
            },
        },
        flexItem: {
            [theme.breakpoints.up("sm")]: {
                maxWidth: "446px",
            },
        },
        circularProgress: {
            margin: "10vh auto",
        },
        cancelDialogContent: {
            textAlign: "left",
        },
    }),
);

const StyledDialog = withStyles({
    paperWidthSm: {
        width: "400px",
    },
})(Dialog);

export const MessageEditor = () => {
    const styles = useStyle();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] =
        useState(false);

    const {
        message,
        uploadedFilesCount,
        inProgressSendMessage,
        memory,
        reservedFileId,
    } = useSelector((store: Store) => store.pages.sendFilePage);

    const { encryptionState } = useSelector<Store, PublicSettingsDTO>(
        store => store.publicSettings,
    );

    useEffect(() => {
        dispatch(getDownloadVolumeValues(message.id));
    }, [message.id, dispatch]);

    useEffect(() => {
        const someFileInIntermediateState = message?.files?.some(
            checkIfFileInIntermediateState,
        );
        let dispatcher = _.debounce(() => {
            dispatch(updateFileStatuses(message.id));
        }, 5000);
        if (someFileInIntermediateState) {
            dispatcher();
        }
        //TODO update to make less queries
        return () => {
            dispatcher.cancel();
        };
    }, [message, dispatch]);

    useEffect(() => {
        let dispatcher = _.debounce(() => {
            dispatch(
                getDownloadStateFilesSendFile({ fileIds: reservedFileId }),
            );
        }, 5000);
        if (reservedFileId.length) {
            dispatcher();
        }
        //TODO update to make less queries
        return () => {
            dispatcher.cancel();
        };
    }, [reservedFileId, dispatch]);

    const onUploadFiles = (files: File[]) => {
        dispatch(uploadFiles({ files: files, encrypted: encryptionState }));
    };

    const onDeleteFile = (file: FileViewModel) => {
        dispatch(
            deleteFile({
                file,
                messageId: message?.id!,
            }),
        );
    };

    const openConfirmCancelDialog = () => {
        setConfirmCancelDialogOpen(true);
    };

    const closeConfirmCancelDialog = () => {
        setConfirmCancelDialogOpen(false);
    };

    const deleteMessageEverywhere = useCallback(() => {
        dispatch(
            deleteMessageOnServer({
                messageId: message.id!,
                messageType: ReceivedMessageResponseDataMessageTypeEnum.MESSAGE,
            }),
        );
        dispatch(deleteMessage());
    }, [dispatch, message.id]);

    const onCancel = () => {
        deleteMessageEverywhere();
        dispatch(changeSendFilePageState(SendFilePageStates.MESSAGE_CREATING));
    };
    const onLeavePage = useCallback(() => {
        const notLoadedFiles = message?.files?.filter(
            file => file.status !== FileStatus.READY,
        );

        if (!notLoadedFiles?.length) {
            return;
        }

        if (notLoadedFiles.length === message.files.length) {
            deleteMessageEverywhere();
            return;
        }

        notLoadedFiles.forEach(file =>
            dispatch(deleteFile({ file, messageId: message.id! })),
        );
    }, [deleteMessageEverywhere, dispatch, message.files, message.id]);

    return inProgressSendMessage ? (
        <CircularProgress className={styles.circularProgress} />
    ) : (
        <Grid
            container
            justifyContent="space-around"
            spacing={8}
            className={styles.wrapper}
        >
            <Grid
                item
                xs={12}
                md={6}
                className={`${styles.flexItem} ${styles.loadFilesBox}`}
            >
                <div className={styles.fileDisplayContainer}>
                    <FileDisplayContainer
                        onUploadFiles={onUploadFiles}
                        height={241}
                        downloadedInfo={{
                            downloadedCount: uploadedFilesCount,
                            freeSpace: "X Mb",
                            totalCount: message?.files?.length || 0,
                            usedSpace: "Y Mb",
                        }}
                    >
                        {message?.files &&
                            message?.files.map((file, i) => (
                                <FileDisplay
                                    key={i}
                                    file={file}
                                    isView={false}
                                    onDeleteFile={onDeleteFile}
                                />
                            ))}
                    </FileDisplayContainer>
                    <FileMemoryStatus files={message.files} memory={memory} />
                </div>
                <FileSettingsForm />
            </Grid>
            <Grid item xs={12} md={6} className={styles.flexItem}>
                <FileSendBox onCancel={openConfirmCancelDialog} />
            </Grid>
            <LeaveUnsaveChangesDialog
                when={Boolean(message.files?.length)}
                title={t("common:modals.dataLossWindow.title")}
                cancelButtonText={t("common:modals.dataLossWindow.cancel")}
                confirmButtonText={t("common:modals.dataLossWindow.confirm")}
                onConfirmLeave={onLeavePage}
            >
                {t("common:modals.dataLossWindow.content")}
            </LeaveUnsaveChangesDialog>
            <StyledDialog
                open={confirmCancelDialogOpen}
                onClose={closeConfirmCancelDialog}
            >
                <ClosingDialogTitle onClose={closeConfirmCancelDialog}>
                    {t("common:modals.cancelSendFiles.title")}
                </ClosingDialogTitle>
                <DialogContent className={styles.cancelDialogContent}>
                    {t("common:modals.cancelSendFiles.content")}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeConfirmCancelDialog}
                        color="secondary"
                    >
                        {t("common:modals.cancelSendFiles.cancel")}
                    </Button>
                    <Button onClick={onCancel} color="primary">
                        {t("common:modals.cancelSendFiles.confirm")}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </Grid>
    );
};
