import { ComponentsOverrides } from "@mui/material/styles/overrides";
import styleVariables from "../variables";

export const MuiTabs: ComponentsOverrides["MuiTabs"] | any = {
    indicator: {
        left: 0,
    },
    vertical: {
        "& .MuiTab-wrapper": {
            alignItems: "start",
            paddingLeft: styleVariables.spacing * 4,
        },
    },
};

export default MuiTabs;
