import React from "react";
import { TFunction } from "i18next";
import { ClassNameMap } from "@mui/material";
import moment from "moment";

import { DatagridColumn } from "src/components/DataGrid";
import { prettyBytes } from "src/helpers/prettyBytes";
import { LogFileDTO } from "src/services/generated";
import { commonDateTimeFormat } from "src/constants/momentFormats";
import { onViewLogFile } from "./utils";

export const getColumns: (
    t: TFunction,
    styles: ClassNameMap,
) => DatagridColumn<LogFileDTO>[] = (t, styles) => [
    {
        accessorKey: "name",
        header: t("admin-logFiles:tableHeader.fileName"),
        Cell: ({ cell, row }) => (
            <span
                className={`${styles.viewFile}`}
                onClick={() => onViewLogFile(row.original)}
            >
                {cell.getValue() as string}
            </span>
        ),
        enableSorting: false,
    },
    {
        accessorKey: "size",
        header: `${t("admin-logFiles:tableHeader.size")}`,
        Cell: ({ cell }) => prettyBytes(cell.getValue() as number),
        size: 106,
        grow: false,
        enableSorting: false,
    },
    {
        accessorKey: "createdDateTime",
        header: `${t("admin-logFiles:tableHeader.date")}`,
        Cell: ({ cell }) => {
            return moment(cell.getValue() as string).format(
                commonDateTimeFormat(),
            );
        },
        size: 144,
        grow: false,
    },
];
