import React, { FC, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { ResponseMessageEdit } from "../components/ResponseMessage/ResponseMessageEdit";
import { ResponseMessagePageState } from "../types/redux/store/pages/ResponseMessage";
import { AfterResponseMessageEditing } from "../components/ResponseMessage/AfterResponseMessageEditing";
import { Preloader } from "src/components/Preloader";
import { ErrorCodes, ErrorPage } from "./ErrorPage/ErrorPage";
import { useParams } from "react-router-dom";
import { createResponseMessage } from "src/redux/pages/responseMessage/actions";
import { Theme } from "@mui/material";

const useStyle = makeStyles((theme: Theme) => ({
    page: {
        marginTop: "97px",
        [theme.breakpoints.down("md")]: {
            marginTop: "40px",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "36px",
        },
    },
}));

const responseMessagePageStates = new Map([
    [
        ResponseMessagePageState.RESPONSE_MESSAGE_EDIT,
        <ResponseMessageEdit
            key={ResponseMessagePageState.RESPONSE_MESSAGE_EDIT}
        />,
    ],
    [
        ResponseMessagePageState.AFTER_RESPONSE_MESSAGE_EDITING,
        <AfterResponseMessageEditing
            key={ResponseMessagePageState.AFTER_RESPONSE_MESSAGE_EDITING}
        />,
    ],
]);

export const ResponseMessagePage: FC = () => {
    const { requestMessageUUID } = useParams<{ requestMessageUUID: string }>();
    const styles = useStyle();
    const dispatch = useDispatch();
    const {
        responseMessagePageState,
        inProgress,
        responseMessage,
        linkExpired,
    } = useSelector((store: Store) => store.pages.responseMessagePage);

    useEffect(() => {
        dispatch(createResponseMessage(requestMessageUUID));
    }, [dispatch, requestMessageUUID]);

    return (
        <div className={styles.page}>
            {inProgress && <Preloader over />}
            {responseMessage &&
                responseMessagePageStates.get(responseMessagePageState)}
            {linkExpired && <ErrorPage errorType={ErrorCodes.GENERAL} />}
        </div>
    );
};
