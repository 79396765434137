import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ExtendMessageMailHeadersDTO } from "src/services/generated";
import { DeletedMessageSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getDeletedMessage, updateDeletedMessage } from "./actions";

const deletedMessageSettingsInitialState: DeletedMessageSettingsPage = {
    loading: false,
};

export const deletedMessageSettingsReducer =
    createReducer<DeletedMessageSettingsPage>(
        deletedMessageSettingsInitialState,
        {
            [getDeletedMessage.pending.type](state) {
                state.loading = true;
            },
            [getDeletedMessage.fulfilled.type](
                state,
                action: PayloadAction<ExtendMessageMailHeadersDTO>,
            ) {
                state.loading = false;
                state.form = action.payload;
            },
            [getDeletedMessage.rejected.type](state) {
                state.loading = false;
            },
            [updateDeletedMessage.pending.type](state) {
                state.loading = true;
            },
            [updateDeletedMessage.fulfilled.type](state) {
                state.loading = false;
            },
            [updateDeletedMessage.rejected.type](state) {
                state.loading = false;
            },
        },
    );
