import { shadow } from "./shadow";
import { color } from "./color";
import spacingValue from "./spacingValue";

export const styleVariables = {
    shadow: shadow,
    color: color,
    spacing: spacingValue,
};

export default styleVariables;
