import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, FormControlLabel, Switch } from "@mui/material";
import { CommonAdminForm } from "../../../components/Admin/commonAdminForm";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../../types/redux/store";
import { UserDeactivationSettingsPage } from "../../../types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { useForm } from "src/components/Form";
import { UserExpireSettingsDTO } from "../../../services/generated";
import {
    getUserExpireSettings,
    updateUserExpireSettings,
} from "../../../redux/pages/administrationSettings/deactivation/actions";
import { Preloader } from "../../../components/Preloader";

export const Deactivation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { loading, form } = useSelector<Store, UserDeactivationSettingsPage>(
        store =>
            store.pages.administration.administrationSettings
                .userDeactivationSettingsPage,
    );
    const {
        handleSubmit,
        watch,
        register,
        reset,
        setValue,
        setError,
        errors,
        clearErrors,
    } = useForm<UserDeactivationSettingsPage["form"]>({
        defaultValues: form,
    });
    const { userExpireEnabled, userExpireDays } = watch();

    useEffect(() => {
        dispatch(getUserExpireSettings());
    }, [dispatch]);

    useEffect(() => {
        reset(form);
    }, [form, reset]);

    const onSubmit = (data: UserExpireSettingsDTO) => {
        dispatch(updateUserExpireSettings(data));
    };

    const disableSubmit = (): boolean => {
        return (
            userExpireEnabled && (!userExpireDays || !!errors["userExpireDays"])
        );
    };

    const handleDeactivationModeToggle = () => {
        userExpireEnabled ? deactivationOff() : deactivationOn();
    };
    const deactivationOn = () => {
        setValue("userExpireEnabled", true);
    };
    const deactivationOff = () => {
        setValue("userExpireEnabled", false);

        setValue("userExpireDays", null);
    };

    const [shrink, setShrink] = useState(!!userExpireDays);

    useEffect(() => {
        if (!shrink) {
            setShrink(!!userExpireDays);
        }
    }, [userExpireDays, shrink]);

    const handleFocus = () => {
        setShrink(true);
    };

    const handleBlur = () => {
        setShrink(!!userExpireDays);
    };

    useEffect(() => {
        clearErrors("userExpireDays");
        if (userExpireDays && !isIntegerAndPositive(userExpireDays)) {
            setError("userExpireDays", {
                message: t(
                    "admin-settings:autoDeactivationMode.onlyPositiveDayValue",
                ),
            });
        }
    }, [userExpireDays, userExpireEnabled, setError, clearErrors, t]);

    const isIntegerAndPositive = (data: number): boolean => {
        return (
            Boolean(Number(data)) && Number.isInteger(Number(data)) && data > 0
        );
    };

    return (
        <CommonAdminForm
            onSubmit={handleSubmit(onSubmit)}
            disableSubmit={disableSubmit()}
            disableButtonTooltipText={t(
                "admin-settings:autoDeactivationMode.disableButtonTooltipText",
            )}
        >
            {loading && <Preloader over />}
            <Grid container>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={userExpireEnabled}
                                onChange={handleDeactivationModeToggle}
                                color="primary"
                                {...register("userExpireEnabled", {
                                    onChange: handleDeactivationModeToggle,
                                })}
                            />
                        }
                        label={t(
                            "admin-settings:autoDeactivationMode.toggleLabel",
                        )}
                    />
                    <TextField
                        disabled={!userExpireEnabled}
                        error={!!errors["userExpireDays"]}
                        label={t("admin-settings:autoDeactivationMode.label")}
                        helperText={
                            !!errors["userExpireDays"]
                                ? errors.userExpireDays.message
                                : t(
                                      "admin-settings:autoDeactivationMode.helperText",
                                  )
                        }
                        InputLabelProps={{ shrink }}
                        {...register("userExpireDays", {
                            onBlur: handleBlur,
                        })}
                        onFocus={handleFocus}
                    />
                </Grid>
            </Grid>
        </CommonAdminForm>
    );
};
