import React, { useState, useRef, FC } from "react";
import { Button, Theme, Input } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import uuid from "uuid/v1";
import { uploadFiles } from "../../redux/pages/sendFile/actions";
import { useDispatch, useSelector } from "react-redux";
import { getFilesAsync } from "src/helpers/file";
import classNames from "classnames";
import { prettyBytes } from "../../helpers/prettyBytes";
import { Store } from "../../types/redux/store";
import { PublicSettingsDTO } from "src/services/generated";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            position: "absolute",
            left: 0,
            top: 0,
            height: "0",
            width: "0",
            opacity: 0,
        },
        label: {
            position: "absolute",
            left: 0,
            top: 0,
            height: "0",
            width: "0",
            opacity: 0,
        },
        container: {
            position: "relative",
            border: "1px dashed #1A1F2999",
            boxSizing: "border-box",
            borderRadius: "4px",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "46px 10px 32px",
            width: "100%",
            minWidth: 0,
        },
        dragOver: {
            backgroundColor: "#EDF0FC99",
        },
        text: {
            color: "#1A1F29",
            opacity: "0.6",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "19px",
            marginTop: "24px",
            textAlign: "center",
        },
        limit: {
            color: "#1A1F29",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            paddingTop: 8,
        },
        middleSizeImage: {
            width: "158px",
        },
        middleSizeButton: {
            marginTop: "42px",
        },
        smallSizeContainer: {
            paddingTop: 32,
        },
        smallSizeImage: {
            width: "102px",
        },
        smallSizeButton: {
            marginTop: "37px",
        },
        andFoldersLine: {
            marginTop: "6px",
            fontSize: "14px",
        },
        andFolderLabler: {
            cursor: "pointer",
            fontWeight: "500",
            marginLeft: "4px",
            color: "#003FBD",
            "&:hover": {
                color: "#6577CB",
                textDecoration: "underline",
            },
        },
    }),
);

type EmptyFileInputProps = {
    onFileUpload?: (files: File[]) => void;
    emptyFileInputSize?: EmptyFileInputSize;
};

interface SizeStyle {
    container?: string;
    button: string;
    image: string;
}

export enum EmptyFileInputSize {
    MEDIUM,
    SMALL,
}

export const EmptyFileInput: FC<EmptyFileInputProps> = ({
    onFileUpload,
    emptyFileInputSize = EmptyFileInputSize.MEDIUM,
}) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const labelRef = useRef<HTMLLabelElement>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const { fileSizeUploadLimit, encryptionState } = useSelector<
        Store,
        PublicSettingsDTO
    >(store => store.publicSettings);
    const inputId = useRef(uuid());
    const dispatch = useDispatch();

    const handleFileUpload = onFileUpload
        ? onFileUpload
        : (files: File[]) => {
              dispatch(
                  uploadFiles({ files: files, encrypted: encryptionState }),
              );
          };

    const onClick = () => {
        labelRef.current?.click();
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        unsetDragOver();
    };

    const unsetDragOver = () => {
        if (isDragOver) {
            setIsDragOver(false);
        }
    };

    const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        unsetDragOver();
        const files = await getFilesAsync(e.dataTransfer);
        handleFileUpload?.(files);
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!isDragOver && e.dataTransfer?.types?.includes?.("Files")) {
            setIsDragOver(true);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files != null) {
            handleFileUpload(Array.from(files));
        }
        e.target.value = "";
    };

    const sizeStyle: SizeStyle =
        emptyFileInputSize === EmptyFileInputSize.MEDIUM
            ? {
                  button: styles.middleSizeButton,
                  image: styles.middleSizeImage,
              }
            : {
                  container: styles.smallSizeContainer,
                  button: styles.smallSizeButton,
                  image: styles.smallSizeImage,
              };

    return (
        <>
            <div
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDragLeave={onDragLeave}
                className={classNames(styles.container, sizeStyle.container, {
                    [styles.dragOver]: isDragOver,
                })}
            >
                <div>
                    <img
                        src="/src/assets/images/Cloud.svg"
                        alt=""
                        className={sizeStyle.image}
                    />
                </div>
                <Button
                    className={sizeStyle.button}
                    onClick={onClick}
                    color="primary"
                >
                    {t("send-file:choseFiles")}
                </Button>
                <div className={styles.andFoldersLine}>
                    {t("send-file:emptyInput.orAddFolder")}
                    <label className={styles.andFolderLabler}>
                        {t("send-file:emptyInput.folder")}
                        <Input
                            type="file"
                            className={styles.input}
                            inputProps={{ multiple: true, webkitdirectory: "" }}
                            onChange={onChange}
                        />
                    </label>
                </div>
                <div className={styles.text}>{t("send-file:orDrag")}</div>
                <label
                    className={styles.label}
                    ref={labelRef}
                    htmlFor={inputId.current}
                />
                <Input
                    className={styles.input}
                    type="file"
                    id={inputId.current}
                    onChange={onChange}
                    inputProps={{ multiple: true }}
                />
            </div>
            {fileSizeUploadLimit != null && (
                <div className={styles.limit}>
                    {t("send-file:maxFileSize", {
                        maxSize: prettyBytes(fileSizeUploadLimit, {
                            sizeToRound: "MB",
                        }),
                    })}
                </div>
            )}
        </>
    );
};
