import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    changeTechSupportPageState,
    sendMessageForTechSupport,
} from "src/redux/pages/techSupport/action";
import {
    TechSupportPage,
    TechSupportPageStates,
} from "src/types/redux/store/pages/TechSupportPage";

export const techSupportInitialState: TechSupportPage = {
    currentPageState: TechSupportPageStates.MESSAGE_CREATING,
    inProgress: false,
};

export const techSupportPageReducer = createReducer<TechSupportPage>(
    techSupportInitialState,
    {
        [changeTechSupportPageState.type](
            state,
            action: PayloadAction<TechSupportPageStates>,
        ) {
            state.currentPageState = action.payload;
        },
        [sendMessageForTechSupport.pending.type](state) {
            state.inProgress = true;
        },
        [sendMessageForTechSupport.fulfilled.type](state) {
            state.inProgress = false;
        },
        [sendMessageForTechSupport.rejected.type](state) {
            state.inProgress = false;
        },
    },
);
