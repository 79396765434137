import React, { ReactNode } from "react";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Controller } from "../FieldController";
import { UseControllerProps } from "react-hook-form";
import styled from "@emotion/styled";

type Value = { value: Option["value"]; checked: boolean };
type Option = { label: string; value: string | number };
export type FieldCheckboxProps = {
    name: string;
    label?: ReactNode;
    defaultValue?: Value[];
    controllerProps?: Omit<UseControllerProps, "name">;
    options?: Option[];
};
export const FieldCheckbox = ({
    name,
    controllerProps,
    defaultValue = [],
    options,
    label,
}: FieldCheckboxProps) => {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            {...controllerProps}
        >
            {({ field: { onChange, value } }) => {
                const isChecked = (o: Option) =>
                    value?.find(
                        (existingValue: Value) =>
                            existingValue.value === o.value,
                    )?.checked;
                return (
                    <FormControl>
                        <Label>{label}</Label>
                        {options.map(o => (
                            <FormControlLabel
                                key={o.value}
                                label={o.label}
                                control={
                                    <Checkbox
                                        checked={isChecked(o) || false}
                                        onChange={(_event, checked) => {
                                            const newArr = value?.filter(
                                                (v: Option) =>
                                                    v.value !== o.value,
                                            );
                                            onChange([
                                                ...newArr,
                                                { value: o.value, checked },
                                            ]);
                                        }}
                                    />
                                }
                            />
                        ))}
                    </FormControl>
                );
            }}
        </Controller>
    );
};
const Label = styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
`;
