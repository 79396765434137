import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { getResponseMessageById, resetResponseMessage } from "./actions";
import { ResponseMessageDTO } from "../../../services/generated/api";
import { ResponseMessageDownloadPage } from "../../../types/redux/store/pages/ResponseMessgeDownloadPage";

const initialState: ResponseMessageDownloadPage = {
    responseMessage: {
        messageText: "",
        subject: "",
        uuid: "",
    },
    isProtected: true,
    inProgress: false,
};

export const responseDownloadReducer =
    createReducer<ResponseMessageDownloadPage>(initialState, {
        [getResponseMessageById.pending.type](state) {
            state.inProgress = true;
        },
        [getResponseMessageById.fulfilled.type](
            state,
            action: PayloadAction<ResponseMessageDTO>,
        ) {
            state.responseMessage = action.payload;
            state.isProtected = false;
            state.inProgress = false;
            state.errorCode = undefined;
        },
        [getResponseMessageById.rejected.type](
            state,
            action: PayloadAction<number>,
        ) {
            state.isProtected = false;
            state.inProgress = false;
            state.errorCode = action.payload;
        },
        [resetResponseMessage.type](state) {
            state.responseMessage = initialState.responseMessage;
        },
    });
