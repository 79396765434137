import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import styled from "@emotion/styled";

type SubmitButtonProps = { disabledOnInvalid?: boolean } & ButtonProps;
export const SubmitButton: FC<SubmitButtonProps> = ({
    children,
    disabledOnInvalid = true,
    ...rest
}) => {
    const {
        formState: { isSubmitting, isValid, isDirty },
    } = useFormContext();

    const isValidCountingFormDirtiness = isDirty ? isValid : false;
    const isDisabled = disabledOnInvalid
        ? !isValidCountingFormDirtiness
        : false;
    return (
        <Container>
            <Button disabled={isDisabled} {...rest}>
                {children}
            </Button>
            {isSubmitting && <CircularProgressStyled size={24} />}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
`;

const CircularProgressStyled = styled(CircularProgress)`
    position: absolute;
    top: "50%";
    left: "50%";
    margin-top: -12;
    margin-left: -12;
`;
