import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { MRT_TableInstance } from "material-react-table";
import React, { Fragment } from "react";

export const GroupedBy = <T,>({ table }: { table: MRT_TableInstance<T> }) => {
    const {
        getState,
        options: { localization },
    } = table;
    const { grouping } = getState();

    const groupedAlert =
        grouping.length > 0 ? (
            <span>
                {localization.groupedBy}{" "}
                {grouping.map((columnId, index) => (
                    <Fragment key={`${index}-${columnId}`}>
                        {index > 0 ? localization.thenBy : ""}
                        <Chip
                            label={table.getColumn(columnId).columnDef.header}
                            onDelete={() =>
                                table.getColumn(columnId).toggleGrouping()
                            }
                        />
                    </Fragment>
                ))}
            </span>
        ) : null;
    return <Container>{groupedAlert}</Container>;
};

const Container = styled.div`
    display: flex;
    width: 100%;
`;
