import React from "react";
import { Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
    tabsContainer: {
        fontSize: 14,
    },
    tab: {
        margin: 0,
        borderBottom: "2px solid #EBEBEB",
        "&.Mui-selected": {
            backgroundColor: "#E6EAFB66",
        },
    },
});

export interface TabType {
    value: any;
    label: string;
    disabled?: boolean;
}

interface SwitchersProps {
    tabs: TabType[];
    onChange: (value: any) => void;
    value: any;
    className?: string;
    tabClassName?: string;
}

export const Switchers = (props: SwitchersProps) => {
    const { tabs, onChange, value, className, tabClassName } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        onChange(newValue);
    };

    return (
        <Tabs
            value={value}
            indicatorColor="primary"
            onChange={handleChange}
            className={`${classes.tabsContainer} ${className ? className : ""}`}
            variant="fullWidth"
        >
            {tabs.map(tab => (
                <Tab
                    disabled={tab.disabled}
                    key={tab.value}
                    className={`${classes.tab} ${tabClassName && tabClassName}`}
                    value={tab.value}
                    label={t(tab.label)}
                />
            ))}
        </Tabs>
    );
};
