import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    FormControl,
    Grid,
    Input,
    InputLabel,
    Tooltip,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LicenseExpiredMessageSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { Store } from "src/types/redux/store/index";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "src/components/Form";
import { CommonAdminForm } from "../../../components/Admin/commonAdminForm";
import { Preloader } from "src/components/Preloader";
import { Languages } from "src/types/Languages";
import {
    getLicenseExpiredMessageHeaders,
    updateLicenseExpiredMessageHeaders,
} from "src/redux/pages/administrationSettings/licenseExpiredMessage/actions";

const useStyle = makeStyles({
    container: {
        maxWidth: 800,
    },
    tooltip: {
        maxWidth: 500,
    },
    contentLongRow: {
        "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
});

const fields: { name: string; label: string }[] = [
    {
        name: "title",
        label: "headerLicenseExpired",
    },
    {
        name: "body",
        label: "licenseExpiredSoon",
    },
    {
        name: "expiredBody",
        label: "licenseExpired",
    },
];

export const LicenseExpiredMessage = () => {
    const styles = useStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { form, loading } = useSelector<
        Store,
        LicenseExpiredMessageSettingsPage
    >(
        store =>
            store.pages.administration.administrationSettings
                .licenseExpiredMessageSettingsPage,
    );
    const { register, reset, handleSubmit, watch } = useForm<
        LicenseExpiredMessageSettingsPage["form"]
    >({ defaultValues: form });

    useEffect(() => {
        dispatch(getLicenseExpiredMessageHeaders());
    }, [dispatch]);

    useEffect(() => {
        reset(form);
    }, [form, reset]);

    const onSubmit = (data: LicenseExpiredMessageSettingsPage["form"]) => {
        dispatch(updateLicenseExpiredMessageHeaders(data));
    };
    const values = watch();
    let isValidForm = 0;
    Object.values(values).forEach(value => {
        value.length > 0 && isValidForm++;
    });
    return (
        <CommonAdminForm
            onSubmit={handleSubmit(onSubmit)}
            className={styles.container}
            disableSubmit={!(isValidForm === 6)}
            disableButtonTooltipText={t(`admin-settings:fillRequiredFields`)}
        >
            {loading && <Preloader over />}
            <Grid container spacing={10}>
                {Object.values(Languages).map(language => (
                    <Grid item xs={12} sm={6} key={language}>
                        <Typography variant="h6">
                            {t(
                                `admin-settings:licenseExpiredMessageSettings.header${language}`,
                            )}
                        </Typography>
                        {fields.map(field => {
                            const value =
                                (values as any)[field.name + language] || "";
                            return (
                                <FormControl key={field.name + language}>
                                    <InputLabel required>
                                        {t(
                                            `admin-settings:licenseExpiredMessageSettings.${
                                                field.label + language
                                            }`,
                                        )}
                                    </InputLabel>
                                    <Tooltip
                                        title={value}
                                        className={styles.tooltip}
                                        disableInteractive
                                    >
                                        <Input
                                            required
                                            value={value}
                                            name={`${field.name + language}`}
                                            className={styles.contentLongRow}
                                            {...register(
                                                // @ts-ignore
                                                field.name + language,
                                                { required: true },
                                            )}
                                            autoComplete="off"
                                        />
                                    </Tooltip>
                                </FormControl>
                            );
                        })}
                    </Grid>
                ))}
            </Grid>
        </CommonAdminForm>
    );
};
