import { useEffect } from "react";

export function useAutoUpdate(fn: () => void, ms: number) {
    useEffect(() => {
        let intervalId: number;
        intervalId = window.setInterval(fn, ms);
        return () => {
            window.clearInterval(intervalId);
        };
    }, [fn, ms]);
}
