import { useState, useEffect } from "react";
import {
    SCREEN_XL,
    SCREEN_LG,
    SCREEN_MD,
    SCREEN_SM,
    SCREEN_XS,
} from "src/constants/breakpoints";

export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return {
        width,
        isScreenXs: width >= SCREEN_XS,
        isScreenSm: width >= SCREEN_SM,
        isScreenMd: width >= SCREEN_MD,
        isScreenLg: width >= SCREEN_LG,
        isScreenXl: width >= SCREEN_XL,
    };
};
