import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { settingsApiService } from "src/services/clientApi";
import { GeneralSettingsDTO } from "src/services/generated";
import {
    getPrivateSettings,
    getPublicSettings,
} from "../../applicationSettings/actions";

export const getSettingsGeneral = createAsyncThunk(
    "getSettingsGeneral",
    async (args, thankAPI) => {
        try {
            const response = await settingsApiService.getGeneralSettings();
            return response.data;
        } catch (error) {
            enqueueError(i18n.t("admin-settings:errors.get"));
            thankAPI.rejectWithValue(error);
        }
    },
);

export const updateSettingsGeneral = createAsyncThunk(
    "updateSettingsGeneral",
    async (data: GeneralSettingsDTO, thunkAPI) => {
        try {
            const response =
                await settingsApiService.updateGeneralSettings(data);
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
            thunkAPI.dispatch(getPublicSettings());
            thunkAPI.dispatch(getPrivateSettings());
            return response.data;
        } catch (error) {
            enqueueError(i18n.t("admin-settings:settingsEMAIL.errors.update"));
            thunkAPI.rejectWithValue(error);
        }
    },
);
