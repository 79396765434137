import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import moment from "moment";
import "moment/locale/ru";
import i18n from "src/i18n";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { getVersionInfo } from "../redux/version/actions";
import { color } from "../theme/variables/color";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingTop: "80px",
            width: "100%",
            maxWidth: "672px",
            margin: "auto",
            [theme.breakpoints.down("sm")]: {
                paddingTop: "60px",
            },
            [theme.breakpoints.down("xs")]: {
                paddingTop: 0,
            },
        },
        paper: {
            padding: "24px 36px 40px 36px",
            fontSize: "12px",
            lineHeight: "16px",
            fontFamily: "Roboto",
            textAlign: "center",
            background: color.sendFileColor.background.transparentWhite,
            "& ul": {
                padding: 0,
            },
            "& h2": {
                fontSize: "12px",
                margin: 0,
            },
            "& li": {
                listStyleType: "none",
            },
            [theme.breakpoints.down("xs")]: {
                textAlign: "left",
            },
        },
        textLeft: {
            textAlign: "left",
        },
        paddingBottom: {
            paddingBottom: 10,
        },
        textBlock: {
            marginTop: "40px",
        },
    }),
);

export const AboutPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getVersionInfo());
    }, [dispatch]);

    const { appVersionInfo } = useSelector(
        (store: Store) => store.appVersionInfo,
    );
    const fullVersion = `${appVersionInfo?.branch}-${appVersionInfo?.commitHashShort}`;
    const classes = useStyles();
    let advantages = t("about:advantages.list", { returnObjects: true });
    let terms = t("about:terms.list", { returnObjects: true });
    let responsibilities = t("about:terms.responsibilities", {
        returnObjects: true,
    });
    const localeDate = useMemo(
        () =>
            moment(appVersionInfo?.buildDateTime).locale(
                i18n.language.toLowerCase(),
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [i18n.language, appVersionInfo],
    );
    return (
        <div className={classes.container}>
            <div className={classes.paper}>
                <h2>{t("about:sendfile").toUpperCase()}</h2>
                <p>
                    {t("about:version")}
                    {fullVersion}
                    {t("about:from")} {localeDate.format("LL")}
                </p>
                <span className={classes.textLeft}>
                    <p>{t("about:title")}</p>
                    <ul>
                        {t("about:advantages.title")}
                        {Object.keys(advantages).map((value, index) => {
                            return (
                                <li key={index}>
                                    {"-"} {advantages[index]}
                                </li>
                            );
                        })}
                    </ul>
                </span>
                <div className={classes.textBlock}>
                    <h2>{t("about:termsOfUse").toUpperCase()}</h2>
                    <p>
                        {t("about:version")}
                        {fullVersion}
                        {t("about:from")} {localeDate.format("LL")}
                    </p>
                    <span className={classes.textLeft}>
                        <p>{t("about:terms.title")}</p>
                        <ul>
                            {Object.keys(terms).map((value, index) => {
                                return (
                                    <li key={index}>
                                        {index + 1}. {terms[index]}
                                    </li>
                                );
                            })}
                        </ul>
                        <ul>
                            {Object.keys(responsibilities).map(
                                (value, index) => {
                                    return (
                                        <li key={index}>
                                            {responsibilities[index]}
                                        </li>
                                    );
                                },
                            )}
                        </ul>
                    </span>
                </div>
            </div>
        </div>
    );
};
