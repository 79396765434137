import { ComponentsOverrides } from "@mui/material/styles/overrides";
import styleVariables from "../variables";

export const MuiSelect: ComponentsOverrides["MuiSelect"] = {
    select: {
        "&": {
            padding: "2px 8px 4px",
            lineHeight: "16px",
            fontSize: 14,
            minHeight: "unset",
        },
        "&:focus": {
            backgroundColor: "inherit",
        },
    },
    icon: {
        color: styleVariables.color.sendFileColor.onSecondary.text,
        right: styleVariables.spacing * 2,
    },
};

export default MuiSelect;
