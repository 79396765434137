import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Form,
    FieldSelect,
    FieldInput,
    FieldBooleanCheckbox,
} from "src/components/Form";
import { Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { ReactComponent as Copy } from "../icons/copy.svg";
import { copyElementContentById } from "../helpers/clipboard";
import { useTranslation } from "react-i18next";
import {
    changeDirectLinksEnabled,
    changePassword,
    changePasswordSwitchState,
    updateMessageModel,
} from "src/redux/pages/sendFile/actions";
import { Store } from "src/types/redux/store/index";
import { pluralize, WordTranslationKey } from "../helpers/wordCasesUtil";
import moment from "moment";
import _ from "lodash";
import { DAYS_TO_EXPIRE, passwordRegExp } from "src/constants/sendFiles";
import { sha256 } from "js-sha256";
import { FileSettingsForm as FileSettingsFormType } from "src/types/redux/store/pages/SendFilePage";
import { MessageModelSendingTypeEnum } from "src/services/generated";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            textAlign: "left",
            [theme.breakpoints.down("sm")]: {
                padding: "0 24px",
            },
        },
    }),
);

const downloadCounts = [1, 3, 5, 25, 100, 1000];

const defaultValues = {
    storageTermExpiration: 5,
    daysToExpired: 3,
    password: "",
    passwordEnabled: false,
    withDirectLinks: false,
};

export const FileSettingsForm = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [isPasswordError, setIsPasswordError] = useState(false);
    const locale = i18n.languages && i18n.languages[0];

    const { message, isPasswordEnabled, directLinksEnabled, sendType } =
        useSelector((store: Store) => store.pages.sendFilePage);
    const canDirectLinksExist = useSelector(
        (store: Store) => store.publicSettings.directLinksEnabled,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateMessage = useCallback(
        _.debounce((values: FileSettingsFormType) => {
            dispatch(
                updateMessageModel({
                    messageId: message?.id,
                    messageModel: {
                        withDirectLinks: values.withDirectLinks,
                        expireTime: moment()
                            .add(values.daysToExpired, "days")
                            .toISOString(),
                        downloadLimit: values.storageTermExpiration,
                        password:
                            values.passwordEnabled && values.password
                                ? sha256(values.password)
                                : null,
                    },
                }),
            );
        }, 1000),
        [],
    );

    const onChange = (values: any) => {
        const password = values.password;
        dispatch(changePasswordSwitchState(values.passwordEnabled));
        dispatch(changeDirectLinksEnabled(values.withDirectLinks));
        dispatch(changePassword(password));
        setIsPasswordError(
            (password?.match(passwordRegExp)?.[0] || "") !== password,
        );
        updateMessage(values);
    };

    const daysToExpiredOpts = DAYS_TO_EXPIRE.map(item => ({
        value: item,
        label: `${item} ${t(pluralize(item, WordTranslationKey.DAY, locale))}`,
    }));
    const storageTermExpirationOpts = downloadCounts.map(item => ({
        value: item,
        label: `${item} ${
            item > 1
                ? t("send-file:fileSettingsForm.downloads")
                : t("send-file:fileSettingsForm.download")
        }`,
    }));

    return (
        <Form
            formConfig={{
                defaultValues,
            }}
            onChange={onChange}
            className={classes.form}
        >
            <Grid container columnSpacing={4}>
                <Grid item sm={7} xs={12}>
                    <FieldSelect
                        name="daysToExpired"
                        label={t("send-file:fileSettingsForm.storageTerm")}
                        options={daysToExpiredOpts}
                        clearHidden
                    />
                </Grid>
                <Grid item sm={5} xs={12}>
                    <FieldSelect
                        name="storageTermExpiration"
                        label={t("send-file:fileSettingsForm.orAfter")}
                        options={storageTermExpirationOpts}
                        clearHidden
                    />
                </Grid>
                <Grid item sm={7} xs={12}>
                    <FieldBooleanCheckbox
                        disabled={directLinksEnabled}
                        name="passwordEnabled"
                        label={t("send-file:fileSettingsForm.passwordProtect")}
                    />
                </Grid>
                {isPasswordEnabled && (
                    <Grid item sm={5} xs={12}>
                        <Tooltip
                            title={
                                isPasswordError &&
                                t("send-file:tooltipText.passwordIsIncorrect")
                            }
                        >
                            <FieldInput
                                name="password"
                                label={t("send-file:fileSettingsForm.password")}
                                id="FileSettingsFoem:password"
                                error={isPasswordError}
                                endAdornment={
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={() =>
                                                copyElementContentById(
                                                    "FileSettingsFoem:password",
                                                )
                                            }
                                        >
                                            <Copy />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </Tooltip>
                    </Grid>
                )}
                {canDirectLinksExist &&
                    sendType === MessageModelSendingTypeEnum.LINK && (
                        <Grid item sm={7} xs={12}>
                            <FieldBooleanCheckbox
                                disabled={isPasswordEnabled}
                                name="withDirectLinks"
                                label={t(
                                    "send-file:fileSettingsForm.directLinksEnabled",
                                )}
                            />
                        </Grid>
                    )}
            </Grid>
        </Form>
    );
};
