import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { changeLogo, changeMobileLogo, getLogoUrl } from "./actions";
import { AppLogoInfo } from "../../types/redux/store/AppLogoInfo";
import { AppLogo, LogoUploadResponse } from "src/services/generated";

const appUrlPathInitialState: AppLogoInfo = {
    loading: false,
    uploadingLogo: false,
    uploadingMobileLogo: false,
};

export const appLogoReducer = createReducer<AppLogoInfo>(
    appUrlPathInitialState,
    {
        [getLogoUrl.pending.type](state) {
            state.loading = true;
        },
        [getLogoUrl.fulfilled.type](state, action?: PayloadAction<AppLogo>) {
            state.loading = false;
            state.appLogoInfo = action.payload;
        },
        [getLogoUrl.rejected.type](state) {
            state.loading = false;
        },

        [changeLogo.pending.type](state) {
            state.uploadingLogo = true;
        },
        [changeLogo.fulfilled.type](
            state,
            action: PayloadAction<LogoUploadResponse>,
        ) {
            state.uploadingLogo = false;
            state.appLogoInfo.logoPath = action.payload.logoPath;
            state.appLogoInfo.logoName = action.payload.logoName;
        },
        [changeLogo.rejected.type](state) {
            state.uploadingLogo = false;
        },

        [changeMobileLogo.pending.type](state) {
            state.uploadingMobileLogo = true;
        },
        [changeMobileLogo.fulfilled.type](
            state,
            action: PayloadAction<LogoUploadResponse>,
        ) {
            state.uploadingMobileLogo = false;
            state.appLogoInfo.logoMobilePath = action.payload.logoPath;
            state.appLogoInfo.logoMobileName = action.payload.logoName;
        },
        [changeMobileLogo.rejected.type](state) {
            state.uploadingMobileLogo = false;
        },
    },
);
