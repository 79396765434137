import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

type TextFieldShrinkWrapperProps = {
    name: string;
    label: string;
    value: string;
    multiline?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    required?: boolean;
    maxRows?: number;
    onChange?: any;
    maxLength?: number;
};

export const TextFieldShrinkWrapper = React.forwardRef(
    (props: TextFieldShrinkWrapperProps, ref: any) => {
        const {
            name,
            label,
            value,
            multiline = false,
            fullWidth = false,
            disabled = false,
            required,
            maxRows: rowsMax,
            onChange,
            maxLength,
        } = props;
        const [shrink, setShrink] = useState(false);

        useEffect(() => {
            if (!shrink) {
                setShrink(value !== "");
            }
        }, [value, shrink]);

        const handleFocus = () => {
            setShrink(true);
        };

        const handleBlur = () => {
            setShrink(value !== "");
        };

        return (
            <TextField
                name={name}
                label={label}
                inputRef={ref}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={onChange}
                disabled={disabled}
                multiline={multiline}
                fullWidth={fullWidth}
                required={required}
                maxRows={rowsMax}
                inputProps={{ maxLength }}
                InputLabelProps={{
                    shrink: shrink,
                }}
            />
        );
    },
);
