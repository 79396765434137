import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { MailHeadersDTO } from "src/services/generated";
import { MailHeadersSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getMailHeaders, updateMailHeaders } from "./actions";

const mailHeadersSettingsInitialState: MailHeadersSettingsPage = {
    loading: false,
    form: {
        requestMessageRU: "",
        responseMessageRU: "",
        sentMessageRU: "",
        requestMessageEN: "",
        responseMessageEN: "",
        sentMessageEN: "",
    },
};

export const mailHeadersSettingsReducer =
    createReducer<MailHeadersSettingsPage>(mailHeadersSettingsInitialState, {
        [getMailHeaders.pending.type](state) {
            state.loading = true;
        },
        [getMailHeaders.fulfilled.type](
            state,
            action: PayloadAction<MailHeadersDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [getMailHeaders.rejected.type](state) {
            state.loading = false;
        },
        [updateMailHeaders.pending.type](state) {
            state.loading = true;
        },
        [updateMailHeaders.fulfilled.type](state) {
            state.loading = false;
        },
        [updateMailHeaders.rejected.type](state) {
            state.loading = false;
        },
    });
