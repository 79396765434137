import React from "react";
import {
    Autocomplete,
    AutocompleteProps,
    TextField,
    TextFieldProps,
    InputProps,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Controller } from "../FieldController";
import { UseControllerProps } from "react-hook-form";
import { isEqual, isUndefined } from "lodash";

const useStyles = makeStyles(() =>
    createStyles({
        autocompleteOptionText: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }),
);

export type Option<T = string | number | undefined> = {
    label: string;
    value: T;
};

type BooleanOrUndefined = boolean | undefined;
type FieldAutoCompleteProps<
    T,
    Multiple extends BooleanOrUndefined,
    Clearable extends BooleanOrUndefined,
    FreeSolo extends BooleanOrUndefined,
> = {
    name: string;
    options: Option[];
    label: TextFieldProps["label"];
    placeholder?: TextFieldProps["placeholder"];
    controllerProps?: Omit<UseControllerProps, "name">;
    InputProps?: InputProps;
} & Omit<AutocompleteProps<T, Multiple, Clearable, FreeSolo>, "renderInput">;

export const FieldAutoComplete = <
    T extends Option,
    Multiple extends BooleanOrUndefined,
    Clearable extends BooleanOrUndefined,
    FreeSolo extends BooleanOrUndefined,
>({
    name,
    options = [],
    label,
    placeholder,
    defaultValue,
    controllerProps,
    InputProps,
    ...autoCompleteProps
}: FieldAutoCompleteProps<T, Multiple, Clearable, FreeSolo>) => {
    const styles = useStyles();
    return (
        <Controller name={name} {...controllerProps}>
            {({
                field: { onBlur, onChange, value },
                fieldState: { invalid },
            }) => {
                return (
                    <Autocomplete
                        options={options}
                        isOptionEqualToValue={isEqual}
                        {...autoCompleteProps}
                        // @ts-ignore
                        value={isUndefined(value) ? [] : value}
                        // @ts-ignore
                        onChange={(e, option, reason, details) => {
                            // @ts-ignore
                            const value = option?.value || option;
                            onChange(value);
                            autoCompleteProps?.onChange?.(
                                e,
                                value,
                                reason,
                                details,
                            );
                        }}
                        onBlur={e => {
                            autoCompleteProps?.onBlur?.(e);
                            onBlur();
                        }}
                        renderInput={params => (
                            <TextField
                                {...params}
                                error={invalid}
                                variant="standard"
                                label={label}
                                placeholder={placeholder}
                                InputProps={{
                                    ...params.InputProps,
                                    ...InputProps,
                                }}
                            />
                        )}
                        renderOption={(props, option) => {
                            return (
                                <li {...props}>
                                    <span
                                        className={
                                            styles.autocompleteOptionText
                                        }
                                    >
                                        {option.label}
                                    </span>
                                </li>
                            );
                        }}
                    />
                );
            }}
        </Controller>
    );
};
