import { createAction } from "@reduxjs/toolkit";
import { FileViewModel } from "src/types/FileDisplay";

export const addFile = createAction<FileViewModel>("ADD_FILE");

export const updateDownloadProgress = createAction<FileViewModel>(
    "UPDATE_DOWNLOAD_PROGRESS",
);

export const removeFile = createAction<string>("REMOVE_FILE");

export const abort = createAction<string>("ABORT");
