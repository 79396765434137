import { styleVariables } from "../variables";

export const MuiCheckbox = {
    root: {
        padding: `${styleVariables.spacing}px ${styleVariables.spacing * 2}px ${
            styleVariables.spacing
        }px ${styleVariables.spacing * 2}px`,
    },
    colorSecondary: {
        "&.Mui-checked": {
            color: styleVariables.color.primary.light,
        },
    },
};

export default MuiCheckbox;
