import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { UsersReportsFilter } from "./UsersReportsFilter";
import { AdminUsersReportsTable } from "./UsersReportsTable";
import { useUsersReports, useUsersReportsParams } from "./apiMethods";
import { DataGrid } from "src/components/DataGrid";
import { isEqual } from "lodash";
import { usePageStyles } from "./utils";

const defaultFilters: useUsersReportsParams["filters"] = {
    date: {
        start: undefined,
        end: undefined,
    },
    users: undefined,
};
export const AdministrationUsersReportsPage = () => {
    const styles = usePageStyles();
    const [filters, setFilters] = useState(defaultFilters);
    const {
        pagination,
        sorting,
        setPagination,
        setSorting,
        selected,
        setSelected,
    } = DataGrid.useTableState();
    const { data, isLoading, allData } = useUsersReports({
        filters,
        pagination,
        sorting,
    });

    return (
        <Grid minWidth={0} container wrap="nowrap" className={styles.height100}>
            <Box className={styles.tableWrapper}>
                <AdminUsersReportsTable
                    isFiltersDefault={isEqual(defaultFilters, filters)}
                    table={{
                        state: {
                            pagination,
                            sorting,
                            isLoading,
                            rowSelection: selected,
                        },
                        data: data?.content || [],
                        rowCount: data?.totalElements,
                    }}
                    allData={allData}
                    onRowSelectionChange={setSelected}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                />
            </Box>
            <Box className={styles.filterWrapper}>
                <UsersReportsFilter
                    onChange={d => {
                        setFilters({
                            date: {
                                start: d?.date?.start.toISOString(),
                                end: d?.date?.end.toISOString(),
                            },
                            users: d.users?.map(u => u.value),
                        });
                    }}
                />
            </Box>
        </Grid>
    );
};
