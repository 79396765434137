export const MuiPagination = {
    root: {
        height: 72,
        display: "flex",
        justifyContent: "flex-end",
    },
    ul: {
        height: "inherit",
        "&.Mui-selected": {
            color: "white",
        },
    },
};
