import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { MuiButton } from "./muiButton";
import { MuiTextField } from "./muiTextField";
import { MuiInputLabel } from "./muiInputLabel";
import { MuiInputBase } from "./muiInputBase";
import { MuiTab } from "./muiTab";
import { MuiAppBar } from "./muiAppBar";
import { MuiPagination } from "./muiPagination";
import { MuiTableCell } from "./muiTableCell";
import MuiCheckbox from "./muiCheckbox";
import MuiFormControl from "./muiFormControl";
import MuiFormControlLabel from "./muiFormControlLabel";
import MuiSelect from "./muiSelect";
import MuiIconButton from "./muiIconButton";
import { MuiCircularProgress } from "./muiCircularProgress";
import { MuiTooltip } from "./muiTooltip";
import { MuiDialog } from "./muiDialog";
import { MuiPaper } from "./muiPaper";
import { MuiAutocomplete } from "./muiAutocomplete";
import MuiChip from "./muiChip";
import MuiListItem from "./muiListItem";
import MuiMenuItem from "./muiMenuItem";
import { MuiFormHelperText } from "./muiFormHelperText";
import MuiTabs from "./muiTabs";
import { MuiInputAdornment } from "./muiInputAdornment";
import { MuiMenu } from "./muiMenu";
import MuiSvgIcon from "./muiSvgIcon";
import { MuiDialogTitle } from "./muiDialogTitle";
import { MuiDialogContent } from "./muiDialogContent";
import { MuiDialogActions } from "./muiDialogActions";
import MuiListItemText from "./muiListItemText";
import { MuiListItemSecondaryAction } from "./muiListItemSecondaryAction";
import { MuiTableRow } from "./muiTableRow";
import { MuiFormLabel } from "./muiFormLabel";
import { MuiInput } from "./muiInput";
import { MuiCssBaseline } from "./MuiCssBaseline";

interface LabOverrides {
    MuiPagination: any;
    MuiAutocomplete: any;
}

export const overrides: ComponentsOverrides & LabOverrides = {
    MuiButton,
    MuiTextField,
    MuiInputLabel,
    MuiInputBase,
    MuiAppBar,
    MuiTab,
    MuiPagination,
    MuiTableCell,
    MuiCheckbox,
    MuiFormControl,
    MuiFormControlLabel,
    MuiSelect,
    MuiIconButton,
    MuiCircularProgress,
    MuiTooltip,
    MuiDialog,
    MuiPaper,
    MuiAutocomplete,
    MuiChip,
    MuiListItem,
    MuiMenuItem,
    MuiFormHelperText,
    MuiTabs,
    MuiInputAdornment,
    MuiSvgIcon,
    MuiDialogTitle,
    MuiDialogContent,
    MuiDialogActions,
    MuiMenu,
    MuiListItemText,
    MuiListItemSecondaryAction,
    MuiTableRow,
    MuiFormLabel,
    MuiInput,
    MuiCssBaseline,
};
