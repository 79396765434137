import React from "react";
import { CircularProgress, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import classNames from "classnames";

interface StylesProps {
    over?: boolean;
    overDocument?: boolean;
}

interface PreloaderProps extends StylesProps {
    size?: number;
    color?: "inherit" | "primary" | "secondary";
    className?: string;
}

const useStyle = makeStyles<Theme, StylesProps>((theme: Theme) =>
    createStyles({
        wrapper: ({ over, overDocument }) => {
            let position = "unset";
            let width = "auto";
            let height = "auto";
            let backgroundColor = "unset";

            if (over) {
                position = "absolute";
                width = "100%";
                height = "100%";
                backgroundColor = "rgba(26, 31, 41, 0.13)";
            }

            if (overDocument) {
                position = "fixed";
                width = "100vw";
                height = "100vh";
                backgroundColor = "rgba(26, 31, 41, 0.38)";
            }

            return {
                backgroundColor,
                top: 0,
                left: 0,
                display: "flex",
                zIndex: 1400,
                position: position as any,
                alignItems: "center",
                justifyContent: "center",
                width,
                height,
            };
        },
    }),
);

export const Preloader: React.FC<PreloaderProps> = ({
    size = 24,
    color = "primary",
    over,
    overDocument,
    className,
}) => {
    const styles = useStyle({ over, overDocument });

    return (
        <div className={classNames(styles.wrapper, className)}>
            <CircularProgress color={color} size={size} />
        </div>
    );
};
