import React from "react";
import { Grid, Box, Theme } from "@mui/material";
import UsersFilter from "../../components/filters/UsersFilter";
import UsersTable from "../../components/CommonTable/TableRealisation/UsersTable";
import { DialogNames } from "../../types/redux/store/dialogs/commonDialog";
import { UserFilesReportDialog } from "../../components/UserFilesReportDialog";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        height100: {
            height: "100%",
        },
        filterWrapper: {
            height: "100%",
            width: "240px",
            flexShrink: 0,
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "block",
            },
        },
        tableWrapper: {
            width: "100%",
            height: "100%",
            marginRight: 0,
            [theme.breakpoints.up("md")]: {
                marginRight: "16px",
            },
        },
    }),
);

export const AdministrationUsersPage = () => {
    const styles = useStyles();

    return (
        <Grid container wrap="nowrap" className={styles.height100}>
            <Box className={styles.tableWrapper}>
                <UsersTable />
            </Box>
            <Box className={styles.filterWrapper}>
                <UsersFilter />
            </Box>
            <UserFilesReportDialog name={DialogNames.USERFILESREPORTDIALOG} />
        </Grid>
    );
};

export default AdministrationUsersPage;
