import React from "react";
import { Button, ButtonProps } from "@mui/material";
import { useFormContext } from "react-hook-form";

export const ResetButton = ({ children, onClick, ...rest }: ButtonProps) => {
    const { reset, formState } = useFormContext();
    const isDisabled = Object.keys(formState.dirtyFields).length === 0;

    return (
        <Button
            disabled={isDisabled}
            onClick={e => {
                reset();
                onClick?.(e);
            }}
            {...rest}
        >
            {children}
        </Button>
    );
};
