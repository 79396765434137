export const MuiInputLabel = {
    root: {
        fontSize: 14,
        lineHeight: "16px",
        paddingRight: 40,
        paddingLeft: 8,
        opacity: 0.6,
        "&.Mui-focused": {
            opacity: 1,
        },
    },
    formControl: {
        transform: "translate(0, 13px) scale(1)",
    },
    shrink: {
        height: 14,
        fontSize: 12,
        lineHeight: "14px",
        transform: "translate(0, 4px) scale(1)",
    },
};
