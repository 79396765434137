import React from "react";
import { DialogTitle, Grid, IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { color } from "../../theme/variables/color";

const StyledIconButton = withStyles({
    root: {
        width: "24px",
        height: "24px",
        alignSelf: "start",
        color: color.sendFileColor.onSecondary.text,
    },
})(IconButton);

type Props = {
    id?: string;
    className?: string;
    children?: React.ReactNode;
    onClose: () => void;
};

export const ClosingDialogTitle = ({ children, onClose, ...other }: Props) => {
    return (
        <DialogTitle {...other}>
            <Grid
                wrap="nowrap"
                container
                alignItems="center"
                justifyContent="space-between"
            >
                {children}
                <StyledIconButton disableRipple onClick={onClose}>
                    <Close fontSize="inherit" />
                </StyledIconButton>
            </Grid>
        </DialogTitle>
    );
};
