import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UploadForAdmin } from "src/types/Upload";
import { AdminFilesFilter } from "../../../types/redux/store/pages/administration/AdministrationFilesPage";
import {
    adminFilesApi,
    adminFilesEmailsApi,
} from "../../../services/clientApi";
import {
    ReceivedMessageResponseDataMessageTypeEnum,
    UserMessagesRequestParams,
} from "../../../services/generated";

export const setAdminFilesSearchFilter = createAction<AdminFilesFilter>(
    "ADMIN_FILES_SET_SEARCH_FILTER",
);

export const loadUploadedMessagesInfo = createAsyncThunk(
    "getMessages",
    async (params: UserMessagesRequestParams, thunkAPI) => {
        const result = await adminFilesApi.getMessages(params);
        return result.data;
    },
);

export const loadEmails = createAsyncThunk("getEmails", async () => {
    return (await adminFilesEmailsApi.getEmails()).data;
});

export const toggleUploadExpand = createAction<UploadForAdmin>("toggleExpand");

export type DeleteMessagePayload = {
    messageId: string;
    messageType: ReceivedMessageResponseDataMessageTypeEnum;
    params: UserMessagesRequestParams;
};
export const deleteMessageOnServer = createAsyncThunk<
    void,
    DeleteMessagePayload
>(
    "deleteMessageOnServer",
    async ({ messageId, messageType, params }, thunkApi) => {
        try {
            await adminFilesApi.removeMessages(messageId, messageType);
        } catch (e) {
            return thunkApi.rejectWithValue(e);
        }
        await thunkApi.dispatch(loadUploadedMessagesInfo(params));
    },
);
