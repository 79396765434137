import React, { useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, Box, Button, IconButton } from "@mui/material";
import classNames from "classnames";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import { CalendarProps } from "./Calendar";
import color from "src/theme/variables/color";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: "#fff",
            width: "300px",
            minWidth: 0,
            padding: "0 24px 12px",
            [theme.breakpoints.down("sm")]: {
                padding: 0,
            },
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "48px",
            padding: "4px 0",
        },
        content: {
            display: "flex",
            flexWrap: "wrap",
        },
        calendarItem: {
            flex: "1 0 25%",
            borderRadius: "30px",
            padding: 0,
            minWidth: 0,
            fontWeight: 400,
            color: color.sendFileColor.onSecondary.text,
        },
        active: {
            backgroundColor: color.primary.main,
            color: "#fff",
            "&:hover": {
                backgroundColor: color.primary.main,
            },
        },
    }),
);

const amountViewYers = 16;
const minYear = 1800;

export const CalendarYear = ({ onChange, date }: CalendarProps) => {
    const styles = useStyles();
    const [startYear, setStartYear] = useState(
        date.year() - (date.year() % amountViewYers),
    );
    const endYear = startYear + amountViewYers - 1;

    const onIncYear = () => {
        setStartYear(startYear + amountViewYers);
    };

    const onDecYear = () => {
        setStartYear(startYear - amountViewYers);
    };

    const createContent = () => {
        const yers = [];

        for (let year = startYear; year <= endYear; year++) {
            yers.push(
                <Button
                    variant="text"
                    key={year}
                    className={classNames(styles.calendarItem, {
                        [styles.active]: year === date.year(),
                    })}
                    onClick={() => onChange(date.year(year).startOf("year"))}
                >
                    {year}
                </Button>,
            );
        }

        return yers;
    };

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <IconButton disabled={startYear < minYear} onClick={onDecYear}>
                    <ArrowBackIos />
                </IconButton>
                <Box>{`${startYear} - ${endYear}`}</Box>
                <IconButton onClick={onIncYear}>
                    <ArrowForwardIos />
                </IconButton>
            </Box>
            <Box className={styles.content}>{createContent()}</Box>
        </Box>
    );
};
