import React, { useState } from "react";
import {
    Grid,
    IconButton,
    Input,
    InputLabel,
    List,
    Typography,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { getFilesAsync } from "src/helpers/file";
import classNames from "classnames";
import { ReactComponent as AddCircleIcon } from "src/icons/addCircle.svg";

const useStyles = makeStyles({
    input: {
        position: "absolute",
        left: 0,
        top: 0,
        height: "0",
        width: "0",
        opacity: 0,
    },
    container: {
        border: "1px dashed rgba(26, 31, 41, 0.3)",
        borderRadius: "4px",
        background: "rgba(229, 229, 230, 0.3)",
        boxSizing: "border-box",
        position: "relative",
    },
    hasErrors: {
        borderColor: "#FF5722",
    },
    workPanel: {
        height: "55px",
        padding: "0 24px",
        fontSize: "12px",
        fontWeight: "normal",
    },
    fileContainer: {
        marginTop: "24px",
    },
    fileList: {
        padding: "0 24px",
        width: "100%",
        overflowY: "auto",
        overflow: "hidden",
    },
    opacity: {
        opacity: "0.6",
    },
    downloaded: {
        color: "rgba(255,87,34, 0.8)",
        margin: "4px 0 0 8px",
        fontSize: "12px",
        fontWeight: "normal",
        textAlign: "left",
    },
    errors: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "14px",
        textAlign: "center",
        color: "#FF5722",
    },
    andFolderLabel: {
        cursor: "pointer",
        fontWeight: "500",
        marginLeft: "4px",
        marginRight: "4px",
        color: "#003FBD",
        "&:hover": {
            color: "#6577CB",
            textDecoration: "underline",
        },
    },
    dragOver: {
        backgroundColor: "#EDF0FC99",
    },
});

const StyledIconButton = withStyles({
    root: {
        height: "24px",
        width: "24px",
        padding: "0",
        marginRight: "8px",
    },
})(IconButton);

const StyledInputLabel = withStyles({
    root: {
        height: "24px",
        width: "24px",
        padding: "0",
        cursor: "pointer",
        opacity: 1,
        "&:hover": {
            background: "#EDF0FC",
        },
    },
})(InputLabel);

const StyledList = withStyles({
    root: {
        width: "100%",
        height: "100%",
    },
    padding: {
        paddingTop: "0px",
        paddingBottom: "0px",
    },
})(List);

const StylesTypography = withStyles({
    root: {
        width: "100%",
    },
})(Typography);

type FileDisplayContainerProps = {
    children: React.ReactNode;
    height: number;
    downloadedInfo: {
        downloadedCount: number;
        totalCount: number;
        usedSpace: string;
        freeSpace: string;
        errors?: string[];
    };
    onUploadFiles?: (files: File[]) => void;
};

export const FileDisplayContainer = (props: FileDisplayContainerProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const { downloadedInfo, onUploadFiles } = props;
    const [isDragOver, setIsDragOver] = useState(false);

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        unsetDragOver();
    };

    const unsetDragOver = () => {
        if (isDragOver) {
            setIsDragOver(false);
        }
    };

    const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        unsetDragOver();
        const files = await getFilesAsync(e.dataTransfer);
        onUploadFiles?.(files);
    };

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!isDragOver && e.dataTransfer?.types?.includes?.("Files")) {
            setIsDragOver(true);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.target.files as FileList;
        onUploadFiles?.(Array.from(fileList));
        e.target.value = "";
    };

    return (
        <StylesTypography noWrap>
            <div
                className={classNames(styles.container, {
                    [styles.dragOver]: isDragOver,
                    [styles.hasErrors]: downloadedInfo.errors?.length > 0,
                })}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDragLeave={onDragLeave}
            >
                <Grid container className={styles.fileContainer}>
                    <div
                        className={styles.fileList}
                        style={{ height: props.height }}
                    >
                        <StyledList>{props.children}</StyledList>
                    </div>
                </Grid>
                <Grid
                    className={styles.workPanel}
                    container
                    alignItems="center"
                    justifyContent="start"
                    wrap="nowrap"
                    component="div"
                >
                    <Grid item>
                        <StyledIconButton>
                            <Input
                                id="file"
                                type="file"
                                style={{ display: "none" }}
                                onChange={onChange}
                                inputProps={{ multiple: true }}
                            />
                            <StyledInputLabel htmlFor="file">
                                <AddCircleIcon />
                            </StyledInputLabel>
                        </StyledIconButton>
                    </Grid>
                    <Grid item container>
                        {/* {t("send-file:fileDisplay.chooseFile")} */}

                        {t("send-file:fileDisplay.chooseFilesOrFolder.choose")}
                        <label className={styles.andFolderLabel}>
                            {t(
                                "send-file:fileDisplay.chooseFilesOrFolder.files",
                            )}
                            <Input
                                type="file"
                                className={styles.input}
                                inputProps={{ multiple: true }}
                                onChange={onChange}
                            />
                        </label>
                        {t("send-file:fileDisplay.chooseFilesOrFolder.or")}
                        <label className={styles.andFolderLabel}>
                            {t(
                                "send-file:fileDisplay.chooseFilesOrFolder.folder",
                            )}
                            <Input
                                type="file"
                                className={styles.input}
                                inputProps={{
                                    multiple: true,
                                    webkitdirectory: "",
                                }}
                                onChange={onChange}
                            />
                        </label>
                        {t(
                            "send-file:fileDisplay.chooseFilesOrFolder.forUpload",
                        )}
                    </Grid>
                    {/* <Grid item xs={4}>
                        <Grid container wrap="wrap">
                            <Grid container justifyContent="flex-end">
                                <span className={styles.opacity}>
                                    {t("send-file:fileDisplay.usedSpace")}:{" "}
                                    {downloadedInfo.usedSpace}
                                </span>
                            </Grid>
                            <Grid container justifyContent="flex-end">
                                <span className={styles.opacity}>
                                    {t("send-file:fileDisplay.freeSpace")}:{" "}
                                    {downloadedInfo.freeSpace}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </div>
            <div className={styles.downloaded}>
                {t("send-file:fileDisplay.downloaded", {
                    downloadCount: downloadedInfo.downloadedCount,
                    totalCount: downloadedInfo.totalCount,
                })}
            </div>
            {downloadedInfo.errors?.length > 0 && (
                <div className={styles.errors}>
                    {(downloadedInfo.errors || []).map(error => (
                        <div key={error}>{t(error)}</div>
                    ))}
                </div>
            )}
        </StylesTypography>
    );
};

export default FileDisplayContainer;
