import React from "react";
import CircularProgress, {
    CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    const [progress, setProgress] = React.useState(props.value || 0);

    React.useEffect(() => {
        setProgress(props.value);
    }, [props]);

    return (
        <Box position="relative" display="inline-flex">
            {
                <>
                    <CircularProgress variant="determinate" {...props} />
                    <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                        >
                            {progress}%
                        </Typography>
                    </Box>
                </>
            }
        </Box>
    );
}
