import { Breakpoints } from "@mui/material/styles";

import {
    SCREEN_XL,
    SCREEN_LG,
    SCREEN_MD,
    SCREEN_SM,
    SCREEN_XS,
} from "src/constants/breakpoints";

type Size = number | "xs" | "sm" | "md" | "lg" | "xl";

const getExtremeQuery = (leftEdge?: boolean, rightEdge?: boolean) => {
    if (rightEdge && leftEdge) {
        return (sizeStart: Size, sizeEnd: Size) => {
            let finalSizeStart: number;
            let finalSizeEnd: number;
            switch (sizeStart) {
                case "xl": {
                    finalSizeStart = SCREEN_XL;
                    break;
                }
                case "lg": {
                    finalSizeStart = SCREEN_LG;
                    break;
                }
                case "md": {
                    finalSizeStart = SCREEN_MD;
                    break;
                }
                case "sm": {
                    finalSizeStart = SCREEN_SM;
                    break;
                }
                case "xs": {
                    finalSizeStart = SCREEN_XS;
                    break;
                }
                default:
                    finalSizeStart = sizeStart;
            }
            switch (sizeEnd) {
                case "xl": {
                    finalSizeEnd = SCREEN_XL;
                    break;
                }
                case "lg": {
                    finalSizeEnd = SCREEN_LG;
                    break;
                }
                case "md": {
                    finalSizeEnd = SCREEN_MD;
                    break;
                }
                case "sm": {
                    finalSizeEnd = SCREEN_SM;
                    break;
                }
                case "xs": {
                    finalSizeEnd = SCREEN_XS;
                    break;
                }
                default:
                    finalSizeEnd = sizeEnd;
            }
            return `@media (min-width: ${finalSizeStart}px) and (max-width: ${finalSizeEnd}px)`;
        };
    } else {
        const edge = leftEdge ? "max-width" : "min-width";
        return (size: Size) => {
            switch (size) {
                case "xl":
                    return `@media (${edge}: ${SCREEN_XL}px)`;
                case "lg":
                    return `@media (${edge}: ${SCREEN_LG}px)`;
                case "md":
                    return `@media (${edge}: ${SCREEN_MD}px)`;
                case "sm":
                    return `@media (${edge}: ${SCREEN_SM}px)`;
                case "xs":
                    return `@media (${edge}: ${SCREEN_XS}px)`;
                default:
                    return `@media (${edge}: ${size}px)`;
            }
        };
    }
};

export const breakpoints: Partial<
    {
        unit: string;
        step: number;
    } & Breakpoints
> = {
    down: getExtremeQuery(true) as any,
    up: getExtremeQuery(false, true) as any,
    between: getExtremeQuery(true, true),
};
