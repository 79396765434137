import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, FormControlLabel, TextField, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Logo } from "../components/Logo";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { Grid } from "@mui/material";
import { ReactComponent as CheckboxIconUnchecked } from "../icons/checkboxUnchecked.svg";
import { ReactComponent as CheckboxIconChecked } from "../icons/checkboxChecked.svg";
import { useForm, Controller } from "src/components/Form";
import login_background from "src/icons/loginpage/login_background.svg";
import { authenticate, setCountDownParams } from "../redux/pages/login/action";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { AuthenticationRequestModel } from "../services/generated";
import { useHistory, useLocation } from "react-router";
import { routePaths } from "src/routes/routePaths";
import { Preloader } from "src/components/Preloader";
import { PasswordWithEye } from "src/components/PasswordWithEye";
import { REDIRECT_URL_KEY } from "src/constants/localstorageKeys";
import { UserBlockCountDownParams } from "src/types/redux/store/pages/loginPage";
import classNames from "classnames";
import { ReactComponent as ErrorIcon } from "src/icons/error-icon.svg";

const useStyle = makeStyles((theme: Theme) => ({
    content: {
        background: "#FFF",
        width: "520px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "auto",
        zIndex: "2",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    width100: {
        width: "100%",
    },
    height100: {
        height: "100%",
    },
    logoContainer: {
        marginTop: "100px",
        minHeight: "50px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            marginTop: "68px",
        },
    },
    loginFormContainer: {
        maxWidth: "288px",
        marginTop: "96px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "40px",
        },
    },
    loginForm: {
        marginTop: "40px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "20px",
        },
    },
    footerContainer: {
        marginTop: "30px",
        paddingBottom: "16px",
        color: "#959595",
        fontSize: "12px",
        lineHeight: "14px",
    },
    pageContainer: {
        overflow: "auto",
        height: "100vh",
        background: "#171742",
        display: "flex",
    },
    checkBox: {
        marginLeft: "-8px",
        marginTop: "4px",
    },
    failBlockWrapper: {
        minHeight: "52px",
        display: "flex",
        alignItems: "flex-start",
    },
    failBlock: {
        width: 288,
        borderRadius: 4,
        borderLeft: "4px solid #FF0000",
        padding: "12px 16px 12px 18px",
        fontSize: "12px",
        fontFamily: "Roboto",
        backgroundColor: "#FFE6E6",
        display: "flex",
        alignItems: "center",
    },
    failBlockIsHidden: {
        display: "none",
    },
    failBlockErrorIcon: {
        marginRight: 14,
        height: 20,
        width: 20,
        flexBasis: 20,
        flexShrink: 0,
    },
    preloader: {
        "& span": {
            marginTop: "-150px",
        },
    },
    containerImg: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
    },
    loginImg: {
        padding: "20px 0",
        maxHeight: "604px",
        minHeight: 0,
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
}));

const beautifyCounter = (counter: number) => {
    const minutes = Math.floor(counter / 60);
    const min = minutes < 10 ? `0${minutes}` : minutes;
    const seconds = counter - minutes * 60;
    const sec = seconds < 10 ? `0${seconds}` : seconds;
    return `${min}:${sec}`;
};

export const LoginPage = () => {
    const styles = useStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { search } = useLocation<{ redirectUrl: string }>();
    const { push } = useHistory();
    const {
        authenticationFailCode,
        temporaryBlocked,
        authenticateLoading,
        countDownParams,
        licenseHasExpired,
    } = useSelector((store: Store) => store.authenticationHandler);
    const { user, accessToken } = useSelector(
        (store: Store) => store.authenticationHandler.authentication,
    );
    const { register, handleSubmit, setValue, watch, control } =
        useForm<AuthenticationRequestModel>({
            defaultValues: {
                withRefreshToken: false,
                password: "",
                username: "",
            },
        });
    const intervalId = useRef(null);
    const [counter, setCounter] = useState<number | null>(null);

    const username = watch().username;

    const changeCounter = useCallback(
        (countDownParams: UserBlockCountDownParams) => {
            const secondsRemaining =
                countDownParams.blockTimeInMinutes * 60 -
                Math.ceil((Date.now() - countDownParams.date) / 1000);
            if (secondsRemaining < 0) {
                clearInterval(intervalId.current);
                dispatch(
                    setCountDownParams({
                        username: countDownParams.username,
                        params: null,
                    }),
                );
                return;
            }
            setCounter(secondsRemaining);
        },
        [dispatch],
    );

    useEffect(() => {
        const redirectUrl = new URLSearchParams(search).get("redirectUrl");

        if (redirectUrl) {
            sessionStorage.setItem(REDIRECT_URL_KEY, redirectUrl);
        }
        if (user && accessToken) {
            if (redirectUrl) {
                push(redirectUrl);
            } else {
                push(routePaths.sendFile);
            }
        }
    }, [user, accessToken, search, push]);

    useEffect(() => {
        if (!countDownParams[username]) {
            clearInterval(intervalId.current);
            return;
        }

        clearInterval(intervalId.current);
        intervalId.current = setInterval(
            changeCounter,
            1000,
            countDownParams[username],
        );
        changeCounter(countDownParams[username]);
    }, [countDownParams, username, changeCounter]);

    const onSubmit = (data: AuthenticationRequestModel) => {
        data.username = data.username.trim();
        dispatch(authenticate(data));
    };

    const handleRememberMe = useCallback<
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    >(
        (event, checked) => {
            setValue("withRefreshToken", checked);
        },
        [setValue],
    );

    const isFailAuthentication = authenticationFailCode !== null;
    const noConnection = isFailAuthentication && !authenticationFailCode;
    const isCredentialsFail =
        authenticationFailCode === 500 || authenticationFailCode === 400;
    const isLoginForbidden =
        authenticationFailCode === 403 && !temporaryBlocked;
    const isUserBlockedForShortPeriod =
        countDownParams && !!countDownParams[username];
    const isLecenseExpired =
        authenticationFailCode === 403 && licenseHasExpired;

    const getError = () => {
        switch (true) {
            case noConnection: {
                return t("login:incorrect.noConnection");
            }
            case isLoginForbidden: {
                return t("login:incorrect.forbidenMessage");
            }
            case isUserBlockedForShortPeriod: {
                return t("login:incorrect.blockedForShortPeriod", {
                    minutesCounter: beautifyCounter(counter),
                });
            }
            case isCredentialsFail: {
                return t("login:incorrect.сredentialsMessage");
            }
            case isLecenseExpired: {
                return t("login:incorrect.licenseExpired");
            }
        }
    };

    return (
        <div className={styles.pageContainer}>
            {authenticateLoading && (
                <Preloader className={styles.preloader} over size={45} />
            )}
            <Grid
                className={styles.containerImg}
                container
                direction="column"
                alignItems="flex-start"
                justifyContent="center"
            >
                <img
                    src={login_background}
                    className={styles.loginImg}
                    alt="Login page"
                />
            </Grid>

            <div className={styles.content}>
                <div className={styles.logoContainer}>
                    <Logo />
                </div>
                <Grid
                    className={styles.height100}
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                >
                    <div className={styles.loginFormContainer}>
                        <div className={styles.failBlockWrapper}>
                            <div
                                className={classNames(styles.failBlock, {
                                    [styles.failBlockIsHidden]: !(
                                        isFailAuthentication ||
                                        isUserBlockedForShortPeriod
                                    ),
                                })}
                            >
                                <ErrorIcon
                                    width={20}
                                    height={20}
                                    className={styles.failBlockErrorIcon}
                                />
                                {getError()}
                            </div>
                        </div>

                        <form
                            className={styles.loginForm}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <TextField
                                label={t("login:email")}
                                error={isCredentialsFail}
                                {...register("username")}
                            />
                            <PasswordWithEye
                                label={t("login:password")}
                                error={isCredentialsFail}
                                {...register("password")}
                            />
                            <Button
                                disabled={isUserBlockedForShortPeriod}
                                type="submit"
                                color="primary"
                                className={styles.width100}
                            >
                                {t("login:login")}
                            </Button>
                            <Controller
                                name="withRefreshToken"
                                control={control}
                                render={() => (
                                    <FormControlLabel
                                        label={t("login:rememberMe")}
                                        className={styles.checkBox}
                                        control={
                                            <Checkbox
                                                name="remember me"
                                                icon={<CheckboxIconUnchecked />}
                                                checkedIcon={
                                                    <CheckboxIconChecked />
                                                }
                                                onChange={handleRememberMe}
                                            />
                                        }
                                    />
                                )}
                            />
                        </form>
                    </div>
                    <div className={styles.footerContainer}>
                        {t("login:footer")}
                    </div>
                </Grid>
            </div>
        </div>
    );
};
