import { TableRowClasses } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

export const MuiTableRow: Partial<
    OverridesStyleRules<keyof TableRowClasses, "MuiTableRow", unknown>
> = {
    root: {
        "&:hover": {
            background: "rgba(239, 239, 244, 0.8)",
        },
    },
    head: {
        "&:hover": {
            background: "#FFFFFF",
        },
    },
};
