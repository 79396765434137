import moment from "moment";

export const calculateTimeToExpire = (
    futureDateString: moment.MomentInput,
): DatesDifference => {
    if (!futureDateString) {
        return {
            days: 0,
            minutes: 0,
            hours: 0,
        };
    }

    const now = moment();
    const nextDateMoment = moment(futureDateString);
    const days = nextDateMoment.diff(now, "days");
    const hours = nextDateMoment.diff(now, "hours") - days * 24;
    const minutes =
        nextDateMoment.diff(now, "minutes") - days * 24 * 60 - hours * 60;
    return {
        days,
        hours,
        minutes,
    };
};

export interface DatesDifference {
    days: number;
    hours: number;
    minutes: number;
}

export const ONE_MINUTE_IN_MS = 60000;
export const TEN_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 10;
export const JWT_MULTIPLIER = 1000;
