import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { ExtendMessageMailHeadersDTO } from "src/services/generated";
import { ExtendMessageSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getExtendMessage, updateExtendMessage } from "./actions";

const extendMessageSettingsInitialState: ExtendMessageSettingsPage = {
    loading: false,
};

export const extendMessageSettingsReducer =
    createReducer<ExtendMessageSettingsPage>(
        extendMessageSettingsInitialState,
        {
            [getExtendMessage.pending.type](state) {
                state.loading = true;
            },
            [getExtendMessage.fulfilled.type](
                state,
                action: PayloadAction<ExtendMessageMailHeadersDTO>,
            ) {
                state.loading = false;
                state.form = action.payload;
            },
            [getExtendMessage.rejected.type](state) {
                state.loading = false;
            },
            [updateExtendMessage.pending.type](state) {
                state.loading = true;
            },
            [updateExtendMessage.fulfilled.type](state) {
                state.loading = false;
            },
            [updateExtendMessage.rejected.type](state) {
                state.loading = false;
            },
        },
    );
