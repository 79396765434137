import { passwordRegExp } from "src/constants/sendFiles";
import { FileDTO, ResponseFileDTO } from "src/services/generated";
import { checkIfFileInIntermediateState, isFiledFile } from "./file";

export function getTooltipText(files: (ResponseFileDTO | FileDTO)[]) {
    if (!Boolean(files?.length) || files?.every(isFiledFile)) {
        return "send-file:tooltipText.emptyMessage";
    }
    if (files.some(checkIfFileInIntermediateState)) {
        return "send-file:tooltipText.filesNotLoaded";
    }
    return "";
}

export function getTooltipTextWithPassword(
    files: (ResponseFileDTO | FileDTO)[],
    isPasswordEnabled?: boolean,
    password?: string,
) {
    if (isPasswordEnabled) {
        if (!password) {
            return "send-file:tooltipText.passwordIsEmpty";
        }

        if ((password.match(passwordRegExp)?.[0] || "") !== password) {
            return "send-file:errors.incorrectData";
        }
    }

    return getTooltipText(files);
}
