import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
// import logger from "redux-logger";
import { authenticationReducer } from "./pages/login/reducer";
import { Store } from "src/types/redux/store/index";
import { sendFilePageReducer } from "src/redux/pages/sendFile/reducer";
import { myFilesPageReducer } from "src/redux/pages/myFiles/reducer";
import { administrationStatisticsPageReducer } from "./pages/administrationStatistics/reducer";
import { administrationFilesPageReducer } from "./pages/administrationFiles/reducer";
import { commonDialogReducer } from "./dialogs/commonDialog/reducer";
import { administrationUsersPageReducer } from "./pages/administrationUsers/reducer";
import { administrationLogFilesPageReducer } from "./pages/administrationLogFiles/reducer";
import { administrationStoragesPageReducer } from "./pages/administrationStorages/reducer";
import { filesRequestPageReducer } from "./pages/filesRequest/reducer";
import { downloadPageReducer } from "./pages/downloadPage/reducer";
import { responseMessageReducer } from "./pages/responseMessage/reducer";
import { appLogoReducer } from "./logo/reducer";
import { responseDownloadReducer } from "./pages/responseMessageDownloadPage/reducer";
import { techSupportPageReducer } from "./pages/techSupport/reducer";
import { generalSettingsReducer } from "./pages/administrationSettings/general/reducer";
import { mailSettingsReducer } from "./pages/administrationSettings/mail/reducer";
import { ldapSettingsReducer } from "./pages/administrationSettings/ldap/reducer";
import { mailHeadersSettingsReducer } from "./pages/administrationSettings/mailHeaders/reducer";
import { extendMessageSettingsReducer } from "./pages/administrationSettings/extendMessage/reducer";
import { licenseSettingsReducer } from "./pages/administrationSettings/license/reducer";
import { storageSettingsReducer } from "./pages/administrationSettings/storage/reducer";
import { logFileSettingsReducer } from "./pages/administrationSettings/logFiles/reducer";
import { downloadMenuReducer } from "./downloadMenu/reducer";
import { appVersionReducer } from "./version/reducer";
import { licenseExpiredMessageSettingsReducer } from "./pages/administrationSettings/licenseExpiredMessage/reducer";
import {
    publicSettingsReducer,
    privateSettingsReducer,
} from "./pages/applicationSettings/reducer";
import { deletedMessageSettingsReducer } from "./pages/administrationSettings/deletedMessage/reducer";
import { userDeactivationSettingsReducer } from "./pages/administrationSettings/deactivation/reducer";

export const store = configureStore({
    reducer: combineReducers<Store>({
        pages: combineReducers({
            sendFilePage: sendFilePageReducer,
            myFilesPage: myFilesPageReducer,
            downloadPage: downloadPageReducer,
            responseMessagePage: responseMessageReducer,
            responseMessageDownloadPage: responseDownloadReducer,
            administration: combineReducers({
                administrationStatisticsPage:
                    administrationStatisticsPageReducer,
                administrationStoragesPage: administrationStoragesPageReducer,
                administrationUsersPage: administrationUsersPageReducer,
                administrationLogFilesPage: administrationLogFilesPageReducer,
                administrationFilesPage: administrationFilesPageReducer,
                administrationSettings: combineReducers({
                    generalSettingsPage: generalSettingsReducer,
                    logFileSettingsPage: logFileSettingsReducer,
                    mailSettingsPage: mailSettingsReducer,
                    ldapSettingsPage: ldapSettingsReducer,
                    mailHeadersSettingsPage: mailHeadersSettingsReducer,
                    licenseExpiredMessageSettingsPage:
                        licenseExpiredMessageSettingsReducer,
                    extendMessageSettingsPage: extendMessageSettingsReducer,
                    deletedMessageSettingsPage: deletedMessageSettingsReducer,
                    licenseSettingsPage: licenseSettingsReducer,
                    storageSettingsPage: storageSettingsReducer,
                    userDeactivationSettingsPage:
                        userDeactivationSettingsReducer,
                }),
            }),
            filesRequestPage: filesRequestPageReducer,
            techSupportPage: techSupportPageReducer,
        }),
        authenticationHandler: authenticationReducer,
        dialogs: combineReducers({
            commonDialog: commonDialogReducer,
        }),
        appLogoInfo: appLogoReducer,
        publicSettings: publicSettingsReducer,
        privateSettings: privateSettingsReducer,
        appVersionInfo: appVersionReducer,
        downloadMenuInfo: downloadMenuReducer,
    }),
    // middleware: [...getDefaultMiddleware() as any, logger]
    middleware: [...(getDefaultMiddleware() as any)],
});
