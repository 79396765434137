import React from "react";
import { ClosingDialogTitle } from "../СlosingDialogTitle";
import { Button, Dialog, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { BaseDialogProps } from "src/pages/MyFiles";
import { DirectLinksList } from "src/components/DirectLinksList";

export const DialogDirectLinks = ({
    open,
    onClose,
    upload,
}: BaseDialogProps) => {
    const { t } = useTranslation();
    return (
        <DialogStyles open={open} onClose={onClose}>
            <ClosingDialogTitle onClose={onClose}>
                {t("my-file:dropDownMenuItems.directLinks")}
            </ClosingDialogTitle>
            <DirectLinksList message={upload} />
            <DialogActionsStyles>
                <Button onClick={onClose} color="secondary">
                    {t("my-file:directLinksDialog.cancel")}
                </Button>
            </DialogActionsStyles>
        </DialogStyles>
    );
};

const DialogStyles = styled(Dialog)`
    & .MuiPaper-root {
        width: 440px;
    }
`;

const DialogActionsStyles = styled(DialogActions)`
    margin-top: 24px;
`;
