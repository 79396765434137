import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Store } from "src/types/redux/store/index";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "src/components/Form";
import { CommonAdminForm } from "../../../components/Admin/commonAdminForm";
import { LdapSettingsDTO } from "src/services/generated";
import { LdapSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { Preloader } from "src/components/Preloader";
import { PasswordWithEye } from "src/components/PasswordWithEye";
import {
    getSettingsLdap,
    updateSettingsLdap,
} from "src/redux/pages/administrationSettings/ldap/actions";

const useStyle = makeStyles({
    container: {
        maxWidth: 800,
    },
    inputSetting: {
        paddingBottom: "16px",
    },
});

export const Ldap = () => {
    const { t } = useTranslation();
    const styles = useStyle();
    const dispatch = useDispatch();
    const { loading, form } = useSelector<Store, LdapSettingsPage>(
        store =>
            store.pages.administration.administrationSettings.ldapSettingsPage,
    );
    const { register, reset, handleSubmit } = useForm<LdapSettingsDTO>({
        defaultValues: form,
    });

    useEffect(() => {
        dispatch(getSettingsLdap());
    }, [dispatch]);

    useEffect(() => {
        reset(form);
    }, [form, reset]);

    const onSubmit = (data: LdapSettingsDTO) => {
        dispatch(updateSettingsLdap(data));
    };

    return (
        <CommonAdminForm
            submitLabel={t("admin-settings:ldap.submitLabel")}
            onSubmit={handleSubmit(onSubmit)}
            className={styles.container}
        >
            {loading && <Preloader over />}
            <Grid container direction="column">
                <Grid item xs={6}>
                    <Typography variant="h6">
                        {t("admin-settings:settingsLDAP.titleSettingsLDAP")}
                    </Typography>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"users.dn"}
                        defaultValue=""
                        {...register("usersDn")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"users.filter"}
                        {...register("usersFilters")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"roles.dn"}
                        {...register("rolesDn")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"roles.filter"}
                        {...register("rolesFilter")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"user"}
                        {...register("user")}
                    />
                    <PasswordWithEye
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"password"}
                        {...register("password")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"url"}
                        {...register("url")}
                    />
                </Grid>
            </Grid>
        </CommonAdminForm>
    );
};
