import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { addFile, updateDownloadProgress, removeFile, abort } from "./actions";
import { FileViewModel } from "src/types/FileDisplay";
import { DownloadMenuInfo } from "src/types/redux/store/DownloadMenuInfo";
import { FileStatus } from "src/services/generated";

const initialState: DownloadMenuInfo = {
    loadedFiles: [],
};

export const downloadMenuReducer = createReducer(initialState, {
    [addFile.type](state, action: PayloadAction<FileViewModel>) {
        const file = action.payload;
        state.loadedFiles.push(file);
    },
    [updateDownloadProgress.type](state, action: PayloadAction<FileViewModel>) {
        const file = action.payload;
        state.loadedFiles.find(({ id }) => file.id === id).progress =
            file.progress;
    },
    [removeFile.type](state, action: PayloadAction<string>) {
        state.loadedFiles = state.loadedFiles.filter(
            ({ id }) => action.payload !== id,
        );
    },
    [abort.type](state, action: PayloadAction<string>) {
        state.loadedFiles.find(({ id }) => action.payload === id).status =
            FileStatus.UPLD_PART;
    },
});
