import { styled, Checkbox } from "@mui/material";
import React from "react";

type SelectorProps = {
    rowId: string;
    onRowSelect: (arg: { [s: string]: boolean }) => void;
    selectedRows: { [s: string]: boolean };
};

export const Selector = ({
    rowId,
    selectedRows,
    onRowSelect,
}: SelectorProps) => {
    return (
        <>
            {onRowSelect && (
                <CheckboxStyled
                    checked={selectedRows[rowId] || false}
                    onChange={e => {
                        if (e.target.checked) {
                            const newSelected = {
                                ...selectedRows,
                                [rowId]: e.target.checked,
                            };
                            onRowSelect(newSelected);
                        } else {
                            delete selectedRows[rowId];
                            onRowSelect({ ...selectedRows });
                        }
                    }}
                />
            )}
        </>
    );
};

const CheckboxStyled = styled(Checkbox)`
    align-self: baseline;
    padding-right: 12px;
`;
