import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../types/redux/store";
import { SendFilePageStates } from "../../types/redux/store/pages/SendFilePage";
import {
    changeSendFilePageState,
    deleteMessage,
} from "../../redux/pages/sendFile/actions";
import { getMessageDownloadWithHost } from "src/helpers/url";
import { copyText } from "src/helpers/clipboard";
import { Theme } from "@mui/material/styles";

const useStyle = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "40px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    },
    button: {
        width: "210px",
        marginTop: "40px",
    },
    description: {
        fontSize: "20px",
        fontWeight: 500,
    },
    emails: {
        fontSize: "20px",
        color: "#4A90E2",
        textDecoration: "underline",
    },
    link: {
        color: "#4A90E2",
    },
    copyButton: {
        marginTop: "20px",
        width: "210px",
    },
}));

export const AfterMessageSending = () => {
    const styles = useStyle();
    const { t } = useTranslation();
    const message = useSelector(
        (store: Store) => store.pages.sendFilePage.message,
    );
    const currentPageState = useSelector(
        (store: Store) => store.pages.sendFilePage.currentPageState,
    );
    const dispatch = useDispatch();

    const sendMore = () => {
        dispatch(deleteMessage());
        dispatch(changeSendFilePageState(SendFilePageStates.MESSAGE_CREATING));
    };

    const link = getMessageDownloadWithHost(message?.id!);

    return (
        <div className={styles.container}>
            {currentPageState === SendFilePageStates.AFTER_MESSAGE_SENDING && (
                <>
                    <div className={styles.description}>
                        {t("send-file:afterMessageSending.fileSent")}
                    </div>
                    <div className={styles.emails}>
                        {message?.recipients?.map(
                            (recipient: string, index) => (
                                <div key={index}>{recipient}</div>
                            ),
                        )}
                    </div>
                </>
            )}
            {currentPageState === SendFilePageStates.AFTER_LINK_SENDING && (
                <>
                    <div className={styles.description}>
                        {t(
                            "send-file:afterMessageSending.fileIsAvailableByLink",
                        )}
                    </div>
                    <div>
                        <a
                            href={`/download/${message?.id}`}
                            className={styles.link}
                        >
                            {link}
                        </a>
                    </div>
                    <div className={styles.description}>
                        {t("send-file:afterMessageSending.fileDirection")}
                    </div>
                    <div>
                        <Button
                            className={styles.copyButton}
                            color="secondary"
                            onClick={() => copyText(link)}
                        >
                            {t("send-file:afterMessageSending.copyLink")}
                        </Button>
                    </div>
                </>
            )}
            <div>
                <Button
                    className={styles.button}
                    color="primary"
                    onClick={sendMore}
                >
                    {t("send-file:afterMessageSending.sendMore")}
                </Button>
            </div>
        </div>
    );
};
