import i18n from "src/i18n";

export type SizeValue = "byte" | "KB" | "MB" | "GB" | "TB";
interface PrettyBytesOptions {
    hideSize?: boolean;
    sizeToRound?: SizeValue;
}

const sizes: SizeValue[] = ["byte", "KB", "MB", "GB", "TB"];
const bytesInKilobyte = 1024;

const getSizeByBytes = (bytes: number, sizeToRound?: SizeValue) => {
    if (bytes === 0) {
        return { sizeBytes: 0, index: 0 };
    }
    let sizeBytes = bytes;
    let index = 0;

    if (sizeToRound) {
        index = sizes.findIndex(size => size === sizeToRound);
        sizeBytes /= Math.pow(bytesInKilobyte, index);
    } else {
        while (sizeBytes >= 1 && index <= sizes.length) {
            sizeBytes /= bytesInKilobyte;
            index++;
        }
        index -= 1;
        sizeBytes *= bytesInKilobyte;
    }

    return { sizeBytes, index };
};

export const prettyBytes = (
    bytes?: number | null,
    options: PrettyBytesOptions = {},
) => {
    const { hideSize, sizeToRound } = options;

    if (bytes === undefined || bytes === null) {
        return "";
    }

    const { index, sizeBytes } = getSizeByBytes(bytes, sizeToRound);
    let formattedSizeBytes = sizeBytes.toFixed(2);
    if (formattedSizeBytes[formattedSizeBytes.length - 1] === "0") {
        formattedSizeBytes = formattedSizeBytes.slice(
            0,
            formattedSizeBytes.length - 1,
        );
    }
    if (formattedSizeBytes[formattedSizeBytes.length - 1] === "0") {
        formattedSizeBytes = formattedSizeBytes.slice(
            0,
            formattedSizeBytes.length - 2,
        );
    }

    if (hideSize) {
        return formattedSizeBytes;
    }

    return `${formattedSizeBytes} ${i18n.t(`common:sizes.${sizes[index]}`)}`;
};
