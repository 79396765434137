import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { LogFileDTO } from "src/services/generated";
import { loadListLogFiles } from "src/redux/pages/administrationLogFiles/actions";
import { ReactComponent as DownloadImage } from "src/icons/download.svg";
import { ISO_FORMAT_DATE } from "src/constants/momentFormats";
import { Dayjs } from "dayjs";
import { NoDataFound } from "src/components/NoDataFound";
import { DataGrid } from "src/components/DataGrid";
import { getColumns } from "./getColumns";
import { useStyles } from "./utils";
import { MobileTable } from "./MobileTable";
import { useSortField } from "src/hooks/useSortField";

type LogFilesTableProps = {
    dateRange: Dayjs[];
    onDownloadLogFile: (file: LogFileDTO) => void;
    isLoadingFile: boolean;
};
const LogFilesTable = ({
    dateRange,
    onDownloadLogFile,
    isLoadingFile,
}: LogFilesTableProps) => {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { logFilesInfo, listLogFiles, loadingListLogFiles } = useSelector(
        (store: Store) => store.pages.administration.administrationLogFilesPage,
    );
    const { setPagination, pagination, sorting, setSorting } =
        DataGrid.useTableState();

    const { sortDir, sortField } = useSortField(sorting, [
        { id: "createdDateTime", desc: true },
    ]);
    useEffect(() => {
        dispatch(
            loadListLogFiles({
                page: pagination.pageIndex + 1,
                size: pagination.pageSize,
                fromDate: dateRange?.[0]?.format(ISO_FORMAT_DATE),
                toDate: dateRange?.[1]?.format(ISO_FORMAT_DATE),
                sortDir,
                sortField,
            }),
        );
    }, [dispatch, pagination, dateRange, sorting, sortDir, sortField]);

    useEffect(() => {
        setPagination({
            pageIndex: 0,
            pageSize: pagination.pageSize,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);

    return (
        <React.Fragment>
            {listLogFiles.length > 0 || loadingListLogFiles ? (
                <>
                    <div className={styles.tableVisibility}>
                        <DataGrid
                            columns={getColumns(t, styles)}
                            data={listLogFiles}
                            layoutMode="grid"
                            positionActionsColumn="last"
                            enableRowActions
                            manualPagination
                            enableColumnActions={false}
                            onPaginationChange={setPagination}
                            onSortingChange={setSorting}
                            rowCount={logFilesInfo.totalElements}
                            mobileView={<></>}
                            state={{
                                pagination,
                                sorting,
                            }}
                            renderRowActions={({ row }) => {
                                return (
                                    <button
                                        className={styles.loadButton}
                                        disabled={isLoadingFile}
                                        onClick={() =>
                                            onDownloadLogFile(row.original)
                                        }
                                    >
                                        <DownloadImage
                                            fill={"#1A1F29"}
                                            className={styles.image}
                                        />
                                    </button>
                                );
                            }}
                            displayColumnDefOptions={{
                                "mrt-row-actions": {
                                    header: "",
                                    grow: false,
                                },
                            }}
                        />
                    </div>
                    <MobileTable
                        listLogFiles={listLogFiles}
                        loadingListLogFiles={loadingListLogFiles}
                        isLoadingFile={isLoadingFile}
                        onDownloadLogFile={onDownloadLogFile}
                        pagination={{
                            page: pagination.pageIndex,
                            totalElements: logFilesInfo.totalElements,
                            totalPages: logFilesInfo.totalPages,
                            pageSize: pagination.pageSize,
                        }}
                        setPagination={setPagination}
                    />
                </>
            ) : (
                <NoDataFound />
            )}
        </React.Fragment>
    );
};

export default LogFilesTable;
