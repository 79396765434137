import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, Row } from "src/components/DataGrid";
import { NoDataFound } from "src/components/NoDataFound";
import { Store } from "src/types/redux/store";
import { getColumns } from "./getColumns";
import { useTranslation } from "react-i18next";
import { MoreVert } from "@mui/icons-material";
import { DropDownMenu, DropDownMenuItem } from "src/components/DropDownMenu";
import { openDialog } from "src/redux/dialogs/commonDialog/actions";
import {
    reorderStorages,
    selectEditStorage,
} from "src/redux/pages/administrationStorages/actions";
import { LocalFileStorageDTO } from "src/services/generated";
import styled from "@emotion/styled";

type StoragesTableProps = {
    storagesFilter: string;
    setConfirmDeleteStorageId: React.Dispatch<React.SetStateAction<number>>;
};

export const StoragesTable = ({
    storagesFilter,
    setConfirmDeleteStorageId,
}: StoragesTableProps) => {
    const { t } = useTranslation("admin-storages");
    const dispatch = useDispatch();
    const { setPagination, pagination } = DataGrid.useTableState();
    const { storages, loadingStorages } = useSelector(
        (store: Store) => store.pages.administration.administrationStoragesPage,
    );

    const dropDownMenuItems: DropDownMenuItem[] = [
        {
            title: t("menuActions.edit"),
            onClick: function (event: React.MouseEvent, context) {
                const storageIndex = storages.findIndex(
                    storage => context?.id === storage.id,
                )!;
                dispatch(openDialog("EditOrCreateStorage"));
                dispatch(selectEditStorage(storages[storageIndex]));
            },
        },
        {
            title: t("menuActions.delete"),
            onClick: function (event: React.MouseEvent, context) {
                setConfirmDeleteStorageId(context.id as number);
            },
            isDisabled: (index: number, context) => {
                if (storages.length < 2) {
                    return true;
                }
                const storage = storages.find(
                    storage => storage.id === context.id,
                );
                return storage && storage.usedSpace !== 0;
            },
            hint: (index: number, context) => {
                if (storages.length < 2) {
                    return t("menuActions.lastStorageHint") as string;
                }
                const storage = storages.find(
                    storage => storage.id === context.id,
                );
                if (storage && storage.usedSpace !== 0) {
                    return t("menuActions.deleteHint") as string;
                }
            },
        },
    ];

    const filteredStorages = useMemo(() => {
        return storages.filter(
            storage =>
                !storagesFilter ||
                storage.path
                    ?.toLowerCase()
                    .includes(storagesFilter.toLowerCase()),
        );
    }, [storages, storagesFilter]);

    const sumStorages = storages.reduce(
        (sum, storages) => {
            sum.size += storages.size;
            sum.freeSpace += storages.freeSpace;
            sum.usedSpace += storages.usedSpace;

            return sum;
        },
        {
            size: 0,
            freeSpace: 0,
            usedSpace: 0,
        },
    );

    return (
        <>
            {storages.length <= 0 && !loadingStorages ? (
                <NoDataFound />
            ) : (
                <>
                    <Container>
                        <DataGrid
                            data={filteredStorages}
                            columns={getColumns(t, sumStorages)}
                            enableColumnActions={false}
                            enableSorting={false}
                            onPaginationChange={setPagination}
                            enableRowOrdering
                            rowCount={filteredStorages.length}
                            enableRowActions
                            state={{
                                pagination,
                            }}
                            muiRowDragHandleProps={({ table }) => ({
                                onDragEnd: () => {
                                    const { draggingRow, hoveredRow } =
                                        table.getState();
                                    if (
                                        hoveredRow &&
                                        draggingRow &&
                                        draggingRow.index !== hoveredRow.index
                                    ) {
                                        const reorderedStorages = [...storages];
                                        reorderedStorages.splice(
                                            (
                                                hoveredRow as Row<LocalFileStorageDTO>
                                            ).index,
                                            0,
                                            reorderedStorages.splice(
                                                draggingRow.index,
                                                1,
                                            )[0],
                                        );
                                        dispatch(
                                            reorderStorages(reorderedStorages),
                                        );
                                    }
                                },
                            })}
                            positionActionsColumn="last"
                            layoutMode="grid"
                            renderRowActions={({ row }) => (
                                <DropDownMenu
                                    anchorElement={MoreVert}
                                    items={dropDownMenuItems}
                                    context={row.original}
                                />
                            )}
                            displayColumnDefOptions={{
                                "mrt-row-actions": {
                                    header: "",
                                    size: 68,
                                    grow: false,
                                },
                                "mrt-row-drag": {
                                    header: "",
                                },
                            }}
                        />
                    </Container>
                </>
            )}
        </>
    );
};

const Container = styled.div`
    height: calc(100% - 52px);
    margin-top: 16px;
`;
