import { SortingState } from "src/components/DataGrid";
import { ReportsUserRequestParamsSortDirEnum } from "src/services/generated";

export const useSortField = (
    sorting: SortingState,
    defaultSorting: SortingState = [],
) => {
    const newSorting = sorting.length === 0 ? defaultSorting : sorting;
    const sortDir = newSorting?.[0]?.desc
        ? ReportsUserRequestParamsSortDirEnum.DESC
        : ReportsUserRequestParamsSortDirEnum.ASC;

    const sortField = newSorting?.[0]?.id;

    return { sortDir, sortField };
};
