import React, { useState } from "react";
import dayjs from "dayjs";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, Box, IconButton, Button } from "@mui/material";
import color from "src/theme/variables/color";
import classNames from "classnames";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import { CalendarProps } from "./Calendar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: "#fff",
            width: "224px",
            minWidth: 0,
            padding: "0 24px 12px",
            [theme.breakpoints.down("sm")]: {
                padding: 0,
            },
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "48px",
            padding: "4px 0",
        },
        content: {
            display: "flex",
            flexWrap: "wrap",
        },
        calendarItem: {
            flex: "1 0 25%",
            borderRadius: "30px",
            padding: 0,
            minWidth: 0,
            fontWeight: 400,
            color: color.sendFileColor.onSecondary.text,
            textTransform: "none",
        },
        active: {
            backgroundColor: color.primary.main,
            color: "#fff",
            "&:hover": {
                backgroundColor: color.primary.main,
            },
        },
    }),
);

export const CalendarMonth = ({ onChange, date }: CalendarProps) => {
    const styles = useStyles();
    const [year, setYear] = useState(date.year());
    const onIncYear = () => {
        setYear(year + 1);
    };

    const onDecYear = () => {
        setYear(year - 1);
    };
    const createContent = () => {
        return dayjs.monthsShort().map((month, index) => (
            <Button
                variant="text"
                key={month}
                className={classNames(styles.calendarItem, {
                    [styles.active]: date.isSame(dayjs([year, index]), "month"),
                })}
                onClick={() =>
                    onChange(date.year(year).month(index).startOf("month"))
                }
            >
                {month}
            </Button>
        ));
    };

    return (
        <Box className={styles.container}>
            <Box className={styles.header}>
                <IconButton onClick={onDecYear}>
                    <ArrowBackIos />
                </IconButton>
                <Box>{year}</Box>
                <IconButton onClick={onIncYear}>
                    <ArrowForwardIos />
                </IconButton>
            </Box>
            <Box className={styles.content}>{createContent()}</Box>
        </Box>
    );
};
