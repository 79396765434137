import React from "react";
import { FilterAlt } from "@mui/icons-material";
import { Box, Button, Grid, TablePagination, styled } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useTranslation } from "react-i18next";
import { NoDataFound } from "src/components/NoDataFound";
import { DataGridMobileRow } from "./DataGridMobileRow";

const getPage = <T,>(
    usersToFilter: T[],
    pageNumber: number,
    pageSize: number,
) => {
    const firstPageIndex = pageNumber * pageSize;
    const lastPageIndex = (pageNumber + 1) * pageSize;

    return usersToFilter.slice(
        firstPageIndex,
        Math.min(usersToFilter.length, lastPageIndex),
    );
};

type DataGridMobileProps<T> = {
    data: T[];
    columns: MRT_ColumnDef<T>[];
    totalPages: number;
    pageNumber: number;
    pageSize: number;
    selectedRows?: { [s: string]: boolean };
    onRowSelect?: (arg: { [s: string]: boolean }) => void;
    onOpenFilter: () => void;
    onPaginationChange: (pagination: {
        pageIndex: number;
        pageSize: number;
    }) => void;
    getRowId?: (v: T, idx: number) => string;
    manualPagination?: boolean;
    getSubRows: (originalRow: T, index: number) => T[];
    renderRowActions?: (props: any) => React.ReactNode;
    enableExpanding?: boolean;
};
export const DataGridMobile = <T,>({
    data,
    columns = [],
    totalPages,
    pageNumber,
    pageSize,
    selectedRows,
    onRowSelect,
    onPaginationChange,
    onOpenFilter,
    getRowId,
    getSubRows,
    renderRowActions,
    manualPagination = false,
    enableExpanding,
}: DataGridMobileProps<T>) => {
    const { t } = useTranslation();

    const newData = manualPagination
        ? data
        : getPage([...data], pageNumber, pageSize);
    const dataForDisplay = getSubRows
        ? newData.map((item, index) => {
              const subRow = getSubRows(item, index);
              return { ...item, subRow };
          })
        : newData;
    return (
        <Container>
            {data?.length === 0 ? (
                <NoDataFound />
            ) : (
                dataForDisplay?.map((item, idx) => (
                    <DataGridMobileRow
                        key={getRowId?.(item, idx) || idx}
                        item={item}
                        index={idx}
                        columns={columns}
                        onRowSelect={onRowSelect}
                        selectedRows={selectedRows}
                        getRowId={getRowId}
                        renderRowActions={renderRowActions}
                        enableExpanding={enableExpanding}
                    />
                ))
            )}

            <Menu container wrap="nowrap" alignItems="center">
                {onOpenFilter && (
                    <FilterButton color="primary" onClick={onOpenFilter}>
                        <FilterIcon />
                        {t("admin-users:filterForm.label")}
                    </FilterButton>
                )}

                {totalPages > 1 && (
                    <Pagination container alignItems="center">
                        <Box flexShrink={0}>
                            <TablePagination
                                showFirstButton
                                showLastButton
                                component="div"
                                count={totalPages}
                                page={pageNumber}
                                onPageChange={(_, p) =>
                                    onPaginationChange({
                                        pageIndex: p,
                                        pageSize,
                                    })
                                }
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={({ target }) =>
                                    onPaginationChange({
                                        pageIndex: 0,
                                        pageSize: Number(target.value),
                                    })
                                }
                                labelRowsPerPage=""
                                rowsPerPageOptions={[]}
                            />
                        </Box>
                    </Pagination>
                )}
            </Menu>
        </Container>
    );
};

const Pagination = styled(Grid)(({ theme }) => ({
    minWidth: 0,
    justifyContent: "flex-end",
    backgroundColor: theme.palette.background.default,
    flexWrap: "nowrap",
    [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        borderTop: "1px solid #DBDBDB",
    },
}));

const Menu = styled(Grid)(({ theme }) => ({
    padding: "0 24px",
    position: "sticky",
    bottom: "0",
    backgroundColor: theme.palette.background.default,
    minHeight: "72px",
    [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        padding: 0,
    },
}));

const Container = styled(Box)`
    background-color: ${({ theme }) => theme.palette.background.default};
`;

const FilterButton = styled(Button)`
    position: sticky;
    bottom: 0;
    margin: 12px 0;
    width: 180px;
`;

const FilterIcon = styled(FilterAlt)`
    width: 18px;
    height: 18px;
    margin-right: 8px;
`;
