import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../types/redux/store";
import { FilesRequestType } from "../../types/redux/store/pages/FilesRequestPage";
import { resetFilesRequestPage } from "../../redux/pages/filesRequest/actions";
import { copyText } from "src/helpers/clipboard";

const useStyle = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "120px",
    },
    button: {
        width: "210px",
        marginTop: "40px",
        marginBottom: "40px",
    },
    description: {
        fontSize: "20px",
        fontWeight: 500,
    },
    emails: {
        fontSize: "20px",
        color: "#4A90E2",
        textDecoration: "underline",
    },
    link: {
        color: "#4A90E2",
    },
    additionalInformation: {
        fontSize: "16px",
        lineHeight: "19px",
        color: "#4C5A99",
        textAlign: "center",
        maxWidth: "446px",
    },
    copyButton: {
        marginTop: "20px",
        width: "210px",
    },
});

const createResponseLink = (link: string | undefined): string => {
    if (link === undefined) {
        return "";
    }
    const linkParts = link.split("/");
    return `/requestMessage/${linkParts[linkParts?.length - 1]}`;
};
const createLink = (pathname: string) => {
    return window.location.origin + pathname;
};

export const AfterRequestSending = () => {
    const styles = useStyle();
    const { t } = useTranslation();
    const response = useSelector(
        (store: Store) => store.pages.filesRequestPage.response,
    );
    const currentRequestType = useSelector(
        (store: Store) => store.pages.filesRequestPage.requestType,
    );
    const dispatch = useDispatch();

    const sendMore = () => {
        dispatch(resetFilesRequestPage());
    };

    const link = createResponseLink(response.link);

    return (
        <div className={styles.container}>
            {currentRequestType === FilesRequestType.BY_EMAIL && (
                <>
                    <div className={styles.description}>
                        {t("request-files:requestWasSent")}
                    </div>
                    <div className={styles.emails}>
                        {response.recipientEmails?.map(
                            (email: string, index) => (
                                <div key={index}>{email}</div>
                            ),
                        )}
                    </div>
                </>
            )}
            {currentRequestType === FilesRequestType.BY_LINK && (
                <>
                    <div className={styles.description}>
                        {t("request-files:linkWasCreated")}
                    </div>
                    <div>
                        <a href={link} className={styles.link}>
                            {createLink(link)}
                        </a>
                    </div>
                </>
            )}
            {currentRequestType === FilesRequestType.BY_LINK && (
                <div>
                    <Button
                        className={styles.copyButton}
                        color="secondary"
                        onClick={() => copyText(createLink(link))}
                    >
                        {t("send-file:afterMessageSending.copyLink")}
                    </Button>
                </div>
            )}
            <div>
                <Button
                    className={styles.button}
                    color="primary"
                    onClick={sendMore}
                >
                    {t("request-files:requestMore")}
                </Button>
            </div>
            <div className={styles.additionalInformation}>
                {t("request-files:additionalInformation")}
            </div>
        </div>
    );
};
