import { AdministrationStoragesPageType } from "src/types/redux/store/pages/administration/AdministrationStoragesPage";
import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    editStorage,
    deleteStorage,
    loadStorages,
    selectEditStorage,
    disposeError,
    disposeEditingStorage,
    createStorage,
    getNewStorageSettings,
    reorderStorages,
} from "./actions";
import {
    LocalFileStorageCreateParametersDTO,
    LocalFileStorageDTO,
} from "src/services/generated";
import { MetaAction } from "src/types/redux/PayloadAction";

const storagesInitialState: AdministrationStoragesPageType = {
    storages: [],
    lastSavedOrder: [],
    editingStorage: undefined,
    pending: false,
    loadingStorages: false,
    reordering: false,
};

export const administrationStoragesPageReducer =
    createReducer<AdministrationStoragesPageType>(storagesInitialState, {
        [disposeError.type](state, action) {
            state.pending = false;
        },
        [disposeEditingStorage.type](state, action) {
            state.editingStorage = undefined;
        },
        [loadStorages.pending.type](state) {
            state.loadingStorages = true;
        },
        [loadStorages.fulfilled.type](
            state,
            action: PayloadAction<LocalFileStorageDTO[]>,
        ) {
            state.loadingStorages = false;
            if (!Array.isArray(action.payload)) {
                return;
            }
            state.storages = action.payload;
        },
        [loadStorages.rejected.type](state) {
            state.loadingStorages = false;
        },
        [createStorage.pending.type](
            state,
            action: PayloadAction<LocalFileStorageDTO>,
        ) {
            state.pending = true;
        },
        [createStorage.fulfilled.type](
            state,
            action: PayloadAction<LocalFileStorageDTO>,
        ) {
            state.pending = false;
            state.storages.unshift(action.payload);
        },
        [createStorage.rejected.type](state, action) {
            state.pending = false;
        },
        [editStorage.pending.type](
            state,
            action: PayloadAction<LocalFileStorageDTO>,
        ) {
            state.pending = true;
        },
        [editStorage.fulfilled.type](
            state,
            action: PayloadAction<LocalFileStorageDTO>,
        ) {
            state.pending = false;
            state.storages = state.storages.map(storage => {
                if (storage.id === action.payload.id) {
                    return action.payload;
                }
                return storage;
            });
        },
        [editStorage.rejected.type](state, action) {
            state.pending = false;
        },
        [deleteStorage.fulfilled.type](state, action: PayloadAction<number>) {
            state.pending = false;

            const index = state.storages.findIndex(
                storageId => storageId.id === action.payload,
            );
            state.storages.splice(index, 1);
        },
        [deleteStorage.pending.type](state, action: PayloadAction<number>) {
            state.pending = true;
        },
        [deleteStorage.rejected.type](state, action: PayloadAction<number>) {
            state.pending = false;
        },
        [selectEditStorage.type](
            state,
            action: PayloadAction<LocalFileStorageDTO>,
        ) {
            state.loadingStorages = false;
            state.editingStorage = action.payload;
        },
        [getNewStorageSettings.fulfilled.type](
            state,
            action: PayloadAction<LocalFileStorageCreateParametersDTO>,
        ) {
            state.newStorageSettings = action.payload;
        },
        [reorderStorages.pending.type](
            state,
            action: MetaAction<LocalFileStorageDTO[]>,
        ) {
            state.lastSavedOrder = [...state.storages];
            state.storages = action.meta.arg;
            state.reordering = true;
        },
        [reorderStorages.fulfilled.type](state) {
            state.reordering = false;
        },
        [reorderStorages.rejected.type](state) {
            state.reordering = false;
            state.storages = [...state.lastSavedOrder];
        },
    });
