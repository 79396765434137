import { ComponentsOverrides } from "@mui/material/styles/overrides";
import styleVariables from "../variables";

export const MuiAppBar: ComponentsOverrides["MuiAppBar"] = {
    root: {
        height: 72,
        backgroundColor: styleVariables.color.secondary.light,
        flexDirection: "row",
    },
};
