import { createAction } from "@reduxjs/toolkit";

export const openDialog = createAction(
    "openDialog",
    function prepare(name: string) {
        return {
            payload: {
                dialogName: name,
                isOpen: true,
            },
        };
    },
);

export const closeDialog = createAction(
    "closeDialog",
    function prepare(name: string) {
        return {
            payload: {
                dialogName: name,
                isOpen: false,
            },
        };
    },
);

export const addToDialogChain = createAction<string>("addToDialogChain");

export const deleteDialogFromChain = createAction("deleteDialogFromChain");
