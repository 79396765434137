import { SimpleCronPeriodType } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";

export interface LightScheduleFormat {
    periodType?: SimpleCronPeriodType;
    time?: string;
}

const defaultDayOfMonth = "1";
const defaultDayOfWeek = "0";
const defaultMonth = "1";

export const fromCronToLight = (
    cronSchedule: string,
): LightScheduleFormat | null => {
    const error = () => {
        throw new Error();
    };

    if (!cronSchedule) {
        return {};
    }

    try {
        const symbols = cronSchedule.split(" ");
        if (symbols.length > 7) {
            error();
        }

        if (symbols.length === 6) {
            symbols.shift();
        }

        if (isNaN(+symbols[0]) || isNaN(+symbols[0])) {
            error();
        }

        const minutes = +symbols[0];
        const hours = +symbols[1];
        const dayOfMonth = symbols[2];
        const month = symbols[3];
        const dayOfWeek = symbols[4];
        const time = `${hours > 9 ? hours : "0" + hours}:${
            minutes > 9 ? minutes : "0" + minutes
        }`;

        if (minutes < 0 || minutes > 59 || hours < 0 || hours > 23) {
            error();
        }

        if (dayOfWeek === "?" && dayOfMonth === "?") {
            error();
        }

        if (
            month !== "*" &&
            (month.match(/\d{1,2}/)?.[0] === month ||
                month.match(/\[A-Z]{3}/)?.[0] === month)
        ) {
            return { time, periodType: SimpleCronPeriodType.YEAR };
        } else if (month !== "*") {
            error();
        }

        if (
            dayOfWeek !== "*" &&
            dayOfWeek !== "?" &&
            (dayOfWeek.match(/\d/)?.[0] === dayOfWeek ||
                dayOfWeek.match(/\[A-Z]{3}/)?.[0] === dayOfWeek)
        ) {
            if (dayOfMonth !== "*" && dayOfMonth !== "?") {
                error();
            }
            return { time, periodType: SimpleCronPeriodType.WEEk };
        } else if (dayOfWeek !== "*" && dayOfWeek !== "?") {
            error();
        }

        if (
            dayOfMonth !== "*" &&
            dayOfMonth !== "?" &&
            dayOfMonth.match(/\d{1,2}/)?.[0] === dayOfMonth
        ) {
            return { time, periodType: SimpleCronPeriodType.MONTH };
        } else if (dayOfMonth !== "*" && dayOfMonth !== "?") {
            error();
        }

        return { time, periodType: SimpleCronPeriodType.DAY };
    } catch {
        return null;
    }
};

export const fromLightToCron = (lightSchedule: LightScheduleFormat) => {
    const matches = lightSchedule.time?.match(/(\d\d):(\d\d)/);
    let hours: string | number = matches?.[1];
    let minutes: string | number = matches?.[2];
    let dayOfMonth = "*";
    let month = "*";
    let dayOfWeek = "*";

    if (!lightSchedule.time && !lightSchedule.periodType) {
        return "";
    }

    if (isNaN(+hours)) {
        hours = 0;
    } else {
        hours = +hours;
    }

    if (isNaN(+hours)) {
        minutes = 0;
    } else {
        minutes = +minutes;
    }

    switch (lightSchedule.periodType) {
        case SimpleCronPeriodType.DAY: {
            break;
        }
        case SimpleCronPeriodType.WEEk: {
            dayOfWeek = defaultDayOfWeek;
            break;
        }
        case SimpleCronPeriodType.MONTH: {
            dayOfMonth = defaultDayOfMonth;
            break;
        }
        case SimpleCronPeriodType.YEAR: {
            dayOfMonth = defaultDayOfMonth;
            month = defaultMonth;
            break;
        }
    }

    return `0 ${minutes || 0} ${
        hours || 0
    } ${dayOfMonth} ${month} ${dayOfWeek}`;
};
