import React from "react";
import { Menu, MenuItem } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { logOut } from "src/redux/pages/login/action";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { loginApi, loginUrl } from "src/services/clientApi";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        menuDropdownImage: {
            marginLeft: 18,
        },
        userName: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 14,
            textAlign: "right",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        userMenuBlock: {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
        },
        expandMenu: {
            width: "25px",
            height: "25px",
            paddingLeft: "16px",
        },
    }),
);

export function UserMenu() {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const [isOpenMenu, setOpenMenu] = React.useState(false);
    const styles = useStyle();
    const dispatch = useDispatch();
    const { user } = useSelector(
        (store: Store) => store.authenticationHandler.authentication,
    );
    const { t } = useTranslation();

    const getUsetName = () => {
        if ((user && user.firstName) || (user && user.lastName)) {
            return `${user.firstName} ${user.lastName}`;
        }
        if (user && user.email) {
            return user.email;
        }
        return t("common:guest");
    };

    const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(!isOpenMenu);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();
    const handleLogout = () => {
        if (user?.email) {
            loginApi.logout();
        }
        setAnchorEl(null);
        dispatch(logOut());
        history.replace(loginUrl);
    };

    return (
        <>
            <div onClick={handleClick} className={styles.userMenuBlock}>
                <span className={styles.userName}>{getUsetName()}</span>
                {!anchorEl ? (
                    <ExpandMore className={styles.expandMenu} />
                ) : (
                    <ExpandLess className={styles.expandMenu} />
                )}
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleLogout}>{t("common:exit")}</MenuItem>
            </Menu>
        </>
    );
}
