import React from "react";
import { TFunction } from "i18next";
import { Tooltip, Box } from "@mui/material";

import { DatagridColumn } from "src/components/DataGrid";
import { Upload, UploadedFile } from "src/types/Upload";
import { prettyBytes } from "src/helpers/prettyBytes";
import { getSumSizeFiles } from "src/helpers/file";
import { commonDateTimeFormat } from "src/constants/momentFormats";
import dayjs from "dayjs";

export const getColumns: (
    t: TFunction,
) => DatagridColumn<Upload & UploadedFile>[] = t => [
    {
        accessorKey: "name",
        header: t("my-file:headers.file"),
        AggregatedCell: ({ row }) => {
            return (
                <div>{`${row.original.files.length} ${t(
                    "my-file:headers.filesCount",
                )}`}</div>
            );
        },
        Cell: ({ cell, row }) => {
            const name =
                (cell.getValue() as string) || row.original.files?.[0]?.name;
            return <Box title={name}>{name}</Box>;
        },
    },
    {
        header: t("my-file:headers.size"),
        accessorKey: "size",
        AggregatedCell: ({ row }) => {
            return prettyBytes(getSumSizeFiles(row.original.files), {
                hideSize: true,
                sizeToRound: "MB",
            });
        },
        Cell: ({ cell, row }) => {
            const size =
                (cell.getValue() as number) || row.original.files?.[0]?.size;
            return prettyBytes(size, {
                hideSize: true,
                sizeToRound: "MB",
            });
        },
        size: 100,
        grow: false,
    },
    {
        header: t("my-file:headers.limit"),
        accessorKey: "downloadCount",
        AggregatedCell: () => null,
        Cell: ({ row }) => {
            const file = row.original.files?.[0] || row.original;
            return file.downloadCount !== undefined
                ? `${file.downloadCount}/${file.downloadLimit}`
                : null;
        },
        size: 108,
    },
    {
        header: t("my-file:headers.dispatchDate"),
        accessorKey: "dispatchDate",
        Cell: ({ cell, row }) => {
            const date =
                (cell.getValue() as string) || row.original.dispatchDate;
            const value = date
                ? dayjs(date).format(commonDateTimeFormat())
                : null;
            return <Box title={value}>{value}</Box>;
        },
        size: 132,
    },
    {
        header: t("my-file:headers.activeBefore"),
        accessorKey: "activeBefore",
        Cell: ({ cell, row }) => {
            const date =
                (cell.getValue() as string) || row.original.activeBefore;
            const value = date
                ? dayjs(date).format(commonDateTimeFormat())
                : null;
            return <Box title={value}>{value}</Box>;
        },
        size: 132,
    },
    {
        header: t("my-file:headers.recipients"),
        accessorKey: "recipients",
        Cell: ({ cell }) => {
            const recipients = cell.getValue() as string[];
            if (!recipients) {
                return null;
            }
            return recipients.length > 1 ? (
                <Tooltip
                    placement="top-start"
                    arrow
                    title={recipients.map((value, index) => (
                        <Box key={index}>{value}</Box>
                    ))}
                >
                    <Box>
                        {`${recipients.length} ${t(
                            "my-file:headers.recipientsCount",
                        )}`}
                    </Box>
                </Tooltip>
            ) : (
                <Box title={recipients[0]}>{recipients[0]}</Box>
            );
        },
        size: 132,
    },
];
