import React, { FC, useCallback } from "react";
import { CircularProgress, Tooltip, Box } from "@mui/material";
import { ClearOutlined, InsertDriveFile } from "@mui/icons-material";
import { makeStyles, withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import VirusCheckStatusIcon from "./VirusCheckStatusIcon";
import { prettyBytes } from "src/helpers/prettyBytes";
import CircularProgressWithLabel from "../CircularProgressWithLabel";
import {
    FileDTO,
    FileError,
    FileStatus,
    ResponseFileDTO,
} from "src/services/generated/";
import { isFiledFile } from "src/helpers/file";
import { FileViewModel } from "src/types/FileDisplay";
import { ButtonBase } from "@mui/material";
import { styled } from "@mui/material";
import { IconButton } from "@mui/material";
import classNames from "classnames";

const useStyle = makeStyles({
    listItemBorder: {
        border: "1px solid transparent",
    },
    redBorder: {
        border: "1px solid rgba(255, 87, 34, 0.7)",
    },
    yellowBorder: {
        border: "1px solid rgba(255, 204, 0, 0.7)",
    },
    fileSizeError: {
        "& p": {
            color: "rgba(255, 87, 34, 0.9)",
        },
    },
    viewFontSize: {
        fontSize: "12px",
    },
    customWidth: {
        maxWidth: "none",
    },
    downloadsCount: {
        textAlign: "center",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        minWidth: "76px",
    },
    downloadsCountNumbers: {
        fontSize: "14px",
        lineHeight: "16px",
        color: "#1A1F29",
    },
    downloadsCountLabel: {
        fontSize: "12px",
        lineHeight: "14px",
        letterSpacing: "0.4px",
        color: "rgba(26, 31, 41, 0.6)",
    },
    wrapper: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        minWidth: 0,
        marginTop: "8px",
        "&:first-child": {
            marginTop: 0,
        },
    },
    info: {
        flexDirection: "column",
        minWidth: 0,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "8px",
        flexGrow: 1,
        fontSize: "12px",
        fontFamily: "roboto",
        textAlign: "left",
        lineHeight: 1,
    },
    info__name: {
        width: "100%",
        fontWeight: 500,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    info__size: {
        opacity: "0.7",
    },
    iconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    fileWrapper: {
        borderRadius: "4px",
        minHeight: "48px",
        minWidth: 0,
        backgroundColor: "#FFFFFF",
        padding: "6px 16px",
        display: "flex",
        gap: "8px",
        flexGrow: 1,
        justifyContent: "flex-start",
        alignItems: "center",
    },
});

const StyledInsertDriveFile = withStyles({
    root: {
        color: "#548ED7",
        opacity: "0.7",
        width: "24px",
        height: "24px",
    },
})(InsertDriveFile);

const StyledClearOutlined = withStyles({
    root: {
        cursor: "pointer",
        opacity: "0.7",
        color: "#000000",
    },
})(ClearOutlined);

const ButtonWrapper = styled(ButtonBase)(() => ({
    border: "1px solid #CCC",
    "&[disabled]": {
        opacity: 0.6,
    },
    "&:focus": {
        border: "solid 1px black",
    },
}));

const DivWrapper = styled(Box)(() => ({
    boxShadow:
        "0px 1px 5px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.04)",
    border: "1px solid transparent",
}));

const StyledIconButton = styled(IconButton)(() => ({
    padding: 0,
    width: "24px",
    height: "24px",
    marginLeft: "8px",
}));

const renderStatusIcon = (status?: FileStatus, value?: number): any => {
    switch (status) {
        case FileStatus.ERROR: {
            return <VirusCheckStatusIcon color="#FF5722" status="ERROR" />;
        }
        case FileStatus.READY: {
            return <VirusCheckStatusIcon color="#4CAF50" status="SUCCESS" />;
        }
        default: {
            return value && value < 100 ? (
                <CircularProgressWithLabel
                    value={value}
                    color="primary"
                    size={24}
                />
            ) : (
                <CircularProgress color="primary" size={24} />
            );
        }
    }
};

interface GetTooltipTitleArguments {
    fileViewModel: FileViewModel;
    inProgress?: boolean;
    isView?: boolean;
    isLimitReached?: boolean;
}

const getTooltipTitle = ({
    fileViewModel,
    inProgress,
    isLimitReached,
    isView,
}: GetTooltipTitleArguments): string => {
    switch (fileViewModel.error) {
        case FileError.DUPLICATED: {
            return "send-file:fileDisplay.fileDuplicationError";
        }
        case FileError.EXCEED_UPLOAD_LIMIT: {
            return "send-file:fileDisplay.exceedLimit";
        }
        case FileError.INFECTED: {
            return "send-file:fileDisplay.virusCheckError";
        }
        case FileError.NOT_ENOUGH_SPACE: {
            return "send-file:fileDisplay.spaceError";
        }
        default: {
            if (fileViewModel.status === FileStatus.PROCESSING) {
                return "send-file:fileDisplay.antivirusCheck";
            }
            if (isView && inProgress) {
                return "download-files:statusChecking";
            }
            if (isView && isLimitReached) {
                return "download-files:limitReached";
            }
            return "";
        }
    }
};

type FileDisplayProps = {
    file: FileDTO | ResponseFileDTO | FileViewModel;
    isView?: boolean;
    isExpiredTime?: boolean;
    downloadLimit?: number;
    onDeleteFile?: (file: FileViewModel) => void;
    onClick?: () => void;
};

export const FileDisplay: FC<FileDisplayProps> = props => {
    const {
        file,
        isView = true,
        onDeleteFile,
        isExpiredTime,
        onClick,
        downloadLimit,
    } = props;

    const fileViewModel = file as FileViewModel;

    const { t } = useTranslation();
    const styles = useStyle();

    const inProgress =
        fileViewModel.status === FileStatus.UPLOADING ||
        fileViewModel.status === FileStatus.PROCESSING;
    const isVirus = isFiledFile(fileViewModel);
    const isLimitReached =
        Number.isInteger(downloadLimit!) &&
        Number.isInteger(fileViewModel.downloadCount!) &&
        fileViewModel.downloadCount! >= downloadLimit!;
    const tooltipTitle = t(
        getTooltipTitle({ fileViewModel, inProgress, isView, isLimitReached }),
    );

    return (
        <Tooltip
            disableInteractive
            title={tooltipTitle}
            followCursor
            placement="bottom-end"
            classes={{ tooltip: styles.customWidth }}
        >
            <Box className={styles.wrapper}>
                {isView ? (
                    <ButtonWrapper
                        disabled={
                            isView &&
                            (isExpiredTime ||
                                inProgress ||
                                isLimitReached ||
                                isVirus)
                        }
                        className={classNames(styles.fileWrapper, {
                            [styles.redBorder]:
                                fileViewModel.status === FileStatus.ERROR,
                        })}
                        onClick={() =>
                            !inProgress &&
                            !isLimitReached &&
                            !isVirus &&
                            onClick?.()
                        }
                    >
                        <File isView={isView} fileViewModel={fileViewModel} />
                    </ButtonWrapper>
                ) : (
                    <DivWrapper
                        className={classNames(styles.fileWrapper, {
                            [styles.redBorder]:
                                fileViewModel.status === FileStatus.ERROR,
                        })}
                    >
                        <File
                            isView={isView}
                            fileViewModel={fileViewModel}
                            onDeleteFile={onDeleteFile}
                        />
                    </DivWrapper>
                )}

                {downloadLimit && (
                    <div className={styles.downloadsCount}>
                        <div className={styles.downloadsCountNumbers}>
                            {fileViewModel.downloadCount} / {downloadLimit}
                        </div>
                        <div className={styles.downloadsCountLabel}>
                            {t("download-files:downloads")}
                        </div>
                    </div>
                )}
            </Box>
        </Tooltip>
    );
};

type FileProps = {
    fileViewModel: FileViewModel;
    isView?: boolean;
    onDeleteFile?: (file: FileViewModel) => void;
};

const File = ({ isView, fileViewModel, onDeleteFile }: FileProps) => {
    const styles = useStyle();

    const handleDeleteFile = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            onDeleteFile?.(fileViewModel);
        },
        [onDeleteFile, fileViewModel],
    );

    return (
        <>
            <Box>
                <StyledInsertDriveFile />
            </Box>
            <Box className={styles.info}>
                <Box className={styles.info__name}>{fileViewModel.name}</Box>
                <Box className={styles.info__size}>
                    {fileViewModel.size && prettyBytes(fileViewModel.size)}{" "}
                </Box>
            </Box>
            <Box className={styles.iconWrapper}>
                {renderStatusIcon(
                    fileViewModel.status as FileStatus,
                    fileViewModel.progress,
                )}
                {!isView && (
                    <StyledIconButton size="small" onClick={handleDeleteFile}>
                        <StyledClearOutlined />
                    </StyledIconButton>
                )}
            </Box>
        </>
    );
};

export default FileDisplay;
