import React, { useEffect } from "react";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "src/components/Form";
import { ReactComponent as CheckboxIconUnchecked } from "./../../icons/checkboxUnchecked.svg";
import { ReactComponent as CheckboxIconChecked } from "./../../icons/checkboxChecked.svg";
import { Store } from "src/types/redux/store";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import styleVariables from "../../theme/variables";
import { AdminFilesFilter } from "../../types/redux/store/pages/administration/AdministrationFilesPage";
import {
    loadEmails,
    setAdminFilesSearchFilter,
} from "../../redux/pages/administrationFiles/actions";
import { defaultAdminFilesFilter } from "../../redux/pages/administrationFiles/reducer";
import { Search } from "@mui/icons-material";
import Clear from "@mui/icons-material/Clear";

const useStyles = makeStyles(() =>
    createStyles({
        flexContainer: {
            textAlign: "left",
            height: "100%",
            width: "100%",
            padding: 16,
            display: "flex",
            flexDirection: "column",
        },
        form: {
            paddingTop: styleVariables.spacing * 7,
            flexGrow: 1,
            boxSizing: "border-box",
            paddingBottom: "20px",
        },
        filterLabel: {
            fontWeight: 500,
        },
        autocompleteOptionText: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }),
);

export const FilesFilter = () => {
    const styles = useStyles();
    const { t } = useTranslation();

    const { emails } = useSelector(
        (store: Store) => store.pages.administration.administrationFilesPage,
    );

    const { hideInactive, searchText } = useSelector(
        (store: Store) =>
            store.pages.administration.administrationFilesPage.filter,
    );
    const dispatch = useDispatch();
    const { getValues, control, reset, register } = useForm<AdminFilesFilter>({
        defaultValues: { hideInactive, searchText },
        shouldUnregister: false,
    });

    const updateFilters = () => {
        dispatch(setAdminFilesSearchFilter(getValues()));
    };

    const clearFilters = () => {
        reset(defaultAdminFilesFilter);
        dispatch(setAdminFilesSearchFilter(defaultAdminFilesFilter));
    };

    useEffect(() => {
        dispatch(loadEmails());
    }, [dispatch]);

    useEffect(() => {
        return () => clearFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isEqualValues =
        Object.keys(getValues()).length === 0 ||
        _.isEqual(defaultAdminFilesFilter, getValues());

    return (
        <Paper className={styles.flexContainer} elevation={1}>
            <span className={styles.filterLabel}>
                {t("admin-users:filterForm.label")}
            </span>
            <Box className={styles.form}>
                <form
                    name="filter"
                    onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <Controller
                        name="searchText"
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                options={emails}
                                freeSolo
                                value={value}
                                onBlur={e => {
                                    // @ts-ignore
                                    if (!e.target?.value) return;
                                    // @ts-ignore
                                    onChange(e.target.value.trim());
                                    updateFilters();
                                }}
                                onChange={(event, value, reason) => {
                                    if (reason === "clear") {
                                        value =
                                            defaultAdminFilesFilter.searchText;
                                    }
                                    onChange(value);
                                    updateFilters();
                                }}
                                clearIcon={<Clear />}
                                renderOption={(props, option: string) => (
                                    <li {...props}>
                                        <span
                                            className={
                                                styles.autocompleteOptionText
                                            }
                                        >
                                            {option}
                                        </span>
                                    </li>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        name="searchText"
                                        placeholder={t(
                                            "admin-files:filter.search",
                                        )}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{ marginBottom: "4px" }}
                                                >
                                                    <IconButton
                                                        sx={{ padding: 0 }}
                                                    >
                                                        <Search />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}
                        defaultValue=""
                        control={control}
                    />
                    <FormControlLabel
                        name="hideInactive"
                        control={
                            <Checkbox
                                icon={<CheckboxIconUnchecked />}
                                checkedIcon={<CheckboxIconChecked />}
                                checked={hideInactive}
                                {...register("hideInactive", {
                                    onChange: updateFilters,
                                })}
                            />
                        }
                        label={t("admin-files:filter.hideInactive")}
                    />
                </form>
            </Box>
            <Button
                disabled={isEqualValues}
                color="secondary"
                fullWidth={true}
                onClick={clearFilters}
            >
                {t("admin-files:filter.clear")}
            </Button>
        </Paper>
    );
};
