import { ComponentsProps } from "@mui/material/styles/props";

interface LabComponentsProps {
    MuiToggleButton: ComponentsProps["MuiButton"];
}

type DefaultProps = ComponentsProps & LabComponentsProps;

export const defaultProps: DefaultProps = {
    MuiTab: {
        disableRipple: true,
    },
    MuiTabs: {
        indicatorColor: "primary",
        textColor: "inherit",
    },
    MuiButton: {
        color: "secondary",
        variant: "contained",
        disableRipple: true,
    },
    MuiToggleButton: {
        disableRipple: true,
    },
    MuiCheckbox: {
        color: "primary",
    },
    MuiFormControl: {
        variant: "standard",
    },
    MuiLink: {
        underline: "hover",
    },
    MuiMenu: {
        anchorOrigin: {
            vertical: "top",
            horizontal: "left",
        },
    },
    MuiSelect: {
        variant: "standard",
    },
    MuiSwitch: {
        color: "secondary",
    },
    MuiTextField: {
        variant: "standard",
    },
    MuiIconButton: {
        size: "large",
    },
};

export default defaultProps;
