import {
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    Link,
    LinkProps,
    Redirect,
    Route,
    useLocation,
} from "react-router-dom";
import { routePaths } from "../routes/routePaths";
import Box from "@mui/material/Box";
import React, { useEffect, useMemo } from "react";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { IAdministrationStatisticsPage } from "src/types/redux/store/pages/administration/AdministrationStatisticsPage";
import moment from "moment";
import { ReactComponent as AlertImg } from "../icons/alert.svg";
import { REDAlert, YELLOWAlert } from "src/components/LicenseAlert";

export interface Page {
    name: string;
    component: React.FunctionComponent<any>;
    url: string;
    accordion?: {
        name: string;
        component: React.FunctionComponent<any>;
        route: string;
        license?: boolean;
        accordion?: {
            name: string;
            component: React.FunctionComponent<any>;
            route: string;
        }[];
    }[];
}

type ManagePageTemplateProps = {
    pages: Page[];
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            padding: "20px 16px",
            height: "100%",
            boxSizing: "border-box",
            [theme.breakpoints.down("xs")]: {
                padding: 0,
            },
        },
        height100: {
            height: "100%",
        },
        navMenu: {
            overflowY: "auto",
            maxHeight: "100%",
        },
        menuWrapper: {
            maxHeight: "100%",
            width: "196px",
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        content: {
            height: "100%",
            minWidth: 0,
            width: "100%",
            marginLeft: "16px",
            [theme.breakpoints.down("lg")]: {
                marginLeft: 0,
                width: "100%",
            },
        },
    }),
);
const useListItemLinkStyles = makeStyles({
    selected: {
        backgroundColor: "#EFEFF4",
        borderLeft: "4px solid #6577CB",
    },
    default: {},
});

const useAccordionStyles = makeStyles((theme: Theme) => ({
    space: {
        marginLeft: theme.spacing(4),
    },
    space2: {
        paddingLeft: theme.spacing(8),
    },
    alertIcon: {
        justifyContent: "flex-end",
    },
    fullWidth: {
        display: "flex",
        flex: "1",
    },
}));

export const ManagePageTemplate = (props: ManagePageTemplateProps) => {
    const { pages } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { stats } = useSelector<Store, IAdministrationStatisticsPage>(
        store => store.pages.administration.administrationStatisticsPage,
    );
    const [expanded, setExpanded] = React.useState(false);
    const [selected, setSelected] = React.useState<string>(pathname);

    const licenseTermDays = useMemo(() => {
        const licenseTerm = moment(new Date(stats?.licenseValidBefore));
        return licenseTerm.diff(moment(new Date()), "days") + 1;
    }, [stats?.licenseValidBefore]);

    useEffect(() => {
        setSelected(pathname);
    }, [pathname]);

    const handleChange = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        expandedState?: boolean,
    ) => {
        if (typeof expandedState === "undefined") {
            setExpanded(!expanded);
        } else {
            setExpanded(!!expandedState);
        }
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.menuWrapper}>
                <Paper elevation={1} className={classes.navMenu}>
                    <List component="nav">
                        {pages.map(page => {
                            const items = page.accordion?.map(accordion => ({
                                name: accordion.name,
                                route: accordion.route,
                                license: accordion?.license,
                                accordion: accordion?.accordion,
                            }));
                            return page.accordion ? (
                                <AccordionWrapper
                                    key={page.name}
                                    expanded={expanded}
                                    selected={selected}
                                    onChange={handleChange}
                                    name={page.name}
                                    onItemClick={(e, route) => {
                                        setSelected(route);
                                    }}
                                    items={items!}
                                    licenseTermDays={licenseTermDays}
                                />
                            ) : (
                                <ListItemLink
                                    tooltip={false}
                                    key={page.name}
                                    to={page.url}
                                    name={t(page.name)}
                                    primary={t(page.name)}
                                    selected={selected === page.url}
                                    onClick={e => {
                                        handleChange(e);
                                        setSelected(page.url);
                                    }}
                                />
                            );
                        })}
                    </List>
                </Paper>
            </Box>
            <Box className={classes.content}>
                <Route path={routePaths.administration} exact={true}>
                    <Redirect
                        to={routePaths.administrationTemplate.statistics}
                    />
                </Route>

                {pages.map((tabs: Page) => {
                    return tabs.accordion ? (
                        tabs.accordion.map(
                            (tab: {
                                name: string;
                                component: React.FunctionComponent<any>;
                                route: string;
                                license?: boolean;
                                accordion?: {
                                    name: string;
                                    component: React.FunctionComponent<any>;
                                    route: string;
                                }[];
                            }) => {
                                return tab.accordion ? (
                                    Object.entries(tab.accordion).map(
                                        ([key, obj]) => {
                                            return (
                                                <Route
                                                    key={key}
                                                    path={obj.route}
                                                    component={obj.component}
                                                    exact
                                                />
                                            );
                                        },
                                    )
                                ) : (
                                    <Route
                                        key={tab.route}
                                        path={tab.route}
                                        component={tab.component}
                                        exact
                                    />
                                );
                            },
                        )
                    ) : (
                        <Route
                            key={tabs.url}
                            path={tabs.url}
                            component={tabs.component}
                            exact
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

type AccordionWrapperProps = {
    name: string;
    items: {
        name: string;
        route: string;
        license?: boolean;
        accordion: {
            name: string;
            component: React.FunctionComponent<any>;
            route: string;
        }[];
    }[];
    expanded: boolean;
    selected?: string;
    onChange?: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        expandState: boolean,
    ) => void;
    onItemClick?: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        itemRoute: string,
    ) => void;
    licenseTermDays?: number;
    className?: string;
};
const AccordionWrapper = (props: AccordionWrapperProps) => {
    const {
        name,
        items,
        expanded,
        onChange,
        selected,
        onItemClick,
        licenseTermDays,
        className,
    } = props;
    const [open, setOpen] = React.useState(expanded);
    const { t } = useTranslation();
    const classes = useAccordionStyles();

    const handleOpen = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        setOpen(!open);
        if (onChange) {
            onChange(event, !open);
        }
    };

    const handleItemClick =
        (route: string) =>
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (onItemClick) onItemClick(event, route);
        };
    return (
        <>
            <ListItemButton onClick={handleOpen} className={className}>
                <Typography className={classes.fullWidth} variant="subtitle1">
                    {t(name).toUpperCase()}
                </Typography>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open}>
                <List>
                    {items.map(page => {
                        const innerItems = page.accordion?.map(accordion => ({
                            name: accordion.name,
                            route: accordion.route,
                            // @ts-ignore
                            accordion: accordion?.accordion,
                        }));
                        return page.accordion ? (
                            <AccordionWrapper
                                className={classes.space2}
                                key={page.name}
                                expanded={expanded}
                                selected={selected}
                                onChange={onChange}
                                name={page.name}
                                onItemClick={onItemClick}
                                items={innerItems!}
                                licenseTermDays={licenseTermDays}
                            />
                        ) : (
                            <ListItemLink
                                className={className || classes.space}
                                classNameIcon={classes.alertIcon}
                                key={page.name}
                                tooltip={true}
                                name={t(page.name)}
                                onClick={handleItemClick(page.route)}
                                selected={page.route === selected}
                                to={page.route}
                                license={page.license}
                                primary={t(page.name)}
                                licenseTermDays={licenseTermDays}
                            />
                        );
                    })}
                </List>
            </Collapse>
        </>
    );
};

const HtmlTooltip = withStyles({
    tooltipPlacementTop: {
        margin: 0,
    },
})(Tooltip);

interface ListItemLinkProps {
    primary: string;
    to: string;
    name: string;
    tooltip?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    selected?: boolean;
    className?: string;
    classNameIcon?: string;
    licenseTermDays?: number;
    license?: boolean;
}

function ListItemLink(props: ListItemLinkProps) {
    const {
        primary,
        to,
        name,
        onClick,
        selected,
        className,
        tooltip,
        licenseTermDays,
        classNameIcon,
        license,
    } = props;
    const classes = useListItemLinkStyles();
    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
                <Link to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );
    const listItem = (
        <ListItemButton
            component={renderLink}
            onClick={onClick}
            className={selected ? classes.selected : classes.default}
        >
            <ListItemText
                className={className}
                primary={primary.toUpperCase()}
            />
            {license && (
                <ListItemIcon className={classNameIcon}>
                    {licenseTermDays < 1 ? (
                        <AlertImg fill={REDAlert} />
                    ) : licenseTermDays < 31 ? (
                        <AlertImg fill={YELLOWAlert} />
                    ) : null}
                </ListItemIcon>
            )}
        </ListItemButton>
    );

    return tooltip ? (
        <HtmlTooltip followCursor title={name} placement="bottom-start">
            {listItem}
        </HtmlTooltip>
    ) : (
        listItem
    );
}
