import React, { useState } from "react";
import {
    Box,
    ButtonBase,
    CircularProgress,
    Menu,
    MenuItem,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { FileDisplay } from "../components/FileDisplay/FileDisplay";
import { useSelector, useDispatch } from "react-redux";
import { Store } from "src/types/redux/store/index";
import { FileViewModel } from "src/types/FileDisplay";
import { abort } from "src/redux/downloadMenu/actions";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "8px",
        },
        title: {
            fontFamily: "Roboto",
            fontSize: "14px",
            [theme.breakpoints.between("lg", "xl")]: {
                display: "none",
            },
            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },
        spinner: {
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        menu: {
            width: "302px",
        },
        menuItem: {
            padding: "4px 8px",
        },
    }),
);

export const DownloadMenu = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const files = useSelector(
        (store: Store) => store.downloadMenuInfo.loadedFiles,
    );
    const styles = useStyle();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onOpen = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };
    const onClose = () => {
        setAnchorEl(null);
    };

    const onDeleteFile = (file: FileViewModel) => {
        dispatch(abort(file.id));
    };

    if (files.length === 0) {
        return null;
    }

    return (
        <>
            <ButtonBase onClick={onOpen} className={styles.container}>
                <Box className={styles.title}>
                    {t("download-files:numOfUploadedFiles", {
                        count: files.length,
                    })}
                </Box>
                <Box className={styles.spinner}>
                    <CircularProgress size={18} />
                </Box>
            </ButtonBase>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    className: styles.menu,
                }}
            >
                {files.map(file => (
                    <MenuItem
                        disableRipple
                        className={styles.menuItem}
                        key={file.id}
                    >
                        <FileDisplay
                            onDeleteFile={onDeleteFile}
                            isView={false}
                            file={file}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
