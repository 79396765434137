import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { UserExpireSettingsDTO } from "src/services/generated";
import { UserDeactivationSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getUserExpireSettings, updateUserExpireSettings } from "./actions";

const userDeactivationInitialState: UserDeactivationSettingsPage = {
    form: {
        userExpireEnabled: false,
    },
    loading: false,
};

export const userDeactivationSettingsReducer =
    createReducer<UserDeactivationSettingsPage>(userDeactivationInitialState, {
        [getUserExpireSettings.pending.type](state) {
            state.loading = true;
        },
        [getUserExpireSettings.fulfilled.type](
            state,
            action: PayloadAction<UserExpireSettingsDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [getUserExpireSettings.rejected.type](state) {
            state.loading = false;
        },
        [updateUserExpireSettings.pending.type](state) {
            state.loading = true;
        },
        [updateUserExpireSettings.fulfilled.type](
            state,
            action: PayloadAction<UserExpireSettingsDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [updateUserExpireSettings.rejected.type](state) {
            state.loading = false;
        },
    });
