import React, { ReactNode } from "react";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { Controller } from "../FieldController";
import { UseControllerProps } from "react-hook-form";

export type FieldBooleanCheckboxProps = {
    name: string;
    label?: ReactNode;
    defaultValue?: boolean;
    disabled?: boolean;
    controllerProps?: Omit<UseControllerProps, "name">;
};
export const FieldBooleanCheckbox = ({
    name,
    controllerProps,
    defaultValue = false,
    label,
    disabled = false,
}: FieldBooleanCheckboxProps) => {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            {...controllerProps}
        >
            {({ field: { onChange, value } }) => {
                return (
                    <FormControl>
                        <FormControlLabel
                            label={label}
                            control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={value}
                                    onChange={(_event, checked) => {
                                        onChange(checked);
                                    }}
                                />
                            }
                        />
                    </FormControl>
                );
            }}
        </Controller>
    );
};
