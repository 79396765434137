import React from "react";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Controller, useForm } from "src/components/Form";
import { useDispatch } from "react-redux";
import { sendMessageForTechSupport } from "src/redux/pages/techSupport/action";

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            fontSize: 16,
            fontFamily: "Roboto",
            textAlign: "left",
        },
        button: {
            width: 200,
            "@media screen and (max-width: 480px)": {
                width: "100%",
            },
        },
    }),
);

export const TechSupportMessageEditor = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const { register, handleSubmit, control, watch } = useForm({
        defaultValues: {
            message: "",
        },
    });

    const { message } = watch();

    const onSubmit = () => {
        dispatch(sendMessageForTechSupport(message));
    };

    return (
        <div>
            <h2 className={classes.title}>{t("support:title")}</h2>
            <form>
                <Controller
                    name="message"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...register("message", {
                                onChange: field.onChange,
                            })}
                            label={t("support:message")}
                            fullWidth={true}
                            multiline
                        />
                    )}
                />
                <Button
                    onClick={handleSubmit(onSubmit)}
                    className={classes.button}
                    color="primary"
                    disabled={!message}
                >
                    {t("support:send").toUpperCase()}
                </Button>
            </form>
        </div>
    );
};
