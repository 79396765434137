import React, { FormEventHandler } from "react";
import { Button, ButtonProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles, withStyles } from "@mui/styles";
import classnames from "classnames";
import { Theme } from "@mui/material/styles";

const StyledButton = withStyles((theme: Theme) => ({
    root: {
        marginTop: "24px",
        minWidth: "200px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
        "&.Mui-disabled": {
            pointerEvents: "auto",
        },
        "&.Mui-disabled:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.12);",
            boxShadow: "none",
        },
    },
}))(Button);

const useStyle = makeStyles((theme: Theme) => ({
    wrapper: {
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            padding: "16px",
        },
    },
}));

type ButtonWithTooltipProps = ButtonProps & { tooltipText: string };
const ButtonWithTooltip = (props: ButtonWithTooltipProps) => {
    const { disabled, onClick, tooltipText, ...other } = props;
    const adjustedButtonProps = {
        disabled: disabled,
        component: disabled ? "div" : undefined,
        onClick: disabled ? undefined : onClick,
    };
    return (
        <Tooltip title={disabled ? tooltipText : ""}>
            <StyledButton {...other} {...adjustedButtonProps} />
        </Tooltip>
    );
};

type Props = {
    onSubmit?: FormEventHandler;
    disableSubmit?: boolean;
    className?: string;
    disableButtonTooltipText?: string;
    submitLabel?: string;
};
export const CommonAdminForm = (props: React.PropsWithChildren<Props>) => {
    const {
        children,
        className,
        disableSubmit,
        onSubmit,
        disableButtonTooltipText,
        submitLabel,
    } = props;
    const { t } = useTranslation();
    const styles = useStyle();

    return (
        <form className={classnames(styles.wrapper, className)}>
            {children}
            <ButtonWithTooltip
                disabled={disableSubmit}
                onClick={onSubmit}
                color="primary"
                tooltipText={disableButtonTooltipText}
            >
                {submitLabel || t("admin-settings:saveButton")}
            </ButtonWithTooltip>
        </form>
    );
};
