import i18n from "src/i18n";
import { FileDTO, FileStatus, ResponseFileDTO } from "src/services/generated";
import { enqueueError } from "./enqueueError";
import { FileViewModel } from "src/types/FileDisplay";

export const failedStatuses: Partial<FileStatus>[] = [
    FileStatus.ERROR,
    undefined,
];

const notFinalFileStatuses: Partial<FileStatus>[] = [
    FileStatus.PROCESSING,
    FileStatus.UPLOADING,
    FileStatus.DUMMY,
    FileStatus.UPLD_PART,
];

export function getSumSizeFiles(files: FileDTO[]) {
    return files.reduce((sum, file) => sum + file.size, 0);
}

export function isTempFile(file: FileDTO | ResponseFileDTO) {
    return file.status === FileStatus.PROCESSING;
}

export function fileInProcess(file: FileDTO | ResponseFileDTO) {
    return (
        file.status === FileStatus.UPLOADING || file.status === FileStatus.DUMMY
    );
}

export function isSuccessFile(file: FileDTO | ResponseFileDTO) {
    return file.status === FileStatus.READY;
}

export function checkIfFileInIntermediateState(
    file: FileDTO | ResponseFileDTO,
) {
    if (file.status !== undefined) {
        return notFinalFileStatuses.includes(file.status);
    }
    return false;
}

export function isFiledFile(file: FileDTO | ResponseFileDTO | FileViewModel) {
    return failedStatuses.includes(file.status);
}

export async function getFilesAsync(dataTransfer: DataTransfer) {
    const files: File[][] = await Promise.all(
        Array.from(dataTransfer.items).map(async item => {
            return await new Promise<File[]>(async resolve => {
                if (item.kind === "file") {
                    if (typeof item.webkitGetAsEntry === "function") {
                        const entry = item.webkitGetAsEntry();
                        const entryContent = await readEntryContentAsync(entry);
                        resolve(entryContent);
                    }

                    const file = item.getAsFile();
                    if (file) {
                        resolve([file]);
                    }
                }
            });
        }),
    );

    return files.flat();
}

export function isFileDuplicated(files: FileDTO[], file: File) {
    const filesCountMap = files.reduce<{ [key: string]: number }>(
        (result, file) => {
            result[file.name] = result[file.name] ? result[file.name] + 1 : 1;
            return result;
        },
        {},
    );
    return filesCountMap[file.name] > 0;
}

export function fireFileWasNotUploadedPopup(fileName: string) {
    //TODO: this is not the best solution, needs to be fixed in the future
    let errorText = "";
    if (fileName.length > 27) {
        errorText = fileName.slice(0, 27) + `... `;
    } else {
        errorText = fileName;
    }
    errorText += ` ${i18n.t("send-file:errors.fileWasNotUploaded")}`;
    enqueueError(errorText, 15000);
}

// Returns a promise with all the files of the directory hierarchy
function readEntryContentAsync(entry: FileSystemEntry) {
    return new Promise<File[]>((resolve, reject) => {
        let reading = 0;
        const contents: File[] = [];

        readEntry(entry);

        function readEntry(entry: FileSystemEntry) {
            if (isFile(entry)) {
                reading++;
                entry.file(file => {
                    reading--;
                    contents.push(file);

                    if (reading === 0) {
                        resolve(contents);
                    }
                });
            } else if (isDirectory(entry)) {
                readReaderContent(entry.createReader());
            }
        }

        function readReaderContent(reader: FileSystemDirectoryReader) {
            reading++;

            reader.readEntries(function (entries) {
                reading--;
                for (const entry of entries) {
                    readEntry(entry);
                }

                if (reading === 0) {
                    resolve(contents);
                }
            });
        }
    });
}

// for TypeScript typing (type guard function)
// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
function isDirectory(
    entry: FileSystemEntry,
): entry is FileSystemDirectoryEntry {
    return entry.isDirectory;
}

function isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
    return entry.isFile;
}
