import {
    FileDTO,
    FileUploadProgressbarResponse,
    ResponseFileDTO,
    ResponseMessageDTO,
    ResponseMessageEmailDTO,
} from "src/services/generated";

export enum FileDisplayContainerState {
    EMPTY,
    FILLED,
}

export interface ResponseMessagePage {
    responseMessage?: ResponseMessageDTO & { errors?: string[] };
    uploadedFileCount: number;
    responseMessagePageState: ResponseMessagePageState;
    inProgress: boolean;
    linkExpired: boolean;
    responseMessageMemory: FileUploadProgressbarResponse;
    reservedFileId: string[];
}

export interface ResponseMessagePatch {
    uuid: string;
    responseMessageEmailDTO: ResponseMessageEmailDTO;
}

export interface DeleteFileParams {
    messageId: string;
    file: FileDTO | ResponseFileDTO;
}

export enum ResponseMessagePageState {
    AFTER_RESPONSE_MESSAGE_EDITING,
    RESPONSE_MESSAGE_EDIT,
}
