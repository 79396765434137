import React from "react";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import { copyElementContentById } from "src/helpers/clipboard";
import { useTranslation } from "react-i18next";
import { getDirectLinks } from "src/helpers/url";
import { ReactComponent as Copy } from "../icons/copy.svg";
import { MessageModel } from "src/services/generated";

type DirectLinksListProps = {
    message: MessageModel;
};

export const DirectLinksList = ({ message }: DirectLinksListProps) => {
    const { t } = useTranslation();

    return (
        <Textarea
            id="FileLinkForm:direct-links"
            name="direct-links"
            label={t("send-file:directLinks")}
            multiline
            maxRows={8}
            value={getDirectLinks(message)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <Tooltip
                            title={t(
                                "send-file:fileSettingsForm.directLinksTooltip",
                            )}
                        >
                            <IconButton
                                onClick={() =>
                                    copyElementContentById(
                                        "FileLinkForm:direct-links",
                                    )
                                }
                            >
                                <Copy />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
        />
    );
};

const Textarea = styled(TextField)`
    & .MuiInputBase-input {
        line-height: 16px;
        overflow-x: auto !important;
        white-space: pre;
    }
`;
