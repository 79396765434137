import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { LightScheduleFormat } from "src/helpers/cronFromatAdapters";
import { settingsApiService } from "src/services/clientApi";
import { StorageSettingsDTO } from "src/services/generated";

export interface SetLightFormatValues {
    freeSpaceCheckLightFormat: LightScheduleFormat;
    expiredFilesLightFormat: LightScheduleFormat;
}

export interface SetCronFormatValues {
    freeSpaceCheckSchedule?: string;
    expiredFilesSchedule?: string;
}

export const setLightFormatValues = createAction<SetLightFormatValues>(
    "setLightFormatValues",
);
export const setCronFormatValues = createAction<SetCronFormatValues>(
    "setCronFormatValues",
);
export const setPeriodTypeIsNormal = createAction<boolean>(
    "setPeriodTypeIsNormal",
);

export const getStorageSettings = createAsyncThunk(
    "getStorageSettings",
    async () => {
        return (await settingsApiService.getStorageSettings()).data;
    },
);

export const updateStorageSettings = createAsyncThunk(
    "updateStorageSettings",
    async (data: StorageSettingsDTO) => {
        return (await settingsApiService.updateStorageSettings(data)).data;
    },
);
