export const MuiFormLabel = {
    root: {
        fontSize: 14,
        lineHeight: "16px",

        "&.Mui-disabled": {
            color: "#1A1F29",
            opacity: 0.6,
        },
    },
    shrink: {
        height: 14,
        fontSize: 12,
        lineHeight: "14px",
        transform: "translate(0, 4px) scale(1)",
    },
};
