import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
    EmailForm,
    FileSettingsForm,
    FileStatusUpdate,
    SendFilePageStates,
    SendMessageData,
    UpdateMessageData,
} from "src/types/redux/store/pages/SendFilePage";
import { messageApi, controllerApi } from "src/services/clientApi";
import { UploadFileInfo } from "src/types/Upload";
import {
    MessageModelSendingTypeEnum,
    FileDTO,
    FileStatus,
    FileUploadPrepareRequest,
    FileStateRequest,
    Encrypted,
} from "src/services/generated";
import { Store } from "src/types/redux/store";
import { DeleteFileParams } from "src/types/redux/store/pages/ResponseMessage";
import axios, { CancelTokenSource } from "axios";
import i18n from "src/i18n";
import { enqueueError } from "src/helpers/enqueueError";
import { fireFileWasNotUploadedPopup } from "src/helpers/file";
import { FilesWithArgs } from "../myFiles/actions";

let cancelTokens: Map<string, CancelTokenSource>;

type CreateFileUUIDParams = {
    messageId?: string;
    name: string;
    size: number;
    encrypted: Encrypted;
};
export type UpdateFileSettingsForm = Partial<FileSettingsForm>;
export type UpdateEmailForm = Partial<EmailForm>;
export type ChangeFileUploadProgressActionType = {
    fileId: string;
    progress: number;
};

export const setSendType =
    createAction<MessageModelSendingTypeEnum>("setSendType");
export const updateFileSettingsForm = createAction<UpdateFileSettingsForm>(
    "updateFileSettingsForm",
);
export const updateEmailForm = createAction<UpdateEmailForm>("updateEmailForm");
export const changeSendFilePageState = createAction<SendFilePageStates>(
    "changeSendFilePageState",
);
export const changePasswordSwitchState = createAction<boolean>(
    "changePasswordSwitchState",
);
export const changeDirectLinksEnabled = createAction<boolean>(
    "changeDirectLinksEnabled",
);

export const changePassword = createAction<string>("changePassword");
export const changeFileUploadProgressAction =
    createAction<ChangeFileUploadProgressActionType>(
        "changeFileUploadProgressAction",
    );
export const updateFileStatus =
    createAction<FileStatusUpdate>("updateFileStatus");
export const deleteMessage = createAction("deleteMessage");
export const createFileUUID = createAction<FileDTO>("createFileUUID");

export const updateMessageModel = createAsyncThunk(
    "updateMessageModel",
    async (updateMessageData: UpdateMessageData) => {
        if (!updateMessageData.messageId) {
            return;
        }
        return (
            await messageApi.updateMessage(
                updateMessageData.messageId,
                updateMessageData.messageModel,
            )
        ).data;
    },
);

//
export const createMessage = createAsyncThunk("createMessage", async () => {
    cancelTokens = new Map();
    return (await messageApi.createMessage()).data;
});

export const reserveFileInMessage = createAsyncThunk<
    FileDTO,
    CreateFileUUIDParams
>(
    "reserveFileInMessage",
    async ({ messageId, name, size, encrypted }, thunkAPI) => {
        try {
            const response = await messageApi.reserveFileInMessage1(messageId, {
                name,
                size,
                encrypted,
            } as FileUploadPrepareRequest);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue((error as any).response.data);
        }
    },
);

export const updateFileStatuses = createAsyncThunk(
    "updateFileStatuses",
    async (messageId: string) => {
        return (await messageApi.getPublicMessageById(messageId)).data;
    },
);

export const deleteFile = createAsyncThunk(
    "deleteFile",
    async (deleteFileParams: DeleteFileParams, thunkAPI) => {
        const store = thunkAPI.getState() as Store;
        const { file: fileToDelete, messageId } = deleteFileParams;
        const file = store.pages.sendFilePage.message?.files?.find(
            file => file.id === fileToDelete.id,
        );

        if (
            file &&
            (file.status === FileStatus.UPLOADING ||
                (file as any).progress !== 100) &&
            cancelTokens.has(file.id!)
        ) {
            cancelTokens.get(file.id!)?.cancel();
            cancelTokens.delete(file.id!);
        }

        await messageApi.deleteFileFromMessage(messageId, fileToDelete.id);

        return fileToDelete.id;
    },
);

export const uploadFiles = createAsyncThunk(
    "uploadFiles",
    async ({ files, encrypted }: FilesWithArgs, thunkAPI) => {
        const state = thunkAPI.getState() as Store;
        let message = state.pages.sendFilePage.message;
        if (!message) {
            try {
                const newMessage = (await thunkAPI.dispatch(createMessage()))
                    .payload;
                message = newMessage;
                if (!message) {
                    throw new Error();
                }
            } catch (error) {
                enqueueError(
                    i18n.t("send-file:errors.messageWasNotCreated"),
                    15000,
                );
                return;
            }
        }

        files.forEach(async file => {
            let result = await thunkAPI.dispatch(
                reserveFileInMessage({
                    messageId: message.id!,
                    name: file.name,
                    size: file.size,
                    encrypted: encrypted,
                }),
            );

            if (
                (result as any).error ||
                (result.payload as FileDTO).status === FileStatus.ERROR
            ) {
                const errorFileModel = result.payload as FileDTO;
                fireFileWasNotUploadedPopup(file.name);
                thunkAPI.dispatch(createFileUUID(errorFileModel));
                return;
            }

            const fileId = (result.payload as FileDTO).id;
            const fileStatus = (result.payload as FileDTO).status;
            thunkAPI.dispatch(
                createFileUUID({
                    id: fileId,
                    name: file.name,
                    size: file.size,
                    status: fileStatus,
                }),
            );

            cancelTokens.set(fileId, axios.CancelToken.source());
            thunkAPI.dispatch(
                uploadFileToServer({
                    file: file,
                    messageId: message.id,
                    reservedFileUUID: fileId,
                }),
            );
        });
    },
);

export const uploadFileToServer = createAsyncThunk(
    "uploadFileToServer",
    async (uploadFileInfo: UploadFileInfo, thunkAPI) => {
        const { file, messageId, reservedFileUUID } = uploadFileInfo;

        const onUploadProgress = (progressEvent: ProgressEvent) => {
            const progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
            );
            thunkAPI.dispatch(
                changeFileUploadProgressAction({
                    fileId: reservedFileUUID,
                    progress,
                }),
            );
        };

        try {
            const fileModel = await messageApi.uploadFileToMessage(
                messageId,
                reservedFileUUID,
                file,
                {
                    onUploadProgress,
                    cancelToken: cancelTokens.get(reservedFileUUID)!.token,
                },
            );
            cancelTokens.delete(reservedFileUUID);
            return fileModel.data;
        } catch (e) {
            thunkAPI.rejectWithValue(reservedFileUUID);
        }
    },
);

export const sendMessage = createAsyncThunk(
    "sendMessage",
    async (sendMessageInfo: SendMessageData, thunkAPI) => {
        const newMessage = await messageApi.updateAndSendMessageByEmail(
            sendMessageInfo.messageId,
            sendMessageInfo.messageModel,
        );
        thunkAPI.dispatch(
            changeSendFilePageState(SendFilePageStates.AFTER_MESSAGE_SENDING),
        );
        return newMessage.data;
    },
);

export const getDownloadVolumeValues = createAsyncThunk(
    "getDownloadVolumeValues",
    async (excludeMessageId: string) => {
        return (await controllerApi.getProgressbarStats({ excludeMessageId }))
            .data;
    },
);

export const getDownloadStateFilesSendFile = createAsyncThunk(
    "getDownloadStateFilesSendFile",
    async (fileStateRequest: FileStateRequest) => {
        return (await controllerApi.getState(fileStateRequest)).data;
    },
);
