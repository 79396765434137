import React, { useState, useMemo, useCallback, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, Box, IconButton, Button, Grid } from "@mui/material";
import classNames from "classnames";
import {
    ArrowBackIos,
    ArrowForwardIos,
    ArrowDropDown,
    ArrowDropUp,
} from "@mui/icons-material";

import { CalendarYear } from "./CalendarYear";
import { CalendarProps } from "./Calendar";
import color from "src/theme/variables/color";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            backgroundColor: "#fff",
            maxWidth: "300px",
            minWidth: 0,
            padding: "0 24px 12px",
            [theme.breakpoints.down("sm")]: {
                padding: 0,
            },
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "48px",
            padding: "4px 0",
        },
        selectYear: {
            fontSize: "12px",
            color: color.sendFileColor.onSecondary.text,
            letterSpacing: "1px",
            textTransform: "none",
        },
        calendar: {
            display: "flex",
            flexWrap: "wrap",
        },
        calendarRow: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
        calendarItem: {
            flex: "1 0 calc(100% / 7)",
            padding: "8px",
            height: "36px",
            minWidth: "32px",
            color: color.sendFileColor.onSecondary.text,
            fontSize: "12px",
            fontWeight: 400,
            borderRadius: "30px",
        },
        active: {
            backgroundColor: color.primary.main,
            color: "#fff",
            "&:hover": {
                backgroundColor: color.primary.main,
            },
        },
        arrowDrop: {
            opacity: 0.6,
            marginLeft: "12px",
        },
    }),
);

const minIndexMonth = 0;
const maxIndexMonth = 11;

export const CalendarDate = ({ onChange, date }: CalendarProps) => {
    const styles = useStyles();
    const [year, setYear] = useState(date.year());
    const [month, setMonth] = useState(date.month());
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setYear(date.year());
        setMonth(date.month());
    }, [date]);

    const monthsName = dayjs?.months();

    const onIncMonth = () => {
        if (month === maxIndexMonth) {
            setYear(year + 1);
            setMonth(minIndexMonth);
        } else {
            setMonth(month + 1);
        }
    };

    const onDecMonth = () => {
        if (month === minIndexMonth) {
            setYear(year - 1);
            setMonth(maxIndexMonth);
        } else {
            setMonth(month - 1);
        }
    };

    const onChangeDay = useCallback(
        (event: any) => {
            onChange(
                date
                    .year(year)
                    .month(month)
                    .date(+event.target.value)
                    .startOf("day"),
            );
        },
        [date, year, month, onChange],
    );

    const calendar = useMemo(() => {
        const calendar = [];
        const weekdays = [...dayjs.weekdaysMin()];
        const sunday = weekdays.shift();
        weekdays.push(sunday);

        calendar.push(
            <Box key="header" className={styles.calendarRow}>
                {weekdays.map(weekday => (
                    <Box key={weekday} className={styles.calendarItem}>
                        {weekday}
                    </Box>
                ))}
            </Box>,
        );

        let weekday = dayjs([year, month]).startOf("month").day();
        weekday = weekday === 0 ? 7 : weekday;
        let day = 1 - weekday;
        const lastDay = dayjs([year, month]).endOf("month").date();

        calendar.push(
            ...Array.from({ length: 6 }, (_, index) => (
                <Box key={index} className={styles.calendarRow}>
                    {Array.from({ length: 7 }, () => {
                        day++;
                        return day > 0 && day <= lastDay ? (
                            <Button
                                value={day}
                                variant="text"
                                key={day}
                                className={classNames(styles.calendarItem, {
                                    [styles.active]: date.isSame(
                                        dayjs([year, month, day]),
                                        "day",
                                    ),
                                })}
                                onClick={onChangeDay}
                            >
                                {day > 0 && day <= lastDay && day}
                            </Button>
                        ) : (
                            <Box
                                key={day}
                                className={styles.calendarItem}
                            ></Box>
                        );
                    })}
                </Box>
            )),
        );

        return calendar;
    }, [month, year, styles, date, onChangeDay]);

    const selectTogle = () => {
        setIsOpen(isOpen => !isOpen);
    };

    const changeYear = useCallback((date: Dayjs) => {
        setYear(date.year());
        selectTogle();
    }, []);

    return (
        <>
            <Box className={styles.container}>
                <Grid
                    container
                    justifyContent="space-between"
                    className={styles.header}
                    wrap="nowrap"
                >
                    <Button
                        className={styles.selectYear}
                        onClick={selectTogle}
                        variant="text"
                    >
                        <Box>{`${monthsName[month]} ${year}`}</Box>
                        <Box className={styles.arrowDrop}>
                            {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                        </Box>
                    </Button>
                    <Grid
                        item
                        container
                        wrap="nowrap"
                        justifyContent="flex-end"
                        xs="auto"
                    >
                        <IconButton onClick={onDecMonth}>
                            <ArrowBackIos />
                        </IconButton>
                        <IconButton onClick={onIncMonth}>
                            <ArrowForwardIos />
                        </IconButton>
                    </Grid>
                </Grid>

                <Box className={styles.calendar}>
                    {isOpen ? (
                        <CalendarYear
                            type="year"
                            date={dayjs([year, month])}
                            onChange={changeYear}
                        />
                    ) : (
                        calendar
                    )}
                </Box>
            </Box>
        </>
    );
};
