import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { logFilesApi } from "../../../services/clientApi";
import { LogFileDTO, LogFileListRequest } from "../../../services/generated";

export const loadListLogFiles = createAsyncThunk(
    "loadListLogFiles",
    async (params: LogFileListRequest, thunkApi) => {
        return (await logFilesApi.list(params)).data;
    },
);

export const setViewLogFile = createAction(
    "setViewLogFile",
    function prepare(file: LogFileDTO) {
        return {
            payload: file,
        };
    },
);

export const unsetViewLogFile = createAction("unsetViewLogFile");
