import React, { MouseEvent } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface LanguageSwitcherProps {
    languages: string[];
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            whiteSpace: "nowrap",
            display: "flex",
            justifyContent: "space-between",
            "& button": {
                color: "black",
            },
            "& button.language-switcher_disabled": {
                opacity: "0.5",
                pointerEvents: "unset",
            },
        },
        languageButton: {
            minWidth: 5,
            padding: 4,
            pointerEvents: "none",
        },
    }),
);

export function LanguageHorizontalSwitcher(props: LanguageSwitcherProps) {
    const { languages, className = "" } = props;
    const { i18n } = useTranslation();
    const styles = useStyles();

    const changeGlobalLanguage = (event: MouseEvent) => {
        const value = event.currentTarget.getAttribute("value");
        value !== null && value !== i18n.language && i18n.changeLanguage(value);
    };

    return (
        <div className={`${styles.root} ${className}`}>
            {languages.map(language => {
                return (
                    <Button
                        size="small"
                        variant="text"
                        value={language}
                        className={`${styles.languageButton}  ${
                            i18n.language === language
                                ? ""
                                : "language-switcher_disabled"
                        }`}
                        key={language}
                        onClick={changeGlobalLanguage}
                    >
                        {language}
                    </Button>
                );
            })}
        </div>
    );
}
