import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { settingsApiService } from "src/services/clientApi";
import { MailHeadersDTO } from "src/services/generated";

export const getMailHeaders = createAsyncThunk("getMailHeaders", async () => {
    return (await settingsApiService.getMailHeaders()).data;
});

export const updateMailHeaders = createAsyncThunk(
    "updateMailHeaders",
    async (settings: MailHeadersDTO, thankAPI) => {
        try {
            const response =
                await settingsApiService.updateMailHeaders(settings);
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
            return response.data;
        } catch (error) {
            thankAPI.rejectWithValue(error);
            enqueueError(i18n.t("admin-settings:error.edit"));
        }
    },
);
