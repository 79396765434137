import React from "react";
import { ClosingDialogTitle } from "../СlosingDialogTitle";
import { Button, Dialog, DialogActions, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BaseDialogProps } from "src/pages/MyFiles";

type DialogDeleteFileProps = {
    confirmDelete?: () => void;
} & BaseDialogProps;

export const DialogDeleteFile = ({
    open,
    onClose,
    confirmDelete,
}: DialogDeleteFileProps) => {
    const { t } = useTranslation();
    const confirmDeleteFile = () => {
        confirmDelete();
        onClose();
    };

    return (
        <Dialog
            PaperProps={{ sx: { width: "440px" } }}
            open={open}
            onClose={onClose}
        >
            <ClosingDialogTitle onClose={onClose}>
                {t("my-file:deleteFileDialog.title")}
            </ClosingDialogTitle>
            <DialogActionsStyles>
                <Button onClick={onClose} color="secondary">
                    {t("my-file:deleteFileDialog.cancel")}
                </Button>
                <Button
                    onClick={confirmDeleteFile}
                    type="submit"
                    color="primary"
                >
                    {t("my-file:deleteFileDialog.confirm")}
                </Button>
            </DialogActionsStyles>
        </Dialog>
    );
};

const DialogActionsStyles = styled(DialogActions)`
    margin-top: 24px;
`;
