import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { settingsApiService } from "src/services/clientApi";
import { UserExpireSettingsDTO } from "src/services/generated";

export const getUserExpireSettings = createAsyncThunk(
    "getUserExpireSettings",
    async (args, thankAPI) => {
        try {
            const response = await settingsApiService.getUserExpireSettings();
            return response.data;
        } catch (error) {
            enqueueError(i18n.t("admin-settings:errors.get"));
            thankAPI.rejectWithValue(error);
        }
    },
);

export const updateUserExpireSettings = createAsyncThunk(
    "updateUserExpireSettings",
    async (data: UserExpireSettingsDTO, thunkAPI) => {
        try {
            const response =
                await settingsApiService.updateUserExpireSettings(data);
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
            thunkAPI.dispatch(getUserExpireSettings());
            return response.data;
        } catch (error) {
            enqueueError(i18n.t("admin-settings:error.edit"));
            thunkAPI.rejectWithValue(error);
        }
    },
);
