import React, { useEffect } from "react";
import {
    FormControl,
    Grid,
    Input,
    InputLabel,
    TextField,
    Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm, FieldRadio } from "src/components/Form";
import { useTranslation } from "react-i18next";
import { CommonAdminForm } from "src/components/Admin/commonAdminForm";
import { Preloader } from "src/components/Preloader";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { GeneralSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import {
    getSettingsGeneral,
    updateSettingsGeneral,
} from "src/redux/pages/administrationSettings/general/actions";
import { Encrypted, GeneralSettingsDTO } from "src/services/generated";
import { mbMultiplier } from "src/constants/fileSizeMultiplier";
import { isEmpty } from "lodash";
import { getPublicSettings } from "../../../redux/pages/applicationSettings/actions";
import * as yup from "yup";

const useStyle = makeStyles({
    container: {
        maxWidth: 800,
    },
    tooltip: {
        maxWidth: 500,
    },
    contentLongRow: {
        "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
    label: {
        fontWeight: 500,
        paddingBottom: "28px",
    },
    formControl: {
        paddingTop: "40px",
    },
});

export const GeneralSettings = () => {
    const { t } = useTranslation();
    const styles = useStyle();
    const dispatch = useDispatch();
    const { loading, form } = useSelector<Store, GeneralSettingsPage>(
        store =>
            store.pages.administration.administrationSettings
                .generalSettingsPage,
    );

    const { reset, watch, register, handleSubmit, errors, control } = useForm<
        GeneralSettingsPage["form"]
    >({
        defaultValues: {
            ...form,
            fileSizeUploadLimit: form.fileSizeUploadLimit
                ? form.fileSizeUploadLimit / mbMultiplier
                : form.fileSizeUploadLimit,
        },
        schema: yup.object({
            fileSizeUploadLimit: yup
                .number()
                .positive("admin-settings:commonErrors.onlyPositiveIntValue")
                .typeError("admin-settings:commonErrors.onlyPositiveIntValue")
                .transform(
                    (_, originalValue) =>
                        +String(originalValue).replace(/,/g, "."),
                )
                .transform((value, originalValue) =>
                    originalValue === "" ? null : value,
                )
                .notRequired(),
            techSupportEmail: yup.string().email("common:errors.invalidEmail"),
        }),
    });
    const { prevSendFileName, prevSendFileUrl } = watch();
    const prevSendFileNameValue = prevSendFileName?.trim();
    const prevSendFileUrlValue = prevSendFileUrl?.trim();
    const isValidLinkNameField =
        (!prevSendFileNameValue && !prevSendFileUrlValue) ||
        !isEmpty(prevSendFileNameValue);
    const isValidLinkField =
        (!prevSendFileUrlValue && !prevSendFileNameValue) ||
        !isEmpty(prevSendFileUrlValue);

    const onSubmit = (data: GeneralSettingsDTO) => {
        dispatch(
            updateSettingsGeneral({
                ...data,
                techSupportEmail: data.techSupportEmail.toLowerCase(),
                fileSizeUploadLimit: data.fileSizeUploadLimit
                    ? data.fileSizeUploadLimit * mbMultiplier
                    : null,
            }),
        );
    };

    useEffect(() => {
        dispatch(getSettingsGeneral());
        dispatch(getPublicSettings());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getPublicSettings());
    }, [dispatch, form.prevSendFileName, form.prevSendFileUrl]);

    useEffect(() => {
        reset({
            ...form,
            fileSizeUploadLimit: form.fileSizeUploadLimit
                ? form.fileSizeUploadLimit / mbMultiplier
                : form.fileSizeUploadLimit,
        });
    }, [form, reset]);

    return (
        <CommonAdminForm
            className={styles.container}
            disableSubmit={
                !!errors["techSupportEmail"] ||
                !!errors["fileSizeUploadLimit"] ||
                !isValidLinkNameField ||
                !isValidLinkField
            }
            onSubmit={handleSubmit(onSubmit)}
            disableButtonTooltipText={t(
                "admin-settings:generalSettings.disableButtonTooltipText",
            )}
        >
            {loading && <Preloader over />}
            <TextField
                error={!!errors["fileSizeUploadLimit"]}
                InputLabelProps={{ shrink: true }}
                inputProps={{ autoComplete: "off" }}
                label={t("admin-settings:generalSettings.uploadFileLimit")}
                helperText={
                    !!errors["fileSizeUploadLimit"]
                        ? t(errors.fileSizeUploadLimit.message)
                        : t(
                              "admin-settings:generalSettings.uploadFileLimitHint.Hint",
                          )
                }
                {...register("fileSizeUploadLimit")}
            />

            <TextField
                error={!!errors["techSupportEmail"]}
                InputLabelProps={{ shrink: true }}
                label={t("admin-settings:generalSettings.notificationEmail")}
                helperText={
                    !!errors["techSupportEmail"]
                        ? t(errors.techSupportEmail.message)
                        : ""
                }
                {...register("techSupportEmail")}
            />

            <Grid container spacing={12}>
                <Grid item xs={6}>
                    <FormControl>
                        <InputLabel shrink={true}>
                            {t("admin-settings:generalSettings.headerLinkName")}
                        </InputLabel>
                        <Tooltip
                            title={prevSendFileName || ""}
                            className={styles.tooltip}
                            disableInteractive
                        >
                            <Input
                                error={!isValidLinkNameField}
                                className={styles.contentLongRow}
                                autoComplete="off"
                                {...register("prevSendFileName")}
                            />
                        </Tooltip>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl>
                        <InputLabel shrink={true}>
                            {t("admin-settings:generalSettings.headerLink")}
                        </InputLabel>
                        <Tooltip
                            title={prevSendFileUrl || ""}
                            className={styles.tooltip}
                            disableInteractive
                        >
                            <Input
                                error={!isValidLinkField}
                                className={styles.contentLongRow}
                                autoComplete="off"
                                {...register("prevSendFileUrl")}
                            />
                        </Tooltip>
                    </FormControl>
                </Grid>
            </Grid>

            <div style={{ width: "25%" }}>
                <FieldRadio
                    name="encryptionState"
                    label={t(
                        "admin-settings:generalSettings.labels.encryption",
                    )}
                    classNameLabel={styles.label}
                    options={[
                        {
                            label: t("admin-settings:generalSettings.enabled"),
                            value: Encrypted.ZIP,
                        },
                        {
                            label: t("admin-settings:generalSettings.disabled"),
                            value: Encrypted.NONE,
                        },
                    ]}
                    //@ts-ignore
                    controllerProps={{ control }}
                />
            </div>
            <div style={{ width: "25%" }}>
                <FieldRadio
                    name="directLinksEnabled"
                    label={t(
                        "admin-settings:generalSettings.labels.directLinks",
                    )}
                    classNameLabel={styles.label}
                    options={[
                        {
                            label: t("admin-settings:generalSettings.enabled"),
                            value: true,
                        },
                        {
                            label: t("admin-settings:generalSettings.disabled"),
                            value: false,
                        },
                    ]}
                    //@ts-ignore
                    controllerProps={{ control }}
                />
            </div>
        </CommonAdminForm>
    );
};
