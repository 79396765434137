import styleVariables from "../variables";

export const MuiInputBase = {
    input: {
        fontSize: 14,
        height: 16,
        color: styleVariables.color.sendFileColor.onSecondary.text,
        padding: "2px 40px 7px 8px",
    },
    multiline: {
        padding: "2px 0 7px 8px",
    },
    root: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: "14px",
    },
};
