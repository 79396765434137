import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            alignItems: "center",
            textAlign: "center",
            padding: "12px",
            backgroundColor: "#fff",
        },
        title: {
            fontSize: "20px",
            fontWeight: 500,
        },
        subtitle: {
            fontSize: "14px",
            marginTop: "8px",
        },
    }),
);

type NoDataFoundProps = {
    isDefaultFilter?: boolean;
    className?: string;
};

export const NoDataFound = ({
    isDefaultFilter = false,
    className,
}: NoDataFoundProps) => {
    const { t } = useTranslation();
    const styles = useStyles();
    return (
        <Box className={classNames(styles.wrapper, className)}>
            <Typography className={styles.title} component="h2">
                {isDefaultFilter
                    ? t("common:noData.title")
                    : t("common:noDataFound.title")}
            </Typography>
            <Typography className={styles.subtitle}>
                {isDefaultFilter
                    ? t("common:noData.subtitle")
                    : t("common:noDataFound.subtitle")}
            </Typography>
        </Box>
    );
};
