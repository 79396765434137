import React, { FC } from "react";
import { Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FileDisplay from "../FileDisplay/FileDisplay";
import { useTranslation } from "react-i18next";
import { ResponseMessageDTO, ResponseFileDTO } from "../../services/generated";
import {
    downloadResponceAllFileByUser,
    downloadResponceFileByUser,
} from "src/helpers/download";
import { TimeToExpireInfo } from "./TimeToExpireInfo";
import { getSumSizeFiles } from "src/helpers/file";

const useStyle = makeStyles({
    headerText: {
        color: "#000000",
        fontSize: "16px",
        fontWeight: "bold",
    },
    expireInfo: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        marginBottom: "40px",
    },
    fileDownloadButton: {
        width: "200px",
    },
    buttonContainer: {
        background: "#FAFAFC",
        boxShadow: "0 0 0 4px #fafafc",
        padding: "8px 0 14px 0",
        position: "sticky",
        top: 0,
        zIndex: 2,
    },
    footer: {
        position: "sticky",
        bottom: 0,
        background: "#FAFAFC",
        width: "100%",
        paddingBottom: "16px",
    },
    timeWrapper: {
        marginTop: "24px",
    },
    warning: {
        boxShadow:
            "0px 1px 5px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.04)",
        borderRadius: "4px",
        marginBottom: "8px",
        backgroundColor: "#F5F1E3",
        border: "1px solid #FFCC00",
        padding: "16px",
        textAlign: "center",
    },
});

type ResponseMessageProps = {
    responseMessage: ResponseMessageDTO;
    messageId: string;
    password?: string;
    className?: string;
    hideTitle?: boolean;
    hideFooter?: boolean;
};

export const ResponseMessageDownloadView: FC<ResponseMessageProps> = props => {
    const {
        responseMessage,
        messageId,
        className,
        hideTitle = false,
        hideFooter = false,
    } = props;
    const styles = useStyle();
    const { t } = useTranslation();

    const downloadFileCase = (file: ResponseFileDTO) => {
        downloadResponceFileByUser(messageId, file.id);
    };

    const downloadAllFilesCase = () => {
        const totalSize = getSumSizeFiles(responseMessage.responseFiles);
        downloadResponceAllFileByUser(messageId, totalSize);
    };

    const isExpiredTime = new Date(responseMessage?.expireTime!) < new Date();

    return (
        <div className={className}>
            {!hideTitle && (
                <div className={styles.headerText}>
                    {responseMessage.responseFiles?.length === 1
                        ? t("download-files:uploadedFile")
                        : t("download-files:uploadedFiles")}
                </div>
            )}

            {responseMessage.responseFiles?.length === 0 && (
                <Box className={styles.warning}>
                    {t("download-files:warning.filesUnavailable")}
                </Box>
            )}

            <div>
                {responseMessage.responseFiles?.map((file, index) => {
                    return (
                        <FileDisplay
                            isExpiredTime={isExpiredTime}
                            file={file}
                            key={index}
                            onClick={() => downloadFileCase(file)}
                        />
                    );
                })}
            </div>
            {!hideFooter && (
                <Box className={styles.footer}>
                    <Box className={styles.timeWrapper}>
                        <TimeToExpireInfo
                            time={responseMessage?.expireTime!}
                            isExpiredTime={isExpiredTime}
                        />
                    </Box>
                    {responseMessage.responseFiles?.length! > 1 && (
                        <Button
                            onClick={downloadAllFilesCase}
                            className={styles.fileDownloadButton}
                            color="primary"
                            disabled={isExpiredTime}
                        >
                            {t("download-files:downloadFile")}
                        </Button>
                    )}
                </Box>
            )}
        </div>
    );
};
