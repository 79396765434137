import React, { useEffect, useState } from "react";
import {
    Box,
    StandardTextFieldProps,
    InputAdornment,
    Popper,
    Button,
    Paper,
    Dialog,
    DialogContent,
    DialogActions,
    Theme,
    ClickAwayListener,
    IconButton,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ReactComponent as CalendarIcon } from "src/icons/calendar.svg";
import { Close } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { Calendar } from "../Calendar/Calendar";
import { dateRegExp } from "src/constants/regular";
import { useResize } from "src/hooks/useResize";
import { ClosingDialogTitle } from "../ModalWindows/СlosingDialogTitle";
import {
    commonYearFormat,
    commonYearAndMonthFormat,
    commonDateFormat,
} from "src/constants/momentFormats";
import { dateMask, yearMask, monthMask } from "src/constants/masks";
import { MaskedInput } from "../MaskedInput ";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "100%",
        },
        popper: {
            zIndex: "10000",
        },
    }),
);

const types = {
    year: {
        mask: yearMask,
        format: commonYearFormat,
    },
    month: {
        mask: monthMask,
        format: commonYearAndMonthFormat,
    },
    date: {
        mask: dateMask,
        format: commonDateFormat,
    },
};

export type CalendarPickerType = "year" | "month" | "date";

export interface DatePickerProps extends StandardTextFieldProps {
    value?: Dayjs;
    onChangeDate?: (date: Dayjs) => void;
    type?: CalendarPickerType;
    onReset?: () => void;
}

export const DatePicker = ({
    value,
    onChangeDate,
    type,
    onReset,
    ...props
}: DatePickerProps) => {
    const styles = useStyles();
    const [isOpen, seiIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [inputValue, setInputValue] = useState(
        value?.format(types[type].format()),
    );
    const [date, setDate] = useState(value || dayjs());
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();
    const { isScreenSm } = useResize();

    const maskConfig = () => ({
        mask: types[type].mask(),
        lazy: true,
        eager: true,
    });

    useEffect(() => {
        setInputValue(value?.format(types[type].format()));
        setDate(value || dayjs());
    }, [value, type]);

    const onOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        seiIsOpen(true);
    };

    const handleChange = (date: Dayjs) => {
        onChangeDate(date);
        setDate(date);
        setInputValue(date.format(types[type].format()));
        setError(null);
        seiIsOpen(false);
    };

    const onAccept = (value: string) => {
        setInputValue(value);
        seiIsOpen(false);
        if (value === "") {
            setError(null);
        }
    };

    const onComplete = (value: string) => {
        if (dateRegExp.test(value)) {
            const date = dayjs(value, types[type].format());
            onChangeDate(date);
            setDate(date);
            setError(null);
        } else {
            setError(t("date-picker:error.incorrect"));
        }
    };

    const onClose = () => {
        seiIsOpen(false);
    };

    const onResetClbck = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        seiIsOpen(false);
        setInputValue(undefined);
        onReset();
    };

    return (
        <ClickAwayListener onClickAway={onClose}>
            <Box className={styles.container}>
                <MaskedInput
                    value={inputValue}
                    config={maskConfig()}
                    onAccept={onAccept}
                    onComplete={onComplete}
                    error={!!error}
                    helperText={error}
                    {...props}
                    autoComplete="off"
                    InputProps={{
                        endAdornment: inputValue ? (
                            <InputAdornment
                                position="end"
                                sx={{ cursor: "pointer" }}
                            >
                                <IconButton onClick={onResetClbck} edge="end">
                                    <Close />
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            <InputAdornment
                                position="end"
                                onClick={onOpen}
                                sx={{ cursor: "pointer" }}
                            >
                                <CalendarIcon />
                            </InputAdornment>
                        ),
                    }}
                    onClick={onOpen}
                />

                {isScreenSm ? (
                    <Popper
                        open={isOpen}
                        anchorEl={anchorEl}
                        className={styles.popper}
                    >
                        <Paper>
                            <Calendar
                                onChange={handleChange}
                                date={date}
                                type={type}
                            />
                        </Paper>
                    </Popper>
                ) : (
                    <Dialog open={isOpen} onClose={onClose}>
                        <ClosingDialogTitle onClose={onClose}>
                            {props.label}
                        </ClosingDialogTitle>
                        <DialogContent sx={{ display: "flex" }}>
                            <Calendar
                                onChange={setDate}
                                date={date}
                                type={type}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="secondary">
                                {t("common:cancel")}
                            </Button>
                            <Button
                                onClick={() => handleChange(date)}
                                type="submit"
                                color="primary"
                            >
                                {t("common:confirm")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Box>
        </ClickAwayListener>
    );
};
