import React from "react";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    Form,
    FieldDateRange,
    FieldAutoComplete,
    Option,
} from "src/components/Form";
import { useUsersList } from "./apiMethods";
import { useFilterStyles } from "./utils";

export type ReportsByUsersFormData = {
    date?: {
        start?: Date;
        end?: Date;
    };
    users?: Option<number>[];
};
export const UsersReportsFilter = ({
    onChange,
    defaultValues,
}: {
    defaultValues?: ReportsByUsersFormData;
    onChange: (arg: ReportsByUsersFormData) => void;
}) => {
    const styles = useFilterStyles();
    const { t } = useTranslation(["admin-reports"]);
    const { data: users } = useUsersList();

    return (
        <Paper className={styles.flexContainer} elevation={1}>
            <span className={styles.filterLabel}>
                {t("filterUsersForm.title")}
            </span>
            <Form
                className={styles.form}
                onChange={onChange}
                formConfig={{ defaultValues }}
            >
                <FieldDateRange
                    name="date"
                    label={t("filterUsersForm.labels.date")}
                />
                <FieldAutoComplete
                    name="users"
                    label={t("filterUsersForm.labels.users")}
                    multiple
                    options={users?.map(u => ({
                        label: u.email,
                        value: u.id,
                    }))}
                />
                <Form.ResetButton
                    fullWidth
                    sx={{
                        marginTop: "auto",
                    }}
                >
                    {t("filterUsersForm.resetButton")}
                </Form.ResetButton>
            </Form>
        </Paper>
    );
};
