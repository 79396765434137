import { Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { prettyBytes } from "src/helpers/prettyBytes";
import React from "react";
import { useTranslation } from "react-i18next";
import { LocalFileStorageDTO } from "src/services/generated";

const useStyles = makeStyles((theme: Theme) => ({
    storageChartName: {
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        textAlign: "right",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    storageNameCutting: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    storageChartLabel: {
        color: "rgba(26, 31, 41, 0.38)",
    },
    storageChartValue: {
        color: theme.palette.primary.main,
    },
}));

interface StorageChartNameProps {
    storage: LocalFileStorageDTO;
}

export const StorageChartName: React.FC<StorageChartNameProps> = ({
    storage,
}) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div className={styles.storageChartName}>
            <Tooltip
                title={
                    <span style={{ wordBreak: "break-all" }}>
                        {storage.fullPath}
                    </span>
                }
            >
                <div className={styles.storageNameCutting}>
                    {storage.fullPath}
                </div>
            </Tooltip>
            <div>
                <span className={styles.storageChartLabel}>
                    {t("admin-statistics:storageChart.used")}
                </span>
                <span className={styles.storageChartValue}>
                    {prettyBytes(storage?.usedSpace || 0)}
                </span>
            </div>
            <div>
                <span className={styles.storageChartLabel}>
                    {t("admin-statistics:storageChart.free")}
                </span>
                <span className={styles.storageChartValue}>
                    {prettyBytes(storage?.freeSpace || 0)}
                </span>
            </div>
            <div>
                <span className={styles.storageChartLabel}>
                    {t("admin-statistics:storageChart.all")}
                </span>
                <span className={styles.storageChartValue}>
                    {prettyBytes(storage?.size || 0)}
                </span>
            </div>
        </div>
    );
};
