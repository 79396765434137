import React from "react";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    RadioGroupProps,
} from "@mui/material";
import { Controller } from "../FieldController";
import { UseControllerProps } from "react-hook-form";

type FieldRadioProps = {
    name: string;
    label?: string;
    options: { label: string; value: string | number | boolean }[];
    controllerProps?: Omit<UseControllerProps, "name" | "defaultValue">;
    classNameLabel?: string;
} & Omit<RadioGroupProps, "defaultChecked">;

export const FieldRadio = ({
    name,
    defaultValue,
    options,
    label,
    classNameLabel,
    controllerProps,
    ...inputProps
}: FieldRadioProps) => {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            {...controllerProps}
        >
            {({ field: { onChange, onBlur, value } }) => (
                <FormControl>
                    <FormLabel className={classNameLabel}>{label}</FormLabel>
                    <RadioGroup
                        onChange={v => {
                            onChange(v);
                        }}
                        onBlur={onBlur}
                        value={value}
                        defaultValue={defaultValue}
                        {...inputProps}
                    >
                        {options.map(o => (
                            <FormControlLabel
                                value={o.value}
                                control={<Radio />}
                                label={o.label}
                                key={o.label}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            )}
        </Controller>
    );
};
