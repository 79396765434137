import React from "react";
import { makeStyles } from "@mui/styles";
import { ReactComponent as AlertImg } from "../icons/alert.svg";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { WordTranslationKey, pluralize } from "src/helpers/wordCasesUtil";
import i18n from "src/i18n";

interface LicenseAlertProps {
    licenseTermDays: number;
    children?: React.ReactNode;
}
export const YELLOWAlert = "rgba(255, 206, 34, 1)";
export const REDAlert = "rgba(255, 87, 34, 1)";

const useStyles = makeStyles({
    container: {
        marginTop: "20px",
        padding: "14px 18px",
        borderRadius: 4,
    },
    containerYellow: {
        backgroundColor: "rgba(255, 242, 224, 1)",
        borderLeft: "4px solid rgba(255, 206, 34, 1)",
    },
    containerRed: {
        backgroundColor: "rgba(255, 229, 229, 1)",
        borderLeft: `4px solid rgba(255, 0, 0, 1)`,
    },
});

export const LicenseAlert = (props: LicenseAlertProps) => {
    const styles = useStyles();
    const { licenseTermDays } = props;
    const { t } = useTranslation();
    const message =
        licenseTermDays > 0
            ? t("admin-statistics:licensesChart.valid") +
              licenseTermDays +
              " " +
              t(
                  pluralize(
                      licenseTermDays,
                      WordTranslationKey.DAY,
                      i18n.languages[0].toUpperCase(),
                  ),
              )
            : t("admin-statistics:licensesChart.expired");

    return (
        <Grid
            container
            className={`${styles.container} ${
                licenseTermDays > 0
                    ? styles.containerYellow
                    : styles.containerRed
            }`}
        >
            <Grid item xs={1}>
                <AlertImg fill={licenseTermDays > 0 ? YELLOWAlert : REDAlert} />
            </Grid>
            <Grid item xs={11}>
                {message}
            </Grid>
        </Grid>
    );
};
