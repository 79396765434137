import React, { FC, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "src/components/Form";
import { FilesRequestRetentionPeriod } from "./FilesRequestRetentionPeriod";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../types/redux/store";
import {
    createRequestFileByLink,
    getDefaultRequestFilesByLinkProps,
} from "../../redux/pages/filesRequest/actions";
import { RequestByLinkDTO } from "../../services/generated";

const useStyle = makeStyles({
    requestLinkButton: {
        width: "200px",
    },
    buttonContainer: {
        marginTop: "24px",
    },
});

export const FilesRequestByLink: FC = () => {
    const { t } = useTranslation();
    const styles = useStyle();
    const dispatch = useDispatch();
    const requestByLinkForm = useSelector(
        (store: Store) => store.pages.filesRequestPage.requestByLinkForm,
    );
    const { handleSubmit, reset, control } = useForm<RequestByLinkDTO>({
        defaultValues: {
            daysToLiveRequest: 1,
            daysToLiveFiles: 1,
        },
    });

    useEffect(() => {
        dispatch(getDefaultRequestFilesByLinkProps());
    }, [dispatch]);

    useEffect(() => {
        reset(requestByLinkForm);
    }, [requestByLinkForm, reset]);

    const sendForm = (data: RequestByLinkDTO) => {
        dispatch(createRequestFileByLink(data));
    };

    return (
        <form onSubmit={handleSubmit(sendForm)}>
            <FilesRequestRetentionPeriod control={control} />
            <Grid container className={styles.buttonContainer}>
                <Grid item>
                    <Button
                        type="submit"
                        className={styles.requestLinkButton}
                        color="primary"
                    >
                        {t("request-files:requestLinkButton")}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};
