export const shadow = {
    buttonShadow: {
        default:
            "0px 1px 5px rgba(0, 0, 0, 0.05), 0px 3px 1px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.04)",
        hover: "0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08)",
        active: "0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08)",
    },
    containerShadow: {
        light: "0px 1px 3px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.04)",
        dark: "0px 8px 10px rgba(0, 0, 0, 0.2), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 16px 24px rgba(0, 0, 0, 0.14)",
    },
    headerPaper: {
        light: "0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 10px rgba(0, 0, 0, 0.03), 0px 4px 5px rgba(0, 0, 0, 0.04)",
    },
};

export default shadow;
