import React, { useCallback, useEffect } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { DropDownMenu, DropDownMenuItem } from "src/components/DropDownMenu";
import { MoreVert } from "@mui/icons-material";
import { Upload } from "src/types/Upload";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import {
    loadRecievedFilesInfo,
    loadUploadedFilesInfo,
    setAddFilesUploadId,
    FilesInfoParams,
    incrementDownloadCount,
    getDownloadStateFilesMyFiles,
} from "src/redux/pages/myFiles/actions";
import { FilesFilter } from "src/types/redux/store/pages/MyFilesPage";
import { getMessageDownloadWithHost } from "src/helpers/url";
import { copyText } from "src/helpers/clipboard";
import { downloadAllFileByUser } from "src/helpers/download";
import _ from "lodash";
import { getSumSizeFiles } from "src/helpers/file";
import { NoDataFound } from "src/components/NoDataFound";
import {
    DataGrid,
    ExpandButton,
    ExpandedState,
    PaginationState,
} from "src/components/DataGrid";
import { getColumns } from "./getColumns";
import { EventUploadType, TYPE_EVENT } from "src/pages/MyFiles";
import color from "src/theme/variables/color";
import dayjs from "dayjs";
import { ReceivedMessageResponseDataMessageTypeEnum } from "src/services/generated";
import { Dispatch } from "redux";

const isExpired = (upload: Upload) =>
    dayjs(upload.activeBefore).isBefore(dayjs());

const isExeedDownloadLimit = (upload: Upload) =>
    upload.files?.length > 0 &&
    upload.files?.every(file => file?.downloadLimit === file?.downloadCount);

const isUploadDisabled = (upload: Upload) => {
    const expired = isExpired(upload);

    if (
        upload.messageType ===
        ReceivedMessageResponseDataMessageTypeEnum.RESPONSE_MESSAGE
    ) {
        return expired;
    }
    return isExeedDownloadLimit(upload) || expired;
};

type MyFilesTableProps = {
    setEventUpload: React.Dispatch<React.SetStateAction<EventUploadType>>;
    pagination: PaginationState;
    onPaginationChange: React.Dispatch<React.SetStateAction<PaginationState>>;
    expanded: ExpandedState;
    onExpandedChange: React.Dispatch<React.SetStateAction<ExpandedState>>;
};

export const MyFilesTable = ({
    setEventUpload,
    pagination,
    onPaginationChange,
    expanded,
    onExpandedChange,
}: MyFilesTableProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        uploads,
        searchFilter,
        filesFilter,
        hideInactive,
        totalElements,
        loadingUploads,
        reservedFileId,
        sendingByEmail,
        updatingUpload,
    } = useSelector((store: Store) => store.pages.myFilesPage);

    const canDirectLinksExist = useSelector(
        (store: Store) => store.publicSettings.directLinksEnabled,
    );

    const downloadAllFiles = useCallback(
        (message: Upload) => {
            const totalSize = getSumSizeFiles(message.files);
            downloadAllFileByUser(message.id, totalSize);
            dispatch(incrementDownloadCount(message.id));
        },
        [dispatch],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadFilesInfoDebounce = useCallback(
        _.debounce(
            async (
                filesFilter: FilesFilter,
                filesInfoParams: FilesInfoParams,
            ) => {
                const f =
                    filesFilter === FilesFilter.SENT
                        ? loadUploadedFilesInfo
                        : loadRecievedFilesInfo;
                dispatch(f(filesInfoParams));
            },
            400,
        ),
        [],
    );

    useEffect(() => {
        loadFilesInfoDebounce(filesFilter, {
            page: 0,
            pageSize: pagination.pageSize,
            hideInactive,
            searchText: searchFilter,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesFilter, hideInactive, loadFilesInfoDebounce, searchFilter]);

    useEffect(() => {
        loadFilesInfoDebounce(filesFilter, {
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
            hideInactive,
            searchText: searchFilter,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    useEffect(() => {
        const dispatcher = _.debounce(() => {
            dispatch(getDownloadStateFilesMyFiles({ fileIds: reservedFileId }));
        }, 5000);
        if (reservedFileId.length) {
            dispatcher();
        }
        //TODO update to make less queries
        return () => {
            dispatcher.cancel();
        };
    }, [reservedFileId, dispatch]);

    const isFilesFilterSent = filesFilter === FilesFilter.SENT;

    const isDefaultFilter = searchFilter === "" && !hideInactive;
    return (
        <>
            {!!uploads.length && (
                <DataGrid
                    data={uploads}
                    columns={getColumns(t)}
                    getRowId={row => row.id}
                    enableExpanding
                    onExpandedChange={onExpandedChange}
                    enableRowActions
                    enableColumnActions={false}
                    enableSorting={false}
                    onPaginationChange={onPaginationChange}
                    manualPagination
                    rowCount={totalElements}
                    state={{
                        pagination,
                        expanded,
                        columnVisibility: {
                            downloadCount: isFilesFilterSent,
                            recipients: isFilesFilterSent,
                        },
                        isLoading:
                            sendingByEmail || updatingUpload || loadingUploads,
                    }}
                    getSubRows={row =>
                        // @ts-ignore
                        row.files?.length > 1 ? row.files : null
                    }
                    positionActionsColumn="last"
                    layoutMode="grid"
                    renderRowActions={({ row }) => {
                        return !row.parentId ? (
                            <DropDownMenu
                                anchorElement={MoreVert}
                                items={getDropdownItems(
                                    t,
                                    setEventUpload,
                                    downloadAllFiles,
                                    dispatch,
                                    isFilesFilterSent,
                                    canDirectLinksExist,
                                )}
                                context={row.original}
                            />
                        ) : null;
                    }}
                    muiTableBodyRowProps={({ row }) => {
                        return {
                            sx: {
                                backgroundColor: !!row.parentId
                                    ? color.sendFileColor.background.lightGray
                                    : null,
                                opacity: isUploadDisabled(
                                    row.getParentRow()?.original ||
                                        row.original,
                                )
                                    ? "0.5"
                                    : "1",
                            },
                        };
                    }}
                    displayColumnDefOptions={{
                        "mrt-row-actions": {
                            header: "",
                            size: 68,
                            grow: false,
                        },
                        "mrt-row-expand": {
                            Cell: ({ row, table }) =>
                                row.original.files?.length > 1 ? (
                                    <ExpandButton table={table} row={row} />
                                ) : null,
                            size: 68,
                            grow: false,
                        },
                    }}
                />
            )}
            {!uploads.length && !loadingUploads && (
                <NoDataFound isDefaultFilter={isDefaultFilter} />
            )}
        </>
    );
};

export default MyFilesTable;

const getDropdownItems = (
    t: TFunction,
    setEventUpload: React.Dispatch<React.SetStateAction<EventUploadType>>,
    downloadAllFiles: (message: Upload) => void,
    dispatch: Dispatch<any>,
    isFilesFilterSent: boolean,
    canDirectLinksExist: boolean,
) => {
    const dropDownMenuItemsForSent: DropDownMenuItem<Upload>[] = [
        {
            title: t("my-file:dropDownMenuItems.copyLink"),
            onClick: function (event: React.MouseEvent, context) {
                const copyLink = getMessageDownloadWithHost(
                    context?.id as string,
                );
                copyText(copyLink);
            },
            isHide: context => isUploadDisabled(context),
        },
        {
            title: t("my-file:dropDownMenuItems.directLinks"),
            onClick: function (event: React.MouseEvent, context) {
                setEventUpload({
                    uplaod: context,
                    typeEvent: TYPE_EVENT.DIRECT_LINKS,
                });
            },
            isHide: context =>
                !canDirectLinksExist ||
                isUploadDisabled(context) ||
                context.security,
        },
        {
            title: t("my-file:dropDownMenuItems.delete"),
            onClick: function (event: React.MouseEvent, context) {
                setEventUpload({
                    uplaod: context,
                    typeEvent: TYPE_EVENT.DELETE_FILES,
                });
            },
        },
        {
            title: t("my-file:dropDownMenuItems.download"),
            onClick: function (event: React.MouseEvent, context) {
                downloadAllFiles(context);
            },
            isHide: context => isUploadDisabled(context),
        },
        {
            title: t("my-file:dropDownMenuItems.downloadInc"),
            onClick: function (event: React.MouseEvent, context) {
                setEventUpload({
                    uplaod: context,
                    typeEvent: TYPE_EVENT.DOWNLOAD_INC,
                });
            },
            isHide: context =>
                isExpired(context) ||
                (isExeedDownloadLimit(context) && isExpired(context)),
        },
        {
            title: t("my-file:dropDownMenuItems.prolong"),
            onClick: function (event: React.MouseEvent, context) {
                setEventUpload({
                    uplaod: context,
                    typeEvent: TYPE_EVENT.PROLONG,
                });
            },
            isHide: context =>
                isExeedDownloadLimit(context) ||
                (isExeedDownloadLimit(context) && isExpired(context)),
        },
        {
            title: t("my-file:dropDownMenuItems.add"),
            onClick: (event: React.MouseEvent, context) => {
                dispatch(setAddFilesUploadId(context.id));
            },
            isHide: context => isUploadDisabled(context),
        },
        {
            title: t("my-file:dropDownMenuItems.forward"),
            onClick: (event: React.MouseEvent, context) => {
                setEventUpload({
                    uplaod: context,
                    typeEvent: TYPE_EVENT.FORWARD,
                });
            },
            isHide: context => isUploadDisabled(context),
        },
    ];

    const dropDownMenuItemsForReceived: DropDownMenuItem<Upload>[] = [
        {
            title: t("my-file:dropDownMenuItems.followLink"),
            onClick: function (event: React.MouseEvent, context) {
                const link = document.createElement("a");
                link.setAttribute("href", `/responseMessage/${context?.id}`);
                link.click();
            },
            isHide: context => isExpired(context),
        },
        {
            title: t("my-file:dropDownMenuItems.delete"),
            onClick: function (event: React.MouseEvent, context) {
                setEventUpload({
                    uplaod: context,
                    typeEvent: TYPE_EVENT.DELETE_FILES,
                });
            },
        },
    ];

    return isFilesFilterSent
        ? dropDownMenuItemsForSent
        : dropDownMenuItemsForReceived;
};
