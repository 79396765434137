import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { MailSettingsDTO } from "src/services/generated";
import { MailSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getSettingsMail, updateSettingsMail } from "./actions";

const mailSettingsInitialState: MailSettingsPage = {
    loading: false,
};

export const mailSettingsReducer = createReducer<MailSettingsPage>(
    mailSettingsInitialState,
    {
        [getSettingsMail.pending.type](state) {
            state.loading = true;
        },
        [getSettingsMail.fulfilled.type](
            state,
            action: PayloadAction<MailSettingsDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [getSettingsMail.rejected.type](state) {
            state.loading = false;
        },
        [updateSettingsMail.pending.type](state) {
            state.loading = true;
        },
        [updateSettingsMail.fulfilled.type](state) {
            state.loading = false;
        },
        [updateSettingsMail.rejected.type](state) {
            state.loading = false;
        },
    },
);
