import {
    MessageModel,
    MessageModelSendingTypeEnum,
} from "src/services/generated";
import { Upload } from "src/types/Upload";

export function mapUploadToMessageModel(upload: Upload): MessageModel {
    return {
        id: upload.id,
        createDateTime: upload.dispatchDate,
        downloadLimit: undefined,
        expireTime: upload.activeBefore,
        files: upload.files,
        password: undefined,
        recipients: upload.recipients,
        sender: upload.sender,
        sendingType: upload.messageType as MessageModelSendingTypeEnum,
        status: undefined,
        subject: undefined,
        text: "",
        url: undefined,
        viewCount: undefined,
    };
}
