import { CSSInterpolation, CSSObject } from "@mui/material/styles";

export const MuiCssBaseline:
    | string
    | CSSObject
    | ((theme: unknown) => CSSInterpolation) = {
    body: {
        fontSize: "0.875rem",
        lineHeight: 1.43,
        letterSpacing: "0.01071em",
    },
};
