import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";

type IconStatuses = "ERROR" | "SUCCESS" | "WARNING";

const VirusCheckStatusIcon = (props: any & { status: IconStatuses }) => {
    const innerIcon = (status: IconStatuses, color: string) => {
        switch (status) {
            case "ERROR": {
                return (
                    <CloseIcon style={{ color: color }} viewBox="-7 -7 39 39" />
                );
            }
            case "SUCCESS": {
                return (
                    <CheckIcon style={{ color: color }} viewBox="-7 -7 39 39" />
                );
            }
            case "WARNING": {
                return (
                    <PriorityHighRoundedIcon
                        style={{ color: color }}
                        viewBox="-7 -7 39 39"
                    />
                );
            }
        }
    };
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.7">
                <path
                    d="M19.3419 6.03995L11.8429 2.04395C11.7883 2.01476 11.7272 1.99966 11.6652 2.00001C11.6032 2.00036 11.5423 2.01614 11.4879 2.04595L4.19693 6.04195C4.13824 6.07405 4.08927 6.12134 4.05514 6.17887C4.02101 6.2364 4.00297 6.30205 4.00293 6.36895V11.5889C4.00508 13.6887 4.61888 15.7424 5.76932 17.4989C6.91976 19.2555 8.55699 20.6388 10.4809 21.4799L11.4929 21.9209C11.5398 21.9413 11.5903 21.9518 11.6414 21.9518C11.6925 21.9518 11.7431 21.9413 11.7899 21.9209L12.9299 21.4289C14.8885 20.6065 16.5606 19.2241 17.7365 17.4551C18.9124 15.686 19.5398 13.6092 19.5399 11.4849V6.36995C19.54 6.30214 19.5217 6.23558 19.4868 6.17744C19.4519 6.1193 19.4018 6.07176 19.3419 6.03995ZM18.7929 11.4849C18.7929 15.5289 16.3659 19.1769 12.6379 20.7419L12.6349 20.7439L11.6419 21.1719L10.7789 20.7959C8.98807 20.013 7.46409 18.7253 6.39322 17.0902C5.32235 15.4551 4.75097 13.5435 4.74893 11.5889V6.58995L11.6699 2.79795L18.7929 6.59295V11.4849Z"
                    fill={props.color}
                />
                {innerIcon(props.status, props.color)}
            </g>
        </svg>
    );
};

export default VirusCheckStatusIcon;
