import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { settingsApiService } from "src/services/clientApi";
import { MailSettingsDTO } from "src/services/generated";

export const getSettingsMail = createAsyncThunk(
    "getSettingsMail",
    async (args, thankAPI) => {
        try {
            return (await settingsApiService.getMailConfiguration()).data;
        } catch (error) {
            enqueueError(i18n.t("admin-settings:settingsEMAIL.errors.get"));
            thankAPI.rejectWithValue(error);
        }
    },
);

export const updateSettingsMail = createAsyncThunk(
    "updateSettingsMail",
    async (mailSettingsDTO: MailSettingsDTO, thankAPI) => {
        try {
            const response =
                await settingsApiService.updateMailConfiguration(
                    mailSettingsDTO,
                );
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
            return response.data;
        } catch (error) {
            enqueueError(i18n.t("admin-settings:settingsEMAIL.errors.update"));
            thankAPI.rejectWithValue(error);
        }
    },
);
