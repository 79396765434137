import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { LogFileSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getSettingsLogFile, updateSettingsLogFile } from "./actions";
import { LogFileSettings } from "src/services/generated";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import i18n from "src/i18n";

const logFileSettingsInitialState: LogFileSettingsPage = {
    form: {},
    loading: false,
};

export const logFileSettingsReducer = createReducer<LogFileSettingsPage>(
    logFileSettingsInitialState,
    {
        [getSettingsLogFile.pending.type](state) {
            state.loading = true;
        },
        [getSettingsLogFile.fulfilled.type](
            state,
            action: PayloadAction<LogFileSettings>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [getSettingsLogFile.rejected.type](state) {
            state.loading = false;
            enqueueError(i18n.t("admin-settings:error.get"));
        },
        [updateSettingsLogFile.pending.type](state) {
            state.loading = true;
        },
        [updateSettingsLogFile.fulfilled.type](
            state,
            action: PayloadAction<LogFileSettings>,
        ) {
            state.loading = false;
            state.form = action.payload;
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
        },
        [updateSettingsLogFile.rejected.type](state) {
            state.loading = false;
            enqueueError(i18n.t("admin-settings:settingsEMAIL.errors.update"));
        },
    },
);
