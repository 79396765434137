export type CommonDialogType = {
    dialog: DialogItem;
    dialogChain: Array<string>;
};

export type DialogItem = {
    dialogName: string;
    isOpen: boolean;
};

export enum DialogNames {
    EDITUSER = "EDITUSER",
    USERFILESREPORTDIALOG = "USERFILESREPORTDIALOG",
}

export enum FileUploadFailedDialogNames {
    VIRUS_CHECK_ERROR = "viruscheckerror",
    FILE_SIZE_ERROR = "filesizeerror",
    FILE_STATUS_UNDEFINED = "undefinedstatus",
}
