import i18n, { LanguageDetectorModule } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import { Languages } from "./types/Languages";

const defaultLanguage = Languages.EN;

export const localStorageKey = "i18nextLng";

const languageDetector: LanguageDetectorModule = {
    type: "languageDetector",
    detect: () => {
        const lng =
            localStorage.getItem(localStorageKey) ||
            navigator.language.slice(0, 2).toUpperCase();
        if (Object.values(Languages).includes(lng as any)) {
            return lng;
        }
        return defaultLanguage;
    },
    init: () => {},
    cacheUserLanguage: lng => {
        localStorage.setItem(localStorageKey, lng);
    },
};

i18n.use(initReactI18next)
    .use(Backend)
    .use(languageDetector)
    .init({
        ns: [
            "admin",
            "admin-settings",
            "admin-files",
            "admin-events",
            "admin-reports",
            "admin-logFiles",
            "admin-users",
            "admin-statistics",
            "admin-storages",
            "common",
            "my-file",
            "send-file",
            "support",
            "about",
            "login",
            "security",
            "security-dispatches",
            "security-permission-list",
            "request-files",
            "download-files",
            "response-message",
            "pagination",
            "date-picker",
        ],
        load: "languageOnly",
        react: {
            useSuspense: true,
        },
        fallbackLng: Languages.RU,
    });

export default i18n;
