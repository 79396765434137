import { makeStyles, withStyles } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    CircularProgress,
    IconButton,
    InputLabel,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Store } from "src/types/redux/store/index";
import { changeLogo, changeMobileLogo } from "src/redux/logo/actions";
import { ReactComponent as AddCircleIcon } from "src/icons/addCircle.svg";
import { AppLogoInfo } from "src/types/redux/store/AppLogoInfo";
import { Logo } from "src/components/Logo";
import { MobileLogo } from "src/components/MobileLogo";
import { enqueueError } from "src/helpers/enqueueError";
import { Theme } from "@mui/material/styles";

const useStyle = makeStyles((theme: Theme) => ({
    title: {
        fontSize: 11,
        lineHeight: "13px",
        opacity: 0.6,
        marginBottom: 8,
    },
    addFileBlock: {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "14px",
        fontFamily: "Roboto",
        display: "flex",
        alignItems: "center",
        marginTop: 8,
    },
    logoBlock: {
        marginBottom: 28,
    },
    label: {
        opacity: 1,
    },
    clickLabel: {
        marginLeft: 8,
    },
    logoContainer: {
        marginBottom: 4,
        display: "flex",
        alignItems: "center",
    },
    logoName: {
        marginLeft: 12,
        fontSize: 12,
        lineHeight: "14px",
        fontFamily: "Roboto",
        fontStyle: "italic",
        textDecoration: "underline",
    },
    hint: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14px",
        fontFamily: "Roboto",
        opacity: 0.6,
    },
    loaderContainer: {
        width: 24,
    },
    container: {
        [theme.breakpoints.down("xs")]: {
            padding: "16px",
        },
    },
}));

const StyledIconButton = withStyles({
    root: {
        height: "24px",
        width: "24px",
        padding: "0",
    },
})(IconButton);

const StyledInputLabel = withStyles({
    root: {
        height: "24px",
        width: "24px",
        padding: "0",
        cursor: "pointer",
    },
})(InputLabel);

const acceptFormats = ".jpeg,.jpg,.png,.tiff,.webp";
const maxSizeLogoInBytes = 1024 * 1024;

export const LogoSettings = () => {
    const dispatch = useDispatch();
    const styles = useStyle();
    const { t } = useTranslation();
    const { appLogoInfo, uploadingLogo, uploadingMobileLogo } = useSelector<
        Store,
        AppLogoInfo
    >(store => store.appLogoInfo);

    const onChangeLogo = (e: any) => {
        if (e.target.files[0].size > maxSizeLogoInBytes) {
            enqueueError(t("admin-settings:logoSettings.fileSizeExceeded"));
            return;
        }
        dispatch(changeLogo(e.target.files[0]));
    };

    const onChangeMobileLogo = (e: any) => {
        if (e.target.files[0].size > maxSizeLogoInBytes) {
            enqueueError(t("admin-settings:logoSettings.fileSizeExceeded"));
            return;
        }
        dispatch(changeMobileLogo(e.target.files[0]));
    };

    return (
        <div className={styles.container}>
            <Typography variant="h6">
                {t("admin-settings:settingsType.logoSettings")}
            </Typography>

            <div className={styles.logoBlock}>
                <div className={styles.title}>
                    {t("admin-settings:logoSettings.logoInfo")}
                </div>
                <div className={styles.logoContainer}>
                    <Logo />
                    <div className={styles.logoName}>
                        {appLogoInfo?.logoName}
                    </div>
                </div>
                <div className={styles.hint}>
                    {t("admin-settings:logoSettings.formatHint")}
                </div>
                <div className={styles.hint}>
                    {t("admin-settings:logoSettings.formatWarningHint")}
                </div>
                <div className={styles.hint}>
                    {t("admin-settings:logoSettings.fileSizeHint")}
                </div>
                <div className={styles.addFileBlock}>
                    {!uploadingLogo && (
                        <StyledIconButton>
                            <input
                                id="logoFileInput"
                                name="logo"
                                type="file"
                                style={{ display: "none" }}
                                onChange={onChangeLogo}
                                accept={acceptFormats}
                            />
                            <StyledInputLabel
                                className={styles.label}
                                htmlFor="logoFileInput"
                            >
                                <AddCircleIcon />
                            </StyledInputLabel>
                        </StyledIconButton>
                    )}
                    {uploadingLogo && (
                        <div className={styles.loaderContainer}>
                            <CircularProgress size={21} />
                        </div>
                    )}
                    <label
                        className={styles.clickLabel}
                        htmlFor="logoFileInput"
                    >
                        {t("admin-settings:logoSettings.upload")}
                    </label>
                </div>
            </div>

            <div>
                <div className={styles.title}>
                    {t("admin-settings:logoSettings.mobileLogoInfo")}
                </div>
                <div className={styles.logoContainer}>
                    <MobileLogo />
                    <div className={styles.logoName}>
                        {appLogoInfo?.logoMobileName}
                    </div>
                </div>
                <div className={styles.hint}>
                    {t("admin-settings:logoSettings.formatHint")}
                </div>
                <div className={styles.hint}>
                    {t("admin-settings:logoSettings.formatWarningHint")}
                </div>
                <div className={styles.hint}>
                    {t("admin-settings:logoSettings.fileSizeHint")}
                </div>
                <div className={styles.addFileBlock}>
                    {!uploadingMobileLogo && (
                        <StyledIconButton>
                            <input
                                id="mobileLogoFileInput"
                                name="mobileLogo"
                                type="file"
                                style={{ display: "none" }}
                                onChange={onChangeMobileLogo}
                                accept={acceptFormats}
                            />
                            <StyledInputLabel
                                className={styles.label}
                                htmlFor="mobileLogoFileInput"
                            >
                                <AddCircleIcon />
                            </StyledInputLabel>
                        </StyledIconButton>
                    )}
                    {uploadingMobileLogo && (
                        <div className={styles.loaderContainer}>
                            <CircularProgress size={21} />
                        </div>
                    )}
                    <label
                        className={styles.clickLabel}
                        htmlFor="mobileLogoFileInput"
                    >
                        {t("admin-settings:logoSettings.upload")}
                    </label>
                </div>
            </div>
        </div>
    );
};
