import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { EndingOrExpiredLicenseMailHeadersDTO } from "src/services/generated";
import {
    getLicenseExpiredMessageHeaders,
    updateLicenseExpiredMessageHeaders,
} from "./actions";
import { LicenseExpiredMessageSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";

const licenseExpiredMessageSettingsInitialState: LicenseExpiredMessageSettingsPage =
    {
        loading: false,
        form: {
            titleEN: "",
            bodyEN: "",
            expiredBodyEN: "",
            titleRU: "",
            bodyRU: "",
            expiredBodyRU: "",
        },
    };

export const licenseExpiredMessageSettingsReducer =
    createReducer<LicenseExpiredMessageSettingsPage>(
        licenseExpiredMessageSettingsInitialState,
        {
            [getLicenseExpiredMessageHeaders.pending.type](state) {
                state.loading = true;
            },
            [getLicenseExpiredMessageHeaders.fulfilled.type](
                state,
                action: PayloadAction<EndingOrExpiredLicenseMailHeadersDTO>,
            ) {
                state.loading = false;
                state.form = action.payload;
            },
            [getLicenseExpiredMessageHeaders.rejected.type](state) {
                state.loading = false;
            },
            [updateLicenseExpiredMessageHeaders.pending.type](state) {
                state.loading = true;
            },
            [updateLicenseExpiredMessageHeaders.fulfilled.type](state) {
                state.loading = false;
            },
            [updateLicenseExpiredMessageHeaders.rejected.type](state) {
                state.loading = false;
            },
        },
    );
