import React, { useMemo } from "react";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
    Form,
    FieldDateRange,
    FieldAutoComplete,
    FieldInput,
    FieldInputRange,
    FieldSelect,
    FieldCheckbox,
    Option,
    FieldCheckboxProps,
} from "src/components/Form";
import { FieldLifeTime, FieldLifeTimeProps } from "./FieldLifeTime";
import { useEmailsList } from "./apiMethods";
import {
    ReportsFileDTOActionEnum,
    ReportsFilesRequestParamsFileReportStatusesEnum,
} from "src/services/generated";
import { useFilterStyles } from "./utils";

export type FilesReportsFormData = {
    fileName?: string;
    date?: {
        start?: Date;
        end?: Date;
    };
    emails?: Option<string>[];
    actions?: Option<string>[];
    theme?: string;
    text?: string;
    size?: {
        from?: number;
        to?: number;
    };
    lifetime?: FieldLifeTimeProps["defaultValue"];
    fileStatus?: FieldCheckboxProps["defaultValue"];
};

type FilesReportsFilterProps = {
    defaultValues?: FilesReportsFormData;
    onChange: (arg: FilesReportsFormData) => void;
};
export const FilesReportsFilter = ({
    onChange,
    defaultValues,
}: FilesReportsFilterProps) => {
    const styles = useFilterStyles();
    const { t } = useTranslation(["admin-reports"]);
    const { data: emails } = useEmailsList();
    const actionsOpts = useMemo(
        () =>
            Object.keys(ReportsFileDTOActionEnum).map(type => ({
                label: t(`filterFilesForm.labels.actions.options.${type}`),
                value: type,
            })),
        [t],
    );
    const statusOpts = useMemo(
        () =>
            Object.keys(ReportsFilesRequestParamsFileReportStatusesEnum).map(
                type => ({
                    label: t(
                        `filterFilesForm.labels.fileStatus.options.${type}`,
                    ),
                    value: type,
                }),
            ),
        [t],
    );
    return (
        <Paper className={styles.flexContainer} elevation={1}>
            <span className={styles.filterLabel}>
                {t("filterFilesForm.title")}
            </span>
            <Form
                formConfig={{
                    defaultValues,
                }}
                className={styles.form}
                onChange={onChange}
            >
                <FieldInput
                    name="fileName"
                    label={t("filterFilesForm.labels.fileName")}
                />
                <FieldDateRange
                    name="date"
                    label={t("filterFilesForm.labels.date")}
                />
                <FieldAutoComplete
                    name="emails"
                    label={t("filterFilesForm.labels.emails")}
                    multiple
                    options={emails?.map(e => ({
                        label: e,
                        value: e,
                    }))}
                />
                <FieldSelect
                    name="actions"
                    label={t("filterFilesForm.labels.actions.title")}
                    multiple
                    options={actionsOpts}
                />
                <FieldInput
                    name="theme"
                    label={t("filterFilesForm.labels.theme")}
                />
                <FieldInput
                    name="text"
                    label={t("filterFilesForm.labels.text")}
                />
                <FieldInputRange
                    name="size"
                    labels={{
                        from: t("filterFilesForm.labels.size.from"),
                        to: t("filterFilesForm.labels.size.to"),
                    }}
                    min={0}
                />
                <FieldLifeTime
                    name="lifetime"
                    labels={{
                        title: t("filterFilesForm.labels.lifetime.title"),
                        actual: t("filterFilesForm.labels.lifetime.actual"),
                        actualDate: t(
                            "filterFilesForm.labels.lifetime.actualDate",
                        ),
                        notActual: t(
                            "filterFilesForm.labels.lifetime.notActual",
                        ),
                    }}
                />
                <FieldCheckbox
                    name="fileStatus"
                    label={t("filterFilesForm.labels.fileStatus.title")}
                    options={statusOpts}
                />
                <Form.ResetButton
                    fullWidth
                    sx={{
                        marginTop: "auto",
                    }}
                >
                    {t("filterFilesForm.resetButton")}
                </Form.ResetButton>
            </Form>
        </Paper>
    );
};
