import { MRT_TableInstance } from "material-react-table";
import React, { useMemo } from "react";

export const SelectedRows = <T,>({
    table,
}: {
    table: MRT_TableInstance<T>;
}) => {
    const {
        getState,
        getPrePaginationRowModel,
        options: { enableRowSelection, rowCount, localization },
    } = table;
    const { rowSelection } = getState();

    const selectedRowCount = useMemo(
        () => Object.values(rowSelection).filter(Boolean).length,
        [rowSelection],
    );

    const selectedAlert =
        selectedRowCount > 0
            ? localization.selectedCountOfRowCountRowsSelected
                  ?.replace(
                      "{selectedCount}",
                      selectedRowCount.toLocaleString(),
                  )
                  ?.replace(
                      "{rowCount}",
                      (
                          rowCount ?? getPrePaginationRowModel().rows.length
                      ).toString(),
                  )
            : null;

    if (!enableRowSelection) return null;

    return <span>{selectedAlert}</span>;
};
