import React from "react";
import { ClosingDialogTitle } from "src/components/ModalWindows/СlosingDialogTitle";
import { Button, Dialog, DialogActions, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IncreaseDownloadLimitDTO } from "src/services/generated";
import { useDispatch } from "react-redux";
import { increaseDownloadLimit } from "src/redux/pages/myFiles/actions";
import styled from "@emotion/styled";
import { FieldInput, Form } from "src/components/Form";
import { BaseDialogProps } from "src/pages/MyFiles";

const MIN_INC_DOWNLOAD_LIMIT = 1;
const MAX_INC_DOWNLOAD_LIMIT = 1000;

const defaultValues = {
    downloadLimit: 5,
};

export const DialogDownloadInc = ({
    open,
    onClose,
    upload,
}: BaseDialogProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onSubmit = (downloadLimit: IncreaseDownloadLimitDTO) => {
        dispatch(
            increaseDownloadLimit({ messageId: upload.id, downloadLimit }),
        );
        onClose();
    };
    return (
        <Dialog
            PaperProps={{ sx: { width: "440px" } }}
            open={open}
            onClose={onClose}
        >
            <ClosingDialogTitle onClose={onClose}>
                {t("my-file:downloadIncDialog.title")}
            </ClosingDialogTitle>

            <Form
                onSubmit={onSubmit}
                formConfig={{
                    defaultValues,
                }}
            >
                <Info>
                    {t("my-file:downloadIncDialog.form.info", {
                        count: upload?.files?.[0].downloadLimit,
                    })}
                </Info>
                <FieldInput
                    name="downloadLimit"
                    label={t("my-file:downloadIncDialog.form.downloadLimit")}
                    type="number"
                    inputProps={{
                        min: MIN_INC_DOWNLOAD_LIMIT,
                        max: MAX_INC_DOWNLOAD_LIMIT,
                    }}
                />
                <DialogActions>
                    <Button onClick={onClose} color="secondary">
                        {t("my-file:downloadIncDialog.cancel")}
                    </Button>
                    <Button type="submit" color="primary">
                        {t("my-file:downloadIncDialog.confirm")}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
};

const Info = styled(Box)`
    text-align: left;
    font-size: 14px;
    color: #1a1f29;
    font-weight: 500;
    margin-bottom: 8px;
`;
