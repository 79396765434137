import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    ResponseMessagePage,
    ResponseMessagePageState,
} from "src/types/redux/store/pages/ResponseMessage";
import {
    createResponseMessage,
    createReserveFileUUIDInResponseMessage,
    deleteCurrentFileFromResponseMessage,
    sendResponseMessageForm,
    uploadCurrentFileToResponseMessage,
    handleFailFileUpload,
    changeFileUploadProgressAction,
    ChangeFileUploadProgressActionType,
    updateStateFromFormData,
    EmailTextAndSubject,
    getDownloadVolumeValuesFromResponseMessage,
    getDownloadStateFilesResponseMessage,
} from "./actions";
import {
    ResponseMessageDTO,
    FileDTO,
    FileUploadProgressbarResponse,
    FileStatus,
    FileStateResponse,
    FileError,
} from "src/services/generated";
import {
    checkIfFileInIntermediateState,
    fireFileWasNotUploadedPopup,
    isSuccessFile,
} from "src/helpers/file";

const initialState: ResponseMessagePage = {
    uploadedFileCount: 0,
    responseMessagePageState: ResponseMessagePageState.RESPONSE_MESSAGE_EDIT,
    inProgress: false,
    linkExpired: false,
    responseMessageMemory: { storageFreeSpace: 0, uploadedBeforeSize: 0 },
    reservedFileId: [],
};

export const responseMessageReducer = createReducer<ResponseMessagePage>(
    initialState,
    {
        [createResponseMessage.pending.type](state) {
            state.inProgress = true;
        },
        [createResponseMessage.fulfilled.type](
            state,
            action: PayloadAction<ResponseMessageDTO>,
        ) {
            state.responseMessage = action.payload;
            state.linkExpired = false;
            state.inProgress = false;
        },
        [createResponseMessage.rejected.type](state) {
            state.linkExpired = true;
            state.inProgress = false;
        },
        [sendResponseMessageForm.pending.type](state) {
            state.inProgress = true;
        },
        [sendResponseMessageForm.rejected.type](state) {
            state.inProgress = false;
        },
        [sendResponseMessageForm.fulfilled.type](state) {
            state.responseMessagePageState =
                ResponseMessagePageState.AFTER_RESPONSE_MESSAGE_EDITING;
            state.inProgress = false;
        },
        [uploadCurrentFileToResponseMessage.fulfilled.type](
            state,
            action: PayloadAction<FileDTO>,
        ) {
            if (!action.payload || !state.responseMessage) {
                return;
            }

            const currentFileIndex =
                state.responseMessage.responseFiles?.findIndex(file => {
                    return file.id === action.payload.id;
                });
            if (currentFileIndex === -1) {
                return;
            }
            state.responseMessage.responseFiles![currentFileIndex!] = {
                id: action.payload.id!,
                name: action.payload.name,
                size: action.payload.size,
                status: action.payload.status,
                error: action.payload.error,
            };
            state.uploadedFileCount++;
            if (checkIfFileInIntermediateState(action.payload)) {
                state.reservedFileId.push(action.payload.id);
            }
        },
        [createReserveFileUUIDInResponseMessage.type](
            state,
            action: PayloadAction<FileDTO>,
        ) {
            if (
                state.responseMessage &&
                !state.responseMessage.responseFiles === undefined
            ) {
                state.responseMessage.responseFiles = [];
            }
            const file = {
                id: action.payload.id,
                name: action.payload.name,
                size: action.payload.size,
                status: action.payload.status,
                error: action.payload.error,
            };
            state.responseMessage?.responseFiles?.unshift(file);
        },
        [deleteCurrentFileFromResponseMessage.fulfilled.type](
            state,
            action: PayloadAction<string>,
        ) {
            const currentFileId =
                state.responseMessage?.responseFiles?.findIndex(
                    file => file.id === action.payload,
                );
            if (currentFileId !== undefined) {
                state.responseMessage?.responseFiles?.splice(currentFileId, 1);
                const successFileCount =
                    state.responseMessage?.responseFiles?.filter(isSuccessFile)
                        .length;
                state.uploadedFileCount = successFileCount;
            }
            const currentReservedFileIdIndex = state.reservedFileId?.findIndex(
                id => id === action.payload,
            );
            if (
                currentReservedFileIdIndex !== undefined &&
                currentReservedFileIdIndex !== -1
            ) {
                state.reservedFileId?.splice(currentReservedFileIdIndex, 1);
            }
        },
        [changeFileUploadProgressAction.type](
            state,
            action: PayloadAction<ChangeFileUploadProgressActionType>,
        ) {
            const file = state.responseMessage?.responseFiles?.find(
                file => action.payload.fileId === file.id,
            );
            if (file) {
                (file as any).progress = action.payload.progress;
            }
        },
        [handleFailFileUpload.type](state, action: PayloadAction<string>) {
            if (state.responseMessage === undefined) return;
            const currentFileIndex =
                state.responseMessage.responseFiles?.findIndex(file => {
                    return file.id === action.payload;
                });
            if (currentFileIndex) {
                state.responseMessage.responseFiles!.slice(currentFileIndex, 1);
            }
        },
        [updateStateFromFormData.type](
            state,
            action: PayloadAction<EmailTextAndSubject>,
        ) {
            if (state.responseMessage === undefined) return;
            state.responseMessage.messageText = action.payload.messageText;
            state.responseMessage.subject = action.payload.subject;
        },
        [getDownloadVolumeValuesFromResponseMessage.fulfilled.type](
            state,
            action: PayloadAction<FileUploadProgressbarResponse>,
        ) {
            state.responseMessageMemory = action.payload;
        },
        [getDownloadStateFilesResponseMessage.fulfilled.type](
            state,
            action: PayloadAction<FileStateResponse>,
        ) {
            const files = state.responseMessage?.responseFiles?.filter(
                file =>
                    action.payload.files[file.id] &&
                    file.id === action.payload.files[file.id].id,
            );
            if (files?.length) {
                files.map(file => {
                    file.status = action.payload.files[file.id]
                        .status as FileStatus;
                    file.error = action.payload.files[file.id]
                        .error as FileError;
                    return file;
                });
                files
                    .filter(file => file.error === FileError.INFECTED)
                    .forEach(file => fireFileWasNotUploadedPopup(file.name));
            }
            state.reservedFileId = state.reservedFileId.filter(id =>
                checkIfFileInIntermediateState(action.payload.files[id]),
            );
        },
    },
);
