import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { Preloader } from "src/components/Preloader";
import { REDIRECT_URL_KEY } from "src/constants/localstorageKeys";
import { ErrorCodes, ErrorPage } from "src/pages/ErrorPage/ErrorPage";
import { loginViaRefreshToken, logOut } from "src/redux/pages/login/action";
import { UserModelRolesEnum } from "src/services/generated";
import { Store } from "src/types/redux/store";
import { routePaths } from "./routePaths";

const useStyle = makeStyles(() => ({
    preloader: {
        marginTop: 24,
    },
}));

interface PrivateRouteProps extends RouteProps {
    roles?: UserModelRolesEnum[];
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const { roles } = props;
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const styles = useStyle();
    const validationLoading = useSelector(
        (store: Store) => store.authenticationHandler.validationLoading,
    );
    const { user, accessToken, refreshToken } = useSelector(
        (store: Store) => store.authenticationHandler.authentication,
    );

    const loginPath = "/login";
    const sendFilePath = "/sendfile";

    const redirectUrl = () => {
        const redirectUrl = sessionStorage.getItem(REDIRECT_URL_KEY);
        if (user && refreshToken && !accessToken) {
            dispatch(loginViaRefreshToken(refreshToken));
            return <Redirect to={pathname} />;
        }
        if (user && accessToken && redirectUrl) {
            sessionStorage.removeItem(REDIRECT_URL_KEY);
            return <Redirect to={redirectUrl} />;
        }
        if (user && pathname === loginPath) {
            // todo remove?
            return <Redirect to={sendFilePath} />;
        }
        if (user && !accessToken && !refreshToken) {
            dispatch(logOut());
        }
        // Если не залогиненый идет на приватный урл, редирект на логин
        if ((!user || !accessToken) && pathname !== routePaths.login) {
            return (
                <Redirect
                    to={`${routePaths.login}${
                        pathname !== routePaths.root
                            ? `?redirectUrl=${pathname}`
                            : ""
                    }`}
                />
            );
        }
        if (roles) {
            if (!user?.roles) {
                return null;
            }
            if (validationLoading) {
                return <Preloader className={styles.preloader} size={40} />;
            }
            if (roles.some(role => !user.roles?.includes(role))) {
                return <ErrorPage errorType={ErrorCodes.FORBIDDEN} />;
            }
        }
    };

    return <Route {...props}>{redirectUrl() || props.children}</Route>;
};
