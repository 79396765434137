import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { useParams } from "react-router-dom";
import { getMessageForDownloading } from "../redux/pages/downloadPage/actions";
import { makeStyles } from "@mui/styles";
import { DownloadFileView } from "../components/DownloadFiles/DownloadFilesPreview";
import { FilesSecurePassword } from "../components/FilesSecurePassword";
import { ErrorPage } from "./ErrorPage/ErrorPage";
import { useTranslation } from "react-i18next";
import { Preloader } from "src/components/Preloader";

const useStyle = makeStyles({
    container: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        minWidth: 0,
        marginTop: "80px",
        padding: "0 16px",
    },
    content: {
        width: "428px",
        minWidth: 0,
    },
});

export const DownloadPage: FC<{ id?: string }> = ({ id }) => {
    const styles = useStyle();
    const { messageId } = useParams<{ messageId: string }>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { inProgress, downloadMessage, error, password } = useSelector(
        (store: Store) => store.pages.downloadPage,
    );
    const messageIds = messageId || id;
    useEffect(() => {
        dispatch(getMessageForDownloading({ messageId: messageIds, password }));
    }, [dispatch, password, messageIds]);

    const isDownloadingPermitted =
        Array.isArray(downloadMessage.files) || !downloadMessage.protected;

    if (error) {
        return <ErrorPage text={t("")} />;
    }

    return (
        <div className={styles.container}>
            {inProgress && <Preloader overDocument size={40} />}
            {isDownloadingPermitted ? (
                <DownloadFileView
                    className={styles.content}
                    messageId={messageIds}
                    password={password}
                />
            ) : (
                <FilesSecurePassword />
            )}
        </div>
    );
};
