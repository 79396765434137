import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { calculateTimeToExpire, ONE_MINUTE_IN_MS } from "src/helpers/dateUtils";
import { pluralize, WordTranslationKey } from "src/helpers/wordCasesUtil";
import { useAutoUpdate } from "src/hooks/autoUpdate";

const useStyle = makeStyles({
    containerToExpireInfo: {
        fontSize: "12px",
        padding: "16px 0",
    },
    grayText: {
        color: "rgba(0, 0, 0, 0.6)",
    },
    redText: {
        color: "#eb4034",
    },
});

type TimeToExpireInfoProps = {
    isExpiredTime: boolean;
    time: string;
};

export const TimeToExpireInfo = (props: TimeToExpireInfoProps) => {
    const { time, isExpiredTime } = props;
    const { t, i18n } = useTranslation();
    const styles = useStyle();
    const [timeToExpire, setTimeToExpire] = useState(
        calculateTimeToExpire(time),
    );

    useAutoUpdate(() => {
        setTimeToExpire(calculateTimeToExpire(time));
    }, ONE_MINUTE_IN_MS);

    return (
        <div className={styles.containerToExpireInfo}>
            {!isExpiredTime ? (
                <div className={styles.grayText}>
                    {`${t("download-files:timeToExpire", {
                        timeToExpire: `${timeToExpire.days} ${t(
                            pluralize(
                                timeToExpire.days,
                                WordTranslationKey.DAY,
                                i18n.languages[0].toUpperCase(),
                            ),
                        )} 
           ${timeToExpire.hours} ${t(
               pluralize(
                   timeToExpire.hours,
                   WordTranslationKey.HOUR,
                   i18n.languages[0].toUpperCase(),
               ),
           )} 
           ${timeToExpire.minutes} ${t(
               pluralize(
                   timeToExpire.minutes,
                   WordTranslationKey.MINUTE,
                   i18n.languages[0].toUpperCase(),
               ),
           )}`,
                    })}`}
                </div>
            ) : (
                <div className={styles.redText}>
                    {t("download-files:messageExpired")}
                </div>
            )}
        </div>
    );
};
