import { Button, Grid, Theme } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";

import { FindInput } from "../FindInput";
import { Preloader } from "../Preloader";

import { routePaths } from "src/routes/routePaths";
import { openDialog } from "src/redux/dialogs/commonDialog/actions";
import { Store } from "src/types/redux/store";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        storageLinkPanel: {
            [theme.breakpoints.down("xs")]: {
                padding: "12px 8px",
            },
        },
        reorderSpinner: {
            marginLeft: 16,
        },
        width100: {
            width: "100%",
        },
    }),
);

type StorageFilterProps = {
    handleStoragesFilterChange: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;
};

export const StorageFilter = ({
    handleStoragesFilterChange,
}: StorageFilterProps) => {
    const { t } = useTranslation();
    const styles = useStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(
        (store: Store) =>
            store.pages.administration.administrationSettings
                .generalSettingsPage.loading,
    );
    const reordering = useSelector(
        (store: Store) =>
            store.pages.administration.administrationStoragesPage.reordering,
    );

    const onCreateStorage = () => {
        dispatch(openDialog("EditOrCreateStorage"));
    };

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={4}
            className={styles.storageLinkPanel}
        >
            <Grid
                item
                container
                xs={12}
                sm="auto"
                alignItems="stretch"
                alignContent="flex-start"
                zeroMinWidth
                spacing={4}
            >
                <Grid item xs={12} sm="auto">
                    <Button
                        className={styles.width100}
                        startIcon={<AddIcon />}
                        color="primary"
                        onClick={onCreateStorage}
                        disabled={loading}
                    >
                        {t("admin-storages:createStorage")}
                    </Button>
                </Grid>
                <Grid item xs={12} sm="auto">
                    <Button
                        className={styles.width100}
                        color="secondary"
                        onClick={() => {
                            history.push(
                                routePaths.administrationTemplate.settings
                                    .storage,
                            );
                        }}
                    >
                        {t("admin-storages:storageSettings")}
                    </Button>
                </Grid>

                {(reordering || loading) && (
                    <Preloader className={styles.reorderSpinner} size={20} />
                )}
            </Grid>
            <Grid
                item
                container
                xs={12}
                sm="auto"
                zeroMinWidth
                justifyContent="flex-end"
                component="div"
            >
                <FindInput
                    className={styles.width100}
                    onChange={handleStoragesFilterChange}
                />
            </Grid>
        </Grid>
    );
};
