import { ComponentsOverrides } from "@mui/material/styles/overrides";
import styleVariables from "../variables";

export const MuiChip: ComponentsOverrides["MuiChip"] = {
    root: {
        fontSize: 12,
        height: 24,
    },
    label: {
        paddingLeft: styleVariables.spacing * 3,
        paddingRight: styleVariables.spacing * 2,
    },
    deleteIconSmall: {
        margin: styleVariables.spacing,
    },
    deleteIcon: {
        color: styleVariables.color.disabled,
    },
    sizeSmall: {
        height: 18,
    },
    labelSmall: {
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 3,
        paddingBottom: 3,
        fontSize: 10,
    },
};

export default MuiChip;
