import React from "react";
import { ClosingDialogTitle } from "../СlosingDialogTitle";
import { Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { SendMessageForm } from "src/components/FileSendBox/SendMessageForm";
import { getTooltipText } from "src/helpers/getTooltipText";
import { SendMessageData } from "src/types/redux/store/pages/SendFilePage";
import { BaseDialogProps } from "src/pages/MyFiles";
import { mapUploadToMessageModel } from "src/helpers/mapUploadToMessageModel";

type DialogForwardProps = {
    onSend: (sendMessageData: SendMessageData) => void;
} & BaseDialogProps;

export const DialogForward = ({
    open,
    onClose,
    upload,
    onSend,
}: DialogForwardProps) => {
    const { t } = useTranslation();

    const onSendByEmail = (sendMessageData: SendMessageData) => {
        onSend(sendMessageData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContentStyles>
                <SendMessageForm
                    onSendByEmail={onSendByEmail}
                    message={upload && mapUploadToMessageModel(upload)}
                    tooltipText={t(getTooltipText(upload?.files))}
                    onCancel={onClose}
                    title={
                        <ClosingDialogTitle onClose={onClose}>
                            {t("my-file:forwardUploadDialog.title")}
                        </ClosingDialogTitle>
                    }
                />
            </DialogContentStyles>
        </Dialog>
    );
};

const DialogContentStyles = styled(DialogContent)`
    margin-bottom: 0;
`;
