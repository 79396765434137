import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { UserFilters } from "src/types/redux/store/pages/administration/AdministrationUsersPage";
import { userApi } from "../../../services/clientApi";
import { UserModel } from "../../../services/generated";
import { enqueueError, enqueueSuccess } from "../../../helpers/enqueueError";
import i18n from "../../../i18n";

export const loadUsers = createAsyncThunk("loadUsers", async thunkApi => {
    return (await userApi.getUsers()).data;
});

export const cleanUsersPageState = createAction("cleanUsersPage");

export const setUserFilters = createAction<UserFilters>("setUserFilters");

export const blockUser = createAsyncThunk(
    "blockUser",
    async (user: UserModel, thunkAPI) => {
        try {
            const response = (
                await userApi.updateUserById(user.id, {
                    ...user,
                    active: false,
                })
            ).data;
            enqueueSuccess(
                i18n.t("admin-users:activationDialog.blockSuccess", {
                    userName: `${user.firstName} ${user.lastName}`,
                }),
            );
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const activateUser = createAsyncThunk(
    "activateUser",
    async (user: UserModel, thunkAPI) => {
        try {
            const response = (
                await userApi.updateUserById(user.id, { ...user, active: true })
            ).data;
            enqueueSuccess(
                i18n.t("admin-users:activationDialog.activationSuccess", {
                    userName: `${user.firstName} ${user.lastName}`,
                }),
            );
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const editUser = createAsyncThunk(
    "editUser",
    async (user: UserModel, thunkAPI) => {
        try {
            const response = (await userApi.updateUserById(user.id, user)).data;
            enqueueSuccess(i18n.t("admin-users:success.edit"));
            return response;
        } catch (error) {
            enqueueError(i18n.t("admin-users:error.edit"));
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const openEditUserDialog = createAction<number>("openEditUserDialog");

export const closeEditUserDialog = createAction<void>("closeEditUserDialog");
