import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CommonAdminForm } from "src/components/Admin/commonAdminForm";
import { ArrowDropDown } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Input as MuiInput,
} from "@mui/material";
import { ButtonGroupToggle } from "src/components/ButtonGroupToggle";
import { delayBeforeDeletingDays } from "src/redux/pages/administrationSettings/storage/constants";
import { ButtonToggleType } from "src/types/TabType";
import {
    SimpleCronPeriodType,
    StorageSettingsForm,
    StorageSettingsPage,
} from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { Controller, useForm } from "src/components/Form";
import { Store } from "src/types/redux/store";
import { Preloader } from "src/components/Preloader";
import {
    getStorageSettings,
    setCronFormatValues,
    setLightFormatValues,
    setPeriodTypeIsNormal,
    updateStorageSettings,
} from "src/redux/pages/administrationSettings/storage/actions";
import { isValidEmail } from "src/helpers/email";
import {
    fromCronToLight,
    fromLightToCron,
} from "src/helpers/cronFromatAdapters";
import { isNumber } from "lodash";

enum FormatType {
    NORMAL = "NORMAL",
    CRON = "CRON",
}

const periods = Object.values(SimpleCronPeriodType);

const buttons: ButtonToggleType[] = [
    {
        label: "admin-settings:storageSettings.cronFormat",
        value: FormatType.CRON,
    },
    {
        label: "admin-settings:storageSettings.normalFormat",
        value: FormatType.NORMAL,
    },
];

const useStyle = makeStyles({
    container: {
        maxWidth: 800,
    },
    buttonGroup: {
        marginBottom: 40,
        width: 342,
    },
    block: {
        maxWidth: 800,

        "&:not(:last-child)": {
            marginBottom: 40,
        },
    },
    dialogContent: {
        textAlign: "start",
        maxWidth: 352,
    },
    timeField: {
        "& input": {
            paddingRight: 8,
        },
    },
});

export const StorageSettings = () => {
    const { t } = useTranslation();
    const styles = useStyle();
    const dispatch = useDispatch();
    const [showWarnning, setShowWarnning] = useState<boolean>(false);
    const { loading, form } = useSelector<Store, StorageSettingsPage>(
        store =>
            store.pages.administration.administrationSettings
                .storageSettingsPage,
    );
    const { control, formState, register, reset, handleSubmit, watch } =
        useForm<StorageSettingsForm>({
            defaultValues: form,
            shouldUnregister: false,
        });

    useEffect(() => {
        dispatch(getStorageSettings());
    }, [dispatch]);

    useEffect(() => {
        reset(form);
    }, [form, reset]);

    const {
        notEnoughFreeSpaceNotificationEmail,
        notEnoughFreeSpaceNotificationThreshold,
        expiredFilesSchedule,
        freeSpaceCheckSchedule,
        simpleCron_checkFreeSpace_period,
        simpleCron_checkFreeSpace_time,
        simpleCron_deleteFiles_period,
        simpleCron_deleteFiles_time,
        simpleForm,
    } = watch();
    const thisIsValidEmail =
        !notEnoughFreeSpaceNotificationEmail ||
        isValidEmail(notEnoughFreeSpaceNotificationEmail);
    const showErrorEmail =
        formState.touchedFields.notEnoughFreeSpaceNotificationEmail &&
        !thisIsValidEmail;
    const isValidFreeSpace =
        isNumber(+notEnoughFreeSpaceNotificationThreshold) &&
        notEnoughFreeSpaceNotificationThreshold >= 0 &&
        notEnoughFreeSpaceNotificationThreshold <= 100;
    const showErrorFreeSpace =
        formState.touchedFields.notEnoughFreeSpaceNotificationThreshold &&
        !isValidFreeSpace;

    const closeWarning = () => {
        setShowWarnning(false);
    };

    const clearDataAndSetNNormalType = () => {
        closeWarning();
        dispatch(
            setLightFormatValues({
                expiredFilesLightFormat: {},
                freeSpaceCheckLightFormat: {},
            }),
        );
        dispatch(
            setCronFormatValues({
                freeSpaceCheckSchedule: undefined,
                expiredFilesSchedule: undefined,
            }),
        );
        dispatch(setPeriodTypeIsNormal(true));
    };

    const onChangeFormatType = () => {
        if (simpleForm) {
            dispatch(setPeriodTypeIsNormal(false));
            const freeSpaceCheckSchedule = fromLightToCron({
                periodType: simpleCron_checkFreeSpace_period,
                time: simpleCron_checkFreeSpace_time,
            });
            const expiredFilesSchedule = fromLightToCron({
                periodType: simpleCron_deleteFiles_period,
                time: simpleCron_deleteFiles_time,
            });
            dispatch(
                setCronFormatValues({
                    freeSpaceCheckSchedule,
                    expiredFilesSchedule,
                }),
            );
            return;
        }

        const freeSpaceCheckLightFormat = fromCronToLight(
            freeSpaceCheckSchedule,
        );
        const expiredFilesLightFormat = fromCronToLight(expiredFilesSchedule);

        if (freeSpaceCheckLightFormat && expiredFilesLightFormat) {
            dispatch(setPeriodTypeIsNormal(true));
            dispatch(
                setLightFormatValues({
                    expiredFilesLightFormat,
                    freeSpaceCheckLightFormat,
                }),
            );
        } else {
            setShowWarnning(true);
        }
    };

    const onSubmit = (data: StorageSettingsForm) => {
        dispatch(
            updateStorageSettings({
                expiredFilesSchedule: simpleForm
                    ? fromLightToCron({
                          time: data.simpleCron_deleteFiles_time,
                          periodType: data.simpleCron_deleteFiles_period,
                      })
                    : data.expiredFilesSchedule,
                freeSpaceCheckSchedule: simpleForm
                    ? fromLightToCron({
                          time: data.simpleCron_checkFreeSpace_time,
                          periodType: data.simpleCron_checkFreeSpace_period,
                      })
                    : data.freeSpaceCheckSchedule,
                countOfDayDelayBeforeMessageRemoving:
                    data.countOfDayDelayBeforeMessageRemoving,
                notEnoughFreeSpaceNotificationEmail:
                    data.notEnoughFreeSpaceNotificationEmail.toLowerCase(),
                simpleForm: simpleForm,
                notEnoughFreeSpaceNotificationThreshold:
                    +data.notEnoughFreeSpaceNotificationThreshold,
            }),
        );
    };

    return (
        <CommonAdminForm
            className={styles.container}
            disableSubmit={!thisIsValidEmail || !isValidFreeSpace}
            onSubmit={handleSubmit(onSubmit)}
        >
            {loading && <Preloader over />}

            <Dialog disableEscapeKeyDown open={showWarnning}>
                <DialogTitle>
                    {t("admin-settings:storageSettings.clearDataTitle")}
                </DialogTitle>
                <DialogContent className={styles.dialogContent}>
                    {t("admin-settings:storageSettings.clearDataText")}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeWarning} color="secondary">
                        {t("common:cancel")}
                    </Button>
                    <Button
                        onClick={clearDataAndSetNNormalType}
                        type="submit"
                        color="primary"
                    >
                        {t("common:confirm")}
                    </Button>
                </DialogActions>
            </Dialog>

            <ButtonGroupToggle
                className={styles.buttonGroup}
                buttons={buttons}
                onChange={onChangeFormatType}
                value={simpleForm ? FormatType.NORMAL : FormatType.CRON}
            />

            <div>
                <div className={styles.block}>
                    <Typography variant="h6">
                        {t(
                            "admin-settings:storageSettings.checkFreeSpace.title",
                        )}
                    </Typography>

                    {simpleForm && (
                        <FormControl>
                            <InputLabel>
                                {t(
                                    "admin-settings:storageSettings.checkFreeSpace.normalFormat.scheduleLabel",
                                )}
                            </InputLabel>
                            <Controller
                                control={control}
                                name="simpleCron_checkFreeSpace_period"
                                render={({ field: { value, onChange } }) => (
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                        IconComponent={ArrowDropDown}
                                    >
                                        {periods.map((value, index) => (
                                            <MenuItem key={index} value={value}>
                                                {t(
                                                    `admin-settings:storageSettings.periods.${value}`,
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    )}

                    {simpleForm && (
                        <TextField
                            className={styles.timeField}
                            label={t(
                                "admin-settings:storageSettings.checkFreeSpace.normalFormat.timeLabel",
                            )}
                            type="time"
                            {...register("simpleCron_checkFreeSpace_time")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}

                    {!simpleForm && (
                        <TextField
                            label={t(
                                "admin-settings:storageSettings.checkFreeSpace.cronFormat.scheduleLabel",
                            )}
                            helperText={t(
                                "admin-settings:storageSettings.checkFreeSpace.cronFormat.scheduleHint",
                            )}
                            {...register("freeSpaceCheckSchedule")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}

                    <TextField
                        error={showErrorEmail}
                        InputLabelProps={{ shrink: true }}
                        label={t(
                            "admin-settings:storageSettings.notificationEmail",
                        )}
                        helperText={t(
                            "admin-settings:storageSettings.notificationEmailHint",
                        )}
                        {...register("notEnoughFreeSpaceNotificationEmail")}
                    />
                    <FormControl error={showErrorFreeSpace}>
                        <InputLabel shrink>
                            {t(
                                "admin-settings:storageSettings.remainingFreeSpace",
                            )}
                        </InputLabel>
                        <MuiInput
                            {...register(
                                "notEnoughFreeSpaceNotificationThreshold",
                            )}
                        />
                    </FormControl>
                </div>

                <div className={styles.block}>
                    <Typography variant="h6">
                        {t(
                            "admin-settings:storageSettings.deleteExpiredFiles.title",
                        )}
                    </Typography>

                    {simpleForm && (
                        <FormControl>
                            <InputLabel>
                                {t(
                                    "admin-settings:storageSettings.deleteExpiredFiles.normalFormat.scheduleLabel",
                                )}
                            </InputLabel>
                            <Controller
                                control={control}
                                name="simpleCron_deleteFiles_period"
                                render={({ field: { value, onChange } }) => (
                                    <Select
                                        onChange={onChange}
                                        value={value}
                                        IconComponent={ArrowDropDown}
                                    >
                                        {periods.map((value, index) => (
                                            <MenuItem key={index} value={value}>
                                                {t(
                                                    `admin-settings:storageSettings.periods.${value}`,
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    )}

                    {simpleForm && (
                        <TextField
                            className={styles.timeField}
                            label={t(
                                "admin-settings:storageSettings.deleteExpiredFiles.normalFormat.timeLabel",
                            )}
                            type="time"
                            {...register("simpleCron_deleteFiles_time")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}

                    {!simpleForm && (
                        <TextField
                            label={t(
                                "admin-settings:storageSettings.deleteExpiredFiles.cronFormat.scheduleLabel",
                            )}
                            helperText={t(
                                "admin-settings:storageSettings.deleteExpiredFiles.cronFormat.scheduleHint",
                            )}
                            {...register("expiredFilesSchedule")}
                            InputLabelProps={{ shrink: true }}
                        />
                    )}

                    <FormControl>
                        <InputLabel>
                            {t(
                                "admin-settings:storageSettings.delayBeforeDelete",
                            )}
                        </InputLabel>
                        <Controller
                            control={control}
                            name="countOfDayDelayBeforeMessageRemoving"
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    onChange={onChange}
                                    value={value}
                                    IconComponent={ArrowDropDown}
                                >
                                    {delayBeforeDeletingDays.map(
                                        (value, index) => (
                                            <MenuItem key={index} value={value}>
                                                {value}
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            )}
                        />
                    </FormControl>
                </div>
            </div>
        </CommonAdminForm>
    );
};
