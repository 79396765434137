import React from "react";
import { Dayjs } from "dayjs";

import { CalendarYear } from "./CalendarYear";
import { CalendarMonth } from "./CalendarMonth";
import { CalendarDate } from "./CalendarDate";
import { CalendarPickerType } from "../DatePicker/DatePicker";

export type CalendarProps = {
    onChange: (date: Dayjs) => void;
    date: Dayjs;
    type?: CalendarPickerType;
};

export const Calendar = ({ type = "date", ...props }: CalendarProps) => {
    switch (type) {
        case "year":
            return <CalendarYear {...props} />;
        case "month":
            return <CalendarMonth {...props} />;
        case "date":
            return <CalendarDate {...props} />;
    }
};
