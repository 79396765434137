import React from "react";
import { ReactComponent as ErrorIcon } from "./errorIcon.svg";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useShowBlock } from "src/hooks/useShowBlock";

const useStyle = makeStyles({
    container: {
        display: "flex",
        justifyContent: "center",
    },
    banner: {
        marginTop: "72px",
        width: "440px",
    },
    errorNumberContainer: {
        background: "#6577CB",
        borderRadius: "10px",
        width: "172px",
        height: "42px",
        margin: "auto",
        color: "#FFF",
        fontSize: "24px",
        textTransform: "uppercase",
        fontFamily: "Roboto",
        fontWeight: "normal",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    additionalErrorText: {
        marginTop: "24px",
        textTransform: "uppercase",
        color: "#4C5A99",
        fontSize: "18px",
        fontFamily: "Roboto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    comment: {
        marginTop: "16px",
        textAlign: "center",
        fontSize: "14px",
        fontFamily: "Roboto",
    },
    link: {
        color: "#4A90E2",
        textDecoration: "none",
    },
    img: {
        width: "100%",
        padding: "0 20px",
    },
});

export enum ErrorCodes {
    NOT_FOUND = "notFound",
    SERVER_ERROR = "serverError",
    FORBIDDEN = "forbidden",
    GENERAL = "general",
    NETWORK = "network",
}

type ErrorPageProps = {
    errorType?: ErrorCodes;
    text?: string;
    isReturnHome?: boolean;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({
    errorType = ErrorCodes.SERVER_ERROR,
    text,
    isReturnHome = false,
}) => {
    const styles = useStyle();
    const { t } = useTranslation();

    const [codeError, showCodeError] = useShowBlock(
        `common:error.${errorType}.number`,
    );
    const [comment, showComment] = useShowBlock(
        `common:error.${errorType}.comment`,
    );

    return (
        <Box className={styles.container}>
            <Box className={styles.banner}>
                <ErrorIcon className={styles.img} />
                {showCodeError && (
                    <div className={styles.errorNumberContainer}>
                        {codeError}
                    </div>
                )}
                <div className={styles.additionalErrorText}>
                    {t(`common:error.${errorType}.additionalErrorText`)}
                </div>
                {showComment && <div className={styles.comment}>{comment}</div>}
                {isReturnHome && (
                    <div className={styles.comment}>
                        {t("common:error.try")}
                        <Link className={styles.link} to={"/sendFile"}>
                            {t("common:error.return")}
                        </Link>
                    </div>
                )}
                {text && <div className={styles.comment}>{text}</div>}
            </Box>
        </Box>
    );
};
