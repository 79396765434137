export const MuiTab = {
    root: {
        minWidth: 0,
        margin: "0 16px",
        padding: "16px 0px",
        letterSpacing: "0.05em",
        "&.Mui-selected": {
            color: "#1A1F29",
            opacity: 1,
            fontWeight: 500,
        },
        fontWeight: 400,
    },
    textColorInherit: {
        opacity: 0.5,
    },
};
