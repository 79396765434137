import React, { useCallback, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { Grid, Link } from "@mui/material";
import { routePaths } from "../routes/routePaths";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { getVersionInfo } from "src/redux/version/actions";
import { About } from "./About";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footerContainer: {
            backgroundColor: "#EFEFF4",
            boxSizing: "border-box",
            width: "100%",
            padding: "0 120px",
            [theme.breakpoints.down("md")]: {
                padding: "0 56px",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "0 5px",
            },
            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },
        footer: {
            fontFamily: "Roboto",
            fontSize: "12px",
            lineHeight: "14px",
            color: "rgba(0, 0, 0, 0.7)",
        },
        linkItem: {
            color: "rgba(0, 0, 0, 0.7)",
            padding: "12px 16px",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "none",
                fontWeight: 500,
            },
        },
        footerHeight: {
            height: "56px",
            [theme.breakpoints.down("xs")]: {
                justifyContent: "start",
                height: "auto",
            },
        },
    }),
);

export const Footer = () => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const { t } = useTranslation();
    const { accessToken } = useSelector(
        (store: Store) => store.authenticationHandler.authentication,
    );
    const { appVersionInfo } = useSelector(
        (store: Store) => store.appVersionInfo,
    );
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(getVersionInfo());
    }, [dispatch]);
    const version = appVersionInfo?.branch.split("/");

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onOpen = () => {
        setOpen(true);
    };

    return (
        <div className={styles.footerContainer}>
            <Grid
                container
                wrap="nowrap"
                className={styles.footer}
                justifyContent="space-around"
            >
                <div>
                    <Grid
                        container
                        alignItems="center"
                        className={styles.footerHeight}
                    >
                        {t("common:footer.version")}
                        {version?.[1] || version?.[0]}
                        {t("common:footer.copyrightMark")}
                        {", "}
                        {new Date().getFullYear()} {t("common:footer.rights")}
                    </Grid>
                </div>
                <div>
                    <Grid
                        container
                        wrap="nowrap"
                        alignItems="center"
                        className={styles.footerHeight}
                    >
                        <span className={styles.linkItem} onClick={onOpen}>
                            {t("common:footer.aboutSendFile")}
                        </span>
                        <Link
                            className={styles.linkItem}
                            href={routePaths.aboutArtezio}
                        >
                            {t("common:footer.aboutArtezio")}
                        </Link>
                        {accessToken && (
                            <Link
                                className={styles.linkItem}
                                component={RouterLink}
                                to={routePaths.support}
                            >
                                {t("common:footer.support")}
                            </Link>
                        )}
                    </Grid>
                </div>
            </Grid>
            <About isOpen={open} onClose={onClose} />
        </div>
    );
};
