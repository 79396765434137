import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSuccess, enqueueError } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { settingsApiService } from "src/services/clientApi";
import { LdapSettingsDTO } from "src/services/generated";

export const getSettingsLdap = createAsyncThunk("getSettingsLdap", async () => {
    return (await settingsApiService.getLdapSettings()).data;
});

export const updateSettingsLdap = createAsyncThunk(
    "updateSettingsLdap",
    async (ldapSettingsDTO: LdapSettingsDTO, thankAPI) => {
        try {
            const response =
                await settingsApiService.updateLdapSettings(ldapSettingsDTO);
            enqueueSuccess(i18n.t("admin-settings:ldap.notification.success"));
            return response.data;
        } catch (error) {
            const errorCodeStr = (error as any)?.response?.data?.errorCodeStr;
            thankAPI.rejectWithValue(error);
            enqueueError(i18n.t(errorCodeStr || "admin-settings:error.edit"));
        }
    },
);
