import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    activateUser,
    blockUser,
    cleanUsersPageState,
    closeEditUserDialog,
    editUser,
    loadUsers,
    openEditUserDialog,
    setUserFilters,
} from "./actions";
import { UserFilters } from "src/types/redux/store/pages/administration/AdministrationUsersPage";
import { UserModel } from "../../../services/generated";
import { AdministrationUsersPage } from "src/types/redux/store/pages/administration/AdministrationUsersPage";
import { MetaAction } from "src/types/redux/PayloadAction";

export const defaultUserFilters: UserFilters = {
    hideBlocked: false,
    endPeriod: null,
    nameFilter: "",
    startPeriod: null,
};

const initialState: AdministrationUsersPage = {
    users: [],
    filters: defaultUserFilters,
    updatingUserId: null,
    editDialogUserId: null,
    updatingUser: false,
    loadingUsers: false,
};

export const administrationUsersPageReducer =
    createReducer<AdministrationUsersPage>(initialState, {
        [loadUsers.pending.type](state) {
            state.loadingUsers = true;
        },
        [loadUsers.fulfilled.type](
            state,
            action: PayloadAction<Array<UserModel>>,
        ) {
            state.users = action.payload;
            state.loadingUsers = false;
        },
        [loadUsers.rejected.type](
            state,
            action: PayloadAction<Array<UserModel>>,
        ) {
            state.users = action.payload;
            state.loadingUsers = false;
        },
        [setUserFilters.type](state, actions: PayloadAction<UserFilters>) {
            state.filters = actions.payload;
        },
        [cleanUsersPageState.type](state) {
            state.filters = { hideBlocked: false } as UserFilters;
            state.users = [];
        },
        [openEditUserDialog.type](state, action: PayloadAction<number>) {
            state.editDialogUserId = action.payload;
        },
        [closeEditUserDialog.type](state) {
            state.editDialogUserId = null;
        },
        [editUser.pending.type](state, action: MetaAction<UserModel>) {
            state.updatingUser = true;
        },
        [editUser.fulfilled.type](state, action: PayloadAction<UserModel>) {
            const index = state.users.findIndex(
                user => user.id === action.payload.id,
            );
            if (index !== -1) {
                state.users.splice(index, 1, action.payload);
            }
            state.updatingUser = false;
            state.editDialogUserId = null;
        },
        [editUser.rejected.type](state) {
            state.updatingUser = false;
            state.editDialogUserId = null;
        },
        [blockUser.pending.type](state, action: MetaAction<UserModel>) {
            state.updatingUserId = action.meta.arg.id;
        },
        [blockUser.fulfilled.type](state, action: PayloadAction<UserModel>) {
            const index = state.users.findIndex(
                user => user.id === action.payload.id,
            );
            if (index !== -1) {
                state.users.splice(index, 1, action.payload);
            }
            state.updatingUserId = null;
        },
        [blockUser.rejected.type](state, action: PayloadAction<UserModel>) {
            state.updatingUserId = null;
        },
        [activateUser.pending.type](state, action: MetaAction<UserModel>) {
            state.updatingUserId = action.meta.arg.id;
        },
        [activateUser.fulfilled.type](state, action: PayloadAction<UserModel>) {
            const index = state.users.findIndex(
                user => user.id === action.payload.id,
            );
            if (index !== -1) {
                state.users.splice(index, 1, action.payload);
            }
            state.updatingUserId = null;
        },
        [activateUser.rejected.type](state, action: PayloadAction<UserModel>) {
            state.updatingUserId = null;
        },
    });
