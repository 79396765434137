import { makeStyles } from "@mui/styles";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { useForm } from "src/components/Form";
import {
    deleteCurrentFileFromResponseMessage,
    sendResponseMessageForm,
    uploadFilesToResponseMessage,
    updateStateFromFormData,
    getDownloadVolumeValuesFromResponseMessage,
    getDownloadStateFilesResponseMessage,
} from "src/redux/pages/responseMessage/actions";
import {
    EmptyFileInput,
    EmptyFileInputSize,
} from "../SendFilePage/EmptyFileInput";
import FileDisplayContainer from "../FileDisplayContainer/FileDisplayContainer";
import { FileDisplay } from "../FileDisplay/FileDisplay";
import { TextFieldShrinkWrapper } from "../TextFieldShrinkWrapper";
import {
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    Tooltip,
    Box,
} from "@mui/material";
import { ResponseMessageDTO } from "../../services/generated";
import { isFiledFile } from "src/helpers/file";
import { FileUploadFailedWindow } from "../ModalWindows/FileUploadFailedWindow";
import { openDialog } from "src/redux/dialogs/commonDialog/actions";
import { getTooltipText } from "src/helpers/getTooltipText";
import { LeaveUnsaveChangesDialog } from "../LeaveUnsaveChangesDialog";
import { FileViewModel } from "src/types/FileDisplay";
import { FileMemoryStatus } from "../FileMemoryStatus/FileMemoryStatus";
import _ from "lodash";
import { PublicSettingsDTO } from "src/services/generated";
import { Theme } from "@mui/material/styles";

const useStyle = makeStyles((theme: Theme) => ({
    conatiner: {
        width: "414px",
        margin: "32px 40px",
        [theme.breakpoints.down("xs")]: {
            margin: "16px",
        },
    },
    formHeader: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "20px",
    },
    button: {
        width: "200px",
        marginTop: "8px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    emptyFileInputSize: {
        minWidth: "414px",
        height: "264px",
    },
    tooltip: {
        maxWidth: 500,
    },
    contentLongRow: {
        "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
}));

export const ResponseMessageEdit: FC = () => {
    const styles = useStyle();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        responseMessage,
        uploadedFileCount: downloadedFileCount,
        responseMessageMemory,
        reservedFileId,
    } = useSelector((store: Store) => store.pages.responseMessagePage);

    const [tooltiptext, setTooltiptext] = useState(
        "send-file:tooltipText.emptyMessage",
    );
    const { encryptionState } = useSelector<Store, PublicSettingsDTO>(
        store => store.publicSettings,
    );

    const { register, watch, setValue } = useForm<ResponseMessageDTO>({
        defaultValues: {
            email: responseMessage?.email,
            subject: responseMessage?.subject,
            messageText: responseMessage?.messageText,
        },
    });

    const values = watch();

    useEffect(() => {
        if (responseMessage) {
            setTooltiptext(t(getTooltipText(responseMessage.responseFiles!)));
        }
    }, [responseMessage, setValue, t]);

    useEffect(() => {
        dispatch(
            getDownloadVolumeValuesFromResponseMessage(responseMessage.uuid),
        );
    }, [responseMessage.uuid, dispatch]);

    useEffect(() => {
        let dispatcher = _.debounce(() => {
            dispatch(
                getDownloadStateFilesResponseMessage({
                    fileIds: reservedFileId,
                }),
            );
        }, 5000);
        if (reservedFileId.length) {
            dispatcher();
        }
        //TODO update to make less queries
        return () => {
            dispatcher.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservedFileId, dispatch]);

    const handleUploadFile = (fileList: File[]) => {
        dispatch(
            uploadFilesToResponseMessage({
                files: fileList,
                encrypted: encryptionState,
            }),
        );
    };

    const handleDeleteFile = (file: FileViewModel) => {
        dispatch(
            deleteCurrentFileFromResponseMessage({
                messageId: responseMessage!.uuid,
                file: file,
            }),
        );
    };
    const handleEmailTextEditing = () => {
        const values = watch();
        dispatch(updateStateFromFormData(values));
    };

    const onContinueSendMessage = () => {
        dispatch(
            sendResponseMessageForm({
                uuid: responseMessage!.uuid,
                responseMessageEmailDTO: {
                    emailText: responseMessage!.messageText,
                    emailSubject: responseMessage!.subject,
                },
            }),
        );
    };

    const sendResponseMessage = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        if (responseMessage?.uuid === undefined) {
            return;
        }
        const isFailedFiles = responseMessage.responseFiles?.some(isFiledFile);
        if (isFailedFiles) {
            dispatch(openDialog("FileUploadFailedWindow"));
            return;
        }

        dispatch(
            sendResponseMessageForm({
                uuid: responseMessage?.uuid,
                responseMessageEmailDTO: {
                    emailText: responseMessage.messageText,
                    emailSubject: responseMessage.subject,
                },
            }),
        );
    };

    const isFilledForm =
        !responseMessage?.responseFiles ||
        !responseMessage.responseFiles.length ||
        !!tooltiptext ||
        !values.subject;

    const isContainerEmpty =
        !responseMessage?.responseFiles ||
        !responseMessage?.responseFiles?.length;

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Box className={styles.conatiner}>
                {isContainerEmpty ? (
                    <EmptyFileInput
                        onFileUpload={handleUploadFile}
                        emptyFileInputSize={EmptyFileInputSize.SMALL}
                    />
                ) : (
                    <FileDisplayContainer
                        height={264}
                        onUploadFiles={handleUploadFile}
                        downloadedInfo={{
                            downloadedCount: downloadedFileCount,
                            freeSpace: "X Mb",
                            totalCount:
                                responseMessage?.responseFiles?.length || 0,
                            usedSpace: "Y Mb",
                        }}
                    >
                        {responseMessage?.responseFiles?.map((file, index) => (
                            <FileDisplay
                                key={index}
                                file={file}
                                onDeleteFile={handleDeleteFile}
                                isView={false}
                            />
                        ))}
                    </FileDisplayContainer>
                )}
                <FileMemoryStatus
                    files={responseMessage?.responseFiles}
                    memory={responseMessageMemory}
                />
            </Box>
            <Box className={styles.conatiner}>
                <div className={styles.formHeader}>
                    {t("response-message:sendFileByRequest")}
                </div>
                <form>
                    <TextFieldShrinkWrapper
                        required
                        label={t("response-message:to")}
                        value={values.email!}
                        disabled
                        {...register("email")}
                    />
                    <FormControl>
                        <InputLabel required>
                            {t("response-message:subject")}
                        </InputLabel>
                        <Tooltip
                            title={values.subject || ""}
                            className={styles.tooltip}
                            disableInteractive
                        >
                            <Input
                                required
                                className={styles.contentLongRow}
                                fullWidth={true}
                                {...register("subject", {
                                    onChange: handleEmailTextEditing,
                                })}
                            />
                        </Tooltip>
                    </FormControl>
                    <TextFieldShrinkWrapper
                        label={t("response-message:message")}
                        value={values.messageText}
                        {...register("messageText", {
                            onChange: handleEmailTextEditing,
                        })}
                        multiline
                        fullWidth
                        maxLength={65000}
                    />
                    {isFilledForm ? (
                        <Tooltip
                            disableInteractive
                            title={
                                tooltiptext ||
                                (t(
                                    "send-file:tooltipText.emptyFields",
                                ) as string)
                            }
                            className={styles.tooltip}
                        >
                            <span>
                                <Button
                                    disabled={true}
                                    type="submit"
                                    color="primary"
                                    className={styles.button}
                                    fullWidth={true}
                                >
                                    {t(
                                        "response-message:sendButton",
                                    ).toUpperCase()}
                                </Button>
                            </span>
                        </Tooltip>
                    ) : (
                        <Button
                            type="button"
                            color="primary"
                            className={styles.button}
                            disabled={isFilledForm}
                            onClick={sendResponseMessage}
                            fullWidth={true}
                        >
                            {t("response-message:sendButton").toUpperCase()}
                        </Button>
                    )}
                    <FileUploadFailedWindow
                        onContinue={onContinueSendMessage}
                    />
                </form>
            </Box>
            <LeaveUnsaveChangesDialog
                when={!!responseMessage?.responseFiles?.length}
                title={t("common:modals.dataLossWindow.title")}
                cancelButtonText={t("common:modals.dataLossWindow.cancel")}
                confirmButtonText={t("common:modals.dataLossWindow.confirm")}
            >
                {t("common:modals.dataLossWindow.content")}
            </LeaveUnsaveChangesDialog>
        </Grid>
    );
};
