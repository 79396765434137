import {
    CancelOutlined,
    CheckCircleOutlined,
    MoreVert,
} from "@mui/icons-material";
import { DropDownMenu, DropDownMenuItem } from "../../DropDownMenu";
import React, { useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
    activateUser,
    blockUser,
    loadUsers,
    openEditUserDialog,
} from "src/redux/pages/administrationUsers/actions";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Theme,
    Drawer,
} from "@mui/material";
import {
    ClassNameMap,
    createStyles,
    makeStyles,
    withStyles,
} from "@mui/styles";
import { EditUserDialog } from "../../EditUserDialog";
import { useDispatch, useSelector } from "react-redux";
import { AdministrationUsersPage } from "../../../types/redux/store/pages/administration/AdministrationUsersPage";
import { Store } from "../../../types/redux/store";
import moment from "moment";
import { UserModel } from "../../../services/generated";
import { Preloader } from "src/components/Preloader";
import { commonDateTimeFormat } from "src/constants/momentFormats";
import { getSettingsGeneral } from "src/redux/pages/administrationSettings/general/actions";
import { GeneralSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { ClosingDialogTitle } from "src/components/ModalWindows/СlosingDialogTitle";
import classNames from "classnames";
import UsersFilter from "src/components/filters/UsersFilter";
import { NoDataFound } from "src/components/NoDataFound";
import { DataGrid, DatagridColumn } from "src/components/DataGrid";

const StyledDialog = withStyles({
    paper: {
        width: "400px",
    },
})(Dialog);

const useStyles = makeStyles(() =>
    createStyles({
        table: {
            height: "100%",
        },
        activationDialogContent: {
            marginBottom: 40,
            textAlign: "left",
            fontSize: 14,
            lineHeight: "16px",
        },
        cancelButton: {
            width: 120,
            flexShrink: 5,
        },
        disabled: {
            opacity: 0.5,
        },
    }),
);

const StyledDrawer = withStyles((theme: Theme) => ({
    paper: {
        width: "280px",
        [theme.breakpoints.up("xs")]: {
            width: "400px",
        },
        maxHeight: "100%",
        height: "100%",
        maxWidth: "100%",
        margin: "0",
        borderRadius: "0",
        padding: "0",
    },
}))(Drawer);

const filterUsers = (
    users: UserModel[],
    filters: AdministrationUsersPage["filters"],
): UserModel[] => {
    const filteredUsers = users
        ?.filter(user => {
            if (filters.hideBlocked) {
                return user.active;
            }
            return true;
        })
        .filter(user => {
            if (filters.nameFilter) {
                if (user.email || user.firstName || user.lastName) {
                    return Array.of(user.email, user.firstName, user.lastName)
                        .map(userField => userField?.toLowerCase())
                        .some(
                            field =>
                                field?.includes(
                                    filters.nameFilter.toLowerCase() as string,
                                ),
                        );
                }
            }
            return true;
        })
        .filter(user => {
            if (!filters.endPeriod || !filters.startPeriod) return true;
            return moment(user.lastLoginDateTime).isBetween(
                moment(filters.startPeriod),
                moment(filters.endPeriod),
            );
        });

    return filteredUsers;
};
const UsersTable = () => {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { users, filters, updatingUserId, loadingUsers } = useSelector(
        (store: Store) => store.pages.administration.administrationUsersPage,
    );
    const { loading, form } = useSelector<Store, GeneralSettingsPage>(
        store =>
            store.pages.administration.administrationSettings
                .generalSettingsPage,
    );
    const [activationDialog, setActivationDialog] = useState(false);
    const [activation, setActivation] = useState(false);
    const [activationChangeUser, setActivationChangeUser] =
        useState<UserModel | null>(null);
    const [isOpenFilter, setIsopenFilter] = useState(false);
    const { pagination, setPagination } = DataGrid.useTableState();

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(getSettingsGeneral());
    }, [dispatch]);

    const filteredUsers = useMemo(() => {
        return filterUsers(users, filters);
    }, [users, filters]);

    const onOpenFilter = () => {
        setIsopenFilter(true);
    };

    const onCloseFilter = () => {
        setIsopenFilter(false);
    };

    const showActivationDialog = () => {
        setActivationDialog(true);
    };

    const closeActivationDialog = () => {
        setActivationDialog(false);
    };

    const startActivation = (user: UserModel) => {
        setActivation(true);
        showActivationDialog();
        setActivationChangeUser(user);
    };

    const startDeactivation = (user: UserModel) => {
        setActivation(false);
        showActivationDialog();
        setActivationChangeUser(user);
    };

    const getDropDownMenuItems = (user: UserModel): Array<DropDownMenuItem> => [
        {
            title: t(
                `admin-users:dropDownMenu.${
                    !user.active ? "activate" : "block"
                }`,
            ),
            onClick: () => {
                if (!user.active) {
                    startActivation(user);
                } else {
                    startDeactivation(user);
                }
            },
            isDisabled: () => {
                return user.id === updatingUserId;
            },
        },
        {
            title: t("admin-users:dropDownMenu.edit"),
            onClick: () => {
                dispatch(openEditUserDialog(user.id));
            },
            isDisabled: () => {
                return (
                    loading ||
                    !form.fileSizeUploadLimit ||
                    user.id === updatingUserId
                );
            },
        },
    ];

    const changeActivation = () => {
        if (!activationChangeUser) return;

        closeActivationDialog();

        if (activation) {
            dispatch(activateUser(activationChangeUser));
        } else {
            dispatch(blockUser(activationChangeUser));
        }
    };

    return (
        <React.Fragment>
            <Box className={styles.table}>
                {filteredUsers.length === 0 && !loadingUsers ? (
                    <NoDataFound />
                ) : (
                    <DataGrid
                        data={filteredUsers}
                        columns={getColumns(t, styles, updatingUserId)}
                        enableRowActions
                        positionActionsColumn="last"
                        layoutMode="grid"
                        onPaginationChange={setPagination}
                        state={{
                            pagination,
                        }}
                        renderRowActions={({ row }) => {
                            return (
                                <DropDownMenu
                                    anchorElement={MoreVert}
                                    items={getDropDownMenuItems(row.original)}
                                />
                            );
                        }}
                        displayColumnDefOptions={{
                            "mrt-row-actions": {
                                header: "",
                                size: 56,
                                maxSize: 56,
                            },
                        }}
                        onOpenFilterDialog={onOpenFilter}
                    />
                )}
            </Box>

            <EditUserDialog />
            <StyledDialog
                open={activationDialog}
                onClose={closeActivationDialog}
            >
                <ClosingDialogTitle onClose={closeActivationDialog}>
                    {t(
                        `admin-users:activationDialog.${
                            activation ? "activation" : "deactivation"
                        }.header`,
                    )}
                </ClosingDialogTitle>
                <DialogContent className={styles.activationDialogContent}>
                    {t(
                        `admin-users:activationDialog.${
                            activation ? "activation" : "deactivation"
                        }.text`,
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        className={styles.cancelButton}
                        onClick={closeActivationDialog}
                        variant="text"
                        color="secondary"
                    >
                        {t("admin-users:activationDialog.cancel")}
                    </Button>
                    <Button
                        onClick={changeActivation}
                        type="submit"
                        color="primary"
                    >
                        {t(
                            `admin-users:activationDialog.${
                                activation ? "activate" : "block"
                            }`,
                        )}
                    </Button>
                </DialogActions>
            </StyledDialog>
            <StyledDrawer
                onClose={onCloseFilter}
                anchor="right"
                open={isOpenFilter}
                ModalProps={{ keepMounted: true }}
            >
                <UsersFilter />
            </StyledDrawer>
        </React.Fragment>
    );
};

export default UsersTable;

export const getColumns: (
    t: TFunction,
    styles: ClassNameMap,
    updatingUserId: number,
) => DatagridColumn<UserModel>[] = (t, styles, updatingUserId) => [
    {
        accessorKey: "firstName",
        header: t("admin-users:headers.users"),
        size: 200,
        Cell: ({ row: { original } }) => {
            return (
                <Box
                    className={classNames({
                        [styles.disabled]: !original.active,
                    })}
                >{`${original.firstName} ${original.lastName}`}</Box>
            );
        },
    },
    {
        accessorKey: "email",
        header: t("admin-users:headers.email"),
        size: 200,
        Cell: ({ row: { original }, cell }) => {
            return (
                <Box
                    className={classNames({
                        [styles.disabled]: !original.active,
                    })}
                >
                    {cell.getValue() as string}
                </Box>
            );
        },
    },
    {
        accessorKey: "lastLoginDateTime",
        header: t("admin-users:headers.wasActive"),
        size: 120,
        Cell: ({ cell }) => {
            return moment(cell.getValue()).format(commonDateTimeFormat());
        },
    },
    {
        accessorKey: "active",
        header: t("admin-users:headers.status"),
        size: 80,
        Cell: ({ cell, row }) => {
            if (row.original.id === updatingUserId) {
                return <Preloader size={20} />;
            }

            return cell.getValue() ? (
                <CheckCircleOutlined color="primary" />
            ) : (
                <CancelOutlined color="disabled" />
            );
        },
    },
];
