import { FileStatus, MessageModel } from "src/services/generated";

export const getMessageDownloadWithHost = (messageId: string | undefined) => {
    if (messageId === undefined) {
        return "";
    }
    return window.location.origin + `/download/${messageId}`;
};

export const getDirectLinks = (message: MessageModel) => {
    if (!message?.files) {
        return "";
    }

    const root = window.location.origin;

    return message?.files.reduce((acc, file, index, arr) => {
        if (file.status === undefined || file.status === FileStatus.READY) {
            return (
                acc +
                `${root}/api/message/${message.id}/${file.id}/directDownload${
                    arr.length === index + 1 ? "" : "\n"
                }`
            );
        }
        return acc;
    }, "");
};
