import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { Encrypted, PublicSettingsDTO } from "../../../services/generated";
import { getPrivateSettings, getPublicSettings } from "./actions";
import { PrivateSettings } from "../../../types/redux/store/PrivateSettings";

const publicSettingsInitialState: PublicSettingsDTO = {
    fileSizeUploadLimit: null,
    encryptionState: Encrypted.NONE,
    directLinksEnabled: false,
};

export const publicSettingsReducer = createReducer<PublicSettingsDTO>(
    publicSettingsInitialState,
    {
        [getPublicSettings.pending.type](state) {
            state.fileSizeUploadLimit = null;
            state.encryptionState = Encrypted.NONE;
            state.directLinksEnabled = false;
        },
        [getPublicSettings.fulfilled.type](
            state,
            action?: PayloadAction<PublicSettingsDTO>,
        ) {
            state.fileSizeUploadLimit = action.payload.fileSizeUploadLimit;
            state.encryptionState = action.payload.encryptionState;
            state.directLinksEnabled = action.payload.directLinksEnabled;
        },
        [getPublicSettings.rejected.type](state) {
            state.fileSizeUploadLimit = null;
            state.encryptionState = Encrypted.NONE;
            state.directLinksEnabled = false;
        },
    },
);

const privateSettingsInitialState: PrivateSettings = {
    appPreviousVersionData: {
        prevSendFileName: null,
        prevSendFileUrl: null,
    },
};

export const privateSettingsReducer = createReducer<PrivateSettings>(
    privateSettingsInitialState,
    {
        [getPrivateSettings.pending.type](state) {
            state.appPreviousVersionData.prevSendFileName = null;
            state.appPreviousVersionData.prevSendFileUrl = null;
        },
        [getPrivateSettings.fulfilled.type](
            state,
            action?: PayloadAction<PrivateSettings>,
        ) {
            state.appPreviousVersionData.prevSendFileName =
                action.payload.appPreviousVersionData.prevSendFileName;
            state.appPreviousVersionData.prevSendFileUrl =
                action.payload.appPreviousVersionData.prevSendFileUrl;
        },
        [getPrivateSettings.rejected.type](state) {
            state.appPreviousVersionData.prevSendFileName = null;
            state.appPreviousVersionData.prevSendFileUrl = null;
        },
    },
);
