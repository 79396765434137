import React, { useEffect, useState, PropsWithChildren } from "react";
import { Location } from "history";
import { Prompt, useHistory } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ClosingDialogTitle } from "./ModalWindows/СlosingDialogTitle";
import { useDispatch } from "react-redux";
import {
    changeSendFilePageState,
    deleteMessage,
} from "../redux/pages/sendFile/actions";
import { SendFilePageStates } from "../types/redux/store/pages/SendFilePage";

const StyledDialog = withStyles({
    paperWidthSm: {
        width: "400px",
    },
})(Dialog);

const useStyles = makeStyles({
    content: {
        textAlign: "left",
    },
});

interface LeaveUnsaveChangesDialogProps {
    title: string;
    confirmButtonText: string;
    cancelButtonText: string;
    when?: boolean;
    onConfirmLeave?: () => void;
}

export const LeaveUnsaveChangesDialog: React.FC<
    PropsWithChildren<LeaveUnsaveChangesDialogProps>
> = ({
    children,
    when = true,
    title,
    confirmButtonText,
    cancelButtonText,
    onConfirmLeave,
}) => {
    const [showDialog, setShowDialog] = useState(false);
    const [navigationConfirmed, setNavigationConfirmed] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location<unknown> | null>(
        null,
    );

    const classes = useStyles();

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        const f = (event: any) => {
            if (when) {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                    e.returnValue = "";
                }
                return "";
            }
        };

        window.addEventListener("beforeunload", f);
        return () => window.removeEventListener("beforeunload", f);
    }, [when]);

    const handleBlockedNavigation = (nextLocation: Location<unknown>) => {
        if (!navigationConfirmed) {
            setShowDialog(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const onCancel = () => {
        setShowDialog(false);
    };

    const onConfirm = () => {
        if (lastLocation) {
            setNavigationConfirmed(true);
            setShowDialog(false);
        }
    };

    useEffect(() => {
        if (lastLocation && navigationConfirmed) {
            onConfirmLeave?.();
            history.push(lastLocation.pathname);
            dispatch(
                changeSendFilePageState(SendFilePageStates.MESSAGE_CREATING),
            );
            dispatch(deleteMessage());
        }
    }, [navigationConfirmed, lastLocation, history, onConfirmLeave, dispatch]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />

            <StyledDialog open={showDialog} onClose={onCancel}>
                <ClosingDialogTitle onClose={onCancel}>
                    {title}
                </ClosingDialogTitle>
                <DialogContent className={classes.content}>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="secondary">
                        {cancelButtonText}
                    </Button>
                    <Button onClick={onConfirm} color="primary">
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </>
    );
};
