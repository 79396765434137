import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { Store } from "../../types/redux/store";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const useStyle = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        width: "200px",
        marginTop: "40px",
    },
    description: {
        fontSize: "20px",
        fontWeight: 500,
    },
    emails: {
        fontSize: "20px",
        color: "#4A90E2",
        textDecoration: "underline",
    },
    postScriptum: {
        marginTop: 40,
        color: "#4C5A99",
        fontSize: 16,
    },
});

export const AfterResponseMessageEditing: FC = () => {
    const styles = useStyle();
    const { t } = useTranslation();
    const history = useHistory();
    const email = useSelector(
        (store: Store) =>
            store.pages.responseMessagePage.responseMessage?.email,
    );
    const user = useSelector(
        (store: Store) => store.authenticationHandler.authentication.user,
    );

    const goHomePage = () => {
        history.push("/sendFile");
    };

    return (
        <div className={styles.container}>
            <div className={styles.description}>
                {t("response-message:fileWasSent")}
            </div>
            <div className={styles.emails}>{email}</div>
            {_.isEmpty(user) && (
                <div className={styles.postScriptum}>
                    {t("response-message:fileWasSentThanks")}
                </div>
            )}
            {!_.isEmpty(user) && (
                <div>
                    <Button
                        onClick={goHomePage}
                        className={styles.button}
                        color="primary"
                    >
                        {t("response-message:homePage")}
                    </Button>
                </div>
            )}
        </div>
    );
};
