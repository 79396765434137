import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { Store } from "src/types/redux/store/index";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "src/components/Form";
import { CommonAdminForm } from "../../../components/Admin/commonAdminForm";
import { MailSettingsDTO } from "src/services/generated";
import { MailSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { Preloader } from "src/components/Preloader";
import {
    getSettingsMail,
    updateSettingsMail,
} from "src/redux/pages/administrationSettings/mail/actions";
import { PasswordWithEye } from "src/components/PasswordWithEye";

const useStyle = makeStyles({
    container: {
        maxWidth: 800,
    },
    inputSetting: {
        paddingBottom: 16,
    },
    label: {
        fontSize: 14,
        marginTop: 16,
    },
});

const CheckboxStyle = withStyles({
    root: {
        marginTop: 16,
    },
})(Checkbox);

export const Email = () => {
    const { loading, form } = useSelector<Store, MailSettingsPage>(
        store =>
            store.pages.administration.administrationSettings.mailSettingsPage,
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const styles = useStyle();
    const { register, reset, handleSubmit } = useForm<MailSettingsDTO>({
        defaultValues: form,
    });
    const [smtpAuthValue, setSmtpAuthValue] = React.useState(false);
    const [startTlsValue, setStartTlsValue] = React.useState(false);

    useEffect(() => {
        dispatch(getSettingsMail());
    }, [dispatch]);

    const handleAuthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSmtpAuthValue(event.target.checked);
    };
    const handleStartTlsChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setStartTlsValue(event.target.checked);
    };

    useEffect(() => {
        reset(form);
        form?.smtpAuth && setSmtpAuthValue(form?.smtpAuth);
        form?.smtpStartTlsEnable && setStartTlsValue(form?.smtpStartTlsEnable);
    }, [form, reset]);

    const onSubmit = (data: MailSettingsDTO) => {
        dispatch(updateSettingsMail(data));
    };

    return (
        <CommonAdminForm
            onSubmit={handleSubmit(onSubmit)}
            className={styles.container}
        >
            {loading && <Preloader over />}
            <Grid container direction="column">
                <Grid item xs={6}>
                    <Typography variant="h6">
                        {t("admin-settings:settingsEMAIL.titleSettingsEMAIL")}
                    </Typography>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"username"}
                        {...register("username")}
                    />
                    <PasswordWithEye
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"password"}
                        {...register("password")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"port"}
                        {...register("port")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"host"}
                        {...register("host")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"transport.protocol"}
                        {...register("transportProtocol")}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        className={styles.inputSetting}
                        label={"mail application address"}
                        {...register("mailApplicationAddress")}
                    />
                    <div>
                        <FormControlLabel
                            control={
                                <CheckboxStyle
                                    checked={smtpAuthValue}
                                    color="primary"
                                    {...register("smtpAuth", {
                                        onChange: handleAuthChange,
                                    })}
                                />
                            }
                            label="smtp.auth"
                            classes={{ label: styles.label }}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            control={
                                <CheckboxStyle
                                    checked={startTlsValue}
                                    color="primary"
                                    {...register("smtpStartTlsEnable", {
                                        onChange: handleStartTlsChange,
                                    })}
                                />
                            }
                            label="smtp.starttls.enable"
                            classes={{ label: styles.label }}
                        />
                    </div>
                </Grid>
            </Grid>
        </CommonAdminForm>
    );
};
