import { MoreVert } from "@mui/icons-material";
import { DropDownMenu, DropDownMenuItem } from "src/components/DropDownMenu";
import React, { Dispatch, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import {
    ReceivedMessageResponseDataMessageTypeEnum,
    UserMessagesRequestParams,
} from "src/services/generated";
import { UploadForAdmin } from "src/types/Upload";
import {
    deleteMessageOnServer,
    loadUploadedMessagesInfo,
} from "src/redux/pages/administrationFiles/actions";
import { downloadFilesAllByAdmin } from "src/helpers/download";
import { NoDataFound } from "src/components/NoDataFound";
import { getSumSizeFiles } from "src/helpers/file";
import {
    DataGrid,
    ExpandButton,
    PaginationState,
} from "src/components/DataGrid";
import { SortField } from "src/types/redux/store/pages/administration/AdministrationFilesPage";
import { color } from "src/theme/variables/color";
import { getColumns } from "src/components/CommonTable/TableRealisation/adminFilesTable/getColumns";
import { useStyles, isUploadInactive } from "./utils";
import { useSortField } from "src/hooks/useSortField";
import { DialogDeleteFile } from "src/components/ModalWindows/myFiles/DialogDeleteFile";

const deleteMessage = (
    id: string,
    uploads: UploadForAdmin[],
    searchParams: UserMessagesRequestParams,
    dispatch: Dispatch<any>,
    setPage: React.Dispatch<React.SetStateAction<PaginationState>>,
) => {
    const messageType = uploads.find(upload => upload.id === id)
        ?.messageType as ReceivedMessageResponseDataMessageTypeEnum;
    const pageToGet = searchParams.page;
    if (uploads.length <= 1 && pageToGet !== 1) {
        setPage({
            pageIndex: pageToGet,
            pageSize: searchParams.size,
        });
    }
    searchParams.page = pageToGet;
    dispatch(
        deleteMessageOnServer({
            messageId: id,
            messageType,
            params: searchParams,
        }),
    );
};

const SORTING_DEFAULT = [{ id: SortField.CREATE_DATE, desc: true }];

const downloadAllFiles = ({ id, files }: UploadForAdmin) => {
    const totalSize = getSumSizeFiles(files);
    downloadFilesAllByAdmin(id, totalSize);
};

const AdminFilesTable = () => {
    const styles = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { filter, loadingUploads, totalElements, uploads } = useSelector(
        (store: Store) => store.pages.administration.administrationFilesPage,
    );

    const { setPagination, pagination, sorting, setSorting } =
        DataGrid.useTableState();
    const [confirmDeleteFileId, setConfirmDeleteFileId] = useState<
        string | null
    >(null);
    const { sortDir, sortField } = useSortField(sorting, SORTING_DEFAULT);

    useEffect(() => {
        dispatch(
            loadUploadedMessagesInfo({
                page: pagination.pageIndex,
                size: pagination.pageSize,
                sortField,
                sortDir,
                hideInactive: filter.hideInactive,
                searchText: filter.searchText,
            }),
        );
    }, [
        filter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        dispatch,
        sortDir,
        sortField,
    ]);

    const confirmDeleteFile = () => {
        if (confirmDeleteFileId) {
            deleteMessage(
                confirmDeleteFileId,
                uploads,
                {
                    page: pagination.pageIndex,
                    size: pagination.pageSize,
                    sortField,
                    sortDir,
                    hideInactive: filter.hideInactive,
                    searchText: filter.searchText,
                },
                dispatch,
                setPagination,
            );
            setConfirmDeleteFileId(null);
        }
    };

    const cancelDeleteFile = () => {
        setConfirmDeleteFileId(null);
    };

    const dropDownMenuItems = useMemo<DropDownMenuItem<UploadForAdmin>[]>(
        () => [
            {
                title: t("admin-files:dropDownMenuItems.download"),
                onClick: function (event: React.MouseEvent, context) {
                    downloadAllFiles(context);
                },
            },
            {
                title: t("admin-files:dropDownMenuItems.delete"),
                onClick: function (event: React.MouseEvent, context) {
                    setConfirmDeleteFileId(context.id);
                },
            },
        ],
        [t],
    );

    return (
        <React.Fragment>
            <div className={styles.table}>
                {!uploads.length && !loadingUploads ? (
                    <NoDataFound />
                ) : (
                    <DataGrid
                        data={uploads}
                        columns={getColumns(t, styles)}
                        enableExpanding
                        enableColumnActions={false}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        manualPagination
                        rowCount={totalElements}
                        state={{
                            pagination,
                            sorting,
                        }}
                        getSubRows={row =>
                            // @ts-ignore
                            row.files?.length > 1 ? row.files : null
                        }
                        enableRowActions
                        positionActionsColumn="last"
                        layoutMode="grid"
                        renderRowActions={({ row }) => {
                            return !row.parentId ? (
                                <DropDownMenu
                                    anchorElement={MoreVert}
                                    items={dropDownMenuItems}
                                    context={row.original}
                                />
                            ) : null;
                        }}
                        muiTableBodyRowProps={({ row }) => {
                            return {
                                sx: {
                                    backgroundColor: !!row.parentId
                                        ? color.sendFileColor.background
                                              .lightGray
                                        : null,
                                    opacity: isUploadInactive(
                                        row.getParentRow()?.original ||
                                            row.original,
                                    )
                                        ? "0.5"
                                        : "1",
                                },
                            };
                        }}
                        displayColumnDefOptions={{
                            "mrt-row-actions": {
                                header: "",
                                size: 68,
                                grow: false,
                            },
                            "mrt-row-expand": {
                                Cell: ({ row, table }) =>
                                    row.original.files?.length > 1 ? (
                                        <ExpandButton table={table} row={row} />
                                    ) : null,
                                size: 68,
                                grow: false,
                            },
                        }}
                    />
                )}
            </div>
            <DialogDeleteFile
                open={confirmDeleteFileId !== null}
                onClose={cancelDeleteFile}
                confirmDelete={confirmDeleteFile}
            />
        </React.Fragment>
    );
};

export default AdminFilesTable;
