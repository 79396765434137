import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import "moment/locale/ru";
import i18n from "src/i18n";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { getVersionInfo } from "../redux/version/actions";
import { color } from "../theme/variables/color";
import { Theme } from "@mui/material/styles";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const StyledDialog = withStyles({
    paper: {
        position: "relative",
        maxWidth: "672px",
        padding: "30px 26px 40px 36px",
        fontSize: "12px",
        lineHeight: "16px",
        fontFamily: "Roboto",
        textAlign: "left",
        overflowY: "visible",
        "& ul": {
            padding: 0,
        },
        "& h2": {
            fontSize: "12px",
            margin: 0,
        },
        "& li": {
            listStyleType: "none",
        },
    },
})(Dialog);

const StyledIconButton = withStyles({
    root: {
        width: "24px",
        height: "24px",
        alignSelf: "start",
        color: color.sendFileColor.onSecondary.text,
        position: "absolute",
        top: "24px",
        right: "36px",
    },
})(IconButton);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingTop: "80px",
            width: "100%",
            maxWidth: "672px",
            margin: "auto",
            [theme.breakpoints.down("sm")]: {
                paddingTop: "60px",
            },
            [theme.breakpoints.down("xs")]: {
                paddingTop: 0,
            },
        },
        paddingBottom: {
            paddingBottom: 10,
        },
        marginTopZero: {
            marginTop: 0,
        },
        textBlock: {
            marginTop: "40px",
        },
        titleAndSubtitle: {
            textAlign: "center",
            [theme.breakpoints.down("xs")]: {
                textAlign: "left",
            },
        },
        content: {
            overflowY: "auto",
            marginBottom: 0,
            marginTop: "12px",
            paddingRight: "10px",
        },
    }),
);

type AboutProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const About = ({ isOpen, onClose }: AboutProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getVersionInfo());
    }, [dispatch]);

    const { appVersionInfo } = useSelector(
        (store: Store) => store.appVersionInfo,
    );
    const fullVersion = `${appVersionInfo?.branch}-${appVersionInfo?.commitHashShort}`;
    const classes = useStyles();
    const advantages = t("about:advantages.list", { returnObjects: true });
    const terms = t("about:terms.list", { returnObjects: true });
    const responsibilities = t("about:terms.responsibilities", {
        returnObjects: true,
    });
    const localeDate = useMemo(
        () =>
            moment(appVersionInfo?.buildDateTime).locale(
                i18n.language.toLowerCase(),
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [i18n.language, appVersionInfo],
    );
    return (
        <StyledDialog open={isOpen} onClose={onClose}>
            <StyledIconButton disableRipple onClick={onClose}>
                <Close fontSize="inherit" />
            </StyledIconButton>
            <DialogTitle className={classes.titleAndSubtitle}>
                {t("about:sendfile").toUpperCase()}
            </DialogTitle>
            <DialogContent className={classes.content}>
                <p
                    className={`${classes.titleAndSubtitle} ${classes.marginTopZero}`}
                >
                    {t("about:version")}
                    {fullVersion}
                    {t("about:from")} {localeDate.format("LL")}
                </p>
                <span>
                    <p>{t("about:title")}</p>
                    <ul>
                        {t("about:advantages.title")}
                        {Object.keys(advantages).map((value, index) => {
                            return (
                                <li key={index}>
                                    {"-"} {advantages[index]}
                                </li>
                            );
                        })}
                    </ul>
                </span>
                <div className={classes.textBlock}>
                    <h2 className={classes.titleAndSubtitle}>
                        {t("about:termsOfUse").toUpperCase()}
                    </h2>
                    <p className={classes.titleAndSubtitle}>
                        {t("about:version")}
                        {fullVersion}
                        {t("about:from")} {localeDate.format("LL")}
                    </p>
                    <span>
                        <p>{t("about:terms.title")}</p>
                        <ul>
                            {Object.keys(terms).map((value, index) => {
                                return (
                                    <li key={index}>
                                        {index + 1}. {terms[index]}
                                    </li>
                                );
                            })}
                        </ul>
                        <ul>
                            {Object.keys(responsibilities).map(
                                (value, index) => {
                                    return (
                                        <li key={index}>
                                            {responsibilities[index]}
                                        </li>
                                    );
                                },
                            )}
                        </ul>
                    </span>
                </div>
            </DialogContent>
        </StyledDialog>
    );
};
