import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import {
    AdminFilesFilter,
    AdministrationFilesPage,
} from "src/types/redux/store/pages/administration/AdministrationFilesPage";
import {
    loadEmails,
    loadUploadedMessagesInfo,
    setAdminFilesSearchFilter,
    toggleUploadExpand,
} from "./actions";
import { Upload, UploadForAdmin } from "src/types/Upload";
import { PageCommonMessageDTO, UserModel } from "../../../services/generated";

export const defaultAdminFilesFilter: AdminFilesFilter = {
    hideInactive: false,
    searchText: "",
};

const initialState: AdministrationFilesPage = {
    uploads: [],
    filter: defaultAdminFilesFilter,
    totalElements: 0,
    totalPages: 0,
    loadingUploads: false,
    emails: [],
};

export const administrationFilesPageReducer =
    createReducer<AdministrationFilesPage>(initialState, {
        [setAdminFilesSearchFilter.type](
            state,
            action: PayloadAction<AdminFilesFilter>,
        ) {
            state.filter = action.payload;
        },
        [loadUploadedMessagesInfo.pending.type](state) {
            state.loadingUploads = true;
        },
        [loadUploadedMessagesInfo.fulfilled.type](
            state,
            action: PayloadAction<PageCommonMessageDTO>,
        ) {
            if (!action.payload?.content) return;
            state.uploads = action.payload.content!.map<UploadForAdmin>(
                content => ({
                    id: content.id,
                    files: content.files!.map(file => ({
                        id: file.id!,
                        name: file.name!,
                        downloadCount: file.downloadCount!,
                        downloadLimit: file.downloadLimit!,
                        size: file.size || 0,
                        storagePath: file.storagePath!,
                    })),
                    recipientEmail: content.recipientEmails || [],
                    dispatchDate: content.expireTime,
                    expanded: content.files?.length! <= 1,
                    senderEmail: content.senderEmail,
                    messageType: content.messageType,
                    security: content.security,
                }),
            );
            state.totalPages = action.payload.totalPages!;
            state.totalElements = action.payload.totalElements!;
            state.loadingUploads = false;
        },
        [loadUploadedMessagesInfo.rejected.type](
            state,
            action: PayloadAction<Array<UserModel>>,
        ) {
            state.loadingUploads = false;
        },
        [toggleUploadExpand.type](state, action: PayloadAction<Upload>) {
            const targetUpload = state.uploads.find(
                upload => upload.id === action.payload.id,
            );
            if (targetUpload) {
                targetUpload.expanded = !targetUpload.expanded;
            }
        },
        [loadEmails.fulfilled.type](
            state,
            action: PayloadAction<Array<string>>,
        ) {
            state.emails = action.payload;
        },
    });
