import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import isAxiosError from "../../../helpers/isError";
import { responseMessageApi } from "../../../services/clientApi";
import { FileDownloadRequestParams } from "../../../types/redux/store/pages/ResponseMessgeDownloadPage";

type GetResponseMessageParams = {
    messageId: string;
    isPublic?: boolean;
};

export const getResponseMessageById = createAsyncThunk(
    "getResponseMessageById",
    async (
        { messageId, isPublic = true }: GetResponseMessageParams,
        thunkAPI,
    ) => {
        try {
            const result = isPublic
                ? await responseMessageApi.getResponseMessageById(messageId)
                : await responseMessageApi.getFilesStatusesForMessage(
                      messageId,
                  );
            if (!result) {
                return thunkAPI.rejectWithValue(403);
            }
            return result.data;
        } catch (e) {
            if (isAxiosError(e)) {
                return thunkAPI.rejectWithValue(e.response.status);
            }
        }
    },
);

export const downloadFileFromResponseMessage = createAsyncThunk(
    "downloadFileFromResponseMessage",
    async (args: FileDownloadRequestParams) => {
        const response = await responseMessageApi.downloadFile(
            args.fileId,
            args.messageId,
        );
        return (window.navigator as any).msSaveBlob(response.data, "test");
    },
);

export const downloadResponseMessage = createAsyncThunk(
    "downloadResponseMessage",
    async (responseMessageId: string) => {
        return (await responseMessageApi.downloadAllFiles(responseMessageId))
            .data;
    },
);

export const resetResponseMessage = createAction("resetResponseMessage");
