import React from "react";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        height: 8,
        width: "100%",
        display: "flex",
        backgroundColor: "#EFEFF4",
    },
    progress: {
        backgroundColor: theme.palette.primary.main,
    },
}));

interface ProgressBarProps {
    all: number;
    completed: number;
    className?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
    all,
    completed,
    className,
}) => {
    const styles = useStyles();

    const completedProcentage = (completed / all) * 100;
    const width =
        isNaN(completedProcentage) || completedProcentage === 0
            ? 0
            : completedProcentage.toFixed(2) + "%";

    return (
        <div className={classNames(className, styles.container)}>
            <div className={styles.progress} style={{ width }} />
        </div>
    );
};
