import React, { useEffect } from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { ReactComponent as CheckboxIconUnchecked } from "../icons/checkboxUnchecked.svg";
import { ReactComponent as CheckboxIconChecked } from "../icons/checkboxChecked.svg";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import {
    FilesFilter,
    MyFilesPage,
} from "src/types/redux/store/pages/MyFilesPage";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store/index";
import {
    setFilesFilter,
    setHideInactive,
    setFilesSearchFilter,
} from "src/redux/pages/myFiles/actions";
import { ButtonToggleType } from "src/types/TabType";
import { ButtonGroupToggle } from "src/components/ButtonGroupToggle";
import { FindInput } from "./FindInput";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        searchTextField: {
            width: "240px",
            paddingBottom: 0,
        },
        icon: {
            opacity: 0.6,
        },
        alignmentTabs: {
            marginLeft: 0,
            [theme.breakpoints.up("sm")]: {
                marginLeft: "-16px",
            },
        },
        toggleButtons: {
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "262px",
            },
        },
        searchFilter: {
            width: "100%",
        },
        checkBoxWrapper: {
            display: "flex",
        },
    }),
);

const mapState = createSelector<
    MyFilesPage,
    MyFilesPage["hideInactive"],
    MyFilesPage["searchFilter"],
    MyFilesPage["filesFilter"],
    Pick<MyFilesPage, "hideInactive" | "searchFilter" | "filesFilter">
>(
    state => state.hideInactive,
    state => state.searchFilter,
    state => state.filesFilter,
    (hideInactive, searchFilter, filesFilter) => ({
        hideInactive,
        searchFilter,
        filesFilter,
    }),
);

interface Props {
    onHideInactiveChange?(hideInactive: boolean): void;
    onSearchInputChange?(searchText: string): void;
    onFilesFilterChange?(type: FilesFilter): void;
}

export const MyFilesTableFilter = (props: Props) => {
    const { onHideInactiveChange, onSearchInputChange, onFilesFilterChange } =
        props;
    const styles = useStyle();
    const dispatch = useDispatch();
    const { hideInactive, filesFilter, searchFilter } = useSelector(
        (store: Store) => mapState(store.pages.myFilesPage),
    );

    const { t } = useTranslation();

    const buttons: ButtonToggleType[] = [
        {
            label: t("my-file:filter.sent"),
            value: FilesFilter.SENT,
        },
        {
            label: t("my-file:filter.received"),
            value: FilesFilter.RECEIVED,
        },
    ];

    const handleHideInactiveChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean,
    ) => {
        dispatch(setHideInactive(checked));
        onHideInactiveChange?.(checked);
    };

    const handleFileFilterChange = (value: FilesFilter) => {
        dispatch(setFilesFilter(value));
        dispatch(setHideInactive(false));
        dispatch(setFilesSearchFilter(null));
        onFilesFilterChange?.(value);
    };

    const clearFilter = () => {
        dispatch(setFilesSearchFilter(null));
    };

    const handleFindInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const text = event.currentTarget.value;
        dispatch(setFilesSearchFilter(text));
        onSearchInputChange?.(text);
    };

    useEffect(() => {
        return () => {
            dispatch(setFilesFilter(FilesFilter.SENT));
            dispatch(setHideInactive(false));
            dispatch(setFilesSearchFilter(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} sm={8}>
                <Grid container spacing={4} alignItems="center">
                    <Grid
                        xs={12}
                        sm={"auto"}
                        item
                        className={styles.alignmentTabs}
                    >
                        <ButtonGroupToggle
                            onChange={handleFileFilterChange}
                            buttons={buttons}
                            value={filesFilter}
                            className={styles.toggleButtons}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        sm={"auto"}
                        className={styles.checkBoxWrapper}
                        item
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={handleHideInactiveChange}
                                    checked={hideInactive}
                                    name="checkbox"
                                    icon={<CheckboxIconUnchecked />}
                                    checkedIcon={<CheckboxIconChecked />}
                                />
                            }
                            label={t("my-file:filter.hideInactive")}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container justifyContent="flex-end">
                    <FindInput
                        clear={clearFilter}
                        className={styles.searchFilter}
                        value={searchFilter}
                        onChange={handleFindInputChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
