import { enqueueError, enqueueSuccess } from "./enqueueError";
import dayjs, { Dayjs } from "dayjs";
import {
    adminFilesApi,
    messageApi,
    responseMessageApi,
    logFilesApi,
} from "src/services/clientApi";
import { AxiosPromise } from "axios";
import { ISO_FORMAT_DATE } from "src/constants/momentFormats";
import { store } from "src/redux/store";
import {
    addFile,
    removeFile,
    updateDownloadProgress,
} from "src/redux/downloadMenu/actions";
import { FileViewModel } from "src/types/FileDisplay";
import { FileStatus } from "src/services/generated";
import i18n from "src/i18n";

const abortControllers = new Map();

const dispatch = store.dispatch;

store.subscribe(() => {
    const {
        downloadMenuInfo: { loadedFiles },
    } = store.getState();
    for (const file of loadedFiles) {
        if (file.status === FileStatus.UPLD_PART) {
            abortControllers.get(file.id).abort();
        }
    }
});

const onDownloadProgress = (
    progressEvent: ProgressEvent,
    file: FileViewModel,
) => {
    const progress = file.size
        ? Math.round((progressEvent.loaded * 100) / file.size)
        : null;

    dispatch(updateDownloadProgress({ ...file, progress }));
};

const createArchiveName = () => {
    const date = dayjs();
    return `Archive_${date.date()}_${date.month()}_${date.year()}.zip`;
};

const isRecurringDownload = (file: FileViewModel) => {
    const {
        downloadMenuInfo: { loadedFiles },
    } = store.getState();
    return (
        loadedFiles.length > 0 && loadedFiles.some(({ id }) => file.id === id)
    );
};

const getOption = (file: FileViewModel) => {
    const controller = new AbortController();
    abortControllers.set(file.id, controller);

    return {
        responseType: "blob",
        signal: controller.signal,
        onDownloadProgress: (progressEvent: ProgressEvent) => {
            onDownloadProgress(progressEvent, file);
        },
    };
};

const download = (url: string, nameFile?: string) => {
    const link = document.createElement("a");
    link.setAttribute("href", url);
    if (nameFile) {
        link.download = nameFile;
    }
    link.click();
};

const getAndDownloadBlob = async (
    blobDataPromise: () => AxiosPromise<object>,
    file?: FileViewModel,
    errorText?: string,
) => {
    try {
        if (isRecurringDownload(file)) {
            return;
        }

        dispatch(addFile({ ...file, status: FileStatus.UPLOADING }));
        enqueueSuccess(i18n.t("admin-files:notifications.download"));
        const { data, headers } = await blobDataPromise();
        dispatch(removeFile(file.id));
        abortControllers.delete(file.id);
        const url = window.URL.createObjectURL(data as Blob);
        const name =
            file.name ||
            decodeURIComponent(
                headers["content-disposition"]
                    .split("filename=")[1]
                    .split(";")[0],
            );
        download(url, name);
    } catch (e) {
        (e as Error)?.message === "canceled" ||
            enqueueError(errorText || (e as Error)?.message);
        dispatch(removeFile(file.id));
        abortControllers.delete(file.id);
    }
};

export const downloadFileByAdmin = async (
    messageId: string,
    fileId: string,
    size: number,
    name: string,
) => {
    const file: FileViewModel = {
        id: fileId,
        name,
        size,
    };

    return getAndDownloadBlob(
        () => adminFilesApi.downloadFile2(messageId, fileId, getOption(file)),
        file,
    );
};

export const downloadFilesAllByAdmin = (messageId: string, size?: number) => {
    const file: FileViewModel = {
        id: messageId,
        name: createArchiveName(),
        size,
    };

    return getAndDownloadBlob(
        () => adminFilesApi.downloadFiles(messageId, getOption(file)),
        file,
    );
};

export const downloadFileByUser = async (
    messageId: string,
    fileId: string,
    password: string,
) => {
    const url =
        (await messageApi.getLinkToFile1(messageId, fileId)).data +
        (password ? `?password=${password}` : "");
    download(url);
};

export const downloadAllFileByUser = async (
    messageId: string,
    size: number,
    password?: string,
) => {
    const file: FileViewModel = {
        id: messageId,
        name: createArchiveName(),
        size,
    };

    return getAndDownloadBlob(
        () =>
            messageApi.downloadAllFiles1(messageId, password, getOption(file)),
        file,
    );
};

export const downloadResponceFileByUser = async (
    messageId: string,
    fileId: string,
) => {
    const url = (await responseMessageApi.getLinkToFile(messageId, fileId))
        .data;
    download(url);
};

export const downloadResponceAllFileByUser = async (
    messageId: string,
    size: number,
) => {
    const file: FileViewModel = {
        id: messageId,
        name: createArchiveName(),
        size,
    };
    return getAndDownloadBlob(
        () => responseMessageApi.downloadAllFiles(messageId, getOption(file)),
        file,
    );
};

export const downloadLogFile = (
    fileName: string,
    size: number,
    errorText?: string,
) => {
    const file: FileViewModel = {
        id: fileName,
        name: fileName,
        size,
    };
    return getAndDownloadBlob(
        () =>
            logFilesApi.download1(
                fileName,
                getOption(file),
            ) as unknown as AxiosPromise<object>,
        file,
        errorText,
    );
};

export const downloadAllLogFiles = async (
    dateRange: Dayjs[],
    errorText?: string,
) => {
    const name = dateRange
        ? `Log_files_${dateRange?.[0].format(
              "DD_MM_YYYY",
          )}-${dateRange?.[1].format("DD_MM_YYYY")}.zip`
        : "All_log_files.zip";

    const logFileDownloadRequest = dateRange
        ? {
              fromDate: `${dateRange?.[0].format(ISO_FORMAT_DATE)}`,
              toDate: `${dateRange?.[1].format(ISO_FORMAT_DATE)}`,
          }
        : {};

    const file: FileViewModel = {
        id: name,
        name,
    };

    return getAndDownloadBlob(
        () =>
            logFilesApi.download(
                logFileDownloadRequest,
                getOption(file),
            ) as unknown as AxiosPromise<object>,
        file,
        errorText,
    );
};
