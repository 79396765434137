import React, { FC, ReactNode } from "react";
import {
    QueryClient,
    QueryClientProvider,
    QueryCache,
    MutationCache,
    DefaultOptions,
} from "react-query";
import { enqueueError } from "./enqueueError";

const onError = (e: any) => {
    enqueueError(e?.message ?? e?.error);
};
interface QueryClientConfig {
    queryCache?: QueryCache;
    mutationCache?: MutationCache;
    defaultOptions?: DefaultOptions;
}

const config: QueryClientConfig = {
    defaultOptions: {
        queries: {
            onError,
            refetchOnWindowFocus: false,
        },
        mutations: {
            onError,
        },
    },
};
const queryClient = new QueryClient(config);

export const ReactQueryProviders: FC<{ children: ReactNode }> = ({
    children,
}) => (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

type ApiError = {
    error: string;
    message: string;
};
export function isApiError(obj: any): obj is ApiError {
    return (
        (obj as ApiError).error !== undefined ||
        (obj as ApiError).message !== undefined
    );
}
