import {
    LoginControllerApiFactory,
    Configuration,
    MessagesApiFactory,
    MyFilesApiFactory,
    RequestMessageControllerApiFactory,
    ResponseMessageControllerApiFactory,
    StorageApiFactory,
    UsersApiFactory,
    SettingsControllerApiFactory,
    TechSupportControllerApiFactory,
    StatisticsControllerApiFactory,
    FilesControllerApiFactory,
    LogFileControllerApiFactory,
    PublicSettingsControllerApiFactory,
    PrivateSettingsControllerApiFactory,
    GitInfoControllerApiFactory,
    AdminMessageControllerApiFactory,
    RefControllerApiFactory,
    ReportsControllerApiFactory,
} from "./generated";
import axios from "axios";
import { decode } from "src/helpers/jwt";
import { JWT_MULTIPLIER, TEN_MINUTES_IN_MS } from "src/helpers/dateUtils";
import { enqueueError } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import {
    ACCESS_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
} from "src/constants/localstorageKeys";
import { store } from "src/redux/store";
import { logOut, loginViaRefreshToken } from "src/redux/pages/login/action";

const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

const basePath = "/api";
const configurationWithOptions = new Configuration({
    accessToken,
});

export const techSupportApi = TechSupportControllerApiFactory();
export const loginApi = LoginControllerApiFactory(
    configurationWithOptions,
    basePath,
);
export const reportsApi = ReportsControllerApiFactory(
    configurationWithOptions,
    basePath,
);
export const userApi = UsersApiFactory(configurationWithOptions, basePath);
export const storageApi = StorageApiFactory(configurationWithOptions, basePath);
export const messageApi = MessagesApiFactory(
    configurationWithOptions,
    basePath,
);
export const logFilesApi = LogFileControllerApiFactory(
    configurationWithOptions,
    basePath,
);

export const requestFilesApi = RequestMessageControllerApiFactory(
    configurationWithOptions,
    basePath,
);
export const responseMessageApi = ResponseMessageControllerApiFactory(
    configurationWithOptions,
    basePath,
);
export const myFilesApi = MyFilesApiFactory(configurationWithOptions, basePath);
export const settingsApiService = SettingsControllerApiFactory(
    configurationWithOptions,
    basePath,
);
export const statisticsApi = StatisticsControllerApiFactory(
    configurationWithOptions,
    basePath,
);

export const controllerApi = FilesControllerApiFactory(
    configurationWithOptions,
    basePath,
);
export const infoControlleApi = GitInfoControllerApiFactory(
    configurationWithOptions,
    basePath,
);

export const publicSettingsApi = PublicSettingsControllerApiFactory(
    configurationWithOptions,
    basePath,
);

export const privateSettingsApi = PrivateSettingsControllerApiFactory(
    configurationWithOptions,
    basePath,
);

export const adminFilesApi = AdminMessageControllerApiFactory(
    configurationWithOptions,
    basePath,
);

export const adminFilesEmailsApi = RefControllerApiFactory(
    configurationWithOptions,
    basePath,
);

export const loginUrl = "/login";

export const updateAccessToken = (newToken: string) => {
    configurationWithOptions.accessToken = newToken;
};

axios.interceptors.response.use(
    fulfilled => fulfilled,
    async error => {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
        if (
            error?.response?.status === 500 &&
            error?.response.data.errorCodeStr
        ) {
            enqueueError(i18n.t(error.response.data.errorCodeStr));
        }

        if (error?.response?.status === 401) {
            if (refreshToken) {
                // @ts-ignore
                store.dispatch(loginViaRefreshToken(refreshToken));
            } else {
                store.dispatch(logOut());
            }
        }

        return Promise.reject(error);
    },
);

axios.interceptors.request.use(async config => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (
        accessToken &&
        refreshToken &&
        !config.url?.includes("/token/recreateAccessToken")
    ) {
        const decodedAccessToken = decode(accessToken);
        const decodedRefreshToken = decode(refreshToken);
        if (
            decodedAccessToken.exp * JWT_MULTIPLIER - Date.now() <
                TEN_MINUTES_IN_MS &&
            decodedRefreshToken.exp * JWT_MULTIPLIER > Date.now()
        ) {
            // @ts-ignore
            store.dispatch(loginViaRefreshToken(refreshToken));
        }
    }

    return config;
});
