import React from "react";
import { Controller } from "../FieldController";
import { ControllerRenderProps } from "react-hook-form";
import {
    DateRangePicker,
    DateRangePickerProps,
} from "src/components/DatePicker/DateRangePicker";
import dayjs from "dayjs";

type FieldDateRangeProps = {
    name: string;
    controllerProps?: Omit<ControllerRenderProps, "name">;
} & DateRangePickerProps;

export const FieldDateRange = ({
    name,
    controllerProps,
    value: defaultValue,
    ...inputProps
}: FieldDateRangeProps) => (
    <Controller name={name} defaultValue={defaultValue} {...controllerProps}>
        {({ field: { value, onChange } }) => (
            <DateRangePicker
                {...inputProps}
                value={
                    value ? [dayjs(value?.start), dayjs(value?.end)] : undefined
                }
                onChangeDate={v => {
                    onChange({ start: v[0].toDate(), end: v[1].toDate() });
                }}
                onReset={() => {
                    onChange(undefined);
                }}
            />
        )}
    </Controller>
);
