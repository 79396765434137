import i18n from "src/i18n";
import { Languages } from "src/types/Languages";

export const ISO_FORMAT_DATE = "YYYY-MM-DDTHH:mm:ss[Z]";

export const commonDateTimeFormat = () =>
    i18n.language === Languages.EN
        ? "MM/DD/YYYY, HH:mm:ss"
        : "DD.MM.YYYY, HH:mm:ss";
export const commonDateFormat = () =>
    i18n.language === Languages.EN ? "MM/DD/YYYY" : "DD.MM.YYYY";
export const commonYearFormat = () => "YYYY";
export const commonYearAndMonthFormat = () =>
    i18n.language === Languages.EN ? "MM/YYYY" : "MM.YYYY";
