import React from "react";
import { Button, Link, TextField, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Logo } from "../Logo";
import { ClosingDialogTitle } from "./СlosingDialogTitle";

const useStyles = makeStyles((theme: Theme) => ({
    retentionPolicy: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        textAlign: "start",
        marginTop: "24px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "40px",
    },
    button: {
        minWidth: "120px",
    },
    messagePrefix: {
        marginTop: "40px",
        textAlign: "start",
        paddingBottom: "24px",
    },
}));

type Props = {
    messagePrefix?: string;
    sender?: string;
    messageText?: string;
    retentionPolicy?: string;
    onClose?: () => void;
};

export const EmailPreview = ({
    messagePrefix,
    sender,
    messageText,
    retentionPolicy,
    onClose,
}: Props) => {
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <ClosingDialogTitle onClose={onClose}>
                <Logo />
            </ClosingDialogTitle>

            <div className={styles.messagePrefix}>
                {messagePrefix}
                <Link>{sender}</Link>
            </div>
            {messageText && (
                <TextField
                    name="message"
                    label={t("common:modals.emailPreviewWindow.messageText")}
                    fullWidth
                    multiline
                    maxRows={10}
                    value={messageText}
                    inputProps={{ readOnly: true }}
                />
            )}
            <div className={styles.retentionPolicy}>{retentionPolicy}</div>
            <div className={styles.buttonContainer}>
                <Button
                    color="primary"
                    onClick={onClose}
                    className={styles.button}
                >
                    {t("common:modals.emailPreviewWindow.close")}
                </Button>
            </div>
        </div>
    );
};
