import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import EditOrCreateStorage from "src/components/ModalWindows/Admin/EditOrCreateStorage";
import {
    deleteStorage,
    getNewStorageSettings,
    loadStorages,
} from "src/redux/pages/administrationStorages/actions";
import { ClosingDialogTitle } from "src/components/ModalWindows/СlosingDialogTitle";
import { StorageFilter } from "src/components/filters/StorageFilter";
import { StoragesTable } from "src/components/CommonTable/TableRealisation/storagesTable/StoragesTable";
import styled from "@emotion/styled";

export const AdministrationStoragesPage = () => {
    const dispatch = useDispatch();

    const { commonDialog } = useSelector((store: Store) => store.dialogs);
    const [storagesFilter, setStoragesFilter] = useState("");
    const [confirmDeleteStorageId, setConfirmDeleteStorageId] = useState<
        number | undefined
    >();

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(loadStorages());
        dispatch(getNewStorageSettings());
    }, [dispatch]);

    const closeDeleteStorageModal = () => {
        setConfirmDeleteStorageId(undefined);
    };

    const onDeleteStorage = (id: number) => {
        dispatch(deleteStorage(id));
        closeDeleteStorageModal();
    };

    const handleStoragesFilterChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setStoragesFilter(event.target.value);
    };

    return (
        <Container>
            <StorageFilter
                handleStoragesFilterChange={handleStoragesFilterChange}
            />
            <StoragesTable
                storagesFilter={storagesFilter}
                setConfirmDeleteStorageId={setConfirmDeleteStorageId}
            />

            {commonDialog.dialog.dialogName === "EditOrCreateStorage" &&
                commonDialog.dialog.isOpen && <EditOrCreateStorage />}

            <StyledDialog
                PaperProps={{ sx: { width: "440px" } }}
                open={!!confirmDeleteStorageId}
                onClose={closeDeleteStorageModal}
            >
                <ClosingDialogTitle onClose={closeDeleteStorageModal}>
                    {t("admin-storages:modals.delete")}
                </ClosingDialogTitle>

                <DialogContent />
                <DialogActions>
                    <Button color="secondary" onClick={closeDeleteStorageModal}>
                        {t("common:cancel")}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => onDeleteStorage(confirmDeleteStorageId)}
                    >
                        {t("common:delete")}
                    </Button>
                </DialogActions>
            </StyledDialog>
        </Container>
    );
};

const Container = styled.div`
    height: 100%;
`;

const StyledDialog = styled(Dialog)`
    & .MuiDialog-paper {
        width: 300px;
    }
`;

export default AdministrationStoragesPage;
