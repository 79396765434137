import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { storageApi } from "src/services/clientApi";
import i18n from "src/i18n";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import { closeDialog } from "src/redux/dialogs/commonDialog/actions";
import { LocalFileStorageDTO } from "src/services/generated";

export const selectEditStorage =
    createAction<LocalFileStorageDTO>("selectEditStorage");
export const disposeError = createAction("disposeError");
export const disposeEditingStorage = createAction("disposeEditingStorage");

export const getNewStorageSettings = createAsyncThunk(
    "getNewStorageSettings",
    async () => {
        return (await storageApi.getNewStorageSettings()).data;
    },
);

export const loadStorages = createAsyncThunk(
    "loadStorages",
    async (_, thunkAPI) => {
        try {
            const response = await storageApi.getAll();
            return response.data;
        } catch (error) {
            enqueueError(i18n.t("admin-storages:errors.get"));
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const editStorage = createAsyncThunk(
    "editStorage",
    async (
        { id, size, path, basePath, ...other }: LocalFileStorageDTO,
        thunkAPI,
    ) => {
        try {
            const response = await storageApi.update({
                id,
                size,
                path,
                basePath,
            });
            enqueueSuccess(i18n.t("admin-storages:success.edit"));
            thunkAPI.dispatch(closeDialog("EditStorageWindow"));
            thunkAPI.dispatch(disposeError());
            thunkAPI.dispatch(disposeEditingStorage());
            return response.data;
        } catch (error) {
            enqueueError(i18n.t("admin-storages:errors.edit"));
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const deleteStorage = createAsyncThunk(
    "deleteStorage",
    async (id: number, thunkAPI) => {
        try {
            await storageApi._delete(id);
            enqueueSuccess(i18n.t("admin-storages:success.delete"));
            return id;
        } catch (error) {
            enqueueError(i18n.t("admin-storages:errors.delete"));
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const createStorage = createAsyncThunk(
    "createStorage",
    async (
        {
            path,
            size,
            basePath,
        }: Pick<LocalFileStorageDTO, "path" | "size" | "basePath">,
        thunkAPI,
    ) => {
        try {
            const storage = (await storageApi.create({ size, path, basePath }))
                .data;
            enqueueSuccess(i18n.t("admin-storages:success.create"));
            thunkAPI.dispatch(closeDialog("EditStorageWindow"));
            thunkAPI.dispatch(disposeError());
            thunkAPI.dispatch(disposeEditingStorage());
            return storage;
        } catch (error) {
            if (!(error as any)?.response?.data?.errorCodeStr) {
                enqueueError(i18n.t("admin-storages:errors.create"));
            }
            return thunkAPI.rejectWithValue(error);
        }
    },
);

export const reorderStorages = createAsyncThunk(
    "reorderStorages",
    async (storages: LocalFileStorageDTO[], thunkAPI) => {
        try {
            const response = await storageApi.reorder({
                order: storages.map(storage => storage.id),
            });
            return response.data;
        } catch (error) {
            if (!(error as any)?.response?.data?.errorCodeStr) {
                enqueueError(i18n.t("admin-storages:errors.create"));
            }
            return thunkAPI.rejectWithValue(error);
        }
    },
);
