import { values } from "lodash";
import { useQuery } from "react-query";
import { PaginationState, SortingState } from "src/components/DataGrid";
import {
    reportsApi,
    userApi,
    adminFilesEmailsApi,
} from "src/services/clientApi";
import {
    ReportsUserRequestParams,
    ReportsFilesRequestParams,
} from "src/services/generated";
import { usePaginatedDataStore } from "./utils";
import { useSortField } from "src/hooks/useSortField";

const GET_USERS_REPORTS = "GET_USERS_REPORTS";
const GET_FILES_REPORTS = "GET_FILES_REPORTS";

const getUsersReports = async (params: ReportsUserRequestParams) => {
    return (await reportsApi.getReportsUser(params)).data;
};

const getFilesReports = async (params: ReportsFilesRequestParams) => {
    return (await reportsApi.getReportsFile(params)).data;
};

export type useUsersReportsParams = {
    filters: Pick<ReportsUserRequestParams, "date" | "users">;
    sorting: SortingState;
    pagination: PaginationState;
};
export const useUsersReports = ({
    filters,
    pagination,
    sorting,
}: useUsersReportsParams) => {
    const { sortDir, sortField } = useSortField(sorting, [
        { id: "session", desc: true },
    ]);

    const query = useQuery(
        [
            GET_USERS_REPORTS,
            values(pagination),
            values(filters),
            sortDir,
            sortField,
        ],
        () =>
            getUsersReports({
                date: filters?.date,
                users: filters?.users,
                page: pagination?.pageIndex,
                size: pagination?.pageSize,
                sortDir,
                sortField,
            }),
        { keepPreviousData: true },
    );

    const allData = usePaginatedDataStore({
        content: query.data?.content,
        pageNumber: query.data?.number,
    });

    return { ...query, allData };
};

export type useFilesReportsParams = {
    filters: ReportsFilesRequestParams;
    sorting: SortingState;
    pagination: PaginationState;
};
export const useFilesReports = ({
    filters = {},
    pagination,
    sorting,
}: useFilesReportsParams) => {
    const { sortDir, sortField } = useSortField(sorting, [
        { id: "date", desc: true },
    ]);
    const query = useQuery(
        [
            GET_FILES_REPORTS,
            values(pagination),
            values(filters),
            sortDir,
            sortField,
        ],
        () =>
            getFilesReports({
                fileName: filters?.fileName,
                date: filters?.date,
                emails: filters?.emails,
                actions: filters?.actions,
                text: filters?.text,
                theme: filters?.theme,
                fileSize: filters?.fileSize,
                lifetime: filters?.lifetime,
                fileReportStatuses: filters?.fileReportStatuses,

                page: pagination?.pageIndex,
                size: pagination?.pageSize,
                sortDir,
                sortField,
            }),
        { keepPreviousData: true },
    );
    const allData = usePaginatedDataStore({
        content: query.data?.content,
        pageNumber: query.data?.number,
    });

    return { ...query, allData };
};

const GET_USERS_LIST = "GET_USERS_LIST";
const getUsersList = async () => {
    return (await userApi.getUsers()).data;
};
export const useUsersList = () => {
    return useQuery(GET_USERS_LIST, getUsersList);
};

const GET_EMAILS_LIST = "GET_EMAILS_LIST";
const getEmailsList = async () => {
    return (await adminFilesEmailsApi.getEmailsFromAD()).data;
};
export const useEmailsList = () => {
    return useQuery(GET_EMAILS_LIST, getEmailsList);
};
