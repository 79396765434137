import { ComponentsOverrides } from "@mui/material/styles/overrides";

export const MuiMenuItem: ComponentsOverrides["MuiMenuItem"] = {
    root: {
        paddingTop: 9,
        paddingBottom: 9,
        fontSize: 14,
    },
};

export default MuiMenuItem;
