import jwtDecode from "jwt-decode";
import { UserModel, UserModelRolesEnum } from "src/services/generated";

export enum UserRoleUtils {
    ADMIN = "ADMIN",
    SECURITY = "SECURITY",
    USER = "USER",
    GUEST = "GUEST",
}

interface UserFromJWT {
    roles: string;
    exp: number;
    iat: number;
    sub: string;
}

export const getUserModelFromToken = (token: string): UserModel => {
    const decodedTocken = decode(token);

    return {
        email: decodedTocken.sub,
        roles: decodedTocken.roles.split(",") as UserModelRolesEnum[],
    };
};

export function decode(token: string): UserFromJWT {
    return jwtDecode(token) as UserFromJWT;
}
