import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { useForm } from "src/components/Form";
import { FileSecurePasswordForm } from "../types/redux/store/pages/DownloadPage";
import { getMessageForDownloading } from "../redux/pages/downloadPage/actions";
import { Store } from "src/types/redux/store";
import { useParams } from "react-router-dom";
import { PasswordWithEye } from "./PasswordWithEye";
import { sha256 } from "js-sha256";

const useStyle = makeStyles({
    mainText: {
        color: "#000000",
        fontSize: "16px",
        fontWeight: "bold",
    },
    email: {
        color: "#4A90E2",
        textDecoration: "underline",
    },
    unblockButton: {
        width: "200px",
    },
});

export const FilesSecurePassword: FC = () => {
    const { messageId } = useParams<{ messageId: string }>();
    const { t } = useTranslation();
    const styles = useStyle();
    const dispatch = useDispatch();
    const [password, setPasswoed] = useState("");
    const { forbidden } = useSelector(
        (store: Store) => store.pages.downloadPage,
    );
    const { handleSubmit, register } = useForm<FileSecurePasswordForm>({
        defaultValues: { password },
    });

    const sendForm = ({ password }: FileSecurePasswordForm) => {
        dispatch(
            getMessageForDownloading({ messageId, password: sha256(password) }),
        );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const password = e.target.value;
        setPasswoed(password);
    };

    return (
        <form onSubmit={handleSubmit(sendForm)}>
            <div className={styles.mainText}>
                {t("download-files:passwordProtection")}
            </div>
            <PasswordWithEye
                label={t("download-files:enterPassword")}
                {...register("password", {
                    onChange: handleChange,
                })}
                error={forbidden}
                helperText={
                    forbidden ? t("download-files:wrongPassword") : undefined
                }
            />
            <Button
                type="submit"
                color="primary"
                className={styles.unblockButton}
                disabled={password === ""}
            >
                {t("download-files:unblock")}
            </Button>
        </form>
    );
};
