import styleVariables from "../variables";

export const MuiDialog = {
    container: {
        background: "rgba(26,31,41, 0.38)",
    },
    paper: {
        padding: styleVariables.spacing * 6,
        margin: "16px",
    },
};
