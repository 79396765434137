import dayjs from "dayjs";
import React, { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
    DataGrid,
    DataGridProps,
    DatagridColumn,
} from "src/components/DataGrid";
import { PageReportsUserDTO, ReportsUserDTO } from "src/services/generated";
import { commonDateTimeFormat } from "src/constants/momentFormats";
import { ShowOnlySelected } from "./utils";

type AdminUsersReportsTableProps = {
    onPaginationChange: DataGridProps<ReportsUserDTO>["onPaginationChange"];
    onSortingChange: DataGridProps<ReportsUserDTO>["onSortingChange"];
    onRowSelectionChange: DataGridProps<ReportsUserDTO>["onRowSelectionChange"];
    table: {
        state: DataGridProps<ReportsUserDTO>["state"];
        data?: PageReportsUserDTO["content"];
        rowCount?: number;
    };
    allData?: {
        [k: number]: ReportsUserDTO[];
    };
    isFiltersDefault?: boolean;
};
export const AdminUsersReportsTable = ({
    onPaginationChange,
    onSortingChange,
    onRowSelectionChange,
    table,
    allData,
    isFiltersDefault,
}: AdminUsersReportsTableProps) => {
    const { t } = useTranslation("admin-reports");
    const [isShowSelected, setIsShowSelected] = useState(false);
    const selectedKeys = Object.keys(table.state.rowSelection);
    const { filteredData } = ShowOnlySelected.useSelected({
        allData,
        getItemId,
        selectedKeys,
    });

    return (
        <>
            {/* TODO: Добавить после реализации на беке https://jira.artezio.com/browse/ARTSF-2101 */}
            {/* <Grid
        item
        container
        xs={12}
        sm="auto"
        alignItems="stretch"
        alignContent="flex-start"
        zeroMinWidth
        spacing={4}
        marginBottom={6}
      >
        <Grid item xs={12} sm="auto">
          <Button
            fullWidth
            color="primary"
            startIcon={<Report fill="white" />}
            onClick={() => {
              console.log("export selected");
            }}
          >
            {t("exportSelected")}
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            fullWidth
            startIcon={<Report />}
            color="secondary"
            onClick={() => console.log("export all")}
          >
            {isFiltersDefault ? t("exportAll") : t("exportFiltered")}
          </Button>
        </Grid>
      </Grid> */}
            <ShowOnlySelected setIsShowSelected={setIsShowSelected} />
            <DataGrid<ReportsUserDTO>
                getRowId={(row, idx) => {
                    if (!row.senderEmail) return idx.toString();
                    return getItemId(row);
                }}
                enableRowSelection
                manualPagination
                onRowSelectionChange={onRowSelectionChange}
                state={table.state}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                data={isShowSelected ? filteredData : table.data}
                rowCount={isShowSelected ? filteredData.length : table.rowCount}
                columns={getColumns(t)}
                enableColumnDragging={false}
            />
        </>
    );
};

const getItemId = (item: ReportsUserDTO) => item.senderEmail + item.eventDate;

const getColumns: (t: TFunction) => DatagridColumn<ReportsUserDTO>[] = t => [
    {
        accessorKey: "senderEmail",
        header: t("tableUsers.headers.user"),
    },
    {
        accessorKey: "initials",
        header: t("tableUsers.headers.initials"),
        Cell: ({ cell }) => {
            return <>{cell.getValue()}</>;
        },
    },
    {
        accessorKey: "eventDate",
        header: t("tableUsers.headers.eventDate"),
        Cell: ({ cell, row }) => {
            const eventDate = dayjs(cell.getValue()?.toString()).format(
                commonDateTimeFormat(),
            );
            return (
                <>
                    {t(`tableUsers.eventType.${row.original.eventType}`, {
                        eventDate,
                    })}
                </>
            );
        },
    },
];
