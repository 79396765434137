import { ComponentsOverrides } from "@mui/material/styles/overrides";

const MuiListItemText: ComponentsOverrides["MuiListItemText"] = {
    secondary: {
        opacity: "0.6",
    },
    primary: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
};

export default MuiListItemText;
