/* tslint:disable */
/* eslint-disable */
/**
 * SendFile API
 * Back-end and external API Description for SendFile
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sendfile@artezio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'status'?: ApiErrorStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiError
     */
    'status_code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    'errorCodeStr'?: string;
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof ApiError
     */
    'validationErrors'?: Array<ValidationError>;
}

export const ApiErrorStatusEnum = {
    _100_CONTINUE: '100 CONTINUE',
    _101_SWITCHING_PROTOCOLS: '101 SWITCHING_PROTOCOLS',
    _102_PROCESSING: '102 PROCESSING',
    _103_CHECKPOINT: '103 CHECKPOINT',
    _200_OK: '200 OK',
    _201_CREATED: '201 CREATED',
    _202_ACCEPTED: '202 ACCEPTED',
    _203_NON_AUTHORITATIVE_INFORMATION: '203 NON_AUTHORITATIVE_INFORMATION',
    _204_NO_CONTENT: '204 NO_CONTENT',
    _205_RESET_CONTENT: '205 RESET_CONTENT',
    _206_PARTIAL_CONTENT: '206 PARTIAL_CONTENT',
    _207_MULTI_STATUS: '207 MULTI_STATUS',
    _208_ALREADY_REPORTED: '208 ALREADY_REPORTED',
    _226_IM_USED: '226 IM_USED',
    _300_MULTIPLE_CHOICES: '300 MULTIPLE_CHOICES',
    _301_MOVED_PERMANENTLY: '301 MOVED_PERMANENTLY',
    _302_FOUND: '302 FOUND',
    _302_MOVED_TEMPORARILY: '302 MOVED_TEMPORARILY',
    _303_SEE_OTHER: '303 SEE_OTHER',
    _304_NOT_MODIFIED: '304 NOT_MODIFIED',
    _305_USE_PROXY: '305 USE_PROXY',
    _307_TEMPORARY_REDIRECT: '307 TEMPORARY_REDIRECT',
    _308_PERMANENT_REDIRECT: '308 PERMANENT_REDIRECT',
    _400_BAD_REQUEST: '400 BAD_REQUEST',
    _401_UNAUTHORIZED: '401 UNAUTHORIZED',
    _402_PAYMENT_REQUIRED: '402 PAYMENT_REQUIRED',
    _403_FORBIDDEN: '403 FORBIDDEN',
    _404_NOT_FOUND: '404 NOT_FOUND',
    _405_METHOD_NOT_ALLOWED: '405 METHOD_NOT_ALLOWED',
    _406_NOT_ACCEPTABLE: '406 NOT_ACCEPTABLE',
    _407_PROXY_AUTHENTICATION_REQUIRED: '407 PROXY_AUTHENTICATION_REQUIRED',
    _408_REQUEST_TIMEOUT: '408 REQUEST_TIMEOUT',
    _409_CONFLICT: '409 CONFLICT',
    _410_GONE: '410 GONE',
    _411_LENGTH_REQUIRED: '411 LENGTH_REQUIRED',
    _412_PRECONDITION_FAILED: '412 PRECONDITION_FAILED',
    _413_PAYLOAD_TOO_LARGE: '413 PAYLOAD_TOO_LARGE',
    _413_REQUEST_ENTITY_TOO_LARGE: '413 REQUEST_ENTITY_TOO_LARGE',
    _414_URI_TOO_LONG: '414 URI_TOO_LONG',
    _414_REQUEST_URI_TOO_LONG: '414 REQUEST_URI_TOO_LONG',
    _415_UNSUPPORTED_MEDIA_TYPE: '415 UNSUPPORTED_MEDIA_TYPE',
    _416_REQUESTED_RANGE_NOT_SATISFIABLE: '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    _417_EXPECTATION_FAILED: '417 EXPECTATION_FAILED',
    _418_I_AM_A_TEAPOT: '418 I_AM_A_TEAPOT',
    _419_INSUFFICIENT_SPACE_ON_RESOURCE: '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    _420_METHOD_FAILURE: '420 METHOD_FAILURE',
    _421_DESTINATION_LOCKED: '421 DESTINATION_LOCKED',
    _422_UNPROCESSABLE_ENTITY: '422 UNPROCESSABLE_ENTITY',
    _423_LOCKED: '423 LOCKED',
    _424_FAILED_DEPENDENCY: '424 FAILED_DEPENDENCY',
    _425_TOO_EARLY: '425 TOO_EARLY',
    _426_UPGRADE_REQUIRED: '426 UPGRADE_REQUIRED',
    _428_PRECONDITION_REQUIRED: '428 PRECONDITION_REQUIRED',
    _429_TOO_MANY_REQUESTS: '429 TOO_MANY_REQUESTS',
    _431_REQUEST_HEADER_FIELDS_TOO_LARGE: '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    _451_UNAVAILABLE_FOR_LEGAL_REASONS: '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    _500_INTERNAL_SERVER_ERROR: '500 INTERNAL_SERVER_ERROR',
    _501_NOT_IMPLEMENTED: '501 NOT_IMPLEMENTED',
    _502_BAD_GATEWAY: '502 BAD_GATEWAY',
    _503_SERVICE_UNAVAILABLE: '503 SERVICE_UNAVAILABLE',
    _504_GATEWAY_TIMEOUT: '504 GATEWAY_TIMEOUT',
    _505_HTTP_VERSION_NOT_SUPPORTED: '505 HTTP_VERSION_NOT_SUPPORTED',
    _506_VARIANT_ALSO_NEGOTIATES: '506 VARIANT_ALSO_NEGOTIATES',
    _507_INSUFFICIENT_STORAGE: '507 INSUFFICIENT_STORAGE',
    _508_LOOP_DETECTED: '508 LOOP_DETECTED',
    _509_BANDWIDTH_LIMIT_EXCEEDED: '509 BANDWIDTH_LIMIT_EXCEEDED',
    _510_NOT_EXTENDED: '510 NOT_EXTENDED',
    _511_NETWORK_AUTHENTICATION_REQUIRED: '511 NETWORK_AUTHENTICATION_REQUIRED'
} as const;

export type ApiErrorStatusEnum = typeof ApiErrorStatusEnum[keyof typeof ApiErrorStatusEnum];

/**
 * 
 * @export
 * @interface AppLogo
 */
export interface AppLogo {
    /**
     * main logo
     * @type {string}
     * @memberof AppLogo
     */
    'logoPath': string;
    /**
     * file name of the main logo
     * @type {string}
     * @memberof AppLogo
     */
    'logoName': string;
    /**
     * logo for mobile version
     * @type {string}
     * @memberof AppLogo
     */
    'logoMobilePath': string;
    /**
     * file name of the logo for mobile version
     * @type {string}
     * @memberof AppLogo
     */
    'logoMobileName': string;
}
/**
 * Application previous version link data
 * @export
 * @interface AppPreviousVersionData
 */
export interface AppPreviousVersionData {
    /**
     * 
     * @type {string}
     * @memberof AppPreviousVersionData
     */
    'prevSendFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AppPreviousVersionData
     */
    'prevSendFileUrl'?: string;
}
/**
 * 
 * @export
 * @interface AuthenticationRequestModel
 */
export interface AuthenticationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequestModel
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequestModel
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationRequestModel
     */
    'withRefreshToken': boolean;
}
/**
 * 
 * @export
 * @interface AuthenticationResponseModel
 */
export interface AuthenticationResponseModel {
    /**
     * JWT token for authenticated user
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    'accessToken'?: string;
    /**
     * Firstname of user. Can be empty
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    'firstName'?: string;
    /**
     * Lastname of user. Can be empty
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    'lastName'?: string;
    /**
     * Email of user 
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    'email'?: string;
    /**
     * Refresh token
     * @type {string}
     * @memberof AuthenticationResponseModel
     */
    'refreshToken'?: string;
}
/**
 * The object stores the message model fields that have been sent by all users.
 * @export
 * @interface CommonMessageDTO
 */
export interface CommonMessageDTO {
    /**
     * Message id
     * @type {string}
     * @memberof CommonMessageDTO
     */
    'id'?: string;
    /**
     * The expire time of the message
     * @type {string}
     * @memberof CommonMessageDTO
     */
    'expireTime'?: string;
    /**
     * 
     * @type {Array<FileResponseMainDataWithStoragePath>}
     * @memberof CommonMessageDTO
     */
    'files'?: Array<FileResponseMainDataWithStoragePath>;
    /**
     * Sender email
     * @type {string}
     * @memberof CommonMessageDTO
     */
    'senderEmail'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommonMessageDTO
     */
    'recipientEmails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CommonMessageDTO
     */
    'messageType'?: CommonMessageDTOMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommonMessageDTO
     */
    'createDateTime'?: string;
    /**
     * Recipient email one from
     * @type {string}
     * @memberof CommonMessageDTO
     */
    'recipientEmail'?: string;
    /**
     * CommonMessage size
     * @type {number}
     * @memberof CommonMessageDTO
     */
    'size'?: number;
    /**
     * Is CommonMessage protected with password
     * @type {boolean}
     * @memberof CommonMessageDTO
     */
    'security'?: boolean;
}

export const CommonMessageDTOMessageTypeEnum = {
    MESSAGE: 'MESSAGE',
    RESPONSE_MESSAGE: 'RESPONSE_MESSAGE',
    REQUEST_MESSAGE: 'REQUEST_MESSAGE'
} as const;

export type CommonMessageDTOMessageTypeEnum = typeof CommonMessageDTOMessageTypeEnum[keyof typeof CommonMessageDTOMessageTypeEnum];

/**
 * 
 * @export
 * @interface CreateUpdateUserModel
 */
export interface CreateUpdateUserModel {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserModel
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserModel
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserModel
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateUserModel
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateUserModel
     */
    'allowUploadOverLimit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateUserModel
     */
    'lastLoginDateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateUserModel
     */
    'limitSpacing'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateUserModel
     */
    'roles'?: Array<CreateUpdateUserModelRolesEnum>;
}

export const CreateUpdateUserModelRolesEnum = {
    STANDARD_USER: 'STANDARD_USER',
    ADMIN: 'ADMIN',
    SECURITY_OFFICER: 'SECURITY_OFFICER',
    CONSUMER_API: 'CONSUMER_API'
} as const;

export type CreateUpdateUserModelRolesEnum = typeof CreateUpdateUserModelRolesEnum[keyof typeof CreateUpdateUserModelRolesEnum];

/**
 * 
 * @export
 * @interface EmailMessageModel
 */
export interface EmailMessageModel {
    /**
     * 
     * @type {string}
     * @memberof EmailMessageModel
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmailMessageModel
     */
    'downloadLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailMessageModel
     */
    'expireTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailMessageModel
     */
    'withDirectLinks'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailMessageModel
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof EmailMessageModel
     */
    'text': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EmailMessageModel
     */
    'recipients': Array<string>;
}
/**
 * Encryption type
 * @export
 * @enum {string}
 */

export const Encrypted = {
    NONE: 'NONE',
    ZIP: 'ZIP',
    IN_PROGRESS: 'IN_PROGRESS',
    FAILED: 'FAILED'
} as const;

export type Encrypted = typeof Encrypted[keyof typeof Encrypted];


/**
 * Settings - License expiration message topics
 * @export
 * @interface EndingOrExpiredLicenseMailHeadersDTO
 */
export interface EndingOrExpiredLicenseMailHeadersDTO {
    /**
     * 
     * @type {string}
     * @memberof EndingOrExpiredLicenseMailHeadersDTO
     */
    'titleEN': string;
    /**
     * 
     * @type {string}
     * @memberof EndingOrExpiredLicenseMailHeadersDTO
     */
    'bodyEN': string;
    /**
     * 
     * @type {string}
     * @memberof EndingOrExpiredLicenseMailHeadersDTO
     */
    'expiredBodyEN': string;
    /**
     * 
     * @type {string}
     * @memberof EndingOrExpiredLicenseMailHeadersDTO
     */
    'titleRU': string;
    /**
     * 
     * @type {string}
     * @memberof EndingOrExpiredLicenseMailHeadersDTO
     */
    'bodyRU': string;
    /**
     * 
     * @type {string}
     * @memberof EndingOrExpiredLicenseMailHeadersDTO
     */
    'expiredBodyRU': string;
}
/**
 * Setings - Mail subjects for extend period
 * @export
 * @interface ExtendMessageMailHeadersDTO
 */
export interface ExtendMessageMailHeadersDTO {
    /**
     * 
     * @type {string}
     * @memberof ExtendMessageMailHeadersDTO
     */
    'titleEN': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendMessageMailHeadersDTO
     */
    'bodyEN': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendMessageMailHeadersDTO
     */
    'titleRU': string;
    /**
     * 
     * @type {string}
     * @memberof ExtendMessageMailHeadersDTO
     */
    'bodyRU': string;
}
/**
 * 
 * @export
 * @interface FileDTO
 */
export interface FileDTO {
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDTO
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileDTO
     */
    'downloadCount'?: number;
    /**
     * 
     * @type {FileStatus}
     * @memberof FileDTO
     */
    'status'?: FileStatus;
    /**
     * 
     * @type {FileError}
     * @memberof FileDTO
     */
    'error'?: FileError;
    /**
     * 
     * @type {Encrypted}
     * @memberof FileDTO
     */
    'encrypted'?: Encrypted;
}
/**
 * Error which happened with a file during uploading/processing
 * @export
 * @enum {string}
 */

export const FileError = {
    DUPLICATED: 'DUPLICATED',
    NOT_ENOUGH_SPACE: 'NOT_ENOUGH_SPACE',
    EXCEED_UPLOAD_LIMIT: 'EXCEED_UPLOAD_LIMIT',
    UPLOADED_PARTLY: 'UPLOADED_PARTLY',
    INFECTED: 'INFECTED'
} as const;

export type FileError = typeof FileError[keyof typeof FileError];


/**
 * The object stores the main fields of the file.
 * @export
 * @interface FileResponseMainData
 */
export interface FileResponseMainData {
    /**
     * File id
     * @type {string}
     * @memberof FileResponseMainData
     */
    'id'?: string;
    /**
     * File name
     * @type {string}
     * @memberof FileResponseMainData
     */
    'name'?: string;
    /**
     * File size
     * @type {number}
     * @memberof FileResponseMainData
     */
    'size'?: number;
    /**
     * Download count
     * @type {number}
     * @memberof FileResponseMainData
     */
    'downloadCount'?: number;
    /**
     * Download limit
     * @type {number}
     * @memberof FileResponseMainData
     */
    'downloadLimit'?: number;
}
/**
 * The object stores the main fields with storage path of the file.
 * @export
 * @interface FileResponseMainDataWithStoragePath
 */
export interface FileResponseMainDataWithStoragePath {
    /**
     * File id
     * @type {string}
     * @memberof FileResponseMainDataWithStoragePath
     */
    'id'?: string;
    /**
     * File name
     * @type {string}
     * @memberof FileResponseMainDataWithStoragePath
     */
    'name'?: string;
    /**
     * File size
     * @type {number}
     * @memberof FileResponseMainDataWithStoragePath
     */
    'size'?: number;
    /**
     * Download count
     * @type {number}
     * @memberof FileResponseMainDataWithStoragePath
     */
    'downloadCount'?: number;
    /**
     * Download limit
     * @type {number}
     * @memberof FileResponseMainDataWithStoragePath
     */
    'downloadLimit'?: number;
    /**
     * File storage path
     * @type {string}
     * @memberof FileResponseMainDataWithStoragePath
     */
    'storagePath'?: string;
}
/**
 * 
 * @export
 * @interface FileStateDTO
 */
export interface FileStateDTO {
    /**
     * 
     * @type {string}
     * @memberof FileStateDTO
     */
    'id'?: string;
    /**
     * 
     * @type {FileStatus}
     * @memberof FileStateDTO
     */
    'status'?: FileStatus;
    /**
     * 
     * @type {FileError}
     * @memberof FileStateDTO
     */
    'error'?: FileError;
    /**
     * 
     * @type {string}
     * @memberof FileStateDTO
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface FileStateRequest
 */
export interface FileStateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof FileStateRequest
     */
    'fileIds': Array<string>;
}
/**
 * 
 * @export
 * @interface FileStateResponse
 */
export interface FileStateResponse {
    /**
     * 
     * @type {{ [key: string]: FileStateDTO; }}
     * @memberof FileStateResponse
     */
    'files'?: { [key: string]: FileStateDTO; };
}
/**
 * Represents file status after uploading and antivirus checking .
 * @export
 * @enum {string}
 */

export const FileStatus = {
    DUMMY: 'DUMMY',
    UPLOADING: 'UPLOADING',
    PROCESSING: 'PROCESSING',
    READY: 'READY',
    UPLD_PART: 'UPLD_PART',
    ERROR: 'ERROR'
} as const;

export type FileStatus = typeof FileStatus[keyof typeof FileStatus];


/**
 * 
 * @export
 * @interface FileUploadPrepareRequest
 */
export interface FileUploadPrepareRequest {
    /**
     * 
     * @type {string}
     * @memberof FileUploadPrepareRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FileUploadPrepareRequest
     */
    'size': number;
    /**
     * 
     * @type {Encrypted}
     * @memberof FileUploadPrepareRequest
     */
    'encrypted': Encrypted;
}
/**
 * 
 * @export
 * @interface FileUploadProgressbarRequest
 */
export interface FileUploadProgressbarRequest {
    /**
     * Do not include files from the current message
     * @type {string}
     * @memberof FileUploadProgressbarRequest
     */
    'excludeMessageId'?: string;
}
/**
 * 
 * @export
 * @interface FileUploadProgressbarResponse
 */
export interface FileUploadProgressbarResponse {
    /**
     * Size of files (in bytes) uploaded by the current user
     * @type {number}
     * @memberof FileUploadProgressbarResponse
     */
    'uploadedBeforeSize'?: number;
    /**
     * Free space (in bytes) in the storage
     * @type {number}
     * @memberof FileUploadProgressbarResponse
     */
    'storageFreeSpace'?: number;
}
/**
 * Settings - General
 * @export
 * @interface GeneralSettingsDTO
 */
export interface GeneralSettingsDTO {
    /**
     * 
     * @type {number}
     * @memberof GeneralSettingsDTO
     */
    'fileSizeUploadLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralSettingsDTO
     */
    'techSupportEmail'?: string;
    /**
     * 
     * @type {Encrypted}
     * @memberof GeneralSettingsDTO
     */
    'encryptionState': Encrypted;
    /**
     * 
     * @type {string}
     * @memberof GeneralSettingsDTO
     */
    'prevSendFileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSettingsDTO
     */
    'prevSendFileUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralSettingsDTO
     */
    'directLinksEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface IncreaseDownloadLimitDTO
 */
export interface IncreaseDownloadLimitDTO {
    /**
     * 
     * @type {number}
     * @memberof IncreaseDownloadLimitDTO
     */
    'downloadLimit'?: number;
}
/**
 * 
 * @export
 * @interface IncreaseExpireTimeDto
 */
export interface IncreaseExpireTimeDto {
    /**
     * 
     * @type {number}
     * @memberof IncreaseExpireTimeDto
     */
    'daysCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IncreaseExpireTimeDto
     */
    'sendNotify'?: boolean;
}
/**
 * 
 * @export
 * @interface InnerRole
 */
export interface InnerRole {
    /**
     * 
     * @type {number}
     * @memberof InnerRole
     */
    'Identifier of inner role': number;
    /**
     * Application role that will be using for authorization inside app
     * @type {string}
     * @memberof InnerRole
     */
    'Inner role name': string;
    /**
     * One or many separated by comma outer roles. 
     * @type {string}
     * @memberof InnerRole
     */
    'commaSeparatedOuterRoles': string;
}
/**
 * Class is representing inner application role that would be given to user after mapping  
 * @export
 * @interface InnerRoleNewDTO
 */
export interface InnerRoleNewDTO {
    /**
     * Application role that will be using for authorization inside app
     * @type {string}
     * @memberof InnerRoleNewDTO
     */
    'Inner role name': string;
    /**
     * One or many separated by comma outer roles. 
     * @type {string}
     * @memberof InnerRoleNewDTO
     */
    'commaSeparatedOuterRoles': string;
}
/**
 * Settings - LDAP
 * @export
 * @interface LdapSettingsDTO
 */
export interface LdapSettingsDTO {
    /**
     * Host to connect with port.
     * @type {string}
     * @memberof LdapSettingsDTO
     */
    'url': string;
    /**
     * Domain name of users which name will be used to search through ldap.
     * @type {string}
     * @memberof LdapSettingsDTO
     */
    'user': string;
    /**
     * Users password
     * @type {string}
     * @memberof LdapSettingsDTO
     */
    'password': string;
    /**
     * Domain name of object with users.
     * @type {string}
     * @memberof LdapSettingsDTO
     */
    'usersDn': string;
    /**
     * Users filters.
     * @type {string}
     * @memberof LdapSettingsDTO
     */
    'usersFilters': string;
    /**
     * Domain name of object with roles
     * @type {string}
     * @memberof LdapSettingsDTO
     */
    'rolesDn': string;
    /**
     * Roles filter.
     * @type {string}
     * @memberof LdapSettingsDTO
     */
    'rolesFilter': string;
}
/**
 * 
 * @export
 * @interface LicenseInfoDTO
 */
export interface LicenseInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof LicenseInfoDTO
     */
    'license'?: string;
    /**
     * 
     * @type {number}
     * @memberof LicenseInfoDTO
     */
    'totalUserCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LicenseInfoDTO
     */
    'freeUserCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicenseInfoDTO
     */
    'validity'?: string;
}
/**
 * 
 * @export
 * @interface LicenseUpdateDTO
 */
export interface LicenseUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof LicenseUpdateDTO
     */
    'license': string;
}
/**
 * 
 * @export
 * @interface LinkMessageModel
 */
export interface LinkMessageModel {
    /**
     * 
     * @type {string}
     * @memberof LinkMessageModel
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof LinkMessageModel
     */
    'downloadLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkMessageModel
     */
    'expireTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkMessageModel
     */
    'withDirectLinks'?: boolean;
}
/**
 * Parameters for creating a new local storage
 * @export
 * @interface LocalFileStorageCreateParametersDTO
 */
export interface LocalFileStorageCreateParametersDTO {
    /**
     * Possible directories in which storages can be created
     * @type {Array<string>}
     * @memberof LocalFileStorageCreateParametersDTO
     */
    'basePaths'?: Array<string>;
    /**
     * Size (in bytes) of a new storage by default
     * @type {number}
     * @memberof LocalFileStorageCreateParametersDTO
     */
    'defaultSize'?: number;
}
/**
 * 
 * @export
 * @interface LocalFileStorageCreateRequest
 */
export interface LocalFileStorageCreateRequest {
    /**
     * Storage location in docker container(linux file system). Remember: path should be mapped to volume in docker to make file persisted after restart. You shouldn\'t create storage as a part of other storage(subdirectory). 
     * @type {string}
     * @memberof LocalFileStorageCreateRequest
     */
    'basePath': string;
    /**
     * Storage location inside directory given by basePath
     * @type {string}
     * @memberof LocalFileStorageCreateRequest
     */
    'path': string;
    /**
     * Storage size in bytes.
     * @type {number}
     * @memberof LocalFileStorageCreateRequest
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface LocalFileStorageDTO
 */
export interface LocalFileStorageDTO {
    /**
     * 
     * @type {number}
     * @memberof LocalFileStorageDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalFileStorageDTO
     */
    'basePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalFileStorageDTO
     */
    'fullPath'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalFileStorageDTO
     */
    'path'?: string;
    /**
     * 
     * @type {number}
     * @memberof LocalFileStorageDTO
     */
    'freeSpace'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalFileStorageDTO
     */
    'usedSpace'?: number;
    /**
     * size in bytes
     * @type {number}
     * @memberof LocalFileStorageDTO
     */
    'size'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LocalFileStorageDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface LocalFileStorageEditRequest
 */
export interface LocalFileStorageEditRequest {
    /**
     * 
     * @type {number}
     * @memberof LocalFileStorageEditRequest
     */
    'id': number;
    /**
     * Storage location in docker container(linux file system). Remember: path should be mapped to volume in docker to make file persisted after restart. You shouldn\'t create storage as a part of other storage(subdirectory). 
     * @type {string}
     * @memberof LocalFileStorageEditRequest
     */
    'basePath': string;
    /**
     * Storage location inside directory given by basePath
     * @type {string}
     * @memberof LocalFileStorageEditRequest
     */
    'path': string;
    /**
     * Storage size in bytes.
     * @type {number}
     * @memberof LocalFileStorageEditRequest
     */
    'size': number;
}
/**
 * Request to change the display order of storages on ui
 * @export
 * @interface LocalFileStorageReorderRequest
 */
export interface LocalFileStorageReorderRequest {
    /**
     * List of storage ids in the display order
     * @type {Array<number>}
     * @memberof LocalFileStorageReorderRequest
     */
    'order': Array<number>;
}
/**
 * 
 * @export
 * @interface LogFileDTO
 */
export interface LogFileDTO {
    /**
     * 
     * @type {string}
     * @memberof LogFileDTO
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof LogFileDTO
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogFileDTO
     */
    'createdDateTime'?: string;
}
/**
 * 
 * @export
 * @interface LogFileDownloadRequest
 */
export interface LogFileDownloadRequest {
    /**
     * 
     * @type {string}
     * @memberof LogFileDownloadRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFileDownloadRequest
     */
    'toDate'?: string;
}
/**
 * 
 * @export
 * @interface LogFileListRequest
 */
export interface LogFileListRequest {
    /**
     * 
     * @type {number}
     * @memberof LogFileListRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof LogFileListRequest
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof LogFileListRequest
     */
    'sortField'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFileListRequest
     */
    'sortDir'?: LogFileListRequestSortDirEnum;
    /**
     * 
     * @type {string}
     * @memberof LogFileListRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogFileListRequest
     */
    'toDate'?: string;
}

export const LogFileListRequestSortDirEnum = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export type LogFileListRequestSortDirEnum = typeof LogFileListRequestSortDirEnum[keyof typeof LogFileListRequestSortDirEnum];

/**
 * 
 * @export
 * @interface LogFileSettings
 */
export interface LogFileSettings {
    /**
     * Total size cap of the log files in bytes
     * @type {number}
     * @memberof LogFileSettings
     */
    'totalSizeCap'?: number;
    /**
     * Keep the log files for the last X days
     * @type {number}
     * @memberof LogFileSettings
     */
    'maxHistory'?: number;
}
/**
 * Request a number of lines from the given log file
 * @export
 * @interface LogFileViewRequest
 */
export interface LogFileViewRequest {
    /**
     * Offset in lines from the start of the log file
     * @type {number}
     * @memberof LogFileViewRequest
     */
    'offset'?: number;
    /**
     * Number of lines to read after skipping `offset` lines
     * @type {number}
     * @memberof LogFileViewRequest
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface LogoUploadResponse
 */
export interface LogoUploadResponse {
    /**
     * path to the logo on the server
     * @type {string}
     * @memberof LogoUploadResponse
     */
    'logoPath': string;
    /**
     * logo file name
     * @type {string}
     * @memberof LogoUploadResponse
     */
    'logoName': string;
}
/**
 * Settings - Mail Subjects
 * @export
 * @interface MailHeadersDTO
 */
export interface MailHeadersDTO {
    /**
     * 
     * @type {string}
     * @memberof MailHeadersDTO
     */
    'sentMessageEN': string;
    /**
     * 
     * @type {string}
     * @memberof MailHeadersDTO
     */
    'sentMessageRU': string;
    /**
     * 
     * @type {string}
     * @memberof MailHeadersDTO
     */
    'requestMessageEN': string;
    /**
     * 
     * @type {string}
     * @memberof MailHeadersDTO
     */
    'requestMessageRU': string;
    /**
     * 
     * @type {string}
     * @memberof MailHeadersDTO
     */
    'responseMessageEN': string;
    /**
     * 
     * @type {string}
     * @memberof MailHeadersDTO
     */
    'responseMessageRU': string;
}
/**
 * Settings - EMAIL
 * @export
 * @interface MailSettingsDTO
 */
export interface MailSettingsDTO {
    /**
     * 
     * @type {string}
     * @memberof MailSettingsDTO
     */
    'host': string;
    /**
     * 
     * @type {number}
     * @memberof MailSettingsDTO
     */
    'port': number;
    /**
     * 
     * @type {string}
     * @memberof MailSettingsDTO
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof MailSettingsDTO
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof MailSettingsDTO
     */
    'mailApplicationAddress': string;
    /**
     * 
     * @type {string}
     * @memberof MailSettingsDTO
     */
    'transportProtocol': string;
    /**
     * 
     * @type {boolean}
     * @memberof MailSettingsDTO
     */
    'smtpAuth': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailSettingsDTO
     */
    'smtpStartTlsEnable': boolean;
}
/**
 * 
 * @export
 * @interface MessageModel
 */
export interface MessageModel {
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'text'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageModel
     */
    'viewCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'createDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'password'?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageModel
     */
    'downloadLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'expireTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'sender'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageModel
     */
    'recipients'?: Array<string>;
    /**
     * 
     * @type {Array<FileDTO>}
     * @memberof MessageModel
     */
    'files'?: Array<FileDTO>;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'status'?: MessageModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageModel
     */
    'sendingType'?: MessageModelSendingTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MessageModel
     */
    'withDirectLinks'?: boolean;
}

export const MessageModelStatusEnum = {
    ACTIVE: 'ACTIVE',
    DELETED_BY_USER: 'DELETED_BY_USER',
    DELETED_BY_ADMIN: 'DELETED_BY_ADMIN',
    DELETED_BY_SYSTEM: 'DELETED_BY_SYSTEM',
    DELETED_BY_ANTIVIRUS: 'DELETED_BY_ANTIVIRUS',
    EXPIRED: 'EXPIRED',
    DRAFT: 'DRAFT'
} as const;

export type MessageModelStatusEnum = typeof MessageModelStatusEnum[keyof typeof MessageModelStatusEnum];
export const MessageModelSendingTypeEnum = {
    LINK: 'LINK',
    EMAIL: 'EMAIL'
} as const;

export type MessageModelSendingTypeEnum = typeof MessageModelSendingTypeEnum[keyof typeof MessageModelSendingTypeEnum];

/**
 * 
 * @export
 * @interface MessagePublicModel
 */
export interface MessagePublicModel {
    /**
     * 
     * @type {UserModel}
     * @memberof MessagePublicModel
     */
    'sender'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof MessagePublicModel
     */
    'expireTime'?: string;
    /**
     * 
     * @type {Array<FileDTO>}
     * @memberof MessagePublicModel
     */
    'files'?: Array<FileDTO>;
    /**
     * Download restrictions for message.
     * @type {number}
     * @memberof MessagePublicModel
     */
    'downloadLimit'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessagePublicModel
     */
    'protected'?: boolean;
}
/**
 * Settings - Delete message subject
 * @export
 * @interface NotificationAboutDeletingMailHeadersDTO
 */
export interface NotificationAboutDeletingMailHeadersDTO {
    /**
     * 
     * @type {string}
     * @memberof NotificationAboutDeletingMailHeadersDTO
     */
    'titleEN': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAboutDeletingMailHeadersDTO
     */
    'bodyEN': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAboutDeletingMailHeadersDTO
     */
    'titleRU': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAboutDeletingMailHeadersDTO
     */
    'bodyRU': string;
}
/**
 * 
 * @export
 * @interface PageCommonMessageDTO
 */
export interface PageCommonMessageDTO {
    /**
     * 
     * @type {number}
     * @memberof PageCommonMessageDTO
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCommonMessageDTO
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCommonMessageDTO
     */
    'size'?: number;
    /**
     * 
     * @type {Array<CommonMessageDTO>}
     * @memberof PageCommonMessageDTO
     */
    'content'?: Array<CommonMessageDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageCommonMessageDTO
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageCommonMessageDTO
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCommonMessageDTO
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCommonMessageDTO
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCommonMessageDTO
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCommonMessageDTO
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCommonMessageDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageLogFileDTO
 */
export interface PageLogFileDTO {
    /**
     * 
     * @type {number}
     * @memberof PageLogFileDTO
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLogFileDTO
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLogFileDTO
     */
    'size'?: number;
    /**
     * 
     * @type {Array<LogFileDTO>}
     * @memberof PageLogFileDTO
     */
    'content'?: Array<LogFileDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageLogFileDTO
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageLogFileDTO
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLogFileDTO
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLogFileDTO
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLogFileDTO
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLogFileDTO
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLogFileDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageReceivedMessageResponseData
 */
export interface PageReceivedMessageResponseData {
    /**
     * 
     * @type {number}
     * @memberof PageReceivedMessageResponseData
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReceivedMessageResponseData
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReceivedMessageResponseData
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ReceivedMessageResponseData>}
     * @memberof PageReceivedMessageResponseData
     */
    'content'?: Array<ReceivedMessageResponseData>;
    /**
     * 
     * @type {number}
     * @memberof PageReceivedMessageResponseData
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageReceivedMessageResponseData
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageReceivedMessageResponseData
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageReceivedMessageResponseData
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageReceivedMessageResponseData
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageReceivedMessageResponseData
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageReceivedMessageResponseData
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageReportsFileDTO
 */
export interface PageReportsFileDTO {
    /**
     * 
     * @type {number}
     * @memberof PageReportsFileDTO
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReportsFileDTO
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReportsFileDTO
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ReportsFileDTO>}
     * @memberof PageReportsFileDTO
     */
    'content'?: Array<ReportsFileDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageReportsFileDTO
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageReportsFileDTO
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageReportsFileDTO
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageReportsFileDTO
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageReportsFileDTO
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageReportsFileDTO
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageReportsFileDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageReportsUserDTO
 */
export interface PageReportsUserDTO {
    /**
     * 
     * @type {number}
     * @memberof PageReportsUserDTO
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReportsUserDTO
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageReportsUserDTO
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ReportsUserDTO>}
     * @memberof PageReportsUserDTO
     */
    'content'?: Array<ReportsUserDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageReportsUserDTO
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageReportsUserDTO
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageReportsUserDTO
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageReportsUserDTO
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageReportsUserDTO
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageReportsUserDTO
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageReportsUserDTO
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageSentMessageResponseData
 */
export interface PageSentMessageResponseData {
    /**
     * 
     * @type {number}
     * @memberof PageSentMessageResponseData
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSentMessageResponseData
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSentMessageResponseData
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SentMessageResponseData>}
     * @memberof PageSentMessageResponseData
     */
    'content'?: Array<SentMessageResponseData>;
    /**
     * 
     * @type {number}
     * @memberof PageSentMessageResponseData
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageSentMessageResponseData
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageSentMessageResponseData
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSentMessageResponseData
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSentMessageResponseData
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageSentMessageResponseData
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageSentMessageResponseData
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * Settings - Private
 * @export
 * @interface PrivateSettingsDTO
 */
export interface PrivateSettingsDTO {
    /**
     * 
     * @type {AppPreviousVersionData}
     * @memberof PrivateSettingsDTO
     */
    'appPreviousVersionData'?: AppPreviousVersionData;
}
/**
 * Settings - Public
 * @export
 * @interface PublicSettingsDTO
 */
export interface PublicSettingsDTO {
    /**
     * Upload limit size for all uploaded files
     * @type {number}
     * @memberof PublicSettingsDTO
     */
    'fileSizeUploadLimit'?: number;
    /**
     * 
     * @type {Encrypted}
     * @memberof PublicSettingsDTO
     */
    'encryptionState'?: Encrypted;
    /**
     * Direct links enabled
     * @type {boolean}
     * @memberof PublicSettingsDTO
     */
    'directLinksEnabled'?: boolean;
}
/**
 * The object stores the fields of the message model which were received by the current user.
 * @export
 * @interface ReceivedMessageResponseData
 */
export interface ReceivedMessageResponseData {
    /**
     * Message id
     * @type {string}
     * @memberof ReceivedMessageResponseData
     */
    'id'?: string;
    /**
     * The expire time of the message
     * @type {string}
     * @memberof ReceivedMessageResponseData
     */
    'expireTime'?: string;
    /**
     * Creating date of the message
     * @type {string}
     * @memberof ReceivedMessageResponseData
     */
    'createDateTime'?: string;
    /**
     * 
     * @type {Array<FileResponseMainData>}
     * @memberof ReceivedMessageResponseData
     */
    'files'?: Array<FileResponseMainData>;
    /**
     * Sender email
     * @type {string}
     * @memberof ReceivedMessageResponseData
     */
    'senderEmail'?: string;
    /**
     * Type of the message
     * @type {string}
     * @memberof ReceivedMessageResponseData
     */
    'messageType'?: ReceivedMessageResponseDataMessageTypeEnum;
}

export const ReceivedMessageResponseDataMessageTypeEnum = {
    MESSAGE: 'MESSAGE',
    RESPONSE_MESSAGE: 'RESPONSE_MESSAGE',
    REQUEST_MESSAGE: 'REQUEST_MESSAGE'
} as const;

export type ReceivedMessageResponseDataMessageTypeEnum = typeof ReceivedMessageResponseDataMessageTypeEnum[keyof typeof ReceivedMessageResponseDataMessageTypeEnum];

/**
 * 
 * @export
 * @interface ReportsDate
 */
export interface ReportsDate {
    /**
     * 
     * @type {string}
     * @memberof ReportsDate
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsDate
     */
    'end'?: string;
}
/**
 * The object stores the report files model fields.
 * @export
 * @interface ReportsFileDTO
 */
export interface ReportsFileDTO {
    /**
     * 
     * @type {number}
     * @memberof ReportsFileDTO
     */
    'reportId'?: number;
    /**
     * Sender email
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'senderEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'action'?: ReportsFileDTOActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportsFileDTO
     */
    'fileSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'status'?: ReportsFileDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'messageSubject'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'messageBody'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'messageType'?: ReportsFileDTOMessageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'messageId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'emailCreatorLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileDTO
     */
    'userIp'?: string;
}

export const ReportsFileDTOActionEnum = {
    SEND: 'SEND',
    SEND_BY_LINK: 'SEND_BY_LINK',
    UPLOAD: 'UPLOAD',
    UPLOAD_BY_LINK: 'UPLOAD_BY_LINK',
    CREATE_LINK: 'CREATE_LINK',
    CREATE_RESPONSE_LINK: 'CREATE_RESPONSE_LINK',
    CREATE_REQUEST_LINK: 'CREATE_REQUEST_LINK',
    SEND_REQUEST_LINK: 'SEND_REQUEST_LINK',
    DOWNLOAD: 'DOWNLOAD',
    REMOVE: 'REMOVE'
} as const;

export type ReportsFileDTOActionEnum = typeof ReportsFileDTOActionEnum[keyof typeof ReportsFileDTOActionEnum];
export const ReportsFileDTOStatusEnum = {
    AUTHORIZED: 'AUTHORIZED',
    NOT_AUTHORIZED: 'NOT_AUTHORIZED'
} as const;

export type ReportsFileDTOStatusEnum = typeof ReportsFileDTOStatusEnum[keyof typeof ReportsFileDTOStatusEnum];
export const ReportsFileDTOMessageTypeEnum = {
    SEND: 'SEND',
    REQUEST: 'REQUEST',
    RESPONSE: 'RESPONSE'
} as const;

export type ReportsFileDTOMessageTypeEnum = typeof ReportsFileDTOMessageTypeEnum[keyof typeof ReportsFileDTOMessageTypeEnum];

/**
 * 
 * @export
 * @interface ReportsFileLifetime
 */
export interface ReportsFileLifetime {
    /**
     * 
     * @type {string}
     * @memberof ReportsFileLifetime
     */
    'isActual'?: ReportsFileLifetimeIsActualEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportsFileLifetime
     */
    'actualDate'?: string;
}

export const ReportsFileLifetimeIsActualEnum = {
    ALL: 'ALL',
    ALIVE: 'ALIVE',
    NOT_ALIVE: 'NOT_ALIVE'
} as const;

export type ReportsFileLifetimeIsActualEnum = typeof ReportsFileLifetimeIsActualEnum[keyof typeof ReportsFileLifetimeIsActualEnum];

/**
 * 
 * @export
 * @interface ReportsFileSize
 */
export interface ReportsFileSize {
    /**
     * 
     * @type {number}
     * @memberof ReportsFileSize
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportsFileSize
     */
    'to'?: number;
}
/**
 * Object stores fields which will be used for select files.
 * @export
 * @interface ReportsFilesRequestParams
 */
export interface ReportsFilesRequestParams {
    /**
     * 
     * @type {number}
     * @memberof ReportsFilesRequestParams
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportsFilesRequestParams
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportsFilesRequestParams
     */
    'sortField'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFilesRequestParams
     */
    'sortDir'?: ReportsFilesRequestParamsSortDirEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportsFilesRequestParams
     */
    'fileName'?: string;
    /**
     * 
     * @type {ReportsDate}
     * @memberof ReportsFilesRequestParams
     */
    'date'?: ReportsDate;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportsFilesRequestParams
     */
    'emails'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportsFilesRequestParams
     */
    'actions'?: Array<ReportsFilesRequestParamsActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ReportsFilesRequestParams
     */
    'theme'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsFilesRequestParams
     */
    'text'?: string;
    /**
     * 
     * @type {ReportsFileSize}
     * @memberof ReportsFilesRequestParams
     */
    'fileSize'?: ReportsFileSize;
    /**
     * 
     * @type {ReportsFileLifetime}
     * @memberof ReportsFilesRequestParams
     */
    'lifetime'?: ReportsFileLifetime;
    /**
     * 
     * @type {string}
     * @memberof ReportsFilesRequestParams
     */
    'fileReportStatuses'?: ReportsFilesRequestParamsFileReportStatusesEnum;
}

export const ReportsFilesRequestParamsSortDirEnum = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export type ReportsFilesRequestParamsSortDirEnum = typeof ReportsFilesRequestParamsSortDirEnum[keyof typeof ReportsFilesRequestParamsSortDirEnum];
export const ReportsFilesRequestParamsActionsEnum = {
    SEND: 'SEND',
    SEND_BY_LINK: 'SEND_BY_LINK',
    UPLOAD: 'UPLOAD',
    UPLOAD_BY_LINK: 'UPLOAD_BY_LINK',
    CREATE_LINK: 'CREATE_LINK',
    CREATE_RESPONSE_LINK: 'CREATE_RESPONSE_LINK',
    CREATE_REQUEST_LINK: 'CREATE_REQUEST_LINK',
    SEND_REQUEST_LINK: 'SEND_REQUEST_LINK',
    DOWNLOAD: 'DOWNLOAD',
    REMOVE: 'REMOVE'
} as const;

export type ReportsFilesRequestParamsActionsEnum = typeof ReportsFilesRequestParamsActionsEnum[keyof typeof ReportsFilesRequestParamsActionsEnum];
export const ReportsFilesRequestParamsFileReportStatusesEnum = {
    DOWNLOADED: 'DOWNLOADED',
    UNDOWNLOADED: 'UNDOWNLOADED'
} as const;

export type ReportsFilesRequestParamsFileReportStatusesEnum = typeof ReportsFilesRequestParamsFileReportStatusesEnum[keyof typeof ReportsFilesRequestParamsFileReportStatusesEnum];

/**
 * The object stores the report users model fields.
 * @export
 * @interface ReportsUserDTO
 */
export interface ReportsUserDTO {
    /**
     * senderEmail email
     * @type {string}
     * @memberof ReportsUserDTO
     */
    'senderEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsUserDTO
     */
    'initials'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsUserDTO
     */
    'eventDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsUserDTO
     */
    'eventType'?: ReportsUserDTOEventTypeEnum;
}

export const ReportsUserDTOEventTypeEnum = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    FILE_ADD_PREPARE: 'FILE_ADD_PREPARE',
    FILE_ADD: 'FILE_ADD',
    FILE_DOWNLOAD: 'FILE_DOWNLOAD',
    FILE_DELETE: 'FILE_DELETE',
    FILE_SEND: 'FILE_SEND',
    MESSAGE_CREATE: 'MESSAGE_CREATE',
    MESSAGE_SEND: 'MESSAGE_SEND',
    MESSAGE_RESEND: 'MESSAGE_RESEND',
    MESSAGE_PROLONG: 'MESSAGE_PROLONG',
    MESSAGE_DELETE: 'MESSAGE_DELETE',
    TECH_SUPPORT: 'TECH_SUPPORT',
    MESSAGE_INCREASE_DOWNLOAD_LIMIT: 'MESSAGE_INCREASE_DOWNLOAD_LIMIT'
} as const;

export type ReportsUserDTOEventTypeEnum = typeof ReportsUserDTOEventTypeEnum[keyof typeof ReportsUserDTOEventTypeEnum];

/**
 * Object stores fields which will be used for select users.
 * @export
 * @interface ReportsUserRequestParams
 */
export interface ReportsUserRequestParams {
    /**
     * 
     * @type {number}
     * @memberof ReportsUserRequestParams
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportsUserRequestParams
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportsUserRequestParams
     */
    'sortField'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportsUserRequestParams
     */
    'sortDir'?: ReportsUserRequestParamsSortDirEnum;
    /**
     * 
     * @type {ReportsDate}
     * @memberof ReportsUserRequestParams
     */
    'date'?: ReportsDate;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportsUserRequestParams
     */
    'users'?: Array<number>;
}

export const ReportsUserRequestParamsSortDirEnum = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export type ReportsUserRequestParamsSortDirEnum = typeof ReportsUserRequestParamsSortDirEnum[keyof typeof ReportsUserRequestParamsSortDirEnum];

/**
 * This dto describes all the necessary parameters for request message creation and email notification
 * @export
 * @interface RequestByEmailDTO
 */
export interface RequestByEmailDTO {
    /**
     * The number of days that request will be accessed. In that period users can respond to request by sending files.
     * @type {number}
     * @memberof RequestByEmailDTO
     */
    'daysToLiveRequest': number;
    /**
     * The number of days that files will be available for downloading
     * @type {number}
     * @memberof RequestByEmailDTO
     */
    'daysToLiveFiles': number;
    /**
     * Comma separated list of email addresses
     * @type {Array<string>}
     * @memberof RequestByEmailDTO
     */
    'recipientsEmails': Array<string>;
    /**
     * This text will be using as email subject header.
     * @type {string}
     * @memberof RequestByEmailDTO
     */
    'subject': string;
    /**
     * Email text
     * @type {string}
     * @memberof RequestByEmailDTO
     */
    'messageText': string;
}
/**
 * 
 * @export
 * @interface RequestByLinkDTO
 */
export interface RequestByLinkDTO {
    /**
     * The number of days that request will be accessed. In that period users can respond to request by sending files.
     * @type {number}
     * @memberof RequestByLinkDTO
     */
    'daysToLiveRequest': number;
    /**
     * The number of days that files will be available for downloading
     * @type {number}
     * @memberof RequestByLinkDTO
     */
    'daysToLiveFiles': number;
}
/**
 * Contains all the information about request message.
 * @export
 * @interface RequestMessageDTO
 */
export interface RequestMessageDTO {
    /**
     * Message id
     * @type {string}
     * @memberof RequestMessageDTO
     */
    'uuid'?: string;
    /**
     * This user has made an request for files. All the files will be sent to this user.
     * @type {string}
     * @memberof RequestMessageDTO
     */
    'requesterEmail'?: string;
    /**
     * The text of the message user wrote.
     * @type {string}
     * @memberof RequestMessageDTO
     */
    'requestMessageText'?: string;
    /**
     * The date
     * @type {string}
     * @memberof RequestMessageDTO
     */
    'requestExpireTime'?: string;
    /**
     * Count of message views
     * @type {number}
     * @memberof RequestMessageDTO
     */
    'viewCount'?: number;
}
/**
 * This object describes metadata about the file user sent in response for request
 * @export
 * @interface ResponseFileDTO
 */
export interface ResponseFileDTO {
    /**
     * File identifier. This field has to be included.
     * @type {string}
     * @memberof ResponseFileDTO
     */
    'id': string;
    /**
     * File size in bytes.
     * @type {number}
     * @memberof ResponseFileDTO
     */
    'size'?: number;
    /**
     * This subject will be used as a subject of email sent for requester notification.
     * @type {string}
     * @memberof ResponseFileDTO
     */
    'name'?: string;
    /**
     * 
     * @type {FileStatus}
     * @memberof ResponseFileDTO
     */
    'status'?: FileStatus;
    /**
     * 
     * @type {FileError}
     * @memberof ResponseFileDTO
     */
    'error'?: FileError;
}
/**
 * This object describes data sent to response
 * @export
 * @interface ResponseMessageDTO
 */
export interface ResponseMessageDTO {
    /**
     * The identifier of response message.
     * @type {string}
     * @memberof ResponseMessageDTO
     */
    'uuid': string;
    /**
     * The email of requester. All the files sent in that response will be seen by requester. You may not sent this field, it will be taken from backend.
     * @type {string}
     * @memberof ResponseMessageDTO
     */
    'email'?: string;
    /**
     * This subject will be used as a subject of email sent for requester notification.
     * @type {string}
     * @memberof ResponseMessageDTO
     */
    'subject': string;
    /**
     * The text of the message. This text will be seen by requester in email notification as email message text.
     * @type {string}
     * @memberof ResponseMessageDTO
     */
    'messageText': string;
    /**
     * 
     * @type {Array<ResponseFileDTO>}
     * @memberof ResponseMessageDTO
     */
    'responseFiles'?: Array<ResponseFileDTO>;
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageDTO
     */
    'expireTime'?: string;
}
/**
 * Contains all the fields related to email sending.
 * @export
 * @interface ResponseMessageEmailDTO
 */
export interface ResponseMessageEmailDTO {
    /**
     * The email of requester. All the files sent in that response will be seen by requester. You may not sent this field, it will be taken from backend.
     * @type {string}
     * @memberof ResponseMessageEmailDTO
     */
    'emailText': string;
    /**
     * The text of the message. This text will be seen by requester in email notification as email message text.
     * @type {string}
     * @memberof ResponseMessageEmailDTO
     */
    'emailSubject': string;
}
/**
 * The object stores the fields of the message model which were sent by the current user.
 * @export
 * @interface SentMessageResponseData
 */
export interface SentMessageResponseData {
    /**
     * Message id
     * @type {string}
     * @memberof SentMessageResponseData
     */
    'id'?: string;
    /**
     * The expire time of the message
     * @type {string}
     * @memberof SentMessageResponseData
     */
    'expireTime'?: string;
    /**
     * Creating date of the message
     * @type {string}
     * @memberof SentMessageResponseData
     */
    'createDateTime'?: string;
    /**
     * 
     * @type {Array<FileResponseMainData>}
     * @memberof SentMessageResponseData
     */
    'files'?: Array<FileResponseMainData>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SentMessageResponseData
     */
    'recipientEmails'?: Array<string>;
    /**
     * Type of the message
     * @type {string}
     * @memberof SentMessageResponseData
     */
    'messageType'?: SentMessageResponseDataMessageTypeEnum;
    /**
     * Is Message protected with password
     * @type {boolean}
     * @memberof SentMessageResponseData
     */
    'security'?: boolean;
}

export const SentMessageResponseDataMessageTypeEnum = {
    MESSAGE: 'MESSAGE',
    RESPONSE_MESSAGE: 'RESPONSE_MESSAGE',
    REQUEST_MESSAGE: 'REQUEST_MESSAGE'
} as const;

export type SentMessageResponseDataMessageTypeEnum = typeof SentMessageResponseDataMessageTypeEnum[keyof typeof SentMessageResponseDataMessageTypeEnum];

/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface StatsFileSummaryDTO
 */
export interface StatsFileSummaryDTO {
    /**
     * Total number of uploaded files
     * @type {number}
     * @memberof StatsFileSummaryDTO
     */
    'uploadsCount'?: number;
    /**
     * Total size of uploaded files
     * @type {number}
     * @memberof StatsFileSummaryDTO
     */
    'uploadsSize'?: number;
}
/**
 * 
 * @export
 * @interface StatsSummaryDTO
 */
export interface StatsSummaryDTO {
    /**
     * Total number of users
     * @type {number}
     * @memberof StatsSummaryDTO
     */
    'usersCount'?: number;
    /**
     * Total number of active users
     * @type {number}
     * @memberof StatsSummaryDTO
     */
    'activeUsersCount'?: number;
    /**
     * Max file size for upload
     * @type {number}
     * @memberof StatsSummaryDTO
     */
    'uploadLimit'?: number;
    /**
     * Number of active users (for license)
     * @type {number}
     * @memberof StatsSummaryDTO
     */
    'activeLicensesCount'?: number;
    /**
     * Total number of users allowed by the license
     * @type {number}
     * @memberof StatsSummaryDTO
     */
    'licensesCount'?: number;
    /**
     * License expiry date
     * @type {string}
     * @memberof StatsSummaryDTO
     */
    'licenseValidBefore'?: string;
    /**
     * File storage information
     * @type {Array<LocalFileStorageDTO>}
     * @memberof StatsSummaryDTO
     */
    'storages'?: Array<LocalFileStorageDTO>;
}
/**
 * Settings - Storage
 * @export
 * @interface StorageSettingsDTO
 */
export interface StorageSettingsDTO {
    /**
     * Schedule for free space check.
     * @type {string}
     * @memberof StorageSettingsDTO
     */
    'freeSpaceCheckSchedule'?: string;
    /**
     * Schedule for expired files.
     * @type {string}
     * @memberof StorageSettingsDTO
     */
    'expiredFilesSchedule'?: string;
    /**
     * Count of days delay before will be removed after expiration.
     * @type {number}
     * @memberof StorageSettingsDTO
     */
    'countOfDayDelayBeforeMessageRemoving'?: number;
    /**
     * Email to notify about low free space in storage
     * @type {string}
     * @memberof StorageSettingsDTO
     */
    'notEnoughFreeSpaceNotificationEmail'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StorageSettingsDTO
     */
    'simpleForm'?: boolean;
    /**
     * Remaining free space in percentage in storage at which notification is sent
     * @type {number}
     * @memberof StorageSettingsDTO
     */
    'notEnoughFreeSpaceNotificationThreshold': number;
}
/**
 * Contains message sent from user to tech support.
 * @export
 * @interface TechSupportDataDTO
 */
export interface TechSupportDataDTO {
    /**
     * Message from user to tech support.
     * @type {string}
     * @memberof TechSupportDataDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UploadFileRequest
 */
export interface UploadFileRequest {
    /**
     * 
     * @type {number}
     * @memberof UploadFileRequest
     */
    'short'?: number;
    /**
     * 
     * @type {string}
     * @memberof UploadFileRequest
     */
    'char'?: string;
    /**
     * 
     * @type {number}
     * @memberof UploadFileRequest
     */
    'int'?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadFileRequest
     */
    'long'?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadFileRequest
     */
    'float'?: number;
    /**
     * 
     * @type {number}
     * @memberof UploadFileRequest
     */
    'double'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFileRequest
     */
    'direct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UploadFileRequest
     */
    'readOnly'?: boolean;
}
/**
 * Request message url
 * @export
 * @interface UploadLink
 */
export interface UploadLink {
    /**
     * 
     * @type {string}
     * @memberof UploadLink
     */
    'uploadUrl'?: string;
}
/**
 * Settings - User expire
 * @export
 * @interface UserExpireSettingsDTO
 */
export interface UserExpireSettingsDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UserExpireSettingsDTO
     */
    'userExpireEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserExpireSettingsDTO
     */
    'userExpireDays'?: number;
}
/**
 * Object stores fields which will be used for select messages.
 * @export
 * @interface UserMessagesRequestParams
 */
export interface UserMessagesRequestParams {
    /**
     * 
     * @type {number}
     * @memberof UserMessagesRequestParams
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserMessagesRequestParams
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserMessagesRequestParams
     */
    'sortField'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMessagesRequestParams
     */
    'sortDir'?: UserMessagesRequestParamsSortDirEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserMessagesRequestParams
     */
    'hideInactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMessagesRequestParams
     */
    'searchText'?: string;
}

export const UserMessagesRequestParamsSortDirEnum = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export type UserMessagesRequestParamsSortDirEnum = typeof UserMessagesRequestParamsSortDirEnum[keyof typeof UserMessagesRequestParamsSortDirEnum];

/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'lastLoginDateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    'limitSpacing'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    'allowUploadOverLimit'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserModel
     */
    'roles'?: Array<UserModelRolesEnum>;
}

export const UserModelRolesEnum = {
    STANDARD_USER: 'STANDARD_USER',
    ADMIN: 'ADMIN',
    SECURITY_OFFICER: 'SECURITY_OFFICER',
    CONSUMER_API: 'CONSUMER_API'
} as const;

export type UserModelRolesEnum = typeof UserModelRolesEnum[keyof typeof UserModelRolesEnum];

/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'validationMessage'?: string;
}
/**
 * 
 * @export
 * @interface VersionInfoDto
 */
export interface VersionInfoDto {
    /**
     * 
     * @type {string}
     * @memberof VersionInfoDto
     */
    'branch'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfoDto
     */
    'commitHashFull'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfoDto
     */
    'commitHashShort'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfoDto
     */
    'commitDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfoDto
     */
    'buildDateTime'?: string;
}

/**
 * AdminMessageControllerApi - axios parameter creator
 * @export
 */
export const AdminMessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method sends all the bytes of given file id in message for admin.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile2: async (messageId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('downloadFile2', 'messageId', messageId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadFile2', 'fileId', fileId)
            const localVarPath = `/admin/messages/guid/download/{messageId}/{fileId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method of downloading files without going to the standard download page, and without increasing the \"downloaded times\" counter
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFiles: async (messageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('downloadFiles', 'messageId', messageId)
            const localVarPath = `/admin/messages/guid/download/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Receiving a message by the administrator, regardless of whether it is password protected or not
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById: async (messageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getMessageById', 'messageId', messageId)
            const localVarPath = `/admin/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method performs a page-by-page search by messages
         * @param {UserMessagesRequestParams} userMessagesRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (userMessagesRequestParams: UserMessagesRequestParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userMessagesRequestParams' is not null or undefined
            assertParamExists('getMessages', 'userMessagesRequestParams', userMessagesRequestParams)
            const localVarPath = `/admin/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMessagesRequestParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method deletes messages with files
         * @param {string} messageId 
         * @param {'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'} messageType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMessages: async (messageId: string, messageType: 'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('removeMessages', 'messageId', messageId)
            // verify required parameter 'messageType' is not null or undefined
            assertParamExists('removeMessages', 'messageType', messageType)
            const localVarPath = `/admin/messages/guid/remove/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (messageType !== undefined) {
                localVarQueryParameter['messageType'] = messageType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminMessageControllerApi - functional programming interface
 * @export
 */
export const AdminMessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminMessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method sends all the bytes of given file id in message for admin.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile2(messageId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile2(messageId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method of downloading files without going to the standard download page, and without increasing the \"downloaded times\" counter
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFiles(messageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFiles(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Receiving a message by the administrator, regardless of whether it is password protected or not
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageById(messageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagePublicModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageById(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method performs a page-by-page search by messages
         * @param {UserMessagesRequestParams} userMessagesRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(userMessagesRequestParams: UserMessagesRequestParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCommonMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(userMessagesRequestParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method deletes messages with files
         * @param {string} messageId 
         * @param {'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'} messageType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMessages(messageId: string, messageType: 'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMessages(messageId, messageType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminMessageControllerApi - factory interface
 * @export
 */
export const AdminMessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminMessageControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Method sends all the bytes of given file id in message for admin.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile2(messageId: string, fileId: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadFile2(messageId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method of downloading files without going to the standard download page, and without increasing the \"downloaded times\" counter
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFiles(messageId: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadFiles(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Receiving a message by the administrator, regardless of whether it is password protected or not
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById(messageId: string, options?: any): AxiosPromise<MessagePublicModel> {
            return localVarFp.getMessageById(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method performs a page-by-page search by messages
         * @param {UserMessagesRequestParams} userMessagesRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(userMessagesRequestParams: UserMessagesRequestParams, options?: any): AxiosPromise<PageCommonMessageDTO> {
            return localVarFp.getMessages(userMessagesRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method deletes messages with files
         * @param {string} messageId 
         * @param {'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'} messageType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMessages(messageId: string, messageType: 'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE', options?: any): AxiosPromise<void> {
            return localVarFp.removeMessages(messageId, messageType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for downloadFile2 operation in AdminMessageControllerApi.
 * @export
 * @interface AdminMessageControllerApiDownloadFile2Request
 */
export interface AdminMessageControllerApiDownloadFile2Request {
    /**
     * 
     * @type {string}
     * @memberof AdminMessageControllerApiDownloadFile2
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof AdminMessageControllerApiDownloadFile2
     */
    readonly fileId: string
}

/**
 * Request parameters for downloadFiles operation in AdminMessageControllerApi.
 * @export
 * @interface AdminMessageControllerApiDownloadFilesRequest
 */
export interface AdminMessageControllerApiDownloadFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminMessageControllerApiDownloadFiles
     */
    readonly messageId: string
}

/**
 * Request parameters for getMessageById operation in AdminMessageControllerApi.
 * @export
 * @interface AdminMessageControllerApiGetMessageByIdRequest
 */
export interface AdminMessageControllerApiGetMessageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminMessageControllerApiGetMessageById
     */
    readonly messageId: string
}

/**
 * Request parameters for getMessages operation in AdminMessageControllerApi.
 * @export
 * @interface AdminMessageControllerApiGetMessagesRequest
 */
export interface AdminMessageControllerApiGetMessagesRequest {
    /**
     * 
     * @type {UserMessagesRequestParams}
     * @memberof AdminMessageControllerApiGetMessages
     */
    readonly userMessagesRequestParams: UserMessagesRequestParams
}

/**
 * Request parameters for removeMessages operation in AdminMessageControllerApi.
 * @export
 * @interface AdminMessageControllerApiRemoveMessagesRequest
 */
export interface AdminMessageControllerApiRemoveMessagesRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminMessageControllerApiRemoveMessages
     */
    readonly messageId: string

    /**
     * 
     * @type {'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'}
     * @memberof AdminMessageControllerApiRemoveMessages
     */
    readonly messageType: 'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'
}

/**
 * AdminMessageControllerApi - object-oriented interface
 * @export
 * @class AdminMessageControllerApi
 * @extends {BaseAPI}
 */
export class AdminMessageControllerApi extends BaseAPI {
    /**
     * 
     * @summary Method sends all the bytes of given file id in message for admin.
     * @param {AdminMessageControllerApiDownloadFile2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMessageControllerApi
     */
    public downloadFile2(requestParameters: AdminMessageControllerApiDownloadFile2Request, options?: AxiosRequestConfig) {
        return AdminMessageControllerApiFp(this.configuration).downloadFile2(requestParameters.messageId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method of downloading files without going to the standard download page, and without increasing the \"downloaded times\" counter
     * @param {AdminMessageControllerApiDownloadFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMessageControllerApi
     */
    public downloadFiles(requestParameters: AdminMessageControllerApiDownloadFilesRequest, options?: AxiosRequestConfig) {
        return AdminMessageControllerApiFp(this.configuration).downloadFiles(requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Receiving a message by the administrator, regardless of whether it is password protected or not
     * @param {AdminMessageControllerApiGetMessageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMessageControllerApi
     */
    public getMessageById(requestParameters: AdminMessageControllerApiGetMessageByIdRequest, options?: AxiosRequestConfig) {
        return AdminMessageControllerApiFp(this.configuration).getMessageById(requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method performs a page-by-page search by messages
     * @param {AdminMessageControllerApiGetMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMessageControllerApi
     */
    public getMessages(requestParameters: AdminMessageControllerApiGetMessagesRequest, options?: AxiosRequestConfig) {
        return AdminMessageControllerApiFp(this.configuration).getMessages(requestParameters.userMessagesRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method deletes messages with files
     * @param {AdminMessageControllerApiRemoveMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminMessageControllerApi
     */
    public removeMessages(requestParameters: AdminMessageControllerApiRemoveMessagesRequest, options?: AxiosRequestConfig) {
        return AdminMessageControllerApiFp(this.configuration).removeMessages(requestParameters.messageId, requestParameters.messageType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationRolesControllerApi - axios parameter creator
 * @export
 */
export const ApplicationRolesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method creates a new one inner role with associated outer roles. Outer names should be concatenated by comma.
         * @param {InnerRoleNewDTO} innerRoleNewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInnerRole: async (innerRoleNewDTO: InnerRoleNewDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'innerRoleNewDTO' is not null or undefined
            assertParamExists('createInnerRole', 'innerRoleNewDTO', innerRoleNewDTO)
            const localVarPath = `/admin/roles/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(innerRoleNewDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method deletes existing inner role by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInnerRole: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteInnerRole', 'id', id)
            const localVarPath = `/admin/roles/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method returns InnerRoleDTO object if id exists. Otherwise DataNotFoundException will be thrown.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById1', 'id', id)
            const localVarPath = `/admin/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method returns list of inner roles and associated with them outer roles, concatenated using comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method updates outer or inner role name. Outer names should be concatenated by comma.
         * @param {number} id 
         * @param {InnerRoleNewDTO} innerRoleNewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInnerRole: async (id: number, innerRoleNewDTO: InnerRoleNewDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInnerRole', 'id', id)
            // verify required parameter 'innerRoleNewDTO' is not null or undefined
            assertParamExists('updateInnerRole', 'innerRoleNewDTO', innerRoleNewDTO)
            const localVarPath = `/admin/roles/{id}/update`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(innerRoleNewDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationRolesControllerApi - functional programming interface
 * @export
 */
export const ApplicationRolesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationRolesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method creates a new one inner role with associated outer roles. Outer names should be concatenated by comma.
         * @param {InnerRoleNewDTO} innerRoleNewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInnerRole(innerRoleNewDTO: InnerRoleNewDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInnerRole(innerRoleNewDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method deletes existing inner role by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInnerRole(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInnerRole(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method returns InnerRoleDTO object if id exists. Otherwise DataNotFoundException will be thrown.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method returns list of inner roles and associated with them outer roles, concatenated using comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InnerRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method updates outer or inner role name. Outer names should be concatenated by comma.
         * @param {number} id 
         * @param {InnerRoleNewDTO} innerRoleNewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInnerRole(id: number, innerRoleNewDTO: InnerRoleNewDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InnerRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInnerRole(id, innerRoleNewDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationRolesControllerApi - factory interface
 * @export
 */
export const ApplicationRolesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationRolesControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Method creates a new one inner role with associated outer roles. Outer names should be concatenated by comma.
         * @param {InnerRoleNewDTO} innerRoleNewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInnerRole(innerRoleNewDTO: InnerRoleNewDTO, options?: any): AxiosPromise<InnerRole> {
            return localVarFp.createInnerRole(innerRoleNewDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method deletes existing inner role by id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInnerRole(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInnerRole(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method returns InnerRoleDTO object if id exists. Otherwise DataNotFoundException will be thrown.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById1(id: number, options?: any): AxiosPromise<InnerRole> {
            return localVarFp.getById1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method returns list of inner roles and associated with them outer roles, concatenated using comma.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1(options?: any): AxiosPromise<Array<InnerRole>> {
            return localVarFp.list1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method updates outer or inner role name. Outer names should be concatenated by comma.
         * @param {number} id 
         * @param {InnerRoleNewDTO} innerRoleNewDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInnerRole(id: number, innerRoleNewDTO: InnerRoleNewDTO, options?: any): AxiosPromise<InnerRole> {
            return localVarFp.updateInnerRole(id, innerRoleNewDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createInnerRole operation in ApplicationRolesControllerApi.
 * @export
 * @interface ApplicationRolesControllerApiCreateInnerRoleRequest
 */
export interface ApplicationRolesControllerApiCreateInnerRoleRequest {
    /**
     * 
     * @type {InnerRoleNewDTO}
     * @memberof ApplicationRolesControllerApiCreateInnerRole
     */
    readonly innerRoleNewDTO: InnerRoleNewDTO
}

/**
 * Request parameters for deleteInnerRole operation in ApplicationRolesControllerApi.
 * @export
 * @interface ApplicationRolesControllerApiDeleteInnerRoleRequest
 */
export interface ApplicationRolesControllerApiDeleteInnerRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof ApplicationRolesControllerApiDeleteInnerRole
     */
    readonly id: number
}

/**
 * Request parameters for getById1 operation in ApplicationRolesControllerApi.
 * @export
 * @interface ApplicationRolesControllerApiGetById1Request
 */
export interface ApplicationRolesControllerApiGetById1Request {
    /**
     * 
     * @type {number}
     * @memberof ApplicationRolesControllerApiGetById1
     */
    readonly id: number
}

/**
 * Request parameters for updateInnerRole operation in ApplicationRolesControllerApi.
 * @export
 * @interface ApplicationRolesControllerApiUpdateInnerRoleRequest
 */
export interface ApplicationRolesControllerApiUpdateInnerRoleRequest {
    /**
     * 
     * @type {number}
     * @memberof ApplicationRolesControllerApiUpdateInnerRole
     */
    readonly id: number

    /**
     * 
     * @type {InnerRoleNewDTO}
     * @memberof ApplicationRolesControllerApiUpdateInnerRole
     */
    readonly innerRoleNewDTO: InnerRoleNewDTO
}

/**
 * ApplicationRolesControllerApi - object-oriented interface
 * @export
 * @class ApplicationRolesControllerApi
 * @extends {BaseAPI}
 */
export class ApplicationRolesControllerApi extends BaseAPI {
    /**
     * 
     * @summary Method creates a new one inner role with associated outer roles. Outer names should be concatenated by comma.
     * @param {ApplicationRolesControllerApiCreateInnerRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationRolesControllerApi
     */
    public createInnerRole(requestParameters: ApplicationRolesControllerApiCreateInnerRoleRequest, options?: AxiosRequestConfig) {
        return ApplicationRolesControllerApiFp(this.configuration).createInnerRole(requestParameters.innerRoleNewDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method deletes existing inner role by id.
     * @param {ApplicationRolesControllerApiDeleteInnerRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationRolesControllerApi
     */
    public deleteInnerRole(requestParameters: ApplicationRolesControllerApiDeleteInnerRoleRequest, options?: AxiosRequestConfig) {
        return ApplicationRolesControllerApiFp(this.configuration).deleteInnerRole(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method returns InnerRoleDTO object if id exists. Otherwise DataNotFoundException will be thrown.
     * @param {ApplicationRolesControllerApiGetById1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationRolesControllerApi
     */
    public getById1(requestParameters: ApplicationRolesControllerApiGetById1Request, options?: AxiosRequestConfig) {
        return ApplicationRolesControllerApiFp(this.configuration).getById1(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method returns list of inner roles and associated with them outer roles, concatenated using comma.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationRolesControllerApi
     */
    public list1(options?: AxiosRequestConfig) {
        return ApplicationRolesControllerApiFp(this.configuration).list1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method updates outer or inner role name. Outer names should be concatenated by comma.
     * @param {ApplicationRolesControllerApiUpdateInnerRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationRolesControllerApi
     */
    public updateInnerRole(requestParameters: ApplicationRolesControllerApiUpdateInnerRoleRequest, options?: AxiosRequestConfig) {
        return ApplicationRolesControllerApiFp(this.configuration).updateInnerRole(requestParameters.id, requestParameters.innerRoleNewDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesControllerApi - axios parameter creator
 * @export
 */
export const FilesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns information needed to draw the upload progressbar under the files in the message
         * @param {FileUploadProgressbarRequest} fileUploadProgressbarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgressbarStats: async (fileUploadProgressbarRequest: FileUploadProgressbarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileUploadProgressbarRequest' is not null or undefined
            assertParamExists('getProgressbarStats', 'fileUploadProgressbarRequest', fileUploadProgressbarRequest)
            const localVarPath = `/file/upload/progressbar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUploadProgressbarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the current state of the given files
         * @param {FileStateRequest} fileStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getState: async (fileStateRequest: FileStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileStateRequest' is not null or undefined
            assertParamExists('getState', 'fileStateRequest', fileStateRequest)
            const localVarPath = `/file/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesControllerApi - functional programming interface
 * @export
 */
export const FilesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns information needed to draw the upload progressbar under the files in the message
         * @param {FileUploadProgressbarRequest} fileUploadProgressbarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgressbarStats(fileUploadProgressbarRequest: FileUploadProgressbarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadProgressbarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgressbarStats(fileUploadProgressbarRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the current state of the given files
         * @param {FileStateRequest} fileStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getState(fileStateRequest: FileStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getState(fileStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesControllerApi - factory interface
 * @export
 */
export const FilesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesControllerApiFp(configuration)
    return {
        /**
         * Returns information needed to draw the upload progressbar under the files in the message
         * @param {FileUploadProgressbarRequest} fileUploadProgressbarRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgressbarStats(fileUploadProgressbarRequest: FileUploadProgressbarRequest, options?: any): AxiosPromise<FileUploadProgressbarResponse> {
            return localVarFp.getProgressbarStats(fileUploadProgressbarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the current state of the given files
         * @param {FileStateRequest} fileStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getState(fileStateRequest: FileStateRequest, options?: any): AxiosPromise<FileStateResponse> {
            return localVarFp.getState(fileStateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProgressbarStats operation in FilesControllerApi.
 * @export
 * @interface FilesControllerApiGetProgressbarStatsRequest
 */
export interface FilesControllerApiGetProgressbarStatsRequest {
    /**
     * 
     * @type {FileUploadProgressbarRequest}
     * @memberof FilesControllerApiGetProgressbarStats
     */
    readonly fileUploadProgressbarRequest: FileUploadProgressbarRequest
}

/**
 * Request parameters for getState operation in FilesControllerApi.
 * @export
 * @interface FilesControllerApiGetStateRequest
 */
export interface FilesControllerApiGetStateRequest {
    /**
     * 
     * @type {FileStateRequest}
     * @memberof FilesControllerApiGetState
     */
    readonly fileStateRequest: FileStateRequest
}

/**
 * FilesControllerApi - object-oriented interface
 * @export
 * @class FilesControllerApi
 * @extends {BaseAPI}
 */
export class FilesControllerApi extends BaseAPI {
    /**
     * Returns information needed to draw the upload progressbar under the files in the message
     * @param {FilesControllerApiGetProgressbarStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public getProgressbarStats(requestParameters: FilesControllerApiGetProgressbarStatsRequest, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).getProgressbarStats(requestParameters.fileUploadProgressbarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the current state of the given files
     * @param {FilesControllerApiGetStateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesControllerApi
     */
    public getState(requestParameters: FilesControllerApiGetStateRequest, options?: AxiosRequestConfig) {
        return FilesControllerApiFp(this.configuration).getState(requestParameters.fileStateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GitInfoControllerApi - axios parameter creator
 * @export
 */
export const GitInfoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Git build info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GitInfoControllerApi - functional programming interface
 * @export
 */
export const GitInfoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GitInfoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Git build info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GitInfoControllerApi - factory interface
 * @export
 */
export const GitInfoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GitInfoControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Git build info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: any): AxiosPromise<VersionInfoDto> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GitInfoControllerApi - object-oriented interface
 * @export
 * @class GitInfoControllerApi
 * @extends {BaseAPI}
 */
export class GitInfoControllerApi extends BaseAPI {
    /**
     * 
     * @summary Git build info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitInfoControllerApi
     */
    public getVersion(options?: AxiosRequestConfig) {
        return GitInfoControllerApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogFileControllerApi - axios parameter creator
 * @export
 */
export const LogFileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LogFileDownloadRequest} logFileDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (logFileDownloadRequest: LogFileDownloadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logFileDownloadRequest' is not null or undefined
            assertParamExists('download', 'logFileDownloadRequest', logFileDownloadRequest)
            const localVarPath = `/admin/log/file/download/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logFileDownloadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} logFileName File name of the log file to download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download1: async (logFileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logFileName' is not null or undefined
            assertParamExists('download1', 'logFileName', logFileName)
            const localVarPath = `/admin/log/file/download/{logFileName}`
                .replace(`{${"logFileName"}}`, encodeURIComponent(String(logFileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogFileListRequest} logFileListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (logFileListRequest: LogFileListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logFileListRequest' is not null or undefined
            assertParamExists('list', 'logFileListRequest', logFileListRequest)
            const localVarPath = `/admin/log/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logFileListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} logFileName File name of the log file to read
         * @param {LogFileViewRequest} logFileViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view: async (logFileName: string, logFileViewRequest: LogFileViewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logFileName' is not null or undefined
            assertParamExists('view', 'logFileName', logFileName)
            // verify required parameter 'logFileViewRequest' is not null or undefined
            assertParamExists('view', 'logFileViewRequest', logFileViewRequest)
            const localVarPath = `/admin/log/file/view/{logFileName}`
                .replace(`{${"logFileName"}}`, encodeURIComponent(String(logFileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logFileViewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogFileControllerApi - functional programming interface
 * @export
 */
export const LogFileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogFileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LogFileDownloadRequest} logFileDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(logFileDownloadRequest: LogFileDownloadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(logFileDownloadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} logFileName File name of the log file to download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download1(logFileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download1(logFileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LogFileListRequest} logFileListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(logFileListRequest: LogFileListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLogFileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(logFileListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} logFileName File name of the log file to read
         * @param {LogFileViewRequest} logFileViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async view(logFileName: string, logFileViewRequest: LogFileViewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.view(logFileName, logFileViewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogFileControllerApi - factory interface
 * @export
 */
export const LogFileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogFileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {LogFileDownloadRequest} logFileDownloadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(logFileDownloadRequest: LogFileDownloadRequest, options?: any): AxiosPromise<void> {
            return localVarFp.download(logFileDownloadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} logFileName File name of the log file to download
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download1(logFileName: string, options?: any): AxiosPromise<void> {
            return localVarFp.download1(logFileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogFileListRequest} logFileListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(logFileListRequest: LogFileListRequest, options?: any): AxiosPromise<PageLogFileDTO> {
            return localVarFp.list(logFileListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} logFileName File name of the log file to read
         * @param {LogFileViewRequest} logFileViewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        view(logFileName: string, logFileViewRequest: LogFileViewRequest, options?: any): AxiosPromise<void> {
            return localVarFp.view(logFileName, logFileViewRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for download operation in LogFileControllerApi.
 * @export
 * @interface LogFileControllerApiDownloadRequest
 */
export interface LogFileControllerApiDownloadRequest {
    /**
     * 
     * @type {LogFileDownloadRequest}
     * @memberof LogFileControllerApiDownload
     */
    readonly logFileDownloadRequest: LogFileDownloadRequest
}

/**
 * Request parameters for download1 operation in LogFileControllerApi.
 * @export
 * @interface LogFileControllerApiDownload1Request
 */
export interface LogFileControllerApiDownload1Request {
    /**
     * File name of the log file to download
     * @type {string}
     * @memberof LogFileControllerApiDownload1
     */
    readonly logFileName: string
}

/**
 * Request parameters for list operation in LogFileControllerApi.
 * @export
 * @interface LogFileControllerApiListRequest
 */
export interface LogFileControllerApiListRequest {
    /**
     * 
     * @type {LogFileListRequest}
     * @memberof LogFileControllerApiList
     */
    readonly logFileListRequest: LogFileListRequest
}

/**
 * Request parameters for view operation in LogFileControllerApi.
 * @export
 * @interface LogFileControllerApiViewRequest
 */
export interface LogFileControllerApiViewRequest {
    /**
     * File name of the log file to read
     * @type {string}
     * @memberof LogFileControllerApiView
     */
    readonly logFileName: string

    /**
     * 
     * @type {LogFileViewRequest}
     * @memberof LogFileControllerApiView
     */
    readonly logFileViewRequest: LogFileViewRequest
}

/**
 * LogFileControllerApi - object-oriented interface
 * @export
 * @class LogFileControllerApi
 * @extends {BaseAPI}
 */
export class LogFileControllerApi extends BaseAPI {
    /**
     * 
     * @param {LogFileControllerApiDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogFileControllerApi
     */
    public download(requestParameters: LogFileControllerApiDownloadRequest, options?: AxiosRequestConfig) {
        return LogFileControllerApiFp(this.configuration).download(requestParameters.logFileDownloadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogFileControllerApiDownload1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogFileControllerApi
     */
    public download1(requestParameters: LogFileControllerApiDownload1Request, options?: AxiosRequestConfig) {
        return LogFileControllerApiFp(this.configuration).download1(requestParameters.logFileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogFileControllerApiListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogFileControllerApi
     */
    public list(requestParameters: LogFileControllerApiListRequest, options?: AxiosRequestConfig) {
        return LogFileControllerApiFp(this.configuration).list(requestParameters.logFileListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogFileControllerApiViewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogFileControllerApi
     */
    public view(requestParameters: LogFileControllerApiViewRequest, options?: AxiosRequestConfig) {
        return LogFileControllerApiFp(this.configuration).view(requestParameters.logFileName, requestParameters.logFileViewRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginControllerApi - axios parameter creator
 * @export
 */
export const LoginControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create authentication token by request body parameters and return it.
         * @summary Return authentication token.
         * @param {AuthenticationRequestModel} authenticationRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthenticationToken: async (authenticationRequestModel: AuthenticationRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationRequestModel' is not null or undefined
            assertParamExists('createAuthenticationToken', 'authenticationRequestModel', authenticationRequestModel)
            const localVarPath = `/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns true/false according to access token validity.
         * @summary validateAccessToken
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isTokenValid: async (accessToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessToken' is not null or undefined
            assertParamExists('isTokenValid', 'accessToken', accessToken)
            const localVarPath = `/token/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint accepts refresh token and in case of its validity returns new access token or exception.AuthenticationResponse model contains only access and refresh token, other fields are empty.
         * @summary recreateAccessToken
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateAccessToken: async (refreshToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('recreateAccessToken', 'refreshToken', refreshToken)
            const localVarPath = `/token/recreateAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refreshToken'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginControllerApi - functional programming interface
 * @export
 */
export const LoginControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create authentication token by request body parameters and return it.
         * @summary Return authentication token.
         * @param {AuthenticationRequestModel} authenticationRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAuthenticationToken(authenticationRequestModel: AuthenticationRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAuthenticationToken(authenticationRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns true/false according to access token validity.
         * @summary validateAccessToken
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isTokenValid(accessToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isTokenValid(accessToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logout user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint accepts refresh token and in case of its validity returns new access token or exception.AuthenticationResponse model contains only access and refresh token, other fields are empty.
         * @summary recreateAccessToken
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recreateAccessToken(refreshToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recreateAccessToken(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginControllerApi - factory interface
 * @export
 */
export const LoginControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginControllerApiFp(configuration)
    return {
        /**
         * Create authentication token by request body parameters and return it.
         * @summary Return authentication token.
         * @param {AuthenticationRequestModel} authenticationRequestModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthenticationToken(authenticationRequestModel: AuthenticationRequestModel, options?: any): AxiosPromise<AuthenticationResponseModel> {
            return localVarFp.createAuthenticationToken(authenticationRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns true/false according to access token validity.
         * @summary validateAccessToken
         * @param {string} accessToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isTokenValid(accessToken: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isTokenValid(accessToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<object> {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint accepts refresh token and in case of its validity returns new access token or exception.AuthenticationResponse model contains only access and refresh token, other fields are empty.
         * @summary recreateAccessToken
         * @param {string} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recreateAccessToken(refreshToken: string, options?: any): AxiosPromise<AuthenticationResponseModel> {
            return localVarFp.recreateAccessToken(refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAuthenticationToken operation in LoginControllerApi.
 * @export
 * @interface LoginControllerApiCreateAuthenticationTokenRequest
 */
export interface LoginControllerApiCreateAuthenticationTokenRequest {
    /**
     * 
     * @type {AuthenticationRequestModel}
     * @memberof LoginControllerApiCreateAuthenticationToken
     */
    readonly authenticationRequestModel: AuthenticationRequestModel
}

/**
 * Request parameters for isTokenValid operation in LoginControllerApi.
 * @export
 * @interface LoginControllerApiIsTokenValidRequest
 */
export interface LoginControllerApiIsTokenValidRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginControllerApiIsTokenValid
     */
    readonly accessToken: string
}

/**
 * Request parameters for recreateAccessToken operation in LoginControllerApi.
 * @export
 * @interface LoginControllerApiRecreateAccessTokenRequest
 */
export interface LoginControllerApiRecreateAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginControllerApiRecreateAccessToken
     */
    readonly refreshToken: string
}

/**
 * LoginControllerApi - object-oriented interface
 * @export
 * @class LoginControllerApi
 * @extends {BaseAPI}
 */
export class LoginControllerApi extends BaseAPI {
    /**
     * Create authentication token by request body parameters and return it.
     * @summary Return authentication token.
     * @param {LoginControllerApiCreateAuthenticationTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public createAuthenticationToken(requestParameters: LoginControllerApiCreateAuthenticationTokenRequest, options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).createAuthenticationToken(requestParameters.authenticationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns true/false according to access token validity.
     * @summary validateAccessToken
     * @param {LoginControllerApiIsTokenValidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public isTokenValid(requestParameters: LoginControllerApiIsTokenValidRequest, options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).isTokenValid(requestParameters.accessToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public logout(options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint accepts refresh token and in case of its validity returns new access token or exception.AuthenticationResponse model contains only access and refresh token, other fields are empty.
     * @summary recreateAccessToken
     * @param {LoginControllerApiRecreateAccessTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginControllerApi
     */
    public recreateAccessToken(requestParameters: LoginControllerApiRecreateAccessTokenRequest, options?: AxiosRequestConfig) {
        return LoginControllerApiFp(this.configuration).recreateAccessToken(requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new message and return it as MessageModel object
         * @summary Create a new message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete file from message.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileFromMessage: async (messageId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('deleteFileFromMessage', 'messageId', messageId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFileFromMessage', 'fileId', fileId)
            const localVarPath = `/message/{messageId}/file/{fileId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete message.
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage1: async (messageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('deleteMessage1', 'messageId', messageId)
            const localVarPath = `/message/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method zips all the files in message and send them to client. 
         * @param {string} messageId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllFiles1: async (messageId: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('downloadAllFiles1', 'messageId', messageId)
            const localVarPath = `/message/{messageId}/download`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} messageId 
         * @param {string} linkId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile1: async (messageId: string, linkId: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('downloadFile1', 'messageId', messageId)
            // verify required parameter 'linkId' is not null or undefined
            assertParamExists('downloadFile1', 'linkId', linkId)
            const localVarPath = `/message/{messageId}/download/{linkId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFileDirectly: async (messageId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('downloadFileDirectly', 'messageId', messageId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadFileDirectly', 'fileId', fileId)
            const localVarPath = `/message/{messageId}/{fileId}/directDownload`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method sends link to file.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToFile1: async (messageId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getLinkToFile1', 'messageId', messageId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getLinkToFile1', 'fileId', fileId)
            const localVarPath = `/message/{messageId}/{fileId}/link`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a messagePublicModel with the same id in request param.1. The response body must contains fields: isProtected, sender, expireTime, files. If isProtected = true, files = null, it means that request must contains query parameter \'password\'
         * @summary Get messagePublicModel by id
         * @param {string} messageId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicMessageById: async (messageId: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getPublicMessageById', 'messageId', messageId)
            const localVarPath = `/message/public/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint reserves file in response message and returns file with id.
         * @param {string} messageId 
         * @param {FileUploadPrepareRequest} fileUploadPrepareRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveFileInMessage1: async (messageId: string, fileUploadPrepareRequest: FileUploadPrepareRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('reserveFileInMessage1', 'messageId', messageId)
            // verify required parameter 'fileUploadPrepareRequest' is not null or undefined
            assertParamExists('reserveFileInMessage1', 'fileUploadPrepareRequest', fileUploadPrepareRequest)
            const localVarPath = `/message/{messageId}/reserveFile`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUploadPrepareRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Setting message summarys and sending
         * @summary Send message
         * @param {string} messageId 
         * @param {EmailMessageModel} emailMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAndSendMessageByEmail: async (messageId: string, emailMessageModel: EmailMessageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('updateAndSendMessageByEmail', 'messageId', messageId)
            // verify required parameter 'emailMessageModel' is not null or undefined
            assertParamExists('updateAndSendMessageByEmail', 'emailMessageModel', emailMessageModel)
            const localVarPath = `/message/send/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailMessageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * All fields of the messageModel object will rewrite info of the same fields of message in DB with id = messageId
         * @summary Update message
         * @param {string} messageId 
         * @param {LinkMessageModel} linkMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessage: async (messageId: string, linkMessageModel: LinkMessageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('updateMessage', 'messageId', messageId)
            // verify required parameter 'linkMessageModel' is not null or undefined
            assertParamExists('updateMessage', 'linkMessageModel', linkMessageModel)
            const localVarPath = `/message/update/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkMessageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint gives client ability to send file and put it in message. Before sending file you have to reserve it.
         * @param {string} messageId 
         * @param {string} reservedFileId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileToMessage: async (messageId: string, reservedFileId: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('uploadFileToMessage', 'messageId', messageId)
            // verify required parameter 'reservedFileId' is not null or undefined
            assertParamExists('uploadFileToMessage', 'reservedFileId', reservedFileId)
            const localVarPath = `/message/{messageId}/file/{reservedFileId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"reservedFileId"}}`, encodeURIComponent(String(reservedFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new message and return it as MessageModel object
         * @summary Create a new message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete file from message.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileFromMessage(messageId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileFromMessage(messageId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete message.
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessage1(messageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessage1(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method zips all the files in message and send them to client. 
         * @param {string} messageId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAllFiles1(messageId: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAllFiles1(messageId, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} messageId 
         * @param {string} linkId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile1(messageId: string, linkId: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile1(messageId, linkId, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFileDirectly(messageId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFileDirectly(messageId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method sends link to file.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkToFile1(messageId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkToFile1(messageId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a messagePublicModel with the same id in request param.1. The response body must contains fields: isProtected, sender, expireTime, files. If isProtected = true, files = null, it means that request must contains query parameter \'password\'
         * @summary Get messagePublicModel by id
         * @param {string} messageId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicMessageById(messageId: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagePublicModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicMessageById(messageId, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint reserves file in response message and returns file with id.
         * @param {string} messageId 
         * @param {FileUploadPrepareRequest} fileUploadPrepareRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reserveFileInMessage1(messageId: string, fileUploadPrepareRequest: FileUploadPrepareRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reserveFileInMessage1(messageId, fileUploadPrepareRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Setting message summarys and sending
         * @summary Send message
         * @param {string} messageId 
         * @param {EmailMessageModel} emailMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAndSendMessageByEmail(messageId: string, emailMessageModel: EmailMessageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAndSendMessageByEmail(messageId, emailMessageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * All fields of the messageModel object will rewrite info of the same fields of message in DB with id = messageId
         * @summary Update message
         * @param {string} messageId 
         * @param {LinkMessageModel} linkMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMessage(messageId: string, linkMessageModel: LinkMessageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMessage(messageId, linkMessageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint gives client ability to send file and put it in message. Before sending file you have to reserve it.
         * @param {string} messageId 
         * @param {string} reservedFileId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileToMessage(messageId: string, reservedFileId: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileToMessage(messageId, reservedFileId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * Create a new message and return it as MessageModel object
         * @summary Create a new message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(options?: any): AxiosPromise<MessageModel> {
            return localVarFp.createMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete file from message.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileFromMessage(messageId: string, fileId: string, options?: any): AxiosPromise<MessageModel> {
            return localVarFp.deleteFileFromMessage(messageId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete message.
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage1(messageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessage1(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method zips all the files in message and send them to client. 
         * @param {string} messageId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllFiles1(messageId: string, password?: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadAllFiles1(messageId, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} messageId 
         * @param {string} linkId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile1(messageId: string, linkId: string, password?: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadFile1(messageId, linkId, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFileDirectly(messageId: string, fileId: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadFileDirectly(messageId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method sends link to file.
         * @param {string} messageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToFile1(messageId: string, fileId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getLinkToFile1(messageId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a messagePublicModel with the same id in request param.1. The response body must contains fields: isProtected, sender, expireTime, files. If isProtected = true, files = null, it means that request must contains query parameter \'password\'
         * @summary Get messagePublicModel by id
         * @param {string} messageId 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicMessageById(messageId: string, password?: string, options?: any): AxiosPromise<MessagePublicModel> {
            return localVarFp.getPublicMessageById(messageId, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint reserves file in response message and returns file with id.
         * @param {string} messageId 
         * @param {FileUploadPrepareRequest} fileUploadPrepareRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveFileInMessage1(messageId: string, fileUploadPrepareRequest: FileUploadPrepareRequest, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.reserveFileInMessage1(messageId, fileUploadPrepareRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Setting message summarys and sending
         * @summary Send message
         * @param {string} messageId 
         * @param {EmailMessageModel} emailMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAndSendMessageByEmail(messageId: string, emailMessageModel: EmailMessageModel, options?: any): AxiosPromise<MessageModel> {
            return localVarFp.updateAndSendMessageByEmail(messageId, emailMessageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * All fields of the messageModel object will rewrite info of the same fields of message in DB with id = messageId
         * @summary Update message
         * @param {string} messageId 
         * @param {LinkMessageModel} linkMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessage(messageId: string, linkMessageModel: LinkMessageModel, options?: any): AxiosPromise<MessageModel> {
            return localVarFp.updateMessage(messageId, linkMessageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint gives client ability to send file and put it in message. Before sending file you have to reserve it.
         * @param {string} messageId 
         * @param {string} reservedFileId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileToMessage(messageId: string, reservedFileId: string, file?: File, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.uploadFileToMessage(messageId, reservedFileId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteFileFromMessage operation in MessagesApi.
 * @export
 * @interface MessagesApiDeleteFileFromMessageRequest
 */
export interface MessagesApiDeleteFileFromMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiDeleteFileFromMessage
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiDeleteFileFromMessage
     */
    readonly fileId: string
}

/**
 * Request parameters for deleteMessage1 operation in MessagesApi.
 * @export
 * @interface MessagesApiDeleteMessage1Request
 */
export interface MessagesApiDeleteMessage1Request {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiDeleteMessage1
     */
    readonly messageId: string
}

/**
 * Request parameters for downloadAllFiles1 operation in MessagesApi.
 * @export
 * @interface MessagesApiDownloadAllFiles1Request
 */
export interface MessagesApiDownloadAllFiles1Request {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiDownloadAllFiles1
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiDownloadAllFiles1
     */
    readonly password?: string
}

/**
 * Request parameters for downloadFile1 operation in MessagesApi.
 * @export
 * @interface MessagesApiDownloadFile1Request
 */
export interface MessagesApiDownloadFile1Request {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiDownloadFile1
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiDownloadFile1
     */
    readonly linkId: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiDownloadFile1
     */
    readonly password?: string
}

/**
 * Request parameters for downloadFileDirectly operation in MessagesApi.
 * @export
 * @interface MessagesApiDownloadFileDirectlyRequest
 */
export interface MessagesApiDownloadFileDirectlyRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiDownloadFileDirectly
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiDownloadFileDirectly
     */
    readonly fileId: string
}

/**
 * Request parameters for getLinkToFile1 operation in MessagesApi.
 * @export
 * @interface MessagesApiGetLinkToFile1Request
 */
export interface MessagesApiGetLinkToFile1Request {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetLinkToFile1
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetLinkToFile1
     */
    readonly fileId: string
}

/**
 * Request parameters for getPublicMessageById operation in MessagesApi.
 * @export
 * @interface MessagesApiGetPublicMessageByIdRequest
 */
export interface MessagesApiGetPublicMessageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetPublicMessageById
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiGetPublicMessageById
     */
    readonly password?: string
}

/**
 * Request parameters for reserveFileInMessage1 operation in MessagesApi.
 * @export
 * @interface MessagesApiReserveFileInMessage1Request
 */
export interface MessagesApiReserveFileInMessage1Request {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiReserveFileInMessage1
     */
    readonly messageId: string

    /**
     * 
     * @type {FileUploadPrepareRequest}
     * @memberof MessagesApiReserveFileInMessage1
     */
    readonly fileUploadPrepareRequest: FileUploadPrepareRequest
}

/**
 * Request parameters for updateAndSendMessageByEmail operation in MessagesApi.
 * @export
 * @interface MessagesApiUpdateAndSendMessageByEmailRequest
 */
export interface MessagesApiUpdateAndSendMessageByEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiUpdateAndSendMessageByEmail
     */
    readonly messageId: string

    /**
     * 
     * @type {EmailMessageModel}
     * @memberof MessagesApiUpdateAndSendMessageByEmail
     */
    readonly emailMessageModel: EmailMessageModel
}

/**
 * Request parameters for updateMessage operation in MessagesApi.
 * @export
 * @interface MessagesApiUpdateMessageRequest
 */
export interface MessagesApiUpdateMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiUpdateMessage
     */
    readonly messageId: string

    /**
     * 
     * @type {LinkMessageModel}
     * @memberof MessagesApiUpdateMessage
     */
    readonly linkMessageModel: LinkMessageModel
}

/**
 * Request parameters for uploadFileToMessage operation in MessagesApi.
 * @export
 * @interface MessagesApiUploadFileToMessageRequest
 */
export interface MessagesApiUploadFileToMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessagesApiUploadFileToMessage
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof MessagesApiUploadFileToMessage
     */
    readonly reservedFileId: string

    /**
     * 
     * @type {File}
     * @memberof MessagesApiUploadFileToMessage
     */
    readonly file?: File
}

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * Create a new message and return it as MessageModel object
     * @summary Create a new message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public createMessage(options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).createMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint gives client ability to delete file from message.
     * @param {MessagesApiDeleteFileFromMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public deleteFileFromMessage(requestParameters: MessagesApiDeleteFileFromMessageRequest, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).deleteFileFromMessage(requestParameters.messageId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint gives client ability to delete message.
     * @param {MessagesApiDeleteMessage1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public deleteMessage1(requestParameters: MessagesApiDeleteMessage1Request, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).deleteMessage1(requestParameters.messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method zips all the files in message and send them to client. 
     * @param {MessagesApiDownloadAllFiles1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public downloadAllFiles1(requestParameters: MessagesApiDownloadAllFiles1Request, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).downloadAllFiles1(requestParameters.messageId, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method sends all the bytes of given file id in message.
     * @param {MessagesApiDownloadFile1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public downloadFile1(requestParameters: MessagesApiDownloadFile1Request, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).downloadFile1(requestParameters.messageId, requestParameters.linkId, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method sends all the bytes of given file id in message.
     * @param {MessagesApiDownloadFileDirectlyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public downloadFileDirectly(requestParameters: MessagesApiDownloadFileDirectlyRequest, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).downloadFileDirectly(requestParameters.messageId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method sends link to file.
     * @param {MessagesApiGetLinkToFile1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getLinkToFile1(requestParameters: MessagesApiGetLinkToFile1Request, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getLinkToFile1(requestParameters.messageId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a messagePublicModel with the same id in request param.1. The response body must contains fields: isProtected, sender, expireTime, files. If isProtected = true, files = null, it means that request must contains query parameter \'password\'
     * @summary Get messagePublicModel by id
     * @param {MessagesApiGetPublicMessageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getPublicMessageById(requestParameters: MessagesApiGetPublicMessageByIdRequest, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getPublicMessageById(requestParameters.messageId, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint reserves file in response message and returns file with id.
     * @param {MessagesApiReserveFileInMessage1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public reserveFileInMessage1(requestParameters: MessagesApiReserveFileInMessage1Request, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).reserveFileInMessage1(requestParameters.messageId, requestParameters.fileUploadPrepareRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Setting message summarys and sending
     * @summary Send message
     * @param {MessagesApiUpdateAndSendMessageByEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public updateAndSendMessageByEmail(requestParameters: MessagesApiUpdateAndSendMessageByEmailRequest, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).updateAndSendMessageByEmail(requestParameters.messageId, requestParameters.emailMessageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All fields of the messageModel object will rewrite info of the same fields of message in DB with id = messageId
     * @summary Update message
     * @param {MessagesApiUpdateMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public updateMessage(requestParameters: MessagesApiUpdateMessageRequest, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).updateMessage(requestParameters.messageId, requestParameters.linkMessageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint gives client ability to send file and put it in message. Before sending file you have to reserve it.
     * @param {MessagesApiUploadFileToMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public uploadFileToMessage(requestParameters: MessagesApiUploadFileToMessageRequest, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).uploadFileToMessage(requestParameters.messageId, requestParameters.reservedFileId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MyFilesApi - axios parameter creator
 * @export
 */
export const MyFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method deletes the message.
         * @param {string} messageId 
         * @param {'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'} messageType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage: async (messageId: string, messageType: 'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('deleteMessage', 'messageId', messageId)
            // verify required parameter 'messageType' is not null or undefined
            assertParamExists('deleteMessage', 'messageType', messageType)
            const localVarPath = `/myFiles/{messageId}/delete`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (messageType !== undefined) {
                localVarQueryParameter['messageType'] = messageType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method finds all current user\'s messages.
         * @param {boolean} [hideInactive] 
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findResponseMessages: async (hideInactive?: boolean, searchText?: string, page?: number, size?: number, sortField?: string, sortDir?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/myFiles/receivedFiles/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hideInactive !== undefined) {
                localVarQueryParameter['hideInactive'] = hideInactive;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method finds all current user\'s messages.
         * @param {boolean} [hideInactive] 
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserMessages: async (hideInactive?: boolean, searchText?: string, page?: number, size?: number, sortField?: string, sortDir?: 'ASC' | 'DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/myFiles/sentFiles/find`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (hideInactive !== undefined) {
                localVarQueryParameter['hideInactive'] = hideInactive;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Increase message download limit
         * @summary Increase message download limit
         * @param {string} messageId 
         * @param {IncreaseDownloadLimitDTO} increaseDownloadLimitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseDownloadLimit: async (messageId: string, increaseDownloadLimitDTO: IncreaseDownloadLimitDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('increaseDownloadLimit', 'messageId', messageId)
            // verify required parameter 'increaseDownloadLimitDTO' is not null or undefined
            assertParamExists('increaseDownloadLimit', 'increaseDownloadLimitDTO', increaseDownloadLimitDTO)
            const localVarPath = `/myFiles/increase_download_limit/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(increaseDownloadLimitDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Increase message expireTime and send notifications to recipients if sendNotify is true.
         * @summary Increase message expire time
         * @param {string} messageId 
         * @param {IncreaseExpireTimeDto} increaseExpireTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseExpireTime: async (messageId: string, increaseExpireTimeDto: IncreaseExpireTimeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('increaseExpireTime', 'messageId', messageId)
            // verify required parameter 'increaseExpireTimeDto' is not null or undefined
            assertParamExists('increaseExpireTime', 'increaseExpireTimeDto', increaseExpireTimeDto)
            const localVarPath = `/myFiles/increase_expire_time/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(increaseExpireTimeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend message to recipients, which emails were received.
         * @param {string} messageId 
         * @param {EmailMessageModel} emailMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendMessage: async (messageId: string, emailMessageModel: EmailMessageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('resendMessage', 'messageId', messageId)
            // verify required parameter 'emailMessageModel' is not null or undefined
            assertParamExists('resendMessage', 'emailMessageModel', emailMessageModel)
            const localVarPath = `/myFiles/{messageId}/resend`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailMessageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyFilesApi - functional programming interface
 * @export
 */
export const MyFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method deletes the message.
         * @param {string} messageId 
         * @param {'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'} messageType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessage(messageId: string, messageType: 'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessage(messageId, messageType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method finds all current user\'s messages.
         * @param {boolean} [hideInactive] 
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findResponseMessages(hideInactive?: boolean, searchText?: string, page?: number, size?: number, sortField?: string, sortDir?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageReceivedMessageResponseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findResponseMessages(hideInactive, searchText, page, size, sortField, sortDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method finds all current user\'s messages.
         * @param {boolean} [hideInactive] 
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUserMessages(hideInactive?: boolean, searchText?: string, page?: number, size?: number, sortField?: string, sortDir?: 'ASC' | 'DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSentMessageResponseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUserMessages(hideInactive, searchText, page, size, sortField, sortDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Increase message download limit
         * @summary Increase message download limit
         * @param {string} messageId 
         * @param {IncreaseDownloadLimitDTO} increaseDownloadLimitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async increaseDownloadLimit(messageId: string, increaseDownloadLimitDTO: IncreaseDownloadLimitDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.increaseDownloadLimit(messageId, increaseDownloadLimitDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Increase message expireTime and send notifications to recipients if sendNotify is true.
         * @summary Increase message expire time
         * @param {string} messageId 
         * @param {IncreaseExpireTimeDto} increaseExpireTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async increaseExpireTime(messageId: string, increaseExpireTimeDto: IncreaseExpireTimeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.increaseExpireTime(messageId, increaseExpireTimeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend message to recipients, which emails were received.
         * @param {string} messageId 
         * @param {EmailMessageModel} emailMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendMessage(messageId: string, emailMessageModel: EmailMessageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendMessage(messageId, emailMessageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyFilesApi - factory interface
 * @export
 */
export const MyFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyFilesApiFp(configuration)
    return {
        /**
         * 
         * @summary Method deletes the message.
         * @param {string} messageId 
         * @param {'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'} messageType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage(messageId: string, messageType: 'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE', options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessage(messageId, messageType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method finds all current user\'s messages.
         * @param {boolean} [hideInactive] 
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findResponseMessages(hideInactive?: boolean, searchText?: string, page?: number, size?: number, sortField?: string, sortDir?: 'ASC' | 'DESC', options?: any): AxiosPromise<PageReceivedMessageResponseData> {
            return localVarFp.findResponseMessages(hideInactive, searchText, page, size, sortField, sortDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method finds all current user\'s messages.
         * @param {boolean} [hideInactive] 
         * @param {string} [searchText] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sortField] 
         * @param {'ASC' | 'DESC'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUserMessages(hideInactive?: boolean, searchText?: string, page?: number, size?: number, sortField?: string, sortDir?: 'ASC' | 'DESC', options?: any): AxiosPromise<PageSentMessageResponseData> {
            return localVarFp.findUserMessages(hideInactive, searchText, page, size, sortField, sortDir, options).then((request) => request(axios, basePath));
        },
        /**
         * Increase message download limit
         * @summary Increase message download limit
         * @param {string} messageId 
         * @param {IncreaseDownloadLimitDTO} increaseDownloadLimitDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseDownloadLimit(messageId: string, increaseDownloadLimitDTO: IncreaseDownloadLimitDTO, options?: any): AxiosPromise<void> {
            return localVarFp.increaseDownloadLimit(messageId, increaseDownloadLimitDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Increase message expireTime and send notifications to recipients if sendNotify is true.
         * @summary Increase message expire time
         * @param {string} messageId 
         * @param {IncreaseExpireTimeDto} increaseExpireTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        increaseExpireTime(messageId: string, increaseExpireTimeDto: IncreaseExpireTimeDto, options?: any): AxiosPromise<void> {
            return localVarFp.increaseExpireTime(messageId, increaseExpireTimeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend message to recipients, which emails were received.
         * @param {string} messageId 
         * @param {EmailMessageModel} emailMessageModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendMessage(messageId: string, emailMessageModel: EmailMessageModel, options?: any): AxiosPromise<string> {
            return localVarFp.resendMessage(messageId, emailMessageModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteMessage operation in MyFilesApi.
 * @export
 * @interface MyFilesApiDeleteMessageRequest
 */
export interface MyFilesApiDeleteMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MyFilesApiDeleteMessage
     */
    readonly messageId: string

    /**
     * 
     * @type {'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'}
     * @memberof MyFilesApiDeleteMessage
     */
    readonly messageType: 'MESSAGE' | 'RESPONSE_MESSAGE' | 'REQUEST_MESSAGE'
}

/**
 * Request parameters for findResponseMessages operation in MyFilesApi.
 * @export
 * @interface MyFilesApiFindResponseMessagesRequest
 */
export interface MyFilesApiFindResponseMessagesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MyFilesApiFindResponseMessages
     */
    readonly hideInactive?: boolean

    /**
     * 
     * @type {string}
     * @memberof MyFilesApiFindResponseMessages
     */
    readonly searchText?: string

    /**
     * 
     * @type {number}
     * @memberof MyFilesApiFindResponseMessages
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MyFilesApiFindResponseMessages
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MyFilesApiFindResponseMessages
     */
    readonly sortField?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof MyFilesApiFindResponseMessages
     */
    readonly sortDir?: 'ASC' | 'DESC'
}

/**
 * Request parameters for findUserMessages operation in MyFilesApi.
 * @export
 * @interface MyFilesApiFindUserMessagesRequest
 */
export interface MyFilesApiFindUserMessagesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof MyFilesApiFindUserMessages
     */
    readonly hideInactive?: boolean

    /**
     * 
     * @type {string}
     * @memberof MyFilesApiFindUserMessages
     */
    readonly searchText?: string

    /**
     * 
     * @type {number}
     * @memberof MyFilesApiFindUserMessages
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MyFilesApiFindUserMessages
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MyFilesApiFindUserMessages
     */
    readonly sortField?: string

    /**
     * 
     * @type {'ASC' | 'DESC'}
     * @memberof MyFilesApiFindUserMessages
     */
    readonly sortDir?: 'ASC' | 'DESC'
}

/**
 * Request parameters for increaseDownloadLimit operation in MyFilesApi.
 * @export
 * @interface MyFilesApiIncreaseDownloadLimitRequest
 */
export interface MyFilesApiIncreaseDownloadLimitRequest {
    /**
     * 
     * @type {string}
     * @memberof MyFilesApiIncreaseDownloadLimit
     */
    readonly messageId: string

    /**
     * 
     * @type {IncreaseDownloadLimitDTO}
     * @memberof MyFilesApiIncreaseDownloadLimit
     */
    readonly increaseDownloadLimitDTO: IncreaseDownloadLimitDTO
}

/**
 * Request parameters for increaseExpireTime operation in MyFilesApi.
 * @export
 * @interface MyFilesApiIncreaseExpireTimeRequest
 */
export interface MyFilesApiIncreaseExpireTimeRequest {
    /**
     * 
     * @type {string}
     * @memberof MyFilesApiIncreaseExpireTime
     */
    readonly messageId: string

    /**
     * 
     * @type {IncreaseExpireTimeDto}
     * @memberof MyFilesApiIncreaseExpireTime
     */
    readonly increaseExpireTimeDto: IncreaseExpireTimeDto
}

/**
 * Request parameters for resendMessage operation in MyFilesApi.
 * @export
 * @interface MyFilesApiResendMessageRequest
 */
export interface MyFilesApiResendMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MyFilesApiResendMessage
     */
    readonly messageId: string

    /**
     * 
     * @type {EmailMessageModel}
     * @memberof MyFilesApiResendMessage
     */
    readonly emailMessageModel: EmailMessageModel
}

/**
 * MyFilesApi - object-oriented interface
 * @export
 * @class MyFilesApi
 * @extends {BaseAPI}
 */
export class MyFilesApi extends BaseAPI {
    /**
     * 
     * @summary Method deletes the message.
     * @param {MyFilesApiDeleteMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFilesApi
     */
    public deleteMessage(requestParameters: MyFilesApiDeleteMessageRequest, options?: AxiosRequestConfig) {
        return MyFilesApiFp(this.configuration).deleteMessage(requestParameters.messageId, requestParameters.messageType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method finds all current user\'s messages.
     * @param {MyFilesApiFindResponseMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFilesApi
     */
    public findResponseMessages(requestParameters: MyFilesApiFindResponseMessagesRequest = {}, options?: AxiosRequestConfig) {
        return MyFilesApiFp(this.configuration).findResponseMessages(requestParameters.hideInactive, requestParameters.searchText, requestParameters.page, requestParameters.size, requestParameters.sortField, requestParameters.sortDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method finds all current user\'s messages.
     * @param {MyFilesApiFindUserMessagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFilesApi
     */
    public findUserMessages(requestParameters: MyFilesApiFindUserMessagesRequest = {}, options?: AxiosRequestConfig) {
        return MyFilesApiFp(this.configuration).findUserMessages(requestParameters.hideInactive, requestParameters.searchText, requestParameters.page, requestParameters.size, requestParameters.sortField, requestParameters.sortDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Increase message download limit
     * @summary Increase message download limit
     * @param {MyFilesApiIncreaseDownloadLimitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFilesApi
     */
    public increaseDownloadLimit(requestParameters: MyFilesApiIncreaseDownloadLimitRequest, options?: AxiosRequestConfig) {
        return MyFilesApiFp(this.configuration).increaseDownloadLimit(requestParameters.messageId, requestParameters.increaseDownloadLimitDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Increase message expireTime and send notifications to recipients if sendNotify is true.
     * @summary Increase message expire time
     * @param {MyFilesApiIncreaseExpireTimeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFilesApi
     */
    public increaseExpireTime(requestParameters: MyFilesApiIncreaseExpireTimeRequest, options?: AxiosRequestConfig) {
        return MyFilesApiFp(this.configuration).increaseExpireTime(requestParameters.messageId, requestParameters.increaseExpireTimeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend message to recipients, which emails were received.
     * @param {MyFilesApiResendMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyFilesApi
     */
    public resendMessage(requestParameters: MyFilesApiResendMessageRequest, options?: AxiosRequestConfig) {
        return MyFilesApiFp(this.configuration).resendMessage(requestParameters.messageId, requestParameters.emailMessageModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivateSettingsControllerApi - axios parameter creator
 * @export
 */
export const PrivateSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Settings - Common: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivateSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/private/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateSettingsControllerApi - functional programming interface
 * @export
 */
export const PrivateSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Settings - Common: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivateSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrivateSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateSettingsControllerApi - factory interface
 * @export
 */
export const PrivateSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Settings - Common: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivateSettings(options?: any): AxiosPromise<PrivateSettingsDTO> {
            return localVarFp.getPrivateSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrivateSettingsControllerApi - object-oriented interface
 * @export
 * @class PrivateSettingsControllerApi
 * @extends {BaseAPI}
 */
export class PrivateSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Settings - Common: get settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateSettingsControllerApi
     */
    public getPrivateSettings(options?: AxiosRequestConfig) {
        return PrivateSettingsControllerApiFp(this.configuration).getPrivateSettings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicSettingsControllerApi - axios parameter creator
 * @export
 */
export const PublicSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Endpoint with custom image for mobile version.
         * @param {string} uniqueName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppLogoForMobile: async (uniqueName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueName' is not null or undefined
            assertParamExists('getAppLogoForMobile', 'uniqueName', uniqueName)
            const localVarPath = `/public/settings/customLogoMobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uniqueName !== undefined) {
                localVarQueryParameter['uniqueName'] = uniqueName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint with custom image.
         * @param {string} uniqueName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkFromDb: async (uniqueName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uniqueName' is not null or undefined
            assertParamExists('getLinkFromDb', 'uniqueName', uniqueName)
            const localVarPath = `/public/settings/customLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uniqueName !== undefined) {
                localVarQueryParameter['uniqueName'] = uniqueName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns path to logo image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogoUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/settings/logoPath`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - Common: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicSettingsControllerApi - functional programming interface
 * @export
 */
export const PublicSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Endpoint with custom image for mobile version.
         * @param {string} uniqueName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppLogoForMobile(uniqueName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppLogoForMobile(uniqueName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Endpoint with custom image.
         * @param {string} uniqueName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkFromDb(uniqueName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkFromDb(uniqueName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns path to logo image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogoUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppLogo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogoUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - Common: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicSettingsControllerApi - factory interface
 * @export
 */
export const PublicSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Endpoint with custom image for mobile version.
         * @param {string} uniqueName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppLogoForMobile(uniqueName: string, options?: any): AxiosPromise<object> {
            return localVarFp.getAppLogoForMobile(uniqueName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint with custom image.
         * @param {string} uniqueName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkFromDb(uniqueName: string, options?: any): AxiosPromise<object> {
            return localVarFp.getLinkFromDb(uniqueName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns path to logo image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogoUrl(options?: any): AxiosPromise<AppLogo> {
            return localVarFp.getLogoUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - Common: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSettings(options?: any): AxiosPromise<PublicSettingsDTO> {
            return localVarFp.getPublicSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAppLogoForMobile operation in PublicSettingsControllerApi.
 * @export
 * @interface PublicSettingsControllerApiGetAppLogoForMobileRequest
 */
export interface PublicSettingsControllerApiGetAppLogoForMobileRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicSettingsControllerApiGetAppLogoForMobile
     */
    readonly uniqueName: string
}

/**
 * Request parameters for getLinkFromDb operation in PublicSettingsControllerApi.
 * @export
 * @interface PublicSettingsControllerApiGetLinkFromDbRequest
 */
export interface PublicSettingsControllerApiGetLinkFromDbRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicSettingsControllerApiGetLinkFromDb
     */
    readonly uniqueName: string
}

/**
 * PublicSettingsControllerApi - object-oriented interface
 * @export
 * @class PublicSettingsControllerApi
 * @extends {BaseAPI}
 */
export class PublicSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Endpoint with custom image for mobile version.
     * @param {PublicSettingsControllerApiGetAppLogoForMobileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsControllerApi
     */
    public getAppLogoForMobile(requestParameters: PublicSettingsControllerApiGetAppLogoForMobileRequest, options?: AxiosRequestConfig) {
        return PublicSettingsControllerApiFp(this.configuration).getAppLogoForMobile(requestParameters.uniqueName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint with custom image.
     * @param {PublicSettingsControllerApiGetLinkFromDbRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsControllerApi
     */
    public getLinkFromDb(requestParameters: PublicSettingsControllerApiGetLinkFromDbRequest, options?: AxiosRequestConfig) {
        return PublicSettingsControllerApiFp(this.configuration).getLinkFromDb(requestParameters.uniqueName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns path to logo image.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsControllerApi
     */
    public getLogoUrl(options?: AxiosRequestConfig) {
        return PublicSettingsControllerApiFp(this.configuration).getLogoUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - Common: get settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicSettingsControllerApi
     */
    public getPublicSettings(options?: AxiosRequestConfig) {
        return PublicSettingsControllerApiFp(this.configuration).getPublicSettings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RefControllerApi - axios parameter creator
 * @export
 */
export const RefControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method finds all emails.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ref/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method finds emails from AD.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailsFromAD: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ref/email/ad`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RefControllerApi - functional programming interface
 * @export
 */
export const RefControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RefControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method finds all emails.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method finds emails from AD.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailsFromAD(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailsFromAD(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RefControllerApi - factory interface
 * @export
 */
export const RefControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RefControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Method finds all emails.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmails(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method finds emails from AD.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailsFromAD(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getEmailsFromAD(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RefControllerApi - object-oriented interface
 * @export
 * @class RefControllerApi
 * @extends {BaseAPI}
 */
export class RefControllerApi extends BaseAPI {
    /**
     * 
     * @summary Method finds all emails.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefControllerApi
     */
    public getEmails(options?: AxiosRequestConfig) {
        return RefControllerApiFp(this.configuration).getEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method finds emails from AD.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RefControllerApi
     */
    public getEmailsFromAD(options?: AxiosRequestConfig) {
        return RefControllerApiFp(this.configuration).getEmailsFromAD(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsControllerApi - axios parameter creator
 * @export
 */
export const ReportsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method performs a page-by-page search by files
         * @param {ReportsFilesRequestParams} reportsFilesRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsFile: async (reportsFilesRequestParams: ReportsFilesRequestParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportsFilesRequestParams' is not null or undefined
            assertParamExists('getReportsFile', 'reportsFilesRequestParams', reportsFilesRequestParams)
            const localVarPath = `/admin/reports/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportsFilesRequestParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method performs a page-by-page search by users
         * @param {ReportsUserRequestParams} reportsUserRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsUser: async (reportsUserRequestParams: ReportsUserRequestParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportsUserRequestParams' is not null or undefined
            assertParamExists('getReportsUser', 'reportsUserRequestParams', reportsUserRequestParams)
            const localVarPath = `/admin/reports/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportsUserRequestParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsControllerApi - functional programming interface
 * @export
 */
export const ReportsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method performs a page-by-page search by files
         * @param {ReportsFilesRequestParams} reportsFilesRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportsFile(reportsFilesRequestParams: ReportsFilesRequestParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageReportsFileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportsFile(reportsFilesRequestParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method performs a page-by-page search by users
         * @param {ReportsUserRequestParams} reportsUserRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportsUser(reportsUserRequestParams: ReportsUserRequestParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageReportsUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportsUser(reportsUserRequestParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsControllerApi - factory interface
 * @export
 */
export const ReportsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Method performs a page-by-page search by files
         * @param {ReportsFilesRequestParams} reportsFilesRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsFile(reportsFilesRequestParams: ReportsFilesRequestParams, options?: any): AxiosPromise<PageReportsFileDTO> {
            return localVarFp.getReportsFile(reportsFilesRequestParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method performs a page-by-page search by users
         * @param {ReportsUserRequestParams} reportsUserRequestParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsUser(reportsUserRequestParams: ReportsUserRequestParams, options?: any): AxiosPromise<PageReportsUserDTO> {
            return localVarFp.getReportsUser(reportsUserRequestParams, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getReportsFile operation in ReportsControllerApi.
 * @export
 * @interface ReportsControllerApiGetReportsFileRequest
 */
export interface ReportsControllerApiGetReportsFileRequest {
    /**
     * 
     * @type {ReportsFilesRequestParams}
     * @memberof ReportsControllerApiGetReportsFile
     */
    readonly reportsFilesRequestParams: ReportsFilesRequestParams
}

/**
 * Request parameters for getReportsUser operation in ReportsControllerApi.
 * @export
 * @interface ReportsControllerApiGetReportsUserRequest
 */
export interface ReportsControllerApiGetReportsUserRequest {
    /**
     * 
     * @type {ReportsUserRequestParams}
     * @memberof ReportsControllerApiGetReportsUser
     */
    readonly reportsUserRequestParams: ReportsUserRequestParams
}

/**
 * ReportsControllerApi - object-oriented interface
 * @export
 * @class ReportsControllerApi
 * @extends {BaseAPI}
 */
export class ReportsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Method performs a page-by-page search by files
     * @param {ReportsControllerApiGetReportsFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsControllerApi
     */
    public getReportsFile(requestParameters: ReportsControllerApiGetReportsFileRequest, options?: AxiosRequestConfig) {
        return ReportsControllerApiFp(this.configuration).getReportsFile(requestParameters.reportsFilesRequestParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method performs a page-by-page search by users
     * @param {ReportsControllerApiGetReportsUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsControllerApi
     */
    public getReportsUser(requestParameters: ReportsControllerApiGetReportsUserRequest, options?: AxiosRequestConfig) {
        return ReportsControllerApiFp(this.configuration).getReportsUser(requestParameters.reportsUserRequestParams, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RequestMessageControllerApi - axios parameter creator
 * @export
 */
export const RequestMessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method will return default success message, if the dto is valid
         * @param {RequestByEmailDTO} requestByEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRequestMessageByEmail: async (requestByEmailDTO: RequestByEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestByEmailDTO' is not null or undefined
            assertParamExists('createRequestMessageByEmail', 'requestByEmailDTO', requestByEmailDTO)
            const localVarPath = `/requestMessage/by_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestByEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method will return link for uploading file, if the dto is valid
         * @param {RequestByLinkDTO} requestByLinkDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRequestMessageByLink: async (requestByLinkDTO: RequestByLinkDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestByLinkDTO' is not null or undefined
            assertParamExists('createRequestMessageByLink', 'requestByLinkDTO', requestByLinkDTO)
            const localVarPath = `/requestMessage/by_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestByLinkDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method returns all files for request message by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFilesRequestMessageById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAllFilesRequestMessageById', 'id', id)
            const localVarPath = `/requestMessage/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method returns default properties for request by email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultRequestByEmailProps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/requestMessage/by_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method returns default properties for request by link.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultRequestByLinkProps: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/requestMessage/by_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method returns request message by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestMessageById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRequestMessageById', 'id', id)
            const localVarPath = `/requestMessage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestMessageControllerApi - functional programming interface
 * @export
 */
export const RequestMessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestMessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method will return default success message, if the dto is valid
         * @param {RequestByEmailDTO} requestByEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRequestMessageByEmail(requestByEmailDTO: RequestByEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRequestMessageByEmail(requestByEmailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method will return link for uploading file, if the dto is valid
         * @param {RequestByLinkDTO} requestByLinkDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRequestMessageByLink(requestByLinkDTO: RequestByLinkDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRequestMessageByLink(requestByLinkDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method returns all files for request message by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFilesRequestMessageById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileResponseMainData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFilesRequestMessageById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method returns default properties for request by email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultRequestByEmailProps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestByEmailDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultRequestByEmailProps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method returns default properties for request by link.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultRequestByLinkProps(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestByLinkDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultRequestByLinkProps(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method returns request message by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequestMessageById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequestMessageById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestMessageControllerApi - factory interface
 * @export
 */
export const RequestMessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestMessageControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Method will return default success message, if the dto is valid
         * @param {RequestByEmailDTO} requestByEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRequestMessageByEmail(requestByEmailDTO: RequestByEmailDTO, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.createRequestMessageByEmail(requestByEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method will return link for uploading file, if the dto is valid
         * @param {RequestByLinkDTO} requestByLinkDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRequestMessageByLink(requestByLinkDTO: RequestByLinkDTO, options?: any): AxiosPromise<UploadLink> {
            return localVarFp.createRequestMessageByLink(requestByLinkDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method returns all files for request message by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFilesRequestMessageById(id: string, options?: any): AxiosPromise<Array<FileResponseMainData>> {
            return localVarFp.getAllFilesRequestMessageById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method returns default properties for request by email.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultRequestByEmailProps(options?: any): AxiosPromise<RequestByEmailDTO> {
            return localVarFp.getDefaultRequestByEmailProps(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method returns default properties for request by link.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultRequestByLinkProps(options?: any): AxiosPromise<RequestByLinkDTO> {
            return localVarFp.getDefaultRequestByLinkProps(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method returns request message by id.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestMessageById(id: string, options?: any): AxiosPromise<RequestMessageDTO> {
            return localVarFp.getRequestMessageById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRequestMessageByEmail operation in RequestMessageControllerApi.
 * @export
 * @interface RequestMessageControllerApiCreateRequestMessageByEmailRequest
 */
export interface RequestMessageControllerApiCreateRequestMessageByEmailRequest {
    /**
     * 
     * @type {RequestByEmailDTO}
     * @memberof RequestMessageControllerApiCreateRequestMessageByEmail
     */
    readonly requestByEmailDTO: RequestByEmailDTO
}

/**
 * Request parameters for createRequestMessageByLink operation in RequestMessageControllerApi.
 * @export
 * @interface RequestMessageControllerApiCreateRequestMessageByLinkRequest
 */
export interface RequestMessageControllerApiCreateRequestMessageByLinkRequest {
    /**
     * 
     * @type {RequestByLinkDTO}
     * @memberof RequestMessageControllerApiCreateRequestMessageByLink
     */
    readonly requestByLinkDTO: RequestByLinkDTO
}

/**
 * Request parameters for getAllFilesRequestMessageById operation in RequestMessageControllerApi.
 * @export
 * @interface RequestMessageControllerApiGetAllFilesRequestMessageByIdRequest
 */
export interface RequestMessageControllerApiGetAllFilesRequestMessageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestMessageControllerApiGetAllFilesRequestMessageById
     */
    readonly id: string
}

/**
 * Request parameters for getRequestMessageById operation in RequestMessageControllerApi.
 * @export
 * @interface RequestMessageControllerApiGetRequestMessageByIdRequest
 */
export interface RequestMessageControllerApiGetRequestMessageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestMessageControllerApiGetRequestMessageById
     */
    readonly id: string
}

/**
 * RequestMessageControllerApi - object-oriented interface
 * @export
 * @class RequestMessageControllerApi
 * @extends {BaseAPI}
 */
export class RequestMessageControllerApi extends BaseAPI {
    /**
     * 
     * @summary Method will return default success message, if the dto is valid
     * @param {RequestMessageControllerApiCreateRequestMessageByEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestMessageControllerApi
     */
    public createRequestMessageByEmail(requestParameters: RequestMessageControllerApiCreateRequestMessageByEmailRequest, options?: AxiosRequestConfig) {
        return RequestMessageControllerApiFp(this.configuration).createRequestMessageByEmail(requestParameters.requestByEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method will return link for uploading file, if the dto is valid
     * @param {RequestMessageControllerApiCreateRequestMessageByLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestMessageControllerApi
     */
    public createRequestMessageByLink(requestParameters: RequestMessageControllerApiCreateRequestMessageByLinkRequest, options?: AxiosRequestConfig) {
        return RequestMessageControllerApiFp(this.configuration).createRequestMessageByLink(requestParameters.requestByLinkDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method returns all files for request message by id.
     * @param {RequestMessageControllerApiGetAllFilesRequestMessageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestMessageControllerApi
     */
    public getAllFilesRequestMessageById(requestParameters: RequestMessageControllerApiGetAllFilesRequestMessageByIdRequest, options?: AxiosRequestConfig) {
        return RequestMessageControllerApiFp(this.configuration).getAllFilesRequestMessageById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method returns default properties for request by email.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestMessageControllerApi
     */
    public getDefaultRequestByEmailProps(options?: AxiosRequestConfig) {
        return RequestMessageControllerApiFp(this.configuration).getDefaultRequestByEmailProps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method returns default properties for request by link.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestMessageControllerApi
     */
    public getDefaultRequestByLinkProps(options?: AxiosRequestConfig) {
        return RequestMessageControllerApiFp(this.configuration).getDefaultRequestByLinkProps(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method returns request message by id.
     * @param {RequestMessageControllerApiGetRequestMessageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestMessageControllerApi
     */
    public getRequestMessageById(requestParameters: RequestMessageControllerApiGetRequestMessageByIdRequest, options?: AxiosRequestConfig) {
        return RequestMessageControllerApiFp(this.configuration).getRequestMessageById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResponseMessageControllerApi - axios parameter creator
 * @export
 */
export const ResponseMessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Method creates ResponseMessage for existing request. Otherwise if the RequestMessage is not exist or expired exception will be thrown.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResponseMessageForGivenRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createResponseMessageForGivenRequest', 'id', id)
            const localVarPath = `/responseMessage/{id}/create`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete file from response message.
         * @param {string} responseMessageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileFromResponseMessage: async (responseMessageId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('deleteFileFromResponseMessage', 'responseMessageId', responseMessageId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFileFromResponseMessage', 'fileId', fileId)
            const localVarPath = `/responseMessage/{responseMessageId}/file/{fileId}`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method zips all the files in message and send them to client. 
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllFiles: async (responseMessageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('downloadAllFiles', 'responseMessageId', responseMessageId)
            const localVarPath = `/responseMessage/{responseMessageId}/download`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} responseMessageId 
         * @param {string} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (responseMessageId: string, linkId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('downloadFile', 'responseMessageId', responseMessageId)
            // verify required parameter 'linkId' is not null or undefined
            assertParamExists('downloadFile', 'linkId', linkId)
            const localVarPath = `/responseMessage/{responseMessageId}/download/{linkId}`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)))
                .replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method returns response message using id.
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesStatusesForMessage: async (responseMessageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('getFilesStatusesForMessage', 'responseMessageId', responseMessageId)
            const localVarPath = `/responseMessage/public/{responseMessageId}`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method sends link to file.
         * @param {string} responseMessageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToFile: async (responseMessageId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('getLinkToFile', 'responseMessageId', responseMessageId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getLinkToFile', 'fileId', fileId)
            const localVarPath = `/responseMessage/{responseMessageId}/{fileId}/link`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns response message by id. Current user should be the owner of the message to see it.
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponseMessageById: async (responseMessageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('getResponseMessageById', 'responseMessageId', responseMessageId)
            const localVarPath = `/responseMessage/{responseMessageId}/get`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint reserves file in response message and returns file with id.
         * @param {string} responseMessageId 
         * @param {FileUploadPrepareRequest} fileUploadPrepareRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveFileInMessage: async (responseMessageId: string, fileUploadPrepareRequest: FileUploadPrepareRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('reserveFileInMessage', 'responseMessageId', responseMessageId)
            // verify required parameter 'fileUploadPrepareRequest' is not null or undefined
            assertParamExists('reserveFileInMessage', 'fileUploadPrepareRequest', fileUploadPrepareRequest)
            const localVarPath = `/responseMessage/{responseMessageId}/reserveFile`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUploadPrepareRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Method created for updating emails text and(or) subject.
         * @param {string} responseMessageId 
         * @param {ResponseMessageEmailDTO} responseMessageEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResponseMessageAndSend: async (responseMessageId: string, responseMessageEmailDTO: ResponseMessageEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('updateResponseMessageAndSend', 'responseMessageId', responseMessageId)
            // verify required parameter 'responseMessageEmailDTO' is not null or undefined
            assertParamExists('updateResponseMessageAndSend', 'responseMessageEmailDTO', responseMessageEmailDTO)
            const localVarPath = `/responseMessage/{responseMessageId}/send`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(responseMessageEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This endpoint gives client ability to send file and put it in response message. Before sending file you have to reserve it.
         * @param {string} responseMessageId 
         * @param {string} reservedFileId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileToResponseMessage: async (responseMessageId: string, reservedFileId: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'responseMessageId' is not null or undefined
            assertParamExists('uploadFileToResponseMessage', 'responseMessageId', responseMessageId)
            // verify required parameter 'reservedFileId' is not null or undefined
            assertParamExists('uploadFileToResponseMessage', 'reservedFileId', reservedFileId)
            const localVarPath = `/responseMessage/{responseMessageId}/file/{reservedFileId}`
                .replace(`{${"responseMessageId"}}`, encodeURIComponent(String(responseMessageId)))
                .replace(`{${"reservedFileId"}}`, encodeURIComponent(String(reservedFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResponseMessageControllerApi - functional programming interface
 * @export
 */
export const ResponseMessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResponseMessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Method creates ResponseMessage for existing request. Otherwise if the RequestMessage is not exist or expired exception will be thrown.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResponseMessageForGivenRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResponseMessageForGivenRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete file from response message.
         * @param {string} responseMessageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileFromResponseMessage(responseMessageId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileFromResponseMessage(responseMessageId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method zips all the files in message and send them to client. 
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAllFiles(responseMessageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAllFiles(responseMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} responseMessageId 
         * @param {string} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(responseMessageId: string, linkId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(responseMessageId, linkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method returns response message using id.
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesStatusesForMessage(responseMessageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesStatusesForMessage(responseMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method sends link to file.
         * @param {string} responseMessageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkToFile(responseMessageId: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkToFile(responseMessageId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns response message by id. Current user should be the owner of the message to see it.
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResponseMessageById(responseMessageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResponseMessageById(responseMessageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint reserves file in response message and returns file with id.
         * @param {string} responseMessageId 
         * @param {FileUploadPrepareRequest} fileUploadPrepareRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reserveFileInMessage(responseMessageId: string, fileUploadPrepareRequest: FileUploadPrepareRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reserveFileInMessage(responseMessageId, fileUploadPrepareRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Method created for updating emails text and(or) subject.
         * @param {string} responseMessageId 
         * @param {ResponseMessageEmailDTO} responseMessageEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateResponseMessageAndSend(responseMessageId: string, responseMessageEmailDTO: ResponseMessageEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateResponseMessageAndSend(responseMessageId, responseMessageEmailDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This endpoint gives client ability to send file and put it in response message. Before sending file you have to reserve it.
         * @param {string} responseMessageId 
         * @param {string} reservedFileId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileToResponseMessage(responseMessageId: string, reservedFileId: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileToResponseMessage(responseMessageId, reservedFileId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResponseMessageControllerApi - factory interface
 * @export
 */
export const ResponseMessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResponseMessageControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Method creates ResponseMessage for existing request. Otherwise if the RequestMessage is not exist or expired exception will be thrown.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResponseMessageForGivenRequest(id: string, options?: any): AxiosPromise<ResponseMessageDTO> {
            return localVarFp.createResponseMessageForGivenRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint gives client ability to delete file from response message.
         * @param {string} responseMessageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileFromResponseMessage(responseMessageId: string, fileId: string, options?: any): AxiosPromise<ResponseMessageDTO> {
            return localVarFp.deleteFileFromResponseMessage(responseMessageId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method zips all the files in message and send them to client. 
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllFiles(responseMessageId: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadAllFiles(responseMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method sends all the bytes of given file id in message.
         * @param {string} responseMessageId 
         * @param {string} linkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(responseMessageId: string, linkId: string, options?: any): AxiosPromise<object> {
            return localVarFp.downloadFile(responseMessageId, linkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method returns response message using id.
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesStatusesForMessage(responseMessageId: string, options?: any): AxiosPromise<ResponseMessageDTO> {
            return localVarFp.getFilesStatusesForMessage(responseMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method sends link to file.
         * @param {string} responseMessageId 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToFile(responseMessageId: string, fileId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getLinkToFile(responseMessageId, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns response message by id. Current user should be the owner of the message to see it.
         * @param {string} responseMessageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResponseMessageById(responseMessageId: string, options?: any): AxiosPromise<ResponseMessageDTO> {
            return localVarFp.getResponseMessageById(responseMessageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint reserves file in response message and returns file with id.
         * @param {string} responseMessageId 
         * @param {FileUploadPrepareRequest} fileUploadPrepareRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reserveFileInMessage(responseMessageId: string, fileUploadPrepareRequest: FileUploadPrepareRequest, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.reserveFileInMessage(responseMessageId, fileUploadPrepareRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Method created for updating emails text and(or) subject.
         * @param {string} responseMessageId 
         * @param {ResponseMessageEmailDTO} responseMessageEmailDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResponseMessageAndSend(responseMessageId: string, responseMessageEmailDTO: ResponseMessageEmailDTO, options?: any): AxiosPromise<ResponseMessageDTO> {
            return localVarFp.updateResponseMessageAndSend(responseMessageId, responseMessageEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This endpoint gives client ability to send file and put it in response message. Before sending file you have to reserve it.
         * @param {string} responseMessageId 
         * @param {string} reservedFileId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileToResponseMessage(responseMessageId: string, reservedFileId: string, file?: File, options?: any): AxiosPromise<FileDTO> {
            return localVarFp.uploadFileToResponseMessage(responseMessageId, reservedFileId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createResponseMessageForGivenRequest operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiCreateResponseMessageForGivenRequestRequest
 */
export interface ResponseMessageControllerApiCreateResponseMessageForGivenRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiCreateResponseMessageForGivenRequest
     */
    readonly id: string
}

/**
 * Request parameters for deleteFileFromResponseMessage operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiDeleteFileFromResponseMessageRequest
 */
export interface ResponseMessageControllerApiDeleteFileFromResponseMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiDeleteFileFromResponseMessage
     */
    readonly responseMessageId: string

    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiDeleteFileFromResponseMessage
     */
    readonly fileId: string
}

/**
 * Request parameters for downloadAllFiles operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiDownloadAllFilesRequest
 */
export interface ResponseMessageControllerApiDownloadAllFilesRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiDownloadAllFiles
     */
    readonly responseMessageId: string
}

/**
 * Request parameters for downloadFile operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiDownloadFileRequest
 */
export interface ResponseMessageControllerApiDownloadFileRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiDownloadFile
     */
    readonly responseMessageId: string

    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiDownloadFile
     */
    readonly linkId: string
}

/**
 * Request parameters for getFilesStatusesForMessage operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiGetFilesStatusesForMessageRequest
 */
export interface ResponseMessageControllerApiGetFilesStatusesForMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiGetFilesStatusesForMessage
     */
    readonly responseMessageId: string
}

/**
 * Request parameters for getLinkToFile operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiGetLinkToFileRequest
 */
export interface ResponseMessageControllerApiGetLinkToFileRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiGetLinkToFile
     */
    readonly responseMessageId: string

    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiGetLinkToFile
     */
    readonly fileId: string
}

/**
 * Request parameters for getResponseMessageById operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiGetResponseMessageByIdRequest
 */
export interface ResponseMessageControllerApiGetResponseMessageByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiGetResponseMessageById
     */
    readonly responseMessageId: string
}

/**
 * Request parameters for reserveFileInMessage operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiReserveFileInMessageRequest
 */
export interface ResponseMessageControllerApiReserveFileInMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiReserveFileInMessage
     */
    readonly responseMessageId: string

    /**
     * 
     * @type {FileUploadPrepareRequest}
     * @memberof ResponseMessageControllerApiReserveFileInMessage
     */
    readonly fileUploadPrepareRequest: FileUploadPrepareRequest
}

/**
 * Request parameters for updateResponseMessageAndSend operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiUpdateResponseMessageAndSendRequest
 */
export interface ResponseMessageControllerApiUpdateResponseMessageAndSendRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiUpdateResponseMessageAndSend
     */
    readonly responseMessageId: string

    /**
     * 
     * @type {ResponseMessageEmailDTO}
     * @memberof ResponseMessageControllerApiUpdateResponseMessageAndSend
     */
    readonly responseMessageEmailDTO: ResponseMessageEmailDTO
}

/**
 * Request parameters for uploadFileToResponseMessage operation in ResponseMessageControllerApi.
 * @export
 * @interface ResponseMessageControllerApiUploadFileToResponseMessageRequest
 */
export interface ResponseMessageControllerApiUploadFileToResponseMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiUploadFileToResponseMessage
     */
    readonly responseMessageId: string

    /**
     * 
     * @type {string}
     * @memberof ResponseMessageControllerApiUploadFileToResponseMessage
     */
    readonly reservedFileId: string

    /**
     * 
     * @type {File}
     * @memberof ResponseMessageControllerApiUploadFileToResponseMessage
     */
    readonly file?: File
}

/**
 * ResponseMessageControllerApi - object-oriented interface
 * @export
 * @class ResponseMessageControllerApi
 * @extends {BaseAPI}
 */
export class ResponseMessageControllerApi extends BaseAPI {
    /**
     * 
     * @summary Method creates ResponseMessage for existing request. Otherwise if the RequestMessage is not exist or expired exception will be thrown.
     * @param {ResponseMessageControllerApiCreateResponseMessageForGivenRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public createResponseMessageForGivenRequest(requestParameters: ResponseMessageControllerApiCreateResponseMessageForGivenRequestRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).createResponseMessageForGivenRequest(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint gives client ability to delete file from response message.
     * @param {ResponseMessageControllerApiDeleteFileFromResponseMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public deleteFileFromResponseMessage(requestParameters: ResponseMessageControllerApiDeleteFileFromResponseMessageRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).deleteFileFromResponseMessage(requestParameters.responseMessageId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method zips all the files in message and send them to client. 
     * @param {ResponseMessageControllerApiDownloadAllFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public downloadAllFiles(requestParameters: ResponseMessageControllerApiDownloadAllFilesRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).downloadAllFiles(requestParameters.responseMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method sends all the bytes of given file id in message.
     * @param {ResponseMessageControllerApiDownloadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public downloadFile(requestParameters: ResponseMessageControllerApiDownloadFileRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).downloadFile(requestParameters.responseMessageId, requestParameters.linkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method returns response message using id.
     * @param {ResponseMessageControllerApiGetFilesStatusesForMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public getFilesStatusesForMessage(requestParameters: ResponseMessageControllerApiGetFilesStatusesForMessageRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).getFilesStatusesForMessage(requestParameters.responseMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method sends link to file.
     * @param {ResponseMessageControllerApiGetLinkToFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public getLinkToFile(requestParameters: ResponseMessageControllerApiGetLinkToFileRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).getLinkToFile(requestParameters.responseMessageId, requestParameters.fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns response message by id. Current user should be the owner of the message to see it.
     * @param {ResponseMessageControllerApiGetResponseMessageByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public getResponseMessageById(requestParameters: ResponseMessageControllerApiGetResponseMessageByIdRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).getResponseMessageById(requestParameters.responseMessageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint reserves file in response message and returns file with id.
     * @param {ResponseMessageControllerApiReserveFileInMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public reserveFileInMessage(requestParameters: ResponseMessageControllerApiReserveFileInMessageRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).reserveFileInMessage(requestParameters.responseMessageId, requestParameters.fileUploadPrepareRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Method created for updating emails text and(or) subject.
     * @param {ResponseMessageControllerApiUpdateResponseMessageAndSendRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public updateResponseMessageAndSend(requestParameters: ResponseMessageControllerApiUpdateResponseMessageAndSendRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).updateResponseMessageAndSend(requestParameters.responseMessageId, requestParameters.responseMessageEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This endpoint gives client ability to send file and put it in response message. Before sending file you have to reserve it.
     * @param {ResponseMessageControllerApiUploadFileToResponseMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResponseMessageControllerApi
     */
    public uploadFileToResponseMessage(requestParameters: ResponseMessageControllerApiUploadFileToResponseMessageRequest, options?: AxiosRequestConfig) {
        return ResponseMessageControllerApiFp(this.configuration).uploadFileToResponseMessage(requestParameters.responseMessageId, requestParameters.reservedFileId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsControllerApi - axios parameter creator
 * @export
 */
export const SettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes custom logo, default will be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomLogo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes custom logo for mobile version, default will be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomLogoMobile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/logoMobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns messages on the expiration of the license or termination of the license.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEndingOrExpiredLicenseMailHeaders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/mailHeaders/endingOrExpiredLicense`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns messages for the file period extension emails.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtendPeriodMailHeaders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/mailHeaders/extendPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - General: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - LDAP: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLdapSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/ldap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns license info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensesInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - Log Files: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogFileSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/logFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - Mail: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns default mail headers dto. Values from this dto will be set as default email subject.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailHeaders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/mailHeaders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns messages about deleting links to files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationAboutDeletingMailHeaders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/mailHeaders/notificationAboutDeleting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - Storage: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - User expire: get user expire settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserExpireSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/userDeactivation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates messages on the expiration of the license or termination of the license.
         * @param {EndingOrExpiredLicenseMailHeadersDTO} endingOrExpiredLicenseMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEndingOrExpiredLicenseMailHeaders: async (endingOrExpiredLicenseMailHeadersDTO: EndingOrExpiredLicenseMailHeadersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'endingOrExpiredLicenseMailHeadersDTO' is not null or undefined
            assertParamExists('updateEndingOrExpiredLicenseMailHeaders', 'endingOrExpiredLicenseMailHeadersDTO', endingOrExpiredLicenseMailHeadersDTO)
            const localVarPath = `/admin/settings/mailHeaders/endingOrExpiredLicense`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endingOrExpiredLicenseMailHeadersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates messages for the file period extension emails.
         * @param {ExtendMessageMailHeadersDTO} extendMessageMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtendPeriodMailHeaders: async (extendMessageMailHeadersDTO: ExtendMessageMailHeadersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extendMessageMailHeadersDTO' is not null or undefined
            assertParamExists('updateExtendPeriodMailHeaders', 'extendMessageMailHeadersDTO', extendMessageMailHeadersDTO)
            const localVarPath = `/admin/settings/mailHeaders/extendPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extendMessageMailHeadersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - General: save settings
         * @param {GeneralSettingsDTO} generalSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralSettings: async (generalSettingsDTO: GeneralSettingsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generalSettingsDTO' is not null or undefined
            assertParamExists('updateGeneralSettings', 'generalSettingsDTO', generalSettingsDTO)
            const localVarPath = `/admin/settings/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - LDAP: save settings
         * @param {LdapSettingsDTO} ldapSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLdapSettings: async (ldapSettingsDTO: LdapSettingsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ldapSettingsDTO' is not null or undefined
            assertParamExists('updateLdapSettings', 'ldapSettingsDTO', ldapSettingsDTO)
            const localVarPath = `/admin/settings/ldap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ldapSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LicenseUpdateDTO} licenseUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense: async (licenseUpdateDTO: LicenseUpdateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseUpdateDTO' is not null or undefined
            assertParamExists('updateLicense', 'licenseUpdateDTO', licenseUpdateDTO)
            const localVarPath = `/admin/settings/license`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseUpdateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - Log Files: save settings
         * @param {LogFileSettings} logFileSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogFileSettings: async (logFileSettings: LogFileSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logFileSettings' is not null or undefined
            assertParamExists('updateLogFileSettings', 'logFileSettings', logFileSettings)
            const localVarPath = `/admin/settings/logFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logFileSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - Mail: save settings
         * @param {MailSettingsDTO} mailSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMailConfiguration: async (mailSettingsDTO: MailSettingsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailSettingsDTO' is not null or undefined
            assertParamExists('updateMailConfiguration', 'mailSettingsDTO', mailSettingsDTO)
            const localVarPath = `/admin/settings/mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates default mail headers with values from given dto.
         * @param {MailHeadersDTO} mailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMailHeaders: async (mailHeadersDTO: MailHeadersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailHeadersDTO' is not null or undefined
            assertParamExists('updateMailHeaders', 'mailHeadersDTO', mailHeadersDTO)
            const localVarPath = `/admin/settings/mailHeaders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailHeadersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates messages about deleting links to files.
         * @param {NotificationAboutDeletingMailHeadersDTO} notificationAboutDeletingMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationAboutDeletingMailHeaders: async (notificationAboutDeletingMailHeadersDTO: NotificationAboutDeletingMailHeadersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationAboutDeletingMailHeadersDTO' is not null or undefined
            assertParamExists('updateNotificationAboutDeletingMailHeaders', 'notificationAboutDeletingMailHeadersDTO', notificationAboutDeletingMailHeadersDTO)
            const localVarPath = `/admin/settings/mailHeaders/notificationAboutDeleting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationAboutDeletingMailHeadersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - Storage: save settings
         * @param {StorageSettingsDTO} storageSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStorageSettings: async (storageSettingsDTO: StorageSettingsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storageSettingsDTO' is not null or undefined
            assertParamExists('updateStorageSettings', 'storageSettingsDTO', storageSettingsDTO)
            const localVarPath = `/admin/settings/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storageSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Settings - User expire: save user expire settings
         * @param {UserExpireSettingsDTO} userExpireSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserExpireSettings: async (userExpireSettingsDTO: UserExpireSettingsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userExpireSettingsDTO' is not null or undefined
            assertParamExists('updateUserExpireSettings', 'userExpireSettingsDTO', userExpireSettingsDTO)
            const localVarPath = `/admin/settings/userDeactivation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userExpireSettingsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads custom logo to backend
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/logo/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads custom logo for mobile version to backend
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogoMobile: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/settings/logoMobile/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsControllerApi - functional programming interface
 * @export
 */
export const SettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes custom logo, default will be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomLogo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomLogo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes custom logo for mobile version, default will be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomLogoMobile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomLogoMobile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns messages on the expiration of the license or termination of the license.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEndingOrExpiredLicenseMailHeaders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EndingOrExpiredLicenseMailHeadersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEndingOrExpiredLicenseMailHeaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns messages for the file period extension emails.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExtendPeriodMailHeaders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendMessageMailHeadersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExtendPeriodMailHeaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - General: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - LDAP: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLdapSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LdapSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLdapSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns license info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLicensesInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLicensesInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - Log Files: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogFileSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogFileSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogFileSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - Mail: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns default mail headers dto. Values from this dto will be set as default email subject.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailHeaders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailHeadersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailHeaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns messages about deleting links to files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationAboutDeletingMailHeaders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationAboutDeletingMailHeadersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationAboutDeletingMailHeaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - Storage: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStorageSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStorageSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - User expire: get user expire settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserExpireSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserExpireSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserExpireSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates messages on the expiration of the license or termination of the license.
         * @param {EndingOrExpiredLicenseMailHeadersDTO} endingOrExpiredLicenseMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEndingOrExpiredLicenseMailHeaders(endingOrExpiredLicenseMailHeadersDTO: EndingOrExpiredLicenseMailHeadersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EndingOrExpiredLicenseMailHeadersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEndingOrExpiredLicenseMailHeaders(endingOrExpiredLicenseMailHeadersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates messages for the file period extension emails.
         * @param {ExtendMessageMailHeadersDTO} extendMessageMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExtendPeriodMailHeaders(extendMessageMailHeadersDTO: ExtendMessageMailHeadersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendMessageMailHeadersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExtendPeriodMailHeaders(extendMessageMailHeadersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - General: save settings
         * @param {GeneralSettingsDTO} generalSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralSettings(generalSettingsDTO: GeneralSettingsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralSettings(generalSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - LDAP: save settings
         * @param {LdapSettingsDTO} ldapSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLdapSettings(ldapSettingsDTO: LdapSettingsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LdapSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLdapSettings(ldapSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LicenseUpdateDTO} licenseUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicense(licenseUpdateDTO: LicenseUpdateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicense(licenseUpdateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - Log Files: save settings
         * @param {LogFileSettings} logFileSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLogFileSettings(logFileSettings: LogFileSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogFileSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogFileSettings(logFileSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - Mail: save settings
         * @param {MailSettingsDTO} mailSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMailConfiguration(mailSettingsDTO: MailSettingsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMailConfiguration(mailSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates default mail headers with values from given dto.
         * @param {MailHeadersDTO} mailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMailHeaders(mailHeadersDTO: MailHeadersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailHeadersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMailHeaders(mailHeadersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates messages about deleting links to files.
         * @param {NotificationAboutDeletingMailHeadersDTO} notificationAboutDeletingMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationAboutDeletingMailHeaders(notificationAboutDeletingMailHeadersDTO: NotificationAboutDeletingMailHeadersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationAboutDeletingMailHeadersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationAboutDeletingMailHeaders(notificationAboutDeletingMailHeadersDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - Storage: save settings
         * @param {StorageSettingsDTO} storageSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStorageSettings(storageSettingsDTO: StorageSettingsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStorageSettings(storageSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Settings - User expire: save user expire settings
         * @param {UserExpireSettingsDTO} userExpireSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserExpireSettings(userExpireSettingsDTO: UserExpireSettingsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserExpireSettingsDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserExpireSettings(userExpireSettingsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads custom logo to backend
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLogo(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLogo(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads custom logo for mobile version to backend
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLogoMobile(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogoUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLogoMobile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsControllerApi - factory interface
 * @export
 */
export const SettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes custom logo, default will be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomLogo(options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes custom logo for mobile version, default will be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomLogoMobile(options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomLogoMobile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns messages on the expiration of the license or termination of the license.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEndingOrExpiredLicenseMailHeaders(options?: any): AxiosPromise<EndingOrExpiredLicenseMailHeadersDTO> {
            return localVarFp.getEndingOrExpiredLicenseMailHeaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns messages for the file period extension emails.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtendPeriodMailHeaders(options?: any): AxiosPromise<ExtendMessageMailHeadersDTO> {
            return localVarFp.getExtendPeriodMailHeaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - General: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralSettings(options?: any): AxiosPromise<GeneralSettingsDTO> {
            return localVarFp.getGeneralSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - LDAP: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLdapSettings(options?: any): AxiosPromise<LdapSettingsDTO> {
            return localVarFp.getLdapSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns license info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLicensesInfo(options?: any): AxiosPromise<LicenseInfoDTO> {
            return localVarFp.getLicensesInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - Log Files: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogFileSettings(options?: any): AxiosPromise<LogFileSettings> {
            return localVarFp.getLogFileSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - Mail: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailConfiguration(options?: any): AxiosPromise<MailSettingsDTO> {
            return localVarFp.getMailConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns default mail headers dto. Values from this dto will be set as default email subject.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailHeaders(options?: any): AxiosPromise<MailHeadersDTO> {
            return localVarFp.getMailHeaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns messages about deleting links to files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationAboutDeletingMailHeaders(options?: any): AxiosPromise<NotificationAboutDeletingMailHeadersDTO> {
            return localVarFp.getNotificationAboutDeletingMailHeaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - Storage: get settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageSettings(options?: any): AxiosPromise<StorageSettingsDTO> {
            return localVarFp.getStorageSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - User expire: get user expire settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserExpireSettings(options?: any): AxiosPromise<UserExpireSettingsDTO> {
            return localVarFp.getUserExpireSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates messages on the expiration of the license or termination of the license.
         * @param {EndingOrExpiredLicenseMailHeadersDTO} endingOrExpiredLicenseMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEndingOrExpiredLicenseMailHeaders(endingOrExpiredLicenseMailHeadersDTO: EndingOrExpiredLicenseMailHeadersDTO, options?: any): AxiosPromise<EndingOrExpiredLicenseMailHeadersDTO> {
            return localVarFp.updateEndingOrExpiredLicenseMailHeaders(endingOrExpiredLicenseMailHeadersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates messages for the file period extension emails.
         * @param {ExtendMessageMailHeadersDTO} extendMessageMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExtendPeriodMailHeaders(extendMessageMailHeadersDTO: ExtendMessageMailHeadersDTO, options?: any): AxiosPromise<ExtendMessageMailHeadersDTO> {
            return localVarFp.updateExtendPeriodMailHeaders(extendMessageMailHeadersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - General: save settings
         * @param {GeneralSettingsDTO} generalSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralSettings(generalSettingsDTO: GeneralSettingsDTO, options?: any): AxiosPromise<GeneralSettingsDTO> {
            return localVarFp.updateGeneralSettings(generalSettingsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - LDAP: save settings
         * @param {LdapSettingsDTO} ldapSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLdapSettings(ldapSettingsDTO: LdapSettingsDTO, options?: any): AxiosPromise<LdapSettingsDTO> {
            return localVarFp.updateLdapSettings(ldapSettingsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LicenseUpdateDTO} licenseUpdateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense(licenseUpdateDTO: LicenseUpdateDTO, options?: any): AxiosPromise<LicenseInfoDTO> {
            return localVarFp.updateLicense(licenseUpdateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - Log Files: save settings
         * @param {LogFileSettings} logFileSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogFileSettings(logFileSettings: LogFileSettings, options?: any): AxiosPromise<LogFileSettings> {
            return localVarFp.updateLogFileSettings(logFileSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - Mail: save settings
         * @param {MailSettingsDTO} mailSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMailConfiguration(mailSettingsDTO: MailSettingsDTO, options?: any): AxiosPromise<MailSettingsDTO> {
            return localVarFp.updateMailConfiguration(mailSettingsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates default mail headers with values from given dto.
         * @param {MailHeadersDTO} mailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMailHeaders(mailHeadersDTO: MailHeadersDTO, options?: any): AxiosPromise<MailHeadersDTO> {
            return localVarFp.updateMailHeaders(mailHeadersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates messages about deleting links to files.
         * @param {NotificationAboutDeletingMailHeadersDTO} notificationAboutDeletingMailHeadersDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationAboutDeletingMailHeaders(notificationAboutDeletingMailHeadersDTO: NotificationAboutDeletingMailHeadersDTO, options?: any): AxiosPromise<NotificationAboutDeletingMailHeadersDTO> {
            return localVarFp.updateNotificationAboutDeletingMailHeaders(notificationAboutDeletingMailHeadersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - Storage: save settings
         * @param {StorageSettingsDTO} storageSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStorageSettings(storageSettingsDTO: StorageSettingsDTO, options?: any): AxiosPromise<StorageSettingsDTO> {
            return localVarFp.updateStorageSettings(storageSettingsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Settings - User expire: save user expire settings
         * @param {UserExpireSettingsDTO} userExpireSettingsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserExpireSettings(userExpireSettingsDTO: UserExpireSettingsDTO, options?: any): AxiosPromise<UserExpireSettingsDTO> {
            return localVarFp.updateUserExpireSettings(userExpireSettingsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads custom logo to backend
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogo(file?: File, options?: any): AxiosPromise<LogoUploadResponse> {
            return localVarFp.uploadLogo(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads custom logo for mobile version to backend
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLogoMobile(file?: File, options?: any): AxiosPromise<LogoUploadResponse> {
            return localVarFp.uploadLogoMobile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updateEndingOrExpiredLicenseMailHeaders operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateEndingOrExpiredLicenseMailHeadersRequest
 */
export interface SettingsControllerApiUpdateEndingOrExpiredLicenseMailHeadersRequest {
    /**
     * 
     * @type {EndingOrExpiredLicenseMailHeadersDTO}
     * @memberof SettingsControllerApiUpdateEndingOrExpiredLicenseMailHeaders
     */
    readonly endingOrExpiredLicenseMailHeadersDTO: EndingOrExpiredLicenseMailHeadersDTO
}

/**
 * Request parameters for updateExtendPeriodMailHeaders operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateExtendPeriodMailHeadersRequest
 */
export interface SettingsControllerApiUpdateExtendPeriodMailHeadersRequest {
    /**
     * 
     * @type {ExtendMessageMailHeadersDTO}
     * @memberof SettingsControllerApiUpdateExtendPeriodMailHeaders
     */
    readonly extendMessageMailHeadersDTO: ExtendMessageMailHeadersDTO
}

/**
 * Request parameters for updateGeneralSettings operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateGeneralSettingsRequest
 */
export interface SettingsControllerApiUpdateGeneralSettingsRequest {
    /**
     * 
     * @type {GeneralSettingsDTO}
     * @memberof SettingsControllerApiUpdateGeneralSettings
     */
    readonly generalSettingsDTO: GeneralSettingsDTO
}

/**
 * Request parameters for updateLdapSettings operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateLdapSettingsRequest
 */
export interface SettingsControllerApiUpdateLdapSettingsRequest {
    /**
     * 
     * @type {LdapSettingsDTO}
     * @memberof SettingsControllerApiUpdateLdapSettings
     */
    readonly ldapSettingsDTO: LdapSettingsDTO
}

/**
 * Request parameters for updateLicense operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateLicenseRequest
 */
export interface SettingsControllerApiUpdateLicenseRequest {
    /**
     * 
     * @type {LicenseUpdateDTO}
     * @memberof SettingsControllerApiUpdateLicense
     */
    readonly licenseUpdateDTO: LicenseUpdateDTO
}

/**
 * Request parameters for updateLogFileSettings operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateLogFileSettingsRequest
 */
export interface SettingsControllerApiUpdateLogFileSettingsRequest {
    /**
     * 
     * @type {LogFileSettings}
     * @memberof SettingsControllerApiUpdateLogFileSettings
     */
    readonly logFileSettings: LogFileSettings
}

/**
 * Request parameters for updateMailConfiguration operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateMailConfigurationRequest
 */
export interface SettingsControllerApiUpdateMailConfigurationRequest {
    /**
     * 
     * @type {MailSettingsDTO}
     * @memberof SettingsControllerApiUpdateMailConfiguration
     */
    readonly mailSettingsDTO: MailSettingsDTO
}

/**
 * Request parameters for updateMailHeaders operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateMailHeadersRequest
 */
export interface SettingsControllerApiUpdateMailHeadersRequest {
    /**
     * 
     * @type {MailHeadersDTO}
     * @memberof SettingsControllerApiUpdateMailHeaders
     */
    readonly mailHeadersDTO: MailHeadersDTO
}

/**
 * Request parameters for updateNotificationAboutDeletingMailHeaders operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateNotificationAboutDeletingMailHeadersRequest
 */
export interface SettingsControllerApiUpdateNotificationAboutDeletingMailHeadersRequest {
    /**
     * 
     * @type {NotificationAboutDeletingMailHeadersDTO}
     * @memberof SettingsControllerApiUpdateNotificationAboutDeletingMailHeaders
     */
    readonly notificationAboutDeletingMailHeadersDTO: NotificationAboutDeletingMailHeadersDTO
}

/**
 * Request parameters for updateStorageSettings operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateStorageSettingsRequest
 */
export interface SettingsControllerApiUpdateStorageSettingsRequest {
    /**
     * 
     * @type {StorageSettingsDTO}
     * @memberof SettingsControllerApiUpdateStorageSettings
     */
    readonly storageSettingsDTO: StorageSettingsDTO
}

/**
 * Request parameters for updateUserExpireSettings operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUpdateUserExpireSettingsRequest
 */
export interface SettingsControllerApiUpdateUserExpireSettingsRequest {
    /**
     * 
     * @type {UserExpireSettingsDTO}
     * @memberof SettingsControllerApiUpdateUserExpireSettings
     */
    readonly userExpireSettingsDTO: UserExpireSettingsDTO
}

/**
 * Request parameters for uploadLogo operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUploadLogoRequest
 */
export interface SettingsControllerApiUploadLogoRequest {
    /**
     * 
     * @type {File}
     * @memberof SettingsControllerApiUploadLogo
     */
    readonly file?: File
}

/**
 * Request parameters for uploadLogoMobile operation in SettingsControllerApi.
 * @export
 * @interface SettingsControllerApiUploadLogoMobileRequest
 */
export interface SettingsControllerApiUploadLogoMobileRequest {
    /**
     * 
     * @type {File}
     * @memberof SettingsControllerApiUploadLogoMobile
     */
    readonly file?: File
}

/**
 * SettingsControllerApi - object-oriented interface
 * @export
 * @class SettingsControllerApi
 * @extends {BaseAPI}
 */
export class SettingsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Deletes custom logo, default will be set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public deleteCustomLogo(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).deleteCustomLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes custom logo for mobile version, default will be set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public deleteCustomLogoMobile(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).deleteCustomLogoMobile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns messages on the expiration of the license or termination of the license.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getEndingOrExpiredLicenseMailHeaders(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getEndingOrExpiredLicenseMailHeaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns messages for the file period extension emails.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getExtendPeriodMailHeaders(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getExtendPeriodMailHeaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - General: get settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getGeneralSettings(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getGeneralSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - LDAP: get settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getLdapSettings(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getLdapSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns license info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getLicensesInfo(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getLicensesInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - Log Files: get settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getLogFileSettings(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getLogFileSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - Mail: get settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getMailConfiguration(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getMailConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns default mail headers dto. Values from this dto will be set as default email subject.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getMailHeaders(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getMailHeaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns messages about deleting links to files.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getNotificationAboutDeletingMailHeaders(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getNotificationAboutDeletingMailHeaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - Storage: get settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getStorageSettings(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getStorageSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - User expire: get user expire settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public getUserExpireSettings(options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).getUserExpireSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates messages on the expiration of the license or termination of the license.
     * @param {SettingsControllerApiUpdateEndingOrExpiredLicenseMailHeadersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateEndingOrExpiredLicenseMailHeaders(requestParameters: SettingsControllerApiUpdateEndingOrExpiredLicenseMailHeadersRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateEndingOrExpiredLicenseMailHeaders(requestParameters.endingOrExpiredLicenseMailHeadersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates messages for the file period extension emails.
     * @param {SettingsControllerApiUpdateExtendPeriodMailHeadersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateExtendPeriodMailHeaders(requestParameters: SettingsControllerApiUpdateExtendPeriodMailHeadersRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateExtendPeriodMailHeaders(requestParameters.extendMessageMailHeadersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - General: save settings
     * @param {SettingsControllerApiUpdateGeneralSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateGeneralSettings(requestParameters: SettingsControllerApiUpdateGeneralSettingsRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateGeneralSettings(requestParameters.generalSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - LDAP: save settings
     * @param {SettingsControllerApiUpdateLdapSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateLdapSettings(requestParameters: SettingsControllerApiUpdateLdapSettingsRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateLdapSettings(requestParameters.ldapSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingsControllerApiUpdateLicenseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateLicense(requestParameters: SettingsControllerApiUpdateLicenseRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateLicense(requestParameters.licenseUpdateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - Log Files: save settings
     * @param {SettingsControllerApiUpdateLogFileSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateLogFileSettings(requestParameters: SettingsControllerApiUpdateLogFileSettingsRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateLogFileSettings(requestParameters.logFileSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - Mail: save settings
     * @param {SettingsControllerApiUpdateMailConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateMailConfiguration(requestParameters: SettingsControllerApiUpdateMailConfigurationRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateMailConfiguration(requestParameters.mailSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates default mail headers with values from given dto.
     * @param {SettingsControllerApiUpdateMailHeadersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateMailHeaders(requestParameters: SettingsControllerApiUpdateMailHeadersRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateMailHeaders(requestParameters.mailHeadersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates messages about deleting links to files.
     * @param {SettingsControllerApiUpdateNotificationAboutDeletingMailHeadersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateNotificationAboutDeletingMailHeaders(requestParameters: SettingsControllerApiUpdateNotificationAboutDeletingMailHeadersRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateNotificationAboutDeletingMailHeaders(requestParameters.notificationAboutDeletingMailHeadersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - Storage: save settings
     * @param {SettingsControllerApiUpdateStorageSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateStorageSettings(requestParameters: SettingsControllerApiUpdateStorageSettingsRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateStorageSettings(requestParameters.storageSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Settings - User expire: save user expire settings
     * @param {SettingsControllerApiUpdateUserExpireSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public updateUserExpireSettings(requestParameters: SettingsControllerApiUpdateUserExpireSettingsRequest, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).updateUserExpireSettings(requestParameters.userExpireSettingsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads custom logo to backend
     * @param {SettingsControllerApiUploadLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public uploadLogo(requestParameters: SettingsControllerApiUploadLogoRequest = {}, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).uploadLogo(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads custom logo for mobile version to backend
     * @param {SettingsControllerApiUploadLogoMobileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsControllerApi
     */
    public uploadLogoMobile(requestParameters: SettingsControllerApiUploadLogoMobileRequest = {}, options?: AxiosRequestConfig) {
        return SettingsControllerApiFp(this.configuration).uploadLogoMobile(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsControllerApi - axios parameter creator
 * @export
 */
export const StatisticsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileSummary: async (startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getFileSummary', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getFileSummary', 'endDate', endDate)
            const localVarPath = `/admin/stats/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsControllerApi - functional programming interface
 * @export
 */
export const StatisticsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileSummary(startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatsFileSummaryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileSummary(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatsSummaryDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsControllerApi - factory interface
 * @export
 */
export const StatisticsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileSummary(startDate: string, endDate: string, options?: any): AxiosPromise<StatsFileSummaryDTO> {
            return localVarFp.getFileSummary(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary(options?: any): AxiosPromise<StatsSummaryDTO> {
            return localVarFp.getSummary(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getFileSummary operation in StatisticsControllerApi.
 * @export
 * @interface StatisticsControllerApiGetFileSummaryRequest
 */
export interface StatisticsControllerApiGetFileSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof StatisticsControllerApiGetFileSummary
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof StatisticsControllerApiGetFileSummary
     */
    readonly endDate: string
}

/**
 * StatisticsControllerApi - object-oriented interface
 * @export
 * @class StatisticsControllerApi
 * @extends {BaseAPI}
 */
export class StatisticsControllerApi extends BaseAPI {
    /**
     * 
     * @param {StatisticsControllerApiGetFileSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getFileSummary(requestParameters: StatisticsControllerApiGetFileSummaryRequest, options?: AxiosRequestConfig) {
        return StatisticsControllerApiFp(this.configuration).getFileSummary(requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getSummary(options?: AxiosRequestConfig) {
        return StatisticsControllerApiFp(this.configuration).getSummary(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/admin/storage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates local file storage
         * @param {LocalFileStorageCreateRequest} localFileStorageCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (localFileStorageCreateRequest: LocalFileStorageCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localFileStorageCreateRequest' is not null or undefined
            assertParamExists('create', 'localFileStorageCreateRequest', localFileStorageCreateRequest)
            const localVarPath = `/admin/storage/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localFileStorageCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Storage might be with different types
         * @summary Returns a list of storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns storage properties for requested storage
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getById', 'id', id)
            const localVarPath = `/admin/storage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get default settings for creating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewStorageSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/storage/create/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set order for the storages to display on ui
         * @param {LocalFileStorageReorderRequest} localFileStorageReorderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorder: async (localFileStorageReorderRequest: LocalFileStorageReorderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localFileStorageReorderRequest' is not null or undefined
            assertParamExists('reorder', 'localFileStorageReorderRequest', localFileStorageReorderRequest)
            const localVarPath = `/admin/storage/reorder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localFileStorageReorderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit existing local file storage.
         * @param {LocalFileStorageEditRequest} localFileStorageEditRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (localFileStorageEditRequest: LocalFileStorageEditRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localFileStorageEditRequest' is not null or undefined
            assertParamExists('update', 'localFileStorageEditRequest', localFileStorageEditRequest)
            const localVarPath = `/admin/storage/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(localFileStorageEditRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates local file storage
         * @param {LocalFileStorageCreateRequest} localFileStorageCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(localFileStorageCreateRequest: LocalFileStorageCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalFileStorageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(localFileStorageCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Storage might be with different types
         * @summary Returns a list of storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocalFileStorageDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns storage properties for requested storage
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalFileStorageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get default settings for creating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewStorageSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalFileStorageCreateParametersDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewStorageSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set order for the storages to display on ui
         * @param {LocalFileStorageReorderRequest} localFileStorageReorderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorder(localFileStorageReorderRequest: LocalFileStorageReorderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorder(localFileStorageReorderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit existing local file storage.
         * @param {LocalFileStorageEditRequest} localFileStorageEditRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(localFileStorageEditRequest: LocalFileStorageEditRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalFileStorageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(localFileStorageEditRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates local file storage
         * @param {LocalFileStorageCreateRequest} localFileStorageCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(localFileStorageCreateRequest: LocalFileStorageCreateRequest, options?: any): AxiosPromise<LocalFileStorageDTO> {
            return localVarFp.create(localFileStorageCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Storage might be with different types
         * @summary Returns a list of storage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<Array<LocalFileStorageDTO>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns storage properties for requested storage
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(id: number, options?: any): AxiosPromise<LocalFileStorageDTO> {
            return localVarFp.getById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get default settings for creating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewStorageSettings(options?: any): AxiosPromise<LocalFileStorageCreateParametersDTO> {
            return localVarFp.getNewStorageSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set order for the storages to display on ui
         * @param {LocalFileStorageReorderRequest} localFileStorageReorderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorder(localFileStorageReorderRequest: LocalFileStorageReorderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reorder(localFileStorageReorderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit existing local file storage.
         * @param {LocalFileStorageEditRequest} localFileStorageEditRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(localFileStorageEditRequest: LocalFileStorageEditRequest, options?: any): AxiosPromise<LocalFileStorageDTO> {
            return localVarFp.update(localFileStorageEditRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in StorageApi.
 * @export
 * @interface StorageApiDeleteRequest
 */
export interface StorageApiDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof StorageApiDelete
     */
    readonly id: number
}

/**
 * Request parameters for create operation in StorageApi.
 * @export
 * @interface StorageApiCreateRequest
 */
export interface StorageApiCreateRequest {
    /**
     * 
     * @type {LocalFileStorageCreateRequest}
     * @memberof StorageApiCreate
     */
    readonly localFileStorageCreateRequest: LocalFileStorageCreateRequest
}

/**
 * Request parameters for getById operation in StorageApi.
 * @export
 * @interface StorageApiGetByIdRequest
 */
export interface StorageApiGetByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof StorageApiGetById
     */
    readonly id: number
}

/**
 * Request parameters for reorder operation in StorageApi.
 * @export
 * @interface StorageApiReorderRequest
 */
export interface StorageApiReorderRequest {
    /**
     * 
     * @type {LocalFileStorageReorderRequest}
     * @memberof StorageApiReorder
     */
    readonly localFileStorageReorderRequest: LocalFileStorageReorderRequest
}

/**
 * Request parameters for update operation in StorageApi.
 * @export
 * @interface StorageApiUpdateRequest
 */
export interface StorageApiUpdateRequest {
    /**
     * 
     * @type {LocalFileStorageEditRequest}
     * @memberof StorageApiUpdate
     */
    readonly localFileStorageEditRequest: LocalFileStorageEditRequest
}

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     * 
     * @param {StorageApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public _delete(requestParameters: StorageApiDeleteRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates local file storage
     * @param {StorageApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public create(requestParameters: StorageApiCreateRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).create(requestParameters.localFileStorageCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Storage might be with different types
     * @summary Returns a list of storage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns storage properties for requested storage
     * @param {StorageApiGetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getById(requestParameters: StorageApiGetByIdRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).getById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get default settings for creating
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public getNewStorageSettings(options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).getNewStorageSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set order for the storages to display on ui
     * @param {StorageApiReorderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public reorder(requestParameters: StorageApiReorderRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).reorder(requestParameters.localFileStorageReorderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit existing local file storage.
     * @param {StorageApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public update(requestParameters: StorageApiUpdateRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).update(requestParameters.localFileStorageEditRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TechSupportControllerApi - axios parameter creator
 * @export
 */
export const TechSupportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sends message to tech support.
         * @param {TechSupportDataDTO} techSupportDataDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToTechSupport: async (techSupportDataDTO: TechSupportDataDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'techSupportDataDTO' is not null or undefined
            assertParamExists('sendMessageToTechSupport', 'techSupportDataDTO', techSupportDataDTO)
            const localVarPath = `/support`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(techSupportDataDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TechSupportControllerApi - functional programming interface
 * @export
 */
export const TechSupportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TechSupportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sends message to tech support.
         * @param {TechSupportDataDTO} techSupportDataDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessageToTechSupport(techSupportDataDTO: TechSupportDataDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMessageToTechSupport(techSupportDataDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TechSupportControllerApi - factory interface
 * @export
 */
export const TechSupportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TechSupportControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Sends message to tech support.
         * @param {TechSupportDataDTO} techSupportDataDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageToTechSupport(techSupportDataDTO: TechSupportDataDTO, options?: any): AxiosPromise<void> {
            return localVarFp.sendMessageToTechSupport(techSupportDataDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sendMessageToTechSupport operation in TechSupportControllerApi.
 * @export
 * @interface TechSupportControllerApiSendMessageToTechSupportRequest
 */
export interface TechSupportControllerApiSendMessageToTechSupportRequest {
    /**
     * 
     * @type {TechSupportDataDTO}
     * @memberof TechSupportControllerApiSendMessageToTechSupport
     */
    readonly techSupportDataDTO: TechSupportDataDTO
}

/**
 * TechSupportControllerApi - object-oriented interface
 * @export
 * @class TechSupportControllerApi
 * @extends {BaseAPI}
 */
export class TechSupportControllerApi extends BaseAPI {
    /**
     * 
     * @summary Sends message to tech support.
     * @param {TechSupportControllerApiSendMessageToTechSupportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechSupportControllerApi
     */
    public sendMessageToTechSupport(requestParameters: TechSupportControllerApiSendMessageToTechSupportRequest, options?: AxiosRequestConfig) {
        return TechSupportControllerApiFp(this.configuration).sendMessageToTechSupport(requestParameters.techSupportDataDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TusUploadsApi - axios parameter creator
 * @export
 */
export const TusUploadsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create file location on server where the temporary file will be stored. Successful execution will return code 201. The request must contain a header \'Upload-Metadata\' that consist of one or more comma-separated key-value pairs. The key and value MUST be separated by a space. The key SHOULD be ASCII encoded and the value MUST be Base64 encoded. Example [filename TmFtZU9mVGhlRmlsZQ==]If the file name is not specified, the name will be put in the form of UUID
         * @summary Create file location
         * @param {string} [tusResumable] 
         * @param {string} [uploadLength] 
         * @param {string} [uploadMetadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileLocation: async (tusResumable?: string, uploadLength?: string, uploadMetadata?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tus/file/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tusResumable != null) {
                localVarHeaderParameter['Tus-Resumable'] = String(tusResumable);
            }

            if (uploadLength != null) {
                localVarHeaderParameter['Upload-Length'] = String(uploadLength);
            }

            if (uploadMetadata != null) {
                localVarHeaderParameter['Upload-Metadata'] = String(uploadMetadata);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an upload with uploadId. Successful execution will return code 204.
         * @summary Delete upload
         * @param {string} uploadId 
         * @param {string} [tusResumable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUpload: async (uploadId: string, tusResumable?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('deleteUpload', 'uploadId', uploadId)
            const localVarPath = `/tus/file/upload/{uploadId}`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tusResumable != null) {
                localVarHeaderParameter['Tus-Resumable'] = String(tusResumable);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information about TUS protocol server settings. Successful execution will return code 204.
         * @summary TUS protocol server settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tusOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tus/file/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a part of a file in byte representation. Successful execution will return code 204 or 200 if uploading is finish.
         * @summary Upload file by TUS protocol
         * @param {string} uploadId 
         * @param {string} messageId 
         * @param {string} [tusResumable] 
         * @param {string} [contentLength] 
         * @param {string} [uploadOffset] 
         * @param {UploadFileRequest} [uploadFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (uploadId: string, messageId: string, tusResumable?: string, contentLength?: string, uploadOffset?: string, uploadFileRequest?: UploadFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadFile', 'uploadId', uploadId)
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('uploadFile', 'messageId', messageId)
            const localVarPath = `/tus/file/upload/{uploadId}`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }

            if (tusResumable != null) {
                localVarHeaderParameter['Tus-Resumable'] = String(tusResumable);
            }

            if (contentLength != null) {
                localVarHeaderParameter['Content-Length'] = String(contentLength);
            }

            if (uploadOffset != null) {
                localVarHeaderParameter['Upload-Offset'] = String(uploadOffset);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/offset+octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get information about an upload with uploadId. Successful execution will return code 204.
         * @summary Upload information
         * @param {string} uploadId 
         * @param {string} [tusResumable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOptions: async (uploadId: string, tusResumable?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadOptions', 'uploadId', uploadId)
            const localVarPath = `/tus/file/upload/{uploadId}`
                .replace(`{${"uploadId"}}`, encodeURIComponent(String(uploadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tusResumable != null) {
                localVarHeaderParameter['Tus-Resumable'] = String(tusResumable);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TusUploadsApi - functional programming interface
 * @export
 */
export const TusUploadsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TusUploadsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create file location on server where the temporary file will be stored. Successful execution will return code 201. The request must contain a header \'Upload-Metadata\' that consist of one or more comma-separated key-value pairs. The key and value MUST be separated by a space. The key SHOULD be ASCII encoded and the value MUST be Base64 encoded. Example [filename TmFtZU9mVGhlRmlsZQ==]If the file name is not specified, the name will be put in the form of UUID
         * @summary Create file location
         * @param {string} [tusResumable] 
         * @param {string} [uploadLength] 
         * @param {string} [uploadMetadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileLocation(tusResumable?: string, uploadLength?: string, uploadMetadata?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileLocation(tusResumable, uploadLength, uploadMetadata, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an upload with uploadId. Successful execution will return code 204.
         * @summary Delete upload
         * @param {string} uploadId 
         * @param {string} [tusResumable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUpload(uploadId: string, tusResumable?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUpload(uploadId, tusResumable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information about TUS protocol server settings. Successful execution will return code 204.
         * @summary TUS protocol server settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tusOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tusOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a part of a file in byte representation. Successful execution will return code 204 or 200 if uploading is finish.
         * @summary Upload file by TUS protocol
         * @param {string} uploadId 
         * @param {string} messageId 
         * @param {string} [tusResumable] 
         * @param {string} [contentLength] 
         * @param {string} [uploadOffset] 
         * @param {UploadFileRequest} [uploadFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(uploadId: string, messageId: string, tusResumable?: string, contentLength?: string, uploadOffset?: string, uploadFileRequest?: UploadFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(uploadId, messageId, tusResumable, contentLength, uploadOffset, uploadFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get information about an upload with uploadId. Successful execution will return code 204.
         * @summary Upload information
         * @param {string} uploadId 
         * @param {string} [tusResumable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadOptions(uploadId: string, tusResumable?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadOptions(uploadId, tusResumable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TusUploadsApi - factory interface
 * @export
 */
export const TusUploadsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TusUploadsApiFp(configuration)
    return {
        /**
         * Create file location on server where the temporary file will be stored. Successful execution will return code 201. The request must contain a header \'Upload-Metadata\' that consist of one or more comma-separated key-value pairs. The key and value MUST be separated by a space. The key SHOULD be ASCII encoded and the value MUST be Base64 encoded. Example [filename TmFtZU9mVGhlRmlsZQ==]If the file name is not specified, the name will be put in the form of UUID
         * @summary Create file location
         * @param {string} [tusResumable] 
         * @param {string} [uploadLength] 
         * @param {string} [uploadMetadata] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileLocation(tusResumable?: string, uploadLength?: string, uploadMetadata?: string, options?: any): AxiosPromise<void> {
            return localVarFp.createFileLocation(tusResumable, uploadLength, uploadMetadata, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an upload with uploadId. Successful execution will return code 204.
         * @summary Delete upload
         * @param {string} uploadId 
         * @param {string} [tusResumable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUpload(uploadId: string, tusResumable?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUpload(uploadId, tusResumable, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information about TUS protocol server settings. Successful execution will return code 204.
         * @summary TUS protocol server settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tusOptions(options?: any): AxiosPromise<void> {
            return localVarFp.tusOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a part of a file in byte representation. Successful execution will return code 204 or 200 if uploading is finish.
         * @summary Upload file by TUS protocol
         * @param {string} uploadId 
         * @param {string} messageId 
         * @param {string} [tusResumable] 
         * @param {string} [contentLength] 
         * @param {string} [uploadOffset] 
         * @param {UploadFileRequest} [uploadFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(uploadId: string, messageId: string, tusResumable?: string, contentLength?: string, uploadOffset?: string, uploadFileRequest?: UploadFileRequest, options?: any): AxiosPromise<void> {
            return localVarFp.uploadFile(uploadId, messageId, tusResumable, contentLength, uploadOffset, uploadFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get information about an upload with uploadId. Successful execution will return code 204.
         * @summary Upload information
         * @param {string} uploadId 
         * @param {string} [tusResumable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOptions(uploadId: string, tusResumable?: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadOptions(uploadId, tusResumable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFileLocation operation in TusUploadsApi.
 * @export
 * @interface TusUploadsApiCreateFileLocationRequest
 */
export interface TusUploadsApiCreateFileLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiCreateFileLocation
     */
    readonly tusResumable?: string

    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiCreateFileLocation
     */
    readonly uploadLength?: string

    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiCreateFileLocation
     */
    readonly uploadMetadata?: string
}

/**
 * Request parameters for deleteUpload operation in TusUploadsApi.
 * @export
 * @interface TusUploadsApiDeleteUploadRequest
 */
export interface TusUploadsApiDeleteUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiDeleteUpload
     */
    readonly uploadId: string

    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiDeleteUpload
     */
    readonly tusResumable?: string
}

/**
 * Request parameters for uploadFile operation in TusUploadsApi.
 * @export
 * @interface TusUploadsApiUploadFileRequest
 */
export interface TusUploadsApiUploadFileRequest {
    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiUploadFile
     */
    readonly uploadId: string

    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiUploadFile
     */
    readonly messageId: string

    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiUploadFile
     */
    readonly tusResumable?: string

    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiUploadFile
     */
    readonly contentLength?: string

    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiUploadFile
     */
    readonly uploadOffset?: string

    /**
     * 
     * @type {UploadFileRequest}
     * @memberof TusUploadsApiUploadFile
     */
    readonly uploadFileRequest?: UploadFileRequest
}

/**
 * Request parameters for uploadOptions operation in TusUploadsApi.
 * @export
 * @interface TusUploadsApiUploadOptionsRequest
 */
export interface TusUploadsApiUploadOptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiUploadOptions
     */
    readonly uploadId: string

    /**
     * 
     * @type {string}
     * @memberof TusUploadsApiUploadOptions
     */
    readonly tusResumable?: string
}

/**
 * TusUploadsApi - object-oriented interface
 * @export
 * @class TusUploadsApi
 * @extends {BaseAPI}
 */
export class TusUploadsApi extends BaseAPI {
    /**
     * Create file location on server where the temporary file will be stored. Successful execution will return code 201. The request must contain a header \'Upload-Metadata\' that consist of one or more comma-separated key-value pairs. The key and value MUST be separated by a space. The key SHOULD be ASCII encoded and the value MUST be Base64 encoded. Example [filename TmFtZU9mVGhlRmlsZQ==]If the file name is not specified, the name will be put in the form of UUID
     * @summary Create file location
     * @param {TusUploadsApiCreateFileLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TusUploadsApi
     */
    public createFileLocation(requestParameters: TusUploadsApiCreateFileLocationRequest = {}, options?: AxiosRequestConfig) {
        return TusUploadsApiFp(this.configuration).createFileLocation(requestParameters.tusResumable, requestParameters.uploadLength, requestParameters.uploadMetadata, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an upload with uploadId. Successful execution will return code 204.
     * @summary Delete upload
     * @param {TusUploadsApiDeleteUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TusUploadsApi
     */
    public deleteUpload(requestParameters: TusUploadsApiDeleteUploadRequest, options?: AxiosRequestConfig) {
        return TusUploadsApiFp(this.configuration).deleteUpload(requestParameters.uploadId, requestParameters.tusResumable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information about TUS protocol server settings. Successful execution will return code 204.
     * @summary TUS protocol server settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TusUploadsApi
     */
    public tusOptions(options?: AxiosRequestConfig) {
        return TusUploadsApiFp(this.configuration).tusOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a part of a file in byte representation. Successful execution will return code 204 or 200 if uploading is finish.
     * @summary Upload file by TUS protocol
     * @param {TusUploadsApiUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TusUploadsApi
     */
    public uploadFile(requestParameters: TusUploadsApiUploadFileRequest, options?: AxiosRequestConfig) {
        return TusUploadsApiFp(this.configuration).uploadFile(requestParameters.uploadId, requestParameters.messageId, requestParameters.tusResumable, requestParameters.contentLength, requestParameters.uploadOffset, requestParameters.uploadFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get information about an upload with uploadId. Successful execution will return code 204.
     * @summary Upload information
     * @param {TusUploadsApiUploadOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TusUploadsApi
     */
    public uploadOptions(requestParameters: TusUploadsApiUploadOptionsRequest, options?: AxiosRequestConfig) {
        return TusUploadsApiFp(this.configuration).uploadOptions(requestParameters.uploadId, requestParameters.tusResumable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Find user by user id
         * @summary Return user with the same user id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of users with pagination parameters.
         * @summary Returns a list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * All fields of the createUpdateUserModel object will rewrite info of the same fields of user in DB with id = id
         * @summary Update user info
         * @param {number} id 
         * @param {CreateUpdateUserModel} createUpdateUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById: async (id: number, createUpdateUserModel: CreateUpdateUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserById', 'id', id)
            // verify required parameter 'createUpdateUserModel' is not null or undefined
            assertParamExists('updateUserById', 'createUpdateUserModel', createUpdateUserModel)
            const localVarPath = `/admin/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token access required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUpdateUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Find user by user id
         * @summary Return user with the same user id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of users with pagination parameters.
         * @summary Returns a list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * All fields of the createUpdateUserModel object will rewrite info of the same fields of user in DB with id = id
         * @summary Update user info
         * @param {number} id 
         * @param {CreateUpdateUserModel} createUpdateUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserById(id: number, createUpdateUserModel: CreateUpdateUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserById(id, createUpdateUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Find user by user id
         * @summary Return user with the same user id.
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: number, options?: any): AxiosPromise<UserModel> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of users with pagination parameters.
         * @summary Returns a list of users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * All fields of the createUpdateUserModel object will rewrite info of the same fields of user in DB with id = id
         * @summary Update user info
         * @param {number} id 
         * @param {CreateUpdateUserModel} createUpdateUserModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById(id: number, createUpdateUserModel: CreateUpdateUserModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.updateUserById(id, createUpdateUserModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getUserById operation in UsersApi.
 * @export
 * @interface UsersApiGetUserByIdRequest
 */
export interface UsersApiGetUserByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUserById
     */
    readonly id: number
}

/**
 * Request parameters for updateUserById operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserByIdRequest
 */
export interface UsersApiUpdateUserByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiUpdateUserById
     */
    readonly id: number

    /**
     * 
     * @type {CreateUpdateUserModel}
     * @memberof UsersApiUpdateUserById
     */
    readonly createUpdateUserModel: CreateUpdateUserModel
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Find user by user id
     * @summary Return user with the same user id.
     * @param {UsersApiGetUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserById(requestParameters: UsersApiGetUserByIdRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of users with pagination parameters.
     * @summary Returns a list of users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * All fields of the createUpdateUserModel object will rewrite info of the same fields of user in DB with id = id
     * @summary Update user info
     * @param {UsersApiUpdateUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserById(requestParameters: UsersApiUpdateUserByIdRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserById(requestParameters.id, requestParameters.createUpdateUserModel, options).then((request) => request(this.axios, this.basePath));
    }
}


