import { ComponentsOverrides } from "@mui/material/styles/overrides";
import { styleVariables } from "../variables";

export const MuiIconButton: ComponentsOverrides["MuiIconButton"] = {
    root: {
        padding: styleVariables.spacing * 2,
    },
};

export default MuiIconButton;
