import { Theme, Box } from "@mui/material";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { MyFilesTableFilter } from "src/components/MyFilesTableFilter";
import { Upload } from "src/types/Upload";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import {
    deleteMessageOnServer,
    prolongPeriod,
    sendByEmail,
    ProlongPeriodArgs,
} from "src/redux/pages/myFiles/actions";
import { ReceivedMessageResponseDataMessageTypeEnum } from "../services/generated";
import { SendMessageData } from "src/types/redux/store/pages/SendFilePage";
import MyFilesTable from "src/components/CommonTable/TableRealisation/myFilesTable/MyFilesTable";
import { DialodAddFiles } from "src/components/ModalWindows/myFiles/DialodAddFiles";
import { DialogDeleteFile } from "src/components/ModalWindows/myFiles/DialogDeleteFile";
import { DialogProlong } from "src/components/ModalWindows/myFiles/DialogProlong";
import { DialogForward } from "src/components/ModalWindows/myFiles/DialogForward";
import {
    DEFAULT_PAGINATION,
    DataGrid,
    ExpandedState,
} from "src/components/DataGrid";
import { DialogDirectLinks } from "src/components/ModalWindows/myFiles/DialogDirectLinks";
import { DialogDownloadInc } from "src/components/ModalWindows/myFiles/DialogDownloadInc";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            padding: "12px 8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.up("sm")]: {
                padding: "18px 32px",
            },
        },
        height100: {
            height: "100%",
        },
        table: {
            minHeight: 200,
            height: "calc(100% - 155px)",
            padding: 0,
            [theme.breakpoints.up("sm")]: {
                padding: "0 24px",
                height: "calc(100% - 75px)",
            },
        },
    }),
);

export enum TYPE_EVENT {
    ADD_FILES,
    DELETE_FILES,
    PROLONG,
    FORWARD,
    DIRECT_LINKS,
    DOWNLOAD_INC,
}

export type EventUploadType = {
    uplaod: Upload;
    typeEvent: TYPE_EVENT;
};

export type BaseDialogProps = {
    open?: boolean;
    onClose?: () => void;
    upload?: Upload;
};

export function MyFiles() {
    const styles = useStyle();
    const dispatch = useDispatch();
    const [eventUpload, setEventUpload] = useState<EventUploadType | null>(
        null,
    );

    const { setPagination, pagination } = DataGrid.useTableState();
    const [expanded, setExpanded] = useState<ExpandedState>({});

    const { uploads, filesFilter } = useSelector(
        (store: Store) => store.pages.myFilesPage,
    );

    const onFilesFilterChange = () => {
        setPagination(DEFAULT_PAGINATION);
        setExpanded({});
    };

    const closeModal = () => {
        setEventUpload(null);
    };

    const onSendByEmail = (sendMessageData: SendMessageData) => {
        dispatch(sendByEmail(sendMessageData));
    };

    const prolong = (params: ProlongPeriodArgs) => {
        dispatch(prolongPeriod(params));
    };

    const confirmDelete = () => {
        const upload = eventUpload.uplaod;
        let pageToGet = pagination.pageIndex;
        if (uploads.length <= 1 && pageToGet !== 0) {
            pageToGet -= 1;
            setPagination({
                pageIndex: pageToGet,
                pageSize: pagination.pageSize,
            });
        }
        dispatch(
            deleteMessageOnServer({
                messageId: upload.id,
                messageType:
                    upload.messageType as ReceivedMessageResponseDataMessageTypeEnum,
                page: pageToGet,
                pageSize: pagination.pageSize,
                filter: filesFilter,
            }),
        );
    };

    return (
        <Box className={styles.height100}>
            <Box className={styles.header}>
                <MyFilesTableFilter onFilesFilterChange={onFilesFilterChange} />
            </Box>
            <Box className={styles.table}>
                <MyFilesTable
                    setEventUpload={setEventUpload}
                    pagination={pagination}
                    onPaginationChange={setPagination}
                    expanded={expanded}
                    onExpandedChange={setExpanded}
                />
            </Box>
            <DialodAddFiles />
            <DialogProlong
                open={eventUpload?.typeEvent === TYPE_EVENT.PROLONG}
                onClose={closeModal}
                upload={eventUpload?.uplaod}
                prolong={prolong}
            />
            <DialogForward
                open={eventUpload?.typeEvent === TYPE_EVENT.FORWARD}
                onClose={closeModal}
                upload={eventUpload?.uplaod}
                onSend={onSendByEmail}
            />
            <DialogDeleteFile
                open={eventUpload?.typeEvent === TYPE_EVENT.DELETE_FILES}
                onClose={closeModal}
                confirmDelete={confirmDelete}
            />
            <DialogDirectLinks
                open={eventUpload?.typeEvent === TYPE_EVENT.DIRECT_LINKS}
                onClose={closeModal}
                upload={eventUpload?.uplaod}
            />
            <DialogDownloadInc
                open={eventUpload?.typeEvent === TYPE_EVENT.DOWNLOAD_INC}
                onClose={closeModal}
                upload={eventUpload?.uplaod}
            />
        </Box>
    );
}

export default MyFiles;
