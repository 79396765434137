import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { MyFilesPage } from "src/types/redux/store/pages/MyFilesPage";
import { FilesFilter } from "src/types/redux/store/pages/MyFilesPage";
import {
    setFilesFilter,
    setHideInactive,
    setFilesSearchFilter,
    loadUploadedFilesInfo,
    loadRecievedFilesInfo,
    prolongPeriod,
    setAddFilesUploadId,
    addFiles,
    changeAddedFileUploadProgressAction,
    setAddedFiles,
    deleteAddedFile,
    uploadAddedFileToServer,
    sendByEmail,
    removeWrongFiles,
    ProlongPeriodArgs,
    incrementDownloadCount,
    getDownloadStateFilesMyFiles,
    increaseDownloadLimit,
    IncreaseDownloadLimitArgs,
} from "./actions";
import { Upload, UploadedFile } from "src/types/Upload";
import {
    FileDTO,
    FileError,
    FileStateResponse,
    FileStatus,
    PageReceivedMessageResponseData,
    PageSentMessageResponseData,
} from "src/services/generated";
import { ChangeFileUploadProgressActionType } from "../sendFile/actions";
import { FileViewModel } from "src/types/FileDisplay";
import { MetaAction } from "src/types/redux/PayloadAction";
import moment from "moment";
import { SendMessageData } from "src/types/redux/store/pages/SendFilePage";
import {
    checkIfFileInIntermediateState,
    fireFileWasNotUploadedPopup,
} from "src/helpers/file";

const myFilesInitialState: MyFilesPage = {
    filesFilter: FilesFilter.SENT,
    hideInactive: false,
    uploads: [],
    totalElements: 0,
    totalPages: 0,
    loadingUploads: true,
    addFilesUploadId: null,
    addedFilesIds: [],
    sendingByEmail: false,
    reservedFileId: [],
};

export const myFilesPageReducer = createReducer<MyFilesPage>(
    myFilesInitialState,
    {
        [setFilesFilter.type](state, action: PayloadAction<FilesFilter>) {
            state.filesFilter = action.payload;
        },
        [setFilesSearchFilter.type](
            state,
            action: PayloadAction<string | undefined>,
        ) {
            state.searchFilter = action.payload || "";
        },
        [setHideInactive.type](state, action: PayloadAction<boolean>) {
            state.hideInactive = action.payload;
        },
        [incrementDownloadCount.type](state, action: PayloadAction<string>) {
            const upload = state.uploads.find(
                upload => upload.id === action.payload,
            );
            if (upload) {
                upload.files?.forEach(file => {
                    if (file.downloadCount < file.downloadLimit) {
                        file.downloadCount++;
                    }
                });
            }
        },
        [increaseDownloadLimit.pending.type](
            state,
            action: PayloadAction<void>,
        ) {
            state.updatingUpload = true;
        },
        [increaseDownloadLimit.fulfilled.type](
            state,
            action: MetaAction<IncreaseDownloadLimitArgs>,
        ) {
            state.updatingUpload = false;
            const upload = state.uploads.find(
                uplaod => uplaod.id === action.meta.arg.messageId,
            );

            if (upload) {
                for (const file of upload.files) {
                    file.downloadLimit +=
                        +action.meta.arg.downloadLimit.downloadLimit;
                }
            }
        },
        [increaseDownloadLimit.rejected.type](
            state,
            action: PayloadAction<any>,
        ) {
            state.updatingUpload = false;
        },
        [loadUploadedFilesInfo.pending.type](
            state,
            action: PayloadAction<void>,
        ) {
            state.loadingUploads = true;
        },
        [loadUploadedFilesInfo.fulfilled.type](
            state,
            action: PayloadAction<PageSentMessageResponseData>,
        ) {
            if (!action.payload?.content) return;
            state.uploads = action.payload.content!.map<Upload>(payload => ({
                files: payload.files as any,
                id: payload.id,
                recipients: payload.recipientEmails || [],
                activeBefore: payload.expireTime,
                expanded: payload.files?.length > 1 ? false : true || true,
                blocked: false,
                sender: undefined,
                dispatchDate: payload.createDateTime,
                messageType: payload.messageType,
                security: payload.security,
            }));
            state.totalPages = action.payload.totalPages!;
            state.totalElements = action.payload.totalElements!;
            state.loadingUploads = false;
        },
        [loadUploadedFilesInfo.rejected.type](
            state,
            action: PayloadAction<void>,
        ) {
            state.loadingUploads = false;
        },
        [loadRecievedFilesInfo.pending.type](
            state,
            action: PayloadAction<void>,
        ) {
            state.loadingUploads = true;
        },
        [loadRecievedFilesInfo.fulfilled.type](
            state,
            action: PayloadAction<PageReceivedMessageResponseData>,
        ) {
            state.uploads = action.payload.content!.map<Upload>(payload => ({
                files: payload.files!.map(file => ({
                    currentDownloadCount: file.downloadCount!,
                    limitDownloadCount: file.downloadLimit!,
                    id: file.id!,
                    name: file.name!,
                    size: file.size || 0,
                })),
                id: payload.id,
                recipients: [],
                activeBefore: payload.expireTime,
                expanded: false,
                blocked: false,
                sender: payload.senderEmail,
                dispatchDate: payload.createDateTime,
                messageType: payload.messageType,
            }));
            state.totalPages = action.payload.totalPages!;
            state.totalElements = action.payload.totalElements!;
            state.loadingUploads = false;
        },
        [loadRecievedFilesInfo.rejected.type](
            state,
            action: PayloadAction<void>,
        ) {
            state.loadingUploads = true;
        },

        [prolongPeriod.pending.type](state, action: PayloadAction<void>) {
            state.updatingUpload = true;
        },
        [prolongPeriod.fulfilled.type](
            state,
            action: MetaAction<ProlongPeriodArgs>,
        ) {
            state.updatingUpload = false;
            const upload = state.uploads.find(
                uplaod => uplaod.id === action.meta.arg.upload.id,
            );
            if (upload) {
                upload.activeBefore = moment(upload.activeBefore)
                    .add(action.meta.arg.prolongFor, "day")
                    .format();
            }
        },
        [prolongPeriod.rejected.type](state, action: PayloadAction<any>) {
            state.updatingUpload = false;
        },

        [setAddFilesUploadId.type](
            state,
            action: PayloadAction<null | string>,
        ) {
            state.addFilesUploadId = action.payload;
        },
        [addFiles.fulfilled.type](state, action: PayloadAction<FileDTO[]>) {
            const upload = state.uploads.find(
                upload => upload.id === state.addFilesUploadId,
            );
            const limitDownloadCount = upload.files.find(
                file => file.downloadLimit,
            )?.downloadLimit;
            const newFiles = action.payload;
            const newUploadedFiles = newFiles.map(
                file =>
                    ({
                        ...file,
                        name: file.name,
                        downloadCount: 0,
                        downloadLimit: limitDownloadCount,
                    }) as UploadedFile,
            );
            upload.files.unshift(...newUploadedFiles);
            state.addedFilesIds.push(...newFiles.map(file => file.id));
        },

        [changeAddedFileUploadProgressAction.type](
            state,
            action: PayloadAction<ChangeFileUploadProgressActionType>,
        ) {
            const upload = state.uploads.find(
                upload => upload.id === state.addFilesUploadId,
            );
            if (!upload?.files) return;
            upload.files = upload.files.map(file => {
                if (file.id === action.payload.fileId) {
                    const newFile: FileViewModel = { ...file };
                    newFile.progress = action.payload.progress;
                    if (action.payload.progress === 100) {
                        newFile.status = FileStatus.READY;
                    }
                    return newFile;
                }
                return file;
            });
        },

        [uploadAddedFileToServer.fulfilled.type](
            state,
            action: PayloadAction<FileDTO | undefined>,
        ) {
            if (!action.payload) return;
            const upload = state.uploads.find(
                upload => upload.id === state.addFilesUploadId,
            );
            const file = upload?.files.find(
                file => file.id === action.payload.id,
            );
            if (!file) return;
            (file as any).status = action.payload?.status;
            if (checkIfFileInIntermediateState(action.payload)) {
                state.reservedFileId.push(action.payload.id);
            }
        },

        [setAddedFiles.type](state, action: PayloadAction<string[]>) {
            state.addedFilesIds = action.payload;
        },

        [deleteAddedFile.pending.type](state, action: MetaAction<string>) {
            const upload = state.uploads.find(
                upload => upload.id === state.addFilesUploadId,
            );
            const addedFileIndex = state.addedFilesIds.findIndex(
                fileId => fileId === action.meta.arg,
            );
            const uploadFileIndex = upload.files.findIndex(
                file => file.id === action.meta.arg,
            );
            if (addedFileIndex !== -1) {
                state.addedFilesIds.splice(addedFileIndex, 1);
            }
            if (uploadFileIndex !== -1) {
                upload.files.splice(uploadFileIndex, 1);
            }
            const currentReservedFileIdIndex = state.reservedFileId?.findIndex(
                id => id === action.meta.arg,
            );
            if (
                currentReservedFileIdIndex !== undefined &&
                currentReservedFileIdIndex !== -1
            ) {
                state.reservedFileId?.splice(currentReservedFileIdIndex, 1);
            }
        },
        [sendByEmail.pending.type](state, action: PayloadAction<void>) {
            state.sendingByEmail = true;
        },
        [sendByEmail.fulfilled.type](
            state,
            action: PayloadAction<SendMessageData>,
        ) {
            state.sendingByEmail = false;
        },
        [sendByEmail.rejected.type](state, action: PayloadAction<void>) {
            state.sendingByEmail = false;
        },
        [removeWrongFiles.type](state) {
            const upload = state.uploads.find(
                upload => upload.id === state.addFilesUploadId,
            );
            upload.files = upload.files.filter(
                file =>
                    file.error !== FileError.DUPLICATED &&
                    file.error !== FileError.EXCEED_UPLOAD_LIMIT,
            );
        },
        [getDownloadStateFilesMyFiles.fulfilled.type](
            state,
            action: PayloadAction<FileStateResponse>,
        ) {
            const upload = state.uploads.find(
                upload => upload.id === state.addFilesUploadId,
            );
            const files = upload?.files?.filter(
                file =>
                    action.payload.files[file.id] &&
                    file.id === action.payload.files[file.id].id,
            );
            if (files?.length) {
                files.map(file => {
                    file.status = action.payload.files[file.id]
                        .status as FileStatus;
                    file.error = action.payload.files[file.id]
                        .error as FileError;
                    return file;
                });
                files
                    .filter(file => file.error === FileError.INFECTED)
                    .forEach(file => fireFileWasNotUploadedPopup(file.name));
            }
            state.reservedFileId = state.reservedFileId.filter(id =>
                checkIfFileInIntermediateState(action.payload.files[id]),
            );
        },
    },
);
