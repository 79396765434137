import React, { FC } from "react";
import { FilesRequestForm } from "../components/FilesRequest/FilesRequestForm";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { FilesRequestPageState } from "../types/redux/store/pages/FilesRequestPage";
import { AfterRequestSending } from "../components/FilesRequest/AfterRequestSending";
import { CircularProgress } from "@mui/material";

const useStyle = makeStyles({
    container: {
        margin: "auto",
        width: "500px",
        "@media screen and (max-width: 500px)": {
            width: "calc(100% - 48px)",
            padding: "24px",
        },
    },
    circlarContainer: {
        textAlign: "center",
        margin: "10vh auto",
    },
});

export const FilesRequestPage: FC = () => {
    const styles = useStyle();
    const { inProgress, pageState } = useSelector(
        (store: Store) => store.pages.filesRequestPage,
    );

    return (
        <div className={styles.container}>
            {inProgress ? (
                <div className={styles.circlarContainer}>
                    <CircularProgress />{" "}
                </div>
            ) : (
                <div>
                    {pageState === FilesRequestPageState.REQUEST_CREATING ? (
                        <FilesRequestForm />
                    ) : (
                        <AfterRequestSending />
                    )}
                </div>
            )}
        </div>
    );
};
