import React from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

export interface InformCardType {
    icon: React.ElementType;
    name: string;
    value?: string;
    children?: React.ReactNode;
    className?: string;
}

interface InformCardProps extends InformCardType {}
const useStyles = makeStyles({
    container: {
        height: 140,
        fontFamily: "Roboto",
        padding: "16px 0",
        border: "1px solid #E7E7E7",
        borderRadius: "4px",
        background: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& p": {
            margin: 0,
        },
    },
    value: {
        marginBottom: 8,
        fontSize: 24,
        lineHeight: "28px",
    },
    name: {
        fontSize: "12px",
        lineHeight: "15px",
        opacity: 0.6,
        textAlign: "center",
        letterSpacing: "0.4px",
    },
    icon: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 16,
    },
});

export const InformCard = (props: InformCardProps) => {
    const styles = useStyles();
    const { icon, name, value, children, className } = props;

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.icon}>{React.createElement(icon)}</div>
            <p className={styles.value}>{value || children}</p>
            <p className={styles.name}>{name}</p>
        </div>
    );
};
