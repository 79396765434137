import React, { ReactNode, useMemo } from "react";
import {
    adaptV4Theme,
    createTheme,
    ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Languages } from "src/types/Languages";
import { ruRU, enUS } from "@mui/material/locale";
import { typography } from "./typography";
import { palette } from "./palette";
import { defaultProps } from "./defaultProps";
import { spacing } from "./spacing";
import { overrides } from "./overrides";
import { breakpoints } from "./breakpoints";

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const { i18n } = useTranslation();

    const themeWithLocale = useMemo(() => {
        const locale = i18n.language === Languages.RU ? ruRU : enUS;
        return createTheme(
            adaptV4Theme({
                spacing,
                typography,
                overrides,
                palette,
                props: defaultProps,
                breakpoints,
            }),
            locale,
        );
    }, [i18n.language]);

    return (
        <MuiThemeProvider theme={themeWithLocale}>{children}</MuiThemeProvider>
    );
};
