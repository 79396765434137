import React, { useState } from "react";
import { Box, styled } from "@mui/material";
import { SmallTableRow } from "../CommonTable/SmallTable/SmallTableRow";
import { MRT_ColumnDef } from "material-react-table";
import color from "src/theme/variables/color";
import { Expander } from "./components/Expander";
import { Selector } from "./components/Selector";

type DataGridMobileRowType<T> = {
    item: T;
    index: number;
    columns: MRT_ColumnDef<T>[];
    onRowSelect?: (arg: { [s: string]: boolean }) => void;
    selectedRows?: { [s: string]: boolean };
    getRowId?: (v: T, idx: number) => string;
    renderRowActions?: (props: any) => React.ReactNode;
    enableExpanding?: boolean;
};

export const DataGridMobileRow = <T,>({
    item,
    index,
    columns,
    onRowSelect,
    selectedRows,
    getRowId,
    enableExpanding,
    renderRowActions,
}: DataGridMobileRowType<T>) => {
    const [isExpanding, setIsExpanding] = useState(false);

    // @ts-ignore
    const subRow = item?.subRow;
    return (
        <Row>
            <ActionsWrapper>
                <Selector
                    rowId={getRowId?.(item, index)}
                    selectedRows={selectedRows}
                    onRowSelect={onRowSelect}
                />

                {enableExpanding && subRow && (
                    <Expander
                        isExpanding={isExpanding}
                        setIsExpanding={setIsExpanding}
                    />
                )}
            </ActionsWrapper>

            <RowContent>
                {isExpanding && (
                    <SubItemWrapper>
                        {subRow?.map((subItem: any) => {
                            return (
                                <SubItem key={subItem?.id}>
                                    {columns.map(c => {
                                        const params = getParams(
                                            c,
                                            item,
                                            subItem,
                                        );

                                        const renderCell =
                                            // @ts-ignore
                                            c.Cell?.(params) ||
                                            params.cellValue;
                                        return !c.AggregatedCell ? null : (
                                            <SmallTableRow
                                                title={c.header}
                                                value={renderCell}
                                                key={c.header}
                                            />
                                        );
                                    })}
                                </SubItem>
                            );
                        })}
                    </SubItemWrapper>
                )}

                {columns.map(c => {
                    const params = getParams(c, item);

                    const renderCell =
                        subRow && c.AggregatedCell
                            ? // @ts-ignore
                              c.AggregatedCell?.(params)
                            : // @ts-ignore
                              c.Cell?.(params) || params.cellValue;

                    return (isExpanding && c.AggregatedCell) ||
                        renderCell === null ? null : (
                        <SmallTableRow
                            title={c.header}
                            value={renderCell}
                            key={c.header}
                        />
                    );
                })}
            </RowContent>
            {renderRowActions && (
                <ActionsWrapper>
                    {renderRowActions({ row: { original: item } })}
                </ActionsWrapper>
            )}
        </Row>
    );
};

const getParams = <T,>(column: MRT_ColumnDef<T>, item: T, subItem?: any) => {
    const accessorKey =
        typeof column.accessorKey === "function"
            ? column.accessorKey()
            : column.accessorKey;

    const cellValue = (subItem || item)[accessorKey];
    const getValue = () => cellValue;
    const original = subItem || item;
    const getParentRow = () => ({ original: subItem ? item : null });
    return {
        cell: { getValue },
        row: { original, getParentRow },
        cellValue,
    };
};

const ActionsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 48px;
    padding: 8px;
`;

const SubItemWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-left: 1px solid ${color.sendFileColor.indicator.gray2};
    padding-left: 8px;
`;

const SubItem = styled(Box)`
    padding: 4px 0;
`;

const Row = styled(Box)`
    display: flex;
    padding: 12px 4px 12px 7px;
    border-bottom: 1px solid #dbdbdb;
`;

const RowContent = styled(Box)`
    width: 100%;
    min-width: 0;
`;
