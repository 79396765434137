import { UseFormProps, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

type YupResolverSchemaType = Parameters<typeof yupResolver>[0];
type formConfig<T> = Omit<UseFormProps<T>, "resolver"> & {
    schema?: YupResolverSchemaType;
};

const useFormCustom = <T,>(formConfig: formConfig<T>) => {
    const config: UseFormProps<T> = {
        mode: "all",
        shouldUnregister: true,
        ...formConfig,
    };

    if (formConfig?.schema) {
        // @ts-ignore
        config["resolver"] = yupResolver(formConfig?.schema);
    }

    const form = useForm<T>(config);

    return { errors: form.formState.errors, ...form };
};

export { useFormCustom as useForm };
