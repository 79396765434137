import React, { useEffect, useMemo } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { makeStyles } from "@mui/styles";
import styleVariables from "../theme/variables";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "./Form";
import {
    closeEditUserDialog,
    editUser,
} from "src/redux/pages/administrationUsers/actions";
import { UserModel } from "src/services/generated/api";
import { Preloader } from "./Preloader";
import { ClosingDialogTitle } from "./ModalWindows/СlosingDialogTitle";

type FormFields = {
    allowUploadOverLimit: boolean;
};

const useStyles = makeStyles({
    content: {
        minWidth: 240,
        paddingRight: styleVariables.spacing * 18,
        textAlign: "start",
    },
    limitPadding: {
        "&.MuiFormControl-root": {
            paddingBottom: 0,
        },
    },
    allowUploadOverLimit: {
        padding: "20px 0",
    },
});

export const EditUserDialog = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const { editDialogUserId, users, updatingUser } = useSelector(
        (store: Store) => store.pages.administration.administrationUsersPage,
    );

    const editedUser: UserModel | undefined = useMemo(() => {
        return users.find(user => user.id === editDialogUserId);
    }, [users, editDialogUserId]);

    const { control, handleSubmit, setValue } = useForm<FormFields>({
        defaultValues: {
            allowUploadOverLimit: editedUser?.allowUploadOverLimit ?? false,
        },
    });

    useEffect(() => {
        setTimeout(() => {
            if (editedUser) {
                setValue(
                    "allowUploadOverLimit",
                    editedUser?.allowUploadOverLimit ?? false,
                );
            }
        });
    }, [editedUser, setValue]);

    const close = () => {
        !updatingUser && dispatch(closeEditUserDialog());
    };

    const save = (data: FormFields) => {
        dispatch(
            editUser({
                ...editedUser,
                allowUploadOverLimit: data.allowUploadOverLimit,
            }),
        );
    };

    const onAllowLoadChange = (event: any, checked: boolean) => {
        setValue("allowUploadOverLimit", checked);
    };

    return (
        <Dialog open={editDialogUserId !== null} onClose={close}>
            {updatingUser && <Preloader over />}
            <ClosingDialogTitle onClose={close}>
                {t("admin-users:editDialog.title")}
            </ClosingDialogTitle>
            <form onSubmit={handleSubmit(save)}>
                <DialogContent className={classes.content}>
                    <Controller
                        name="allowUploadOverLimit"
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                className={classes.allowUploadOverLimit}
                                label={t(
                                    "admin-users:editForm.allowUploadOverLimit",
                                )}
                                control={
                                    <Checkbox
                                        onChange={onAllowLoadChange}
                                        checked={field.value}
                                        color="primary"
                                        ref={field.ref}
                                    />
                                }
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("admin-users:editDialog.cancel")}
                    </Button>
                    <Button type="submit" color="primary">
                        {t("admin-users:editDialog.save")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
