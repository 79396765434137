const sendFileColor = {
    primary: {
        light: "#5969B3",
        main: "#6577CB",
        dark: "#4C5A99",
    },
    secondary: {
        light: "#FFFFFF",
        main: "#EDF0FC",
        dark: "#E6EAFB",
    },
    disabled: "#CCCCCC",
    background: {
        grayTea: "#EFEFF4",
        lightGray: "#F7F7F7",
        linen: "#F5F1E3",
        transparentWhite: "rgba(236, 236, 236, 0.2)",
    },
    surface: {
        white: "#FFFFFF",
        gray: "#EBEBEB",
        grayAsparagus: "#D6D6D6",
    },
    alert: {
        orange: "#FF5722",
        green: "#4CAF50",
    },
    onSecondary: {
        text: "#1A1F29",
    },
    indicator: {
        green: "#D5E8E4",
        ivory: "#F2FFE8",
        blue: "#D9F3FE",
        lavender: "#E7E4FF",
        blush: "#FFE9FA",
        red: "#FFE9E7",
        orange: "#FFF2E0",
        cream: "#FFF2E0",
        gray: "rgba(26, 31, 41, 0.6)",
        gray2: "#E7E7E7",
    },
};

export const color = {
    primary: {
        ...sendFileColor.primary,
    },
    secondary: {
        ...sendFileColor.secondary,
    },
    disabled: sendFileColor.disabled,
    // selectedTab: '#EFEFF4',
    warning: {
        light: sendFileColor.alert.orange,
        main: sendFileColor.alert.orange,
        dark: sendFileColor.alert.orange,
    },
    success: {
        light: sendFileColor.alert.green,
        main: sendFileColor.alert.green,
        dark: sendFileColor.alert.green,
    },
    sendFileColor,
};

export default color;
