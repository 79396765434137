import React, { useEffect, useState } from "react";
import { AppBar, Button, Link, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { NavLinks } from "./NavLinks";
import Grid from "@mui/material/Grid";
import { UserMenu } from "./UserMenu";
import { LanguageHorizontalSwitcher } from "./LanguageHorizontalSwitcher";
import { Logo } from "./Logo";
import { ReactComponent as OpenMobileMenuIcon } from "src/icons/header/openMenuMobileIcon.svg";
import { LeftMenu } from "./LeftMenu";
import { Languages } from "src/types/Languages";
import { MobileLogo } from "./MobileLogo";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../types/redux/store";
import { PrivateSettings } from "../types/redux/store/PrivateSettings";
import { DownloadMenu } from "./DownloadMenu";
import { getPrivateSettings } from "src/redux/pages/applicationSettings/actions";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: 2,
        },
        container: {
            paddingLeft: "32px",
            paddingRight: "32px",
            [theme.breakpoints.up("lg")]: {
                justifyContent: "space-between",
            },
            [theme.breakpoints.down("lg")]: {
                paddingLeft: "24px",
                paddingRight: "24px",
            },
            [theme.breakpoints.down("xs")]: {
                paddingLeft: "19px",
                paddingRight: "19px",
            },
        },
        navLinks: {
            paddingRight: "35px",
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        logoContainer: {
            maxWidth: "150px",
            maxHeight: "40px",
            marginRight: "30px",
            [theme.breakpoints.down("xs")]: {
                maxWidth: "88px",
                maxHeight: "22px",
            },
        },
        logo: {
            width: "100%",
            height: "100%",
            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },
        mobileLogo: {
            display: "none",
            [theme.breakpoints.down("xs")]: {
                display: "unset",
            },
        },
        horizontalLanguageSwitchers: {
            marginBottom: "-2px",
            paddingRight: "32px",
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        mobileMenuButton: {
            marginRight: "32px",
            width: "24px",
            [theme.breakpoints.up("lg")]: {
                display: "none",
            },
        },
        menuButton: {
            minWidth: "24px",
            padding: "11px 0",
        },
        rightPart: {
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        link: {
            width: "150px",
            paddingRight: "32px",
            marginBottom: "4px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: "right",
        },
        downloadMenu: {
            marginRight: "24px",
        },
        downloadMenuMobile: {
            display: "none",
            [theme.breakpoints.down("lg")]: {
                display: "block",
            },
        },
    }),
);

const languages = Object.values(Languages);

export function Header(props: any) {
    const styles = useStyle();
    const [isLeftMenuOpen, setIsLeftMenuOpen] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPrivateSettings());
    }, [dispatch]);

    const settings = useSelector<Store, PrivateSettings>(
        store => store?.privateSettings,
    );

    const toggleLeftMenu = () => {
        setIsLeftMenuOpen(!isLeftMenuOpen);
    };

    const closeLeftMenu = () => {
        setIsLeftMenuOpen(false);
    };

    return (
        <AppBar
            className={styles.appBar}
            elevation={1}
            position="relative"
            color="inherit"
        >
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                className={styles.container}
            >
                <Grid item flexShrink={0}>
                    <Grid container wrap="nowrap" alignItems="center">
                        <Grid item className={styles.mobileMenuButton}>
                            <Button
                                variant="text"
                                className={styles.menuButton}
                                onClick={toggleLeftMenu}
                            >
                                <OpenMobileMenuIcon />
                            </Button>
                        </Grid>
                        <Grid item className={styles.logoContainer}>
                            <Logo className={styles.logo} />
                            <MobileLogo className={styles.mobileLogo} />
                        </Grid>
                        <Grid item className={styles.navLinks}>
                            <NavLinks />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className={styles.downloadMenuMobile}>
                    <DownloadMenu />
                </Grid>
                <Grid item zeroMinWidth className={styles.rightPart}>
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid item className={styles.downloadMenu}>
                            <DownloadMenu />
                        </Grid>
                        {settings.appPreviousVersionData && (
                            <Grid item className={styles.link}>
                                <Tooltip
                                    title={
                                        settings.appPreviousVersionData
                                            .prevSendFileName || ""
                                    }
                                    placement="bottom-start"
                                >
                                    <Link
                                        href={
                                            settings.appPreviousVersionData
                                                .prevSendFileUrl
                                        }
                                    >
                                        {
                                            settings.appPreviousVersionData
                                                .prevSendFileName
                                        }
                                    </Link>
                                </Tooltip>
                            </Grid>
                        )}
                        <Grid
                            item
                            className={styles.horizontalLanguageSwitchers}
                        >
                            <LanguageHorizontalSwitcher languages={languages} />
                        </Grid>
                        <Grid flexShrink={2} zeroMinWidth item>
                            <UserMenu />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <LeftMenu isOpen={isLeftMenuOpen} onClose={closeLeftMenu} />
        </AppBar>
    );
}
