import {
    StorageSettingsDTO,
    EndingOrExpiredLicenseMailHeadersDTO,
    ExtendMessageMailHeadersDTO,
    GeneralSettingsDTO,
    LdapSettingsDTO,
    LicenseInfoDTO,
    MailHeadersDTO,
    MailSettingsDTO,
    LogFileSettings,
    NotificationAboutDeletingMailHeadersDTO,
    UserExpireSettingsDTO,
} from "src/services/generated";

export interface AdministrationSettingsBasePage<T> {
    form?: T;
    loading: boolean;
}

export interface MessageTopic {
    request: string;
    send: string;
    response: string;
}

export enum SimpleCronPeriodType {
    DAY = "DAY",
    WEEk = "WEEK",
    MONTH = "MONTH",
    YEAR = "YEAR",
}

export interface StorageSettingsForm extends StorageSettingsDTO {
    simpleCron_checkFreeSpace_period?: SimpleCronPeriodType;
    simpleCron_checkFreeSpace_time?: string;

    simpleCron_deleteFiles_period?: SimpleCronPeriodType;
    simpleCron_deleteFiles_time?: string;
}

export interface AdministrationSettingsThemeAndLogo {
    internationalMessageSettings?: MailHeadersDTO;
    extendMessage?: ExtendMessageMailHeadersDTO;
    workMode?: string;
    encryption?: boolean;
    autoDeactivationInDays?: number;
    ldap?: LdapSettingsDTO;
    mail?: MailSettingsDTO;
    general?: StorageSettingsDTO;
}

export interface AdministrationSettings {
    generalSettingsPage: GeneralSettingsPage;
    logFileSettingsPage: LogFileSettingsPage;
    mailSettingsPage: MailSettingsPage;
    ldapSettingsPage: LdapSettingsPage;
    mailHeadersSettingsPage: MailHeadersSettingsPage;
    licenseExpiredMessageSettingsPage: LicenseExpiredMessageSettingsPage;
    extendMessageSettingsPage: ExtendMessageSettingsPage;
    deletedMessageSettingsPage: DeletedMessageSettingsPage;
    licenseSettingsPage: LicenseSettingsPage;
    storageSettingsPage: StorageSettingsPage;
    userDeactivationSettingsPage: UserDeactivationSettingsPage;
}

export type GeneralSettingsPage =
    AdministrationSettingsBasePage<GeneralSettingsDTO>;
export type LogFileSettingsPage =
    AdministrationSettingsBasePage<LogFileSettings>;
export type LdapSettingsPage = AdministrationSettingsBasePage<LdapSettingsDTO>;
export type MailSettingsPage = AdministrationSettingsBasePage<MailSettingsDTO>;
export type MailHeadersSettingsPage =
    AdministrationSettingsBasePage<MailHeadersDTO>;
export type LicenseExpiredMessageSettingsPage =
    AdministrationSettingsBasePage<EndingOrExpiredLicenseMailHeadersDTO>;
export type ExtendMessageSettingsPage =
    AdministrationSettingsBasePage<ExtendMessageMailHeadersDTO>;
export type DeletedMessageSettingsPage =
    AdministrationSettingsBasePage<NotificationAboutDeletingMailHeadersDTO>;
export type LicenseSettingsPage =
    AdministrationSettingsBasePage<LicenseInfoDTO>;
export type StorageSettingsPage =
    AdministrationSettingsBasePage<StorageSettingsForm>;
export type UserDeactivationSettingsPage =
    AdministrationSettingsBasePage<UserExpireSettingsDTO>;
