import { useState, useEffect } from "react";
import axios from "axios";

export const useNetwork = () => {
    const [isOnline, setOnline] = useState(window.navigator.onLine);
    useEffect(() => {
        const handleStatusChange = () => {
            setOnline(window.navigator.onLine);
        };

        axios.interceptors.request.use(config => {
            handleStatusChange();
            return config;
        });

        window.addEventListener("online", handleStatusChange);

        return () => {
            window.removeEventListener("online", handleStatusChange);
        };
    }, []);

    return isOnline;
};
