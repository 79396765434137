import styleVariables from "../variables";

export const MuiAutocomplete = {
    tag: {
        marginLeft: 0,
        marginRight: 6,
    },
    inputRoot: {
        paddingLeft: styleVariables.spacing * 2,
    },
    endAdornment: {
        marginTop: "-4px",
    },
};
