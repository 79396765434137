import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { enqueueError, enqueueSuccess } from "src/helpers/enqueueError";
import i18n from "src/i18n";
import { LicenseInfoDTO } from "src/services/generated";
import { LicenseSettingsPage } from "src/types/redux/store/pages/administration/administrationnSettings/AdministrationSettings";
import { getLicenseSettings, updateLicenseSettings } from "./actions";

const licenseSettingsInitialState: LicenseSettingsPage = {
    loading: false,
};

export const licenseSettingsReducer = createReducer<LicenseSettingsPage>(
    licenseSettingsInitialState,
    {
        [getLicenseSettings.pending.type](state) {
            state.loading = true;
        },
        [getLicenseSettings.fulfilled.type](
            state,
            action: PayloadAction<LicenseInfoDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
        },
        [getLicenseSettings.rejected.type](state) {
            state.loading = false;
        },
        [updateLicenseSettings.pending.type](state) {
            state.loading = true;
        },
        [updateLicenseSettings.fulfilled.type](
            state,
            action: PayloadAction<LicenseInfoDTO>,
        ) {
            state.loading = false;
            state.form = action.payload;
            enqueueSuccess(i18n.t("admin-settings:success.edit"));
        },
        [updateLicenseSettings.rejected.type](state) {
            state.loading = false;
            enqueueError(i18n.t("admin-settings:error.edit"));
        },
    },
);
