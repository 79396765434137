import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../types/redux/store";
import { Switchers, TabType } from "../Switchers";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { FilesRequestType } from "../../types/redux/store/pages/FilesRequestPage";
import { FilesRequestByLink } from "./FilesRequestByLink";
import { FilesRequestByEmail } from "./FilesRequestByEmail";
import { changeRequestType } from "../../redux/pages/filesRequest/actions";

const useStyle = makeStyles({
    wrapper: {
        overflow: "hidden",
        paddingBottom: "50px",
    },
    formHeader: {
        fontSize: "16px",
        fontWeight: "bold",
        lineHeight: "19px",
        color: "#1A1F29",
        marginBottom: "24px",
    },
    tabFont: {
        textTransform: "none",
        fontSize: "14px",
        lineHeight: "16px",
    },
    switcherContainer: {
        marginBottom: "34px",
    },
    noteText: {
        marginTop: "56px",
        marginBottom: "40px",
        color: "#4C5A99",
        fontSize: "16px",
    },
});

const tabs: TabType[] = [
    {
        label: "request-files:tab.byLink",
        value: FilesRequestType.BY_LINK,
    },
    {
        label: "request-files:tab.byEmail",
        value: FilesRequestType.BY_EMAIL,
    },
];

export const FilesRequestForm: FC = () => {
    const { t } = useTranslation();
    const requestType = useSelector(
        (store: Store) => store.pages.filesRequestPage.requestType,
    );
    const dispatch = useDispatch();
    const styles = useStyle();

    const handleFilesRequestTypeSwitch = () => {
        const newRequestType =
            requestType === FilesRequestType.BY_LINK
                ? FilesRequestType.BY_EMAIL
                : FilesRequestType.BY_LINK;
        dispatch(changeRequestType(newRequestType));
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.noteText}>{t("request-files:note")}</div>
            <div className={styles.formHeader}>
                {t("request-files:sendFileRequest")}
            </div>
            <div className={styles.switcherContainer}>
                <Switchers
                    tabClassName={styles.tabFont}
                    onChange={handleFilesRequestTypeSwitch}
                    value={requestType}
                    tabs={tabs}
                />
            </div>
            {requestType === FilesRequestType.BY_LINK && <FilesRequestByLink />}
            {requestType === FilesRequestType.BY_EMAIL && (
                <FilesRequestByEmail />
            )}
        </div>
    );
};
