import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
    Box,
    Drawer,
    Grid,
    Link as MuiLink,
    Tooltip,
    Accordion,
    AccordionSummary,
    List,
    ListItemButton,
    AccordionDetails,
} from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import { NavLinkButton } from "src/types/NavLinkButton";
import { routePaths } from "src/routes/routePaths";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageHorizontalSwitcher } from "./LanguageHorizontalSwitcher";
import { Languages } from "src/types/Languages";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { DropDownMenuItem, DropDownMenu } from "./DropDownMenu";
import { logOut } from "src/redux/pages/login/action";
import { ReactComponent as MenuOpenBlackIcon } from "src/icons/menu_open-black.svg";
import { UserModelRolesEnum } from "../services/generated";
import { PrivateSettings } from "../types/redux/store/PrivateSettings";
import { getVersionInfo } from "../redux/version/actions";
import { Theme } from "@mui/material/styles";
import { MobileLogo } from "./MobileLogo";
import { color } from "../theme/variables/color";
import { About } from "./About";
import { ExpandMore } from "@mui/icons-material";
import { getPrivateSettings } from "src/redux/pages/applicationSettings/actions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuBody: {
            overflowY: "auto",
            height: "100%",
            boxShadow: "0 0 10px #0003",
        },
        menuFooter: {
            display: "none",
            [theme.breakpoints.down("xs")]: {
                display: "block",
            },
        },
        navMenu: {
            display: "flex",
            flexDirection: "column",
            padding: "4px 0",
        },
        navItem: {
            display: "flex",
            alignItems: "center",
            minHeight: "40px",
            opacity: 1,
            textTransform: "none",
            lineHeight: "16px",
            fontSize: "14px",
            letterSpacing: "normal",
            padding: "6px 16px",
            margin: 0,
            "&.Mui-selected": {
                backgroundColor: color.sendFileColor.background.grayTea,
                borderLeft: `2px solid ${color.primary.main}`,
            },
        },
        navItemAdmin: {
            fontWeight: 700,
        },
        userMenu: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            "& p": {
                margin: 0,
            },
            paddingTop: "10px",
        },
        languageSwitcher: {
            maxWidth: "68px",
            width: "100%",
            marginRight: "20px",
            "& button": {
                height: "28px",
            },
        },
        link: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        version: {
            fontSize: "12px",
            fontWeight: 400,
            textAlign: "left",
            padding: "12px 16px",
            width: "100%",
        },
        userName: {
            marginRight: "8px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
        containerUser: {
            minHeight: "32px",
        },
        accordion: {
            boxShadow: "none",
            padding: 0,
            "&.Mui-expanded": {
                margin: 0,
            },
            "&:before": {
                display: "none",
            },
            "& .MuiButtonBase-root": {
                minHeight: "40px",
            },
        },
        drawerTitle: {
            display: "flex",
            flexDirection: "column",
            padding: "20px 16px 12px",
            boxSizing: "border-box",
            marginBottom: "0px",
            fontWeight: 400,
        },
    }),
);

const StyledDrawer = withStyles((theme: Theme) => ({
    paper: {
        width: "280px",
        [theme.breakpoints.up("xs")]: {
            width: "400px",
        },
        maxHeight: "100%",
        height: "100%",
        maxWidth: "100%",
        margin: "0",
        borderRadius: "0",
        padding: "0",
        "& p": {
            margin: "0",
        },
    },
}))(Drawer);

const StyledAccordionSummary = withStyles({
    root: {
        "& .css-o4b71y-MuiAccordionSummary-content": {
            margin: 0,
        },
    },
})(AccordionSummary);

const StyledExpandMore = withStyles({
    root: {
        color: color.sendFileColor.onSecondary.text,
        width: 24,
        height: 24,
    },
})(ExpandMore);

interface LeftMenuProps {
    isOpen: boolean;
    onClose(): void;
}

const navButtons: NavLinkButton[] = [
    { name: "common:header.sendFile", url: routePaths.sendFile },
    {
        name: "common:header.requestFile",
        url: routePaths.requestFile,
        role: UserModelRolesEnum.STANDARD_USER,
    },
    {
        name: "common:header.myFile",
        url: routePaths.myFiles,
        role: UserModelRolesEnum.STANDARD_USER,
    },
    {
        name: "common:header.administration",
        url: routePaths.administration,
        role: UserModelRolesEnum.ADMIN,
        accordion: [
            {
                name: "admin:pages.statistics",
                url: routePaths.administrationTemplate.statistics,
            },
            {
                name: "admin:pages.users",
                url: routePaths.administrationTemplate.users,
            },
            {
                name: "admin:pages.reports",
                url: routePaths.administrationTemplate.reports,
            },
            {
                name: "admin:pages.files",
                url: routePaths.administrationTemplate.files,
            },
            {
                name: "admin:pages.storages",
                url: routePaths.administrationTemplate.storages,
            },
            {
                name: "admin:pages.logFiles",
                url: routePaths.administrationTemplate.logFiles,
            },
            {
                name: "admin:pages.settings",
                url: "",
                role: UserModelRolesEnum.ADMIN,
                accordion: [
                    {
                        name: "admin:pages.settingsMenu.general",
                        url: routePaths.administrationTemplate.settings.general,
                        role: UserModelRolesEnum.ADMIN,
                    },
                    {
                        name: "admin:pages.settingsMenu.logo",
                        url: routePaths.administrationTemplate.settings.logo,
                        role: UserModelRolesEnum.ADMIN,
                    },
                    {
                        name: "admin:pages.settingsMenu.storage",
                        url: routePaths.administrationTemplate.settings.storage,
                        role: UserModelRolesEnum.ADMIN,
                    },
                    {
                        name: "admin:pages.settingsMenu.ldap",
                        url: routePaths.administrationTemplate.settings.ldap,
                        role: UserModelRolesEnum.ADMIN,
                    },
                    {
                        name: "admin:pages.settingsMenu.email",
                        url: routePaths.administrationTemplate.settings.email,
                        role: UserModelRolesEnum.ADMIN,
                    },
                    {
                        name: "admin:pages.settingsMenu.texts",
                        url: "",
                        role: UserModelRolesEnum.ADMIN,
                        accordion: [
                            {
                                name: "admin:pages.settingsMenu.textsMessages.emailMessage",
                                url: routePaths.administrationTemplate.settings
                                    .textsMessages.emailMessage,
                                role: UserModelRolesEnum.ADMIN,
                            },
                            {
                                name: "admin:pages.settingsMenu.textsMessages.extendMessage",
                                url: routePaths.administrationTemplate.settings
                                    .textsMessages.extendMessage,
                                role: UserModelRolesEnum.ADMIN,
                            },
                            {
                                name: "admin:pages.settingsMenu.textsMessages.deleteMessage",
                                url: routePaths.administrationTemplate.settings
                                    .textsMessages.deleteMessage,
                                role: UserModelRolesEnum.ADMIN,
                            },
                            {
                                name: "admin:pages.settingsMenu.textsMessages.licenseMessage",
                                url: routePaths.administrationTemplate.settings
                                    .textsMessages.licenseMessage,
                                role: UserModelRolesEnum.ADMIN,
                            },
                        ],
                    },
                    {
                        name: "admin:pages.settingsMenu.licences",
                        url: routePaths.administrationTemplate.settings
                            .licences,
                        role: UserModelRolesEnum.ADMIN,
                    },
                    {
                        name: "admin:pages.settingsMenu.logFiles",
                        url: routePaths.administrationTemplate.settings
                            .logFiles,
                        role: UserModelRolesEnum.ADMIN,
                    },
                    {
                        name: "admin:pages.settingsMenu.deactivation",
                        url: routePaths.administrationTemplate.settings
                            .deactivation,
                        role: UserModelRolesEnum.ADMIN,
                    },
                ],
            },
        ],
    },
];

const footerButtons: NavLinkButton[] = [
    { name: "common:footer.aboutSendFile", url: routePaths.about },
    { name: "common:footer.aboutArtezio", url: routePaths.aboutArtezio },
    { name: "common:footer.support", url: routePaths.support },
];

const languages = Object.values(Languages);

const OuterLink = React.forwardRef((props: any, ref: any) => {
    return (
        <a
            href={props.to}
            target="_blank"
            rel="noopener noreferrer"
            ref={ref}
            {...props}
        >
            {props.children}
        </a>
    );
});

export const LeftMenu = (props: LeftMenuProps) => {
    const { isOpen: isOpenMenu, onClose: onCloseMenu } = props;
    const [isOpenAbout, setIsOpenAbout] = useState(false);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const { user, accessToken } = useSelector(
        (store: Store) => store.authenticationHandler.authentication,
    );
    const settings = useSelector<Store, PrivateSettings>(
        store => store?.privateSettings,
    );
    const { appVersionInfo } = useSelector(
        (store: Store) => store.appVersionInfo,
    );
    const userRoles = user?.roles;

    useEffect(() => {
        dispatch(getVersionInfo());
        dispatch(getPrivateSettings());
    }, [dispatch]);
    const version = appVersionInfo?.branch.split("/");

    const closeMenu = useCallback(() => {
        onCloseMenu();
    }, [onCloseMenu]);

    const onCloseAbout = useCallback(() => {
        setIsOpenAbout(false);
    }, []);

    const onOpenAbout = useCallback(() => {
        setIsOpenAbout(true);
        onCloseMenu();
    }, [onCloseMenu]);

    const createNavButtons = useCallback(
        (button: NavLinkButton[]) => {
            return button.map(({ url, name, accordion }) => {
                if (url === routePaths.about) {
                    return (
                        <ListItemButton
                            onClick={onOpenAbout}
                            className={styles.navItem}
                            component="button"
                            key={url}
                        >
                            {t(name)}
                        </ListItemButton>
                    );
                }
                let component;
                if (url === routePaths.aboutArtezio) {
                    component = OuterLink;
                } else {
                    component = Link;
                }
                if (accordion) {
                    return (
                        <Accordion key={url} className={styles.accordion}>
                            <StyledAccordionSummary
                                sx={[url === routePaths.administration]}
                                expandIcon={<StyledExpandMore />}
                                className={`${styles.navItem} ${
                                    url === routePaths.administration &&
                                    styles.navItemAdmin
                                }`}
                            >
                                {t(name)}
                            </StyledAccordionSummary>
                            <AccordionDetails sx={{ padding: "0 0 0 4px" }}>
                                {createNavButtons(accordion)}
                            </AccordionDetails>
                        </Accordion>
                    );
                }

                return (
                    <ListItemButton
                        onClick={closeMenu}
                        key={url}
                        selected={url === pathname}
                        className={styles.navItem}
                        component={component}
                        to={url}
                    >
                        {t(name)}
                    </ListItemButton>
                );
            });
        },
        [closeMenu, onOpenAbout, pathname, styles, t],
    );

    const items: DropDownMenuItem[] = [
        {
            title: t("common:exit"),
            onClick() {
                dispatch(logOut());
            },
        },
    ];

    const navButtonsElems = useMemo(() => {
        const filteredButtons = navButtons.filter(
            button =>
                !button.hasOwnProperty("role") ||
                userRoles?.includes(button.role!),
        );
        return accessToken && createNavButtons(filteredButtons);
    }, [createNavButtons, accessToken, userRoles]);

    const footerButtonsElems = useMemo(() => {
        const filteredButtons = accessToken
            ? footerButtons
            : footerButtons.filter(button => button.url !== routePaths.support);
        return createNavButtons(filteredButtons);
    }, [createNavButtons, accessToken]);

    return (
        <>
            <StyledDrawer
                onClose={onCloseMenu}
                open={isOpenMenu}
                ModalProps={{ keepMounted: true }}
            >
                <Box className={styles.drawerTitle}>
                    <Grid
                        container
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="center"
                    >
                        <MobileLogo />
                        {settings.appPreviousVersionData && (
                            <Tooltip
                                title={
                                    settings.appPreviousVersionData
                                        .prevSendFileName || ""
                                }
                                placement="bottom-start"
                            >
                                <MuiLink
                                    href={
                                        settings.appPreviousVersionData
                                            .prevSendFileUrl
                                    }
                                    className={styles.link}
                                >
                                    {
                                        settings.appPreviousVersionData
                                            .prevSendFileName
                                    }
                                </MuiLink>
                            </Tooltip>
                        )}
                    </Grid>

                    <Grid
                        container
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="center"
                        marginTop="39px"
                    >
                        <LanguageHorizontalSwitcher
                            languages={languages}
                            className={styles.languageSwitcher}
                        />
                        <Grid
                            className={styles.containerUser}
                            container
                            item
                            justifyContent="flex-end"
                            alignItems="center"
                            wrap="nowrap"
                            zeroMinWidth
                        >
                            <span className={styles.userName}>
                                {(user && user.firstName) ||
                                    (user && user.email) ||
                                    t("common:guest")}
                            </span>
                            <DropDownMenu
                                items={items}
                                anchorElement={MenuOpenBlackIcon}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box className={styles.menuBody}>
                    <List component="nav" className={styles.navMenu}>
                        {navButtonsElems}
                    </List>
                </Box>
                <Box className={styles.menuFooter}>
                    <List component="nav" className={styles.navMenu}>
                        {footerButtonsElems}
                    </List>
                </Box>

                <Box className={styles.version}>
                    {t("common:footer.version")}
                    {version?.[1] || version?.[0]}
                    {t("common:footer.copyrightMark")}
                    {", "}
                    {new Date().getFullYear()} {t("common:footer.rights")}
                </Box>
            </StyledDrawer>
            <About isOpen={isOpenAbout} onClose={onCloseAbout} />
        </>
    );
};
