import { Chip } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import {
    DataGrid,
    DataGridProps,
    DatagridColumn,
} from "src/components/DataGrid";
import {
    ReportsFileDTO,
    ReportsFileDTOStatusEnum,
} from "src/services/generated";
import { commonDateTimeFormat } from "src/constants/momentFormats";
import { prettyBytes } from "src/helpers/prettyBytes";
import { ShowOnlySelected } from "./utils";
import { TextEllipsis } from "src/components/TextEllipsis";
import { styled, Box } from "@mui/material";
import color from "src/theme/variables/color";

type AdminFilesReportsTableProps = {
    onPaginationChange: DataGridProps<ReportsFileDTO>["onPaginationChange"];
    onSortingChange: DataGridProps<ReportsFileDTO>["onSortingChange"];
    onRowSelectionChange: DataGridProps<ReportsFileDTO>["onRowSelectionChange"];
    onOpenFilterDialog: () => void;
    table: {
        state: DataGridProps<ReportsFileDTO>["state"];
        rowCount?: number;
        data?: ReportsFileDTO[];
    };
    allData?: {
        [k: number]: ReportsFileDTO[];
    };
    setActiveReportFile: React.Dispatch<ReportsFileDTO>;
};
export const AdminFilesReportsTable = ({
    onPaginationChange,
    onRowSelectionChange,
    onSortingChange,
    onOpenFilterDialog,
    table,
    allData,
    setActiveReportFile,
}: AdminFilesReportsTableProps) => {
    const { t } = useTranslation("admin-reports");
    const [isShowSelected, setIsShowSelected] = useState(false);
    const selectedKeys = Object.keys(table.state.rowSelection);
    const { filteredData } = ShowOnlySelected.useSelected({
        allData,
        getItemId,
        selectedKeys,
    });

    return (
        <>
            {/* TODO: Добавить кнопки экспорта после реализации на беке */}
            {/* <Grid
        item
        container
        xs={12}
        sm="auto"
        alignItems="stretch"
        alignContent="flex-start"
        zeroMinWidth
        spacing={4}
        marginBottom={6}
      >
        <Grid item xs={12} sm="auto">
          <Button
            fullWidth
            color="primary"
            startIcon={<Report fill="white" />}
            onClick={() => {
              console.log("export selected");
            }}
          >
            {t("exportSelected")}
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button
            fullWidth
            startIcon={<Report />}
            color="secondary"
            onClick={() => console.log("export all")}
          >
            {t("exportAll")}
          </Button>
        </Grid>
      </Grid> */}
            <ShowOnlySelected setIsShowSelected={setIsShowSelected} />
            <DataGrid<ReportsFileDTO>
                getRowId={(row, idx) => {
                    if (!row.reportId) return idx.toString();
                    return getItemId(row);
                }}
                data={isShowSelected ? filteredData : table.data}
                rowCount={isShowSelected ? filteredData.length : table.rowCount}
                columns={getColumns(t, setActiveReportFile)}
                enableGrouping
                enableHiding
                enableRowSelection
                manualPagination
                onRowSelectionChange={onRowSelectionChange}
                state={table.state}
                onPaginationChange={onPaginationChange}
                onSortingChange={onSortingChange}
                onOpenFilterDialog={onOpenFilterDialog}
            />
        </>
    );
};

const getItemId = (item: ReportsFileDTO) => item.reportId.toString();

const getColumns: (
    t: TFunction,
    setActiveReportFile: React.Dispatch<ReportsFileDTO>,
) => DatagridColumn<ReportsFileDTO>[] = (t, setActiveReportFile) => [
    {
        accessorKey: "senderEmail",
        header: t("tableFiles.headers.email"),
        Cell: ({ cell, row }) => {
            return (
                (cell.getValue() as string) ||
                t("notAuthorizedUser", { userIp: row.original.userIp })
            );
        },
    },
    {
        accessorKey: "action",
        header: t("tableFiles.headers.action"),
        Cell: ({ cell }) => {
            return (
                <>
                    {t(
                        `filterFilesForm.labels.actions.options.${cell.getValue()}`,
                    )}
                </>
            );
        },
        enableSorting: false,
    },
    {
        accessorKey: "emailCreatorLink",
        header: t("tableFiles.headers.comment"),
        Cell: ({ cell }) => {
            const email = cell.getValue() as string;
            return (
                email && (
                    <TextEllipsis text={t("tableFiles.comment", { email })} />
                )
            );
        },
        enableSorting: false,
    },
    {
        accessorKey: "messageSubject",
        header: t("tableFiles.headers.theme"),
        Cell: ({ cell }) => {
            return <TextEllipsis text={cell.getValue() as string} />;
        },
    },
    {
        accessorKey: "messageBody",
        header: t("tableFiles.headers.message"),
        Cell: ({ cell }) => {
            return <TextEllipsis text={cell.getValue() as string} />;
        },
    },
    {
        accessorKey: "fileName",
        header: t("tableFiles.headers.file"),
        Cell: ({ cell, row }) => {
            const fileName = cell.getValue() as string;
            return (
                fileName && (
                    <OpenModalLink
                        onClick={() => setActiveReportFile(row.original)}
                    >
                        <TextEllipsis text={fileName} />
                    </OpenModalLink>
                )
            );
        },
        enableSorting: false,
    },
    {
        accessorKey: "fileSize",
        header: t("tableFiles.headers.size"),
        Cell: ({ cell }) => {
            const fileSize = cell.getValue();
            return fileSize && prettyBytes(Number(fileSize));
        },
        enableSorting: false,
    },
    {
        accessorKey: "date",
        header: t("tableFiles.headers.date"),
        Cell: ({ cell }) => {
            return (
                <>
                    {dayjs(cell.getValue().toString()).format(
                        commonDateTimeFormat(),
                    )}
                </>
            );
        },
    },
    {
        accessorKey: "status",
        header: t("tableFiles.headers.status"),
        Cell: ({ cell }) => {
            return cell.getValue() === ReportsFileDTOStatusEnum.AUTHORIZED ? (
                <Chip
                    color="success"
                    label={t(`tableFiles.recipientStatus.${cell.getValue()}`)}
                />
            ) : null;
        },
    },
];

const OpenModalLink = styled(Box)`
    color: ${color.primary.main};
    cursor: pointer;
`;
