import React, { useState } from "react";
import { ClosingDialogTitle } from "../СlosingDialogTitle";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Tooltip,
    styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { EmptyFileInput } from "src/components/SendFilePage/EmptyFileInput";
import FileDisplayContainer from "src/components/FileDisplayContainer/FileDisplayContainer";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "src/types/redux/store";
import { Encrypted } from "src/services/generated";
import {
    addFiles,
    deleteAddedFile,
    removeWrongFiles,
    setAddFilesUploadId,
    setAddedFiles,
} from "src/redux/pages/myFiles/actions";
import FileDisplay from "src/components/FileDisplay/FileDisplay";
import { isFiledFile } from "src/helpers/file";
import { FileViewModel } from "src/types/FileDisplay";
import { openDialog } from "src/redux/dialogs/commonDialog/actions";
import { deleteFile } from "src/redux/pages/sendFile/actions";
import { FileUploadFailedWindow } from "../FileUploadFailedWindow";
import { getTooltipText } from "src/helpers/getTooltipText";

enum AddFilesState {
    EMPTY_INPUT,
    FILES_IN_PROGRESS,
}

export const DialodAddFiles = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filesState, setFilesState] = useState<AddFilesState>(
        AddFilesState.EMPTY_INPUT,
    );

    const addedFilesIds = useSelector(
        (store: Store) => store.pages.myFilesPage.addedFilesIds,
    );
    const addFilesUploadId = useSelector(
        (store: Store) => store.pages.myFilesPage.addFilesUploadId,
    );
    const uploads = useSelector(
        (store: Store) => store.pages.myFilesPage.uploads,
    );

    const encryptionState = useSelector<Store, Encrypted>(
        store => store.publicSettings.encryptionState,
    );
    const dialog = useSelector(
        (store: Store) => store.dialogs.commonDialog.dialog,
    );

    const addedFiles =
        uploads
            .find(upload => upload.id === addFilesUploadId)
            ?.files.filter(file => addedFilesIds.includes(file.id)) ?? [];

    const closeAddFilesDialog = () => {
        const isFailedFiles = addedFiles?.some(isFiledFile);
        if (isFailedFiles) {
            dispatch(openDialog("FileUploadFailedWindow"));
            return;
        }
        setFilesState(AddFilesState.EMPTY_INPUT);
        dispatch(setAddFilesUploadId(null));
        dispatch(setAddedFiles([]));
    };

    const onCloseAddFilesByBackdropClick = () => {
        if (filesState === AddFilesState.EMPTY_INPUT) {
            closeAddFilesDialog();
        }
    };

    const cancelAddFiles = () => {
        addedFiles.forEach(file => dispatch(deleteAddedFile(file.id)));
        closeAddFilesDialog();
    };

    const closeFileUploadFailedWindow = () => {
        dispatch(removeWrongFiles());
        addedFiles.forEach(file => {
            if (isFiledFile(file)) {
                dispatch(deleteFile({ messageId: addFilesUploadId, file }));
            }
        });
        dispatch(setAddFilesUploadId(null));
        setFilesState(AddFilesState.EMPTY_INPUT);
        dispatch(setAddedFiles([]));
    };

    const onDeleteFile = (fileToDelete: FileViewModel) => {
        dispatch(deleteAddedFile(fileToDelete.id));
    };

    const onFileUpload = (files: File[]) => {
        setFilesState(AddFilesState.FILES_IN_PROGRESS);
        dispatch(
            addFiles({
                files: files,
                encrypted: encryptionState,
            }),
        );
    };

    const tooltipText = t(getTooltipText(addedFiles));

    return (
        <>
            <DialogStyles
                open={addFilesUploadId !== null}
                onClose={onCloseAddFilesByBackdropClick}
            >
                <ClosingDialogTitle onClose={closeAddFilesDialog}>
                    {t("my-file:addFilesDialod.title")}
                </ClosingDialogTitle>
                <DialogContentStyles>
                    {filesState === AddFilesState.EMPTY_INPUT && (
                        <EmptyFileInput onFileUpload={onFileUpload} />
                    )}
                    {filesState === AddFilesState.FILES_IN_PROGRESS && (
                        <FileDisplayContainer
                            onUploadFiles={onFileUpload}
                            height={241}
                            downloadedInfo={{
                                downloadedCount: addedFiles.filter(
                                    file => file.progress === 100,
                                ).length,
                                freeSpace: "X Mb",
                                totalCount: addedFiles.length,
                                usedSpace: "Y Mb",
                            }}
                        >
                            {addedFiles.map(file => (
                                <FileDisplay
                                    key={file.id}
                                    file={file}
                                    isView={false}
                                    onDeleteFile={onDeleteFile}
                                />
                            ))}
                        </FileDisplayContainer>
                    )}
                </DialogContentStyles>
                {filesState === AddFilesState.FILES_IN_PROGRESS && (
                    <DialogActionsStyles>
                        <Button variant="text" onClick={cancelAddFiles}>
                            {t("my-file:addFilesDialod.cancel")}
                        </Button>
                        <Tooltip disableInteractive title={tooltipText}>
                            <span>
                                <Button
                                    disabled={!!tooltipText}
                                    color="primary"
                                    onClick={closeAddFilesDialog}
                                >
                                    {t("my-file:addFilesDialod.confirm")}
                                </Button>
                            </span>
                        </Tooltip>
                    </DialogActionsStyles>
                )}
            </DialogStyles>
            {dialog.dialogName === "FileUploadFailedWindow" && (
                <FileUploadFailedWindow
                    onContinue={closeFileUploadFailedWindow}
                />
            )}
        </>
    );
};

const DialogStyles = styled(Dialog)`
    & .MuiPaper-root {
        width: 600px;
    }
`;

const DialogContentStyles = styled(DialogContent)`
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
`;

const DialogActionsStyles = styled(DialogActions)`
    margin-top: 40;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > *:not(:last-child) {
        margin-right: 8;
    }
`;
