import { ComponentsOverrides } from "@mui/material/styles/overrides";
import styleVariables from "../variables";

export const MuiSvgIcon: ComponentsOverrides["MuiSvgIcon"] = {
    colorPrimary: {
        color: styleVariables.color.success.main,
    },
    colorDisabled: {
        color: styleVariables.color.warning.main,
    },
};

export default MuiSvgIcon;
